export const COMPONENT_CATEGORY_CODE = 'COMPONENTE';
export const CONSUMABLE_CATEGORY_CODE = 'CONSUMIBLE';
export const TOOL_CATEGORY_CODE = 'HERRAMIENTAS';
export const EQUIPMENT_AND_MACHINERY_CATEGORY_CODE = 'EQUIPOSYMAQUINARIAS';
export const RUBBER_CATEGORY_CODE = 'NEUMATICOS';

export const OVERHAULED_MATERIAL_CONDITION_CODE = 'COND_OVERHAULED';
export const TESTED_MATERIAL_CONDITION_CODE = 'COND_TESTED';
export const INSPECTED_MATERIAL_CONDITION_CODE = 'COND_INSPECTED';
export const MODIFIED_MATERIAL_CONDITION_CODE = 'COND_MODIFIED';
export const DISCARDED_MATERIAL_CONDITION_CODE = 'COND_DISCARDED';
export const EXCESS_MATERIAL_CONDITION_CODE = 'COND_EXCESS';
export const NEW_MATERIAL_CONDITION_CODE = 'COND_NEW';
export const REPAIRED_MATERIAL_CONDITION_CODE = 'COND_REPAIRED';
export const SERVICIABLE_MATERIAL_CONDITION_CODE = 'COND_SERVICIABLE';
export const TEST_BENCH_MATERIAL_CONDITION_CODE = 'COND_TEST_BENCH';

//Labels
export const CANCEL_LABEL = 'Cancel';
export const SAVE_LABEL = 'Save';
export const REQUIERED_FIELDS_LABEL = '(*)Required Fields';
export const DOWNLOAD_REPORT_LABEL = 'Download Report';
export const DOWNLOAD_REPORT_EXPIRED_LABEL = 'Download Expired Report';
export const DOWNLOAD_REPORT_AVAILABLE_LABEL = 'Download Available Report';
export const DOWNLOAD_REPORT_REPAIRABLE_LABEL = 'Download Repairable Report';




// Constantes para Estatus de Solicitud de Materiales
// Borrador
export const REACT_APP_MR_STATUS_DF = "DF";
export const REACT_APP_MR_STATUS_DF_TITLE = "Eraser";
// Pendiente de Procesar
export const REACT_APP_MR_STATUS_PE = "PE";
export const REACT_APP_MR_STATUS_PE_TITLE =  "Pending Dispatch";
// Reservada para Despacho
export const REACT_APP_MR_STATUS_PD = "PD";
export const REACT_APP_MR_STATUS_PD_TITLE = "Reserved for Dispatch";
// Despachada
export const REACT_APP_MR_STATUS_DP = "DP";
export const REACT_APP_MR_STATUS_DP_TITLE = "Dispatched";
// Semi Devolución de Materiales
export const REACT_APP_MR_STATUS_SD = "SD";
export const REACT_APP_MR_STATUS_SD_TITLE = "There is returned material";
// Devolución de Materiales
export const REACT_APP_MR_STATUS_DV = "DV";
export const REACT_APP_MR_STATUS_DV_TITLE = "Returned";
// Cerrada
export const REACT_APP_MR_STATUS_CC = "CC";
export const REACT_APP_MR_STATUS_CC_TITLE = "Closed";
// Anulada
export const REACT_APP_MR_STATUS_AN = "AN";
export const REACT_APP_MR_STATUS_AN_TITLE = "Canceled";
// En espera por el Inspector
export const REACT_APP_MR_STATUS_WI = "WI";
export const REACT_APP_MR_STATUS_WI_TITLE = "Wait for Inspector";
// Confirmada la instalacion
export const REACT_APP_MR_STATUS_CI = "CI";
export const REACT_APP_MR_STATUS_CI_TITLE = "Installation Confirmed";



//App Roles
export const ROLE_CODE_CHEFF                                 = 'CHEFF';
export const ROLE_CODE_DMTTO                                 = 'DMTTO';
export const ROLE_CODE_SUPER_USER                            = 'SUPER_USER';
export const ROLE_CODE_INGEN                                 = 'INGEN';
export const ROLE_CODE_JABAST                                = 'JABAST';
export const ROLE_CODE_ALMAC                                 = 'ALMAC';
export const ROLE_CODE_CCM                                   = 'CCM';
export const ROLE_CODE_CMTTO                                 = 'CMTTO';
export const ROLE_CODE_JINGE                                 = 'JINGE';
export const ROLE_CODE_COMEXT                                = 'COMEXT';
export const ROLE_CODE_AERO_INSPECTOR                        = 'AERO_INSPECTOR';
export const ROLE_CODE_JMTTO                                 = 'JMTTO';
export const ROLE_CODE_COCCM                                 = 'COCCM';
export const ROLE_CODE_COABAST                               = 'COABAST';
export const ROLE_CODE_TMA                                   = 'TMA';
export const ROLE_CODE_DINSPECT                              = 'DINSPECT';
export const ROLE_CODE_COINSPECT                             = 'COINSPECT';
export const ROLE_CODE_JINSPECT                              = 'JINSPECT';

//App Charge Codes


export const CHARGE_CODE_ADMINISTRATOR                       = 'ADMINISTRATOR';
export const CHARGE_CODE_CCM                                 = 'CCM';
export const CHARGE_CODE_STORER                              = 'STORER';
export const CHARGE_CODE_TMA2                                = 'TMA2';
export const CHARGE_CODE_TMA1                                = 'TMA1';
export const CHARGE_CODE_INSPECTOR                           = 'INSPECTOR';
export const CHARGE_CODE_CAPTAIN                             = 'CAPTAIN';
export const CHARGE_CODE_COPILOT                             = 'COPILOT';
export const CHARGE_CODE_F_OFFICIAL                          = 'F_OFFICIAL';
export const CHARGE_CODE_CABIN_BOSS                          = 'CABIN_BOSS';
export const CHARGE_CODE_CABIN_PER                           = 'CABIN_PER';
export const CHARGE_CODE_FLIGHT_DISP                         = 'FLIGHT_DISP';
export const CHARGE_CODE_MAINTENANCE                         = 'MAINTENANCE';
export const CHARGE_CODE_TRAINING                            = 'TRAINING';
export const CHARGE_CODE_OPERATIONS                          = 'OPERATIONS';
export const CHARGE_CODE_MAIN_MANAGER                        = 'MAIN_MANAGER';
export const CHARGE_CODE_MAIN_ASSIST                         = 'MAIN_ASSIST';
export const CHARGE_CODE_MAIN_COORD                          = 'MAIN_COORD';
export const CHARGE_CODE_HEAD_ENGI                           = 'HEAD_ENGI';
export const CHARGE_CODE_ENGI_ANALIST                        = 'ENGI_ANALIST';
export const CHARGE_CODE_AERO_ENGI                           = 'AERO_ENGI';
export const CHARGE_CODE_HAMD                                = 'HAMD';
export const CHARGE_CODE_DVP                                 = 'DVP';
export const CHARGE_CODE_EXE_PRES                            = 'EXE_PRES';
export const CHARGE_CODE_C_CCM                               = 'C_CCM';
export const CHARGE_CODE_D_C_C                               = 'D_C_C';

//Entity Codes
export const ENTITY_CODE_PAYMENT_TIME_OC                    = 'PAYMENT_TIME_OC';
export const ENTITY_CODE_CURRENCY_TYPE                      = 'CURRENCY_TYPE';
export const ENTITY_CODE_WARRANTY_TIME_TYPE                 = 'WARRANTY_TIME_TYPE';


//Correlative Documents Code
export const DOCUMENT_TYPE_CODE_RC                          = 'RC';
export const DOCUMENT_TYPE_CODE_RT                          = 'RT';
export const DOCUMENT_TYPE_CODE_OC                          = 'OC';

export const MATERIAL_STATE_CODE_NO_SERVICIABLE = 'NO_SERVICIABLE';
export const MATERIAL_STATE_CODE_INSTALADO = 'INSTALADO';
export const MATERIAL_STATE_CODE_TO_LOCATE = 'TOLOCATE';
export const MATERIAL_STATE_CODE_SERVICIABLE = 'SERVICIABLE';
export const MATERIAL_STATE_CODE_REMOVE = 'REMOVE';
export const MATERIAL_STATE_CODE_REPAIRABLE = 'REPAIRABLE';
export const MATERIAL_STATE_CODE_ENV_SERVICE = 'ENV_SERVICE';
export const MATERIAL_STATE_CODE_REJECTED = 'REJECTED';
export const MATERIAL_STATE_CODE_CANNIBALIZED = 'CANNIBALIZED';
export const MATERIAL_STATE_CODE_QUARANTINE = 'QUARANTINE';
export const MATERIAL_STATE_CODE_EXPIRED = 'EXPIRED';
export const MATERIAL_STATE_CODE_DISCARDED = 'DISCARDED';
export const MATERIAL_STATE_CODE_CV  = 'DISCARDED';
export const MATERIAL_STATE_CODE_CUSTODY  = 'CUSTODY';
