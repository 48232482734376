import {
    PREINCOMING_LIST,
    PREINCOMING_LIST_SUCCESS,
    PREINCOMING_LIST_ERROR,
    PREINCOMING_DDLIST,
    PREINCOMING_DDLIST_SUCCESS,
    PREINCOMING_DDLIST_ERROR,
    PREINCOMING_GET,
    PREINCOMING_GET_SUCCESS,
    PREINCOMING_GET_ERROR,
    PREINCOMING_NEW,
    PREINCOMING_NEW_SUCCESS,
    PREINCOMING_NEW_ERROR,
    PREINCOMING_NEW_RECORD,
    PREINCOMING_EDIT,
    PREINCOMING_EDIT_SUCCESS,
    PREINCOMING_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de PreIncoming
export function PreIncomingListAction(){
    
    return (dispatch =>{
        dispatch(PreIncomingList());
        AxiosClient.get(`${process.env.REACT_APP_PREINCOMING}/all`)
            .then((response)=> {
                dispatch( PreIncomingListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( PreIncomingListError(error) );
            });
    });
}

export const PreIncomingList = () => ({
    type: PREINCOMING_LIST
});

export const PreIncomingListSuccess = (records) => ({
    type: PREINCOMING_LIST_SUCCESS,
    payload: records
});

export const PreIncomingListError = (error) => ({
    type: PREINCOMING_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Master Data por Entidad en forma de DropDown
export function PreIncomingDDListAction(entity_id){
    
    return (dispatch =>{
        dispatch(PreIncomingDDList());
        AxiosClient.get(`${process.env.REACT_APP_PREINCOMING}/ddlist`)
            .then((response)=> {
                dispatch( PreIncomingDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( PreIncomingDDListError(error) );
            });
    });
}

export const PreIncomingDDList = () => ({
    type: PREINCOMING_DDLIST
});

export const PreIncomingDDListSuccess = (records) => ({
    type: PREINCOMING_DDLIST_SUCCESS,
    payload: records
});

export const PreIncomingDDListError = (error) => ({
    type: PREINCOMING_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener un PreIncoming
export function PreIncomingGetAction(id){
    return (dispatch =>{
        dispatch(PreIncomingGet());
        AxiosClient.get(`${process.env.REACT_APP_PREINCOMING}/${id}`)
            .then((response)=> {
                dispatch( PreIncomingGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( PreIncomingGetError(error) );
            });
    });
}

export const PreIncomingGet = () => ({
    type: PREINCOMING_GET
});

export const PreIncomingGetSuccess = (record) => ({
    type: PREINCOMING_GET_SUCCESS,
    payload: record
});

export const PreIncomingGetError = (error) => ({
    type: PREINCOMING_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de PreIncoming cuando se va crear un registro nuevo
export function PreIncomingNewAction(){
    return (dispatch =>{
            dispatch(PreIncomingNewRecord());
    });
}

export const PreIncomingNewRecord = () => ({
    type: PREINCOMING_NEW_RECORD
});

// Función Principal para crear y editar registros de PreIncoming
export function PreIncomingSaveAction(record, isNew){
    console.log(record);
    return (dispatch =>{
        if(!isNew) {
            dispatch(PreIncomingEdit());
        } else{
            dispatch(PreIncomingNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_PREINCOMING}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( PreIncomingNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( PreIncomingNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_PREINCOMING}/${record.id}`, record)
                .then((response)=> {
                    dispatch( PreIncomingEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( PreIncomingEditError(true) );
                });
        }
    });
}

export const PreIncomingNew = () => ({
    type: PREINCOMING_NEW
});

export const PreIncomingNewSuccess = (record) => ({
    type: PREINCOMING_NEW_SUCCESS,
    payload: record
});

export const PreIncomingNewError = (error) => ({
    type: PREINCOMING_NEW_ERROR,
    payload: error
});

export const PreIncomingEdit = () => ({
    type: PREINCOMING_EDIT
});

export const PreIncomingEditSuccess = (record) => ({
    type: PREINCOMING_EDIT_SUCCESS,
    payload: record
});

export const PreIncomingEditError = (error) => ({
    type: PREINCOMING_EDIT_ERROR,
    payload: error
});