import { 
    CANNIBALIZATIONRECORD_ADD_NEW,
    CANNIBALIZATIONRECORD_EDIT_ONE, 
    CANNIBALIZATIONRECORD_SET_LIST, 
    CANNIBALIZATIONRECORD_SET_ONE} from "../types";

const initialState = {
    cannibalizationrecord: {},
    cannibalizationrecords: []
}

export default function( state = initialState, action ) {
    switch (action.type) {
        case CANNIBALIZATIONRECORD_SET_LIST:
            return {
                ...state,
                cannibalizationrecords: action.payload
            }

        case CANNIBALIZATIONRECORD_SET_ONE:
            return {
                ...state,
                cannibalizationrecord: action.payload
            }

        case CANNIBALIZATIONRECORD_EDIT_ONE:
            return {
                ...state,
                cannibalizationrecords: state.cannibalizationrecords.map( record => ( record.id === action.payload.id ? action.payload : record ) ),
                cannibalizationrecord: action.payload
            }

        case CANNIBALIZATIONRECORD_ADD_NEW:
            return {
                ...state,
                cannibalizationrecords: [ ...state.cannibalizationrecords, action.payload ],
            }
    
        default:
            return state;
    }
}