import {
    FLEET_LIST,
    FLEET_LIST_SUCCESS,
    FLEET_LIST_ERROR,
    FLEET_DDLIST,
    FLEET_DDLIST_SUCCESS,
    FLEET_DDLIST_ERROR,
    FLEET_NEW,
    // FLEET_NEW_SUCCESS,
    // FLEET_NEW_ERROR,
    FLEET_EDIT,
    // FLEET_EDIT_SUCCESS,
    // FLEET_EDIT_ERROR
} from '../types';

const initialState = {
    fleets: [],
    fleetList: [],
    fleet: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case FLEET_LIST:
            return {
                ...state,
                loading: true,
                fleet: {}
            }
        case FLEET_LIST_SUCCESS:
            return {
                ...state,
                fleets: action.payload,
                fleetList: [],
                loading: false,
                error: false,
                fleet: {}
            }
        case FLEET_LIST_ERROR:
            return {
                ...state,
                fleets: [],
                fleetList: [],
                loading: false,
                error: true,
                fleet: {}
            }
        case FLEET_DDLIST:
            return {
                ...state,
                loading: true,
                fleets: [],
                fleet: {}
            }
        case FLEET_DDLIST_SUCCESS:
            return {
                ...state,
                fleetList: action.payload,
                fleets: [],
                loading: false,
                error: false,
                fleet: {}
            }
        case FLEET_DDLIST_ERROR:
            return {
                ...state,
                fleets: [],
                fleetList: [],
                loading: false,
                error: true,
                fleet: {}
            }
        case FLEET_NEW:
            return {
                ...state
            }
        case FLEET_EDIT:
            return {
                ...state
            }
        default:
            return state;
    }
}
