import {
    BPARTNERBANK_LIST,
    BPARTNERBANK_LIST_SUCCESS,
    BPARTNERBANK_LIST_ERROR,
    BPARTNERBANK_DDLIST,
    BPARTNERBANK_DDLIST_SUCCESS,
    BPARTNERBANK_DDLIST_ERROR,
    BPARTNERBANKALL_DDLIST,
    BPARTNERBANKALL_DDLIST_SUCCESS,
    BPARTNERBANKALL_DDLIST_ERROR,
    BPARTNERBANK_GET,
    BPARTNERBANK_GET_SUCCESS,
    BPARTNERBANK_GET_ERROR,
    BPARTNERBANK_NEW,
    BPARTNERBANK_NEW_SUCCESS,
    BPARTNERBANK_NEW_ERROR,
    BPARTNERBANK_NEW_RECORD,
    BPARTNERBANK_EDIT,
    BPARTNERBANK_EDIT_SUCCESS,
    BPARTNERBANK_EDIT_ERROR
} from '../types';

const initialState = {
    bpartnerbanks: [],
    bpartnerbankddlist: [],
    bpartnerbank: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case BPARTNERBANK_LIST:
            return {
                ...state,
                loading: true,
                bpartnerbank: {}
            }
        case BPARTNERBANK_LIST_SUCCESS:
            return {
                ...state,
                bpartnerbanks: action.payload,
                loading: false,
                error: false,
                bpartnerbank: {}
            }
        case BPARTNERBANK_LIST_ERROR:
            return {
                ...state,
                bpartnerbanks: [],
                loading: false,
                error: true,
                bpartnerbank: {}
            }
        case BPARTNERBANK_DDLIST:
            return {
                ...state,
                loading: true,
                bpartnerbank: {}
            }
        case BPARTNERBANK_DDLIST_SUCCESS:
            return {
                ...state,
                bpartnerbankddlist: action.payload,                
                loading: false,
                error: false                
            }
        case BPARTNERBANK_DDLIST_ERROR:
            return {
                ...state,
                bpartnerbankddlist: [],
                loading: false,
                error: true                
            }

        case BPARTNERBANKALL_DDLIST:
            return {
                ...state,
                loading: true,
                bpartnerbank: {}
            }
        case BPARTNERBANKALL_DDLIST_SUCCESS:
            return {
                ...state,
                bpartnerbankddlist: action.payload,                
                loading: false,
                error: false                
            }
        case BPARTNERBANKALL_DDLIST_ERROR:
            return {
                ...state,
                bpartnerbankddlist: [],
                loading: false,
                error: true                
            }
        case BPARTNERBANK_GET:
            return {
                ...state,
                loading: true
            }
        case BPARTNERBANK_GET_SUCCESS:
            return {
                ...state,
                bpartnerbank: action.payload,
                loading: false,
                error: false
            }
        case BPARTNERBANK_GET_ERROR:
            return {
                ...state,
                error: true,
                bpartnerbank: {}
            }
        case BPARTNERBANK_NEW:
            return {
                ...state               
            }
        case BPARTNERBANK_NEW_SUCCESS:           
            return {
                ...state,
                bpartnerbanks: [...state.bpartnerbanks, action.payload],
                bpartnerbank: {},
                loading: false,
                error: false
            }
        case BPARTNERBANK_NEW_ERROR:           
            return {
                ...state,
                // bpartnerbank: action.payload,
                // loading: false,
                error: true
            }
        case BPARTNERBANK_NEW_RECORD:
            return {
                ...state,
                bpartnerbank: {},
                error: false
            }
        case BPARTNERBANK_EDIT:
            return {
                ...state
            }
        case BPARTNERBANK_EDIT_SUCCESS:            
            return {
                ...state,
                error: false,
                bpartnerbank: action.payload,
                bpartnerbanks: state.bpartnerbanks.map(bpartnerbank => bpartnerbank.id === action.payload.id ? bpartnerbank = action.payload : bpartnerbank),
            }
        case BPARTNERBANK_EDIT_ERROR:            
            return {
                ...state,              
                error: false
            }
        default:
            return state;
    }
}
















// import {
//     BPARTNERBANK_LIST,
//     BPARTNERBANK_LIST_SUCCESS,
//     BPARTNERBANK_LIST_ERROR,
//     BPARTNERBANK_DDLIST,
//     BPARTNERBANK_DDLIST_SUCCESS,
//     BPARTNERBANK_DDLIST_ERROR,
//     BPARTNERBANK_GET,
//     BPARTNERBANK_GET_SUCCESS,
//     BPARTNERBANK_GET_ERROR,
//     BPARTNERBANK_NEW,
//     BPARTNERBANK_NEW_SUCCESS,
//     BPARTNERBANK_NEW_ERROR,
//     BPARTNERBANK_NEW_RECORD,
//     BPARTNERBANK_EDIT,
//     BPARTNERBANK_EDIT_SUCCESS,
//     BPARTNERBANK_EDIT_ERROR
// } from '../types';

// const initialState = {
//     bpartnerbanks: [],
//     bpartnerbankDDList: [],
//     bpartnerbank: {},
//     loading: false,
//     error: false
// }

// export default function(state = initialState, action){
//     switch(action.type){
//         case BPARTNERBANK_LIST:
//             return {
//                 ...state,
//                 loading: true,
//                 bpartnerbank: {}
//             }
//         case BPARTNERBANK_LIST_SUCCESS:           
//             return {
//                 ...state,
//                 bpartnerbanks: action.payload,
//                 loading: false,
//                 error: false,
//                 bpartnerbank: {}
//             }
//         case BPARTNERBANK_LIST_ERROR:
//             return {
//                 ...state,
//                 bpartnerbanks: [],
//                 loading: false,
//                 error: true,
//                 bpartnerbank: {}
//             }
//         case BPARTNERBANK_DDLIST:
//             return {
//                 ...state,
//                 loading: true,              
//                 bpartnerbank: {}
//             }
//         case BPARTNERBANK_DDLIST_SUCCESS:
//             return {
//                 ...state,
//                 bpartnerbankDDList: action.payload,                
//                 loading: false,
//                 error: false                
//             }
//         case BPARTNERBANK_DDLIST_ERROR:
//             return {
//                 ...state,
//                 bpartnerbankDDList: [],
//                 loading: false,
//                 error: true,
//                 bpartnerbank: {}
//             }
//         case BPARTNERBANK_GET:
//             return {
//                 ...state,
//                 loading: true
//             }
//         case BPARTNERBANK_GET_SUCCESS:
//             return {
//                 ...state,
//                 bpartnerbank: action.payload,
//                 loading: false,
//                 error: false
//             }
//         case BPARTNERBANK_GET_ERROR:
//             return {
//                 ...state,
//                 error: true,
//                 bpartnerbank: {}
//             }
//         case BPARTNERBANK_NEW:
//             return {
//                 ...state
//             }
//         case BPARTNERBANK_NEW_SUCCESS:
//             return {
//                 ...state,
//                 bpartnerbanks: [...state.bpartnerbanks, action.payload],
//                 bpartnerbank: {},
//                 error: false
//             }
//         case BPARTNERBANK_NEW_ERROR:
//             return {
//                 ...state,
//                 error: true
//             }
//         case BPARTNERBANK_NEW_RECORD:
//             return {
//                 ...state,
//                 bpartnerbank: {},
//                 error: false
//             }
//         case BPARTNERBANK_EDIT:
//             return {
//                 ...state
//             }
//         case BPARTNERBANK_EDIT_SUCCESS:
//             return {
//                 ...state,
//                 error: false,
//                 bpartnerbank: action.payload,
//                 bpartnerbanks: state.bpartnerbanks.map(bpartnerbank => bpartnerbank.id === action.payload.id ? bpartnerbank = action.payload : bpartnerbank),
//             }
//         case BPARTNERBANK_EDIT_ERROR:
//             return {
//                 ...state,
//                 error: false
//             }         
//         default:
//             return state;
//     }
// }
