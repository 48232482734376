import {
    ENTITY_LIST,
    ENTITY_LIST_SUCCESS,
    ENTITY_LIST_ERROR,
    ENTITY_DDLIST,
    ENTITY_DDLIST_SUCCESS,
    ENTITY_DDLIST_ERROR,
} from '../types';

//const axios = require('axios');
import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Entity
export function EntityListAction(){
    
    return (dispatch =>{
        dispatch(EntityList());
        AxiosClient.get(`${process.env.REACT_APP_ENTITY}/all`)
            .then((response)=> {
                dispatch( EntityListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( EntityListError(error) );
            });
    });
}

export const EntityList = () => ({
    type: ENTITY_LIST
});

export const EntityListSuccess = (records) => ({
    type: ENTITY_LIST_SUCCESS,
    payload: records
});

export const EntityListError = (error) => ({
    type: ENTITY_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Master Data por Entidad en forma de DropDown
export function EntityDDListAction(){
    
    return (dispatch =>{
        dispatch(EntityDDList());
        AxiosClient.get(`${process.env.REACT_APP_ENTITY}/ddlist`)
            .then((response)=> {
                dispatch( EntityDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( EntityDDListError(error) );
            });
    });
}

export const EntityDDList = () => ({
    type: ENTITY_DDLIST
});

export const EntityDDListSuccess = (records) => ({
    type: ENTITY_DDLIST_SUCCESS,
    payload: records
});

export const EntityDDListError = (error) => ({
    type: ENTITY_DDLIST_ERROR,
    payload: error
});