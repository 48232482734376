import {
    BPARTNER_LIST,
    BPARTNER_LIST_SUCCESS,
    BPARTNER_LIST_ERROR,
    BPARTNER_DDLIST,
    BPARTNER_DDLIST_SUCCESS,
    BPARTNER_DDLIST_ERROR,
    BPARTNER_GET,
    BPARTNER_GET_SUCCESS,
    BPARTNER_GET_ERROR,
    BPARTNER_NEW,
    BPARTNER_NEW_SUCCESS,
    BPARTNER_NEW_ERROR,
    BPARTNER_NEW_RECORD,
    BPARTNER_EDIT,
    BPARTNER_EDIT_SUCCESS,
    BPARTNER_EDIT_ERROR
} from '../types';

const initialState = {
    bpartners: [],
    bpartner: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case BPARTNER_LIST:
            return {
                ...state,
                loading: true,
                bpartner: {}
            }
        case BPARTNER_LIST_SUCCESS:
            return {
                ...state,
                bpartners: action.payload,
                loading: false,
                error: false,
                bpartner: {}
            }
        case BPARTNER_LIST_ERROR:
            return {
                ...state,
                bpartners: [],
                loading: false,
                error: true,
                bpartner: {}
            }
        case BPARTNER_DDLIST:
            return {
                ...state,
                loading: true,
                bpartner: {}
            }
        case BPARTNER_DDLIST_SUCCESS:
            return {
                ...state,
                bpartners: action.payload,
                loading: false,
                error: false,
                bpartner: {}
            }
        case BPARTNER_DDLIST_ERROR:
            return {
                ...state,
                bpartners: [],
                loading: false,
                error: true,
                bpartner: {}
            }
        case BPARTNER_GET:
            return {
                ...state,
                loading: true
            }
        case BPARTNER_GET_SUCCESS:
            return {
                ...state,
                bpartner: action.payload,
                loading: false,
                error: false
            }
        case BPARTNER_GET_ERROR:
            return {
                ...state,
                error: true,
                bpartner: {}
            }
        case BPARTNER_NEW:
            return {
                ...state
            }
        case BPARTNER_NEW_SUCCESS:
            return {
                ...state,
                bpartners: [...state.bpartners, action.payload],
                bpartner: {},
                error: false
            }
        case BPARTNER_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case BPARTNER_NEW_RECORD:
            return {
                ...state,
                bpartner: {},
                error: false
            }
        case BPARTNER_EDIT:
            return {
                ...state
            }
        case BPARTNER_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                bpartner: action.payload,
                bpartners: state.bpartners.map(bpartner => bpartner.id === action.payload.id ? bpartner = action.payload : bpartner),
            }
        case BPARTNER_EDIT_ERROR:
            return {
                ...state,
                error: false
            }
        default:
            return state;
    }
}
