import {
    IMAGE_BY_PROCESS_LIST,
    IMAGE_BY_PROCESS_LIST_SUCCESS,
    IMAGE_BY_PROCESS_LIST_ERROR,
    IMAGE_BY_PROCESS_DDLIST,
    IMAGE_BY_PROCESS_DDLIST_SUCCESS,
    IMAGE_BY_PROCESS_DDLIST_ERROR,
    IMAGE_BY_PROCESS_GET,
    IMAGE_BY_PROCESS_GET_SUCCESS,
    IMAGE_BY_PROCESS_GET_ERROR,
    IMAGE_BY_PROCESS_NEW,
    IMAGE_BY_PROCESS_NEW_SUCCESS,
    IMAGE_BY_PROCESS_NEW_ERROR,
    IMAGE_BY_PROCESS_NEW_RECORD,
    IMAGE_BY_PROCESS_EDIT,
    IMAGE_BY_PROCESS_EDIT_SUCCESS,
    IMAGE_BY_PROCESS_EDIT_ERROR,
    IMAGE_BY_PROCESS_DELETE,
    IMAGE_BY_PROCESS_DELETE_SUCCESS,
    IMAGE_BY_PROCESS_DELETE_ERROR
} from '../types';

const initialState = {
    imagebyprocesss: [],
    imagebyprocess: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case IMAGE_BY_PROCESS_LIST:
            return {
                ...state,
                loading: true,
                imagebyprocess: {}
            }
        case IMAGE_BY_PROCESS_LIST_SUCCESS:
            return {
                ...state,
                imagebyprocesss: action.payload,
                loading: false,
                error: false,
                imagebyprocess: {}
            }
        case IMAGE_BY_PROCESS_LIST_ERROR:
            return {
                ...state,
                imagebyprocesss: [],
                loading: false,
                error: true,
                imagebyprocess: {}
            }
        case IMAGE_BY_PROCESS_DELETE:
            return {
                ...state
            }       
        case IMAGE_BY_PROCESS_DELETE_SUCCESS:
            return {
                ...state,
                imagebyprocesss: state.imagebyprocesss.filter(imagebyprocess=>imagebyprocess.id !== imagebyprocess.payload),                
                error: false
            }

        case IMAGE_BY_PROCESS_DELETE_ERROR:
            return {
                ...state,
                error: true
            }
        case IMAGE_BY_PROCESS_DDLIST:
            return {
                ...state,
                loading: true,
                imagebyprocess: {}
            }
        case IMAGE_BY_PROCESS_DDLIST_SUCCESS:
            return {
                ...state,
                imagebyprocesss: action.payload,
                loading: false,
                error: false,
                entity: {}
            }
        case IMAGE_BY_PROCESS_DDLIST_ERROR:
            return {
                ...state,
                imagebyprocesss: [],
                loading: false,
                error: true,
                imagebyprocess: {}
            }
        case IMAGE_BY_PROCESS_GET:
            return {
                ...state,
                loading: true
            }
        case IMAGE_BY_PROCESS_GET_SUCCESS:
            return {
                ...state,
                imagebyprocess: action.payload,
                loading: false,
                error: false
            }
        case IMAGE_BY_PROCESS_GET_ERROR:
            return {
                ...state,
                error: true,
                imagebyprocess: {}
            }
        case IMAGE_BY_PROCESS_NEW:
            return {
                ...state
            }
        case IMAGE_BY_PROCESS_NEW_SUCCESS:
            return {
                ...state,
                imagebyprocesss: [...state.imagebyprocesss, action.payload],
                imagebyprocess: {},
                error: false
            }
        case IMAGE_BY_PROCESS_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case IMAGE_BY_PROCESS_NEW_RECORD:
            return {
                ...state,
                imagebyprocess: {},
                error: false
            }
        case IMAGE_BY_PROCESS_EDIT:
            return {
                ...state
            }
        case IMAGE_BY_PROCESS_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                imagebyprocess: action.payload,
                imagebyprocesss: state.imagebyprocesss.map(imagebyprocess => imagebyprocess.id === action.payload.id ? imagebyprocess = action.payload : imagebyprocess),
            }
        case IMAGE_BY_PROCESS_EDIT_ERROR:
            return {
                ...state,
                error: false
            }
        default:
            return state;
    }
}
