import {
    MATERIALREQUESTDETAIL_LIST,
    MATERIALREQUESTDETAIL_LIST_SUCCESS,
    MATERIALREQUESTDETAIL_LIST_ERROR,
    MATERIALREQUESTDETAIL_GET,
    MATERIALREQUESTDETAIL_GET_SUCCESS,
    MATERIALREQUESTDETAIL_GET_ERROR,
    MATERIALREQUESTDETAIL_NEW,
    MATERIALREQUESTDETAIL_NEW_SUCCESS,
    MATERIALREQUESTDETAIL_NEW_ERROR,
    MATERIALREQUESTDETAIL_NEW_RECORD,
    MATERIALREQUESTDETAIL_EDIT,
    MATERIALREQUESTDETAIL_EDIT_SUCCESS,
    MATERIALREQUESTDETAIL_EDIT_ERROR,
    MATERIALREQUEST_RUBBER_AND_COMPONENTS_DD_LIST,
    MATERIALREQUEST_RUBBER_AND_COMPONENTS_DD_LIST_SUCCESS,
    MATERIALREQUEST_RUBBER_AND_COMPONENTS_DD_LIST_ERROR
} from '../types';

const initialState = {
    materialrequestdetails: [],
    materialrequestdetail: {},
    materialrequestrubberandcomponentsDDList : [],
    loading: false,
    error: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case MATERIALREQUESTDETAIL_LIST:
            return {
                ...state,
                loading: true,
                materialrequestdetail: {}
            }
        case MATERIALREQUESTDETAIL_LIST_SUCCESS:
            return {
                ...state,
                materialrequestdetails: action.payload,
                loading: false,
                error: false,
                materialrequestdetail: {}
            }
        case MATERIALREQUESTDETAIL_LIST_ERROR:
            return {
                ...state,
                materialrequestdetails: [],
                loading: false,
                error: true,
                materialrequestdetail: {}
            }
        case MATERIALREQUESTDETAIL_GET:
            return {
                ...state,
                loading: true
            }
        case MATERIALREQUESTDETAIL_GET_SUCCESS:
            return {
                ...state,
                materialrequestdetail: action.payload,
                loading: false,
                error: false
            }
        case MATERIALREQUESTDETAIL_GET_ERROR:
            return {
                ...state,
                error: true,
                materialrequestdetail: {}
            }
        case MATERIALREQUESTDETAIL_NEW:
            return {
                ...state
            }
        case MATERIALREQUESTDETAIL_NEW_SUCCESS:
            return {
                ...state,
                materialrequestdetails: [...state.materialrequestdetails, action.payload],
                materialrequestdetail: {},
                error: false
            }
        case MATERIALREQUESTDETAIL_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case MATERIALREQUESTDETAIL_NEW_RECORD:
            return {
                ...state,
                materialrequestdetail: {},
                error: false
            }
        case MATERIALREQUESTDETAIL_EDIT:
            return {
                ...state
            }
        case MATERIALREQUESTDETAIL_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                materialrequestdetail: action.payload,
                materialrequestdetails: state.materialrequestdetails.map(materialrequestdetail => materialrequestdetail.id === action.payload.id ? materialrequestdetail = action.payload : materialrequestdetail),
            }
        case MATERIALREQUESTDETAIL_EDIT_ERROR:
            return {
                ...state,
                error: false
            }
        case MATERIALREQUEST_RUBBER_AND_COMPONENTS_DD_LIST:
            return {
                ...state,
                loading: true,
                materialrequestrubberandcomponentsDDList: []
            };
        case MATERIALREQUEST_RUBBER_AND_COMPONENTS_DD_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                materialrequestrubberandcomponentsDDList: action.payload
            };
        case MATERIALREQUEST_RUBBER_AND_COMPONENTS_DD_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                materialrequestrubberandcomponentsDDList: []
            };
        default:
            return state;
    }
}
