import {
    UOM_LIST,
    UOM_LIST_SUCCESS,
    UOM_LIST_ERROR,
    UOM_DDLIST,
    UOM_DDLIST_SUCCESS,
    UOM_DDLIST_ERROR,
    UOM_DDTYPEUNITLIST,
    UOM_DDTYPEUNITLIST_SUCCESS,
    UOM_DDTYPEUNITLIST_ERROR,
    UOM_GET,
    UOM_GET_SUCCESS,
    UOM_GET_ERROR,
    UOM_NEW,
    UOM_NEW_SUCCESS,
    UOM_NEW_ERROR,
    UOM_NEW_RECORD,
    UOM_EDIT,
    UOM_EDIT_SUCCESS,
    UOM_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Unidades de Medida
export function UomListAction(){
    
    return (dispatch =>{
        dispatch(UomList());
        AxiosClient.get(`${process.env.REACT_APP_UOM}`)
            .then((response)=> {
                dispatch(UomListSuccess(response.data.result));
            })
            .catch(error=> {
                console.log(error);
                dispatch( UomListError(error) );
            });
    });
}

export const UomList = () => ({
    type: UOM_LIST
});

export const UomListSuccess = (records) => ({
    type: UOM_LIST_SUCCESS,
    payload: records
});

export const UomListError = (error) => ({
    type: UOM_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Unidad de Medida en Forma de DropDown
export const UomDDListAction = () => async (dispatch) => {
    dispatch(UomDDList());
    AxiosClient.get(`${process.env.REACT_APP_UOM}/ddlist`)
        .then((response)=> {
            dispatch( UomDDListSuccess(response.data.result));
        })
        .catch(error=> {
            dispatch( UomDDListError(error) );
        });
}

export const UomDDList = () => ({
    type: UOM_DDLIST
});

export const UomDDListSuccess = (records) => ({
    type: UOM_DDLIST_SUCCESS,
    payload: records    
});

export const UomDDListError = (error) => ({
    type: UOM_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener Unidad de Medida por Tipo de Unidad en Forma de DropDown
export function UomTypeUnitDDListAction(uom_type_id,id){    
    return (dispatch =>{
        dispatch(UomTypeUnitDDList());
        AxiosClient.get(`${process.env.REACT_APP_UOM}/ddlist/${uom_type_id}/${id}`)
            .then((response)=> {
                dispatch( UomDDTypeUnitListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( UomDDTypeUnitListError(error) );
            });
    });
}

export const UomTypeUnitDDList = () => ({
    type: UOM_DDTYPEUNITLIST
});

export const UomDDTypeUnitListSuccess = (records) => ({
    type: UOM_DDTYPEUNITLIST_SUCCESS,
    payload: records
});

export const UomDDTypeUnitListError = (error) => ({
    type: UOM_DDTYPEUNITLIST_ERROR,
    payload: error
});

// Función Principal para obtener una Unidad de Medida por ID
export function UomGetAction(id){
    return (dispatch =>{
        dispatch(UomGet());
        AxiosClient.get(`${process.env.REACT_APP_UOM}/${id}`)
            .then((response)=> {
                dispatch( UomGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( UomGetError(error) );
            });
    });
}

export const UomGet = () => ({
    type: UOM_GET
});

export const UomGetSuccess = (record) => ({
    type: UOM_GET_SUCCESS,
    payload: record
});

export const UomGetError = (error) => ({
    type: UOM_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Unidad de Medida cuando se va crear un registro nuevo
export function UomNewAction(){
    return (dispatch =>{
            dispatch(UomNewRecord());
    });
}

export const UomNewRecord = () => ({
    type: UOM_NEW_RECORD
});

// Función Principal para crear y editar registros de Unidades de Medida
export function UomSaveAction(record, isNew){
    console.log(record);
    return (dispatch =>{
        if(!isNew) {
            dispatch(UomEdit());
        } else{
            dispatch(UomNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_UOM}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( UomNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( UomNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_UOM}/${record.id}`, record)
                .then((response)=> {
                    dispatch( UomEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( UomEditError(true) );
                });
        }
    });
}

export const UomNew = () => ({
    type: UOM_NEW
});

export const UomNewSuccess = (record) => ({
    type: UOM_NEW_SUCCESS,
    payload: record
});

export const UomNewError = (error) => ({
    type: UOM_NEW_ERROR,
    payload: error
});

export const UomEdit = () => ({
    type: UOM_EDIT
});

export const UomEditSuccess = (record) => ({
    type: UOM_EDIT_SUCCESS,
    payload: record
});

export const UomEditError = (error) => ({
    type: UOM_EDIT_ERROR,
    payload: error
});
