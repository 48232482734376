import {
    CHARGE_LIST,
    CHARGE_LIST_SUCCESS,
    CHARGE_LIST_ERROR,
    CHARGE_DDLIST,
    CHARGE_DDLIST_SUCCESS,
    CHARGE_DDLIST_ERROR,
    CHARGE_GET,
    CHARGE_GET_SUCCESS,
    CHARGE_GET_ERROR,
    CHARGE_NEW,
    CHARGE_NEW_SUCCESS,
    CHARGE_NEW_ERROR,
    CHARGE_NEW_RECORD,
    CHARGE_EDIT,
    CHARGE_EDIT_SUCCESS,
    CHARGE_EDIT_ERROR
} from '../types';

const initialState = {
    charges: [], 
    charge:{},   
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case CHARGE_LIST:
            return {
                ...state,
                loading: true,
                charge: {}
            }
        case CHARGE_LIST_SUCCESS:
            return {
                ...state,
                charges: action.payload,
                loading: false,
                error: false,
                charge: {}
            }
        case CHARGE_LIST_ERROR:
            return {
                ...state,
                charges: [],
                loading: false,
                error: true,
                charge: {}
            }
        case CHARGE_DDLIST:
            return {
                ...state,
                loading: true,
                charge: {}
            }
        case CHARGE_DDLIST_SUCCESS:
            return {
                ...state,
                charges: action.payload,
                loading: false,
                error: false,
                charge: {}
            }
        case CHARGE_DDLIST_ERROR:
            return {
                ...state,
                charges: [],
                loading: false,
                error: true,
                charge: {}
            }
        case CHARGE_GET:
            return {
                ...state,
                loading: true
            }
        case CHARGE_GET_SUCCESS:
            return {
                ...state,
                charge: action.payload,
                loading: false,
                error: false
            }
        case CHARGE_GET_ERROR:
            return {
                ...state,
                error: true,
                charge: {}
            }
        case CHARGE_NEW:
            return {
                ...state
            }
        case CHARGE_NEW_SUCCESS:
            return {
                ...state,
                charges: [...state.charges, action.payload],
                charge: {},
                error: false
            }
        case CHARGE_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case CHARGE_NEW_RECORD:
            return {
                ...state,
                charge: {},
                error: false
            }
        case CHARGE_EDIT:
            return {
                ...state
            }
        case CHARGE_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                charge: action.payload,
                charges: state.charges.map(charge => charge.id === action.payload.id ? charge = action.payload : charge),
            }
        case CHARGE_EDIT_ERROR:
            return {
                ...state,
                error: false
            }
        default:
            return state;
    }
}
