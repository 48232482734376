import {
    DEPARTMENT_LIST,
    DEPARTMENT_LIST_SUCCESS,
    DEPARTMENT_LIST_ERROR,
    DEPARTMENT_DDLIST,
    DEPARTMENT_DDLIST_SUCCESS,
    DEPARTMENT_DDLIST_ERROR,
    DEPARTMENT_GET,
    DEPARTMENT_GET_SUCCESS,
    DEPARTMENT_GET_ERROR,
    DEPARTMENT_NEW,
    DEPARTMENT_NEW_SUCCESS,
    DEPARTMENT_NEW_ERROR,
    DEPARTMENT_NEW_RECORD,
    DEPARTMENT_EDIT,
    DEPARTMENT_EDIT_SUCCESS,
    DEPARTMENT_EDIT_ERROR
} from '../types';

const initialState = {
    departments: [], 
    departmentDDList: [],
    department:{},   
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case DEPARTMENT_LIST:
            return {
                ...state,
                loading: true,
                department: {}
            }
        case DEPARTMENT_LIST_SUCCESS:
            return {
                ...state,
                departments: action.payload,
                loading: false,
                error: false,
                department: {}
            }
        case DEPARTMENT_LIST_ERROR:
            return {
                ...state,
                departments: [],
                loading: false,
                error: true,
                department: {}
            }
        case DEPARTMENT_DDLIST:
            return {
                ...state,
                loading: true,
                department: {}
            }
        case DEPARTMENT_DDLIST_SUCCESS:
            return {
                ...state,
                departmentDDList: action.payload,
                loading: false,
                error: false,  
                //department:{}             
            }
        case DEPARTMENT_DDLIST_ERROR:
            return {
                ...state,
                departmentDDList: [],
                loading: false,
                error: true,
                department: {}
            }
        case DEPARTMENT_GET:
            return {
                ...state,
                loading: true
            }
        case DEPARTMENT_GET_SUCCESS:
            return {
                ...state,
                department: action.payload,
                loading: false,
                error: false
            }
        case DEPARTMENT_GET_ERROR:
            return {
                ...state,
                error: true,
                department: {}
            }
        case DEPARTMENT_NEW:
            return {
                ...state
            }
        case DEPARTMENT_NEW_SUCCESS:
            return {
                ...state,
                departments: [...state.departments, action.payload],
                department: {},
                error: false
            }
        case DEPARTMENT_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case DEPARTMENT_NEW_RECORD:
            return {
                ...state,
                department: {},
                error: false
            }
        case DEPARTMENT_EDIT:
            return {
                ...state
            }
        case DEPARTMENT_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                department: action.payload,
                departments: state.departments.map(department => department.id === action.payload.id ? department = action.payload : department),

                departmentDDList: []
            }
        case DEPARTMENT_EDIT_ERROR:
            return {
                ...state,
                error: false
            }
        default:
            return state;
    }
}
