import {
    AIRPLANECONTROLMAINTENANCE_LIST,
    AIRPLANECONTROLMAINTENANCE_LIST_SUCCESS,
    AIRPLANECONTROLMAINTENANCE_LIST_ERROR,
    AIRPLANECONTROLMAINTENANCE_DDLIST,
    AIRPLANECONTROLMAINTENANCE_DDLIST_SUCCESS,
    AIRPLANECONTROLMAINTENANCE_DDLIST_ERROR,
    AIRPLANECONTROLMAINTENANCE_GET,
    AIRPLANECONTROLMAINTENANCE_GET_SUCCESS,
    AIRPLANECONTROLMAINTENANCE_GET_ERROR,
    AIRPLANECONTROLMAINTENANCE_NEW,
    AIRPLANECONTROLMAINTENANCE_NEW_SUCCESS,
    AIRPLANECONTROLMAINTENANCE_NEW_ERROR,
    AIRPLANECONTROLMAINTENANCE_NEW_RECORD,
    AIRPLANECONTROLMAINTENANCE_EDIT,
    AIRPLANECONTROLMAINTENANCE_EDIT_SUCCESS,
    AIRPLANECONTROLMAINTENANCE_EDIT_ERROR,
} from '../types';

import AxiosClient from '../../config/AxiosClient';
require('dotenv').config();

// // Función Principal para obtener la Lista de Control de Mantenimiento por Aeronave
export function AirplaneControlMaintenanceListAction(id){
    return (dispatch =>{
        dispatch(AirplaneControlMaintenanceList());
        AxiosClient.get(`${process.env.REACT_APP_CONTROLMAINTENANCE}/list/${id}`)      
        .then((response)=> {
            dispatch(AirplaneControlMaintenanceListSuccess(response.data.result));
        })
        .catch(error=> {
            dispatch(AirplaneControlMaintenanceListError(error) );
        });
    });
}


export const AirplaneControlMaintenanceList = () => ({
    type: AIRPLANECONTROLMAINTENANCE_LIST,
});

export const AirplaneControlMaintenanceListSuccess = (records) => ({
    type: AIRPLANECONTROLMAINTENANCE_LIST_SUCCESS,
    payload: records,
});

export const  AirplaneControlMaintenanceListError = (error) => ({
    type: AIRPLANECONTROLMAINTENANCE_LIST_ERROR,
    payload: error,
});

// // Función Principal para obtener los Task Card en forma de DDList
// export const AirplaneTaskCardDDListAction = () => async (dispatch) => {
//     dispatch(AirplaneTaskCardDDList());
//     await AxiosClient.get(`${process.env.REACT_APP_CONTROLMAINTENANCE}/ddlist`)
//         .then((response) => {
//             dispatch(AirplaneTaskCardDDListSuccess(response.data.result));
//         })
//         .catch((error) => {
//             dispatch(AirplaneTaskCardDDListError(error));
//         });
// };

// export const AirplaneTaskCardDDList = () => ({
//     type: AIRPLANETASKCARD_DDLIST,
// });

// export const AirplaneTaskCardDDListSuccess = (records) => ({
//     type: AIRPLANETASKCARD_DDLIST_SUCCESS,
//     payload: records,
// });

// export const AirplaneTaskCardDDListError = (error) => ({
//     type: AIRPLANETASKCARD_DDLIST_ERROR,
//     payload: error,
// });


// Función Principal para obtener un Control de Mantenimiento especifico
export const AirplaneControlMaintenanceGetAction = (id) => async (dispatch) => {
    try {
        dispatch(AirplaneControlMaintenanceGet());
        const response = await AxiosClient.get(`${process.env.REACT_APP_CONTROLMAINTENANCE}/${id}`).catch(
            (error) => {
                dispatch(AirplaneControlMaintenanceGetError(error));
            },
        );
        dispatch(AirplaneControlMaintenanceGetSuccess(response.data.result));
    } catch (e) {
        console.error(e);
    }
};

export const AirplaneControlMaintenanceGet = () => ({
    type: AIRPLANECONTROLMAINTENANCE_GET,
});

export const AirplaneControlMaintenanceGetSuccess = (record) => ({
    type: AIRPLANECONTROLMAINTENANCE_GET_SUCCESS,
    payload: record,
});

export const AirplaneControlMaintenanceGetError = (error) => ({
    type: AIRPLANECONTROLMAINTENANCE_GET_ERROR,
    payload: error,
});



// Main function for to create Maintenance
export const AirplaneControlMaintenanceNewAction = (airplanecontrolmaintenance) => async (dispatch) => {
    dispatch(AirplaneControlMaintenanceNew());

    AxiosClient.post(process.env.REACT_APP_CONTROLMAINTENANCE, airplanecontrolmaintenance)
        .then((resultado) => {
            if (resultado.status === 201) {
                dispatch(AirplaneControlMaintenanceNewSuccess(airplanecontrolmaintenance));
            }
        })
        .catch((error) => {
            dispatch(AirplaneControlMaintenanceNewError());
        });
};

export const AirplaneControlMaintenanceNewRecord = () => ({
    type: AIRPLANECONTROLMAINTENANCE_NEW_RECORD,
});

// Función Principal para crear y editar registros de Mantenimiento
export const AirplaneControlMaintenanceSaveAction = (record, isNew) => async (dispatch) => {

    if (!isNew) {
        dispatch(AirplaneControlMaintenanceEdit());
    } else {
        dispatch(AirplaneControlMaintenanceNew());
    }
    if (isNew) {
        await AxiosClient.post(`${process.env.REACT_APP_CONTROLMAINTENANCE}`, record)
            .then((response) => {
                record.id = response.data.result.id;
                //dispatch( AirplaneNewSuccess(record));
                console.debug(response);
                //dispatch(AirplaneControlMaintenanceNewSuccess(response.data.result));
                dispatch(AirplaneControlMaintenanceNewSuccess(record));
            })
            .catch((error) => {
                console.error(error);
                dispatch(AirplaneControlMaintenanceNewError(error));
            });
    } else {
        await AxiosClient.put(`${process.env.REACT_APP_CONTROLMAINTENANCE}/${record.id}`, record)
            .then((response) => {               
                //dispatch(AirplaneTaskCardEditSuccess(record.data.result));
                dispatch(AirplaneControlMaintenanceEditSuccess(record));
            })
            .catch((error) => {
                console.error(error);
                dispatch(AirplaneControlMaintenanceEditError(error));
            });
    }
};

export const AirplaneControlMaintenanceNew = () => ({
    type: AIRPLANECONTROLMAINTENANCE_NEW,
});

export const AirplaneControlMaintenanceNewSuccess = (record) => ({
    type: AIRPLANECONTROLMAINTENANCE_NEW_SUCCESS,
    payload: record,
});

export const AirplaneControlMaintenanceNewError = (error) => ({
    type: AIRPLANECONTROLMAINTENANCE_NEW_ERROR,
    payload: error,
});

export const AirplaneControlMaintenanceEdit = () => ({
    type: AIRPLANECONTROLMAINTENANCE_EDIT,
});

export const AirplaneControlMaintenanceEditSuccess = (record) => ({
    type: AIRPLANECONTROLMAINTENANCE_EDIT_SUCCESS,
    payload: record,
});

export const AirplaneControlMaintenanceEditError = (error) => ({
    type: AIRPLANECONTROLMAINTENANCE_EDIT_ERROR,
    payload: error,
});
