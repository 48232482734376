import {
    AIRPLANEMANUAL_LIST,
    AIRPLANEMANUAL_LIST_SUCCESS,
    AIRPLANEMANUAL_LIST_ERROR,
    AIRPLANEMANUAL_GET,
    AIRPLANEMANUAL_GET_SUCCESS,
    AIRPLANEMANUAL_GET_ERROR,
    AIRPLANEMANUAL_NEW,
    AIRPLANEMANUAL_NEW_SUCCESS,
    AIRPLANEMANUAL_NEW_ERROR,
    AIRPLANEMANUAL_EDIT,
    AIRPLANEMANUAL_EDIT_SUCCESS,
    AIRPLANEMANUAL_EDIT_ERROR
} from '../types';

// Inicialización del State
const initialState = {
    airplaneManualList: [],
    airplaneManual: {},
    error: false
}

export default function (state=initialState, action){
    switch(action.type){
        case AIRPLANEMANUAL_LIST:
            return {
                ...state
            }
        case AIRPLANEMANUAL_LIST_SUCCESS:
            return {
                airplaneManualList : action.payload
            }
        case AIRPLANEMANUAL_LIST_ERROR:
            return {
                airplaneManualList : []
            }
        case AIRPLANEMANUAL_GET:
            return {
                ...state,
                loading: true
            }
        case AIRPLANEMANUAL_GET_SUCCESS:
            return {
                ...state,
                airplaneManual: action.payload,
                pdf: action.pdf,
                loading: false,
                error: false
            }
        case AIRPLANEMANUAL_GET_ERROR:
            return {
                ...state,
                error: true,
                airplaneManual: {}
            }
        case AIRPLANEMANUAL_NEW:
            return {
                ...state,
            }
        case AIRPLANEMANUAL_NEW_SUCCESS:
            return {
                ...state,
                error: false,
                airplaneManualList: [...state.airplaneManualList, action.payload]
            }
        case AIRPLANEMANUAL_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case AIRPLANEMANUAL_EDIT:
            return {
                ...state
            }
        case AIRPLANEMANUAL_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                airplaneManualList: state.airplaneManualList.map(airplanemanual => airplanemanual.id === action.payload.id ? airplanemanual = action.payload : airplanemanual),
                airplaneManual: {}
            }
        case AIRPLANEMANUAL_EDIT_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state;
    }
}