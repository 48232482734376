import { 
    SERVICEROLE_SET_LIST,
    SERVICEROLE_SET_ONE,
    SERVICEROLE_ADD_NEW,
    SERVICEROLE_EDIT_ONE,
    SERVICEROLE_SET_DDLIST
} from "../types";


const initialState = {
    servicerolelist: [],
    servicerole: {},
    serviceroleddlist: []
}

export default function( state= initialState, action ) {
    switch (action.type) {
        case SERVICEROLE_SET_LIST:
            return {
                ...state,
                servicerolelist: action.payload
            };
        
        case SERVICEROLE_SET_ONE:
            return {
                ...state,
                servicerole: action.payload
            };

        case SERVICEROLE_ADD_NEW:
            return {
                ...state,
                servicerolelist: [...state.servicerolelist, action.payload]
            };

        case SERVICEROLE_EDIT_ONE:
            return {
                ...state,
                servicerolelist: state.servicerolelist.map( servicerole => ( servicerole.id === action.payload.id ? action.payload : servicerole ) )
            };

        case SERVICEROLE_SET_DDLIST:
            return {
                ...state,
                serviceroleddlist: action.payload
            };
            
        default:
            return state;
    }
}