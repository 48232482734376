import AxiosClient from '../../config/AxiosClient';
import {
    PURCHASEORDER_NEW,
    PURCHASEREQUIREMENT_CLEAN,
    PURCHASEREQUIREMENT_EDIT,
    PURCHASEREQUIREMENT_EDIT_ERROR,
    PURCHASEREQUIREMENT_EDIT_SUCCESS,
    PURCHASEREQUIREMENT_GET,
    PURCHASEREQUIREMENT_GET_ERROR,
    PURCHASEREQUIREMENT_GET_SUCCESS,
    PURCHASEREQUIREMENT_LIST,
    PURCHASEREQUIREMENT_LIST_ERROR,
    PURCHASEREQUIREMENT_LIST_SUCCESS,
    PURCHASEREQUIREMENT_NEW,
    PURCHASEREQUIREMENT_NEW_ERROR,
    PURCHASEREQUIREMENT_NEW_SUCCESS,
    PURCHASEREQUIREMENT_REPORT_GET,
    PURCHASEREQUIREMENT_REPORT_GET_ERROR,
    PURCHASEREQUIREMENT_REPORT_GET_SUCCESS,
    PURCHASEREQUIREMENTDETAIL_DD_LIST,
    PURCHASEREQUIREMENTDETAIL_DD_LIST_ERROR,
    PURCHASEREQUIREMENTDETAIL_DD_LIST_SUCCESS,
    PURCHASEREQUIREMENT_REPORT_STOCK_FOR_PURCHASE_GET,
    PURCHASEREQUIREMENT_REPORT_STOCK_FOR_PURCHASE_GET_SUCCESS,
    PURCHASEREQUIREMENT_REPORT_STOCK_FOR_PURCHASE_GET_ERROR
} from '../types';

require('dotenv').config();

export function PurchaseRequirementListAction() {
    return (dispatch => {
        dispatch(PurchaseRequirementList());
        AxiosClient.get(`${process.env.REACT_APP_PURCHASEREQUIREMENT}`)
            .then((response) => {
                dispatch(PurchaseRequirementListSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(PurchaseRequirementListError());
            });
    });
};

export function PurchaseRequirementList() {
    return {
        type: PURCHASEREQUIREMENT_LIST
    };
};

export function PurchaseRequirementListSuccess(records) {
    return {
        type: PURCHASEREQUIREMENT_LIST_SUCCESS,
        payload: records
    };
};

export function PurchaseRequirementListError(error) {
    return {
        type: PURCHASEREQUIREMENT_LIST_ERROR,
        payload: error
    };
};

export const PurchaseRequirementCleanAction = () => (dispatch) => {
    dispatch(PurchaseRequirementClean())
}

export const PurchaseRequirementClean = () => ({
    type: PURCHASEREQUIREMENT_CLEAN
});

export const PurchaseRequirementGetAction = (id) => async (dispatch) => {
    dispatch(PurchaseRequirementGet());
    await AxiosClient.get(`${process.env.REACT_APP_PURCHASEREQUIREMENT}/getOne/${id}`)
        .then((response) => {
            dispatch(PurchaseRequirementGetSuccess(response.data.result));
        })
        .catch(error => {
            dispatch(PurchaseRequirementGetError(error));
        });
}

export const PurchaseRequirementGet = () => ({
    type: PURCHASEREQUIREMENT_GET
});

export const PurchaseRequirementGetSuccess = (record) => ({
    type: PURCHASEREQUIREMENT_GET_SUCCESS,
    payload: record
});

export const PurchaseRequirementGetError = (error) => ({
    type: PURCHASEREQUIREMENT_GET_ERROR,
    payload: error
});

export const PurchaseRequirementReportGetAction = (id) => async (dispatch) => {
    dispatch(PurchaseRequirementReportGet());
    const response = await AxiosClient.get(`${process.env.REACT_APP_PURCHASEREQUIREMENT}/report/${id}`)
        .catch(error => {
            dispatch(PurchaseRequirementReportGetError(error));
            return {
                ok: false,
                error
            };
        });
    dispatch(PurchaseRequirementReportGetSuccess(response.data.result));
    return response?.data;
}

export const PurchaseRequirementReportGet = () => ({
    type: PURCHASEREQUIREMENT_REPORT_GET
});

export const PurchaseRequirementReportGetSuccess = (record) => ({
    type: PURCHASEREQUIREMENT_REPORT_GET_SUCCESS,
    payload: record
});

export const PurchaseRequirementReportGetError = (error) => ({
    type: PURCHASEREQUIREMENT_REPORT_GET_ERROR,
    payload: error
});

export const PurchaseRequirementSaveAction = (record, isNew) => async (dispatch) => {

    if (isNew) {
        dispatch(PurchaseRequirementNew());
        const response = await AxiosClient.post(
            `${process.env.REACT_APP_PURCHASEREQUIREMENT}`,
            record,
        ).catch((error) => {
            dispatch(PurchaseRequirementNewError(error));
        });

        if (response) {
            dispatch(PurchaseRequirementNewSuccess( (response.data.result ? response.data.result : {}) ));
            dispatch(PurchaseRequirementGetSuccess( (response.data.result ? response.data.result : {}) ));

            return response.data;
        } else {
            return {};
        }


    } else {
        dispatch(PurchaseRequirementEdit());
        const response = await AxiosClient.put(
            `${process.env.REACT_APP_PURCHASEREQUIREMENT}`,
            record,
        ).catch((error) => {
            dispatch(PurchaseRequirementEditError(error));
        });

        if (response) {
            dispatch(PurchaseRequirementEditSuccess( (response.data.result ? response.data.result : {}) ));
            dispatch(PurchaseRequirementGetSuccess( (response.data.result ? response.data.result : {}) ));

            return response.data;
        } else {
            return {};
        }

    }
};

export const PurchaseRequirementNew = () => ({
    type: PURCHASEREQUIREMENT_NEW
});

export const PurchaseRequirementNewSuccess = (record) => ({
    type: PURCHASEREQUIREMENT_NEW_SUCCESS,
    payload: record
});

export const PurchaseRequirementNewError = (error) => ({
    type: PURCHASEREQUIREMENT_NEW_ERROR,
    payload: error
});

export const PurchaseRequirementEdit = () => ({
    type: PURCHASEREQUIREMENT_EDIT
});

export const PurchaseRequirementEditSuccess = (record) => ({
    type: PURCHASEREQUIREMENT_EDIT_SUCCESS,
    payload: record
});

export const PurchaseRequirementEditError = (error) => ({
    type: PURCHASEREQUIREMENT_EDIT_ERROR,
    payload: error
});

export const PurchaseRequirementApprovedListAction = () => {
    return (dispatch => {
        dispatch(PurchaseRequirementList());
        AxiosClient.get(`${process.env.REACT_APP_PURCHASEREQUIREMENT}/approved`)
            .then((response) => {
                dispatch(PurchaseRequirementListSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(PurchaseRequirementListError());
            });
    });
}

export function PurchaseRequirementDetailDDListAction( pr_id ) {
    return (dispatch => {
        dispatch(PurchaseRequirementDetailDDList());
        AxiosClient.get(`${process.env.REACT_APP_PURCHASEREQUIREMENT}/detail/ddlist/${pr_id}`)
            .then((response) => {
                dispatch(PurchaseRequirementDetailDDListSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(PurchaseRequirementDetailDDListError());
            });
    });
};

export function PurchaseRequirementDetailDDList() {
    return {
        type: PURCHASEREQUIREMENTDETAIL_DD_LIST
    };
};

export function PurchaseRequirementDetailDDListSuccess(records) {
    return {
        type: PURCHASEREQUIREMENTDETAIL_DD_LIST_SUCCESS,
        payload: records
    };
};

export function PurchaseRequirementDetailDDListError(error) {
    return {
        type: PURCHASEREQUIREMENTDETAIL_DD_LIST_ERROR,
        payload: error
    };
};

export const PurchaseRequirementReportStockForPurchaseGetAction = (rc, user_id) => async (dispatch) => {
    dispatch(PurchaseRequirementStockForPurchaseReportGet());
    const response = await AxiosClient.get(`${process.env.REACT_APP_PURCHASEREQUIREMENT}/report/stockforpurchase/${user_id}/${rc}`)
        .catch(error => {
            dispatch(PurchaseRequirementStockForPurchaseReportGetError(error));
            return {
                ok: false,
                error
            };
        });

    return response?.data;
}

export const PurchaseRequirementStockForPurchaseReportGet = () => ({
    type: PURCHASEREQUIREMENT_REPORT_STOCK_FOR_PURCHASE_GET
});

export const PurchaseRequirementStockForPurchaseReportGetSuccess = (record) => {
    console.log('capa 5');
    return{
        type: PURCHASEREQUIREMENT_REPORT_STOCK_FOR_PURCHASE_GET_SUCCESS,
        payload: record
    };
 
};

export const PurchaseRequirementStockForPurchaseReportGetError = (error) => ({
    type: PURCHASEREQUIREMENT_REPORT_STOCK_FOR_PURCHASE_GET_ERROR,
    payload: error
});