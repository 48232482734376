import {
     ITEM_INV_ADJUSTMENT_ADD_NEW, 
     ITEM_INV_ADJUSTMENT_EDIT_ONE, 
     ITEM_INV_ADJUSTMENT_SET_LIST, 
     ITEM_INV_ADJUSTMENT_SET_ONE ,
    } from "../types";


const initialState = {
    iteminventoryadjustments: [],
    iteminventoryadjustment: {}
}

export default function( state = initialState, action ) {
    switch (action.type) {
        case ITEM_INV_ADJUSTMENT_SET_LIST:
            return {
                ...state,
                iteminventoryadjustments: action.payload
            };
        
        case ITEM_INV_ADJUSTMENT_SET_ONE:
            return {
                ...state,
                iteminventoryadjustment: action.payload
            }

        case ITEM_INV_ADJUSTMENT_ADD_NEW:
            return {
                ...state,
                iteminventoryadjustments: [ ...state.iteminventoryadjustments, action.payload ],
                iteminventoryadjustment: action.payload
            }
        
        case ITEM_INV_ADJUSTMENT_EDIT_ONE:
            return {
                ...state,
                iteminventoryadjustments: state.iteminventoryadjustments
                    .map( iteminventoryadjustment => ( iteminventoryadjustment.id === action.payload.id ? action.payload : iteminventoryadjustment ) ),
                iteminventoryadjustment: action.payload
            }
    
        default:
            return state;
    }
}