import {
    ATA_LIST,
    ATA_LIST_SUCCESS,
    ATA_LIST_ERROR,
    ATA_DDLIST,
    ATA_DDLIST_SUCCESS,
    ATA_DDLIST_ERROR,
    ATA_GET,
    ATA_GET_SUCCESS,
    ATA_GET_ERROR,
    ATA_NEW,
    ATA_NEW_SUCCESS,
    ATA_NEW_ERROR,
    ATA_NEW_RECORD,
    ATA_EDIT,
    ATA_EDIT_SUCCESS,
    ATA_EDIT_ERROR,
} from '../types';

import AxiosClient from '../../config/AxiosClient';
require('dotenv').config();

// Función Principal para obtener la Lista de ATAS
export const AtaListAction = () => async (dispatch) => {
    try {
        dispatch(AtaList());

        const response = await AxiosClient.get(`${process.env.REACT_APP_ATA}/all`).catch(
            (error) => {
                dispatch(AtaListError(error));
            },
        );
        dispatch(AtaListSuccess(response.data.result));
    } catch (e) {
        console.error(e);
    }
};

export const AtaList = () => ({
    type: ATA_LIST,
});

export const AtaListSuccess = (atas) => ({
    type: ATA_LIST_SUCCESS,
    payload: atas,
});

export const AtaListError = (error) => ({
    type: ATA_LIST_ERROR,
    payload: error,
});

// Función Principal para obtener los ATAS en forma de DDList
export const AtaDDListAction = () => async (dispatch) => {
    dispatch(AtaDDList());
    await AxiosClient.get(`${process.env.REACT_APP_ATA}/ddlist`)
        .then((response) => {
            dispatch(AtaDDListSuccess(response.data.result));
        })
        .catch((error) => {
            dispatch(AtaDDListError(error));
        });
};

export const AtaDDList = () => ({
    type: ATA_DDLIST,
});

export const AtaDDListSuccess = (records) => ({
    type: ATA_DDLIST_SUCCESS,
    payload: records,
});

export const AtaDDListError = (error) => ({
    type: ATA_DDLIST_ERROR,
    payload: error,
});

// Función Principal para obtener un Ata
export const AtaGetAction = (id) => async (dispatch) => {
    try {
        dispatch(AtaGet());
        const response = await AxiosClient.get(`${process.env.REACT_APP_ATA}/${id}`).catch(
            (error) => {
                dispatch(AtaGetError(error));
            },
        );
        dispatch(AtaGetSuccess(response.data.result));
    } catch (e) {
        console.error(e);
    }
};

export const AtaGet = () => ({
    type: ATA_GET,
});

export const AtaGetSuccess = (record) => ({
    type: ATA_GET_SUCCESS,
    payload: record,
});

export const AtaGetError = (error) => ({
    type: ATA_GET_ERROR,
    payload: error,
});

// Main function for to create ATA info
export const AtaNewAction = (ata) => async (dispatch) => {
    dispatch(AtaNew());

    AxiosClient.post(process.env.REACT_APP_ATA, ata)
        .then((resultado) => {
            if (resultado.status === 201) {
                dispatch(AtaNewSuccess(ata));
            }
        })
        .catch((error) => {
            dispatch(AtaNewError());
        });
};

export const AtaNewRecord = () => ({
    type: ATA_NEW_RECORD,
});

// Función Principal para crear y editar registros de Ata
export const AtaSaveAction = (record, isNew) => async (dispatch) => {
    if (!isNew) {
        dispatch(AtaEdit());
    } else {
        dispatch(AtaNew());
    }
    if (isNew) {
        await AxiosClient.post(`${process.env.REACT_APP_ATA}`, record)
            .then((response) => {
                record.id = response.data.result.id;
                //dispatch( AirplaneNewSuccess(record));
                console.debug(response);
                dispatch(AtaNewSuccess(response.data.result));
            })
            .catch((error) => {
                console.error(error);
                dispatch(AtaNewError(error));
            });
    } else {
        await AxiosClient.put(`${process.env.REACT_APP_ATA}/${record.id}`, record)
            .then((response) => {
                console.debug(response);
                dispatch(AtaEditSuccess(record.data.result));
            })
            .catch((error) => {
                console.error(error);
                dispatch(AtaEditError(error));
            });
    }
};

export const AtaNew = () => ({
    type: ATA_NEW,
});

export const AtaNewSuccess = (record) => ({
    type: ATA_NEW_SUCCESS,
    payload: record,
});

export const AtaNewError = (error) => ({
    type: ATA_NEW_ERROR,
    payload: error,
});

export const AtaEdit = () => ({
    type: ATA_EDIT,
});

export const AtaEditSuccess = (record) => ({
    type: ATA_EDIT_SUCCESS,
    payload: record,
});

export const AtaEditError = (error) => ({
    type: ATA_EDIT_ERROR,
    payload: error,
});
