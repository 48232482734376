import {
    USERSECURITYQUESTION_LIST,
    USERSECURITYQUESTION_LIST_SUCCESS,
    USERSECURITYQUESTION_LIST_ERROR,
    USERSECURITYQUESTION_DDLIST,
    USERSECURITYQUESTION_DDLIST_SUCCESS,
    USERSECURITYQUESTION_DDLIST_ERROR,
    USERSECURITYQUESTION_GET,
    USERSECURITYQUESTION_GET_SUCCESS,
    USERSECURITYQUESTION_GET_ERROR,
    USERSECURITYQUESTION_NEW,
    USERSECURITYQUESTION_NEW_SUCCESS,
    USERSECURITYQUESTION_NEW_ERROR,
    USERSECURITYQUESTION_NEW_RECORD,
    USERSECURITYQUESTION_EDIT,
    USERSECURITYQUESTION_EDIT_SUCCESS,
    USERSECURITYQUESTION_EDIT_ERROR,
    USERSECURITYQUESTION_DELETE,
    USERSECURITYQUESTION_DELETE_SUCCESS,
    USERSECURITYQUESTION_DELETE_ERROR,
    USERSECURITYQUESTION_RENDER
} from '../types';

import AxiosClient from '../../config/AxiosClient';
require('dotenv').config();

// Función Principal para obtener lista de Preguntas de Seguridad por Usuario
export function UserSecurityQuestionListAction(user_id){   
    return (dispatch =>{
        dispatch(UserSecurityQuestionList());
        AxiosClient.get(`${process.env.REACT_APP_USERSECURITYQUESTION}/list/${user_id}`)      
            .then((response)=> {
                dispatch( UserSecurityQuestionListSuccess(response.data.result));
            })
            .catch(error=> {                
                dispatch( UserSecurityQuestionListError(error) );
            });
    });
}

export const UserSecurityQuestionList = () => ({
    type: USERSECURITYQUESTION_LIST
});

export const UserSecurityQuestionListSuccess = (records) => ({
    type: USERSECURITYQUESTION_LIST_SUCCESS,
    payload: records
});

export const UserSecurityQuestionListError = (error) => ({
    type: USERSECURITYQUESTION_LIST_ERROR,
    payload: error
});

// // Función Principal para obtener pregunta de seguridad en forma de Dropdown
// export function UserSecurityQuestionDDListAction(entity_id){    
//     return (dispatch =>{
//         dispatch(UserSecurityQuestionDDList());
//         AxiosClient.get(`${process.env.REACT_APP_USERSECURITYQUESTION}/ddlist/${entity_id}`)
//             .then((response)=> {
//                 dispatch( UserSecurityQuestionDDListSuccess(response.data.result));
//             })
//             .catch(error=> {
//                 dispatch( UserSecurityQuestionDDListError(error) );
//             });
//     });
// }

// export const UserSecurityQuestionDDList = () => ({
//     type: USERSECURITYQUESTION_DDLIST
// });

// export const UserSecurityQuestionDDListSuccess = (records) => ({
//     type: USERSECURITYQUESTION_DDLIST_SUCCESS,
//     payload: records
// });

// export const UserSecurityQuestionDDListError = (error) => ({
//     type: USERSECURITYQUESTION_DDLIST_ERROR,
//     payload: error
// });

// Función Principal para obtener una Pregunta de Seguridad segun su ID
export function UserSecurityQuestionGetAction(id){
    return (dispatch =>{
        dispatch(UserSecurityQuestionGet());
        AxiosClient.get(`${process.env.REACT_APP_USERSECURITYQUESTION}/${id}`)
            .then((response)=> {
                dispatch( UserSecurityQuestionGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( UserSecurityQuestionGetError(error) );
            });
    });
}

export const UserSecurityQuestionGet = () => ({
    type: USERSECURITYQUESTION_GET
});

export const UserSecurityQuestionGetSuccess = (record) => ({
    type: USERSECURITYQUESTION_GET_SUCCESS,
    payload: record
});

export const UserSecurityQuestionGetError = (error) => ({
    type: USERSECURITYQUESTION_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Pregunta de Seguridad cuando se va crear un registro nuevo
export function UserSecurityQuestionNewAction(idItemMaster, id){       
    return (dispatch =>{          
            dispatch(UserSecurityQuestionNewRecord(idItemMaster,id));
    });
}

export const UserSecurityQuestionNewRecord = () => ({
    type: USERSECURITYQUESTION_NEW_RECORD
});

// Función Principal para crear y editar registros de Pregunta de Seguridad
export function UserSecurityQuestionSaveAction(record, isNew){   
    return (dispatch =>{
        if(!isNew) {
            dispatch(UserSecurityQuestionEdit());
        } else{
            dispatch(UserSecurityQuestionNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_USERSECURITYQUESTION}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( UserSecurityQuestionNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( UserSecurityQuestionNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_USERSECURITYQUESTION}/${record.id}`, record)
                .then((response)=> {
                    dispatch( UserSecurityQuestionEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( UserSecurityQuestionEditError(true) );
                });
        }
    });
}

export const UserSecurityQuestionNew = () => ({
    type: USERSECURITYQUESTION_NEW
});

export const UserSecurityQuestionNewSuccess = (record) => ({
    type: USERSECURITYQUESTION_NEW_SUCCESS,
    payload: record
});

export const UserSecurityQuestionNewError = (error) => ({
    type: USERSECURITYQUESTION_NEW_ERROR,
    payload: error
});

export const UserSecurityQuestionEdit = () => ({
    type: USERSECURITYQUESTION_EDIT
});

export const UserSecurityQuestionEditSuccess = (record) => ({
    type: USERSECURITYQUESTION_EDIT_SUCCESS,
    payload: record
});

export const UserSecurityQuestionEditError = (error) => ({
    type: USERSECURITYQUESTION_EDIT_ERROR,
    payload: error
});

// Función Principal para Eliminar una Pregunta de Seguridad
export function UserSecurityQuestionDeleteAction(id){
    return (dispatch =>{
        dispatch(UserSecurityQuestionDelete());
        AxiosClient.delete(`${process.env.REACT_APP_USERSECURITYQUESTION}/${id}`)
            .then((response)=> {
                dispatch( UserSecurityQuestionDeleteSuccess(id));
            })
            .catch(error=> {
                dispatch( UserSecurityQuestionDeleteError(error) );
            });
    });
}

export const UserSecurityQuestionDelete = () => ({
    type: USERSECURITYQUESTION_DELETE
});

export const UserSecurityQuestionDeleteSuccess = (id) => ({
    type: USERSECURITYQUESTION_DELETE_SUCCESS,
    payload: id
});

export const UserSecurityQuestionDeleteError = (error) => ({
    type: USERSECURITYQUESTION_DELETE_ERROR,
    payload: error
});

export const UserSecurityQuestionRender = (payload) =>(
    
    {
    type: USERSECURITYQUESTION_RENDER,
    payload: payload
})

export function UserSecurityQuestionRenderAction(payload){
    return (dispatch =>{
        dispatch(UserSecurityQuestionRender(payload));
    });
}