import { BaseService } from './BaseService';

const service = new BaseService('airplanecomponent');

export default {
    getListById(id) {
        return service.get(`list/${id}`); 
    }, 
    getOneById( id ) {
        return service.get(`${id}`);
    },
    create( airplaneComponent ) {
        return service.post(``, airplaneComponent);
    },
    update( airplaneComponent ) {
        return service.put(`${airplaneComponent.id}`, airplaneComponent);
    },
    delete(airplaneComponent){
        return service.post(`delete`, airplaneComponent );
    }
};