import {
     MATERIAL_BETWEEN_WAREHOUSE_ADD_NEW, 
     MATERIAL_BETWEEN_WAREHOUSE_EDIT_ONE, 
     MATERIAL_BETWEEN_WAREHOUSE_SET_LIST, 
     MATERIAL_BETWEEN_WAREHOUSE_SET_ONE ,
    } from "../types";


const initialState = {
    materialbetweenwarehouselist: [],
    materialbetweenwarehouse: {}
}

export default function( state = initialState, action ) {
    switch (action.type) {
        case MATERIAL_BETWEEN_WAREHOUSE_SET_LIST:
            return {
                ...state,
                materialbetweenwarehouselist: action.payload
            };
        
        case MATERIAL_BETWEEN_WAREHOUSE_SET_ONE:
            return {
                ...state,
                materialbetweenwarehouse: action.payload
            }

        case MATERIAL_BETWEEN_WAREHOUSE_ADD_NEW:
            return {
                ...state,
                materialbetweenwarehouselist: [ ...state.materialbetweenwarehouselist, action.payload ],
                materialbetweenwarehouse: action.payload
            }
        
        case MATERIAL_BETWEEN_WAREHOUSE_EDIT_ONE:
            return {
                ...state,
                materialbetweenwarehouselist: state.materialbetweenwarehouselist
                    .map( materialbetweenwarehouse => ( materialbetweenwarehouse.id === action.payload.id ? action.payload : materialbetweenwarehouse ) ),
                materialbetweenwarehouse: action.payload
            }
    
        default:
            return state;
    }
}