import {
    PURCHASEORDER_LIST,
    PURCHASEORDER_LIST_SUCCESS,
    PURCHASEORDER_LIST_ERROR,
    PURCHASEORDER_GET,
    PURCHASEORDER_GET_SUCCESS,
    PURCHASEORDER_GET_ERROR,
    PURCHASEORDER_NEW,
    PURCHASEORDER_NEW_SUCCESS,
    PURCHASEORDER_NEW_ERROR,
    PURCHASEORDER_EDIT,
    PURCHASEORDER_EDIT_SUCCESS,
    PURCHASEORDER_EDIT_ERROR,
    PURCHASEORDER_CANCEL,
    PURCHASEORDER_CANCEL_SUCCESS,
    PURCHASEORDER_CANCEL_ERROR,
    PURCHASEORDER_CLEAN
} from '../types';
import service from '../../services/PurchaseOrderService';

require('dotenv').config();

// Función Principal para obtener lista de Unidades de Medida
export const PurchaseOrderListAction = () => 
    dispatch => {
        dispatch(PurchaseOrderList());
        service.findAll()
            .then((response) => {
                dispatch(PurchaseOrderListSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(PurchaseOrderListError(error));
            });

    }

export const PurchaseOrderList = () => ({
    type: PURCHASEORDER_LIST
});

export const PurchaseOrderListSuccess = (records) => ({
    type: PURCHASEORDER_LIST_SUCCESS,
    payload: records
});

export const PurchaseOrderListError = (error) => ({
    type: PURCHASEORDER_LIST_ERROR,
    payload: error
});

// Función Principal para obtener una Unidad de Medida por ID
export function PurchaseOrderGetAction( id ){
    return (dispatch =>{
        dispatch(PurchaseOrderGet());
        service.getOneById( id )
            .then((response)=> {
                dispatch( PurchaseOrderGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( PurchaseOrderGetError(error) );
            });
    });
}

export const PurchaseOrderGet = () => ({
    type: PURCHASEORDER_GET
});

export const PurchaseOrderGetSuccess = (record) => ({
    type: PURCHASEORDER_GET_SUCCESS,
    payload: record
});

export const PurchaseOrderGetError = (error) => ({
    type: PURCHASEORDER_GET_ERROR,
    payload: error
});

// Función Principal para crear y editar registros de Unidades de Medida
export const PurchaseOrderCreateAction = ( record ) => async dispatch => {
    try {
        dispatch(PurchaseOrderNew());
        const { data } = await service.create( record );
        dispatch( PurchaseOrderNewSuccess( data.result ) );
        return data;
    } catch (error) {
        dispatch( PurchaseOrderNewError( error ) );
        throw error;
    }
}

export const PurchaseOrderNew = () => ({
    type: PURCHASEORDER_NEW
});

export const PurchaseOrderNewSuccess = (record) => ({
    type: PURCHASEORDER_NEW_SUCCESS,
    payload: record
});

export const PurchaseOrderNewError = (error) => ({
    type: PURCHASEORDER_NEW_ERROR,
    payload: error
});

export const PurchaseOrderUpdateAction = ( record ) => async dispatch => {
    try {
        dispatch(PurchaseOrderEdit());
        const { data } = await service.update( record );
        dispatch( PurchaseOrderEditSuccess( data.result ) );
        return data;
    } catch (error) {
        dispatch( PurchaseOrderEditError( error ) );
        throw error;
    }
}

export const PurchaseOrderEdit = () => ({
    type: PURCHASEORDER_EDIT
});

export const PurchaseOrderEditSuccess = (record) => ({
    type: PURCHASEORDER_EDIT_SUCCESS,
    payload: record
});

export const PurchaseOrderEditError = (error) => ({
    type: PURCHASEORDER_EDIT_ERROR,
    payload: error
});

export const PurchaseOrderCancelOrderAction = ( id ) => async dispatch => {
    try {
        dispatch(PurchaseOrderCancelOrder());
        const { data } = await service.cancelOrderById( id );
        dispatch( PurchaseOrderCancelOrderSuccess( data.result ) );
        return data;
    } catch (error) {
        dispatch( PurchaseOrderCancelOrderError( error ) );
        return error;
    }
}

export const PurchaseOrderCancelOrder = () => ({
    type: PURCHASEORDER_CANCEL
});

export const PurchaseOrderCancelOrderSuccess = ( record ) => ({
    type: PURCHASEORDER_CANCEL_SUCCESS,
    payload: record
});

export const PurchaseOrderCancelOrderError = ( error ) => ({
    type: PURCHASEORDER_CANCEL_ERROR,
    payload: error
});