import {
    QUOTEMANAGEMENT_CLEAN,
    QUOTEMANAGEMENT_EDIT,
    QUOTEMANAGEMENT_EDIT_ERROR,
    QUOTEMANAGEMENT_EDIT_SUCCESS,
    QUOTEMANAGEMENT_GET,
    QUOTEMANAGEMENT_GET_ERROR,
    QUOTEMANAGEMENT_GET_SUCCESS,
    QUOTEMANAGEMENT_LIST,
    QUOTEMANAGEMENT_LIST_ERROR,
    QUOTEMANAGEMENT_LIST_SUCCESS,
    QUOTEMANAGEMENT_NEW,
    QUOTEMANAGEMENT_NEW_ERROR,
    QUOTEMANAGEMENT_NEW_SUCCESS,
    QUOTEMANAGEMENT_DELETE,
    QUOTEMANAGEMENT_DELETE_SUCCESS,
    QUOTEMANAGEMENT_DELETE_ERROR,
    QUOTEMANAGEMENT_DD_LIST,
    QUOTEMANAGEMENT_DD_LIST_SUCCESS,
    QUOTEMANAGEMENT_DD_LIST_ERROR
} from "../types";

const initialState = {
    quotemanagementlist: [],
    quotemanagement: {},
    quotemanagementddlist: [],
    loading: false,
    error: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case QUOTEMANAGEMENT_LIST:
            return {
                ...state,
                loading: true,
                quotemanagementlist: [],
            };

        case QUOTEMANAGEMENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                quotemanagementlist: action.payload,
                quotemanagement: {}
            };

        case QUOTEMANAGEMENT_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                quotemanagementlist: [],
                quotemanagement: {}
            };

        case QUOTEMANAGEMENT_GET:
            return {
                ...state,
                loading: true,
                error: false,
                quotemanagement: {}
            }

        case QUOTEMANAGEMENT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                quotemanagement: action.payload
            };

        case QUOTEMANAGEMENT_GET_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                quotemanagementlist: [],
                quotemanagement: {}
            };

        case QUOTEMANAGEMENT_CLEAN:
            return {
                ...state,
                quotemanagementlist: [],
                quotemanagement: {}
            };

        case QUOTEMANAGEMENT_NEW:
            return {
                ...state,
                loading: true,
                error: false
            };

        case QUOTEMANAGEMENT_NEW_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                quotemanagementlist: [...state.quotemanagementlist, action.payload],
                quotemanagement: action.payload
            };

        case QUOTEMANAGEMENT_NEW_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                quotemanagementlist: [],
                quotemanagement: {}
            }

        case QUOTEMANAGEMENT_EDIT:
            return {
                ...state,
                loading: true,
                error: false
            };

        case QUOTEMANAGEMENT_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                quotemanagement: action.payload,
                quotemanagementlist: state.quotemanagementlist.map(quotemanagement => (quotemanagement.id === action.payload.id ? action.payload : quotemanagement))
            };

        case QUOTEMANAGEMENT_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                quotemanagementlist: [],
                quotemanagement: {}
            };

        case QUOTEMANAGEMENT_DELETE:
            return {
                ...state,
                loading: true,
                error: false
            }

        case QUOTEMANAGEMENT_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                quotemanagementlist: state.quotemanagementlist.filter(quotemanagement => quotemanagement.id !== action.payload)
            }

        case QUOTEMANAGEMENT_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                error: true
            }

        case QUOTEMANAGEMENT_DD_LIST:
            return {
                ...state,
                quotemanagementddlist: [],
            };

        case QUOTEMANAGEMENT_DD_LIST_SUCCESS:
            return {
                ...state,
                quotemanagementddlist: action.payload
            };

        case QUOTEMANAGEMENT_DD_LIST_ERROR:
            return {
                ...state,
                quotemanagementddlist: []
            };

        default:
            return state;
    }
};