import {
    EMPLOYEE_LIST,
    EMPLOYEE_LIST_SUCCESS,
    EMPLOYEE_LIST_ERROR,
    EMPLOYEE_DDLIST,
    EMPLOYEE_DDLIST_SUCCESS,
    EMPLOYEE_DDLIST_ERROR,
    EMPLOYEE_GET,
    EMPLOYEE_GET_SUCCESS,
    EMPLOYEE_GET_ERROR,
    EMPLOYEE_GET_BY_USER_ID,
    EMPLOYEE_GET_BY_USER_ID_SUCCESS,
    EMPLOYEE_GET_BY_USER_ID_ERROR,
    EMPLOYEE_NEW,
    EMPLOYEE_NEW_SUCCESS,
    EMPLOYEE_NEW_ERROR,
    EMPLOYEE_NEW_RECORD,
    EMPLOYEE_EDIT,
    EMPLOYEE_EDIT_SUCCESS,
    EMPLOYEE_EDIT_ERROR,
    EMPLOYEE_CHARGE_CODE_DDLIST,
    EMPLOYEE_CHARGE_CODE_DDLIST_SUCCESS,
    EMPLOYEE_CHARGE_CODE_DDLIST_ERROR,
} from '../types';

const initialState = {
    employees: [],
    employee: {},
    employeeName:{},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case EMPLOYEE_LIST:
            return {
                ...state,
                loading: true,
                employee: {},
                employeeName: {}
            }
        case EMPLOYEE_LIST_SUCCESS:
            return {
                ...state,
                employees: action.payload,
                loading: false,
                error: false,
                employee: {},
                employeeName: {}
            }
        case EMPLOYEE_LIST_ERROR:
            return {
                ...state,
                employees: [],
                loading: false,
                error: true,
                employee: {},
                employeeName: {}
            }
        case EMPLOYEE_DDLIST:
            return {
                ...state,
                loading: true,
                employee: {},
                employeeName: {}
            }
        case EMPLOYEE_DDLIST_SUCCESS:
            return {
                ...state,
                employees: action.payload,
                loading: false,
                error: false                
            }
        case EMPLOYEE_DDLIST_ERROR:
            return {
                ...state,
                employees: [],
                loading: false,
                error: true,
                employee: {},
                employeeName: {}
            }
        case EMPLOYEE_GET:
            return {
                ...state,
                loading: true
            }
        case EMPLOYEE_GET_SUCCESS:
            return {
                ...state,
                employee: action.payload,
                loading: false,
                error: false
            }
        case EMPLOYEE_GET_ERROR:
            return {
                ...state,
                error: true,
                employee: {},
                employeeName: {}
            }
        case EMPLOYEE_GET_BY_USER_ID:
            return {
                ...state,
                loading: true
            }
        case EMPLOYEE_GET_BY_USER_ID_SUCCESS:
            return {
                ...state,
                employeeName: action.payload,
                loading: false,
                error: false
            }
        case EMPLOYEE_GET_BY_USER_ID_ERROR:
            return {
                ...state,
                error: true,
                employee: {},
                employeeName: {}
            }
        case EMPLOYEE_NEW:
            return {
                ...state
            }
        case EMPLOYEE_NEW_SUCCESS:
            return {
                ...state,
                employees: [...state.employees, action.payload],
                employee: {},
                employeeName: {},
                error: false
            }
        case EMPLOYEE_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case EMPLOYEE_NEW_RECORD:
            return {
                ...state,
                employee: {},
                employeeName: {},
                error: false
            }
        case EMPLOYEE_EDIT:
            return {
                ...state
            }
        case EMPLOYEE_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                employee: action.payload,
                employees: state.employees.map(employee => employee.id === action.payload.id ? employee = action.payload : employee),
            }
        case EMPLOYEE_EDIT_ERROR:
            return {
                ...state,
                error: false
            }
            case EMPLOYEE_CHARGE_CODE_DDLIST:
                return {
                    ...state,
                    loading: true,
                    employee: {},
                    employeeName: {}
                }
            case EMPLOYEE_CHARGE_CODE_DDLIST_SUCCESS:
                return {
                    ...state,
                    employees: action.payload,
                    loading: false,
                    error: false,
                    employee: {},
                    employeeName: {}
                }
            case EMPLOYEE_CHARGE_CODE_DDLIST_ERROR:
                return {
                    ...state,
                    employees: [],
                    loading: false,
                    error: true,
                    employee: {},
                    employeeName: {}
                }
        default:
            return state;
    }
}
