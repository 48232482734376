import {
    UOM_LIST,
    UOM_LIST_SUCCESS,
    UOM_LIST_ERROR,
    UOM_DDLIST,
    UOM_DDLIST_SUCCESS,
    UOM_DDLIST_ERROR,
    UOM_DDTYPEUNITLIST,
    UOM_DDTYPEUNITLIST_SUCCESS,
    UOM_DDTYPEUNITLIST_ERROR,
    UOM_GET,
    UOM_GET_SUCCESS,
    UOM_GET_ERROR,
    UOM_NEW,
    UOM_NEW_SUCCESS,
    UOM_NEW_ERROR,
    UOM_NEW_RECORD,
    UOM_EDIT,
    UOM_EDIT_SUCCESS,
    UOM_EDIT_ERROR
} from '../types';

const initialState = {
    uoms: [],
    uomDDList: [],
    uomDDUnitTypeList: [],
    uom: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case UOM_LIST:
            return {
                ...state,
                loading: true,
                uom: {}
            }
        case UOM_LIST_SUCCESS:
            return {
                ...state,
                uoms: action.payload,
                loading: false,
                error: false,
                uom: {}
            }
        case UOM_LIST_ERROR:
            return {
                ...state,
                uoms: [],
                loading: false,
                error: true,
                uom: {}
            }
        case UOM_DDLIST:
            return {
                ...state,
                loading: true,
                uom: {}
            }
        case UOM_DDLIST_SUCCESS:
            return {
                ...state,
                uomDDList: action.payload,
                loading: false,
                error: false
            }
        case UOM_DDLIST_ERROR:
            return {
                ...state,
                uomDDList: [],
                loading: false,
                error: true
            }
        case UOM_DDTYPEUNITLIST:
            return {
                ...state,
                loading: true,
                uom: {}
            }
        case UOM_DDTYPEUNITLIST_SUCCESS:
            return {
                ...state,
                uomDDUnitTypeList: action.payload,
                loading: false,
                error: false,
                uom: {}
            }
        case UOM_DDTYPEUNITLIST_ERROR:
            return {
                ...state,
                uomDDUnitTypeList: [],
                loading: false,
                error: true,
                uom: {}
            }
        case UOM_GET:
            return {
                ...state,
                loading: true
            }
        case UOM_GET_SUCCESS:
            return {
                ...state,
                uom: action.payload,
                loading: false,
                error: false
            }
        case UOM_GET_ERROR:
            return {
                ...state,
                error: true,
                uom: {}
            }
        case UOM_NEW:
            return {
                ...state
            }
        case UOM_NEW_SUCCESS:
            return {
                ...state,
                uoms: [...state.uoms, action.payload],
                uom: {},
                error: false
            }
        case UOM_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case UOM_NEW_RECORD:
            return {
                ...state,
                uom: {},
                error: false
            }
        case UOM_EDIT:
            return {
                ...state
            }
        case UOM_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                uom: action.payload,
                uoms: state.uoms.map(uom => uom.id === action.payload.id ? uom = action.payload : uom),
            }
        case UOM_EDIT_ERROR:
            return {
                ...state,
                error: false
            }
        default:
            return state;
    }
}
