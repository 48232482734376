import {
    CURRENCY_LIST,
    CURRENCY_LIST_SUCCESS,
    CURRENCY_LIST_ERROR,
    CURRENCY_DDLIST,
    CURRENCY_DDLIST_SUCCESS,
    CURRENCY_DDLIST_ERROR,
    CURRENCY_GET,
    CURRENCY_GET_SUCCESS,
    CURRENCY_GET_ERROR,
    CURRENCY_NEW,
    CURRENCY_NEW_SUCCESS,
    CURRENCY_NEW_ERROR,
    CURRENCY_NEW_RECORD,
    CURRENCY_EDIT,
    CURRENCY_EDIT_SUCCESS,
    CURRENCY_EDIT_ERROR
} from '../types';

const initialState = {
    currencies: [],
    currencyDDList: [],
    currency: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case CURRENCY_LIST:
            return {
                ...state,
                loading: true,
                currency: {}
            }
        case CURRENCY_LIST_SUCCESS:
            return {
                ...state,
                currencies: action.payload,
                loading: false,
                error: false,
                currency: {}
            }
        case CURRENCY_LIST_ERROR:
            return {
                ...state,
                currencies: [],
                loading: false,
                error: true,
                currency: {}
            }
        case CURRENCY_DDLIST:
            return {
                ...state,
                loading: true,
                currency: {}
            }
        case CURRENCY_DDLIST_SUCCESS:
            return {
                ...state,
                currencyDDList: action.payload,                
                loading: false,
                error: false                
            }
        case CURRENCY_DDLIST_ERROR:
            return {
                ...state,
                currencyDDList: [],
                loading: false,
                error: true                
            }
        case CURRENCY_GET:
            return {
                ...state,
                loading: true
            }
        case CURRENCY_GET_SUCCESS:
            return {
                ...state,
                currency: action.payload,
                loading: false,
                error: false
            }
        case CURRENCY_GET_ERROR:
            return {
                ...state,
                error: true,
                currency: {}
            }
        case CURRENCY_NEW:
            return {
                ...state               
            }
        case CURRENCY_NEW_SUCCESS:           
            return {
                ...state,
                currencies: [...state.currencies, action.payload],
                currency: {},
                loading: false,
                error: false
            }
        case CURRENCY_NEW_ERROR:           
            return {
                ...state,
                // currency: action.payload,
                // loading: false,
                error: true
            }
        case CURRENCY_NEW_RECORD:
            return {
                ...state,
                currency: {},
                error: false
            }
        case CURRENCY_EDIT:
            return {
                ...state
            }
        case CURRENCY_EDIT_SUCCESS:            
            return {
                ...state,
                error: false,
                currency: action.payload,
                currencies: state.currencies.map(currency => currency.id === action.payload.id ? currency = action.payload : currency),
            }
        case CURRENCY_EDIT_ERROR:            
            return {
                ...state,
               // currency: action.payload,
                error: false
            }
        default:
            return state;
    }
}
