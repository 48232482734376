import {
    ATA_LIST,
    ATA_LIST_SUCCESS,
    ATA_LIST_ERROR,
    ATA_DDLIST,
    ATA_DDLIST_SUCCESS,
    ATA_DDLIST_ERROR,
    ATA_GET,
    ATA_GET_SUCCESS,
    ATA_GET_ERROR,  
    ATA_NEW,
    ATA_NEW_SUCCESS,
    ATA_NEW_ERROR,
    ATA_EDIT,
    ATA_EDIT_SUCCESS,
    ATA_EDIT_ERROR
} from '../types';

const initialState = {
    atas: [],
    ata: {},
    ataList:{},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    //console.log('switch airplane', {type: action.type, payload: action.payload});
    switch(action.type){
        case ATA_LIST:
            return {
                ...state,
                loading: true,
                ata: {},               
            }
        case ATA_LIST_SUCCESS:
            
            return {
                ...state,
                atas: action.payload,
                loading: false,
                error: false,
                ata: {}
            }
        case ATA_LIST_ERROR:
            return {
                ...state,
                atas: [],
                loading: false,
                error: true,
                ata: {}
            }
        case ATA_DDLIST:
            return {
                ...state,
                loading: true,
                ata: {},
                ataList: []
            }
        case ATA_DDLIST_SUCCESS:
            return {
                ...state,
                ataList: action.payload,
                loading: false,
                error: false
            }
        case ATA_DDLIST_ERROR:
            return {
                ...state,
                ataList: [],
                loading: false,
                error: true,
                ata: {}
            }
        case ATA_GET:
            return {
                ...state,
                loading: true
            }
        case ATA_GET_SUCCESS:
            return {
                ...state,
                ata: action.payload,
                loading: false,
                error: false
            }
        case ATA_GET_ERROR:
            return {
                ...state,
                error: true,
                ata: {}
            }       
        case ATA_NEW:
            return {
                ...state
            }
        case ATA_NEW_SUCCESS:
            return {
                ...state,
                error: false,
                atas: [...state.atas, action.payload]
            }
        case ATA_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case ATA_EDIT:
            return {
                ...state,
            }
        case ATA_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                ata: action.payload,
                atas: state.atas.map(ata => ata.id === action.payload.id ? ata = action.payload : ata),
            }
        case ATA_EDIT_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state;
    }
}