import { BaseService } from './BaseService';

const service = new BaseService('itemmaster');

export default {
    findAll() {
        return service.get('all');
    },
    findAllPageable(page = 1, rowsperpage = 10, search = '') {
        return service.get(`pageable/findall`, { params: { page, rowsperpage, search } });
    }
};
