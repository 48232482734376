import {
    SUBCATEGORY_LIST,
    SUBCATEGORY_LIST_SUCCESS,
    SUBCATEGORY_LIST_ERROR,   
    SUBCATEGORY_BYCATEGORY_LIST,
    SUBCATEGORY_BYCATEGORY_LIST_SUCCESS,
    SUBCATEGORY_BYCATEGORY_LIST_ERROR,
    SUBCATEGORY_DDLIST,
    SUBCATEGORY_DDLIST_SUCCESS,
    SUBCATEGORY_DDLIST_ERROR,
    SUBCATEGORY_GET,
    SUBCATEGORY_GET_SUCCESS,
    SUBCATEGORY_GET_ERROR,
    SUBCATEGORY_NEW,
    SUBCATEGORY_NEW_SUCCESS,
    SUBCATEGORY_NEW_ERROR,
    SUBCATEGORY_EDIT,
    SUBCATEGORY_EDIT_SUCCESS,
    SUBCATEGORY_EDIT_ERROR
} from '../types';

const initialState = {
    subCategories: [],
    subCategory: {},
    subCategoryList:{},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case SUBCATEGORY_LIST:
            return {
                ...state,
                loading: true,
                subCategory: {},
            }
        case SUBCATEGORY_LIST_SUCCESS:

            return {
                ...state,
                subCategories: action.payload,
                loading: false,
                error: false,
                subCategory: {}
            }
        case SUBCATEGORY_LIST_ERROR:
            return {
                ...state,
                subCategories: [],
                loading: false,
                error: true,
                subCategory: {}
            }

        case SUBCATEGORY_BYCATEGORY_LIST:
            return {
                ...state,
                loading: true,
                subCategory: {},
            }
        case SUBCATEGORY_BYCATEGORY_LIST_SUCCESS:

            return {
                ...state,
                subCategories: action.payload,
                loading: false,
                error: false,
                subCategory: {}
            }
        case SUBCATEGORY_BYCATEGORY_LIST_ERROR:
            return {
                ...state,
                subCategories: [],
                loading: false,
                error: true,
                subCategory: {}
            }
        case SUBCATEGORY_DDLIST:
            return {
                ...state,
                loading: true,
                subCategory: {},
                subCategoryList: []
            }
        case SUBCATEGORY_DDLIST_SUCCESS:
            return {
                ...state,
                subCategoryList: action.payload,
                loading: false,
                error: false
            }
        case SUBCATEGORY_DDLIST_ERROR:
            return {
                ...state,
                CATEGORYList: [],
                loading: false,
                error: true,
                subCategory: {}
            }
        case SUBCATEGORY_GET:
            return {
                ...state,
                loading: true
            }
        case SUBCATEGORY_GET_SUCCESS:
            return {
                ...state,
                subCategory: action.payload,
                loading: false,
                error: false
            }
        case SUBCATEGORY_GET_ERROR:
            return {
                ...state,
                error: true,
                subCategory: {}
            }
        case SUBCATEGORY_NEW:
            return {
                ...state
            }
        case SUBCATEGORY_NEW_SUCCESS:
            return {
                ...state,
                error: false,
                subCategories: [...state.subCategories, action.payload]
            }
        case SUBCATEGORY_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case SUBCATEGORY_EDIT:
            return {
                ...state,
            }
        case SUBCATEGORY_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                subCategory: action.payload,
                subCategories: state.subCategories.map(subCategory => subCategory.id === action.payload.id ? subCategory = action.payload : subCategory),
            }
        case SUBCATEGORY_EDIT_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state;
    }
}