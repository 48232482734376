import {
    USER_ALL_LIST,
    USER_ALL_LIST_SUCCESS,
    USER_ALL_LIST_ERROR,
    USER_LIST,
    USER_LIST_SUCCESS,
    USER_LIST_ERROR,
    USER_DDLIST,
    USER_DDLIST_SUCCESS,
    USER_DDLIST_ERROR,
    USER_DDLIST_FILTER,
    USER_DDLIST_FILTER_SUCCESS,
    USER_DDLIST_FILTER_ERROR,
    USER_GET,
    USER_GET_SUCCESS,
    USER_GET_ERROR,
    USER_NEW,
    USER_NEW_SUCCESS,
    USER_NEW_ERROR,
    USER_NEW_RECORD,
    USER_EDIT,
    USER_EDIT_SUCCESS,
    USER_EDIT_ERROR,
    USER_PASSWORD_EDIT,
    USER_PASSWORD_EDIT_SUCCESS,
    USER_PASSWORD_EDIT_ERROR,
    USER_VALID_PASSWORD,
    USER_VALID_PASSWORD_SUCCESS,
    USER_VALID_PASSWORD_ERROR,
    USER_DDLIST_MATERIALDISPATCH,
    USER_DDLIST_MATERIALDISPATCH_SUCCESS,
    USER_DDLIST_MATERIALDISPATCH_ERROR,
    USER_DDLIST_TMA,
    USER_DDLIST_TMA_SUCCESS,
    USER_DDLIST_TMA_ERROR
} from '../types';

const initialState = {
    users: [],
    userList: [],
    user: {},
    userDDListMaterialDispatch: [],
    userDDListTMA: [],
    isAuthenticated: false,
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case USER_ALL_LIST:
            return {
                ...state,
                loading: true,
                user: {}
            }
        case USER_ALL_LIST_SUCCESS:
            return {
                ...state,
                users: action.payload,
                loading: false,
                error: false,
                user: {}
            }
        case USER_ALL_LIST_ERROR:
            return {
                ...state,
                users: [],
                loading: false,
                error: true,
                user: {}
            }
        case USER_LIST:
            return {
                ...state,
                loading: true,
                user: {}
            }
        case USER_LIST_SUCCESS:
            return {
                ...state,
                users: action.payload,
                loading: false,
                error: false,
                user: {}
            }
        case USER_LIST_ERROR:
            return {
                ...state,
                users: [],
                loading: false,
                error: true,
                user: {}
            }
        case USER_DDLIST:
            return {
                ...state,
                loading: true,
                user: {}
            }
        case USER_DDLIST_SUCCESS:
            return {
                ...state,
                userList: action.payload,
                loading: false,
                error: false,
                user: {}
            }
        case USER_DDLIST_ERROR:
            return {
                ...state,
                userList: [],
                loading: false,
                error: true,
                user: {}
            }
        case USER_DDLIST_MATERIALDISPATCH:
            return {
                ...state,
                loading: true,
                userDDListMaterialDispatch: []
            }
        case USER_DDLIST_MATERIALDISPATCH_SUCCESS:
            return {
                ...state,
                userDDListMaterialDispatch: action.payload,
                loading: false,
                error: false
            }
        case USER_DDLIST_MATERIALDISPATCH_ERROR:
            return {
                ...state,
                userDDListMaterialDispatch: [],
                loading: false,
                error: true
            }
        case USER_DDLIST_TMA:
            return {
                ...state,
                loading: true,
                userDDListTMA: []
            }
        case USER_DDLIST_TMA_SUCCESS:
            return {
                ...state,
                userDDListTMA: action.payload,
                loading: false,
                error: false
            }
        case USER_DDLIST_TMA_ERROR:
            return {
                ...state,
                userDDListTMA: [],
                loading: false,
                error: true
            }
        case USER_DDLIST_FILTER:
            return {
                ...state,
                loading: true,
                user: {}
            }
        case USER_DDLIST_FILTER_SUCCESS:
            switch (action.charge_id) {
                case process.env.REACT_APP_CHARGE_INSPECTOR:
                    return {
                        ...state,
                        inspectorList: action.payload,
                        loading: false,
                        error: false,
                        user: {}
                    }
                case process.env.REACT_APP_CHARGE_TMA:
                    return {
                        ...state,
                        tmaList: action.payload,
                        loading: false,
                        error: false,
                        user: {}
                    }
                default:
                    return state;
            }
            
        case USER_DDLIST_FILTER_ERROR:
            switch (action.charge) {
                case process.env.REACT_APP_CHARGE_INSPECTOR:
                    return {
                        ...state,
                        inspectorList: [],
                        loading: false,
                        error: true,
                        user: {}
                    }
                case process.env.REACT_APP_CHARGE_TMA:
                    return {
                        ...state,
                        tmaList: [],
                        loading: false,
                        error: true,
                        user: {}
                    }
                default:
                    return state;
            }
        case USER_GET:
            return {
                ...state,
                loading: true
            }
        case USER_GET_SUCCESS:
            return {
                ...state,
                user: action.payload,
                loading: false,
                error: false
            }
        case USER_GET_ERROR:
            return {
                ...state,
                error: true,
                user: {}
            }
        case USER_NEW:
            return {
                ...state
            }
        case USER_NEW_SUCCESS:
            return {
                ...state,
                users: [...state.users, action.payload],
                user: {},
                error: false
            }
        case USER_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case USER_NEW_RECORD:
            return {
                ...state,
                user: {},
                error: false
            }
        case USER_EDIT:
            return {
                ...state
            }
        case USER_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                user: action.payload,
                users: state.users.map(user => user.id === action.payload.id ? user = action.payload : user),
            }
        case USER_EDIT_ERROR:
            return {
                ...state,
                error: false
            }

        case USER_PASSWORD_EDIT:
            return {
                ...state
            }
        case USER_PASSWORD_EDIT_SUCCESS:
            return {
                ...state,
                error:  false,
                user:  action.payload,
                users: state.users.map(user => user.id === action.payload.id ? user = action.payload : user),
            }
        case USER_PASSWORD_EDIT_ERROR: 
            return {
                ...state,
                error: false
            }

        case USER_VALID_PASSWORD:
            return {
                ...state,
                isAuthenticated: false,
                uservalid: '',
                userrole: '',
                userid: ''
            }
        case USER_VALID_PASSWORD_SUCCESS:
            return {
                ...state,
                error: false,
                isAuthenticated: true,
                users: []
            }
        case USER_VALID_PASSWORD_ERROR:
            return {
                ...state,
                isAuthenticated: false,
                error: true,
                users: []
            }

        default:
            return state;
    }
}
