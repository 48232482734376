import {
    CHARGE_LIST,
    CHARGE_LIST_SUCCESS,
    CHARGE_LIST_ERROR,
    CHARGE_DDLIST,
    CHARGE_DDLIST_SUCCESS,
    CHARGE_DDLIST_ERROR,
    CHARGE_GET,
    CHARGE_GET_SUCCESS,
    CHARGE_GET_ERROR,
    CHARGE_NEW,
    CHARGE_NEW_SUCCESS,
    CHARGE_NEW_ERROR,
    CHARGE_NEW_RECORD,
    CHARGE_EDIT,
    CHARGE_EDIT_SUCCESS,
    CHARGE_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Charge
export function ChargeListAction(){
    
    return (dispatch =>{
        dispatch(ChargeList());
        AxiosClient.get(`${process.env.REACT_APP_CHARGE}`)
            .then((response)=> {
                dispatch( ChargeListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( ChargeListError(error) );
            });
    });
}

export const ChargeList = () => ({
    type: CHARGE_LIST
});

export const ChargeListSuccess = (records) => ({
    type: CHARGE_LIST_SUCCESS,
    payload: records
});

export const ChargeListError = (error) => ({
    type: CHARGE_LIST_ERROR,
    payload: error
});

// Función Principal para obtener los cargos en forma de DropDown
export function ChargeDDListAction(){
    return (dispatch =>{
        dispatch(ChargeDDList());
        AxiosClient.get(`${process.env.REACT_APP_CHARGE}/ddlist`)
            .then((response)=> {
                dispatch( ChargeDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( ChargeDDListError(error) );
            });
    });
}
export const ChargeDDList = () => ({
    type: CHARGE_DDLIST
});

export const ChargeDDListSuccess = (records) => ({
    type: CHARGE_DDLIST_SUCCESS,
    payload: records
    //id: entity_id
});

export const ChargeDDListError = (error) => ({
    type: CHARGE_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener un Cargo
export function ChargeGetAction(id){
    return (dispatch =>{
        dispatch(ChargeGet());
        AxiosClient.get(`${process.env.REACT_APP_CHARGE}/${id}`)
            .then((response)=> {
                dispatch( ChargeGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( ChargeEditError(error) );
            });
    });
}

export const ChargeGet = () => ({
    type: CHARGE_GET
});

export const ChargeGetSuccess = (record) => ({
    type: CHARGE_GET_SUCCESS,
    payload: record
});

export const ChargeMdGetError = (error) => ({
    type: CHARGE_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Cargo cuando se va crear un registro nuevo
export function ChargeNewAction(){
    return (dispatch =>{
            dispatch(ChargeNewRecord());
    });
}

export const ChargeNewRecord = () => ({
    type: CHARGE_NEW_RECORD
});

// Función Principal para crear y editar registros de Cargos
export function ChargeSaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(ChargeEdit());
        } else{
            dispatch(ChargeNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_CHARGE}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( ChargeNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( ChargeNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_CHARGE}/${record.id}`, record)
                .then((response)=> {
                    dispatch( ChargeEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( ChargeEditError(true) );
                });
        }
    });
}

export const ChargeNew = () => ({
    type: CHARGE_NEW
});

export const ChargeNewSuccess = (record) => ({
    type: CHARGE_NEW_SUCCESS,
    payload: record
});

export const ChargeNewError = (error) => ({
    type: CHARGE_NEW_ERROR,
    payload: error
});

export const ChargeEdit = () => ({
    type: CHARGE_EDIT
});

export const ChargeEditSuccess = (record) => ({
    type: CHARGE_EDIT_SUCCESS,
    payload: record
});

export const ChargeEditError = (error) => ({
    type: CHARGE_EDIT_ERROR,
    payload: error
});

