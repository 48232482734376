import {
    INCOMING_LIST,
    INCOMING_LIST_SUCCESS,
    INCOMING_LIST_ERROR,
    INCOMING_BYBPARTNER_LIST,
    INCOMING_BYBPARTNER_LIST_SUCCESS,
    INCOMING_BYBPARTNER_LIST_ERROR,
    INCOMING_DDLIST,
    INCOMING_DDLIST_SUCCESS,
    INCOMING_DDLIST_ERROR,
    INCOMING_GET,
    INCOMING_GET_SUCCESS,
    INCOMING_GET_ERROR,
    INCOMING_NEW,
    INCOMING_NEW_SUCCESS,
    INCOMING_NEW_ERROR,
    INCOMING_NEW_RECORD,
    INCOMING_EDIT,
    INCOMING_EDIT_SUCCESS,
    INCOMING_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Incoming
export function IncomingListAction(){
    return (dispatch =>{
        dispatch(IncomingList());
        AxiosClient.get(`${process.env.REACT_APP_INCOMING}`)
            .then((response)=> {
                dispatch( IncomingListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( IncomingListError(error) );
            });
    });
}

export const IncomingList = () => ({
    type: INCOMING_LIST
});

export const IncomingListSuccess = (records) => ({
    type: INCOMING_LIST_SUCCESS,
    payload: records
});

export const IncomingListError = (error) => ({
    type: INCOMING_LIST_ERROR,
    payload: error
});

// Función Principal para obtener lista de Incoming de Facturas por BPartner
export function IncomingListByBpartnerAction(id){
    return (dispatch =>{
        dispatch(IncomingByBpartnerList());
        AxiosClient.get(`${process.env.REACT_APP_INCOMING}/list/${id}`)
            .then((response)=> {
                dispatch( IncomingByBpartnerListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( IncomingByBPartnerListError(error) );
            });
    });
}

export const IncomingByBpartnerList = () => ({
    type: INCOMING_BYBPARTNER_LIST
});

export const IncomingByBpartnerListSuccess = (records) => ({
    type: INCOMING_BYBPARTNER_LIST_SUCCESS,
    payload: records
});

export const IncomingByBPartnerListError = (error) => ({
    type: INCOMING_BYBPARTNER_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Master Data por Entidad en forma de DropDown
export function IncomingDDListAction(entity_id){    
    return (dispatch =>{
        dispatch(IncomingDDList());
        AxiosClient.get(`${process.env.REACT_APP_INCOMING}/ddlist`)
            .then((response)=> {
                dispatch( IncomingDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( IncomingDDListError(error) );
            });
    });
}

export const IncomingDDList = () => ({
    type: INCOMING_DDLIST
});

export const IncomingDDListSuccess = (records) => ({
    type: INCOMING_DDLIST_SUCCESS,
    payload: records
});

export const IncomingDDListError = (error) => ({
    type: INCOMING_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener un Incoming
export const IncomingGetAction = (id) => async (dispatch) => {
        dispatch(IncomingGet());
        await AxiosClient.get(`${process.env.REACT_APP_INCOMING}/${id}`)
            .then((response)=> {
                dispatch( IncomingGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( IncomingGetError(error) );
            });
}

export const IncomingGet = () => ({
    type: INCOMING_GET
});

export const IncomingGetSuccess = (record) => ({
    type: INCOMING_GET_SUCCESS,
    payload: record
});

export const IncomingGetError = (error) => ({
    type: INCOMING_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Incoming cuando se va crear un registro nuevo
export function IncomingNewAction(){
    return (dispatch =>{
            dispatch(IncomingNewRecord());
    });
}

export const IncomingNewRecord = () => ({
    type: INCOMING_NEW_RECORD
});

// Función Principal para crear y editar registros de Incoming
export function IncomingSaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(IncomingEdit());
        } else{
            dispatch(IncomingNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_INCOMING}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( IncomingNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( IncomingNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_INCOMING}/${record.id}`, record)
                .then((response)=> {
                    dispatch( IncomingEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( IncomingEditError(true) );
                });
        }
    });
}

export const IncomingNew = () => ({
    type: INCOMING_NEW
});

export const IncomingNewSuccess = (record) => ({
    type: INCOMING_NEW_SUCCESS,
    payload: record
});

export const IncomingNewError = (error) => ({
    type: INCOMING_NEW_ERROR,
    payload: error
});

export const IncomingEdit = () => ({
    type: INCOMING_EDIT
});

export const IncomingEditSuccess = (record) => ({
    type: INCOMING_EDIT_SUCCESS,
    payload: record
});

export const IncomingEditError = (error) => ({
    type: INCOMING_EDIT_ERROR,
    payload: error
});

