import {
    QUOTEMANAGEMENT_DETAIL_CLEAN,
    QUOTEMANAGEMENT_DETAIL_EDIT,
    QUOTEMANAGEMENT_DETAIL_EDIT_ERROR,
    QUOTEMANAGEMENT_DETAIL_EDIT_SUCCESS,
    QUOTEMANAGEMENT_DETAIL_GET,
    QUOTEMANAGEMENT_DETAIL_GET_ERROR,
    QUOTEMANAGEMENT_DETAIL_GET_SUCCESS,
    QUOTEMANAGEMENT_DETAIL_LIST,
    QUOTEMANAGEMENT_DETAIL_LIST_ERROR,
    QUOTEMANAGEMENT_DETAIL_LIST_SUCCESS,
    QUOTEMANAGEMENT_DETAIL_NEW,
    QUOTEMANAGEMENT_DETAIL_NEW_ERROR,
    QUOTEMANAGEMENT_DETAIL_NEW_SUCCESS,
    QUOTEMANAGEMENT_DETAIL_DELETE,
    QUOTEMANAGEMENT_DETAIL_DELETE_SUCCESS,
    QUOTEMANAGEMENT_DETAIL_DELETE_ERROR,
    QUOTEMANAGEMENT_DETAIL_DD_LIST,
    QUOTEMANAGEMENT_DETAIL_DD_LIST_SUCCESS,
    QUOTEMANAGEMENT_DETAIL_DD_LIST_ERROR
} from "../types";

const initialState = {
    quotemanagementdetaillist: [],
    quotemanagementdetail: {},
    quotemanagementdetailddlist: [],
    loading: false,
    error: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case QUOTEMANAGEMENT_DETAIL_LIST:
            return {
                ...state,
                loading: true,
                quotemanagementdetaillist: [],
            };

        case QUOTEMANAGEMENT_DETAIL_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                quotemanagementdetaillist: action.payload,
                quotemanagementdetail: {}
            };

        case QUOTEMANAGEMENT_DETAIL_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                quotemanagementdetaillist: [],
                quotemanagementdetail: {}
            };

        case QUOTEMANAGEMENT_DETAIL_GET:
            return {
                ...state,
                loading: true,
                error: false,
                quotemanagementdetail: {}
            }

        case QUOTEMANAGEMENT_DETAIL_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                quotemanagementdetail: action.payload
            };

        case QUOTEMANAGEMENT_DETAIL_GET_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                quotemanagementdetaillist: [],
                quotemanagementdetail: {}
            };

        case QUOTEMANAGEMENT_DETAIL_CLEAN:
            return {
                ...state,
                quotemanagementdetaillist: [],
                quotemanagementdetail: {},
                quotemanagementdetailddlist: []
            };

        case QUOTEMANAGEMENT_DETAIL_NEW: 
            return {
                ...state,
                loading: true,
                error: false
            };

        case QUOTEMANAGEMENT_DETAIL_NEW_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                quotemanagementdetaillist: [...state.quotemanagementdetaillist, action.payload],
                quotemanagementdetail: action.payload
            };

        case QUOTEMANAGEMENT_DETAIL_NEW_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                quotemanagementdetaillist: [],
                quotemanagementdetail: {}
            }
        
        case QUOTEMANAGEMENT_DETAIL_EDIT:
            return {
                ...state,
                loading: true,
                error: false
            };

        case QUOTEMANAGEMENT_DETAIL_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                quotemanagementdetail: action.payload,
                quotemanagementdetaillist: state.quotemanagementdetaillist.map(quotemanagementdetail => (quotemanagementdetail.id === action.payload.id ? action.payload : quotemanagementdetail))
            };

        case QUOTEMANAGEMENT_DETAIL_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                quotemanagementdetaillist: [],
                quotemanagementdetail: {}
            };

        case QUOTEMANAGEMENT_DETAIL_DELETE:
            return {
                ...state,
                loading: true,
                error: false
            }

        case QUOTEMANAGEMENT_DETAIL_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                quotemanagementdetaillist: state.quotemanagementdetaillist.filter(quotemanagementdetail => quotemanagementdetail.id !== action.payload)
            }

        case QUOTEMANAGEMENT_DETAIL_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                error: true
            }

        case QUOTEMANAGEMENT_DETAIL_DD_LIST:
            return {
                ...state,
                quotemanagementdetailddlist: [],
            };

        case QUOTEMANAGEMENT_DETAIL_DD_LIST_SUCCESS:
            return {
                ...state,
                quotemanagementdetailddlist: action.payload
            };

        case QUOTEMANAGEMENT_DETAIL_DD_LIST_ERROR:
            return {
                ...state,
                quotemanagementdetailddlist: []
            };

        default:
            return state;
    }
};