import React, { Fragment, useEffect } from "react";
import { Button, Col, FormGroup, ModalBody, ModalFooter, Row } from "reactstrap";
import { messageText } from "../../../Utils/SAMUtils";

import SelectSAM from "../../Common/SelectSAM";
import ThemeDatePickerSAM from "../../Hooks/ThemeDatePickerSAM";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Field, withFormik } from "formik";
import Input from "reactstrap/es/Input";

import * as Yup from "yup";
import ContentWrapper from "../../Layout/ContentWrapper";
import {
    ComponentAssembledHeaderGetAction,
    ComponentAssembledheaderSaveRecordAction,
    EntityMdDDListAction,
    OmaDDListAction
} from "../../../store/actions/actions";
import {
    CLEAR_FIELDS_TITLE_BUTTON,
    CONDITION_LABEL,
    RECEIPT_MATERIAL_DATE_LABEL
} from "../../Supply/MaterialReception/materialReceptionConstants";
import { REQUIERED_FIELDS_LABEL } from "../../../constants/common";
import {
    ASSEMBLED_DATE,
    CHECK_21_004_LABEL,
    NDT_LABEL,
    NON_ROUTINE_REPORT,
    SERVICE_REQUEST_NUMBER,
    SERVICE_SOURCE,
    TIRE_DESCRIPTION,
    TIRE_DESCRIPTION_TYPES
} from "./RubberAssemblyLabel";
import componentAssembledHeader from "../../../services/componentAssembledHeader";

const RubberAssembledForm = ({ screen, dataForm }) => {
    const dispatch = useDispatch();

    const user_id = sessionStorage
        .getItem('user')
        .slice(
            sessionStorage.getItem('user').indexOf(':') + 1,
            sessionStorage.getItem('user').indexOf(','),
        );

    const saveData = async (dataToSave) => {
        let {
            data: {
                result: { record, result },
            },
        } = await componentAssembledHeader.saveOrUpdate(dataToSave);
        if (!result) {
            messageText('An error has occurred', 'Component could not be saved', 'error');
        } else {
            dispatch(ComponentAssembledHeaderGetAction({ ...dataToSave, ...record }));
            dispatch(ComponentAssembledheaderSaveRecordAction({ ...dataToSave, ...record }));
            messageText('Header saved', 'the component were stored correctly', 'success');
        }
    };

    useEffect(() => {
        dispatch(OmaDDListAction());
        dispatch(EntityMdDDListAction(process.env.REACT_APP_MATERIAL_CONDITION));
    }, [dispatch]);

    const omaList = useSelector((state) => state.Oma.omas);
    const materialconditionList = useSelector((state) => state.EntityMd.materialConditionList);

    const formikValidation = withFormik({
        validationSchema: Yup.object().shape({
            oma: Yup.string().required('OMA is required'),
            service_request_number: Yup.string().required('Request number required'),
            observations: Yup.string().required('Observations required'),
            registration_time: Yup.string().nullable().required('Registration time required'),
            assembled_date: Yup.string().nullable().required('Assembled date required'),
            tire_description: Yup.string().required('Tire description is required'),
        }),
        mapPropsToValues: (propiedades) => ({
            id: dataForm.id,
            oma: dataForm.oma,
            oma_id: dataForm?.oma ? dataForm.oma.value : null,
            service_request_number: dataForm.service_request_number,
            observations: dataForm.observations || '',
            registration_time: dataForm.registration_time || new Date(),
            assembled_date: dataForm.assembled_date || null,
            received_date: dataForm.received_date || null,
            coc: dataForm.coc || false,
            packing_list: dataForm.packing_list || false,
            tear_down: dataForm.tear_down || false,
            material_condition_id: dataForm.material_condition
                ? dataForm.material_condition.value
                : null,
            material_condition: dataForm.material_condition || null,
            tire_description: dataForm.tire_description
                ? {
                      value: dataForm.tire_description,
                      label: dataForm.tire_description,
                  }
                : {
                      value: '',
                      label: '',
                  },
        }),

        handleSubmit: (values, { setSubmitting }) => {
            const payload = {
                ...values,
            };
            setTimeout(() => {
                saveData({
                    id: payload.id,
                    oma: payload?.oma,
                    oma_id: payload?.oma ? payload.oma.value : null,
                    service_request_number: payload?.service_request_number,
                    observations: payload?.observations,
                    registration_time: payload?.registration_time || new Date(),
                    assembled_date: payload?.assembled_date,
                    user_responsable_id: user_id,
                    received_date: payload.received_date ? payload.received_date : null,
                    coc: payload.coc,
                    packing_list: payload.packing_list,
                    tear_down: payload.tear_down,
                    material_condition_id: payload.material_condition
                        ? payload.material_condition.value
                        : null,
                    material_condition: payload.material_condition || null,
                    tire_description: payload?.tire_description.value || '',
                }).then((r) => console.debug(r));
                setSubmitting(true);
            }, 1000);
        },
        displayName: 'ItemInventory',
    });

    const formData = (propiedades) => {
        const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            resetForm,
        } = propiedades;

        return (
            <form
                className="form-horizontal"
                name="airplane_form"
                encType="multipart/form-data"
                onSubmit={handleSubmit}
            >
                <ModalBody>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <SelectSAM
                                    value={values.oma}
                                    fieldName={'oma_id'}
                                    field={'oma'}
                                    textField={SERVICE_SOURCE}
                                    isRequired={false}
                                    options={omaList}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.oma}
                                    touched={touched.oma}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <label className="text-bold" htmlFor="service_request_number">
                                    {SERVICE_REQUEST_NUMBER}
                                </label>
                                <Field
                                    type="text"
                                    className="form-control"
                                    name="service_request_number"
                                    placeholder={SERVICE_REQUEST_NUMBER}
                                    value={values.service_request_number}
                                />
                                {!!errors.service_request_number && touched.service_request_number && (
                                    <div style={{ color: 'red', marginTop: '.5rem' }}>
                                        <ErrorMessage name="service_request_number" />
                                    </div>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <label className="text-bold w-100" htmlFor="received_date">
                                    {RECEIPT_MATERIAL_DATE_LABEL}
                                </label>
                                <ThemeDatePickerSAM
                                    disableFuture={false}
                                    name={'received_date'}
                                    openTo={'year'}
                                    label={''}
                                    views={['date']}
                                    value={values.received_date}
                                    onChange={(value) => {
                                        setFieldValue('received_date', value);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <label className="text-bold w-100" htmlFor="assembled_date">
                                    {ASSEMBLED_DATE}
                                </label>
                                <ThemeDatePickerSAM
                                    name={'assembled_date'}
                                    openTo={'year'}
                                    label={''}
                                    views={['date']}
                                    value={values.assembled_date}
                                    onChange={(value) => {
                                        setFieldValue('assembled_date', value);
                                    }}
                                    className={'w-100'}
                                />
                                {!!errors.assembled_date && touched.assembled_date && (
                                    <div style={{ color: 'red', marginTop: '.5rem' }}>
                                        <ErrorMessage name="assembled_date" />
                                    </div>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <SelectSAM
                                    value={values.material_condition}
                                    fieldName={'material_condition_id'}
                                    field={'material_condition'}
                                    textField={CONDITION_LABEL}
                                    isRequired={true}
                                    isDisabled={false}
                                    options={materialconditionList}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.material_condition}
                                    touched={touched.material_condition}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <SelectSAM
                                    value={values.tire_description}
                                    fieldName={'tire_description'}
                                    field={'tire_description'}
                                    textField={TIRE_DESCRIPTION}
                                    isRequired={true}
                                    isDisabled={false}
                                    options={TIRE_DESCRIPTION_TYPES}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.tire_description}
                                    touched={touched.tire_description}
                                />
                                {!!errors.tire_description && touched.tire_description && (
                                    <div style={{ color: 'red', marginTop: '.5rem' }}>
                                        <ErrorMessage name="tire_description" />
                                    </div>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <FormGroup>
                                <label className="text-bold">{CHECK_21_004_LABEL}</label>
                                <label className="switch">
                                    <Input
                                        type="checkbox"
                                        name="coc"
                                        checked={values.coc}
                                        value={values.coc}
                                        onChange={handleChange}
                                    />
                                    <span></span>
                                </label>
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <label className="text-bold">{NON_ROUTINE_REPORT}</label>
                                <label className="switch">
                                    <Input
                                        type="checkbox"
                                        name="packing_list"
                                        checked={values.packing_list}
                                        value={values.packing_list}
                                        onChange={handleChange}
                                    />
                                    <span></span>
                                </label>
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <label className="text-bold">{NDT_LABEL}</label>
                                <label className="switch">
                                    <Input
                                        type="checkbox"
                                        name="tear_down"
                                        checked={values.tear_down}
                                        value={values.tear_down}
                                        onChange={handleChange}
                                    />
                                    <span></span>
                                </label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <label className="text-bold">Observations (*)</label>
                                <Input
                                    type="textarea"
                                    className="form-control"
                                    name="observations"
                                    placeholder="Observations (*)"
                                    defaultValue={values.observations}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {!!errors.observations && touched.observations && (
                                    <div style={{ color: 'red', marginTop: '.5rem' }}>
                                        <ErrorMessage name="observations" />
                                    </div>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <p className="text-bold text-danger">{REQUIERED_FIELDS_LABEL}</p>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {screen !== 'ForToLocate' ? (
                        <Button color="inverse" type="submit">
                            {screen === 'incoming' ? 'Apply' : 'Save'}
                        </Button>
                    ) : null}{' '}
                    <Button color="secondary" onClick={resetForm}>
                        {CLEAR_FIELDS_TITLE_BUTTON}
                    </Button>
                </ModalFooter>
            </form>
        );
    };

    const SAMForm = formikValidation(formData);

    return (
        <ContentWrapper>
            <Fragment>
                <div style={{ maxWidth: '1300px', paddingLeft: '5%' }}>
                    <SAMForm />
                </div>
            </Fragment>
        </ContentWrapper>
    );
};

export default RubberAssembledForm;
