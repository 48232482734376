// Constantes de AccessLog (Log de Acceso al Sistema)
export const ACCESSLOG_LIST = 'ACCESSLOG_LIST';
export const ACCESSLOG_LIST_SUCCESS = 'ACCESSLOG_LIST_SUCCESS';
export const ACCESSLOG_LIST_ERROR = 'ACCESSLOG_LIST_ERROR';
export const ACCESSLOG_DDLIST = 'ACCESSLOG_DDLIST';
export const ACCESSLOG_DDLIST_SUCCESS = 'ACCESSLOG_DDLIST_SUCCESS';
export const ACCESSLOG_DDLIST_ERROR = 'ACCESSLOG_DDLIST_ERROR';
export const ACCESSLOG_GET = 'ACCESSLOG_GET';
export const ACCESSLOG_GET_SUCCESS = 'ACCESSLOG_GET_SUCCESS'; 
export const ACCESSLOG_GET_ERROR = 'ACCESSLOG_GET_ERROR';
export const ACCESSLOG_NEW = 'ACCESSLOG_NEW';
export const ACCESSLOG_NEW_SUCCESS = 'ACCESSLOG_NEW_SUCCESS';
export const ACCESSLOG_NEW_ERROR = 'ACCESSLOG_NEW_ERROR';
export const ACCESSLOG_NEW_RECORD = 'ACCESSLOG_NEW_RECORD';
export const ACCESSLOG_EDIT = 'ACCESSLOG_EDIT';
export const ACCESSLOG_EDIT_SUCCESS = 'ACCESSLOG_EDIT_SUCCESS';
export const ACCESSLOG_EDIT_ERROR = 'ACCESSLOG_EDIT_ERROR';

// Constantes de Airplane (Aeronave)
export const AIRPLANE_LIST = 'AIRPLANE_LIST';
export const AIRPLANE_LIST_SUCCESS = 'AIRPLANE_LIST_SUCCESS';
export const AIRPLANE_LIST_ERROR = 'AIRPLANE_LIST_ERROR';
export const AIRPLANE_DDLIST = 'AIRPLANE_DDLIST';
export const AIRPLANE_DDLIST_SUCCESS = 'AIRPLANE_DDLIST_SUCCESS';
export const AIRPLANE_DDLIST_ERROR = 'AIRPLANE_DDLIST_ERROR';
export const AIRPLANE_GET = 'AIRPLANE_GET';
export const AIRPLANE_GET_SUCCESS = 'AIRPLANE_GET_SUCCESS';
export const AIRPLANE_GET_ERROR = 'AIRPLANE_GET_ERROR';
export const AIRPLANE_GET_SEARCH = 'AIRPLANE_GET_SEARCH';
export const AIRPLANE_GET_SEARCH_SUCCESS = 'AIRPLANE_GET_SEARCH_SUCCESS';
export const AIRPLANE_GET_SEARCH_ERROR = 'AIRPLANE_GET_SEARCH_ERROR';
export const AIRPLANE_NEW = 'AIRPLANE_NEW';
export const AIRPLANE_NEW_SUCCESS = 'AIRPLANE_NEW_SUCCESS';
export const AIRPLANE_NEW_ERROR = 'AIRPLANE_NEW_ERROR';
export const AIRPLANE_NEW_RECORD = 'AIRPLANE_NEW_RECORD';
export const AIRPLANE_EDIT = 'AIRPLANE_EDIT';
export const AIRPLANE_EDIT_SUCCESS = 'AIRPLANE_EDIT_SUCCESS';
export const AIRPLANE_EDIT_ERROR = 'AIRPLANE_EDIT_ERROR';
export const AIRPLANE_MODEL_DDLIST = 'AIRPLANE_MODEL_DDLIST';
export const AIRPLANE_MODEL_DDLIST_SUCCESS = 'AIRPLANE_MODEL_DDLIST_SUCCESS';
export const AIRPLANE_MODEL_DDLIST_ERROR = 'AIRPLANE_MODEL_DDLIST_ERROR';

// Constantes de Airplane Componentes (Componentes de Aeronave)
export const AIRPLANECOMPONENT_LIST = 'AIRPLANECOMPONENT_LIST';
export const AIRPLANECOMPONENT_LIST_SUCCESS = 'AIRPLANECOMPONENT_LIST_SUCCESS';
export const AIRPLANECOMPONENT_LIST_ERROR = 'AIRPLANECOMPONENT_LIST_ERROR';
export const AIRPLANECOMPONENT_DDLIST = 'AIRPLANECOMPONENT_DDLIST';
export const AIRPLANECOMPONENT_DDLIST_SUCCESS = 'AIRPLANECOMPONENT_DDLIST_SUCCESS';
export const AIRPLANECOMPONENT_DDLIST_ERROR = 'AIRPLANECOMPONENT_DDLIST_ERROR';
export const AIRPLANECOMPONENT_GET = 'AIRPLANECOMPONENT_GET';
export const AIRPLANECOMPONENT_GET_SUCCESS = 'AIRPLANECOMPONENT_GET_SUCCESS';
export const AIRPLANECOMPONENT_GET_ERROR = 'AIRPLANECOMPONENT_GET_ERROR';
export const AIRPLANECOMPONENT_NEW = 'AIRPLANECOMPONENT_NEW';
export const AIRPLANECOMPONENT_NEW_SUCCESS = 'AIRPLANECOMPONENT_NEW_SUCCESS';
export const AIRPLANECOMPONENT_NEW_ERROR = 'AIRPLANECOMPONENT_NEW_ERROR';
export const AIRPLANECOMPONENT_NEW_RECORD = 'AIRPLANECOMPONENT_NEW_RECORD';
export const AIRPLANECOMPONENT_EDIT = 'AIRPLANECOMPONENT_EDIT';
export const AIRPLANECOMPONENT_EDIT_SUCCESS = 'AIRPLANECOMPONENT_EDIT_SUCCESS';
export const AIRPLANECOMPONENT_EDIT_ERROR = 'AIRPLANECOMPONENT_EDIT_ERROR';
export const AIRPLANECOMPONENT_DELETE = 'AIRPLANECOMPONENT_DELETE';
export const AIRPLANECOMPONENT_DELETE_SUCCESS = 'AIRPLANECOMPONENT_DELETE_SUCCESS';
export const AIRPLANECOMPONENT_DELETE_ERROR  = 'AIRPLANECOMPONENT_DELETE_ERROR ';

// Constantes de Airplane Control de Mantenimientos
export const AIRPLANECONTROLMAINTENANCE_LIST = 'AIRPLANECONTROLMAINTENANCE_LIST';
export const AIRPLANECONTROLMAINTENANCE_LIST_SUCCESS = 'AIRPLANECONTROLMAINTENANCE_LIST_SUCCESS';
export const AIRPLANECONTROLMAINTENANCE_LIST_ERROR = 'AIRPLANECONTROLMAINTENANCE_LIST_ERROR';
export const AIRPLANECONTROLMAINTENANCE_DDLIST = 'AIRPLANECONTROLMAINTENANCE_DDLIST';
export const AIRPLANECONTROLMAINTENANCE_DDLIST_SUCCESS =
    'AIRPLANECONTROLMAINTENANCE_DDLIST_SUCCESS';
export const AIRPLANECONTROLMAINTENANCE_DDLIST_ERROR = 'AIRPLANECONTROLMAINTENANCE_DDLIST_ERROR';
export const AIRPLANECONTROLMAINTENANCE_GET = 'AIRPLANECONTROLMAINTENANCE_GET';
export const AIRPLANECONTROLMAINTENANCE_GET_SUCCESS = 'AIRPLANECONTROLMAINTENANCE_GET_SUCCESS';
export const AIRPLANECONTROLMAINTENANCE_GET_ERROR = 'AIRPLANECONTROLMAINTENANCE_GET_ERROR';
export const AIRPLANECONTROLMAINTENANCE_NEW = 'AIRPLANECONTROLMAINTENANCE_NEW';
export const AIRPLANECONTROLMAINTENANCE_NEW_SUCCESS = 'AIRPLANECONTROLMAINTENANCE_NEW_SUCCESS';
export const AIRPLANECONTROLMAINTENANCE_NEW_ERROR = 'AIRPLANECONTROLMAINTENANCE_NEW_ERROR';
export const AIRPLANECONTROLMAINTENANCE_NEW_RECORD = 'AIRPLANECONTROLMAINTENANCE_NEW_RECORD';
export const AIRPLANECONTROLMAINTENANCE_EDIT = 'AIRPLANECONTROLMAINTENANCE_EDIT';
export const AIRPLANECONTROLMAINTENANCE_EDIT_SUCCESS = 'AIRPLANECONTROLMAINTENANCE_EDIT_SUCCESS';
export const AIRPLANECONTROLMAINTENANCE_EDIT_ERROR = 'AIRPLANECONTROLMAINTENANCE_EDIT_ERROR';

// Constantes de Airplane Flight History (Historial de Vuelo de Aeronave)
export const AIRPLANEFLIGHTHISTORY_LIST = 'AIRPLANEFLIGHTHISTORY_LIST';
export const AIRPLANEFLIGHTHISTORY_LIST_SUCCESS = 'AIRPLANEFLIGHTHISTORY_LIST_SUCCESS';
export const AIRPLANEFLIGHTHISTORY_LIST_ERROR = 'AIRPLANEFLIGHTHISTORY_LIST_ERROR';
export const AIRPLANEFLIGHTHISTORY_DDLIST = 'AIRPLANEFLIGHTHISTORY_DDLIST';
export const AIRPLANEFLIGHTHISTORY_DDLIST_SUCCESS = 'AIRPLANEFLIGHTHISTORY_DDLIST_SUCCESS';
export const AIRPLANEFLIGHTHISTORY_DDLIST_ERROR = 'AIRPLANEFLIGHTHISTORY_DDLIST_ERROR';
export const AIRPLANEFLIGHTHISTORY_GET = 'AIRPLANEFLIGHTHISTORY_GET';
export const AIRPLANEFLIGHTHISTORY_GET_SUCCESS = 'AIRPLANEFLIGHTHISTORY_GET_SUCCESS';
export const AIRPLANEFLIGHTHISTORY_GET_ERROR = 'AIRPLANEFLIGHTHISTORY_GET_ERROR';
export const AIRPLANEFLIGHTHISTORY_NEW = 'AIRPLANEFLIGHTHISTORY_NEW';
export const AIRPLANEFLIGHTHISTORY_NEW_SUCCESS = 'AIRPLANEFLIGHTHISTORY_NEW_SUCCESS';
export const AIRPLANEFLIGHTHISTORY_NEW_ERROR = 'AIRPLANEFLIGHTHISTORY_NEW_ERROR';
export const AIRPLANEFLIGHTHISTORY_NEW_RECORD = 'AIRPLANEFLIGHTHISTORY_NEW_RECORD';
export const AIRPLANEFLIGHTHISTORY_EDIT = 'AIRPLANEFLIGHTHISTORY_EDIT';
export const AIRPLANEFLIGHTHISTORY_EDIT_SUCCESS = 'AIRPLANEFLIGHTHISTORY_EDIT_SUCCESS';
export const AIRPLANEFLIGHTHISTORY_EDIT_ERROR = 'AIRPLANEFLIGHTHISTORY_EDIT_ERROR';
export const AIRPLANEFLIGHTHISTORY_DELETE = 'AIRPLANEFLIGHTHISTORY_DELETE';
export const AIRPLANEFLIGHTHISTORY_DELETE_SUCCESS = 'AIRPLANEFLIGHTHISTORY_DELETE_SUCCESS';
export const AIRPLANEFLIGHTHISTORY_DELETE_ERROR = 'AIRPLANEFLIGHTHISTORY_DELETE_ERROR';

export const AIRPLANETASKCARD_LIST = 'AIRPLANETASKCARD_LIST';
export const AIRPLANETASKCARD_LIST_SUCCESS = 'AIRPLANETASKCARD_LIST_SUCCESS';
export const AIRPLANETASKCARD_LIST_ERROR = 'AIRPLANETASKCARD_LIST_ERROR';
export const AIRPLANETASKCARD_DDLIST = 'AIRPLANETASKCARD_DDLIST';
export const AIRPLANETASKCARD_DDLIST_SUCCESS = 'AIRPLANETASKCARD_DDLIST_SUCCESS';
export const AIRPLANETASKCARD_DDLIST_ERROR = 'AIRPLANETASKCARD_DDLIST_ERROR';
export const AIRPLANETASKCARD_GET = 'AIRPLANETASKCARD_GET';
export const AIRPLANETASKCARD_GET_SUCCESS = 'AIRPLANETASKCARD_GET_SUCCESS';
export const AIRPLANETASKCARD_GET_ERROR = 'AIRPLANETASKCARD_GET_ERROR';
export const AIRPLANETASKCARD_NEW = 'AIRPLANETASKCARD_NEW';
export const AIRPLANETASKCARD_NEW_SUCCESS = 'AIRPLANETASKCARD_NEW_SUCCESS';
export const AIRPLANETASKCARD_NEW_ERROR = 'AIRPLANETASKCARD_NEW_ERROR';
export const AIRPLANETASKCARD_NEW_RECORD = 'AIRPLANETASKCARD_NEW_RECORD';
export const AIRPLANETASKCARD_EDIT = 'AIRPLANETASKCARD_EDIT';
export const AIRPLANETASKCARD_EDIT_SUCCESS = 'AIRPLANETASKCARD_EDIT_SUCCESS';
export const AIRPLANETASKCARD_EDIT_ERROR = 'AIRPLANETASKCARD_EDIT_ERROR';

// Constantes de AirportIata (Aeropuerto)
export const AIRPORTIATA_LIST = 'AIRPORTIATA_LIST';
export const AIRPORTIATA_LIST_SUCCESS = 'AIRPORTIATA_LIST_SUCCESS';
export const AIRPORTIATA_LIST_ERROR = 'AIRPORTIATA_LIST_ERROR';
export const AIRPORTIATA_DDLIST = 'AIRPORTIATA_DDLIST';
export const AIRPORTIATA_DDLIST_SUCCESS = 'AIRPORTIATA_DDLIST_SUCCESS';
export const AIRPORTIATA_DDLIST_ERROR = 'AIRPORTIATA_DDLIST_ERROR';
export const AIRPORTIATA_GET = 'AIRPORTIATA_GET';
export const AIRPORTIATA_GET_SUCCESS = 'AIRPORTIATA_GET_SUCCESS';
export const AIRPORTIATA_GET_ERROR = 'AIRPORTIATA_GET_ERROR';
export const AIRPORTIATA_NEW = 'AIRPORTIATA_NEW';
export const AIRPORTIATA_NEW_SUCCESS = 'AIRPORTIATA_NEW_SUCCESS';
export const AIRPORTIATA_NEW_ERROR = 'AIRPORTIATA_NEW_ERROR';
export const AIRPORTIATA_NEW_RECORD = 'AIRPORTIATA_NEW_RECORD';
export const AIRPORTIATA_EDIT = 'AIRPORTIATA_EDIT';
export const AIRPORTIATA_EDIT_SUCCESS = 'AIRPORTIATA_EDIT_SUCCESS';
export const AIRPORTIATA_EDIT_ERROR = 'AIRPORTIATA_EDIT_ERROR';
export const AIRPORTIATA_DDLIST_CODE = 'AIRPORTIATA_DDLIST';
export const AIRPORTIATA_DDLIST_CODE_SUCCESS = 'AIRPORTIATA_DDLIST_SUCCESS';
export const AIRPORTIATA_DDLIST_CODE_ERROR = 'AIRPORTIATA_DDLIST_ERROR';
export const AIRPORTIATA_DELETE = 'AIRPORTIATA_DELETE';
export const AIRPORTIATA_DELETE_SUCCESS = 'AIRPORTIATA_DELETE_SUCCESS';
export const AIRPORTIATA_DELETE_ERROR = 'AIRPORTIATA_DELETE_ERROR';
export const AIRPORTIATA_CITY_DD_LIST = 'AIRPORTIATA_CITY_DD_LIST';
export const AIRPORTIATA_CITY_DD_LIST_SUCCESS = 'AIRPORTIATA_CITY_DD_LIST_SUCCESS';
export const AIRPORTIATA_CITY_DD_LIST_ERROR = 'AIRPORTIATA_CITY_DD_LIST_ERROR';

// Constantes de AirplaneManual (Manuales de Aeronaves)
export const AIRPLANEMANUAL_LIST = 'AIRPLANEMANUAL_LIST';
export const AIRPLANEMANUAL_LIST_SUCCESS = 'AIRPLANEMANUAL_LIST_SUCCESS';
export const AIRPLANEMANUAL_LIST_ERROR = 'AIRPLANEMANUAL_LIST_ERROR';
export const AIRPLANEMANUAL_GET = 'AIRPLANEMANUAL_GET';
export const AIRPLANEMANUAL_GET_SUCCESS = 'AIRPLANEMANUAL_GET_SUCCESS';
export const AIRPLANEMANUAL_GET_ERROR = 'AIRPLANEMANUAL_GET_ERROR';
export const AIRPLANEMANUAL_NEW = 'AIRPLANEMANUAL_NEW';
export const AIRPLANEMANUAL_NEW_SUCCESS = 'AIRPLANEMANUAL_NEW_SUCCESS';
export const AIRPLANEMANUAL_NEW_ERROR = 'AIRPLANEMANUAL_NEW_ERROR';
export const AIRPLANEMANUAL_EDIT = 'AIRPLANEMANUAL_EDIT';
export const AIRPLANEMANUAL_EDIT_SUCCESS = 'AIRPLANEMANUAL_EDIT_SUCCESS';
export const AIRPLANEMANUAL_EDIT_ERROR = 'AIRPLANEMANUAL_EDIT_ERROR';

// Constantes de Atas (Atas)
export const ATA_LIST = 'ATA_LIST';
export const ATA_LIST_SUCCESS = 'ATA_LIST_SUCCESS';
export const ATA_LIST_ERROR = 'ATA_LIST_ERROR';
export const ATA_DDLIST = 'ATA_DDLIST';
export const ATA_DDLIST_SUCCESS = 'ATA_DDLIST_SUCCESS';
export const ATA_DDLIST_ERROR = 'ATA_DDLIST_ERROR';
export const ATA_GET = 'ATA_GET';
export const ATA_GET_SUCCESS = 'ATA_GET_SUCCESS';
export const ATA_GET_ERROR = 'ATA_GET_ERROR';
export const ATA_NEW = 'ATA_NEW';
export const ATA_NEW_SUCCESS = 'ATA_NEW_SUCCESS';
export const ATA_NEW_ERROR = 'ATA_NEW_ERROR';
export const ATA_NEW_RECORD = 'ATA_NEW_RECORD';
export const ATA_EDIT = 'ATA_EDIT';
export const ATA_EDIT_SUCCESS = 'ATA_EDIT_SUCCESS';
export const ATA_EDIT_ERROR = 'ATA_EDIT_ERROR';

// Variables de Documentos requeridos por Proceso
export const ATTACHTYPE_LIST = 'ATTACHTYPE_LIST';
export const ATTACHTYPE_LIST_SUCCESS = 'ATTACHTYPE_LIST_SUCCESS';
export const ATTACHTYPE_LIST_ERROR = 'ATTACHTYPE_LIST_ERROR';
export const ATTACHTYPE_DDLIST = 'ATTACHTYPE_DDLIST';
export const ATTACHTYPE_DDLIST_SUCCESS = 'ATTACHTYPE_DDLIST_SUCCESS';
export const ATTACHTYPE_DDLIST_ERROR = 'ATTACHTYPE_DDLIST_ERROR';
export const ATTACHTYPE_GET = 'ATTACHTYPE_GET';
export const ATTACHTYPE_GET_SUCCESS = 'ATTACHTYPE_GET_SUCCESS';
export const ATTACHTYPE_GET_ERROR = 'ATTACHTYPE_GET_ERROR';
export const ATTACHTYPE_NEW = 'ATTACHTYPE_NEW';
export const ATTACHTYPE_NEW_SUCCESS = 'ATTACHTYPE_NEW_SUCCESS';
export const ATTACHTYPE_NEW_ERROR = 'ATTACHTYPE_NEW_ERROR';
export const ATTACHTYPE_NEW_RECORD = 'ATTACHTYPE_NEW_RECORD';
export const ATTACHTYPE_EDIT = 'ATTACHTYPE_EDIT';
export const ATTACHTYPE_EDIT_SUCCESS = 'ATTACHTYPE_EDIT_SUCCESS';
export const ATTACHTYPE_EDIT_ERROR = 'ATTACHTYPE_EDIT_ERROR';

// Variables de Bank
export const BANK_LIST = 'BANK_LIST';
export const BANK_LIST_SUCCESS = 'BANK_LIST_SUCCESS';
export const BANK_LIST_ERROR = 'BANK_LIST_ERROR';
export const BANK_DDLIST = 'BANK_DDLIST';
export const BANK_DDLIST_SUCCESS = 'BANK_DDLIST_SUCCESS';
export const BANK_DDLIST_ERROR = 'BANK_DDLIST_ERROR';
export const BANK_GET = 'BANK_GET';
export const BANK_GET_SUCCESS = 'BANK_GET_SUCCESS';
export const BANK_GET_ERROR = 'BANK_GET_ERROR';
export const BANK_NEW = 'BANK_NEW';
export const BANK_NEW_SUCCESS = 'BANK_NEW_SUCCESS';
export const BANK_NEW_ERROR = 'BANK_NEW_ERROR';
export const BANK_NEW_RECORD = 'BANK_NEW_RECORD';
export const BANK_EDIT = 'BANK_EDIT';
export const BANK_EDIT_SUCCESS = 'BANK_EDIT_SUCCESS';
export const BANK_EDIT_ERROR = 'BANK_EDIT_ERROR';

// Variables de Bpartner
export const BPARTNER_LIST = 'BPARTNER_LIST';
export const BPARTNER_LIST_SUCCESS = 'BPARTNER_LIST_SUCCESS';
export const BPARTNER_LIST_ERROR = 'BPARTNER_LIST_ERROR';
export const BPARTNER_DDLIST = 'BPARTNER_DDLIST';
export const BPARTNER_DDLIST_SUCCESS = 'BPARTNER_DDLIST_SUCCESS';
export const BPARTNER_DDLIST_ERROR = 'BPARTNER_DDLIST_ERROR';
export const BPARTNER_GET = 'BPARTNER_GET';
export const BPARTNER_GET_SUCCESS = 'BPARTNER_GET_SUCCESS';
export const BPARTNER_GET_ERROR = 'BPARTNER_GET_ERROR';
export const BPARTNER_NEW = 'BPARTNER_NEW';
export const BPARTNER_NEW_SUCCESS = 'BPARTNER_NEW_SUCCESS';
export const BPARTNER_NEW_ERROR = 'BPARTNER_NEW_ERROR';
export const BPARTNER_NEW_RECORD = 'BPARTNER_NEW_RECORD';
export const BPARTNER_EDIT = 'BPARTNER_EDIT';
export const BPARTNER_EDIT_SUCCESS = 'BPARTNER_EDIT_SUCCESS';
export const BPARTNER_EDIT_ERROR = 'BPARTNER_EDIT_ERROR';

// Variables de Bancos Asociados al Proveedor
export const BPARTNERBANK_LIST = 'BPARTNERBANK_LIST';
export const BPARTNERBANK_LIST_SUCCESS = 'BPARTNERBANK_LIST_SUCCESS';
export const BPARTNERBANK_LIST_ERROR = 'BPARTNERBANK_LIST_ERROR';
export const BPARTNERBANK_DDLIST = 'BPARTNERBANK_DDLIST';
export const BPARTNERBANK_DDLIST_SUCCESS = 'BPARTNERBANK_DDLIST_SUCCESS';
export const BPARTNERBANK_DDLIST_ERROR = 'BPARTNERBANK_DDLIST_ERROR';
export const BPARTNERBANKALL_DDLIST = 'BPARTNERBANKALL_DDLIST';
export const BPARTNERBANKALL_DDLIST_SUCCESS = 'BPARTNERBANKALL_DDLIST_SUCCESS';
export const BPARTNERBANKALL_DDLIST_ERROR = 'BPARTNERBANKALL_DDLIST_ERROR';
export const BPARTNERBANK_GET = 'BPARTNERBANK_GET';
export const BPARTNERBANK_GET_SUCCESS = 'BPARTNERBANK_GET_SUCCESS';
export const BPARTNERBANK_GET_ERROR = 'BPARTNERBANK_GET_ERROR';
export const BPARTNERBANK_NEW = 'BPARTNERBANK_NEW';
export const BPARTNERBANK_NEW_SUCCESS = 'BPARTNERBANK_NEW_SUCCESS';
export const BPARTNERBANK_NEW_ERROR = 'BPARTNERBANK_NEW_ERROR';
export const BPARTNERBANK_NEW_RECORD = 'BPARTNERBANK_NEW_RECORD';
export const BPARTNERBANK_EDIT = 'BPARTNERBANK_EDIT';
export const BPARTNERBANK_EDIT_SUCCESS = 'BPARTNERBANK_EDIT_SUCCESS';
export const BPARTNERBANK_EDIT_ERROR = 'BPARTNERBANK_EDIT_ERROR';

// Variables de Productos Asociados al Proveedor
export const BPARTNERPRODUCT_LIST = 'BPARTNERPRODUCT_LIST';
export const BPARTNERPRODUCT_LIST_SUCCESS = 'BPARTNERPRODUCT_LIST_SUCCESS';
export const BPARTNERPRODUCT_LIST_ERROR = 'BPARTNERPRODUCT_LIST_ERROR';
export const BPARTNERPRODUCT_DDLIST = 'BPARTNERPRODUCT_DDLIST';
export const BPARTNERPRODUCT_DDLIST_SUCCESS = 'BPARTNERPRODUCT_DDLIST_SUCCESS';
export const BPARTNERPRODUCT_DDLIST_ERROR = 'BPARTNERPRODUCT_DDLIST_ERROR';
export const BPARTNERPRODUCT_GET = 'BPARTNERPRODUCT_GET';
export const BPARTNERPRODUCT_GET_SUCCESS = 'BPARTNERPRODUCT_GET_SUCCESS';
export const BPARTNERPRODUCT_GET_ERROR = 'BPARTNERPRODUCT_GET_ERROR';
export const BPARTNERPRODUCT_NEW = 'BPARTNERPRODUCT_NEW';
export const BPARTNERPRODUCT_NEW_SUCCESS = 'BPARTNERPRODUCT_NEW_SUCCESS';
export const BPARTNERPRODUCT_NEW_ERROR = 'BBPARTNERPRODUCT_NEW_ERROR';
export const BPARTNERPRODUCT_NEW_RECORD = 'BPARTNERPRODUCT_NEW_RECORD';
export const BPARTNERPRODUCT_EDIT = 'BPARTNERPRODUCT_EDIT';
export const BPARTNERPRODUCT_EDIT_SUCCESS = 'BPARTNERPRODUCT_EDIT_SUCCESS';
export const BPARTNERPRODUCT_EDIT_ERROR = 'BPARTNERPRODUCT_EDIT_ERROR';
export const BPARTNERPRODUCT_DELETE = 'BPARTNERPRODUCT_DELETE';
export const BPARTNERPRODUCT_DELETE_SUCCESS = 'BPARTNERPRODUCT_DELETE_SUCCESS';
export const BPARTNERPRODUCT_DELETE_ERROR = 'BPARTNERPRODUCT_DELETE_ERROR';

// Variables de Charges (Cargos)
export const CHARGE_LIST = 'CHARGE_LIST';
export const CHARGE_LIST_SUCCESS = 'CHARGE_LIST_SUCCESS';
export const CHARGE_LIST_ERROR = 'CHARGE_LIST_ERROR';
export const CHARGE_DDLIST = 'CHARGE_DDLIST';
export const CHARGE_DDLIST_SUCCESS = 'CHARGE_DDLIST_SUCCESS';
export const CHARGE_DDLIST_ERROR = 'CHARGE_DDLIST_ERROR';
export const CHARGE_GET = 'CHARGE_GET';
export const CHARGE_GET_SUCCESS = 'CHARGE_GET_SUCCESS';
export const CHARGE_GET_ERROR = 'CHARGE_GET_ERROR';
export const CHARGE_NEW = 'CHARGE_NEW';
export const CHARGE_NEW_SUCCESS = 'CHARGE_NEW_SUCCESS';
export const CHARGE_NEW_ERROR = 'CHARGE_NEW_ERROR';
export const CHARGE_NEW_RECORD = 'CHARGE_NEW_RECORD';
export const CHARGE_EDIT = 'CHARGE_EDIT';
export const CHARGE_EDIT_SUCCESS = 'CHARGE_EDIT_SUCCESS';
export const CHARGE_EDIT_ERROR = 'CHARGE_EDIT_ERROR';

// Variables de Compañia
export const COMPANY_LIST = 'COMPANY_LIST';
export const COMPANY_LIST_SUCCESS = 'COMPANY_LIST_SUCCESS';
export const COMPANY_LIST_ERROR = 'COMPANY_LIST_ERROR';
export const COMPANY_DDLIST = 'COMPANY_DDLIST';
export const COMPANY_DDLIST_SUCCESS = 'COMPANY_DDLIST_SUCCESS';
export const COMPANY_DDLIST_ERROR = 'COMPANY_DDLIST_ERROR';
export const COMPANY_GET = 'COMPANY_GET';
export const COMPANY_GET_SUCCESS = 'COMPANY_GET_SUCCESS';
export const COMPANY_GET_ERROR = 'COMPANY_GET_ERROR';
export const COMPANY_NEW = 'COMPANY_NEW';
export const COMPANY_NEW_SUCCESS = 'COMPANY_NEW_SUCCESS';
export const COMPANY_NEW_ERROR = 'COMPANY_NEW_ERROR';
export const COMPANY_NEW_RECORD = 'COMPANY_NEW_RECORD';
export const COMPANY_EDIT = 'COMPANY_EDIT';
export const COMPANY_EDIT_SUCCESS = 'COMPANY_EDIT_SUCCESS';
export const COMPANY_EDIT_ERROR = 'COMPANY_EDIT_ERROR';
export const SET_COMPANY_SERVICEROLE_LIST = 'SET_COMPANY_SERVICEROLE_LIST';
export const ADD_COMPANY_SERVICEROLE_LIST = 'ADD_COMPANY_SERVICEROLE_LIST';
export const REMOVE_COMPANY_SERVICEROLE_LIST = 'REMOVE_COMPANY_SERVICEROLE_LIST';
export const COMPANY_LOGISTIC_OPERATOR_DD_LIST = 'COMPANY_LOGISTIC_OPERATOR_DD_LIST';

// Variables Correlative Document
export const CORRELATIVE_DOCUMENT_LIST = 'CORRELATIVE_DOCUMENT_LIST';
export const CORRELATIVE_DOCUMENT_LIST_SUCCESS = 'CORRELATIVE_DOCUMENT_LIST_SUCCESS';
export const CORRELATIVE_DOCUMENT_LIST_ERROR = 'CORRELATIVE_DOCUMENT_LIST_ERROR';
export const CORRELATIVE_DOCUMENT_DDLIST = 'CORRELATIVE_DOCUMENT_DDLIST';
export const CORRELATIVE_DOCUMENT_DDLIST_SUCCESS = 'CORRELATIVE_DOCUMENT_DDLIST_SUCCESS';
export const CORRELATIVE_DOCUMENT_DDLIST_ERROR = 'CORRELATIVE_DOCUMENT_DDLIST_ERROR';
export const CORRELATIVE_DOCUMENT_GET = 'CORRELATIVE_DOCUMENT_GET';
export const CORRELATIVE_DOCUMENT_GET_SUCCESS = 'CORRELATIVE_DOCUMENT_GET_SUCCESS';
export const CORRELATIVE_DOCUMENT_GET_ERROR = 'CORRELATIVE_DOCUMENT_GET_ERROR';
export const CORRELATIVE_DOCUMENT_NEW = 'CORRELATIVE_DOCUMENT_NEW';
export const CORRELATIVE_DOCUMENT_NEW_SUCCESS = 'CORRELATIVE_DOCUMENT_NEW_SUCCESS';
export const CORRELATIVE_DOCUMENT_NEW_ERROR = 'CORRELATIVE_DOCUMENT_NEW_ERROR';
export const CORRELATIVE_DOCUMENT_NEW_RECORD = 'CORRELATIVE_DOCUMENT_NEW_RECORD';
export const CORRELATIVE_DOCUMENT_EDIT = 'CORRELATIVE_DOCUMENT_EDIT';
export const CORRELATIVE_DOCUMENT_EDIT_SUCCESS = 'CORRELATIVE_DOCUMENT_EDIT_SUCCESS';
export const CORRELATIVE_DOCUMENT_EDIT_ERROR = 'CORRELATIVE_DOCUMENT_EDIT_ERROR';

// Variables de Pais
export const COUNTRY_LIST = 'COUNTRY_LIST';
export const COUNTRY_LIST_SUCCESS = 'COUNTRY_LIST_SUCCESS';
export const COUNTRY_LIST_ERROR = 'COUNTRY_LIST_ERROR';
export const COUNTRY_DDLIST = 'COUNTRY_DDLIST';
export const COUNTRY_DDLIST_SUCCESS = 'COUNTRY_DDLIST_SUCCESS';
export const COUNTRY_DDLIST_ERROR = 'COUNTRY_DDLIST_ERROR';
export const COUNTRY_GET = 'COUNTRY_GET';
export const COUNTRY_GET_SUCCESS = 'COUNTRY_GET_SUCCESS';
export const COUNTRY_GET_ERROR = 'COUNTRY_GET_ERROR';
export const COUNTRY_NEW = 'COUNTRY_NEW';
export const COUNTRY_NEW_SUCCESS = 'COUNTRY_NEW_SUCCESS';
export const COUNTRY_NEW_ERROR = 'COUNTRY_NEW_ERROR';
export const COUNTRY_NEW_RECORD = 'COUNTRY_NEW_RECORD';
export const COUNTRY_EDIT = 'COUNTRY_EDIT';
export const COUNTRY_EDIT_SUCCESS = 'COUNTRY_EDIT_SUCCESS';
export const COUNTRY_EDIT_ERROR = 'COUNTRY_EDIT_ERROR';

// Variable de Monedas / Divisas / Currency
export const CURRENCY_LIST = 'CURRENCY_LIST';
export const CURRENCY_LIST_SUCCESS = 'CURRENCY_LIST_SUCCESS';
export const CURRENCY_LIST_ERROR = 'CURRENCY_LIST_ERROR';
export const CURRENCY_DDLIST = 'CURRENCY_DDLIST';
export const CURRENCY_DDLIST_SUCCESS = 'CURRENCY_DDLIST_SUCCESS';
export const CURRENCY_DDLIST_ERROR = 'CURRENCY_DDLIST_ERROR';
export const CURRENCY_GET = 'CURRENCY_GET';
export const CURRENCY_GET_SUCCESS = 'CURRENCY_GET_SUCCESS';
export const CURRENCY_GET_ERROR = 'CURRENCY_GET_ERROR';
export const CURRENCY_NEW = 'CURRENCY_NEW';
export const CURRENCY_NEW_SUCCESS = 'CURRENCY_NEW_SUCCESS';
export const CURRENCY_NEW_ERROR = 'CURRENCY_NEW_ERROR';
export const CURRENCY_NEW_RECORD = 'CURRENCY_NEW_RECORD';
export const CURRENCY_EDIT = 'CURRENCY_EDIT';
export const CURRENCY_EDIT_SUCCESS = 'CURRENCY_EDIT_SUCCESS';
export const CURRENCY_EDIT_ERROR = 'CURRENCY_EDIT_ERROR';

// Variables de Department (Departamentos)
export const DEPARTMENT_LIST = 'DEPARTMENT_LIST';
export const DEPARTMENT_LIST_SUCCESS = 'DEPARTMENT_LIST_SUCCESS';
export const DEPARTMENT_LIST_ERROR = 'DEPARTMENT_LIST_ERROR';
export const DEPARTMENT_DDLIST = 'DEPARTMENT_DDLIST';
export const DEPARTMENT_DDLIST_SUCCESS = 'DEPARTMENT_DDLIST_SUCCESS';
export const DEPARTMENT_DDLIST_ERROR = 'DEPARTMENT_DDLIST_ERROR';
export const DEPARTMENT_GET = 'DEPARTMENT_GET';
export const DEPARTMENT_GET_SUCCESS = 'DEPARTMENT_GET_SUCCESS';
export const DEPARTMENT_GET_ERROR = 'DEPARTMENT_GET_ERROR';
export const DEPARTMENT_NEW = 'DEPARTMENT_NEW';
export const DEPARTMENT_NEW_SUCCESS = 'DEPARTMENT_NEW_SUCCESS';
export const DEPARTMENT_NEW_ERROR = 'DEPARTMENT_NEW_ERROR';
export const DEPARTMENT_NEW_RECORD = 'DEPARTMENT_NEW_RECORD';
export const DEPARTMENT_EDIT = 'DEPARTMENT_EDIT';
export const DEPARTMENT_EDIT_SUCCESS = 'DEPARTMENT_EDIT_SUCCESS';
export const DEPARTMENT_EDIT_ERROR = 'DEPARTMENT_EDIT_ERROR';

// Documentos por Procesos
export const DOCUMENTBYPROCESS_LIST = 'DOCUMENTBYPROCESS_LIST';
export const DOCUMENTBYPROCESS_LIST_SUCCESS = 'DOCUMENTBYPROCESS_LIST_SUCCESS';
export const DOCUMENTBYPROCESS_LIST_ERROR = 'DOCUMENTBYPROCESS_LIST_ERROR';
export const DOCUMENTBYPROCESS_DDLIST = 'DOCUMENTBYPROCESS_DDLIST';
export const DOCUMENTBYPROCESS_DDLIST_SUCCESS = 'DOCUMENTBYPROCESS_DDLIST_SUCCESS';
export const DOCUMENTBYPROCESS_DDLIST_ERROR = 'DOCUMENTBYPROCESS_DDLIST_ERROR';
export const DOCUMENTBYPROCESS_GET = 'DOCUMENTBYPROCESS_GET';
export const DOCUMENTBYPROCESS_GET_SUCCESS = 'DOCUMENTBYPROCESS_GET_SUCCESS';
export const DOCUMENTBYPROCESS_GET_ERROR = 'DOCUMENTBYPROCESS_GET_ERROR';
export const DOCUMENTBYPROCESS_NEW = 'DOCUMENTBYPROCESS_NEW';
export const DOCUMENTBYPROCESS_NEW_SUCCESS = 'DOCUMENTBYPROCESS_NEW_SUCCESS';
export const DOCUMENTBYPROCESS_NEW_ERROR = 'DOCUMENTBYPROCESS_NEW_ERROR';
export const DOCUMENTBYPROCESS_NEW_RECORD = 'DOCUMENTBYPROCESS_NEW_RECORD';
export const DOCUMENTBYPROCESS_EDIT = 'DOCUMENTBYPROCESS_EDIT';
export const DOCUMENTBYPROCESS_EDIT_SUCCESS = 'DOCUMENTBYPROCESS_EDIT_SUCCESS';
export const DOCUMENTBYPROCESS_EDIT_ERROR = 'DOCUMENTBYPROCESS_EDIT_ERROR';

// Variables de Employee
export const EMPLOYEE_LIST = 'EMPLOYEE_LIST';
export const EMPLOYEE_LIST_SUCCESS = 'EMPLOYEE_LIST_SUCCESS';
export const EMPLOYEE_LIST_ERROR = 'EMPLOYEE_LIST_ERROR';
export const EMPLOYEE_DDLIST = 'EMPLOYEE_DDLIST';
export const EMPLOYEE_DDLIST_SUCCESS = 'EMPLOYEE_DDLIST_SUCCESS';
export const EMPLOYEE_DDLIST_ERROR = 'EMPLOYEE_DDLIST_ERROR';
export const EMPLOYEE_GET = 'EMPLOYEE_GET';
export const EMPLOYEE_GET_SUCCESS = 'EMPLOYEE_GET_SUCCESS';
export const EMPLOYEE_GET_ERROR = 'EMPLOYEE_GET_ERROR';
export const EMPLOYEE_GET_BY_USER_ID = 'EMPLOYEE_GET_BY_USER_ID';
export const EMPLOYEE_GET_BY_USER_ID_SUCCESS = 'EMPLOYEE_GET_BY_USER_ID_SUCCESS';
export const EMPLOYEE_GET_BY_USER_ID_ERROR = 'EMPLOYEE_GET_BY_USER_ID_ERROR';
export const EMPLOYEE_NEW = 'EMPLOYEE_NEW';
export const EMPLOYEE_NEW_SUCCESS = 'EMPLOYEE_NEW_SUCCESS';
export const EMPLOYEE_NEW_ERROR = 'EMPLOYEE_NEW_ERROR';
export const EMPLOYEE_NEW_RECORD = 'EMPLOYEE_NEW_RECORD';
export const EMPLOYEE_EDIT = 'EMPLOYEE_EDIT';
export const EMPLOYEE_EDIT_SUCCESS = 'EMPLOYEE_EDIT_SUCCESS';
export const EMPLOYEE_EDIT_ERROR = 'EMPLOYEE_EDIT_ERROR';
export const EMPLOYEE_CHARGE_CODE_DDLIST = 'EMPLOYEE_CHARGE_CODE_DDLIST';
export const EMPLOYEE_CHARGE_CODE_DDLIST_SUCCESS = 'EMPLOYEE_CHARGE_CODE_DDLIST_SUCCESS';
export const EMPLOYEE_CHARGE_CODE_DDLIST_ERROR = 'EMPLOYEE_CHARGE_CODE_DDLIST_ERROR';

// Variables de Entity
export const ENTITY_LIST = 'ENTITY_LIST';
export const ENTITY_LIST_SUCCESS = 'ENTITY_LIST_SUCCESS';
export const ENTITY_LIST_ERROR = 'ENTITY_LIST_ERROR';
export const ENTITY_DDLIST = 'ENTITY_DDLIST';
export const ENTITY_DDLIST_SUCCESS = 'ENTITY_DDLIST_SUCCESS';
export const ENTITY_DDLIST_ERROR = 'ENTITY_DDLIST_ERROR';

// Variables de MasterData
export const ENTITYMDBYENTITY_LIST = 'ENTITYMDBYENTITY_LIST';
export const ENTITYMDBYENTITY_LIST_SUCCESS = 'ENTITYMDBYENTITY_LIST_SUCCESS';
export const ENTITYMDBYENTITY_LIST_ERROR = 'ENTITYMDBYENTITY_LIST_ERROR';
export const ENTITYMD_LIST = 'ENTITYMD_LIST';
export const ENTITYMD_LIST_SUCCESS = 'ENTITYMD_LIST_SUCCESS';
export const ENTITYMD_LIST_ERROR = 'ENTITYMD_LIST_ERROR';
export const ENTITYMD_DDLIST = 'ENTITYMD_DDLIST';
export const ENTITYMD_DDLIST_SUCCESS = 'ENTITYMD_DDLIST_SUCCESS';
export const ENTITYMD_DDLIST_ERROR = 'ENTITYMD_DDLIST_ERROR';
export const ENTITYMD_BYCODE_SUCCESS = 'ENTITYMD_BYCODE_SUCCESS';
export const ENTITYMD_BYCODE_ERROR = 'ENTITYMD_BYCODE_ERROR';
export const ENTITYMD_GET = 'ENTITYMD_GET';
export const ENTITYMD_GET_SUCCESS = 'ENTITYMD_GET_SUCCESS';
export const ENTITYMD_GET_ERROR = 'ENTITYMD_GET_ERROR';
export const ENTITYMD_NEW = 'ENTITYMD_NEW';
export const ENTITYMD_NEW_SUCCESS = 'ENTITYMD_NEW_SUCCESS';
export const ENTITYMD_NEW_ERROR = 'ENTITYMD_NEW_ERROR';
export const ENTITYMD_NEW_RECORD = 'ENTITYMD_NEW_RECORD';
export const ENTITYMD_EDIT = 'ENTITYMD_EDIT';
export const ENTITYMD_EDIT_SUCCESS = 'ENTITYMD_EDIT_SUCCESS';
export const ENTITYMD_EDIT_ERROR = 'ENTITYMD_EDIT_ERROR';
export const ENTITYMDBYENTITYCODE_DDLIST_SUCCESS = 'ENTITYMDBYENTITYCODE_DDLIST_SUCCESS';

// Contantes de Fleet (Flota)
export const FLEET_LIST = 'FLEET_LIST';
export const FLEET_LIST_SUCCESS = 'FLEET_LIST_SUCCESS';
export const FLEET_LIST_ERROR = 'FLEET_LIST_ERROR';
export const FLEET_DDLIST = 'FLEET_DDLIST';
export const FLEET_DDLIST_SUCCESS = 'FLEET_DDLIST_SUCCESS';
export const FLEET_DDLIST_ERROR = 'FLEET_DDLIST_ERROR';
export const FLEET_NEW = 'FLEET_NEW';
export const FLEET_NEW_SUCCESS = 'FLEET_NEW_SUCCESS';
export const FLEET_NEW_ERROR = 'FLEET_NEW_ERROR';
export const FLEET_EDIT = 'FLEET_EDIT';
export const FLEET_EDIT_SUCCESS = 'FLEET_EDIT_SUCCESS';
export const FLEET_EDIT_ERROR = 'FLEET_EDIT_ERROR';

// Variables de ImageByProcess
export const IMAGE_BY_PROCESS_LIST = 'IMAGE_BY_PROCESS_LIST';
export const IMAGE_BY_PROCESS_LIST_SUCCESS = 'IMAGE_BY_PROCESS_LIST_SUCCESS';
export const IMAGE_BY_PROCESS_LIST_ERROR = 'IMAGE_BY_PROCESS_LIST_ERROR';
export const IMAGE_BY_PROCESS_DDLIST = 'IMAGE_BY_PROCESS_DDLIST';
export const IMAGE_BY_PROCESS_DDLIST_SUCCESS = 'IMAGE_BY_PROCESS_DDLIST_SUCCESS';
export const IMAGE_BY_PROCESS_DDLIST_ERROR = 'IMAGE_BY_PROCESS_DDLIST_ERROR';
export const IMAGE_BY_PROCESS_GET = 'IMAGE_BY_PROCESS_GET';
export const IMAGE_BY_PROCESS_GET_SUCCESS = 'IMAGE_BY_PROCESS_GET_SUCCESS';
export const IMAGE_BY_PROCESS_GET_ERROR = 'IMAGE_BY_PROCESS_GET_ERROR';
export const IMAGE_BY_PROCESS_NEW = 'IMAGE_BY_PROCESS_NEW';
export const IMAGE_BY_PROCESS_NEW_SUCCESS = 'IMAGE_BY_PROCESS_NEW_SUCCESS';
export const IMAGE_BY_PROCESS_NEW_ERROR = 'IMAGE_BY_PROCESS_NEW_ERROR';
export const IMAGE_BY_PROCESS_NEW_RECORD = 'IMAGE_BY_PROCESS_NEW_RECORD';
export const IMAGE_BY_PROCESS_EDIT = 'IMAGE_BY_PROCESS_EDIT';
export const IMAGE_BY_PROCESS_EDIT_SUCCESS = 'IMAGE_BY_PROCESS_EDIT_SUCCESS';
export const IMAGE_BY_PROCESS_EDIT_ERROR = 'IMAGE_BY_PROCESS_EDIT_ERROR';
export const IMAGE_BY_PROCESS_DELETE = 'IMAGE_BY_PROCESS_EDIT';
export const IMAGE_BY_PROCESS_DELETE_SUCCESS = 'IMAGE_BY_PROCESS_EDIT_SUCCESS';
export const IMAGE_BY_PROCESS_DELETE_ERROR = 'IMAGE_BY_PROCESS_EDIT_ERROR';

// Variables de Incoming
export const INCOMING_LIST = 'INCOMING_LIST';
export const INCOMING_LIST_SUCCESS = 'INCOMING_LIST_SUCCESS';
export const INCOMING_LIST_ERROR = 'INCOMING_LIST_ERROR';
export const INCOMING_BYBPARTNER_LIST = 'INCOMING_BYBPARTNER_LIST';
export const INCOMING_BYBPARTNER_LIST_SUCCESS = 'INCOMING_BYBPARTNER_LIST_SUCCESS';
export const INCOMING_BYBPARTNER_LIST_ERROR = 'INCOMING_BYBPARTNER_LIST_ERROR';
export const INCOMING_DDLIST = 'INCOMING_DDLIST';
export const INCOMING_DDLIST_SUCCESS = 'INCOMING_DDLIST_SUCCESS';
export const INCOMING_DDLIST_ERROR = 'INCOMING_DDLIST_ERROR';
export const INCOMING_GET = 'INCOMING_GET';
export const INCOMING_GET_SUCCESS = 'INCOMING_GET_SUCCESS';
export const INCOMING_GET_ERROR = 'INCOMING_GET_ERROR';
export const INCOMING_NEW = 'INCOMING_NEW';
export const INCOMING_NEW_SUCCESS = 'INCOMING_NEW_SUCCESS';
export const INCOMING_NEW_ERROR = 'INCOMING_NEW_ERROR';
export const INCOMING_NEW_RECORD = 'INCOMING_NEW_RECORD';
export const INCOMING_EDIT = 'INCOMING_EDIT';
export const INCOMING_EDIT_SUCCESS = 'INCOMING_EDIT_SUCCESS';
export const INCOMING_EDIT_ERROR = 'INCOMING_EDIT_ERROR';

// Variables de Intervalos
export const INTERVAL_LIST = 'INTERVAL_LIST';
export const INTERVAL_LIST_SUCCESS = 'INTERVAL_LIST_SUCCESS';
export const INTERVAL_LIST_ERROR = 'INTERVAL_LIST_ERROR';
export const INTERVAL_DDLIST = 'INTERVAL_DDLIST';
export const INTERVAL_DDLIST_SUCCESS = 'INTERVAL_DDLIST_SUCCESS';
export const INTERVAL_DDLIST_ERROR = 'INTERVAL_DDLIST_ERROR';
export const INTERVAL_GET = 'INTERVAL_GET';
export const INTERVAL_GET_SUCCESS = 'INTERVAL_GET_SUCCESS';
export const INTERVAL_GET_ERROR = 'INTERVAL_GET_ERROR';
export const INTERVAL_NEW = 'INTERVAL_NEW';
export const INTERVAL_NEW_SUCCESS = 'INTERVAL_NEW_SUCCESS';
export const INTERVAL_NEW_ERROR = 'INTERVAL_NEW_ERROR';
export const INTERVAL_NEW_RECORD = 'INTERVAL_NEW_RECORD';
export const INTERVAL_EDIT = 'INTERVAL_EDIT';
export const INTERVAL_EDIT_SUCCESS = 'INTERVAL_EDIT_SUCCESS';
export const INTERVAL_EDIT_ERROR = 'INTERVAL_EDIT_ERROR';

// Variables de ItemMaster
export const ITEMMASTER_LIST = 'ITEMMASTER_LIST';
export const ITEMMASTER_LIST_SUCCESS = 'ITEMMASTER_LIST_SUCCESS';
export const ITEMMASTER_LIST_ERROR = 'ITEMMASTER_LIST_ERROR';
export const ITEMMASTER_ALTER_LIST = 'ITEMMASTER_ALTER_LIST';
export const ITEMMASTER_ALTER_LIST_SUCCESS = 'ITEMMASTER_ALTER_LIST_SUCCESS';
export const ITEMMASTER_ALTER_LIST_ERROR = 'ITEMMASTER_ALTER_LIST_ERROR';
export const ITEMMASTER_DDLIST = 'ITEMMASTER_DDLIST';
export const ITEMMASTER_DDLIST_SUCCESS = 'ITEMMASTER_DDLIST_SUCCESS';
export const ITEMMASTER_DDLIST_ERROR = 'ITEMMASTER_DDLIST_ERROR';
export const ITEMMASTER_ACTIVE_DDLIST = 'ITEMMASTER_ACTIVE_DDLIST';
export const ITEMMASTER_ACTIVE_DDLIST_SUCCESS = 'ITEMMASTER_ACTIVE_DDLIST_SUCCESS';
export const ITEMMASTER_ACTIVE_DDLIST_ERROR = 'ITEMMASTER_ACTIVE_DDLIST_ERROR';
export const ITEMMASTER_ALTERNATE_DDLIST = 'ITEMMASTER_ALTERNATE_DDLIST';
export const ITEMMASTER_ALTERNATE_DDLIST_SUCCESS = 'ITEMMASTER_ALTERNATE_DDLIST_SUCCESS';
export const ITEMMASTER_ALTERNATE_DDLIST_ERROR = 'ITEMMASTER_ALTERNATE_DDLIST_ERROR';
export const ITEMMASTER_GET = 'ITEMMASTER_GET';
export const ITEMMASTER_GET_SUCCESS = 'ITEMMASTER_GET_SUCCESS';
export const ITEMMASTER_GET_ERROR = 'ITEMMASTER_GET_ERROR';
export const ITEMMASTER_NEW = 'ITEMMASTER_NEW';
export const ITEMMASTER_NEW_SUCCESS = 'ITEMMASTER_NEW_SUCCESS';
export const ITEMMASTER_NEW_ERROR = 'ITEMMASTER_NEW_ERROR';
export const ITEMMASTER_NEW_RECORD = 'ITEMMASTER_NEW_RECORD';
export const ITEMMASTER_DUPLICATE = 'ITEMMASTER_DUPLICATE';
export const ITEMMASTER_DUPLICATE_SUCCESS = 'ITEMMASTER_DUPLICATE_SUCCESS';
export const ITEMMASTER_DUPLICATE_ERROR = 'ITEMMASTER_DUPLICATE_ERROR';
export const ITEMMASTER_EDIT = 'ITEMMASTER_EDIT';
export const ITEMMASTER_EDIT_SUCCESS = 'ITEMMASTER_EDIT_SUCCESS';
export const ITEMMASTER_EDIT_ERROR = 'ITEMMASTER_EDIT_ERROR';

// Variables de ItemInventory
export const ITEMINVENTORY_LIST = 'ITEMINVENTORY_LIST';
export const ITEMINVENTORY_LIST_SUCCESS = 'ITEMINVENTORY_LIST_SUCCESS';
export const ITEMINVENTORY_LIST_ERROR = 'ITEMINVENTORY_LIST_ERROR';

export const ITEMINVENTORY_LIST_TOLOCATE = 'ITEMINVENTORY_LIST_TOLOCATE';
export const ITEMINVENTORY_LIST_TOLOCATE_SUCCESS = 'ITEMINVENTORY_LIST_TOLOCATE_SUCCESS';
export const ITEMINVENTORY_LIST_TOLOCATE_ERROR = 'ITEMINVENTORY_LIST_TOLOCATE_ERROR';

export const ITEMINVENTORY_DDLIST = 'ITEMINVENTORY_DDLIST';
export const ITEMINVENTORY_DDLIST_SUCCESS = 'ITEMINVENTORY_DDLIST_SUCCESS';
export const ITEMINVENTORY_DDLIST_ERROR = 'ITEMINVENTORY_DDLIST_ERROR';
export const ITEMINVENTORY_WAITING_FOR_INSPECTION_LIST =
    'ITEMINVENTORY_WAITING_FOR_INSPECTION_LIST';
export const ITEMINVENTORY_WAITING_FOR_INSPECTION_LIST_SUCCESS =
    'ITEMINVENTORY_WAITING_FOR_INSPECTION_LIST_SUCCESS';
export const ITEMINVENTORY_WAITING_FOR_INSPECTION_LIST_ERROR =
    'ITEMINVENTORY_WAITING_FOR_INSPECTION_LIST_ERROR';
export const ITEMINVENTORY_GET = 'ITEMINVENTORY_GET';
export const ITEMINVENTORY_GET_SUCCESS = 'ITEMINVENTORY_GET_SUCCESS';
export const ITEMINVENTORY_GET_ERROR = 'ITEMINVENTORY_GET_ERROR';
export const ITEMINVENTORY_NEW = 'ITEMINVENTORY_NEW';
export const ITEMINVENTORY_NEW_SUCCESS = 'ITEMINVENTORY_NEW_SUCCESS';
export const ITEMINVENTORY_NEW_ERROR = 'ITEMINVENTORY_NEW_ERROR';
export const ITEMINVENTORY_NEW_RECORD = 'ITEMINVENTORY_NEW_RECORD';
export const ITEMINVENTORYTOLOCATE_NEW = 'ITEMINVENTORYTOLOCATE_NEW';
export const ITEMINVENTORYTOLOCATE_NEW_SUCCESS = 'ITEMINVENTORYTOLOCATE_NEW_SUCCESS';
export const ITEMINVENTORYTOLOCATE_NEW_ERROR = 'ITEMINVENTORYTOLOCATE_NEW_ERROR';
export const ITEMINVENTORYTOLOCATE_NEW_RECORD = 'ITEMINVENTORYTOLOCATE_NEW_RECORD';
export const ITEMINVENTORY_EDIT = 'ITEMINVENTORY_EDIT';
export const ITEMINVENTORY_EDIT_SUCCESS = 'ITEMINVENTORY_EDIT_SUCCESS';
export const ITEMINVENTORY_EDIT_ERROR = 'ITEMINVENTORY_EDIT_ERROR';
export const ITEMINVENTORYTOLOCATE_EDIT = 'ITEMINVENTORYTOLOCATE_EDIT';
export const ITEMINVENTORYTOLOCATE_EDIT_SUCCESS = 'ITEMINVENTORYTOLOCATE_EDIT_SUCCESS';
export const ITEMINVENTORYTOLOCATE_EDIT_ERROR = 'ITEMINVENTORYTOLOCATE_EDIT_ERROR';
export const ITEMINVENTORY_WARRANTY_LIST = 'ITEMINVENTORY_WARRANTY_LIST';
export const ITEMINVENTORY_WARRANTY_LIST_SUCCESS = 'ITEMINVENTORY_WARRANTY_LIST_SUCCESS';
export const ITEMINVENTORY_WARRANTY_LIST_ERROR = 'ITEMINVENTORY_WARRANTY_LIST_ERROR';
export const ITEMINVENTORY_DDLISTVERYIMPORTANT = 'ITEMINVENTORY_DDLISTVERYIMPORTANT';
export const ITEMINVENTORY_DDLISTVERYIMPORTANT_SUCCESS = 'ITEMINVENTORY_DDLISTVERYIMPORTANT_SUCCESS';
export const ITEMINVENTORY_DDLISTVERYIMPORTANT_ERROR = 'ITEMINVENTORY_DDLISTVERYIMPORTANT_ERROR';
export const ITEMINVENTORY_ACCEPT_GET = 'ITEMINVENTORY_ACCEPT_GET';
export const ITEMINVENTORY_ACCEPT_GET_SUCCESS = 'ITEMINVENTORY_ACCEPT_GET_SUCCESS';
export const ITEMINVENTORY_ACCEPT_GET_ERROR = 'ITEMINVENTORY_ACCEPT_GET_ERROR';

export const ITEMINVENTORY_MATERIAL_REMOVED_WI_LIST = 'ITEMINVENTORY_MATERIAL_REMOVED_WI_LIST';
export const ITEMINVENTORY_MATERIAL_REMOVED_WI_LIST_SUCCESS = 'ITEMINVENTORY_MATERIAL_REMOVED_WI_LIST_SUCCESS';
export const ITEMINVENTORY_MATERIAL_REMOVED_WI_LIST_ERROR = 'ITEMINVENTORY_MATERIAL_REMOVED_WI_LIST_ERROR';

// Item inventory DD List Installation and Removal Parts
export const ITEMINVENTORY_INSTALLATION_AND_REMOVAL_DDLIST = 'ITEMINVENTORY_INSTALLATION_AND_REMOVAL_DDLIST';
export const ITEMINVENTORY_INSTALLATION_AND_REMOVAL_DDLIST_SUCCESS = 'ITEMINVENTORY_INSTALLATION_AND_REMOVAL_DDLIST_SUCCESS';
export const ITEMINVENTORY_INSTALLATION_AND_REMOVAL_DDLIST_ERROR = 'ITEMINVENTORY_INSTALLATION_AND_REMOVAL_DDLIST_ERROR';

// Constantes para Item Warranty
export const ITEMWARRANTY_NEW = 'ITEMWARRANTY_NEW';
export const ITEMWARRANTY_LIST = 'ITEMWARRANTY_LIST';
export const ITEMWARRANTY_LIST_SUCCESS = 'ITEMWARRANTY_LIST_SUCCESS';
export const ITEMWARRANTY_LIST_ERROR = 'ITEMWARRANTY_LIST_ERROR';

// Constantes de Manufacturer (Fabricante)
export const MANUFACTURER_LIST = 'MANUFACTURER_LIST';
export const MANUFACTURER_LIST_SUCCESS = 'MANUFACTURER_LIST_SUCCESS';
export const MANUFACTURER_LIST_ERROR = 'MANUFACTURER_LIST_ERROR';
export const MANUFACTURER_DDLIST = 'MANUFACTURER_DDLIST';
export const MANUFACTURER_DDLIST_SUCCESS = 'MANUFACTURER_DDLIST_SUCCESS';
export const MANUFACTURER_DDLIST_ERROR = 'MANUFACTURER_DDLIST_ERROR';
export const MANUFACTURER_NEW = 'MANUFACTURER_NEW';
export const MANUFACTURER_NEW_SUCCESS = 'MANUFACTURER_NEW_SUCCESS';
export const MANUFACTURER_NEW_ERROR = 'MANUFACTURER_NEW_ERROR';
export const MANUFACTURER_EDIT = 'MANUFACTURER_EDIT';
export const MANUFACTURER_EDIT_SUCCESS = 'MANUFACTURER_EDIT_SUCCESS';
export const MANUFACTURER_EDIT_ERROR = 'MANUFACTURER_EDIT_ERROR';

// Variables de Material Request / Solicitud de Material.
export const MATERIALREQUEST_LIST = 'MATERIALREQUEST_LIST';
export const MATERIALREQUEST_LIST_SUCCESS = 'MATERIALREQUEST_LIST_SUCCESS';
export const MATERIALREQUEST_LIST_ERROR = 'MATERIALREQUEST_LIST_ERROR';
export const MATERIALREQUEST_PENDING_LIST = 'MATERIALREQUEST_PENDING_LIST';
export const MATERIALREQUEST_PENDING_LIST_SUCCESS = 'MATERIALREQUEST_PENDING_LIST_SUCCESS';
export const MATERIALREQUEST_PENDING_LIST_ERROR = 'MATERIALREQUEST_PENDING_LIST_ERROR';
export const MATERIALREQUEST_RETURN_LIST = 'MATERIALREQUEST_RETURN_LIST';
export const MATERIALREQUEST_RETURN_LIST_SUCCESS = 'MATERIALREQUEST_RETURN_LIST_SUCCESS';
export const MATERIALREQUEST_RETURN_LIST_ERROR = 'MATERIALREQUEST_RETURN_LIST_ERROR';
export const MATERIALREQUEST_REPORT = 'MATERIALREQUEST_REPORT';
export const MATERIALREQUEST_REPORT_SUCCESS = 'MATERIALREQUEST_REPORT_SUCCESS';
export const MATERIALREQUEST_REPORT_ERROR = 'MATERIALREQUEST_REPORT_ERROR';
export const MATERIALREQUEST_GET = 'MATERIALREQUEST_GET';
export const MATERIALREQUEST_GET_SUCCESS = 'MATERIALREQUEST_GET_SUCCESS';
export const MATERIALREQUEST_GET_ERROR = 'MATERIALREQUEST_GET_ERROR';
export const MATERIALREQUEST_CLEAN = 'MATERIALREQUEST_CLEAN';
export const MATERIALREQUEST_NEW = 'MATERIALREQUEST_NEW';
export const MATERIALREQUEST_NEW_SUCCESS = 'MATERIALREQUEST_NEW_SUCCESS';
export const MATERIALREQUEST_NEW_ERROR = 'MATERIALREQUEST_NEW_ERROR';
export const MATERIALREQUEST_NEW_RECORD = 'MATERIALREQUEST_NEW_RECORD';
export const MATERIALREQUEST_RETURN_NEW = 'MATERIALREQUEST_RETURN_NEW';
export const MATERIALREQUEST_RETURN_NEW_SUCCESS = 'MATERIALREQUEST_RETURN_NEW_SUCCESS';
export const MATERIALREQUEST_RETURN_NEW_ERROR = 'MATERIALREQUEST_RETURN_NEW_ERROR';
export const MATERIALREQUEST_EDIT = 'MATERIALREQUEST_EDIT';
export const MATERIALREQUEST_EDIT_SUCCESS = 'MATERIALREQUEST_EDIT_SUCCESS';
export const MATERIALREQUEST_EDIT_ERROR = 'MATERIALREQUEST_EDIT_ERROR';
export const MATERIALREQUEST_RETURN_EDIT = 'MATERIALREQUEST_RETURN_EDIT';
export const MATERIALREQUEST_RETURN_EDIT_SUCCESS = 'MATERIALREQUEST_RETURN_EDIT_SUCCESS';
export const MATERIALREQUEST_RETURN_EDIT_ERROR = 'MATERIALREQUEST_RETURN_EDIT_ERROR';
export const MATERIALREQUEST_CANCELLATION = 'MATERIALREQUEST_CANCELLATION';
export const MATERIALREQUEST_CANCELLATION_SUCCESS = 'MATERIALREQUEST_CANCELLATION_SUCCESS';
export const MATERIALREQUEST_CANCELLATION_ERROR = 'MATERIALREQUEST_CANCELLATION_ERROR';
export const MATERIALREQUEST_DD_LIST = 'MATERIALREQUEST_DD_LIST';
export const MATERIALREQUEST_DD_LIST_SUCCESS = 'MATERIALREQUEST_DD_LIST_ERROR';
export const MATERIALREQUEST_DD_LIST_ERROR = 'MATERIALREQUEST_DD_LIST_ERROR';


// Variables de Material Request detalle / Solicitud de Material.
export const MATERIALREQUESTDETAIL_LIST_SUCCESS = 'MATERIALREQUESTDETAIL_LIST_SUCCESS';
export const MATERIALREQUESTDETAIL_LIST = 'MATERIALREQUESTDETAIL_LIST';
export const MATERIALREQUESTDETAIL_LIST_ERROR = 'MATERIALREQUESTDETAIL_LIST_ERROR';
export const MATERIALREQUESTDETAIL_GET = 'MATERIALREQUESTDETAIL_GET';
export const MATERIALREQUESTDETAIL_GET_SUCCESS = 'MATERIALREQUESTDETAIL_GET_SUCCESS';
export const MATERIALREQUESTDETAIL_GET_ERROR = 'MATERIALREQUESTDETAIL_GET_ERROR';
export const MATERIALREQUESTDETAIL_NEW = 'MATERIALREQUESTDETAIL_NEW';
export const MATERIALREQUESTDETAIL_NEW_SUCCESS = 'MATERIALREQUESTDETAIL_NEW_SUCCESS';
export const MATERIALREQUESTDETAIL_NEW_ERROR = 'MATERIALREQUESTDETAIL_NEW_ERROR';
export const MATERIALREQUESTDETAIL_NEW_RECORD = 'MATERIALREQUESTDETAIL_NEW_RECORD';
export const MATERIALREQUESTDETAIL_EDIT = 'MATERIALREQUESTDETAIL_EDIT';
export const MATERIALREQUESTDETAIL_EDIT_SUCCESS = 'MATERIALREQUESTDETAIL_EDIT_SUCCESS';
export const MATERIALREQUESTDETAIL_EDIT_ERROR = 'MATERIALREQUESTDETAIL_EDIT_ERROR';
export const MATERIALREQUEST_RUBBER_AND_COMPONENTS_DD_LIST = 'MATERIALREQUEST_RUBBER_AND_COMPONENTS_DD_LIST';
export const MATERIALREQUEST_RUBBER_AND_COMPONENTS_DD_LIST_SUCCESS = 'MATERIALREQUEST_RUBBER_AND_COMPONENTS_DD_LIST_SUCCESS';
export const MATERIALREQUEST_RUBBER_AND_COMPONENTS_DD_LIST_ERROR = 'MATERIALREQUEST_RUBBER_AND_COMPONENTS_DD_LIST_ERROR';

// Variables de Menu (Menu)
export const MENU_LIST = 'MENU_LIST';
export const MENU_LIST_SUCCESS = 'MENU_LIST_SUCCESS';
export const MENU_LIST_ERROR = 'MENU_LIST_ERROR';
export const MENU_CONFIG_LIST = 'MENU_CONFIG_LIST';
export const MENU_CONFIG_LIST_SUCCESS = 'MENU_CONFIG_LIST_SUCCESS';
export const MENU_CONFIG_LIST_ERROR = 'MENU_CONFIG_LIST_ERROR';
export const MENU_LIST_ALL = 'MENU_LIST_ALL';
export const MENU_LIST_ALL_SUCCESS = 'MENU_LIST_ALL_SUCCESS';
export const MENU_LIST_ALL_ERROR = 'MENU_LIST_ALL_ERROR';
export const MENU_DDLIST = 'MENU_DDLIST';
export const MENU_DDLIST_SUCCESS = 'MENU_DDLIST_SUCCESS';
export const MENU_DDLIST_ERROR = 'MENU_DDLIST_ERROR';
export const MENU_GET = 'MENU_GET';
export const MENU_GET_SUCCESS = 'MENU_GET_SUCCESS';
export const MENU_GET_ERROR = 'MENU_GET_ERROR';
export const MENU_NEW = 'MENU_NEW';
export const MENU_NEW_SUCCESS = 'MENU_NEW_SUCCESS';
export const MENU_NEW_ERROR = 'MENU_NEW_ERROR';
export const MENU_NEW_RECORD = 'MENU_NEW_RECORD';
export const MENU_EDIT = 'MENU_EDIT';
export const MENU_EDIT_SUCCESS = 'MENU_EDIT_SUCCESS';
export const MENU_EDIT_ERROR = 'MENU_EDIT_ERROR';

// Variables de Oma
export const OMA_LIST = 'OMA_LIST';
export const OMA_LIST_SUCCESS = 'OMA_LIST_SUCCESS';
export const OMA_LIST_ERROR = 'OMA_LIST_ERROR';
export const OMA_DDLIST = 'OMA_DDLIST';
export const OMA_DDLIST_SUCCESS = 'OMA_DDLIST_SUCCESS';
export const OMA_DDLIST_ERROR = 'OMA_DDLIST_ERROR';
export const OMA_GET = 'OMA_GET';
export const OMA_GET_SUCCESS = 'OMA_GET_SUCCESS';
export const OMA_GET_ERROR = 'OMA_GET_ERROR';
export const OMA_NEW = 'OMA_NEW';
export const OMA_NEW_SUCCESS = 'OMA_NEW_SUCCESS';
export const OMA_NEW_ERROR = 'OMA_NEW_ERROR';
export const OMA_NEW_RECORD = 'OMA_NEW_RECORD';
export const OMA_EDIT = 'OMA_EDIT';
export const OMA_EDIT_SUCCESS = 'OMA_EDIT_SUCCESS';
export const OMA_EDIT_ERROR = 'OMA_EDIT_ERROR';

// Variables de Cabecera de Aprobaciones de Pagos (PaymentApprovals)
export const PAYMENTAPPROVAL_LIST = 'PAYMENTAPPROVAL_LIST';
export const PAYMENTAPPROVAL_LIST_SUCCESS = 'PAYMENTAPPROVAL_LIST_SUCCESS';
export const PAYMENTAPPROVAL_LIST_ERROR = 'PAYMENTAPPROVAL_LIST_ERROR';
export const PAYMENTAPPROVAL_DDLIST = 'PAYMENTAPPROVAL_DDLIST';
export const PAYMENTAPPROVAL_DDLIST_SUCCESS = 'PAYMENTAPPROVAL_DDLIST_SUCCESS';
export const PAYMENTAPPROVAL_DDLIST_ERROR = 'PAYMENTAPPROVAL_DDLIST_ERROR';
export const PAYMENTAPPROVAL_GET = 'PAYMENTAPPROVAL_GET';
export const PAYMENTAPPROVAL_GET_SUCCESS = 'PAYMENTAPPROVAL_GET_SUCCESS';
export const PAYMENTAPPROVAL_GET_ERROR = 'PAYMENTAPPROVAL_GET_ERROR';
export const PAYMENTAPPROVAL_NEW = 'PAYMENTAPPROVAL_NEW';
export const PAYMENTAPPROVAL_NEW_SUCCESS = 'PAYMENTAPPROVAL_NEW_SUCCESS';
export const PAYMENTAPPROVAL_NEW_ERROR = 'PAYMENTAPPROVAL_NEW_ERROR';
export const PAYMENTAPPROVAL_NEW_RECORD = 'PAYMENTAPPROVAL_NEW_RECORD';
export const PAYMENTAPPROVAL_EDIT = 'PAYMENTAPPROVAL_EDIT';
export const PAYMENTAPPROVAL_EDIT_SUCCESS = 'PAYMENTAPPROVAL_EDIT_SUCCESS';
export const PAYMENTAPPROVAL_EDIT_ERROR = 'PAYMENTAPPROVAL_EDIT_ERROR';

// Variables de Cabecera de Pagos (PaymentHeader)
export const PAYMENTHEADER_LIST = 'PAYMENTHEADER_LIST';
export const PAYMENTHEADER_LIST_SUCCESS = 'PAYMENTHEADER_LIST_SUCCESS';
export const PAYMENTHEADER_LIST_ERROR = 'PAYMENTHEADER_LIST_ERROR';
export const PAYMENTHEADER_DDLIST = 'PAYMENTHEADER_DDLIST';
export const PAYMENTHEADER_DDLIST_SUCCESS = 'PAYMENTHEADER_DDLIST_SUCCESS';
export const PAYMENTHEADER_DDLIST_ERROR = 'PAYMENTHEADER_DDLIST_ERROR';
export const PAYMENTHEADER_GET = 'PAYMENTHEADER_GET';
export const PAYMENTHEADER_GET_SUCCESS = 'PAYMENTHEADER_GET_SUCCESS';
export const PAYMENTHEADER_GET_ERROR = 'PAYMENTHEADER_GET_ERROR';
export const PAYMENTHEADER_NEW = 'PAYMENTHEADER_NEW';
export const PAYMENTHEADER_NEW_SUCCESS = 'PAYMENTHEADER_NEW_SUCCESS';
export const PAYMENTHEADER_NEW_ERROR = 'PAYMENTHEADER_NEW_ERROR';
export const PAYMENTHEADER_NEW_RECORD = 'PAYMENTHEADER_NEW_RECORD';
export const PAYMENTHEADER_EDIT = 'PAYMENTHEADER_EDIT';
export const PAYMENTHEADER_EDIT_SUCCESS = 'PAYMENTHEADER_EDIT_SUCCESS';
export const PAYMENTHEADER_EDIT_ERROR = 'PAYMENTHEADER_EDIT_ERROR';

// Variables de Detalle de Pagos (PaymentDetail)
export const PAYMENTDETAIL_LIST = 'PAYMENTDETAIL_LIST';
export const PAYMENTDETAIL_LIST_SUCCESS = 'PAYMENTDETAIL_LIST_SUCCESS';
export const PAYMENTDETAIL_LIST_ERROR = 'PAYMENTDETAIL_LIST_ERROR';
export const PAYMENTDETAIL_DDLIST = 'PAYMENTDETAIL_DDLIST';
export const PAYMENTDETAIL_DDLIST_SUCCESS = 'PAYMENTDETAIL_DDLIST_SUCCESS';
export const PAYMENTDETAIL_DDLIST_ERROR = 'PAYMENTDETAIL_DDLIST_ERROR';
export const PAYMENTDETAIL_GET = 'PAYMENTDETAIL_GET';
export const PAYMENTDETAIL_GET_SUCCESS = 'PAYMENTDETAIL_GET_SUCCESS';
export const PAYMENTDETAIL_GET_ERROR = 'PAYMENTDETAIL_GET_ERROR';
export const PAYMENTDETAIL_NEW = 'PAYMENTDETAIL_NEW';
export const PAYMENTDETAIL_NEW_SUCCESS = 'PAYMENTDETAIL_NEW_SUCCESS';
export const PAYMENTDETAIL_NEW_ERROR = 'PAYMENTDETAIL_NEW_ERROR';
export const PAYMENTDETAIL_NEW_RECORD = 'PAYMENTDETAIL_NEW_RECORD';
export const PAYMENTDETAIL_EDIT = 'PAYMENTDETAIL_EDIT';
export const PAYMENTDETAIL_EDIT_SUCCESS = 'PAYMENTDETAIL_EDIT_SUCCESS';
export const PAYMENTDETAIL_EDIT_ERROR = 'PAYMENTDETAIL_EDIT_ERROR';

// Variables de Programación de Pagos (PaymentSchedule)
export const PAYMENTSCHEDULE_LIST = 'PAYMENTSCHEDULE_LIST';
export const PAYMENTSCHEDULE_LIST_SUCCESS = 'PAYMENTSCHEDULE_LIST_SUCCESS';
export const PAYMENTSCHEDULE_LIST_ERROR = 'PAYMENTSCHEDULE_LIST_ERROR';
export const PAYMENTSCHEDULE_DDLIST = 'PAYMENTSCHEDULE_DDLIST';
export const PAYMENTSCHEDULE_DDLIST_SUCCESS = 'PAYMENTSCHEDULE_DDLIST_SUCCESS';
export const PAYMENTSCHEDULE_DDLIST_ERROR = 'PAYMENTSCHEDULE_DDLIST_ERROR';
export const PAYMENTSCHEDULE_GET = 'PAYMENTSCHEDULE_GET';
export const PAYMENTSCHEDULE_GET_SUCCESS = 'PAYMENTSCHEDULE_GET_SUCCESS';
export const PAYMENTSCHEDULE_GET_ERROR = 'PAYMENTSCHEDULE_GET_ERROR';
export const PAYMENTSCHEDULE_NEW = 'PAYMENTSCHEDULE_NEW';
export const PAYMENTSCHEDULE_NEW_SUCCESS = 'PAYMENTSCHEDULE_NEW_SUCCESS';
export const PAYMENTSCHEDULE_NEW_ERROR = 'PAYMENTSCHEDULE_NEW_ERROR';
export const PAYMENTSCHEDULE_NEW_RECORD = 'PAYMENTSCHEDULE_NEW_RECORD';
export const PAYMENTSCHEDULE_EDIT = 'PAYMENTSCHEDULE_EDIT';
export const PAYMENTSCHEDULE_EDIT_SUCCESS = 'PAYMENTSCHEDULE_EDIT_SUCCESS';
export const PAYMENTSCHEDULE_EDIT_ERROR = 'PAYMENTSCHEDULE_EDIT_ERROR';

// Preincoming
export const PREINCOMING_LIST = 'PREINCOMING_LIST';
export const PREINCOMING_LIST_SUCCESS = 'PREINCOMING_LIST_SUCCESS';
export const PREINCOMING_LIST_ERROR = 'PREINCOMING_LIST_ERROR';
export const PREINCOMING_DDLIST = 'PREINCOMING_DDLIST';
export const PREINCOMING_DDLIST_SUCCESS = 'PREINCOMING_DDLIST_SUCCESS';
export const PREINCOMING_DDLIST_ERROR = 'PREINCOMING_DDLIST_ERROR';
export const PREINCOMING_GET = 'PREINCOMING_GET';
export const PREINCOMING_GET_SUCCESS = 'PREINCOMING_GET_SUCCESS';
export const PREINCOMING_GET_ERROR = 'PREINCOMING_GET_ERROR';
export const PREINCOMING_NEW = 'PREINCOMING_NEW';
export const PREINCOMING_NEW_SUCCESS = 'PREINCOMING_NEW_SUCCESS';
export const PREINCOMING_NEW_ERROR = 'PREINCOMING_NEW_ERROR';
export const PREINCOMING_NEW_RECORD = 'PREINCOMING_NEW_RECORD';
export const PREINCOMING_EDIT = 'PREINCOMING_EDIT';
export const PREINCOMING_EDIT_SUCCESS = 'PREINCOMING_EDIT_SUCCESS';
export const PREINCOMING_EDIT_ERROR = 'PREINCOMING_EDIT_ERROR';

// Constantes de Priority By Process (Prioridad por Proceso)
export const PRIORITY_BY_PROCESS_LIST = 'PRIORITY_BY_PROCESS_LIST';
export const PRIORITY_BY_PROCESS_LIST_SUCCESS = 'PRIORITY_BY_PROCESS_LIST_SUCCESS';
export const PRIORITY_BY_PROCESS_LIST_ERROR = 'PRIORITY_BY_PROCESS_LIST_ERROR';
export const PRIORITY_BY_PROCESS_DDLIST = 'PRIORITY_BY_PROCESS_DDLIST';
export const PRIORITY_BY_PROCESS_DDLIST_SUCCESS = 'PRIORITY_BY_PROCESS_DDLIST_SUCCESS';
export const PRIORITY_BY_PROCESS_DDLIST_ERROR = 'PRIORITY_BY_PROCESS_DDLIST_ERROR';
export const PRIORITY_BY_PROCESS_GET = 'PRIORITY_BY_PROCESS_GET';
export const PRIORITY_BY_PROCESS_GET_SUCCESS = 'PRIORITY_BY_PROCESS_GET_SUCCESS';
export const PRIORITY_BY_PROCESS_GET_ERROR = 'PRIORITY_BY_PROCESS_GET_ERROR';
export const PRIORITY_BY_PROCESS_NEW = 'PRIORITY_BY_PROCESS_NEW';
export const PRIORITY_BY_PROCESS_NEW_SUCCESS = 'PRIORITY_BY_PROCESS_NEW_SUCCESS';
export const PRIORITY_BY_PROCESS_NEW_ERROR = 'PRIORITY_BY_PROCESS_NEW_ERROR';
export const PRIORITY_BY_PROCESS_NEW_RECORD = 'PRIORITY_BY_PROCESS_NEW_RECORD';
export const PRIORITY_BY_PROCESS_EDIT = 'PRIORITY_BY_PROCESS_EDIT';
export const PRIORITY_BY_PROCESS_EDIT_SUCCESS = 'PRIORITY_BY_PROCESS_EDIT_SUCCESS';
export const PRIORITY_BY_PROCESS_EDIT_ERROR = 'PRIORITY_BY_PROCESS_EDIT_ERROR';

// Constantes de Requisiones de Compra
export const PURCHASEREQUISITION_LIST = 'PURCHASEREQUISITION_LIST';
export const PURCHASEREQUISITION_LIST_SUCCESS = 'PURCHASEREQUISITION_LIST_SUCCESS';
export const PURCHASEREQUISITION_LIST_ERROR = 'PURCHASEREQUISITION_LIST_ERROR';
export const PURCHASEREQUISITION_DDLIST = 'PURCHASEREQUISITION_DDLIST';
export const PURCHASEREQUISITION_DDLIST_SUCCESS = 'PURCHASEREQUISITION_DDLIST_SUCCESS';
export const PURCHASEREQUISITION_DDLIST_ERROR = 'PURCHASEREQUISITION_DDLIST_ERROR';
export const PURCHASEREQUISITION_GET = 'PURCHASEREQUISITION_GET';
export const PURCHASEREQUISITION_GET_SUCCESS = 'PURCHASEREQUISITION_GET_SUCCESS';
export const PURCHASEREQUISITION_GET_ERROR = 'PURCHASEREQUISITION_GET_ERROR';
export const PURCHASEREQUISITION_NEW = 'PURCHASEREQUISITION_NEW';
export const PURCHASEREQUISITION_NEW_SUCCESS = 'PURCHASEREQUISITION_NEW_SUCCESS';
export const PURCHASEREQUISITION_NEW_ERROR = 'PURCHASEREQUISITION_NEW_ERROR';
export const PURCHASEREQUISITION_NEW_RECORD = 'PURCHASEREQUISITION_NEW_RECORD';
export const PURCHASEREQUISITION_EDIT = 'PURCHASEREQUISITION_EDIT';
export const PURCHASEREQUISITION_EDIT_SUCCESS = 'PURCHASEREQUISITION_EDIT_SUCCESS';
export const PURCHASEREQUISITION_EDIT_ERROR = 'PURCHASEREQUISITION_EDIT_ERROR';
export const PURCHASEREQUISITION_SAVE_IN_REDUCER = 'PURCHASEREQUISITION_SAVE_IN_REDUCER';

// Constantes de Cotizaciones de Proveedor
export const QUOTATIONBPARTNER_LIST = 'QUOTATIONBPARTNER_LIST';
export const QUOTATIONBPARTNER_LIST_SUCCESS = 'QUOTATIONBPARTNER_LIST_SUCCESS';
export const QUOTATIONBPARTNER_LIST_ERROR = 'QUOTATIONBPARTNER_LIST_ERROR';
export const QUOTATIONBYREQUISITION_LIST = 'QUOTATIONBYREQUISITION_LIST';
export const QUOTATIONBYREQUISITION_LIST_SUCCESS = 'QUOTATIONBYREQUISITION_LIST_SUCCESS';
export const QUOTATIONBYREQUISITION_LIST_ERROR = 'QUOTATIONBYREQUISITION_LIST_ERROR';
export const QUOTATIONBPARTNER_DDLIST = 'QUOTATIONBPARTNER_DDLIST';
export const QUOTATIONBPARTNER_DDLIST_SUCCESS = 'QUOTATIONBPARTNER_DDLIST_SUCCESS';
export const QUOTATIONBPARTNER_DDLIST_ERROR = 'QUOTATIONBPARTNER_DDLIST_ERROR';
export const QUOTATIONBPARTNER_GET = 'QUOTATIONBPARTNER_GET';
export const QUOTATIONBPARTNER_GET_SUCCESS = 'QUOTATIONBPARTNER_GET_SUCCESS';
export const QUOTATIONBPARTNER_GET_ERROR = 'QUOTATIONBPARTNER_GET_ERROR';
export const QUOTATIONBYBPARTNER_GET = 'QUOTATIONBYBPARTNER_GET';
export const QUOTATIONBYBPARTNER_GET_SUCCESS = 'QUOTATIONBYBPARTNER_GET_SUCCESS';
export const QUOTATIONBYBPARTNER_GET_ERROR = 'QUOTATIONBYBPARTNER_GET_ERROR';
export const QUOTATIONBPARTNER_NEW = 'QUOTATIONBPARTNER_NEW';
export const QUOTATIONBPARTNER_NEW_SUCCESS = 'QUOTATIONBPARTNER_NEW_SUCCESS';
export const QUOTATIONBPARTNER_NEW_ERROR = 'QUOTATIONBPARTNER_NEW_ERROR';
export const QUOTATIONBPARTNER_NEW_RECORD = 'QUOTATIONBPARTNER_NEW_RECORD';
export const QUOTATIONBPARTNER_EDIT = 'QUOTATIONBPARTNER_EDIT';
export const QUOTATIONBPARTNER_EDIT_SUCCESS = 'QUOTATIONBPARTNER_EDIT_SUCCESS';
export const QUOTATIONBPARTNER_EDIT_ERROR = 'QUOTATIONBPARTNER_EDIT_ERROR';

// Variables de Sub-Ata
export const SUBATA_LIST = 'SUBATA_LIST';
export const SUBATA_LIST_SUCCESS = 'SUBATA_LIST_SUCCESS';
export const SUBATA_LIST_ERROR = 'SUBATA_LIST_ERROR';
export const SUBATA_DDLIST = 'SUBATA_DDLIST';
export const SUBATA_DDLIST_SUCCESS = 'SUBATA_DDLIST_SUCCESS';
export const SUBATA_DDLIST_ERROR = 'SUBATA_DDLIST_ERROR';
export const SUBATA_GET = 'SUBATA_GET';
export const SUBATA_GET_SUCCESS = 'SUBATA_GET_SUCCESS';
export const SUBATA_GET_ERROR = 'SUBATA_GET_ERROR';
export const SUBATA_NEW = 'SUBATA_NEW';
export const SUBATA_NEW_SUCCESS = 'SUBATA_NEW_SUCCESS';
export const SUBATA_NEW_ERROR = 'SUBATA_NEW_ERROR';
export const SUBATA_NEW_RECORD = 'SUBATA_NEW_RECORD';
export const SUBATA_EDIT = 'SUBATA_EDIT';
export const SUBATA_EDIT_SUCCESS = 'SUBATA_EDIT_SUCCESS';
export const SUBATA_EDIT_ERROR = 'SUBATA_EDIT_ERROR';

// Variables de Parámetros de Evaluación por Proveedor
export const SUPPLIEREVALUATIONPARAM_LIST = 'SUPPLIEREVALUATIONPARAM_LIST';
export const SUPPLIEREVALUATIONPARAM_LIST_SUCCESS = 'SUPPLIEREVALUATIONPARAM_LIST_SUCCESS';
export const SUPPLIEREVALUATIONPARAM_LIST_ERROR = 'SUPPLIEREVALUATIONPARAM_LIST_ERROR';
export const SUPPLIEREVALUATIONPARAM_DDLIST = 'SUPPLIEREVALUATIONPARAM_DDLIST';
export const SUPPLIEREVALUATIONPARAM_DDLIST_SUCCESS = 'SUPPLIEREVALUATIONPARAM_DDLIST_SUCCESS';
export const SUPPLIEREVALUATIONPARAM_DDLIST_ERROR = 'SUPPLIEREVALUATIONPARAM_DDLIST_ERROR';
export const SUPPLIEREVALUATIONPARAM_GET = 'SUPPLIEREVALUATIONPARAM_GET';
export const SUPPLIEREVALUATIONPARAM_GET_SUCCESS = 'SUPPLIEREVALUATIONPARAM_GET_SUCCESS';
export const SUPPLIEREVALUATIONPARAM_GET_ERROR = 'SUPPLIEREVALUATIONPARAM_GET_ERROR';
export const SUPPLIEREVALUATIONPARAM_NEW = 'SUPPLIEREVALUATIONPARAM_NEW';
export const SUPPLIEREVALUATIONPARAM_NEW_SUCCESS = 'SUPPLIEREVALUATIONPARAM_NEW_SUCCESS';
export const SUPPLIEREVALUATIONPARAM_NEW_ERROR = 'SUPPLIEREVALUATIONPARAM_NEW_ERROR';
export const SUPPLIEREVALUATIONPARAM_NEW_RECORD = 'SUPPLIEREVALUATIONPARAM_NEW_RECORD';
export const SUPPLIEREVALUATIONPARAM_EDIT = 'SUPPLIEREVALUATIONPARAM_EDIT';
export const SUPPLIEREVALUATIONPARAM_EDIT_SUCCESS = 'SUPPLIEREVALUATIONPARAM_EDIT_SUCCESS';
export const SUPPLIEREVALUATIONPARAM_EDIT_ERROR = 'SUPPLIEREVALUATIONPARAM_EDIT_ERROR';

// Variables de Tax / Impuestos
export const TAX_LIST = 'TAX_LIST';
export const TAX_LIST_SUCCESS = 'TAX_LIST_SUCCESS';
export const TAX_LIST_ERROR = 'TAX_LIST_ERROR';
export const TAX_DDLIST = 'TAX_DDLIST';
export const TAX_DDLIST_SUCCESS = 'TAX_DDLIST_SUCCESS';
export const TAX_DDLIST_ERROR = 'TAX_DDLIST_ERROR';
export const TAX_GET = 'TAX_GET';
export const TAX_GET_SUCCESS = 'TAX_GET_SUCCESS';
export const TAX_GET_ERROR = 'TAX_GET_ERROR';
export const TAX_NEW = 'TAX_NEW';
export const TAX_NEW_SUCCESS = 'TAX_NEW_SUCCESS';
export const TAX_NEW_ERROR = 'TAX_NEW_ERROR';
export const TAX_NEW_RECORD = 'TAX_NEW_RECORD';
export const TAX_EDIT = 'TAX_EDIT';
export const TAX_EDIT_SUCCESS = 'TAX_EDIT_SUCCESS';
export const TAX_EDIT_ERROR = 'TAX_EDIT_ERROR';

// Variables de Unidades de Medida
export const UOM_LIST = 'UOM_LIST';
export const UOM_LIST_SUCCESS = 'UOM_LIST_SUCCESS';
export const UOM_LIST_ERROR = 'UOM_LIST_ERROR';
export const UOM_DDLIST = 'UOM_DDLIST';
export const UOM_DDLIST_SUCCESS = 'UOM_DDLIST_SUCCESS';
export const UOM_DDLIST_ERROR = 'UOM_DDLIST_ERROR';
export const UOM_DDTYPEUNITLIST = 'UOM_DDTYPEUNITLIST';
export const UOM_DDTYPEUNITLIST_SUCCESS = 'UOM_DDTYPEUNITLIST_SUCCESS';
export const UOM_DDTYPEUNITLIST_ERROR = 'UOM_DDTYPEUNITLIST_ERROR';
export const UOM_GET = 'UOM_GET';
export const UOM_GET_SUCCESS = 'UOM_GET_SUCCESS';
export const UOM_GET_ERROR = 'UOM_GET_ERROR';
export const UOM_NEW = 'UOM_NEW';
export const UOM_NEW_SUCCESS = 'UOM_NEW_SUCCESS';
export const UOM_NEW_ERROR = 'UOM_NEW_ERROR';
export const UOM_NEW_RECORD = 'UOM_NEW_RECORD';
export const UOM_EDIT = 'UOM_EDIT';
export const UOM_EDIT_SUCCESS = 'UOM_EDIT_SUCCESS';
export const UOM_EDIT_ERROR = 'UOM_EDIT_ERROR';

// Variables de Almacenes
export const WAREHOUSE_LIST = 'WAREHOUSE_LIST';
export const WAREHOUSE_LIST_SUCCESS = 'WAREHOUSE_LIST_SUCCESS';
export const WAREHOUSE_LIST_ERROR = 'WAREHOUSE_LIST_ERROR';
export const WAREHOUSE_DDLIST = 'WAREHOUSE_DDLIST';
export const WAREHOUSE_DDLIST_SUCCESS = 'WAREHOUSE_DDLIST_SUCCESS';
export const WAREHOUSE_DDLIST_ERROR = 'WAREHOUSE_DDLIST_ERROR';
export const WAREHOUSE_GET = 'WAREHOUSE_GET';
export const WAREHOUSE_GET_SUCCESS = 'WAREHOUSE_GET_SUCCESS';
export const WAREHOUSE_GET_ERROR = 'WAREHOUSE_GET_ERROR';
export const WAREHOUSE_NEW = 'WAREHOUSE_NEW';
export const WAREHOUSE_NEW_SUCCESS = 'WAREHOUSE_NEW_SUCCESS';
export const WAREHOUSE_NEW_ERROR = 'WAREHOUSE_NEW_ERROR';
export const WAREHOUSE_NEW_RECORD = 'WAREHOUSE_NEW_RECORD';
export const WAREHOUSE_EDIT = 'WAREHOUSE_EDIT';
export const WAREHOUSE_EDIT_SUCCESS = 'WAREHOUSE_EDIT_SUCCESS';
export const WAREHOUSE_EDIT_ERROR = 'WAREHOUSE_EDIT_ERROR';

// Variables de Zonas de Almacenes
export const WAREHOUSEZONE_LIST = 'WAREHOUSEZONE_LIST';
export const WAREHOUSEZONE_LIST_SUCCESS = 'WAREHOUSEZONE_LIST_SUCCESS';
export const WAREHOUSEZONE_LIST_ERROR = 'WAREHOUSEZONE_LIST_ERROR';
export const WAREHOUSEZONE_DDLIST = 'WAREHOUSEZONE_DDLIST';
export const WAREHOUSEZONE_DDLIST_SUCCESS = 'WAREHOUSEZONE_DDLIST_SUCCESS';
export const WAREHOUSEZONE_DDLIST_ERROR = 'WAREHOUSEZONE_DDLIST_ERROR';
export const WAREHOUSEZONE_GET = 'WAREHOUSEZONE_GET';
export const WAREHOUSEZONE_GET_SUCCESS = 'WAREHOUSEZONE_GET_SUCCESS';
export const WAREHOUSEZONE_GET_ERROR = 'WAREHOUSEZONE_GET_ERROR';
export const WAREHOUSEZONE_NEW = 'WAREHOUSEZONE_NEW';
export const WAREHOUSEZONE_NEW_SUCCESS = 'WAREHOUSEZONE_NEW_SUCCESS';
export const WAREHOUSEZONE_NEW_ERROR = 'WAREHOUSEZONE_NEW_ERROR';
export const WAREHOUSEZONE_NEW_RECORD = 'WAREHOUSEZONE_NEW_RECORD';
export const WAREHOUSEZONE_EDIT = 'WAREHOUSEZONE_EDIT';
export const WAREHOUSEZONE_EDIT_SUCCESS = 'WAREHOUSEZONE_EDIT_SUCCESS';
export const WAREHOUSEZONE_EDIT_ERROR = 'WAREHOUSEZONE_EDIT_ERROR';
export const WAREHOUSE_ALL_ZONE_DDLIST = 'WAREHOUSE_ALL_ZONE_DDLIST';
export const WAREHOUSE_ALL_ZONE_DDLIST_SUCCESS = 'WAREHOUSE_ALL_ZONE_DDLIST_SUCCESS';
export const WAREHOUSE_ALL_ZONE_DDLIST_ERROR = 'WAREHOUSE_ALL_ZONE_DDLIST_ERROR';

// Variables de Ubicaciones de Almacenes
export const WAREHOUSELOCATION_LIST = 'WAREHOUSELOCATION_LIST';
export const WAREHOUSELOCATION_LIST_SUCCESS = 'WAREHOUSELOCATION_LIST_SUCCESS';
export const WAREHOUSELOCATION_LIST_ERROR = 'WAREHOUSELOCATION_LIST_ERROR';
export const WAREHOUSELOCATION_DDLIST = 'WAREHOUSELOCATION_DDLIST';
export const WAREHOUSELOCATION_DDLIST_SUCCESS = 'WAREHOUSELOCATION_DDLIST_SUCCESS';
export const WAREHOUSELOCATION_DDLIST_ERROR = 'WAREHOUSELOCATION_DDLIST_ERROR';
export const WAREHOUSELOCATION_GET = 'WAREHOUSELOCATION_GET';
export const WAREHOUSELOCATION_GET_SUCCESS = 'WAREHOUSELOCATION_GET_SUCCESS';
export const WAREHOUSELOCATION_GET_ERROR = 'WAREHOUSELOCATION_GET_ERROR';
export const WAREHOUSELOCATION_NEW = 'WAREHOUSELOCATION_NEW';
export const WAREHOUSELOCATION_NEW_SUCCESS = 'WAREHOUSELOCATION_NEW_SUCCESS';
export const WAREHOUSELOCATION_NEW_ERROR = 'WAREHOUSELOCATION_NEW_ERROR';
export const WAREHOUSELOCATION_NEW_RECORD = 'WAREHOUSELOCATION_NEW_RECORD';
export const WAREHOUSELOCATION_EDIT = 'WAREHOUSELOCATION_EDIT';
export const WAREHOUSELOCATION_EDIT_SUCCESS = 'WAREHOUSELOCATION_EDIT_SUCCESS';
export const WAREHOUSELOCATION_EDIT_ERROR = 'WAREHOUSELOCATION_EDIT_ERROR';
export const WAREHOUSE_ALL_LOCATION_DDLIST = 'WAREHOUSE_ALL_LOCATION_DDLIST';
export const WAREHOUSE_ALL_LOCATION_DDLIST_SUCCESS = 'WAREHOUSE_ALL_LOCATION_DDLIST_SUCCESS';
export const WAREHOUSE_ALL_LOCATION_DDLIST_ERROR = 'WAREHOUSE_ALL_LOCATION_DDLIST_ERROR';

// Variables de User
export const USER_ALL_LIST = 'USER_ALL_LIST';
export const USER_ALL_LIST_SUCCESS = 'USER_ALL_LIST';
export const USER_ALL_LIST_ERROR = 'USER_ALL_LIST';
export const USER_LIST = 'USER_LIST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_ERROR = 'USER_LIST_ERROR';
export const USER_DDLIST = 'USER_DDLIST';
export const USER_DDLIST_SUCCESS = 'USER_DDLIST_SUCCESS';
export const USER_DDLIST_ERROR = 'USER_DDLIST_ERROR';
export const USER_DDLIST_FILTER = 'USER_DDLIST_FILTER';
export const USER_DDLIST_FILTER_SUCCESS = 'USER_DDLIST_FILTER_SUCCESS';
export const USER_DDLIST_FILTER_ERROR = 'USER_DDLIST_FILTER_ERROR';
export const USER_GET = 'USER_GET';
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS';
export const USER_GET_ERROR = 'USER_GET_ERROR';
export const USER_NEW = 'USER_NEW';
export const USER_NEW_SUCCESS = 'USER_NEW_SUCCESS';
export const USER_NEW_ERROR = 'USER_NEW_ERROR';
export const USER_NEW_RECORD = 'USER_NEW_RECORD';
export const USER_EDIT = 'USER_EDIT';
export const USER_EDIT_SUCCESS = 'USER_EDIT_SUCCESS';
export const USER_EDIT_ERROR = 'USER_EDIT_ERROR';
export const USER_PASSWORD_EDIT = 'USER_PASSWORD_EDIT';
export const USER_PASSWORD_EDIT_SUCCESS = 'USER_PASSWORD_EDIT_SUCCESS';
export const USER_PASSWORD_EDIT_ERROR = 'USER_PASSWORD_EDIT_ERROR';
export const USER_VALID_PASSWORD = 'USER_VALID_PASSWORD_EDIT';
export const USER_VALID_PASSWORD_SUCCESS = 'USER_VALID_PASSWORD_SUCCESS';
export const USER_VALID_PASSWORD_ERROR = 'USER_VALID_PASSWORD_ERROR';
export const USER_DDLIST_MATERIALDISPATCH = 'USER_DDLIST_MATERIALDISPATCH';
export const USER_DDLIST_MATERIALDISPATCH_SUCCESS = 'USER_DDLIST_MATERIALDISPATCH_SUCCESS';
export const USER_DDLIST_MATERIALDISPATCH_ERROR = 'USER_DDLIST_MATERIALDISPATCH_ERROR';
export const USER_DDLIST_TMA = 'USER_DDLIST_TMA';
export const USER_DDLIST_TMA_SUCCESS = 'USER_DDLIST_TMA_SUCCESS';
export const USER_DDLIST_TMA_ERROR = 'USER_DDLIST_TMA_ERROR';

// Variables de UserRole
export const USERROLE_LIST = 'USERROLE_LIST';
export const USERROLE_LIST_SUCCESS = 'USERROLE_LIST_SUCCESS';
export const USERROLE_LIST_ERROR = 'USERROLE_LIST_ERROR';
export const USERROLE_DDLIST = 'USERROLE_DDLIST';
export const USERROLE_DDLIST_SUCCESS = 'USERROLE_DDLIST_SUCCESS';
export const USERROLE_DDLIST_ERROR = 'USERROLE_DDLIST_ERROR';
export const USERROLE_GET = 'USERROLE_GET';
export const USERROLE_GET_SUCCESS = 'USERROLE_GET_SUCCESS';
export const USERROLE_GET_ERROR = 'USERROLE_GET_ERROR';
export const USERROLE_NEW = 'USERROLE_NEW';
export const USERROLE_NEW_SUCCESS = 'USERROLE_NEW_SUCCESS';
export const USERROLE_NEW_ERROR = 'USERROLE_NEW_ERROR';
export const USERROLE_NEW_RECORD = 'USERROLE_NEW_RECORD';
export const USERROLE_EDIT = 'USERROLE_EDIT';
export const USERROLE_EDIT_SUCCESS = 'USERROLE_EDIT_SUCCESS';
export const USERROLE_EDIT_ERROR = 'USERROLE_EDIT_ERROR';

export const USERROLES_LIST = 'USERROLES_LIST';
export const USERROLES_LIST_SUCCESS = 'USERROLES_LIST_SUCCESS';
export const USERROLES_LIST_ERROR = 'USERROLES_LIST_ERROR';

// Variables de User Security Question
export const USERSECURITYQUESTION_LIST = 'USERSECURITYQUESTION_LIST';
export const USERSECURITYQUESTION_LIST_SUCCESS = 'USERSECURITYQUESTION_LIST_SUCCESS';
export const USERSECURITYQUESTION_LIST_ERROR = 'USERSECURITYQUESTION_LIST_ERROR';
export const USERSECURITYQUESTION_DDLIST = 'USERSECURITYQUESTION_DDLIST';
export const USERSECURITYQUESTION_DDLIST_SUCCESS = 'USERSECURITYQUESTION_DDLIST_SUCCESS';
export const USERSECURITYQUESTION_DDLIST_ERROR = 'USERSECURITYQUESTION_DDLIST_ERROR';
export const USERSECURITYQUESTION_GET = 'USERSECURITYQUESTION_GET';
export const USERSECURITYQUESTION_GET_SUCCESS = 'USERSECURITYQUESTION_GET_SUCCESS';
export const USERSECURITYQUESTION_GET_ERROR = 'USERSECURITYQUESTION_GET_ERROR';
export const USERSECURITYQUESTION_NEW = 'USERSECURITYQUESTION_NEW';
export const USERSECURITYQUESTION_NEW_SUCCESS = 'USERSECURITYQUESTION_NEW_SUCCESS';
export const USERSECURITYQUESTION_NEW_ERROR = 'USERSECURITYQUESTION_NEW_ERROR';
export const USERSECURITYQUESTION_NEW_RECORD = 'USERSECURITYQUESTION_NEW_RECORD';
export const USERSECURITYQUESTION_EDIT = 'USERSECURITYQUESTION_EDIT';
export const USERSECURITYQUESTION_EDIT_SUCCESS = 'USERSECURITYQUESTION_EDIT_SUCCESS';
export const USERSECURITYQUESTION_EDIT_ERROR = 'USERSECURITYQUESTION_EDIT_ERROR';
export const USERSECURITYQUESTION_DELETE = 'USERSECURITYQUESTION_DELETE';
export const USERSECURITYQUESTION_DELETE_SUCCESS = 'USERSECURITYQUESTION_DELETE_SUCCESS';
export const USERSECURITYQUESTION_DELETE_ERROR = 'USERSECURITYQUESTION_DELETE_ERROR';
export const USERSECURITYQUESTION_RENDER = 'USERSECURITYQUESTION_RENDER';

// Variables de Category
export const CATEGORY_LIST = 'CATEGORY_LIST';
export const CATEGORY_LIST_SUCCESS = 'USERROLE_LIST_SUCCESS';
export const CATEGORY_LIST_ERROR = 'CATEGORY_LIST_ERROR';
export const CATEGORY_DDLIST = 'CATEGORY_DDLIST';
export const CATEGORY_DDLIST_SUCCESS = 'CATEGORY_DDLIST_SUCCESS';
export const CATEGORY_DDLIST_ERROR = 'CATEGORY_DDLIST_ERROR';
export const CATEGORY_GET = 'CATEGORY_GET';
export const CATEGORY_GET_SUCCESS = 'CATEGORY_GET_SUCCESS';
export const CATEGORY_GET_ERROR = 'CATEGORY_GET_ERROR';
export const CATEGORY_NEW = 'CATEGORY_NEW';
export const CATEGORY_NEW_SUCCESS = 'CATEGORY_NEW_SUCCESS';
export const CATEGORY_NEW_ERROR = 'CATEGORY_NEW_ERROR';
export const CATEGORY_NEW_RECORD = 'CATEGORY_NEW_RECORD';
export const CATEGORY_EDIT = 'CATEGORY_EDIT';
export const CATEGORY_EDIT_SUCCESS = 'CATEGORY_EDIT_SUCCESS';
export const CATEGORY_EDIT_ERROR = 'CATEGORY_EDIT_ERROR';

// Variables de UserRole
export const SUBCATEGORY_LIST = 'SUBCATEGORY_LIST';
export const SUBCATEGORY_LIST_SUCCESS = 'SUBCATEGORY_LIST_SUCCESS';
export const SUBCATEGORY_LIST_ERROR = 'SUBCATEGORY_LIST_ERROR';
export const SUBCATEGORY_BYCATEGORY_LIST = 'SUBCATEGORY_BYCATEGORY_LIST';
export const SUBCATEGORY_BYCATEGORY_LIST_SUCCESS = 'SUBCATEGORY_BYCATEGORY_LIST_SUCCESS';
export const SUBCATEGORY_BYCATEGORY_LIST_ERROR = 'SUBCATEGORY_BYCATEGORY_LIST_ERROR';
export const SUBCATEGORY_DDLIST = 'SUBCATEGORY_DDLIST';
export const SUBCATEGORY_DDLIST_SUCCESS = 'SUBCATEGORY_DDLIST_SUCCESS';
export const SUBCATEGORY_DDLIST_ERROR = 'SUBCATEGORY_DDLIST_ERROR';
export const SUBCATEGORY_GET = 'SUBCATEGORY_GET';
export const SUBCATEGORY_GET_SUCCESS = 'SUBCATEGORY_GET_SUCCESS';
export const SUBCATEGORY_GET_ERROR = 'SUBCATEGORY_GET_ERROR';
export const SUBCATEGORY_NEW = 'SUBCATEGORY_NEW';
export const SUBCATEGORY_NEW_SUCCESS = 'SUBCATEGORY_NEW_SUCCESS';
export const SUBCATEGORY_NEW_ERROR = 'SUBCATEGORY_NEW_ERROR';
export const SUBCATEGORY_NEW_RECORD = 'SUBCATEGORY_NEW_RECORD';
export const SUBCATEGORY_EDIT = 'SUBCATEGORY_EDIT';
export const SUBCATEGORY_EDIT_SUCCESS = 'SUBCATEGORY_EDIT_SUCCESS';
export const SUBCATEGORY_EDIT_ERROR = 'SUBCATEGORY_EDIT_ERROR';

// Purchase Requirements Varibles
export const PURCHASEREQUIREMENT_LIST = 'PURCHASEREQUIREMENT_LIST';
export const PURCHASEREQUIREMENT_LIST_SUCCESS = 'PURCHASEREQUIREMENT_LIST_SUCCESS';
export const PURCHASEREQUIREMENT_LIST_ERROR = 'PURCHASEREQUIREMENT_LIST_ERROR';
export const PURCHASEREQUIREMENT_GET = 'PURCHASEREQUIREMENT_GET';
export const PURCHASEREQUIREMENT_GET_SUCCESS = 'PURCHASEREQUIREMENT_GET_SUCCESS';
export const PURCHASEREQUIREMENT_GET_ERROR = 'PURCHASEREQUIREMENT_GET_ERROR';
export const PURCHASEREQUIREMENT_REPORT_GET = 'PURCHASEREQUIREMENT_REPORT_GET';
export const PURCHASEREQUIREMENT_REPORT_GET_SUCCESS = 'PURCHASEREQUIREMENT_REPORT_GET_SUCCESS';
export const PURCHASEREQUIREMENT_REPORT_GET_ERROR = 'PURCHASEREQUIREMENT_REPORT_GET_ERROR';
export const PURCHASEREQUIREMENT_NEW = 'PURCHASEREQUIREMENT_NEW';
export const PURCHASEREQUIREMENT_NEW_SUCCESS = 'PURCHASEREQUIREMENT_NEW_SUCCESS';
export const PURCHASEREQUIREMENT_NEW_ERROR = 'PURCHASEREQUIREMENT_NEW_ERROR';
export const PURCHASEREQUIREMENT_EDIT = 'PURCHASEREQUIREMENT_EDIT';
export const PURCHASEREQUIREMENT_EDIT_SUCCESS = 'PURCHASEREQUIREMENT_EDIT_SUCCESS';
export const PURCHASEREQUIREMENT_EDIT_ERROR = 'PURCHASEREQUIREMENT_EDIT_ERROR';
export const PURCHASEREQUIREMENT_CLEAN = 'PURCHASEREQUIREMENT_CLEAN';
export const PURCHASEREQUIREMENTDETAIL_DD_LIST = 'PURCHASEREQUIREMENTDETAIL_DD_LIST';
export const PURCHASEREQUIREMENTDETAIL_DD_LIST_ERROR = 'PURCHASEREQUIREMENTDETAIL_DD_LIST_ERROR';
export const PURCHASEREQUIREMENTDETAIL_DD_LIST_SUCCESS = 'PURCHASEREQUIREMENTDETAIL_DD_LIST_SUCCESS';
export const PURCHASEREQUIREMENT_REPORT_STOCK_FOR_PURCHASE_GET = 'PURCHASEREQUIREMENT_REPORT_STOCK_FOR_PURCHASE_GET';
export const PURCHASEREQUIREMENT_REPORT_STOCK_FOR_PURCHASE_GET_SUCCESS = 'PURCHASEREQUIREMENT_REPORT_STOCK_FOR_PURCHASE_GET_SUCCESS';
export const PURCHASEREQUIREMENT_REPORT_STOCK_FOR_PURCHASE_GET_ERROR = 'PURCHASEREQUIREMENT_REPORT_STOCK_FOR_PURCHASE_GET_ERROR';

// Component Assembled variables
export const COMPONENT_ASSEMBLED_HEADER_LIST = 'COMPONENT_ASSEMBLED_HEADER_LIST';
export const COMPONENT_ASSEMBLED_HEADER_LIST_SUCCESS = 'COMPONENT_ASSEMBLED_HEADER_LIST_SUCCESS';
export const COMPONENT_ASSEMBLED_HEADER_LIST_ERROR = 'COMPONENT_ASSEMBLED_HEADER_LIST_ERROR';
export const COMPONENT_ASSEMBLED_HEADER_DDLIST = 'COMPONENT_ASSEMBLED_HEADER_DDLIST';
export const COMPONENT_ASSEMBLED_HEADER_DDLIST_SUCCESS =
    'COMPONENT_ASSEMBLED_HEADER_DDLIST_SUCCESS';
export const COMPONENT_ASSEMBLED_HEADER_DDLIST_ERROR = 'COMPONENT_ASSEMBLED_HEADER_DDLIST_ERROR';
export const COMPONENT_ASSEMBLED_HEADER_GET = 'COMPONENT_ASSEMBLED_HEADER_GET';
export const COMPONENT_ASSEMBLED_HEADER_GET_SUCCESS = 'COMPONENT_ASSEMBLED_HEADER_GET_SUCCESS';
export const COMPONENT_ASSEMBLED_HEADER_GET_ERROR = 'COMPONENT_ASSEMBLED_HEADER_GET_ERROR';
export const COMPONENT_ASSEMBLED_HEADER_NEW = 'COMPONENT_ASSEMBLED_HEADER_NEW';
export const COMPONENT_ASSEMBLED_HEADER_NEW_SUCCESS = 'COMPONENT_ASSEMBLED_HEADER_NEW_SUCCESS';
export const COMPONENT_ASSEMBLED_HEADER_NEW_ERROR = 'COMPONENT_ASSEMBLED_HEADER_NEW_ERROR';
export const COMPONENT_ASSEMBLED_HEADER_NEW_RECORD = 'COMPONENT_ASSEMBLED_HEADER_NEW_RECORD';
export const COMPONENT_ASSEMBLED_HEADER_EDIT = 'COMPONENT_ASSEMBLED_HEADER_EDIT';
export const COMPONENT_ASSEMBLED_HEADER_EDIT_SUCCESS = 'COMPONENT_ASSEMBLED_HEADER_EDIT_SUCCESS';
export const COMPONENT_ASSEMBLED_HEADER_EDIT_ERROR = 'COMPONENT_ASSEMBLED_HEADER_EDIT_ERROR';
export const COMPONENT_ASSEMBLED_HEADER_ID_CLEAN = 'COMPONENT_ASSEMBLED_HEADER_ID_CLEAN';
export const COMPONENT_ASSEMBLED_HEADER_ID_SET = 'COMPONENT_ASSEMBLED_HEADER_ID_SET';
export const COMPONENT_ASSEMBLED_HEADER_SAVE = 'COMPONENT_ASSEMBLED_HEADER_SAVE';

// Component Assembled Item variables
export const COMPONENT_ASSEMBLED_ITEM_LIST = 'COMPONENT_ASSEMBLED_ITEM_LIST';
export const COMPONENT_ASSEMBLED_ITEM_LIST_SUCCESS = 'COMPONENT_ASSEMBLED_ITEM_LIST_SUCCESS';
export const COMPONENT_ASSEMBLED_ITEM_LIST_ERROR = 'COMPONENT_ASSEMBLED_ITEM_LIST_ERROR';
export const COMPONENT_ASSEMBLED_ITEM_DDLIST = 'COMPONENT_ASSEMBLED_ITEM_DDLIST';
export const COMPONENT_ASSEMBLED_ITEM_DDLIST_SUCCESS = 'COMPONENT_ASSEMBLED_ITEM_DDLIST_SUCCESS';
export const COMPONENT_ASSEMBLED_ITEM_DDLIST_ERROR = 'COMPONENT_ASSEMBLED_ITEM_DDLIST_ERROR';
export const COMPONENT_ASSEMBLED_ITEM_GET = 'COMPONENT_ASSEMBLED_ITEM_GET';
export const COMPONENT_ASSEMBLED_ITEM_GET_SUCCESS = 'COMPONENT_ASSEMBLED_ITEM_GET_SUCCESS';
export const COMPONENT_ASSEMBLED_ITEM_GET_ERROR = 'COMPONENT_ASSEMBLED_ITEM_GET_ERROR';
export const COMPONENT_ASSEMBLED_ITEM_NEW = 'COMPONENT_ASSEMBLED_ITEM_NEW';
export const COMPONENT_ASSEMBLED_ITEM_NEW_SUCCESS = 'COMPONENT_ASSEMBLED_ITEM_NEW_SUCCESS';
export const COMPONENT_ASSEMBLED_ITEM_NEW_ERROR = 'COMPONENT_ASSEMBLED_ITEM_NEW_ERROR';
export const COMPONENT_ASSEMBLED_ITEM_NEW_RECORD = 'COMPONENT_ASSEMBLED_ITEM_NEW_RECORD';
export const COMPONENT_ASSEMBLED_ITEM_EDIT = 'COMPONENT_ASSEMBLED_ITEM_EDIT';
export const COMPONENT_ASSEMBLED_ITEM_EDIT_SUCCESS = 'COMPONENT_ASSEMBLED_ITEM_EDIT_SUCCESS';
export const COMPONENT_ASSEMBLED_ITEM_EDIT_ERROR = 'COMPONENT_ASSEMBLED_ITEM_EDIT_ERROR';

// Airworthiness Directive
export const AIRWORTHINESSDIRECTIVE_LIST = 'AIRWORTHINESSDIRECTIVE_LIST';
export const AIRWORTHINESSDIRECTIVE_LIST_SUCCESS = 'AIRWORTHINESSDIRECTIVE_LIST_SUCCESS';
export const AIRWORTHINESSDIRECTIVE_LIST_ERROR = 'AIRWORTHINESSDIRECTIVE_LIST_ERROR';
export const AIRWORTHINESSDIRECTIVE_GET = 'AIRWORTHINESSDIRECTIVE_GET';
export const AIRWORTHINESSDIRECTIVE_GET_SUCCESS = 'AIRWORTHINESSDIRECTIVE_GET_SUCCESS';
export const AIRWORTHINESSDIRECTIVE_GET_ERROR = 'AIRWORTHINESSDIRECTIVE_GET_ERROR';
export const AIRWORTHINESSDIRECTIVE_NEW = 'AIRWORTHINESSDIRECTIVE_NEW';
export const AIRWORTHINESSDIRECTIVE_NEW_SUCCESS = 'AIRWORTHINESSDIRECTIVE_NEW_SUCCESS';
export const AIRWORTHINESSDIRECTIVE_NEW_ERROR = 'AIRWORTHINESSDIRECTIVE_NEW_ERROR';
export const AIRWORTHINESSDIRECTIVE_EDIT = 'AIRWORTHINESSDIRECTIVE_EDIT';
export const AIRWORTHINESSDIRECTIVE_EDIT_SUCCESS = 'AIRWORTHINESSDIRECTIVE_EDIT_SUCCESS';
export const AIRWORTHINESSDIRECTIVE_EDIT_ERROR = 'AIRWORTHINESSDIRECTIVE_EDIT_ERROR';
export const AIRWORTHINESSDIRECTIVE_CLEAN = 'AIRWORTHINESSDIRECTIVE_CLEAN';

// Airworthiness Directive Task
export const AIRWORTHINESSDIRECTIVETASK_LIST = 'AIRWORTHINESSDIRECTIVETASK_LIST';
export const AIRWORTHINESSDIRECTIVETASK_LIST_SUCCESS = 'AIRWORTHINESSDIRECTIVETASK_LIST_SUCCESS';
export const AIRWORTHINESSDIRECTIVETASK_LIST_ERROR = 'AIRWORTHINESSDIRECTIVETASK_LIST_ERROR';
export const AIRWORTHINESSDIRECTIVETASK_GET = 'AIRWORTHINESSDIRECTIVETASK_GET';
export const AIRWORTHINESSDIRECTIVETASK_GET_SUCCESS = 'AIRWORTHINESSDIRECTIVETASK_GET_SUCCESS';
export const AIRWORTHINESSDIRECTIVETASK_GET_ERROR = 'AIRWORTHINESSDIRECTIVETASK_GET_ERROR';
export const AIRWORTHINESSDIRECTIVETASK_NEW = 'AIRWORTHINESSDIRECTIVETASK_NEW';
export const AIRWORTHINESSDIRECTIVETASK_NEW_SUCCESS = 'AIRWORTHINESSDIRECTIVETASK_NEW_SUCCESS';
export const AIRWORTHINESSDIRECTIVETASK_NEW_ERROR = 'AIRWORTHINESSDIRECTIVETASK_NEW_ERROR';
export const AIRWORTHINESSDIRECTIVETASK_EDIT = 'AIRWORTHINESSDIRECTIVETASK_EDIT';
export const AIRWORTHINESSDIRECTIVETASK_EDIT_SUCCESS = 'AIRWORTHINESSDIRECTIVETASK_EDIT_SUCCESS';
export const AIRWORTHINESSDIRECTIVETASK_EDIT_ERROR = 'AIRWORTHINESSDIRECTIVETASK_EDIT_ERROR';
export const AIRWORTHINESSDIRECTIVETASK_CLEAN = 'AIRWORTHINESSDIRECTIVETASK_CLEAN';

// Equipment reducers type
export const EQUIPMENT_LIST = 'EQUIPMENT_LIST';
export const EQUIPMENT_LIST_SUCCESS = 'EQUIPMENT_LIST_SUCCESS';
export const EQUIPMENT_LIST_ERROR = 'EQUIPMENT_LIST_ERROR';
export const EQUIPMENT_DD_LIST = 'EQUIPMENT_DD_LIST';
export const EQUIPMENT_DD_LIST_SUCCESS = 'EQUIPMENT_DD_LIST_SUCCESS';
export const EQUIPMENT_DD_LIST_ERROR = 'EQUIPMENT_DD_LIST_ERROR';
export const EQUIPMENT_SAVE = 'EQUIPMENT_SAVE';
export const EQUIPMENT_SAVE_SUCCESS = 'EQUIPMENT_SAVE_SUCCESS';
export const EQUIPMENT_SAVE_ERROR = 'EQUIPMENT_SAVE_ERROR';
export const EQUIPMENT_GET = 'EQUIPMENT_GET';
export const EQUIPMENT_GET_SUCCESS = 'EQUIPMENT_GET_SUCCESS';
export const EQUIPMENT_GET_ERROR = 'EQUIPMENT_GET_ERROR';
export const EQUIPMENT_SUB_CATEGORY_DD_LIST = 'EQUIPMENT_SUB_CATEGORY_DD_LIST';
export const EQUIPMENT_SUB_CATEGORY_DD_LIST_SUCCESS = 'EQUIPMENT_SUB_CATEGORY_DD_LIST_SUCCESS';
export const EQUIPMENT_SUB_CATEGORY_DD_LIST_ERROR = 'EQUIPMENT_SUB_CATEGORY_DD_LIST_ERROR';


// Equipment Log reducers type
export const EQUIPMENT_LOG_LIST = 'EQUIPMENT_LOG_LIST';
export const EQUIPMENT_LOG_LIST_SUCCESS = 'EQUIPMENT_LOG_LIST_SUCCESS';
export const EQUIPMENT_LOG_LIST_ERROR = 'EQUIPMENT_LOG_LIST_ERROR';
export const EQUIPMENT_LOG_DD_LIST = 'EQUIPMENT_LOG_DD_LIST';
export const EQUIPMENT_LOG_DD_LIST_SUCCESS = 'EQUIPMENT_LOG_DD_LIST_SUCCESS';
export const EQUIPMENT_LOG_DD_LIST_ERROR = 'EQUIPMENT_LOG_DD_LIST_ERROR';
export const EQUIPMENT_LOG_SAVE = 'EQUIPMENT_LOG_SAVE';
export const EQUIPMENT_LOG_SAVE_SUCCESS = 'EQUIPMENT_LOG_SAVE_SUCCESS';
export const EQUIPMENT_LOG_SAVE_ERROR = 'EQUIPMENT_LOG_SAVE_ERROR';
export const EQUIPMENT_LOG_GET = 'EQUIPMENT_LOG_GET';
export const EQUIPMENT_LOG_GET_SUCCESS = 'EQUIPMENT_LOG_GET_SUCCESS';
export const EQUIPMENT_LOG_GET_ERROR = 'EQUIPMENT_LOG_GET_ERROR';

// Roles reducers type
export const ROLES_LIST = 'ROLES_LIST';
export const ROLES_LIST_SUCCESS = 'ROLES_LIST_SUCCESS';
export const ROLES_LIST_ERROR = 'ROLES_LIST_ERROR';
export const ROLES_DD_LIST = 'ROLES_DD_LIST';
export const ROLES_DD_LIST_SUCCESS = 'ROLES_DD_LIST_SUCCESS';
export const ROLES_DD_LIST_ERROR = 'ROLES_DD_LIST_ERROR';
export const ROLES_SAVE = 'ROLES_SAVE';
export const ROLES_SAVE_SUCCESS = 'ROLES_SAVE_SUCCESS';
export const ROLES_SAVE_ERROR = 'ROLES_SAVE_ERROR';
export const ROLES_GET = 'ROLES_GET';
export const ROLES_GET_SUCCESS = 'ROLES_GET_SUCCESS';
export const ROLES_GET_ERROR = 'ROLES_GET_ERROR';

// Menu By Roles reducers type
export const MENU_ROLE_LIST = 'MENU_ROLE_LIST';
export const MENU_ROLE_LIST_SUCCESS = 'MENU_ROLE_LIST_SUCCESS';
export const MENU_ROLE_LIST_ERROR = 'MENU_ROLE_LIST_ERROR';
export const MENU_ROLE_DD_LIST = 'MENU_ROLE_DD_LIST';
export const MENU_ROLE_DD_LIST_SUCCESS = 'MENU_ROLE_DD_LIST_SUCCESS';
export const MENU_ROLE_DD_LIST_ERROR = 'MENU_ROLE_DD_LIST_ERROR';
export const MENU_ROLE_SAVE = 'MENU_ROLE_SAVE';
export const MENU_ROLE_SAVE_SUCCESS = 'MENU_ROLE_SAVE_SUCCESS';
export const MENU_ROLE_SAVE_ERROR = 'MENU_ROLE_SAVE_ERROR';
export const MENU_ROLE_GET = 'MENU_ROLE_GET';
export const MENU_ROLE_GET_SUCCESS = 'MENU_ROLE_GET_SUCCESS';
export const MENU_ROLE_GET_ERROR = 'MENU_ROLE_GET_ERROR';

//Quote Management Variables
export const QUOTEMANAGEMENT_LIST = 'QUOTEMANAGEMENT_LIST';
export const QUOTEMANAGEMENT_LIST_SUCCESS = 'QUOTEMANAGEMENT_LIST_SUCCESS';
export const QUOTEMANAGEMENT_LIST_ERROR = 'QUOTEMANAGEMENT_LIST_ERROR';
export const QUOTEMANAGEMENT_GET = 'QUOTEMANAGEMENT_GET';
export const QUOTEMANAGEMENT_GET_SUCCESS = 'QUOTEMANAGEMENT_GET_SUCCESS';
export const QUOTEMANAGEMENT_GET_ERROR = 'QUOTEMANAGEMENT_GET_ERROR';
export const QUOTEMANAGEMENT_NEW = 'QUOTEMANAGEMENT_NEW';
export const QUOTEMANAGEMENT_NEW_SUCCESS = 'QUOTEMANAGEMENT_NEW_SUCCESS';
export const QUOTEMANAGEMENT_NEW_ERROR = 'QUOTEMANAGEMENT_NEW_ERROR';
export const QUOTEMANAGEMENT_EDIT = 'QUOTEMANAGEMENT_EDIT';
export const QUOTEMANAGEMENT_EDIT_SUCCESS = 'QUOTEMANAGEMENT_EDIT_SUCCESS';
export const QUOTEMANAGEMENT_EDIT_ERROR = 'QUOTEMANAGEMENT_EDIT_ERROR';
export const QUOTEMANAGEMENT_DELETE = 'QUOTEMANAGEMENT_DELETE';
export const QUOTEMANAGEMENT_DELETE_SUCCESS = 'QUOTEMANAGEMENT_DELETE_SUCCESS';
export const QUOTEMANAGEMENT_DELETE_ERROR = 'QUOTEMANAGEMENT_DELETE_ERROR';
export const QUOTEMANAGEMENT_CLEAN = 'QUOTEMANAGEMENT_CLEAN';
export const QUOTEMANAGEMENT_DD_LIST = 'QUOTEMANAGEMENT_DD_LIST';
export const QUOTEMANAGEMENT_DD_LIST_SUCCESS = 'QUOTEMANAGEMENT_DD_LIST_SUCCESS';
export const QUOTEMANAGEMENT_DD_LIST_ERROR = 'QUOTEMANAGEMENT_DD_LIST_ERROR';

//Quote Management Detail Variables
export const QUOTEMANAGEMENT_DETAIL_LIST = 'QUOTEMANAGEMENT_DETAIL_LIST';
export const QUOTEMANAGEMENT_DETAIL_LIST_SUCCESS = 'QUOTEMANAGEMENT_DETAIL_LIST_SUCCESS';
export const QUOTEMANAGEMENT_DETAIL_LIST_ERROR = 'QUOTEMANAGEMENT_DETAIL_LIST_ERROR';
export const QUOTEMANAGEMENT_DETAIL_GET = 'QUOTEMANAGEMENT_DETAIL_GET';
export const QUOTEMANAGEMENT_DETAIL_GET_SUCCESS = 'QUOTEMANAGEMENT_DETAIL_GET_SUCCESS';
export const QUOTEMANAGEMENT_DETAIL_GET_ERROR = 'QUOTEMANAGEMENT_DETAIL_GET_ERROR';
export const QUOTEMANAGEMENT_DETAIL_NEW = 'QUOTEMANAGEMENT_DETAIL_NEW';
export const QUOTEMANAGEMENT_DETAIL_NEW_SUCCESS = 'QUOTEMANAGEMENT_DETAIL_NEW_SUCCESS';
export const QUOTEMANAGEMENT_DETAIL_NEW_ERROR = 'QUOTEMANAGEMENT_DETAIL_NEW_ERROR';
export const QUOTEMANAGEMENT_DETAIL_EDIT = 'QUOTEMANAGEMENT_DETAIL_EDIT';
export const QUOTEMANAGEMENT_DETAIL_EDIT_SUCCESS = 'QUOTEMANAGEMENT_DETAIL_EDIT_SUCCESS';
export const QUOTEMANAGEMENT_DETAIL_EDIT_ERROR = 'QUOTEMANAGEMENT_DETAIL_EDIT_ERROR';
export const QUOTEMANAGEMENT_DETAIL_DELETE = 'QUOTEMANAGEMENT_DETAIL_DELETE';
export const QUOTEMANAGEMENT_DETAIL_DELETE_SUCCESS = 'QUOTEMANAGEMENT_DETAIL_DELETE_SUCCESS';
export const QUOTEMANAGEMENT_DETAIL_DELETE_ERROR = 'QUOTEMANAGEMENT_DETAIL_DELETE_ERROR';
export const QUOTEMANAGEMENT_DETAIL_CLEAN = 'QUOTEMANAGEMENT_DETAIL_CLEAN';
export const QUOTEMANAGEMENT_DETAIL_DD_LIST = 'QUOTEMANAGEMENT_DETAIL_DD_LIST';
export const QUOTEMANAGEMENT_DETAIL_DD_LIST_SUCCESS = 'QUOTEMANAGEMENT_DETAIL_DD_LIST_SUCCESS';
export const QUOTEMANAGEMENT_DETAIL_DD_LIST_ERROR = 'QUOTEMANAGEMENT_DETAIL_DD_LIST_ERROR';

//Airplane Model Management
export const AIRPLANE_MODEL_MANAGEMENT_LIST  = 'AIRPLANE_MODEL_MANAGEMENT_LIST';
export const AIRPLANE_MODEL_MANAGEMENT_LIST_SUCCESS = 'AIRPLANE_MODEL_MANAGEMENT_LIST_SUCCESS';
export const AIRPLANE_MODEL_MANAGEMENT_LIST_ERROR = 'AIRPLANE_MODEL_MANAGEMENT_LIST_ERROR';
export const AIRPLANE_MODEL_MANAGEMENT_GET = 'AIRPLANE_MODEL_MANAGEMENT_GET';
export const AIRPLANE_MODEL_MANAGEMENT_GET_SUCCESS = 'AIRPLANE_MODEL_MANAGEMENT_GET_SUCCESS';
export const AIRPLANE_MODEL_MANAGEMENT_GET_ERROR = 'AIRPLANE_MODEL_MANAGEMENT_GET_ERROR';
export const AIRPLANE_MODEL_MANAGEMENT_NEW = 'AIRPLANE_MODEL_MANAGEMENT_NEW';
export const AIRPLANE_MODEL_MANAGEMENT_NEW_SUCCESS = 'AIRPLANE_MODEL_MANAGEMENT_NEW_SUCCESS';
export const AIRPLANE_MODEL_MANAGEMENT_NEW_ERROR = 'AIRPLANE_MODEL_MANAGEMENT_NEW_ERROR';
export const AIRPLANE_MODEL_MANAGEMENT_EDIT = 'AIRPLANE_MODEL_MANAGEMENT_EDIT';
export const AIRPLANE_MODEL_MANAGEMENT_EDIT_SUCCESS = 'AIRPLANE_MODEL_MANAGEMENT_EDIT_SUCCESS';
export const AIRPLANE_MODEL_MANAGEMENT_EDIT_ERROR = 'AIRPLANE_MODEL_MANAGEMENT_EDIT_ERROR';
export const AIRPLANE_MODEL_MANAGEMENT_DELETE = 'AIRPLANE_MODEL_MANAGEMENT_DELETE';
export const AIRPLANE_MODEL_MANAGEMENT_DELETE_SUCCESS = 'AIRPLANE_MODEL_MANAGEMENT_DELETE_SUCCESS';
export const AIRPLANE_MODEL_MANAGEMENT_DELETE_ERROR = 'AIRPLANE_MODEL_MANAGEMENT_DELETE_ERROR';

//Airplane Series Management

export const AIRPLANE_SERIES_MANAGEMENT_LIST  = 'AIRPLANE_SERIES_MANAGEMENT_LIST';
export const AIRPLANE_SERIES_MANAGEMENT_LIST_SUCCESS = 'AIRPLANE_SERIES_MANAGEMENT_LIST_SUCCESS';
export const AIRPLANE_SERIES_MANAGEMENT_LIST_ERROR = 'AIRPLANE_SERIES_MANAGEMENT_LIST_ERROR';
export const AIRPLANE_SERIES_MANAGEMENT_GET = 'AIRPLANE_SERIES_MANAGEMENT_GET';
export const AIRPLANE_SERIES_MANAGEMENT_GET_SUCCESS = 'AIRPLANE_SERIES_MANAGEMENT_GET_SUCCESS';
export const AIRPLANE_SERIES_MANAGEMENT_GET_ERROR = 'AIRPLANE_SERIES_MANAGEMENT_GET_ERROR';
export const AIRPLANE_SERIES_MANAGEMENT_NEW = 'AIRPLANE_SERIES_MANAGEMENT_NEW';
export const AIRPLANE_SERIES_MANAGEMENT_NEW_SUCCESS = 'AIRPLANE_SERIES_MANAGEMENT_NEW_SUCCESS';
export const AIRPLANE_SERIES_MANAGEMENT_NEW_ERROR = 'AIRPLANE_SERIES_MANAGEMENT_NEW_ERROR';
export const AIRPLANE_SERIES_MANAGEMENT_EDIT = 'AIRPLANE_SERIES_MANAGEMENT_EDIT';
export const AIRPLANE_SERIES_MANAGEMENT_EDIT_SUCCESS = 'AIRPLANE_SERIES_MANAGEMENT_EDIT_SUCCESS';
export const AIRPLANE_SERIES_MANAGEMENT_EDIT_ERROR = 'AIRPLANE_SERIES_MANAGEMENT_EDIT_ERROR';
export const AIRPLANE_SERIES_MANAGEMENT_DELETE = 'AIRPLANE_SERIES_MANAGEMENT_DELETE';
export const AIRPLANE_SERIES_MANAGEMENT_DELETE_SUCCESS = 'AIRPLANE_SERIES_MANAGEMENT_DELETE_SUCCESS';
export const AIRPLANE_SERIES_MANAGEMENT_DELETE_ERROR = 'AIRPLANE_SERIES_MANAGEMENT_DELETE_ERROR';
export const AIRPLANE_SERIES_DDLIST = 'AIRPLANE_SERIES_DDLIST';
export const AIRPLANE_SERIES_DDLIST_SUCCESS = 'AIRPLANE_SERIES_DDLIST_SUCESS';
export const AIRPLANE_SERIES_DDLIST_ERROR = 'AIRPLANE_SERIES_DDLIST_ERROR';
//Correlative Management Variables

export const CORRELATIVE_MANAGEMENT_LIST = 'CORRELATIVE_MANAGEMENT_LIST';
export const CORRELATIVE_MANAGEMENT_LIST_SUCCESS = 'CORRELATIVE_MANAGEMENT_LIST_SUCCESS';
export const CORRELATIVE_MANAGEMENT_LIST_ERROR = 'CORRELATIVE_MANAGEMENT_LIST_ERROR';
export const CORRELATIVE_MANAGEMENT_GET = 'CORRELATIVE_MANAGEMENT_GET';
export const CORRELATIVE_MANAGEMENT_GET_SUCCESS = 'CORRELATIVE_MANAGEMENT_GET_SUCCESS';
export const CORRELATIVE_MANAGEMENT_GET_ERROR = 'CORRELATIVE_MANAGEMENT_GET_ERROR';
export const CORRELATIVE_MANAGEMENT_NEW = 'CORRELATIVE_MANAGEMENT_NEW';
export const CORRELATIVE_MANAGEMENT_NEW_SUCCESS = 'CORRELATIVE_MANAGEMENT_NEW_SUCCESS';
export const CORRELATIVE_MANAGEMENT_NEW_ERROR = 'CORRELATIVE_MANAGEMENT_NEW_ERROR';
export const CORRELATIVE_MANAGEMENT_EDIT = 'CORRELATIVE_MANAGEMENT_EDIT';
export const CORRELATIVE_MANAGEMENT_EDIT_SUCCESS = 'CORRELATIVE_MANAGEMENT_EDIT_SUCCESS';
export const CORRELATIVE_MANAGEMENT_EDIT_ERROR = 'CORRELATIVE_MANAGEMENT_EDIT_ERROR';
export const CORRELATIVE_MANAGEMENT_DELETE = 'CORRELATIVE_MANAGEMENT_DELETE';
export const CORRELATIVE_MANAGEMENT_DELETE_SUCCESS = 'CORRELATIVE_MANAGEMENT_DELETE_SUCCESS'; 
export const CORRELATIVE_MANAGEMENT_DELETE_ERROR = 'CORRELATIVE_MANAGEMENT_DELETE_ERROR';

//Quote Management Variables
export const PURCHASEORDER_LIST = 'PURCHASEORDER_LIST';
export const PURCHASEORDER_LIST_SUCCESS = 'PURCHASEORDER_LIST_SUCCESS';
export const PURCHASEORDER_LIST_ERROR = 'PURCHASEORDER_LIST_ERROR';
export const PURCHASEORDER_GET = 'PURCHASEORDER_GET';
export const PURCHASEORDER_GET_SUCCESS = 'PURCHASEORDER_GET_SUCCESS';
export const PURCHASEORDER_GET_ERROR = 'PURCHASEORDER_GET_ERROR';
export const PURCHASEORDER_NEW = 'PURCHASEORDER_NEW';
export const PURCHASEORDER_NEW_SUCCESS = 'PURCHASEORDER_NEW_SUCCESS';
export const PURCHASEORDER_NEW_ERROR = 'PURCHASEORDER_NEW_ERROR';
export const PURCHASEORDER_EDIT = 'PURCHASEORDER_EDIT';
export const PURCHASEORDER_EDIT_SUCCESS = 'PURCHASEORDER_EDIT_SUCCESS';
export const PURCHASEORDER_EDIT_ERROR = 'PURCHASEORDER_EDIT_ERROR';
export const PURCHASEORDER_CANCEL = 'PURCHASEORDER_CANCEL';
export const PURCHASEORDER_CANCEL_SUCCESS = 'PURCHASEORDER_CANCEL_SUCCESS';
export const PURCHASEORDER_CANCEL_ERROR = 'PURCHASEORDER_CANCEL_ERROR';
export const PURCHASEORDER_CLEAN = 'PURCHASEORDER_CLEAN';
export const PURCHASEORDER_DD_LIST = 'PURCHASEORDER_DD_LIST';

//Quote Management Detail Variables
export const PURCHASEORDER_DETAIL_LIST = 'PURCHASEORDER_DETAIL_LIST';
export const PURCHASEORDER_DETAIL_LIST_SUCCESS = 'PURCHASEORDER_DETAIL_LIST_SUCCESS';
export const PURCHASEORDER_DETAIL_LIST_ERROR = 'PURCHASEORDER_DETAIL_LIST_ERROR';
export const PURCHASEORDER_DETAIL_GET = 'PURCHASEORDER_DETAIL_GET';
export const PURCHASEORDER_DETAIL_GET_SUCCESS = 'PURCHASEORDER_DETAIL_GET_SUCCESS';
export const PURCHASEORDER_DETAIL_GET_ERROR = 'PURCHASEORDER_DETAIL_GET_ERROR';
export const PURCHASEORDER_DETAIL_NEW = 'PURCHASEORDER_DETAIL_NEW';
export const PURCHASEORDER_DETAIL_NEW_SUCCESS = 'PURCHASEORDER_DETAIL_NEW_SUCCESS';
export const PURCHASEORDER_DETAIL_NEW_ERROR = 'PURCHASEORDER_DETAIL_NEW_ERROR';
export const PURCHASEORDER_DETAIL_EDIT = 'PURCHASEORDER_DETAIL_EDIT';
export const PURCHASEORDER_DETAIL_EDIT_SUCCESS = 'PURCHASEORDER_DETAIL_EDIT_SUCCESS';
export const PURCHASEORDER_DETAIL_EDIT_ERROR = 'PURCHASEORDER_DETAIL_EDIT_ERROR';
export const PURCHASEORDER_DETAIL_DELETE = 'PURCHASEORDER_DETAIL_DELETE';
export const PURCHASEORDER_DETAIL_DELETE_SUCCESS = 'PURCHASEORDER_DETAIL_DELETE_SUCCESS';
export const PURCHASEORDER_DETAIL_DELETE_ERROR = 'PURCHASEORDER_DETAIL_DELETE_ERROR';
export const PURCHASEORDER_DETAIL_CLEAN = 'PURCHASEORDER_DETAIL_CLEAN';
export const PURCHASEORDER_DETAIL_DD_LIST = 'PURCHASEORDER_DETAIL_DD_LIST';

//Material Remove Variables

export const MATERIAL_REMOVE_RECORD_LIST = 'MATERIAL_REMOVE_RECORD_LIST';
export const MATERIAL_REMOVE_RECORD_LIST_SUCCESS = 'MATERIAL_REMOVE_RECORD_LIST_SUCCESS';
export const MATERIAL_REMOVE_RECORD_LIST_ERROR = 'MATERIAL_REMOVE_RECORD_LIST_ERROR';
export const MATERIAL_REMOVE_RECORD_GET = 'MATERIAL_REMOVE_RECORD_GET';
export const MATERIAL_REMOVE_RECORD_GET_SUCCESS = 'MATERIAL_REMOVE_RECORD_GET_SUCCESS';
export const MATERIAL_REMOVE_RECORD_GET_ERROR = 'MATERIAL_REMOVE_RECORD_GET_ERROR';
export const MATERIAL_REMOVE_RECORD_NEW = 'MATERIAL_REMOVE_RECORD_NEW';
export const MATERIAL_REMOVE_RECORD_NEW_SUCCESS = 'MATERIAL_REMOVE_RECORD_NEW_SUCCESS';
export const MATERIAL_REMOVE_RECORD_NEW_ERROR = 'MATERIAL_REMOVE_RECORD_NEW_ERROR';
export const MATERIAL_REMOVE_RECORD_EDIT = 'MATERIAL_REMOVE_RECORD_EDIT';
export const MATERIAL_REMOVE_RECORD_EDIT_SUCCESS = 'MATERIAL_REMOVE_RECORD_EDIT_SUCCESS';
export const MATERIAL_REMOVE_RECORD_EDIT_ERROR = 'MATERIAL_REMOVE_RECORD_EDIT_ERROR';
export const MATERIAL_REMOVE_RECORD_DELETE = 'MATERIAL_REMOVE_RECORD_DELETE';
export const MATERIAL_REMOVE_RECORD_DELETE_SUCCESS = 'MATERIAL_REMOVE_RECORD_DELETE_SUCCESS';
export const MATERIAL_REMOVE_RECORD_DELETE_ERROR = 'MATERIAL_REMOVE_RECORD_DELETE_ERROR';
export const MATERIAL_REMOVE_RECORD_DD_LIST = 'MATERIAL_REMOVE_RECORD_DD_LIST';

//Material Installation And Removal Parts

export const INSTALLATION_AND_REMOVAL_PARTS_LIST = 'INSTALLATION_AND_REMOVAL_PARTS_LIST';
export const INSTALLATION_AND_REMOVAL_PARTS_LIST_SUCCESS = 'INSTALLATION_AND_REMOVAL_PARTS_LIST_SUCCESS';
export const INSTALLATION_AND_REMOVAL_PARTS_LIST_ERROR = 'INSTALLATION_AND_REMOVAL_PARTS_LIST_ERROR';
export const INSTALLATION_AND_REMOVAL_PARTS_GET = 'INSTALLATION_AND_REMOVAL_PARTS_GET';
export const INSTALLATION_AND_REMOVAL_PARTS_GET_SUCCESS = 'INSTALLATION_AND_REMOVAL_PARTS_GET_SUCCESS';
export const INSTALLATION_AND_REMOVAL_PARTS_GET_ERROR = 'INSTALLATION_AND_REMOVAL_PARTS_GET_ERROR';
export const INSTALLATION_AND_REMOVAL_PARTS_NEW = 'INSTALLATION_AND_REMOVAL_PARTS_NEW';
export const INSTALLATION_AND_REMOVAL_PARTS_NEW_SUCCESS = 'INSTALLATION_AND_REMOVAL_PARTS_NEW_SUCCESS';
export const INSTALLATION_AND_REMOVAL_PARTS_NEW_ERROR = 'INSTALLATION_AND_REMOVAL_PARTS_NEW_ERROR';
export const INSTALLATION_AND_REMOVAL_PARTS_EDIT = 'INSTALLATION_AND_REMOVAL_PARTS_EDIT';
export const INSTALLATION_AND_REMOVAL_PARTS_EDIT_SUCCESS = 'INSTALLATION_AND_REMOVAL_PARTS_EDIT_SUCCESS';
export const INSTALLATION_AND_REMOVAL_PARTS_EDIT_ERROR = 'INSTALLATION_AND_REMOVAL_PARTS_EDIT_ERROR';
export const INSTALLATION_AND_REMOVAL_PARTS_DELETE = 'INSTALLATION_AND_REMOVAL_PARTS_DELETE';
export const INSTALLATION_AND_REMOVAL_PARTS_DELETE_SUCCESS = 'INSTALLATION_AND_REMOVAL_PARTS_DELETE_SUCCESS';
export const INSTALLATION_AND_REMOVAL_PARTS_DELETE_ERROR = 'INSTALLATION_AND_REMOVAL_PARTS_DELETE_ERROR';
export const INSTALLATION_AND_REMOVAL_PARTS_DD_LIST = 'INSTALLATION_AND_REMOVAL_PARTS_DD_LIST';


export const SERVICEROLE_SET_LIST = 'SERVICEROLE_SET_LIST';
export const SERVICEROLE_SET_ONE = 'SERVICEROLE_SET_ONE';
export const SERVICEROLE_ADD_NEW = 'SERVICEROLE_ADD_NEW';
export const SERVICEROLE_EDIT_ONE = 'SERVICEROLE_EDIT_ONE';
export const SERVICEROLE_SET_DDLIST = 'SERVICEROLE_SET_DDLIST';

export const MATERIALLOAN_SET_LIST = 'MATERIALLOAN_SET_LIST';
export const MATERIALLOAN_SET_ONE = 'MATERIALLOAN_SET_ONE';
export const MATERIALLOAN_ADD_NEW = 'MATERIALLOAN_ADD_NEW';
export const MATERIALLOAN_EDIT_ONE = 'MATERIALLOAN_EDIT_ONE';
export const MATERIALLOANDETAIL_SET_LIST = 'MATERIALLOANDETAIL_SET_LIST';
export const MATERIALLOANDETAIL_SET_ONE = 'MATERIALLOANDETAIL_SET_ONE';
export const MATERIALLOANDETAIL_ADD_NEW = 'MATERIALLOANDETAIL_ADD_NEW';
export const MATERIALLOANDETAIL_EDIT_ONE = 'MATERIALLOANDETAIL_EDIT_ONE';
export const MATERIALLOAN_TYPE_LIST = 'MATERIALLOAN_TYPE_LIST';
export const MATERIALLOAN_TYPE_LIST_SUCCESS = 'MATERIALLOAN_TYPE_LIST_SUCCESS';
export const MATERIALLOAN_TYPE_LIST_ERROR = 'MATERIALLOAN_TYPE_LIST_ERROR';

export const CANNIBALIZATIONRECORD_SET_LIST = 'CANNIBALIZATIONRECORD_SET_LIST';
export const CANNIBALIZATIONRECORD_SET_ONE = 'CANNIBALIZATIONRECORD_SET_ONE';
export const CANNIBALIZATIONRECORD_ADD_NEW = 'CANNIBALIZATIONRECORD_ADD_NEW';
export const CANNIBALIZATIONRECORD_EDIT_ONE = 'CANNIBALIZATIONRECORD_EDIT_ONE';

export const ITEM_INV_ADJUSTMENT_SET_LIST = 'MATERIALLOAN_SET_LIST';
export const ITEM_INV_ADJUSTMENT_SET_ONE = 'MATERIALLOAN_SET_ONE';
export const ITEM_INV_ADJUSTMENT_ADD_NEW = 'MATERIALLOAN_ADD_NEW';
export const ITEM_INV_ADJUSTMENT_EDIT_ONE = 'MATERIALLOAN_EDIT_ONE';

export const MATERIAL_BETWEEN_WAREHOUSE_SET_LIST = 'MATERIAL_BETWEEN_WAREHOUSE_SET_LIST';
export const MATERIAL_BETWEEN_WAREHOUSE_SET_ONE = 'MATERIAL_BETWEEN_WAREHOUSE_SET_ONE';
export const MATERIAL_BETWEEN_WAREHOUSE_ADD_NEW = 'MATERIAL_BETWEEN_WAREHOUSE_ADD_NEW';
export const MATERIAL_BETWEEN_WAREHOUSE_EDIT_ONE = 'MATERIAL_BETWEEN_WAREHOUSE_EDIT_ONE';

export const EQUIPMENT_REQUEST_SET_ONE = 'EQUIPMENT_REQUEST_SET_ONE';

export const EQUIPMENT_REQUEST_DETAIL_SET_LIST = 'EQUIPMENT_REQUEST_DETAIL_SET_LIST';
export const EQUIPMENT_REQUEST_DETAIL_SET_ONE = 'EQUIPMENT_REQUEST_DETAIL_SET_ONE';
export const EQUIPMENT_REQUEST_DETAIL_ADD_NEW = 'EQUIPMENT_REQUEST_DETAIL_ADD_NEW';
export const EQUIPMENT_REQUEST_DETAIL_EDIT_ONE = 'EQUIPMENT_REQUEST_DETAIL_EDIT_ONE';
export const EQUIPMENT_REQUEST_DETAIL_DELETE_ONE = 'EQUIPMENT_REQUEST_DETAIL_DELETE_ONE';
