import {
    AIRPLANECONTROLMAINTENANCE_LIST,
    AIRPLANECONTROLMAINTENANCE_LIST_SUCCESS,
    AIRPLANECONTROLMAINTENANCE_LIST_ERROR,
    AIRPLANECONTROLMAINTENANCE_DDLIST,
    AIRPLANECONTROLMAINTENANCE_DDLIST_SUCCESS,
    AIRPLANECONTROLMAINTENANCE_DDLIST_ERROR,
    AIRPLANECONTROLMAINTENANCE_GET,
    AIRPLANECONTROLMAINTENANCE_GET_SUCCESS,
    AIRPLANECONTROLMAINTENANCE_GET_ERROR,  
    AIRPLANECONTROLMAINTENANCE_NEW,
    AIRPLANECONTROLMAINTENANCE_NEW_SUCCESS,
    AIRPLANECONTROLMAINTENANCE_NEW_ERROR,
    AIRPLANECONTROLMAINTENANCE_EDIT,
    AIRPLANECONTROLMAINTENANCE_EDIT_SUCCESS,
    AIRPLANECONTROLMAINTENANCE_EDIT_ERROR
} from '../types';

const initialState = {
    airplanecontrolmaintenances: [],    
    airplanecontrolmaintenance: {},
    airplanecontrolmaintenanceList:{},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case AIRPLANECONTROLMAINTENANCE_LIST:
            return {
                ...state,
                loading: true,
                airplanecontrolmaintenance: {},               
            }
        case AIRPLANECONTROLMAINTENANCE_LIST_SUCCESS:            
            return {
                ...state,                
                airplanecontrolmaintenances: action.payload,  
                loading: false,
                error: false,
                airplanecontrolmaintenance: {}
            }
        case AIRPLANECONTROLMAINTENANCE_LIST_ERROR:
            return {
                ...state,
                airplanecontrolmaintenances: [],
                loading: false,
                error: true,
                airplanecontrolmaintenance: {}
            }
        case AIRPLANECONTROLMAINTENANCE_DDLIST:
            return {
                ...state,
                loading: true,
                airplanecontrolmaintenance: {},
                airplanecontrolmaintenanceList: []
            }
        case AIRPLANECONTROLMAINTENANCE_DDLIST_SUCCESS:
            return {
                ...state,
                airplanecontrolmaintenanceList: action.payload,
                loading: false,
                error: false
            }
        case AIRPLANECONTROLMAINTENANCE_DDLIST_ERROR:
            return {
                ...state,
                airplanecontrolmaintenanceList: [],
                loading: false,
                error: true,
                airplanecontrolmaintenance: {}
            }
        case AIRPLANECONTROLMAINTENANCE_GET:
            return {
                ...state,
                loading: true
            }
        case AIRPLANECONTROLMAINTENANCE_GET_SUCCESS:
            return {
                ...state,
                airplanecontrolmaintenance: action.payload,
                loading: false,
                error: false
            }
        case AIRPLANECONTROLMAINTENANCE_GET_ERROR:
            return {
                ...state,
                error: true,
                airplanecontrolmaintenance: {}
            }       
        case AIRPLANECONTROLMAINTENANCE_NEW:
            return {
                ...state
            }
        case AIRPLANECONTROLMAINTENANCE_NEW_SUCCESS:
            return {
                ...state,
                error: false,
                airplanecontrolmaintenances: [...state.airplanecontrolmaintenances, action.payload]
            }
        case AIRPLANECONTROLMAINTENANCE_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case AIRPLANECONTROLMAINTENANCE_EDIT:
            return {
                ...state,
            }
        case AIRPLANECONTROLMAINTENANCE_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                airplanecontrolmaintenance: action.payload,
                airplanecontrolmaintenances: state.airplanecontrolmaintenances.map(airplanecontrolmaintenance => airplanecontrolmaintenance.id === action.payload.id ? airplanecontrolmaintenance = action.payload : airplanecontrolmaintenance),
            }
        case AIRPLANECONTROLMAINTENANCE_EDIT_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state;
    }
}