import {
    PURCHASEORDER_DETAIL_LIST,
    PURCHASEORDER_DETAIL_LIST_SUCCESS,
    PURCHASEORDER_DETAIL_LIST_ERROR,
    PURCHASEORDER_DETAIL_GET,
    PURCHASEORDER_DETAIL_GET_SUCCESS,
    PURCHASEORDER_DETAIL_GET_ERROR,
    PURCHASEORDER_DETAIL_NEW,
    PURCHASEORDER_DETAIL_NEW_SUCCESS,
    PURCHASEORDER_DETAIL_NEW_ERROR,
    PURCHASEORDER_DETAIL_EDIT,
    PURCHASEORDER_DETAIL_EDIT_SUCCESS,
    PURCHASEORDER_DETAIL_EDIT_ERROR,
    PURCHASEORDER_DETAIL_DELETE,
    PURCHASEORDER_DETAIL_DELETE_SUCCESS,
    PURCHASEORDER_DETAIL_DELETE_ERROR,
    PURCHASEORDER_DETAIL_CLEAN
} from '../types';

const initialState = {
    purchaseorderdetaillist: [],
    purchaseorderdetail: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case PURCHASEORDER_DETAIL_CLEAN:
            return {
                ...state,
                loading: false,
                error: false,
                purchaseorderdetaillist: [],
                purchaseorderdetail: {}
            }
        case PURCHASEORDER_DETAIL_LIST:
            return {
                ...state,
                loading: true,
                purchaseorderdetail: {}
            }
        case PURCHASEORDER_DETAIL_LIST_SUCCESS:
            return {
                ...state,
                purchaseorderdetaillist: action.payload,
                loading: false,
                error: false,
                purchaseorderdetail: {}
            }
        case PURCHASEORDER_DETAIL_LIST_ERROR:
            return {
                ...state,
                purchaseorderdetaillist: [],
                loading: false,
                error: true,
                purchaseorderdetail: {}
            }
        case PURCHASEORDER_DETAIL_GET:
            return {
                ...state,
                loading: true
            }
        case PURCHASEORDER_DETAIL_GET_SUCCESS:
            return {
                ...state,
                purchaseorderdetail: action.payload,
                loading: false,
                error: false
            }
        case PURCHASEORDER_DETAIL_GET_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                purchaseorderdetail: {}
            }
        case PURCHASEORDER_DETAIL_NEW:
            return {
                ...state,
                loading: true,
            }
        case PURCHASEORDER_DETAIL_NEW_SUCCESS:
            return {
                ...state,
                purchaseorderdetaillist: [...state.purchaseorderdetaillist, action.payload],
                purchaseorderdetail: {},
                loading: false,
                error: false
            }
        case PURCHASEORDER_DETAIL_NEW_ERROR:
            return {
                ...state,
                purchaseorderdetail: {},
                loading: false,
                error: true
            }
        case PURCHASEORDER_DETAIL_EDIT:
            return {
                ...state,
                loading: true
            }
        case PURCHASEORDER_DETAIL_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                purchaseorderdetail: action.payload,
                purchaseorderdetaillist: state.purchaseorderdetaillist.map(purchaseorder => ( purchaseorder.id === action.payload.id ? action.payload : purchaseorder ) ),
            }
        case PURCHASEORDER_DETAIL_EDIT_ERROR:
            return {
                ...state,
                purchaseorderdetail: action.payload,
                loading: false,
                error: true
            }
        case PURCHASEORDER_DETAIL_DELETE:
            return {
                ...state,
                loading: true
            }
        case PURCHASEORDER_DETAIL_DELETE_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                purchaseorderdetail: {},
                purchaseorderdetaillist: state.purchaseorderdetaillist.filter( _detail => _detail.id !== action.payload.id  ),
            }
        case PURCHASEORDER_DETAIL_DELETE_ERROR:
            return {
                ...state,
                purchaseorderdetail: {},
                loading: false,
                error: true
            }
        default:
            return state;
    }
}
