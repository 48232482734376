import {
    PAYMENTHEADER_LIST,
    PAYMENTHEADER_LIST_SUCCESS,
    PAYMENTHEADER_LIST_ERROR,
    PAYMENTHEADER_DDLIST,
    PAYMENTHEADER_DDLIST_SUCCESS,
    PAYMENTHEADER_DDLIST_ERROR,
    PAYMENTHEADER_GET,
    PAYMENTHEADER_GET_SUCCESS,
    PAYMENTHEADER_GET_ERROR,
    PAYMENTHEADER_NEW,
    PAYMENTHEADER_NEW_SUCCESS,
    PAYMENTHEADER_NEW_ERROR,
    PAYMENTHEADER_NEW_RECORD,
    PAYMENTHEADER_EDIT,
    PAYMENTHEADER_EDIT_SUCCESS,
    PAYMENTHEADER_EDIT_ERROR
} from '../types';

const initialState = {
    paymentheaders: [],
    paymentheader: {},
    loading: false,
    error: false}

export default function(state = initialState, action){
    switch(action.type){
        case PAYMENTHEADER_LIST:
            return {
                ...state,
                loading: true,
                paymentheader: {}
            }
        case PAYMENTHEADER_LIST_SUCCESS:
            return {
                ...state,
                paymentheaders: action.payload,
                loading: false,
                error: false,
                paymentheader: {}
            }
        case PAYMENTHEADER_LIST_ERROR:
            return {
                ...state,
                paymentheaders: [],
                loading: false,
                error: true,
                paymentheader: {}
            }
        case PAYMENTHEADER_DDLIST:
            return {
                ...state,
                loading: true,
                paymentheader: {}
            }
        case PAYMENTHEADER_DDLIST_SUCCESS:
            return {
                ...state,
                paymentheaders: action.payload,
                loading: false,
                error: false                
            }
        case PAYMENTHEADER_DDLIST_ERROR:
            return {
                ...state,
                paymentheaders: [],
                loading: false,
                error: true,
                paymentheader: {}
            }
        case PAYMENTHEADER_GET:
            return {
                ...state,
                loading: true
            }
        case PAYMENTHEADER_GET_SUCCESS:
            return {
                ...state,
                paymentheader: action.payload,
                loading: false,
                error: false
            }
        case PAYMENTHEADER_GET_ERROR:
            return {
                ...state,
                error: true,
                paymentheader: {}
            }
        case PAYMENTHEADER_NEW:
            return {
                ...state
            }
        case PAYMENTHEADER_NEW_SUCCESS:
            return {
                ...state,
                paymentheaders: [...state.paymentheaders, action.payload],
                paymentheader: {},
                error: false
            }
        case PAYMENTHEADER_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case PAYMENTHEADER_NEW_RECORD:
            return {
                ...state,
                paymentheader: {},
                error: false
            }
        case PAYMENTHEADER_EDIT:
            return {
                ...state
            }
        case PAYMENTHEADER_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                paymentheader: action.payload,               
                paymentheaders: state.paymentheaders.map(paymentheader => paymentheader.id === action.payload.id ? paymentheader = action.payload : paymentheader),
            }
        case PAYMENTHEADER_EDIT_ERROR:
            return {
                ...state,
                error: false
            }
        default:
            return state;
    }
}