import {
    PURCHASEORDER_LIST,
    PURCHASEORDER_LIST_SUCCESS,
    PURCHASEORDER_LIST_ERROR,
    PURCHASEORDER_GET,
    PURCHASEORDER_GET_SUCCESS,
    PURCHASEORDER_GET_ERROR,
    PURCHASEORDER_NEW,
    PURCHASEORDER_NEW_SUCCESS,
    PURCHASEORDER_NEW_ERROR,
    PURCHASEORDER_EDIT,
    PURCHASEORDER_EDIT_SUCCESS,
    PURCHASEORDER_EDIT_ERROR,
    PURCHASEORDER_CANCEL,
    PURCHASEORDER_CANCEL_SUCCESS,
    PURCHASEORDER_CANCEL_ERROR,
    PURCHASEORDER_CLEAN
} from '../types';

const initialState = {
    purchaseorders: [],
    purchaseorder: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case PURCHASEORDER_CLEAN:
            return {
                ...state,
                loading: false,
                error: false,
                purchaseorders: [],
                purchaseorder: {}
            }
        case PURCHASEORDER_LIST:
            return {
                ...state,
                loading: true,
                purchaseorder: {}
            }
        case PURCHASEORDER_LIST_SUCCESS:
            return {
                ...state,
                purchaseorders: action.payload,
                loading: false,
                error: false,
                purchaseorder: {}
            }
        case PURCHASEORDER_LIST_ERROR:
            return {
                ...state,
                purchaseorders: [],
                loading: false,
                error: true,
                purchaseorder: {}
            }
        case PURCHASEORDER_GET:
            return {
                ...state,
                loading: true
            }
        case PURCHASEORDER_GET_SUCCESS:
            return {
                ...state,
                purchaseorder: action.payload,
                loading: false,
                error: false
            }
        case PURCHASEORDER_GET_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                purchaseorder: {}
            }
        case PURCHASEORDER_NEW:
            return {
                ...state,
                loading: true,
            }
        case PURCHASEORDER_NEW_SUCCESS:
            return {
                ...state,
                purchaseorders: [action.payload, ...state.purchaseorders],
                purchaseorder: {},
                loading: false,
                error: false
            }
        case PURCHASEORDER_NEW_ERROR:
            return {
                ...state,
                purchaseorder: {},
                loading: false,
                error: true
            }
        case PURCHASEORDER_EDIT:
            return {
                ...state,
                loading: true
            }
        case PURCHASEORDER_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                purchaseorder: action.payload,
                purchaseorders: state.purchaseorders.map(purchaseorder => ( purchaseorder.id === action.payload.id ? action.payload : purchaseorder ) ),
            }
        case PURCHASEORDER_EDIT_ERROR:
            return {
                ...state,
                purchaseorder: action.payload,
                loading: false,
                error: true
            }
        case PURCHASEORDER_CANCEL:
            return {
                ...state,
                loading: true
            }
        case PURCHASEORDER_CANCEL_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                purchaseorder: {},
                purchaseorders: state.purchaseorders.map(purchaseorder => ( purchaseorder.id === action.payload.id ? action.payload : purchaseorder ) ),
            }
        case PURCHASEORDER_CANCEL_ERROR:
            return {
                ...state,
                purchaseorder: {},
                loading: false,
                error: true
            }
        default:
            return state;
    }
}
