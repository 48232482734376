import { BaseService } from './BaseService';

const service = new BaseService('componentassembled');

export default {
    findAll() {
        return service.get();
    },
    findById(id) {
        return service.get(id);
    },
    saveOrUpdate(data) {
        return service.post('', data);
    },
};
