import {
    OMA_LIST,
    OMA_LIST_SUCCESS,
    OMA_LIST_ERROR,
    OMA_DDLIST,
    OMA_DDLIST_SUCCESS,
    OMA_DDLIST_ERROR,
    OMA_GET,
    OMA_GET_SUCCESS,
    OMA_GET_ERROR,
    OMA_NEW,
    OMA_NEW_SUCCESS,
    OMA_NEW_ERROR,
    OMA_NEW_RECORD,
    OMA_EDIT,
    OMA_EDIT_SUCCESS,
    OMA_EDIT_ERROR
} from '../types';

const initialState = {
    omas: [],
    oma: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case OMA_LIST:
            return {
                ...state,
                loading: true,
                oma: {}
            }
        case OMA_LIST_SUCCESS:
            return {
                ...state,
                omas: action.payload,
                loading: false,
                error: false,
                oma: {}
            }
        case OMA_LIST_ERROR:
            return {
                ...state,
                omas: [],
                loading: false,
                error: true,
                oma: {}
            }
        case OMA_DDLIST:
            return {
                ...state,
                loading: true,
                oma: {}
            }
        case OMA_DDLIST_SUCCESS:
            return {
                ...state,
                omas: action.payload,
                loading: false,
                error: false,
                oma: {}
            }
        case OMA_DDLIST_ERROR:
            return {
                ...state,
                omas: [],
                loading: false,
                error: true,
                oma: {}
            }
        case OMA_GET:
            return {
                ...state,
                loading: true
            }
        case OMA_GET_SUCCESS:
            return {
                ...state,
                oma: action.payload,
                loading: false,
                error: false
            }
        case OMA_GET_ERROR:
            return {
                ...state,
                error: true,
                oma: {}
            }
        case OMA_NEW:
            return {
                ...state,
                loading: true,
            }
        case OMA_NEW_SUCCESS:
            sessionStorage.setItem('error', false);
            return {
                ...state,
                omas: [...state.omas, action.payload],
                oma: {},
                loading: false,
                error: false
            }
        case OMA_NEW_ERROR:
            sessionStorage.setItem('error', true);
            return {
                ...state,
                oma: action.payload,
                loading: false,
                error: true
            }
        case OMA_NEW_RECORD:
            return {
                ...state,
                oma: {},
                error: false
            }
        case OMA_EDIT:
            return {
                ...state
            }
        case OMA_EDIT_SUCCESS:
            sessionStorage.setItem('error', false);
            return {
                ...state,
                error: false,
                oma: action.payload,
                omas: state.omas.map(oma => oma.id === action.payload.id ? oma = action.payload : oma),
            }
        case OMA_EDIT_ERROR:
            sessionStorage.setItem('error', true);
            return {
                ...state,
                oma: action.payload,
                error: false
            }
        default:
            return state;
    }
}
