import {
    SUPPLIEREVALUATIONPARAM_LIST,
    SUPPLIEREVALUATIONPARAM_LIST_SUCCESS,
    SUPPLIEREVALUATIONPARAM_LIST_ERROR,
    SUPPLIEREVALUATIONPARAM_DDLIST,
    SUPPLIEREVALUATIONPARAM_DDLIST_SUCCESS,
    SUPPLIEREVALUATIONPARAM_DDLIST_ERROR,
    SUPPLIEREVALUATIONPARAM_GET,
    SUPPLIEREVALUATIONPARAM_GET_SUCCESS,
    SUPPLIEREVALUATIONPARAM_GET_ERROR,
    SUPPLIEREVALUATIONPARAM_NEW,
    SUPPLIEREVALUATIONPARAM_NEW_SUCCESS,
    SUPPLIEREVALUATIONPARAM_NEW_ERROR,
    SUPPLIEREVALUATIONPARAM_NEW_RECORD,
    SUPPLIEREVALUATIONPARAM_EDIT,
    SUPPLIEREVALUATIONPARAM_EDIT_SUCCESS,
    SUPPLIEREVALUATIONPARAM_EDIT_ERROR,
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Parametros de Evaluación de Proveedores
export function SupplierEvaluationParamListAction(){    
    return (dispatch =>{
        dispatch(SupplierEvaluationParamList());
        AxiosClient.get(`${process.env.REACT_APP_SUPPLIEREVALUATIONPARAM}`)                
            .then((response)=> {
                dispatch( SupplierEvaluationParamListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( SupplierEvaluationParamListError(error) );
            });
    });
}

export const SupplierEvaluationParamList = () => ({
    type: SUPPLIEREVALUATIONPARAM_LIST
});

export const SupplierEvaluationParamListSuccess = (records) => ({
    type: SUPPLIEREVALUATIONPARAM_LIST_SUCCESS,
    payload: records
});

export const SupplierEvaluationParamListError = (error) => ({
    type: SUPPLIEREVALUATIONPARAM_LIST_ERROR,
    payload: error
});

// Función Principal para obtener los Parámetros de Evaluación de Proveedor en forma de DropDown
export function SupplierEvaluationParamDDListAction(){
    return (dispatch =>{
        dispatch(SupplierEvaluationParamDDList());
        AxiosClient.get(`${process.env.REACT_APP_SUPPLIEREVALUATIONPARAM}/ddlist`)
            .then((response)=> {
                dispatch( SupplierEvaluationParamDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( SupplierEvaluationParamDDListError(error) );
            });
    });
}
export const SupplierEvaluationParamDDList = () => ({
    type: SUPPLIEREVALUATIONPARAM_DDLIST
});

export const SupplierEvaluationParamDDListSuccess = (records) => ({
    type: SUPPLIEREVALUATIONPARAM_DDLIST_SUCCESS,
    payload: records        
});

export const SupplierEvaluationParamDDListError = (error) => ({
    type: SUPPLIEREVALUATIONPARAM_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener un Parámetro de Evaluación de Proveedor
export function SupplierEvaluationParamGetAction(id){
    return (dispatch =>{
        dispatch(SupplierEvaluationParamGet());
        AxiosClient.get(`${process.env.REACT_APP_SUPPLIEREVALUATIONPARAM}/${id}`)
            .then((response)=> {
                dispatch( SupplierEvaluationParamGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( SupplierEvaluationParamEditError(error) );
            });
    });
}

export const SupplierEvaluationParamGet = () => ({
    type: SUPPLIEREVALUATIONPARAM_GET
});

export const SupplierEvaluationParamGetSuccess = (record) => ({
    type: SUPPLIEREVALUATIONPARAM_GET_SUCCESS,
    payload: record
});

export const SupplierEvaluationParamMdGetError = (error) => ({
    type: SUPPLIEREVALUATIONPARAM_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Parametros de Evaluación de Proveedor cuando se va crear un registro nuevo
export function SupplierEvaluationParamNewAction(){
    return (dispatch =>{
            dispatch(SupplierEvaluationParamNewRecord());
    });
}

export const SupplierEvaluationParamNewRecord = () => ({
    type: SUPPLIEREVALUATIONPARAM_NEW_RECORD
});

// Función Principal para crear y editar registros de Parámetros de Evaluación de Proveedor
export function SupplierEvaluationParamSaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(SupplierEvaluationParamEdit());
        } else{
            dispatch(SupplierEvaluationParamNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_SUPPLIEREVALUATIONPARAM}`, record)
                .then((response)=> {                   
                    record.id = response.data.result.id;                    
                    dispatch( SupplierEvaluationParamNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( SupplierEvaluationParamNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_SUPPLIEREVALUATIONPARAM}/${record.id}`, record)
                .then((response)=> {
                    dispatch( SupplierEvaluationParamEditSuccess(record));
                })
                .catch(error=> {
                    dispatch(SupplierEvaluationParamEditError(true) );
                });
        }
    });
}

export const SupplierEvaluationParamNew = () => ({
    type: SUPPLIEREVALUATIONPARAM_NEW
});

export const SupplierEvaluationParamNewSuccess = (record) => ({
    type: SUPPLIEREVALUATIONPARAM_NEW_SUCCESS,
    payload: record
});

export const SupplierEvaluationParamNewError = (error) => ({
    type: SUPPLIEREVALUATIONPARAM_NEW_ERROR,
    payload: error
});

export const SupplierEvaluationParamEdit = () => ({
    type: SUPPLIEREVALUATIONPARAM_EDIT
});

export const SupplierEvaluationParamEditSuccess = (record) => ({
    type: SUPPLIEREVALUATIONPARAM_EDIT_SUCCESS,
    payload: record
});

export const SupplierEvaluationParamEditError = (error) => ({
    type: SUPPLIEREVALUATIONPARAM_EDIT_ERROR,
    payload: error
});