import * as appConstants from '../AppConstants';


export const requestPurchaseRequirementPrivilege = ( userRoleList = [] ) => {
    return userRoleList.find(
        roleCode => roleCode === appConstants.ROLE_CODE_SUPER_USER
            || roleCode === appConstants.ROLE_CODE_JMTTO
            || roleCode === appConstants.ROLE_CODE_INGEN
            || roleCode === appConstants.ROLE_CODE_CMTTO
            || roleCode === appConstants.ROLE_CODE_JINGE
            || roleCode === appConstants.ROLE_CODE_CCM
            || roleCode === appConstants.ROLE_CODE_COINSPECT
            || roleCode === appConstants.ROLE_CODE_AERO_INSPECTOR
        );
};

export const checkingPurchaseRequirementPrivilege = ( userRoleList = [] ) => {
    return userRoleList.find(
        roleCode => roleCode === appConstants.ROLE_CODE_SUPER_USER
            || roleCode === appConstants.ROLE_CODE_JINGE
            || roleCode === appConstants.ROLE_CODE_JMTTO
            || roleCode === appConstants.ROLE_CODE_CMTTO
            || roleCode === appConstants.ROLE_CODE_COINSPECT
            || roleCode === appConstants.ROLE_CODE_AERO_INSPECTOR
        );
};

export const approvePurchaseRequirementPrivilege = ( userRoleList = [] ) => {
    return userRoleList.find(
        roleCode => roleCode === appConstants.ROLE_CODE_SUPER_USER
            || roleCode === appConstants.ROLE_CODE_JINGE
            || roleCode === appConstants.ROLE_CODE_DMTTO
            || roleCode === appConstants.ROLE_CODE_COINSPECT
            || roleCode === appConstants.ROLE_CODE_AERO_INSPECTOR
        );
};

export const justApprovePurchaseRequirementPrivilege = ( userRoleList = [] ) => {
    return userRoleList.find(
        roleCode => roleCode === appConstants.ROLE_CODE_COMEXT
        );
}

export const materialRequestPrivilege = ( userRoleList = [] ) => {
  return userRoleList.find(
    roleCode => roleCode === appConstants.ROLE_CODE_SUPER_USER
    || roleCode === appConstants.ROLE_CODE_DMTTO
    || roleCode === appConstants.ROLE_CODE_JMTTO
    || roleCode === appConstants.ROLE_CODE_CMTTO
    || roleCode === appConstants.ROLE_CODE_COCCM
    || roleCode === appConstants.ROLE_CODE_CCM
    || roleCode === appConstants.ROLE_CODE_JINGE
    || roleCode === appConstants.ROLE_CODE_INGEN
    || roleCode === appConstants.ROLE_CODE_COABAST
    || roleCode === appConstants.ROLE_CODE_ALMAC
    || roleCode === appConstants.ROLE_CODE_COINSPECT
    || roleCode === appConstants.ROLE_CODE_TMA
  )
}

export const storerReserveAndDispatchMaterialRequestPrivilege = ( userRoleList = [] ) => {
    const variable = userRoleList.find(
        roleCode => roleCode === appConstants.ROLE_CODE_SUPER_USER
        || roleCode === appConstants.ROLE_CODE_JABAST
        || roleCode === appConstants.ROLE_CODE_ALMAC  
        || roleCode === appConstants.ROLE_CODE_COABAST 
    )

    return variable;
}

export const alertProccessMaterialRequest = (userRoleList = []) => {
  return userRoleList.find(
    roleCode => roleCode === appConstants.ROLE_CODE_JABAST
    || roleCode === appConstants.ROLE_CODE_ALMAC  
  )
}

export const mantainenceManagerConfirmInstallationPrivilege = ( userRoleList = [] ) => {
    return userRoleList.find(
        roleCode => roleCode === appConstants.ROLE_CODE_SUPER_USER
        || roleCode === appConstants.ROLE_CODE_JMTTO
        || roleCode === appConstants.ROLE_CODE_CMTTO
        || roleCode === appConstants.ROLE_CODE_COCCM  
    )
}

export const tmaConfirmationConfirmInstallationPrivilege = ( userRoleList = [] ) => {
    return userRoleList.find(
        roleCode => roleCode === appConstants.ROLE_CODE_SUPER_USER
        || roleCode === appConstants.ROLE_CODE_CMTTO
        || roleCode === appConstants.ROLE_CODE_COCCM  
    )
}

export const inspectorConfirmationConfirmInstallationPrivilege = ( userRoleList = [] ) => {
    return userRoleList.find(
        roleCode => roleCode === appConstants.ROLE_CODE_SUPER_USER
        || roleCode === appConstants.ROLE_CODE_COINSPECT
        || roleCode === appConstants.ROLE_CODE_JINSPECT  
    )
}

export const warrantyManagemendComexPermissionRequirementPrivilege = ( userRoleList = [] ) => {
    return userRoleList.find(
        roleCode => roleCode === appConstants.ROLE_CODE_COMEXT
        );
}

export const purchaseOrderPrivilege = (userRoleList = []) => () => {
    return userRoleList.find(
        roleCode => roleCode === appConstants.ROLE_CODE_SUPER_USER
        || roleCode === appConstants.ROLE_CODE_COMEXT  
    )
};

export const storePurchaseOrderPrivilege = ( userRoleList = [] ) => {
    const variable = userRoleList.find(
        roleCode => roleCode === appConstants.ROLE_CODE_JABAST
        || roleCode === appConstants.ROLE_CODE_ALMAC  
        || roleCode === appConstants.ROLE_CODE_COABAST 
    )

    return variable;
}