import React from 'react';

export default function Footer(){

    const year = new Date().getFullYear();
    
    return (
        <footer className="footer-container">
            <span>&copy; {year} - SAM 1.0</span>
        </footer>
    );
}
