import {
    CORRELATIVE_MANAGEMENT_LIST,
    CORRELATIVE_MANAGEMENT_LIST_SUCCESS,
    CORRELATIVE_MANAGEMENT_LIST_ERROR ,
    CORRELATIVE_MANAGEMENT_GET ,
    CORRELATIVE_MANAGEMENT_GET_SUCCESS, 
    CORRELATIVE_MANAGEMENT_GET_ERROR ,
    CORRELATIVE_MANAGEMENT_NEW ,
    CORRELATIVE_MANAGEMENT_NEW_SUCCESS, 
    CORRELATIVE_MANAGEMENT_NEW_ERROR ,
    CORRELATIVE_MANAGEMENT_EDIT ,
    CORRELATIVE_MANAGEMENT_EDIT_SUCCESS, 
    CORRELATIVE_MANAGEMENT_EDIT_ERROR,
    CORRELATIVE_MANAGEMENT_DELETE ,
    CORRELATIVE_MANAGEMENT_DELETE_SUCCESS, 
    CORRELATIVE_MANAGEMENT_DELETE_ERROR 
} from '../types/index'

const initialState = {
    correlativemanagementlist: [],
    correlativemanagement: {},
    loading: false,
    error: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CORRELATIVE_MANAGEMENT_LIST:
            return {
                ...state,
                loading: true,
                correlativemanagementlist: [],
            };

            case CORRELATIVE_MANAGEMENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                correlativemanagementlist: action.payload,
                correlativemanagement: {}
            };

            case CORRELATIVE_MANAGEMENT_LIST_ERROR:
                return {
                    ...state,
                    loading: false,
                    error: true,
                    correlativemanagementlist: [],
                    correlativemanagement: {}
                };

            case CORRELATIVE_MANAGEMENT_GET:
                return {
                    ...state,
                    loading: true,
                    error: false,
                    correlativemanagement: {}
                }

            case CORRELATIVE_MANAGEMENT_GET_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    error: false,
                    correlativemanagement: action.payload
                };

            case CORRELATIVE_MANAGEMENT_GET_ERROR:
                return {
                    ...state,
                    loading: false,
                    error: true,
                    correlativemanagementlist: [],
                    correlativemanagement: {}
                };

            case CORRELATIVE_MANAGEMENT_NEW:
                return {
                    ...state,
                    loading: true,
                    error: false
                };

            case CORRELATIVE_MANAGEMENT_NEW_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    error: false,
                    correlativemanagementlist: [...state.correlativemanagementlist, action.payload],
                    correlativemanagement: action.payload
                };

            case CORRELATIVE_MANAGEMENT_NEW_ERROR:
                return {
                    ...state,
                    loading: false,
                    error: true,
                    correlativemanagementlist: [],
                    correlativemanagement: {}
                }

            case CORRELATIVE_MANAGEMENT_EDIT:
                return {
                    ...state,
                    loading: true,
                    error: false
                };

            case CORRELATIVE_MANAGEMENT_EDIT_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    error: false,
                    correlativemanagement: action.payload,
                    correlativemanagementlist: state.correlativemanagementlist.map(correlativemanagement => (correlativemanagement.id === action.payload.id ? action.payload : correlativemanagement))
                };

            case CORRELATIVE_MANAGEMENT_EDIT_ERROR:
                return {
                    ...state,
                    loading: false,
                    error: true,
                    correlativemanagementlist: [],
                    correlativemanagement: {}
                };

            case CORRELATIVE_MANAGEMENT_DELETE:
                return {
                    ...state,
                    loading: true,
                    error: false
                }

            case CORRELATIVE_MANAGEMENT_DELETE_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    error: false,
                    correlativemanagementlist: state.correlativemanagementlist.filter(correlativemanagement => correlativemanagement.id !== action.payload)
                }

            case CORRELATIVE_MANAGEMENT_DELETE_ERROR:
                return {
                    ...state,
                    loading: false,
                    error: true
                }

            default:
                return state;


    }
}