import {
    BPARTNERPRODUCT_LIST,
    BPARTNERPRODUCT_LIST_SUCCESS,
    BPARTNERPRODUCT_LIST_ERROR,
    BPARTNERPRODUCT_DDLIST,
    BPARTNERPRODUCT_DDLIST_SUCCESS,
    BPARTNERPRODUCT_DDLIST_ERROR,
    BPARTNERPRODUCT_GET,
    BPARTNERPRODUCT_GET_SUCCESS,
    BPARTNERPRODUCT_GET_ERROR,
    BPARTNERPRODUCT_NEW,
    BPARTNERPRODUCT_NEW_SUCCESS,
    BPARTNERPRODUCT_NEW_ERROR,
    BPARTNERPRODUCT_NEW_RECORD,
    BPARTNERPRODUCT_EDIT,
    BPARTNERPRODUCT_EDIT_SUCCESS,
    BPARTNERPRODUCT_EDIT_ERROR,
    BPARTNERPRODUCT_DELETE,
    BPARTNERPRODUCT_DELETE_SUCCESS,
    BPARTNERPRODUCT_DELETE_ERROR
} from '../types';

const initialState = {
    bpartnerproductList: [],
    bpartnerproduct: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case BPARTNERPRODUCT_LIST:
            return {
                ...state,
                loading: true,
                bpartnerproductList: []
            }
        case BPARTNERPRODUCT_LIST_SUCCESS:           
            return {
                ...state,
                bpartnerproductList: action.payload,
                loading: false,
                error: false,
                bpartnerproduct: {}
            }
        case BPARTNERPRODUCT_LIST_ERROR:
            return {
                ...state,
                bpartnerproductList: [],
                loading: false,
                error: true,
                bpartnerproduct: {}
            }
        case BPARTNERPRODUCT_DDLIST:
            return {
                ...state,
                loading: true,
                bpartnerproductList: [],
                bpartnerproduct: {}
            }
        case BPARTNERPRODUCT_DDLIST_SUCCESS:
            return {
                ...state,
                bpartnerproductList: action.payload,
                loading: false,
                error: false,
                bpartnerproduct: {}
            }
        case BPARTNERPRODUCT_DDLIST_ERROR:
            return {
                ...state,
                bpartnerproductList: [],
                loading: false,
                error: true,
                bpartnerproduct: {}
            }
        case BPARTNERPRODUCT_GET:
            return {
                ...state,
                loading: true
            }
        case BPARTNERPRODUCT_GET_SUCCESS:
            return {
                ...state,
                bpartnerproduct: action.payload,
                loading: false,
                error: false
            }
        case BPARTNERPRODUCT_GET_ERROR:
            return {
                ...state,
                error: true,
                bpartnerproduct: {}
            }
        case BPARTNERPRODUCT_NEW:
            return {
                ...state
            }
        case BPARTNERPRODUCT_NEW_SUCCESS:
            return {
                ...state,
                bpartnerproductList: [...state.bpartnerproductList, action.payload],
                bpartnerproduct: action.payload,
                error: false
            }
        case BPARTNERPRODUCT_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case BPARTNERPRODUCT_NEW_RECORD:
            return {
                ...state,
                bpartnerproduct: {},
                error: false
            }
        case BPARTNERPRODUCT_EDIT:
            return {
                ...state
            }
        case BPARTNERPRODUCT_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                bpartnerproduct: action.payload,
                bpartnerproductList: state.bpartnerproductList.map(bpartnerproduct => bpartnerproduct.id === action.payload.id ? bpartnerproduct = action.payload : bpartnerproduct),
            }
        case BPARTNERPRODUCT_EDIT_ERROR:
            return {
                ...state,
                error: false
            } 
        case BPARTNERPRODUCT_DELETE:
            return {
                ...state
            }       
        case BPARTNERPRODUCT_DELETE_SUCCESS:
            //const  filtro = state.bpartnerproductList.filter(product=>product.id !==action.payload);           
            return {
                 ...state,
                bpartnerproductList: state.bpartnerproductList.filter(product=>product.id !==action.payload),                
                error: false
            }

        case BPARTNERPRODUCT_DELETE_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state;
    }
}
