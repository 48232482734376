import {
    AIRPLANEMANUAL_LIST,
    AIRPLANEMANUAL_LIST_SUCCESS,
    AIRPLANEMANUAL_LIST_ERROR,
    AIRPLANEMANUAL_GET,
    AIRPLANEMANUAL_GET_SUCCESS,
    AIRPLANEMANUAL_GET_ERROR,
    AIRPLANEMANUAL_NEW,
    AIRPLANEMANUAL_NEW_SUCCESS,
    AIRPLANEMANUAL_NEW_ERROR,
    AIRPLANEMANUAL_EDIT,
    AIRPLANEMANUAL_EDIT_SUCCESS,
    AIRPLANEMANUAL_EDIT_ERROR
} from '../types';

// Conexión con la API usando Axios.
//const axios = require('axios');
import AxiosClient from '../../config/AxiosClient';
require('dotenv').config();

// Función Principal para Listar los Manuales
export function AirplaneManualListAction(airplane_id){
    return (dispatch =>{
        dispatch(AirplaneManualList());
        AxiosClient.get(`${process.env.REACT_APP_AIRPLANEMANUAL}/list/${airplane_id}`)
            .then((response)=> {
                dispatch(AirplaneManualListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( AirplaneManualListError(error) );
            });
    });
}

export const AirplaneManualList = ()=>({
    type: AIRPLANEMANUAL_LIST
});

export const AirplaneManualListSuccess = airplaneList =>({
    type: AIRPLANEMANUAL_LIST_SUCCESS,
    payload: airplaneList
});

export const AirplaneManualListError = error =>({
    type: AIRPLANEMANUAL_LIST_ERROR,
    payload: error
});

// Función Principal para obtener un AirplaneManual
export function AirplaneManualGetAction(id){
    return (dispatch =>{
        dispatch(AirplaneManualGet());
        AxiosClient.get(`${process.env.REACT_APP_AIRPLANEMANUAL}/${id}`)
            .then((response)=> {
                const record = response.data.result;
                let pdf = "";
                AxiosClient({
                    url: `${process.env.REACT_APP_AIRPLANEMANUAL}/${record.airplane.acronym}/${record.filename}`,
                    method: 'GET',
                    responseType: 'blob'
                }).then((res)=>{
                // AxiosClient.get(`${process.env.REACT_APP_AIRPLANEMANUAL}/${record.airplane.acronym}/${record.filename}`)
                // .then((res)=>{
                //     const newBlob = new Blob([res.data], {type: "application/pdf"});
                    pdf = URL.createObjectURL(res.data);
                    dispatch( AirplaneManualGetSuccess(record, pdf));
                });
                dispatch( AirplaneManualGetSuccess(record, pdf));
            })
            .catch(error=> {
                dispatch( AirplaneManualGetError(error) );
            });
    });
}

export const AirplaneManualGet = () => ({
    type: AIRPLANEMANUAL_GET
});

export const AirplaneManualGetSuccess = (record, pdf) => ({
    type: AIRPLANEMANUAL_GET_SUCCESS,
    payload: record,
    pdf: pdf
});

export const AirplaneManualGetError = (error) => ({
    type: AIRPLANEMANUAL_GET_ERROR,
    payload: error
});

// Función Principal para crear y editar registros de AirplaneManual
export function AirplaneManualSaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(AirplaneManualEdit());
        } else{
            dispatch(AirplaneManualNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_AIRPLANEMANUAL}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data',
                            'Accept': 'application/json'
                        }
                    }
                    AxiosClient.post(`${process.env.REACT_APP_AIRPLANEMANUAL}/attached`, 
                                record.fileForm, config)
                    .then((manualpdf)=>{
                        console.log('Anexo incluido');
                    }).catch(err=>{
                        console.log('error', err);
                    });
                    dispatch( AirplaneManualNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( AirplaneManualNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_AIRPLANEMANUAL}/${record.id}`, record)
                .then((response)=> {
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data',
                            'Accept': 'application/json'
                        }
                    }
                    AxiosClient.post(`${process.env.REACT_APP_AIRPLANEMANUAL}/attached`, 
                                record.fileForm, config)
                    .then((manualpdf)=>{
                        console.log('Anexo incluido');
                    }).catch(err=>{
                        console.log('error', err);
                    });
                    dispatch( AirplaneManualEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( AirplaneManualEditError(true) );
                });
        }
    });
}

export const AirplaneManualNew = () => ({
    type: AIRPLANEMANUAL_NEW
});

export const AirplaneManualNewSuccess = (record) => ({
    type: AIRPLANEMANUAL_NEW_SUCCESS,
    payload: record
});

export const AirplaneManualNewError = (error) => ({
    type: AIRPLANEMANUAL_NEW_ERROR,
    payload: error
});

export const AirplaneManualEdit = () => ({
    type: AIRPLANEMANUAL_EDIT
});

export const AirplaneManualEditSuccess = (record) => ({
    type: AIRPLANEMANUAL_EDIT_SUCCESS,
    payload: record
});

export const AirplaneManualEditError = (error) => ({
    type: AIRPLANEMANUAL_EDIT_ERROR,
    payload: error
});


