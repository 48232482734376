import {
    USERSECURITYQUESTION_LIST,
    USERSECURITYQUESTION_LIST_SUCCESS,
    USERSECURITYQUESTION_LIST_ERROR,
    USERSECURITYQUESTION_DDLIST,
    USERSECURITYQUESTION_DDLIST_SUCCESS,
    USERSECURITYQUESTION_DDLIST_ERROR,
    USERSECURITYQUESTION_GET,
    USERSECURITYQUESTION_GET_SUCCESS,
    USERSECURITYQUESTION_GET_ERROR,
    USERSECURITYQUESTION_NEW,
    USERSECURITYQUESTION_NEW_SUCCESS,
    USERSECURITYQUESTION_NEW_ERROR,
    USERSECURITYQUESTION_NEW_RECORD,
    USERSECURITYQUESTION_EDIT,
    USERSECURITYQUESTION_EDIT_SUCCESS,
    USERSECURITYQUESTION_EDIT_ERROR,
    USERSECURITYQUESTION_DELETE,
    USERSECURITYQUESTION_DELETE_SUCCESS,
    USERSECURITYQUESTION_DELETE_ERROR,
    USERSECURITYQUESTION_RENDER
} from '../types';

const initialState = {
    usersecurityquestions: [],
    usersecurityquestion: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case USERSECURITYQUESTION_LIST:
            return {
                ...state,
                loading: true,
                usersecurityquestion: {}
            }
        case USERSECURITYQUESTION_LIST_SUCCESS:           
            return {
                ...state,
                usersecurityquestions: [...action.payload.map((item,index) => ({...item,index : item.id ? item.id : index}))],
                loading: false,
                error: false,
                usersecurityquestion: {}
            }
        case USERSECURITYQUESTION_LIST_ERROR:
            return {
                ...state,
                usersecurityquestions: [],
                loading: false,
                error: true,
                usersecurityquestion: {}
            }
        case USERSECURITYQUESTION_DDLIST:
            return {
                ...state,
                loading: true,
                usersecurityquestion: {}
            }
        case USERSECURITYQUESTION_DDLIST_SUCCESS:
            return {
                ...state,
                usersecurityquestions: action.payload,
                loading: false,
                error: false,
                usersecurityquestion: {}
            }
        case USERSECURITYQUESTION_DDLIST_ERROR:
            return {
                ...state,
                usersecurityquestions: [],
                loading: false,
                error: true,
                usersecurityquestion: {}
            }
        case USERSECURITYQUESTION_GET:
            return {
                ...state,
                loading: true
            }
        case USERSECURITYQUESTION_GET_SUCCESS:
            return {
                ...state,
                usersecurityquestion: action.payload,
                loading: false,
                error: false
            }
        case USERSECURITYQUESTION_GET_ERROR:
            return {
                ...state,
                error: true,
                usersecurityquestion: {}
            }
        case USERSECURITYQUESTION_NEW:
            return {
                ...state
            }
        case USERSECURITYQUESTION_NEW_SUCCESS:
            return {
                ...state,
                usersecurityquestions: [...state.usersecurityquestions, action.payload],
                usersecurityquestion: {},
                error: false
            }
        case USERSECURITYQUESTION_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case USERSECURITYQUESTION_NEW_RECORD:
            return {
                ...state,
                usersecurityquestion: {},
                error: false
            }
        case USERSECURITYQUESTION_EDIT:
            return {
                ...state
            }
        case USERSECURITYQUESTION_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                usersecurityquestion: action.payload,
                usersecurityquestions: state.usersecurityquestions.map(usersecurityquestion => usersecurityquestion.id === action.payload.id ? usersecurityquestion = action.payload : usersecurityquestion),
            }
        case USERSECURITYQUESTION_EDIT_ERROR:
            return {
                ...state,
                error: false
            }

        case USERSECURITYQUESTION_DELETE:
            return {
                ...state
            }       
        case USERSECURITYQUESTION_DELETE_SUCCESS:
            //const  filtro = state.bpartnerproductList.filter(product=>product.id !==action.payload);           
            return {
                    ...state,
                usersecurityquestions: state.usersecurityquestions.filter(question=>question.id !==action.payload),                
                error: false
            }

        case USERSECURITYQUESTION_DELETE_ERROR:
            return {
                ...state,
                error: true
            }
        case USERSECURITYQUESTION_RENDER:
            return {
                ...state,
                usersecurityquestions: [...action.payload.map((item,index) => ({...item,index : item.id ? item.id : index}))]
            }
        default:
            return state;
    }
}
