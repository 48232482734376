export const RECEPTION_MATERIAL_TITLE_LABEL = 'Material reception';
export const RECEPTION_MATERIAL_SUBTITLE_LABEL = 'First step for the entry of material';
export const CLEAR_FIELDS_TITLE_BUTTON = 'Clean form';
export const PART_NUMBER_LABEL = 'Part No.';
export const DESCRIPTION_LABEL = 'Description';
export const BPARTNER_LABEL = 'Supplier';
export const INVOICE_LABEL = 'Invoice';
export const UNIT_OF_MEASURE_LABEL = 'Unit of measurement';
export const UNIT_COST_LABEL = 'Unit cost';
export const LOT_NUMBER_LABEL = 'Lot number';
export const SERIAL_NUMBER_LABEL = 'Serial number';
export const QUANTITY_LABEL = 'Amount';
export const CONDITION_LABEL = 'Condition';
export const MANUFACTURER_DATE_LABEL = 'Manufacture date';
export const OVERHAULING_DATE_LABEL = 'Date RTS (Return to Service)';
export const SHELF_LIFE_LABEL = 'Exp. Date / Next Calibration';
export const LIFE_LIMIT_LABEL = 'Maximun storage date';
export const TSN_LABEL = 'TSN';
export const TSO_LABEL = 'TSO';
export const CSN_LABEL = 'CSN';
export const CSO_LABEL = 'CSO';
export const ORIGIN_LABEL = 'Origin';
export const SERIAL_TWO_LABEL = 'Serial/Number/2';
export const REP_LABEL = 'R';
export const PURCHASE_REQUIREMENT_CODE_LABEL = 'Purchase Requirement';
export const RECEIPT_MATERIAL_DATE_LABEL = 'Material Reception Date';
export const COC_LABEL = 'Certification Documents';
export const PACKING_LIST_LABEL = 'Packing List';
export const TEAR_DOWN_LABEL = 'Tear Down / Work Order';
export const BORROWED_LABEL = 'Borrowed?';
export const LOAN_ORIGIN_LABEL = 'Loan Origin';
export const CANNIBALIZED_LABEL = 'Cannibalized?';
export const CANNIBALIZED_DESCRIPTION_LABEL = 'What airplane or components?';
export const MODEL_LABEL = 'Model';
export const STATUS_LABEL = 'Status';


export const BPARTNER_REQUIRED_MESSAGE = 'Debe seleccionar un proveedor';
export const INVOICE_REQUIRED_MESSAGE = 'Debe ingresar un número de factura';
export const ORIGIN_REQUIRED_MESSAGE = 'Debe ingresar el origen del material';
export const AMOUNT_REQUIRED_MESSAGE = 'Debe ingresar una cantidad';
export const MATERIAL_CONDITION_REQUIRED_MESSAGE = 'Debe seleccionar la condición del material';
export const UNIT_OF_MEASURE_REQUIRED_MESSAGE = 'Debe seleccionar la unidad de medida';
export const AMOUNT_INTEGER_MESSAGE = 'La cantidad debe ser entera';
export const REP_INTEGER_MESSAGE = 'R debe ser entero';
export const REP_MIN_MESSAGE = 'R debe ser mayor que 0';
export const REP_MAX_MESSAGE = 'R debe ser menor que 6';
export const LOAN_ORIGIN_IS_REQUIRED = 'Loan origin is required';
export const CANNIBALIZED_DESCRIPTION_IS_REQUIRED = 'Description is required';