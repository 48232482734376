import {
    WAREHOUSE_LIST,
    WAREHOUSE_LIST_SUCCESS,
    WAREHOUSE_LIST_ERROR,
    WAREHOUSE_DDLIST,
    WAREHOUSE_DDLIST_SUCCESS,
    WAREHOUSE_DDLIST_ERROR,
    WAREHOUSE_GET,
    WAREHOUSE_GET_SUCCESS,
    WAREHOUSE_GET_ERROR,
    WAREHOUSE_NEW,
    WAREHOUSE_NEW_SUCCESS,
    WAREHOUSE_NEW_ERROR,
    WAREHOUSE_NEW_RECORD,
    WAREHOUSE_EDIT,
    WAREHOUSE_EDIT_SUCCESS,
    WAREHOUSE_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Almacenes
export function WarehouseListAction(){
    
    return (dispatch =>{
        dispatch(WarehouseList());
        AxiosClient.get(`${process.env.REACT_APP_WAREHOUSE}`)
            .then((response)=> {
                dispatch( WarehouseListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( WarehouseListError(error) );
            });
    });
}

export const WarehouseList = () => ({
    type: WAREHOUSE_LIST
});

export const WarehouseListSuccess = (records) => ({
    type: WAREHOUSE_LIST_SUCCESS,
    payload: records
});

export const WarehouseListError = (error) => ({
    type: WAREHOUSE_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Almacen en forma de DropDown
//export function WarehouseDDListAction(name){
// export function WarehouseDDListAction(){
//     return (dispatch =>{
//         dispatch(WarehouseDDList());
//         //AxiosClient.get(`${process.env.REACT_APP_WAREHOUSE}/ddlist/${name}`)        
//         AxiosClient.get(`${process.env.REACT_APP_WAREHOUSE}/ddlist`)        
//             .then((response)=> {
//                 dispatch( WarehouseDDListSuccess(response.data.result, name));
//             })
//             .catch(error=> {
//                 dispatch( WarehouseDDListError(error) );
//             });
//     });
// }


export const WarehouseDDListAction = () => async (dispatch) => {
    dispatch(WarehouseDDList());
    AxiosClient.get(`${process.env.REACT_APP_WAREHOUSE}/ddlist`) 
        .then((response)=> {
            dispatch( WarehouseDDListSuccess(response.data.result));
        })
        .catch(error=> {
            dispatch( WarehouseDDListError(error) );
        });
}



export const WarehouseDDList = () => ({
    type: WAREHOUSE_DDLIST
});

export const WarehouseDDListSuccess = (records, name) => ({
    type: WAREHOUSE_DDLIST_SUCCESS,
    payload: records,
    id: name
});

export const WarehouseDDListError = (error) => ({
    type: WAREHOUSE_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener un Almacén
export function WarehouseGetAction(id){
    return (dispatch =>{
        dispatch(WarehouseGet());
        AxiosClient.get(`${process.env.REACT_APP_WAREHOUSE}/${id}`)
            .then((response)=> {
                dispatch( WarehouseGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( WarehouseGetError(error) );
            });
    });
}

export const WarehouseGet = () => ({
    type: WAREHOUSE_GET
});

export const WarehouseGetSuccess = (record) => ({
    type: WAREHOUSE_GET_SUCCESS,
    payload: record
});

export const WarehouseGetError = (error) => ({
    type: WAREHOUSE_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de EntityMd cuando se va crear un registro nuevo
export function WarehouseNewAction(){
    return (dispatch =>{
            dispatch(WarehouseNewRecord());
    });
}

export const WarehouseNewRecord = () => ({
    type: WAREHOUSE_NEW_RECORD
});

// Función Principal para crear y editar registros de Almacenes
export function WarehouseSaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {            
            dispatch(WarehouseEdit());
        } else{
            dispatch(WarehouseNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_WAREHOUSE}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( WarehouseNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( WarehouseNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_WAREHOUSE}/${record.id}`, record)
                .then((response)=> {
                    dispatch( WarehouseEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( WarehouseEditError(true) );
                });
        }
    });
}

export const WarehouseNew = () => ({
    type: WAREHOUSE_NEW
});

export const WarehouseNewSuccess = (record) => ({
    type: WAREHOUSE_NEW_SUCCESS,
    payload: record
});

export const WarehouseNewError = (error) => ({
    type: WAREHOUSE_NEW_ERROR,
    payload: error
});

export const WarehouseEdit = () => ({
    type: WAREHOUSE_EDIT
});

export const WarehouseEditSuccess = (record) => ({
    type: WAREHOUSE_EDIT_SUCCESS,
    payload: record
});

export const WarehouseEditError = (error) => ({
    type: WAREHOUSE_EDIT_ERROR,
    payload: error
});