import {
    COMPANY_LIST,
    COMPANY_LIST_SUCCESS,
    COMPANY_LIST_ERROR,
    COMPANY_DDLIST,
    COMPANY_DDLIST_SUCCESS,
    COMPANY_DDLIST_ERROR,
    COMPANY_GET,
    COMPANY_GET_SUCCESS,
    COMPANY_GET_ERROR,
    COMPANY_NEW,
    COMPANY_NEW_SUCCESS,
    COMPANY_NEW_ERROR,
    COMPANY_NEW_RECORD,
    COMPANY_EDIT,
    COMPANY_EDIT_SUCCESS,
    COMPANY_EDIT_ERROR,
    SET_COMPANY_SERVICEROLE_LIST,
    ADD_COMPANY_SERVICEROLE_LIST,
    REMOVE_COMPANY_SERVICEROLE_LIST,
    COMPANY_LOGISTIC_OPERATOR_DD_LIST,
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Unidades de Medida
export function CompanyListAction(){
    
    return (dispatch =>{
        dispatch(CompanyList());
        AxiosClient.get(`${process.env.REACT_APP_COMPANY}`)
            .then((response)=> {
                dispatch(CompanyListSuccess(response.data.result));
            })
            .catch(error=> {
                console.log(error);
                dispatch( CompanyListError(error) );
            });
    });
}

export const CompanyList = () => ({
    type: COMPANY_LIST
});

export const CompanyListSuccess = (records) => ({
    type: COMPANY_LIST_SUCCESS,
    payload: records
});

export const CompanyListError = (error) => ({
    type: COMPANY_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Unidad de Medida
export function CompanyDDListAction(){
    
    return (dispatch =>{
        dispatch(CompanyDDList());
        AxiosClient.get(`${process.env.REACT_APP_COMPANY}/ddlist`)
            .then((response)=> {
                dispatch( CompanyDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( CompanyDDListError(error) );
            });
    });
}

export const CompanyDDList = () => ({
    type: COMPANY_DDLIST
});

export const CompanyDDListSuccess = (records) => ({
    type: COMPANY_DDLIST_SUCCESS,
    payload: records
});

export const CompanyDDListError = (error) => ({
    type: COMPANY_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener una Unidad de Medida por ID
export function CompanyGetAction(id){
    return (dispatch =>{
        dispatch(CompanyGet());
        AxiosClient.get(`${process.env.REACT_APP_COMPANY}/${id}`)
            .then((response)=> {
                dispatch( CompanyGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( CompanyGetError(error) );
            });
    });
}

export const CompanyGet = () => ({
    type: COMPANY_GET
});

export const CompanyGetSuccess = (record) => ({
    type: COMPANY_GET_SUCCESS,
    payload: record
});

export const CompanyGetError = (error) => ({
    type: COMPANY_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Unidad de Medida cuando se va crear un registro nuevo
export function CompanyNewAction(){
    return (dispatch =>{
            dispatch(CompanyNewRecord());
    });
}

export const CompanyNewRecord = () => ({
    type: COMPANY_NEW_RECORD
});

// Función Principal para crear y editar registros de Unidades de Medida
export function CompanySaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(CompanyEdit());
        } else{
            dispatch(CompanyNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_COMPANY}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( CompanyNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( CompanyNewError(record) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_COMPANY}/${record.id}`, record)
                .then((response)=> {
                    dispatch( CompanyEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( CompanyEditError(record) );
                });
        }
    });
}

export const CompanyNew = () => ({
    type: COMPANY_NEW
});

export const CompanyNewSuccess = (record) => ({
    type: COMPANY_NEW_SUCCESS,
    payload: record
});

export const CompanyNewError = (error) => ({
    type: COMPANY_NEW_ERROR,
    payload: error
});

export const CompanyEdit = () => ({
    type: COMPANY_EDIT
});

export const CompanyEditSuccess = (record) => ({
    type: COMPANY_EDIT_SUCCESS,
    payload: record
});

export const CompanyEditError = (error) => ({
    type: COMPANY_EDIT_ERROR,
    payload: error
});

export const setCompanyServiceRoleListAction = ( companyServiceRoleList = [] ) => ({
    type: SET_COMPANY_SERVICEROLE_LIST,
    payload: companyServiceRoleList
});

export const addCompanyServiceRoleListAction = ( companyServiceRole = {} ) => ({
    type: ADD_COMPANY_SERVICEROLE_LIST,
    payload: companyServiceRole
});

export const removeCompanyServiceRoleListAction = ( companyServiceRole = {} ) => ({
    type: REMOVE_COMPANY_SERVICEROLE_LIST,
    payload: companyServiceRole
});

// Función para obtener dd list operadores logisticos

export const CompanyLogisticOperatorDDListAction = ( logisticOperatorCompanyDDList = [] ) => ({
    type: COMPANY_LOGISTIC_OPERATOR_DD_LIST,
    payload: logisticOperatorCompanyDDList
});

