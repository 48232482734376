import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

export default function ThemeDatePickerSAM(props){

  const theme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: "black"
        }
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: "black"
        }
      },
      MuiButton: {
        textPrimary: {
          color: 'black',
        }
      },
      MuiInputBase: {
        root: {
          fontSize: 13
        }
      },
    }
  });

  return (
    <ThemeProvider theme={theme}>
        <DatePicker
            disableFuture={props.disableFuture}
            disablePast={props.disablePast}
            clearable
            autoOk
            name={props.name}
            openTo={props.openTo}
            format={props.format || "dd/MM/yyyy"}
            label={props.label}
            clearLabel="Limpiar"
            cancelLabel="Cancelar"
            views={props.views}
            value={props.value}
            onChange={props.onChange} 
            disabled={props.disabled}       
            minDate={props.minDate}
            maxDate={props.maxDate}    
        />
    </ThemeProvider>
  )

  

}
