import {
    BPARTNER_LIST,
    BPARTNER_LIST_SUCCESS,
    BPARTNER_LIST_ERROR,
    BPARTNER_DDLIST,
    BPARTNER_DDLIST_SUCCESS,
    BPARTNER_DDLIST_ERROR,
    BPARTNER_GET,
    BPARTNER_GET_SUCCESS,
    BPARTNER_GET_ERROR,
    BPARTNER_NEW,
    BPARTNER_NEW_SUCCESS,
    BPARTNER_NEW_ERROR,
    BPARTNER_NEW_RECORD,
    BPARTNER_EDIT,
    BPARTNER_EDIT_SUCCESS,
    BPARTNER_EDIT_ERROR
} from '../types';

//const AxiosClient = require('AxiosClient');
import AxiosClient from '../../config/AxiosClient';
require('dotenv').config();

// Función Principal para obtener lista de BPartner
export function BPartnerListAction(){
    
    return (dispatch =>{
        dispatch(BPartnerList());
        AxiosClient.get(`${process.env.REACT_APP_BPARTNER}/all`)
            .then((response)=> {
                dispatch( BPartnerListSuccess(response.data.result));
            })
            .catch(error=> {               
                dispatch( BPartnerListError(error) );
            });
    });
}

export const BPartnerList = () => ({
    type: BPARTNER_LIST
});

export const BPartnerListSuccess = (records) => ({
    type: BPARTNER_LIST_SUCCESS,
    payload: records
});

export const BPartnerListError = (error) => ({
    type: BPARTNER_LIST_ERROR,
    payload: error
});

// Función Principal para obtener BPartner
export function BPartnerDDListAction(){
    
    return (dispatch =>{
        dispatch(BPartnerDDList());
        AxiosClient.get(`${process.env.REACT_APP_BPARTNER}/ddlist`)
            .then((response)=> {
                dispatch( BPartnerDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( BPartnerDDListError(error) );
            });
    });
}

export const BPartnerDDList = () => ({
    type: BPARTNER_DDLIST
});

export const BPartnerDDListSuccess = (records) => ({
    type: BPARTNER_DDLIST_SUCCESS,
    payload: records
});

export const BPartnerDDListError = (error) => ({
    type: BPARTNER_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener un BPartner ID
export function BPartnerGetAction(id){
    return (dispatch =>{
        dispatch(BPartnerGet());
        AxiosClient.get(`${process.env.REACT_APP_BPARTNER}/${id}`)
            .then((response)=> {                
                dispatch( BPartnerGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( BPartnerGetError(error) );
            });
    });
}

export const BPartnerGet = () => ({
    type: BPARTNER_GET
});

export const BPartnerGetSuccess = (record) => ({
    type: BPARTNER_GET_SUCCESS,
    payload: record
});

export const BPartnerGetError = (error) => ({
    type: BPARTNER_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de BPartner cuando se va crear un registro nuevo
export function BPartnerNewAction(){
    return (dispatch =>{
            dispatch(BPartnerNewRecord());
    });
}

export const BPartnerNewRecord = () => ({
    type: BPARTNER_NEW_RECORD
});

// Función Principal para crear y editar registros de BPartner
export function BPartnerSaveAction(record, isNew){    
    return (dispatch =>{
        if(!isNew) {
            dispatch(BPartnerEdit());
        } else{
            dispatch(BPartnerNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_BPARTNER}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( BPartnerNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( BPartnerNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_BPARTNER}/${record.id}`, record)
                .then((response)=> {
                    dispatch( BPartnerEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( BPartnerEditError(true) );
                });
        }
    });
}

export const BPartnerNew = () => ({
    type: BPARTNER_NEW
});

export const BPartnerNewSuccess = (record) => ({
    type: BPARTNER_NEW_SUCCESS,
    payload: record
});

export const BPartnerNewError = (error) => ({
    type: BPARTNER_NEW_ERROR,
    payload: error
});

export const BPartnerEdit = () => ({
    type: BPARTNER_EDIT
});

export const BPartnerEditSuccess = (record) => ({
    type: BPARTNER_EDIT_SUCCESS,
    payload: record
});

export const BPartnerEditError = (error) => ({
    type: BPARTNER_EDIT_ERROR,
    payload: error
});
