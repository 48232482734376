import {
    ROLES_DD_LIST,
    ROLES_DD_LIST_ERROR,
    ROLES_DD_LIST_SUCCESS,
    ROLES_GET,
    ROLES_GET_ERROR,
    ROLES_GET_SUCCESS,
    ROLES_LIST,
    ROLES_LIST_ERROR,
    ROLES_LIST_SUCCESS,
    ROLES_SAVE,
    ROLES_SAVE_ERROR,
    ROLES_SAVE_SUCCESS,
} from '../types';

const initialState = {
    roleList: [],
    roleDDList: [],
    role: {},
    loading: false,
    error: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ROLES_LIST:
            return {
                ...state,
                loading: true,
                roleList: [],
            };
        case ROLES_LIST_SUCCESS:
            return {
                ...state,
                roleList: action.payload,
                loading: false,
                error: false,
            };
        case ROLES_LIST_ERROR:
            return {
                ...state,
                roleList: [],
                loading: false,
                error: true,
            };
        case ROLES_SAVE:
            return {
                ...state,
                loading: true,
                role: {},
            };
        case ROLES_SAVE_SUCCESS:
            return {
                ...state,
                role: action.payload,
                loading: false,
                error: false,
            };
        case ROLES_SAVE_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                role: {},
            };
        case ROLES_GET:
            return {
                ...state,
                role: {},
                loading: true,
                error: false,
            };
        case ROLES_GET_SUCCESS:
            return {
                ...state,
                role: action.payload,
                loading: false,
                error: false,
            };
        case ROLES_GET_ERROR:
            return {
                ...state,
                role: {},
                loading: false,
                error: true,
            };
        case ROLES_DD_LIST:
            return {
                ...state,
                roleDDList: [],
                loading: true,
            };
        case ROLES_DD_LIST_SUCCESS:
            return {
                ...state,
                roleDDList: action.payload,
                loading: false,
            };
        case ROLES_DD_LIST_ERROR:
            return {
                ...state,
                roleDDList: [],
                loading: false,
                error: true,
            };
        default:
            return state;
    }
}
