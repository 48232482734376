import {
    EQUIPMENT_LOG_DD_LIST,
    EQUIPMENT_LOG_DD_LIST_ERROR,
    EQUIPMENT_LOG_DD_LIST_SUCCESS,
    EQUIPMENT_LOG_GET,
    EQUIPMENT_LOG_GET_ERROR,
    EQUIPMENT_LOG_GET_SUCCESS,
    EQUIPMENT_LOG_LIST,
    EQUIPMENT_LOG_LIST_ERROR,
    EQUIPMENT_LOG_LIST_SUCCESS,
    EQUIPMENT_LOG_SAVE,
    EQUIPMENT_LOG_SAVE_ERROR,
    EQUIPMENT_LOG_SAVE_SUCCESS
} from "../types";

const initialState = {
    equipmentLogLogList: [],
    equipmentLogDDList: [],
    equipmentLog: {},
    loading: false,
    error: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case EQUIPMENT_LOG_LIST:
            return {
                ...state,
                loading: true,
                equipmentLogList: [],
            };
        case EQUIPMENT_LOG_LIST_SUCCESS:
            return {
                ...state,
                equipmentLogList: action.payload,
                loading: false,
                error: false,
            };
        case EQUIPMENT_LOG_LIST_ERROR:
            return {
                ...state,
                equipmentLogList: [],
                loading: false,
                error: true,
            };
        case EQUIPMENT_LOG_SAVE:
            return {
                ...state,
                loading: true,
                equipmentLog: {},
            };
        case EQUIPMENT_LOG_SAVE_SUCCESS:
            return {
                ...state,
                equipmentLog: action.payload,
                loading: false,
                error: false,
            };
        case EQUIPMENT_LOG_SAVE_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                equipmentLog: {},
            };
        case EQUIPMENT_LOG_GET:
            return {
                ...state,
                equipmentLog: {},
                loading: true,
                error: false,
            };
        case EQUIPMENT_LOG_GET_SUCCESS:
            return {
                ...state,
                equipmentLog: action.payload,
                loading: false,
                error: false,
            };
        case EQUIPMENT_LOG_GET_ERROR:
            return {
                ...state,
                equipmentLog: {},
                loading: false,
                error: true,
            };
        case EQUIPMENT_LOG_DD_LIST:
            return {
                ...state,
                equipmentLogDDList: [],
                loading: true,
            };
        case EQUIPMENT_LOG_DD_LIST_SUCCESS:
            return {
                ...state,
                equipmentLogDDList: action.payload,
                loading: false,
            };
        case EQUIPMENT_LOG_DD_LIST_ERROR:
            return {
                ...state,
                equipmentLogDDList: [],
                loading: false,
                error: true,
            };
        default:
            return state;
    }
}
