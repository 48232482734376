import {
    DEPARTMENT_LIST,
    DEPARTMENT_LIST_SUCCESS,
    DEPARTMENT_LIST_ERROR,
    DEPARTMENT_DDLIST,
    DEPARTMENT_DDLIST_SUCCESS,
    DEPARTMENT_DDLIST_ERROR,
    DEPARTMENT_GET,
    DEPARTMENT_GET_SUCCESS,
    DEPARTMENT_GET_ERROR,
    DEPARTMENT_NEW,
    DEPARTMENT_NEW_SUCCESS,
    DEPARTMENT_NEW_ERROR,
    DEPARTMENT_NEW_RECORD,
    DEPARTMENT_EDIT,
    DEPARTMENT_EDIT_SUCCESS,
    DEPARTMENT_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Departamentos
export function DepartmentListAction(){
    
    return (dispatch =>{
        dispatch(DepartmentList());
        AxiosClient.get(`${process.env.REACT_APP_DEPARTMENT}`)        
            .then((response)=> {
                dispatch( DepartmentListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( DepartmentListError(error) );
            });
    });
}

export const DepartmentList = () => ({
    type: DEPARTMENT_LIST
});

export const DepartmentListSuccess = (records) => ({
    type: DEPARTMENT_LIST_SUCCESS,
    payload: records
});

export const DepartmentListError = (error) => ({
    type: DEPARTMENT_LIST_ERROR,
    payload: error
});

// Función Principal para obtener los Departamentos en forma de DropDown
export function DepartmentDDListAction(){
    return (dispatch =>{
        dispatch(DepartmentDDList());
        AxiosClient.get(`${process.env.REACT_APP_DEPARTMENT}/ddlist`)
            .then((response)=> {
                dispatch( DepartmentDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( DepartmentDDListError(error) );
            });
    });
}
export const DepartmentDDList = () => ({
    type: DEPARTMENT_DDLIST
});

export const DepartmentDDListSuccess = (records) => ({
    type: DEPARTMENT_DDLIST_SUCCESS,
    payload: records        
});

export const DepartmentDDListError = (error) => ({
    type: DEPARTMENT_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener un Departamento
export function DepartmentGetAction(id){
    return (dispatch =>{
        dispatch(DepartmentGet());
        AxiosClient.get(`${process.env.REACT_APP_DEPARTMENT}/${id}`)
            .then((response)=> {
                dispatch( DepartmentGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( DepartmentEditError(error) );
            });
    });
}

export const DepartmentGet = () => ({
    type: DEPARTMENT_GET
});

export const DepartmentGetSuccess = (record) => ({
    type: DEPARTMENT_GET_SUCCESS,
    payload: record
});

export const DepartmentMdGetError = (error) => ({
    type: DEPARTMENT_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Departamento cuando se va crear un registro nuevo
export function DepartmentNewAction(){
    return (dispatch =>{
            dispatch(DepartmentNewRecord());
    });
}

export const DepartmentNewRecord = () => ({
    type: DEPARTMENT_NEW_RECORD
});

// Función Principal para crear y editar registros de Departamentos
export function DepartmentSaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(DepartmentEdit());
        } else{
            dispatch(DepartmentNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_DEPARTMENT}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( DepartmentNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( DepartmentNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_DEPARTMENT}/${record.id}`, record)
                .then((response)=> {
                    dispatch( DepartmentEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( DepartmentEditError(true) );
                });
        }
    });
}

export const DepartmentNew = () => ({
    type: DEPARTMENT_NEW
});

export const DepartmentNewSuccess = (record) => ({
    type: DEPARTMENT_NEW_SUCCESS,
    payload: record
});

export const DepartmentNewError = (error) => ({
    type: DEPARTMENT_NEW_ERROR,
    payload: error
});

export const DepartmentEdit = () => ({
    type: DEPARTMENT_EDIT
});

export const DepartmentEditSuccess = (record) => ({
    type: DEPARTMENT_EDIT_SUCCESS,
    payload: record
});

export const DepartmentEditError = (error) => ({
    type: DEPARTMENT_EDIT_ERROR,
    payload: error
});