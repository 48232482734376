import {
    FLEET_LIST,
    FLEET_LIST_SUCCESS,
    FLEET_LIST_ERROR,
    FLEET_DDLIST,
    FLEET_DDLIST_SUCCESS,
    FLEET_DDLIST_ERROR,
    // FLEET_NEW,
    // FLEET_NEW_SUCCESS,
    // FLEET_NEW_ERROR,
    // FLEET_EDIT,
    // FLEET_EDIT_SUCCESS,
    // FLEET_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Flotas
export function FleetListAction(){
    
    return (dispatch =>{
        dispatch(FleetList());
        AxiosClient.get(`${process.env.REACT_APP_FLEET}/ddlist`)
            .then((response)=> {
                dispatch( FleetListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( FleetListError(error) );
            });
    });
}

export const FleetList = () => ({
    type: FLEET_LIST
});

export const FleetListSuccess = (records) => ({
    type: FLEET_LIST_SUCCESS,
    payload: records
});

export const FleetListError = (error) => ({
    type: FLEET_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Lista de Flota en forma de Dropdown
export function FleetDDListAction(){
    return (dispatch =>{
        dispatch(FleetDDList());
        AxiosClient.get(`${process.env.REACT_APP_FLEET}/ddlist`)
            .then((response)=> {
                dispatch( FleetDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( FleetDDListError(error) );
            });
    });
}

export const FleetDDList = () => ({
    type: FLEET_DDLIST
});

export const FleetDDListSuccess = (records) => ({
    type: FLEET_DDLIST_SUCCESS,
    payload: records
});

export const FleetDDListError = (error) => ({
    type: FLEET_DDLIST_ERROR,
    payload: error
});