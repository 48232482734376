import {    
    BPARTNERBANK_LIST,    
    BPARTNERBANK_LIST_SUCCESS,
    BPARTNERBANK_LIST_ERROR,
    BPARTNERBANK_DDLIST,
    BPARTNERBANK_DDLIST_SUCCESS,
    BPARTNERBANK_DDLIST_ERROR,
    BPARTNERBANKALL_DDLIST,
    BPARTNERBANKALL_DDLIST_SUCCESS,
    BPARTNERBANKALL_DDLIST_ERROR,
    BPARTNERBANK_GET,
    BPARTNERBANK_GET_SUCCESS,
    BPARTNERBANK_GET_ERROR,
    BPARTNERBANK_NEW,
    BPARTNERBANK_NEW_SUCCESS,
    BPARTNERBANK_NEW_ERROR,
    BPARTNERBANK_NEW_RECORD,
    BPARTNERBANK_EDIT,
    BPARTNERBANK_EDIT_SUCCESS,
    BPARTNERBANK_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Monedas
export function BPartnerBankListAction(){
    
    return (dispatch =>{
        dispatch(BPartnerBankList());
        AxiosClient.get(`${process.env.REACT_APP_BPARTNERBANK}`)
            .then((response)=> {
                dispatch(BPartnerBankListSuccess(response.data.result));
            })
            .catch(error=> {
                console.log(error);
                dispatch( BPartnerBankListError(error) );
            });
    });
}

export const BPartnerBankList = () => ({
    type: BPARTNERBANK_LIST
});

export const BPartnerBankListSuccess = (records) => ({
    type: BPARTNERBANK_LIST_SUCCESS,
    payload: records
});

export const BPartnerBankListError = (error) => ({
    type: BPARTNERBANK_LIST_ERROR,
    payload: error
});

// Función Principal para obtener todos los Bancos y Cuentas de Proveedores en forma de DropDown
export function BPartnerBankAllDDListAction(id){    
    return (dispatch =>{
        dispatch(BPartnerBankAllDDList());
        AxiosClient.get(`${process.env.REACT_APP_BPARTNERBANK}/ddlist`)
            .then((response)=> {
                dispatch( BPartnerBankAllDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( BPartnerBankAllDDListError(error) );
            });
    });
}
export const BPartnerBankAllDDList = () => ({
    type: BPARTNERBANKALL_DDLIST
});

export const BPartnerBankAllDDListSuccess = (records) => ({
    type: BPARTNERBANKALL_DDLIST_SUCCESS,
    payload: records
});

export const BPartnerBankAllDDListError = (error) => ({
    type: BPARTNERBANKALL_DDLIST_ERROR,
    payload: error
});


// Función Principal para obtener Bancos y cuentas de un Bpartner Específico en forma de DropDown
export function BPartnerBankDDListAction(id){    
    return (dispatch =>{
        dispatch(BPartnerBankDDList());
        AxiosClient.get(`${process.env.REACT_APP_BPARTNERBANK}/ddlist/${id}`)
            .then((response)=> {
                dispatch( BPartnerBankDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( BPartnerBankDDListError(error) );
            });
    });
}
export const BPartnerBankDDList = () => ({
    type: BPARTNERBANK_DDLIST
});

export const BPartnerBankDDListSuccess = (records) => ({
    type: BPARTNERBANK_DDLIST_SUCCESS,
    payload: records
});

export const BPartnerBankDDListError = (error) => ({
    type: BPARTNERBANK_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener una Unidad de Medida por ID
export function BPartnerBankGetAction(id){
    return (dispatch =>{
        dispatch(BPartnerBankGet());
        AxiosClient.get(`${process.env.REACT_APP_BPARTNERBANK}/${id}`)
            .then((response)=> {
                dispatch( BPartnerBankGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( BPartnerBankGetError(error) );
            });
    });
}

export const BPartnerBankGet = () => ({
    type: BPARTNERBANK_GET
});

export const BPartnerBankGetSuccess = (record) => ({
    type: BPARTNERBANK_GET_SUCCESS,
    payload: record
});

export const BPartnerBankGetError = (error) => ({
    type: BPARTNERBANK_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Unidad de Medida cuando se va crear un registro nuevo
export function BPartnerBankNewAction(){
    return (dispatch =>{
            dispatch(BPartnerBankNewRecord());
    });
}

export const BPartnerBankNewRecord = () => ({
    type: BPARTNERBANK_NEW_RECORD
});

// Función Principal para crear y editar registros de Unidades de Medida
export function BPartnerBankSaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(BPartnerBankEdit());
        } else{
            dispatch(BPartnerBankNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_BPARTNERBANK}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( BPartnerBankNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( BPartnerBankNewError(record) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_BPARTNERBANK}/${record.id}`, record)
                .then((response)=> {
                    dispatch( BPartnerBankEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( BPartnerBankEditError(record) );
                });
        }
    });
}

export const BPartnerBankNew = () => ({
    type: BPARTNERBANK_NEW
});

export const BPartnerBankNewSuccess = (record) => ({
    type: BPARTNERBANK_NEW_SUCCESS,
    payload: record
});

export const BPartnerBankNewError = (error) => ({
    type: BPARTNERBANK_NEW_ERROR,
    payload: error
});

export const BPartnerBankEdit = () => ({
    type: BPARTNERBANK_EDIT
});

export const BPartnerBankEditSuccess = (record) => ({
    type: BPARTNERBANK_EDIT_SUCCESS,
    payload: record
});

export const BPartnerBankEditError = (error) => ({
    type: BPARTNERBANK_EDIT_ERROR,
    payload: error
});









// import {
//     BPARTNERBANK_LIST,
//     BPARTNERBANK_LIST_SUCCESS,
//     BPARTNERBANK_LIST_ERROR,
//     BPARTNERBANK_DDLIST,
//     BPARTNERBANK_DDLIST_SUCCESS,
//     BPARTNERBANK_DDLIST_ERROR,
//     BPARTNERBANK_GET,
//     BPARTNERBANK_GET_SUCCESS,
//     BPARTNERBANK_GET_ERROR,
//     BPARTNERBANK_NEW,
//     BPARTNERBANK_NEW_SUCCESS,
//     BPARTNERBANK_NEW_ERROR,
//     BPARTNERBANK_NEW_RECORD,
//     BPARTNERBANK_EDIT,
//     BPARTNERBANK_EDIT_SUCCESS,
//     BPARTNERBANK_EDIT_ERROR    
// } from '../types';

// import AxiosClient from '../../config/AxiosClient';

// require('dotenv').config();

// // Función Principal para obtener lista de BPartnerBank
// export function BPartnerBankListAction(id){    
//     return (dispatch =>{
//         dispatch(BPartnerBankList());
//         AxiosClient.get(`${process.env.REACT_APP_BPARTNERBANK}/list/${id}`)
//             .then((response)=> {               
//                 dispatch( BPartnerBankListSuccess(response.data.result));
//             })
//             .catch(error=> {                
//                 dispatch( BPartnerBankListError(error) );
//             });
//     });
// }

// export const BPartnerBankList = () => ({
//     type: BPARTNERBANK_LIST
// });

// export const BPartnerBankListSuccess = (records) => ({
//     type: BPARTNERBANK_LIST_SUCCESS,
//     payload: records
// });

// export const BPartnerBankListError = (error) => ({
//     type: BPARTNERBANK_LIST_ERROR,
//     payload: error
// });

// // Función Principal para obtener BPartneBank asociados a un Proveedor específico en forma de Dropdown
// export function BPartnerBankDDListAction(id){
//     return (dispatch =>{
//         dispatch(BPartnerBankDDList());
//         AxiosClient.get(`${process.env.REACT_APP_BPARTNERBANK}/ddlist/${id}`)
//             .then((response)=> {
//                 dispatch( BPartnerBankDDListSuccess(response.data.result));
//             })
//             .catch(error=> {
//                 dispatch( BPartnerBankDDListError(error) );
//             });
//     });
// }

// export const BPartnerBankDDList = () => ({
//     type: BPARTNERBANK_DDLIST
// });

// export const BPartnerBankDDListSuccess = (records) => ({
//     type: BPARTNERBANK_DDLIST_SUCCESS,
//     payload: records
// });

// export const BPartnerBankDDListError = (error) => ({
//     type: BPARTNERBANK_DDLIST_ERROR,
//     payload: error
// });

// // Función Principal para obtener un BPartnerBank ID
// export function BPartnerBankGetAction(id){
//     return (dispatch =>{
//         dispatch(BPartnerBankGet());
//         AxiosClient.get(`${process.env.REACT_APP_BPARTNERBANK}/${id}`)
//             .then((response)=> {
//                 dispatch( BPartnerBankGetSuccess(response.data.result));
//             })
//             .catch(error=> {
//                 dispatch( BPartnerBankGetError(error) );
//             });
//     });
// }

// export const BPartnerBankGet = () => ({
//     type: BPARTNERBANK_GET
// });

// export const BPartnerBankGetSuccess = (record) => ({
//     type: BPARTNERBANK_GET_SUCCESS,
//     payload: record
// });

// export const BPartnerBankGetError = (error) => ({
//     type: BPARTNERBANK_GET_ERROR,
//     payload: error
// });

// //Función Principal para setear objeto de BPartnerBank cuando se va crear un registro nuevo
// export function BPartnerBankNewAction(idItemMaster, id){       
//     return (dispatch =>{
//             //dispatch(BPartnerBankNewRecord());
//             dispatch(BPartnerBankNewRecord(idItemMaster,id));
//     });
// }

// export const BPartnerBankNewRecord = () => ({
//     type: BPARTNERBANK_NEW_RECORD
// });

// // Función Principal para crear y editar registros de BPartnerBank
// export function BPartnerBankSaveAction(record, isNew){   
//     return (dispatch =>{
//         if(!isNew) {
//             dispatch(BPartnerBankEdit());
//         } else{
//             dispatch(BPartnerBankNew());
//         }
//         if(isNew){
//             AxiosClient.post(`${process.env.REACT_APP_BPARTNERBANK}`, record)
//                 .then((response)=> {
//                     record.id = response.data.result.id;
//                     dispatch( BPartnerBankNewSuccess(record));
//                 })
//                 .catch(error=> {
//                     dispatch( BPartnerBankNewError(error) );
//                 });
//         }else{
//             AxiosClient.put(`${process.env.REACT_APP_BPARTNERBANK}/${record.id}`, record)
//                 .then((response)=> {
//                     dispatch( BPartnerBankEditSuccess(record));
//                 })
//                 .catch(error=> {
//                     dispatch( BPartnerBankEditError(true) );
//                 });
//         }
//     });
// }

// export const BPartnerBankNew = () => ({
//     type: BPARTNERBANK_NEW
// });

// export const BPartnerBankNewSuccess = (record) => ({
//     type: BPARTNERBANK_NEW_SUCCESS,
//     payload: record
// });

// export const BPartnerBankNewError = (error) => ({
//     type: BPARTNERBANK_NEW_ERROR,
//     payload: error
// });

// export const BPartnerBankEdit = () => ({
//     type: BPARTNERBANK_EDIT
// });

// export const BPartnerBankEditSuccess = (record) => ({
//     type: BPARTNERBANK_EDIT_SUCCESS,
//     payload: record
// });

// export const BPartnerBankEditError = (error) => ({
//     type: BPARTNERBANK_EDIT_ERROR,
//     payload: error
// });