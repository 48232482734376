import {
    AIRPORTIATA_LIST,
    AIRPORTIATA_LIST_SUCCESS,
    AIRPORTIATA_LIST_ERROR,
    AIRPORTIATA_DDLIST,
    AIRPORTIATA_DDLIST_SUCCESS,
    AIRPORTIATA_DDLIST_ERROR,
    AIRPORTIATA_GET,
    AIRPORTIATA_GET_SUCCESS,
    AIRPORTIATA_GET_ERROR,
    AIRPORTIATA_NEW,
    AIRPORTIATA_NEW_SUCCESS,
    AIRPORTIATA_NEW_ERROR,
    AIRPORTIATA_NEW_RECORD,
    AIRPORTIATA_EDIT,
    AIRPORTIATA_EDIT_SUCCESS,
    AIRPORTIATA_EDIT_ERROR,
    AIRPORTIATA_DDLIST_CODE,
    AIRPORTIATA_DDLIST_CODE_SUCCESS,
    AIRPORTIATA_DDLIST_CODE_ERROR,
    AIRPORTIATA_DELETE,
    AIRPORTIATA_DELETE_SUCCESS,
    AIRPORTIATA_DELETE_ERROR,
    AIRPORTIATA_CITY_DD_LIST,
    AIRPORTIATA_CITY_DD_LIST_SUCCESS,
    AIRPORTIATA_CITY_DD_LIST_ERROR
} from '../types';

const initialState = {
    airportiatas: [],
    airportiata: {},
    city: [],
    loading: false,
    error: false
}
export default function (state = initialState, action) {
    switch (action.type) {
        case AIRPORTIATA_LIST:
            return {
                ...state,
                loading: true,
                airportiata: {}
            }
        case AIRPORTIATA_LIST_SUCCESS:
            return {
                ...state,
                airportiatas: action.payload,
                loading: false,
                error: false,
                airportiata: {}
            }
        case AIRPORTIATA_LIST_ERROR:
            return {
                ...state,
                airportiatas: [],
                loading: false,
                error: true,
                airportiata: {}
            }
        case AIRPORTIATA_DDLIST:
            return {
                ...state,
                loading: true,
                airportiata: {}
            }
        case AIRPORTIATA_DDLIST_SUCCESS:
            return {
                ...state,
                airportiatas: action.payload,
                loading: false,
                error: false,
                airportiata: {}
            }
        case AIRPORTIATA_DDLIST_ERROR:
            return {
                ...state,
                airportiatas: [],
                loading: false,
                error: true,
                airportiata: {}
            }
        case AIRPORTIATA_GET:
            return {
                ...state,
                loading: true
            }
        case AIRPORTIATA_GET_SUCCESS:
            return {
                ...state,
                airportiata: action.payload,
                loading: false,
                error: false
            }
        case AIRPORTIATA_GET_ERROR:
            return {
                ...state,
                error: true,
                airportiata: {}
            }
        case AIRPORTIATA_NEW:
            return {
                ...state
            }
        case AIRPORTIATA_NEW_SUCCESS:
            return {
                ...state,
                airportiatas: [...state.airportiatas, action.payload],
                airportiata: {},
                error: false
            }
        case AIRPORTIATA_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case AIRPORTIATA_NEW_RECORD:
            return {
                ...state,
                airportiata: {},
                error: false
            }
        case AIRPORTIATA_EDIT:
            return {
                ...state
            }
        case AIRPORTIATA_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                airportiata: action.payload,
                airportiatas: state.airportiatas.map(airportiata => airportiata.id === action.payload.id ? airportiata = action.payload : airportiata),
            }
        case AIRPORTIATA_EDIT_ERROR:
            return {
                ...state,
                error: false
            }
            case AIRPORTIATA_DDLIST_CODE:
            return {
                ...state,
                loading: true, 
                airportiata: {}
            }
        case AIRPORTIATA_DDLIST_CODE_SUCCESS:
            return {
                ...state,
                airportiatas: action.payload,
                loading: false,
                error: false,
                airportiata: {}
            }
        case AIRPORTIATA_DDLIST_CODE_ERROR:
            return {
                ...state,
                airportiatas: [],
                loading: false,
                error: true,
                airportiata: {}
            }
            case AIRPORTIATA_DELETE:  
            return {
                ...state,
                loading: true,
                error: false
            }

        case AIRPORTIATA_DELETE_SUCCESS:
            return {
                ...state, 
                loading: false,
                error: false,
                airportiatas: state.airportiatas.filter(airportiata => airportiata.id !== action.payload.id),
                airportiata: {}
            }

        case AIRPORTIATA_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                error: true
            }
            case AIRPORTIATA_CITY_DD_LIST:
                return {
                    ...state,
                    loading: true,
                    city: []
                };
            case AIRPORTIATA_CITY_DD_LIST_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    error: false,
                    city: action.payload
                };
            case AIRPORTIATA_CITY_DD_LIST_ERROR:
                return {
                    ...state,
                    loading: false,
                    error: true,
                    city: []
                };
        default:
            return state;
    }
}