import {
    PURCHASEREQUISITION_LIST,
    PURCHASEREQUISITION_LIST_SUCCESS,
    PURCHASEREQUISITION_LIST_ERROR,
    PURCHASEREQUISITION_DDLIST,
    PURCHASEREQUISITION_DDLIST_SUCCESS,
    PURCHASEREQUISITION_DDLIST_ERROR,
    PURCHASEREQUISITION_GET,
    PURCHASEREQUISITION_GET_SUCCESS,
    PURCHASEREQUISITION_GET_ERROR,
    PURCHASEREQUISITION_NEW,
    PURCHASEREQUISITION_NEW_SUCCESS,
    PURCHASEREQUISITION_NEW_ERROR,
    PURCHASEREQUISITION_NEW_RECORD,
    PURCHASEREQUISITION_EDIT,
    PURCHASEREQUISITION_EDIT_SUCCESS,
    PURCHASEREQUISITION_EDIT_ERROR,
    PURCHASEREQUISITION_SAVE_IN_REDUCER
} from '../types';

const initialState = {
    purchaserequisitions: [],
    purchaserequisition: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case PURCHASEREQUISITION_LIST:
            return {
                ...state,
                loading: true,
                purchaserequisition: {}
            }
        case PURCHASEREQUISITION_LIST_SUCCESS:
            return {
                ...state,
                purchaserequisitions: action.payload,
                loading: false,
                error: false,
                purchaserequisition: {}
            }
        case PURCHASEREQUISITION_LIST_ERROR:
            return {
                ...state,
                purchaserequisitions: [],
                loading: false,
                error: true,
                purchaserequisition: {}
            }
        case PURCHASEREQUISITION_DDLIST:
            return {
                ...state,
                loading: true,
                purchaserequisition: {}
            }
        case PURCHASEREQUISITION_DDLIST_SUCCESS:
            return {
                ...state,
                purchaserequisitions: action.payload,
                loading: false,
                error: false,
                purchaserequisition: {}
            }
        case PURCHASEREQUISITION_DDLIST_ERROR:
            return {
                ...state,
                purchaserequisitions: [],
                loading: false,
                error: true,
                purchaserequisition: {}
            }
        case PURCHASEREQUISITION_GET:
            return {
                ...state,
                loading: true
            }
        case PURCHASEREQUISITION_GET_SUCCESS:
            return {
                ...state,
                purchaserequisition: action.payload,
                loading: false,
                error: false
            }
        case PURCHASEREQUISITION_GET_ERROR:
            return {
                ...state,
                error: true,
                purchaserequisition: {}
            }
        case PURCHASEREQUISITION_NEW:
            return {
                ...state,
                loading: true,
            }
        case PURCHASEREQUISITION_NEW_SUCCESS:
            sessionStorage.setItem('error', false);
            return {
                ...state,
                purchaserequisitions: [...state.purchaserequisitions, action.payload],
                purchaserequisition: {},
                loading: false,
                error: false
            }
        case PURCHASEREQUISITION_NEW_ERROR:
            sessionStorage.setItem('error', true);
            return {
                ...state,
                purchaserequisition: action.payload,
                loading: false,
                error: true
            }
        case PURCHASEREQUISITION_NEW_RECORD:
            return {
                ...state,
                purchaserequisition: {},
                error: false
            }
        case PURCHASEREQUISITION_EDIT:
            return {
                ...state
            }
        case PURCHASEREQUISITION_EDIT_SUCCESS:
            sessionStorage.setItem('error', false);
            return {
                ...state,
                error: false,
                purchaserequisition: action.payload,
                purchaserequisitions: state.purchaserequisitions.map(purchaserequisition => purchaserequisition.id === action.payload.id ? purchaserequisition = action.payload : purchaserequisition),
            }
        case PURCHASEREQUISITION_EDIT_ERROR:
            sessionStorage.setItem('error', true);
            return {
                ...state,
                purchaserequisition: action.payload,
                error: false
            }
        case PURCHASEREQUISITION_SAVE_IN_REDUCER:
            return {
                ...state,
                purchaserequisition: action.payload,
                error: false
            }
        default:
            return state;
    }
}
