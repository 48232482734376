import {
    AIRPLANE_LIST,
    AIRPLANE_LIST_SUCCESS,
    AIRPLANE_LIST_ERROR,
    AIRPLANE_DDLIST,
    AIRPLANE_DDLIST_SUCCESS,
    AIRPLANE_DDLIST_ERROR,
    AIRPLANE_GET,
    AIRPLANE_GET_SUCCESS,
    AIRPLANE_GET_ERROR,
    AIRPLANE_GET_SEARCH,
    AIRPLANE_GET_SEARCH_SUCCESS,
    AIRPLANE_GET_SEARCH_ERROR,
    AIRPLANE_NEW,
    AIRPLANE_NEW_SUCCESS,
    AIRPLANE_NEW_ERROR,
    AIRPLANE_EDIT,
    AIRPLANE_EDIT_SUCCESS,
    AIRPLANE_EDIT_ERROR,
    AIRPLANE_MODEL_DDLIST,
    AIRPLANE_MODEL_DDLIST_SUCCESS,
    AIRPLANE_MODEL_DDLIST_ERROR
} from '../types';

const initialState = {
    airplanes: [],
    airplane: {},
    airplane_search_acronym: {},
    airplane_search_serial: {},
    airplane_search_name: {},
    airplane_model_ddlist: [],
    loading: false,
    error: false
}

export default function(state = initialState, action){
    //console.log('switch airplane', {type: action.type, payload: action.payload});
    switch(action.type){
        case AIRPLANE_LIST:
            return {
                ...state,
                loading: true,
                airplane: {},
                airplane_search_acronym: {},
                airplane_search_serial: {},
                airplane_search_name: {},
            }
        case AIRPLANE_LIST_SUCCESS:
            
            return {
                ...state,
                airplanes: action.payload,
                loading: false,
                error: false,
                airplane: {}
            }
        case AIRPLANE_LIST_ERROR:
            return {
                ...state,
                airplanes: [],
                loading: false,
                error: true,
                airplane: {}
            }
        case AIRPLANE_DDLIST:
            return {
                ...state,
                loading: true,
                airplane: {}
            }
        case AIRPLANE_DDLIST_SUCCESS:
            return {
                ...state,
                airplaneList: action.payload,
                loading: false,
                error: false
            }
        case AIRPLANE_DDLIST_ERROR:
            return {
                ...state,
                airplaneList: [],
                loading: false,
                error: true,
                airplane: {}
            }
        case AIRPLANE_GET:
            return {
                ...state,
                loading: true
            }
        case AIRPLANE_GET_SUCCESS:
            return {
                ...state,
                airplane: action.payload,
                loading: false,
                error: false
            }
        case AIRPLANE_GET_ERROR:
            return {
                ...state,
                error: true,
                airplane: {}
            }
        case AIRPLANE_GET_SEARCH:
            return {
                ...state,
                loading: true
            }
        case AIRPLANE_GET_SEARCH_SUCCESS:
            switch (action.payload.field) {
                case 'acronym':
                    return {
                        ...state,
                        airplane_search_acronym: action.payload,
                        loading: false,
                        error: false
                    }
                case 'serial':
                    return {
                        ...state,
                        airplane_search_serial: action.payload,
                        loading: false,
                        error: false
                    }
                default:
                    return {
                        ...state,
                        airplane_search_name: action.payload,
                        loading: false,
                        error: false
                    }
            }
            
        case AIRPLANE_GET_SEARCH_ERROR:
            switch (action.payload.field) {
                case 'acronym':
                    return {
                        ...state,
                        error: true,
                        airplane_search_acronym: {}
                    }
                case 'serial':
                    return {
                        ...state,
                        error: true,
                        airplane_search_serial: {}
                    }
                default:
                    return {
                        ...state,
                        error: true,
                        airplane_search_name: {}
                    }
            }
        case AIRPLANE_NEW:
            return {
                ...state
            }
        case AIRPLANE_NEW_SUCCESS:
            return {
                ...state,
                error: false,
                airplanes: [...state.airplanes, action.payload]
            }
        case AIRPLANE_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case AIRPLANE_EDIT:
            return {
                ...state,
            }
        case AIRPLANE_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                airplane: action.payload,
                airplanes: state.airplanes.map(airplane => airplane.id === action.payload.id ? airplane = action.payload : airplane),
            }
        case AIRPLANE_EDIT_ERROR:
            return {
                ...state,
                error: true
            }

        case AIRPLANE_MODEL_DDLIST:
            return {
                ...state,
                loading: true,
                airplane_model_ddlist: []
            }
        case AIRPLANE_MODEL_DDLIST_SUCCESS:
            return {
                ...state,
                airplane_model_ddlist: action.payload,
                loading: false,
                error: false
            }
        case AIRPLANE_MODEL_DDLIST_ERROR:
            return {
                ...state,
                airplane_model_ddlist: [],
                loading: false,
                error: true
            }
        default:
            return state;
    }
}