import {
    MATERIAL_REMOVE_RECORD_LIST,
    MATERIAL_REMOVE_RECORD_LIST_SUCCESS,
    MATERIAL_REMOVE_RECORD_LIST_ERROR,
    MATERIAL_REMOVE_RECORD_GET,
    MATERIAL_REMOVE_RECORD_GET_SUCCESS,
    MATERIAL_REMOVE_RECORD_GET_ERROR,
    MATERIAL_REMOVE_RECORD_NEW,
    MATERIAL_REMOVE_RECORD_NEW_SUCCESS,
    MATERIAL_REMOVE_RECORD_NEW_ERROR,
    MATERIAL_REMOVE_RECORD_EDIT,
    MATERIAL_REMOVE_RECORD_EDIT_SUCCESS,
    MATERIAL_REMOVE_RECORD_EDIT_ERROR,
    MATERIAL_REMOVE_RECORD_DELETE,
    MATERIAL_REMOVE_RECORD_DELETE_SUCCESS,
    MATERIAL_REMOVE_RECORD_DELETE_ERROR
} from '../types';

const initialState = {
    materialremoverecords: [],
    materialremoverecord: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case MATERIAL_REMOVE_RECORD_LIST:
            return {
                ...state,
                loading: true,
                materialremoverecord: {}
            }
        case MATERIAL_REMOVE_RECORD_LIST_SUCCESS:
            return {
                ...state,
                materialremoverecords: action.payload,
                loading: false,
                error: false,
                materialremoverecord: {}
            }
        case MATERIAL_REMOVE_RECORD_LIST_ERROR:
            return {
                ...state,
                materialremoverecords: [],
                loading: false,
                error: true,
                materialremoverecord: {}
            }
        case MATERIAL_REMOVE_RECORD_GET:
            return {
                ...state,
                loading: true
            }
        case MATERIAL_REMOVE_RECORD_GET_SUCCESS:
            return {
                ...state,
                materialremoverecord: action.payload,
                loading: false,
                error: false
            }
        case MATERIAL_REMOVE_RECORD_GET_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                materialremoverecord: {}
            }
        case MATERIAL_REMOVE_RECORD_NEW:
            return {
                ...state,
                loading: true,
            }
        case MATERIAL_REMOVE_RECORD_NEW_SUCCESS:
            return {
                ...state,
                materialremoverecords: [...state.materialremoverecords, action.payload],
                materialremoverecord: {},
                loading: false,
                error: false
            }
        case MATERIAL_REMOVE_RECORD_NEW_ERROR:
            return {
                ...state,
                materialremoverecord: {},
                loading: false,
                error: true
            }
        case MATERIAL_REMOVE_RECORD_EDIT:
            return {
                ...state,
                loading: true
            }
        case MATERIAL_REMOVE_RECORD_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                materialremoverecord: action.payload,
                materialremoverecords: state.materialremoverecords.map(materialremoverecord => ( materialremoverecord.id === action.payload.id ? action.payload : materialremoverecord ) ),
            }
        case MATERIAL_REMOVE_RECORD_EDIT_ERROR:
            return {
                ...state,
                materialremoverecord: action.payload,
                loading: false,
                error: true
            }
        case MATERIAL_REMOVE_RECORD_DELETE:
            return {
                ...state,
                loading: true
            }
        case MATERIAL_REMOVE_RECORD_DELETE_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                materialremoverecord: {},
                materialremoverecords: state.materialremoverecords.map(materialremoverecord => ( materialremoverecord.id === action.payload.id ? action.payload : materialremoverecord ) ),
            }
        case MATERIAL_REMOVE_RECORD_DELETE_ERROR:
            return {
                ...state,
                materialremoverecord: {},
                loading: false,
                error: true
            }
        default:
            return state;
    }
}
