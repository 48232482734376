import {
    COMPANY_LIST,
    COMPANY_LIST_SUCCESS,
    COMPANY_LIST_ERROR,
    COMPANY_DDLIST,
    COMPANY_DDLIST_SUCCESS,
    COMPANY_DDLIST_ERROR,
    COMPANY_GET,
    COMPANY_GET_SUCCESS,
    COMPANY_GET_ERROR,
    COMPANY_NEW,
    COMPANY_NEW_SUCCESS,
    COMPANY_NEW_ERROR,
    COMPANY_NEW_RECORD,
    COMPANY_EDIT,
    COMPANY_EDIT_SUCCESS,
    COMPANY_EDIT_ERROR,
    SET_COMPANY_SERVICEROLE_LIST,
    ADD_COMPANY_SERVICEROLE_LIST,
    REMOVE_COMPANY_SERVICEROLE_LIST,
    COMPANY_LOGISTIC_OPERATOR_DD_LIST,
} from '../types';

const initialState = {
    companies: [],
    companyddList: [],
    company: {},   
    loading: false,
    error: false,
    companyserviceroleList: [],
    companylogisticOperatorDDList: []
}

export default function(state = initialState, action){
    switch(action.type){
        case COMPANY_LIST:
            return {
                ...state,
                loading: true,
                company: {}
            }
        case COMPANY_LIST_SUCCESS:
            return {
                ...state,
                companies: action.payload,
                companyddList:[],
                loading: false,
                error: false,
                company: {}
            }
        case COMPANY_LIST_ERROR:
            return {
                ...state,
                companies: [],
                companyddList:[],
                loading: false,
                error: true,
                company: {}
            }
        case COMPANY_DDLIST:
            return {
                ...state,
                loading: true,
                //companies:[],
                company: {}
               
            }
        case COMPANY_DDLIST_SUCCESS:
            return {
                ...state,                
                companyddList:action.payload,
                //companies:action.payload,
                //companies:[],
                loading: false,
                error: false,
                //company: {}
            }
        case COMPANY_DDLIST_ERROR:
            return {
                ...state,
                companies: [],
                companyddList: [],
                loading: false,
                error: true,
                company: {}
            }
        case COMPANY_GET:
            return {
                ...state,
                loading: true
            }
        case COMPANY_GET_SUCCESS:
            return {
                ...state,
                company: action.payload,
                loading: false,
                error: false
            }
        case COMPANY_GET_ERROR:
            return {
                ...state,
                error: true,
                company: {}
            }
        case COMPANY_NEW:
            return {
                ...state,
                loading: true,
            }
        case COMPANY_NEW_SUCCESS:
            sessionStorage.setItem('error', false);
            return {
                ...state,
                companies: [...state.companies, action.payload],
                company: {},
                loading: false,
                error: false
            }
        case COMPANY_NEW_ERROR:
            sessionStorage.setItem('error', true);
            return {
                ...state,
                company: action.payload,
                loading: false,
                error: true
            }
        case COMPANY_NEW_RECORD:
            return {
                ...state,
                company: {},
                error: false
            }
        case COMPANY_EDIT:
            return {
                ...state
            }
        case COMPANY_EDIT_SUCCESS:
            sessionStorage.setItem('error', false);
            return {
                ...state,
                error: false,
                company: action.payload,
                companies: state.companies.map(company => company.id === action.payload.id ? company = action.payload : company),
            }
        case COMPANY_EDIT_ERROR:
            sessionStorage.setItem('error', true);
            return {
                ...state,
                company: action.payload,
                error: false
            }
        case SET_COMPANY_SERVICEROLE_LIST:
            return {
                ...state,
                companyserviceroleList: action.payload
            }
        case ADD_COMPANY_SERVICEROLE_LIST:
            return {
                ...state,
                companyserviceroleList: [ ...state.companyserviceroleList, action.payload ]
            }
        case REMOVE_COMPANY_SERVICEROLE_LIST:
            return {
                ...state,
                companyserviceroleList: state.companyserviceroleList.filter( comservicerole => comservicerole.id !== action.payload.id )
            }
            case COMPANY_LOGISTIC_OPERATOR_DD_LIST:
            return {
                ...state,
                companylogisticOperatorDDList: action.payload
            }
        default:
            return state;
    }
}
