import {
    ENTITYMDBYENTITY_LIST,
    ENTITYMDBYENTITY_LIST_SUCCESS,
    ENTITYMDBYENTITY_LIST_ERROR,
    ENTITYMD_LIST,
    ENTITYMD_LIST_SUCCESS,
    ENTITYMD_LIST_ERROR,
    ENTITYMD_DDLIST,
    ENTITYMD_DDLIST_SUCCESS,
    ENTITYMD_DDLIST_ERROR,
    ENTITYMD_GET,
    ENTITYMD_GET_SUCCESS,
    ENTITYMD_GET_ERROR,
    ENTITYMD_NEW,
    ENTITYMD_NEW_SUCCESS,
    ENTITYMD_NEW_ERROR,
    ENTITYMD_NEW_RECORD,
    ENTITYMD_EDIT,
    ENTITYMD_EDIT_SUCCESS,
    ENTITYMD_EDIT_ERROR,
    ENTITYMDBYENTITYCODE_DDLIST_SUCCESS
} from '../types';
import * as appConstants from '../../AppConstants';

const initialState = {
    entitymds: [],
    componentTypeList: [],
    inventoryTypeList: [],
    maintenanceTypeList: [],
    maintenanceList: [],
    paymentMethodList:[],
    statusemployeeList: [],
    warehouseclassList: [],
    warehouseTypeList: [],
    unitOfMeasureList: [],
    serviceTypeList: [],
    typeUnitMeasureList: [],
    workOrderTypeList: [],
    atacodeList: [],
    subAtacodeList: [],
    materialstateList: [],
    purchasePriorityDDList: [],
    roles: [],
    groupPaymentList:[],
    environmentConditionList:[],
    securityConditionList:[],
    materialConditionList: [],
    typeBankAccountList: [],
    componentBrandList: [],
    componentModelList: [],
    componentPositionList: [],
    entitymd: {},
    waitForInspection: [],
    paymentTimeDDList: [],
    currencyTypeDDList: [],
    warrantyTimeTypeDDList: [],
    fleetList:[],
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case ENTITYMDBYENTITY_LIST:
            return {
                ...state,
                loading: true,
                entitymd: {}
            }
        case ENTITYMDBYENTITY_LIST_SUCCESS:
            return {
                ...state,
                entitymds: action.payload,
                loading: false,
                error: false,
                entitymd: {}
            }
        case ENTITYMDBYENTITY_LIST_ERROR:
            return {
                ...state,
                entitymds: [],
                loading: false,
                error: true,
                entitymd: {}
            }
        case ENTITYMD_LIST:
            return {
                ...state,
                loading: true,
                entitymd: {}
            }
        case ENTITYMD_LIST_SUCCESS:
            return {
                ...state,
                entitymds: action.payload,
                loading: false,
                error: false,
                entitymd: {}
            }
        case ENTITYMD_LIST_ERROR:
            return {
                ...state,
                entitymds: [],
                loading: false,
                error: true,
                entitymd: {}
            }
        case ENTITYMD_DDLIST:
            return {
                ...state,
                loading: true,
                entitymd: {}
            }
        case ENTITYMD_DDLIST_SUCCESS:
            switch(action.id){
                case process.env.REACT_APP_INVENTORY_TYPE :
                    return {
                        ...state,
                        inventoryTypeList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_COMPONENT_TYPE :
                    return {
                        ...state,
                        componentTypeList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_MAINTENANCE_CONTROL :
                    return {
                        ...state,
                        maintenanceTypeList: action.payload,
                        loading: false,
                        error: false
                    }

                case process.env.REACT_APP_MAINTENANCE_TYPE :
                    return {
                        ...state,
                        maintenanceList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_PARTNER_SERVICE_TYPE :
                    return {
                        ...state,
                        serviceTypeList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_UNIT_MEASURE :
                    return {
                        ...state,
                        unitOfMeasureList: action.payload,
                        loading: false,
                        error: false
                    }

                case process.env.REACT_APP_TYPE_UNIT_MEASURE :
                    return {
                        ...state,
                        typeUnitMeasureList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_AIRPLANE_MODEL :
                    return {
                        ...state,
                        airplaneModelList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_NACIONALITY :
                    return {
                        ...state,
                        nacionalityList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_EMPLOYEE_STATUS : // Para Estatus de Empleados
                    return {
                        ...state,
                        statusemployeeList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_PAYMENT_METHOD : // Para los Metodos de Pago
                    return {
                        ...state,
                        paymentMethodList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_FLEET_GUIDE : // Fleet
                    return {
                        ...state,
                        fleetList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_WAREHOUSE_CLASS :  // Para Clase de Almacen
                    return {
                        ...state,
                        warehouseclassList: action.payload,
                        loading: false,
                        error: false
                    }

                case process.env.REACT_APP_WAREHOUSE_TYPE :  // Para Tipo de Almacenaje
                    return {
                        ...state,
                        warehouseTypeList: action.payload,
                        loading: false,
                        error: false
                    }

                case process.env.REACT_APP_WORK_ORDER_TYPE :
                    return {
                        ...state,
                        workOrderTypeList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_ATA_CODE :
                    return {
                        ...state,
                        atacodeList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_SUB_ATA_CODE :
                    return {
                        ...state,
                        subAtacodeList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_MATERIAL_STATE:
                case process.env.REACT_APP_EQUIPMENT_STATE:
                    return {
                        ...state,
                        materialstateList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_DOCUMENT_TYPE :
                    return {
                        ...state,
                        documentTypeList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_DOCUMENT_REQUIRED :
                    return {
                        ...state,
                        documentRequiredList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_SAM_ROLES :
                    return {
                        ...state,
                        rolesList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_PURCHASE_PRIORITY :
                    return {
                        ...state,
                        purchasePriorityDDList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_GROUP_PAYMENT :
                    return {
                        ...state,
                        groupPaymentList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_ENVIRONMENT_CONDITION :
                    return {
                        ...state,
                        environmentConditionList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_SECURITY_CONDITION :
                    return {
                        ...state,
                        securityConditionList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_MATERIAL_CONDITION :
                    return {
                        ...state,
                        materialConditionList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_BANK_ACCOUNT_TYPE :  // Para Tipo de Cuentas Bancarias
                    return {
                        ...state,
                        typeBankAccountList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_BRAND_COMPONENTAIRPLANE :  // Para Marcas de Componentes de Airplane
                    return {
                        ...state,
                        componentBrandList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_MODEL_COMPONENTAIRPLANE :  // Para Modelos de Componentes de Airplane
                    return {
                        ...state,
                        componentModelList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_POSITION_COMPONENTAIRPLANE :  // Para Ubicacion de Componentes en Airplane
                    return {
                        ...state,
                        componentPositionList: action.payload,
                        loading: false,
                        error: false
                    }
                case process.env.REACT_APP_ITEM_INVENTORY_WAIT_FOR_INSPECTION :
                    return {
                        ...state,
                        waitForInspection: action.payload,
                        loading: false,
                        error: false
                    };
                case process.env.REACT_APP_MAINTENANCE_AIRPLANE :
                    return {
                        ...state,
                        maintenanceTypeList: action.payload,
                        loading: false,
                        error: false
                    };

                default:
                    return {
                        ...state,
                        entitymds: action.payload,
                        loading: false,
                        error: false,
                        entity: {}
                    }
            }
        case ENTITYMDBYENTITYCODE_DDLIST_SUCCESS:
            switch (action.entitycode) {
                case appConstants.ENTITY_CODE_PAYMENT_TIME_OC:
                    return {
                        ...state,
                        paymentTimeDDList: action.payload,
                        loading: false,
                        error: false
                    };

                case appConstants.ENTITY_CODE_CURRENCY_TYPE:
                    return {
                        ...state,
                        currencyTypeDDList: action.payload,
                        loading: false,
                        error: false
                    };
                
                case appConstants.ENTITY_CODE_WARRANTY_TIME_TYPE:
                    return {
                        ...state,
                        warrantyTimeTypeDDList: action.payload,
                        loading: false,
                        error: false
                    };
            
                default:
                    return {
                        ...state,
                        entitymds: action.payload,
                        loading: false,
                        error: false,
                        entity: {}
                    }
            }
        case ENTITYMD_DDLIST_ERROR:
            return {
                ...state,
                entitymds: [],
                loading: false,
                error: true,
                entitymd: {}
            }
        case ENTITYMD_GET:
            return {
                ...state,
                entitymd: {},
                loading: true
            }
        case ENTITYMD_GET_SUCCESS:
            return {
                ...state,
                entitymd: action.payload,
                loading: false,
                error: false
            }
        case ENTITYMD_GET_ERROR:
            return {
                ...state,
                error: true,
                entitymd: {}
            }
        case ENTITYMD_NEW:
            return {
                ...state
            }
        case ENTITYMD_NEW_SUCCESS:
            return {
                ...state,
                entitymds: [...state.entitymds, action.payload],
                entitymd: {},
                error: false
            }
        case ENTITYMD_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case ENTITYMD_NEW_RECORD:
            return {
                ...state,
                entitymd: {},
                error: false
            }
        case ENTITYMD_EDIT:
            return {
                ...state
            }
        case ENTITYMD_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                entitymd: action.payload,
                entitymds: state.entitymds.map(entitymd => entitymd.id === action.payload.id ? entitymd = action.payload : entitymd),
            }
        case ENTITYMD_EDIT_ERROR:
            return {
                ...state,
                error: false
            }
        default:
            return state;
    }
}
