import {
    DOCUMENTBYPROCESS_LIST,
    DOCUMENTBYPROCESS_LIST_SUCCESS,
    DOCUMENTBYPROCESS_LIST_ERROR,
    DOCUMENTBYPROCESS_DDLIST,
    DOCUMENTBYPROCESS_DDLIST_SUCCESS,
    DOCUMENTBYPROCESS_DDLIST_ERROR,
    DOCUMENTBYPROCESS_GET,
    DOCUMENTBYPROCESS_GET_SUCCESS,
    DOCUMENTBYPROCESS_GET_ERROR,
    DOCUMENTBYPROCESS_NEW,
    DOCUMENTBYPROCESS_NEW_SUCCESS,
    DOCUMENTBYPROCESS_NEW_ERROR,
    DOCUMENTBYPROCESS_NEW_RECORD,
    DOCUMENTBYPROCESS_EDIT,
    DOCUMENTBYPROCESS_EDIT_SUCCESS,
    DOCUMENTBYPROCESS_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Departamentos
export function DocumentByProcessListAction(id, processtype_id){
    
    return (dispatch =>{
        dispatch(DocumentByProcessList());
        AxiosClient.get(`${process.env.REACT_APP_DOCUMENTBYPROCESS}/${id}/${processtype_id}`)        
            .then((response)=> {
                dispatch( DocumentByProcessListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( DocumentByProcessListError(error) );
            });
    });
}

export const DocumentByProcessList = () => ({
    type: DOCUMENTBYPROCESS_LIST
});

export const DocumentByProcessListSuccess = (records) => ({
    type: DOCUMENTBYPROCESS_LIST_SUCCESS,
    payload: records
});

export const DocumentByProcessListError = (error) => ({
    type: DOCUMENTBYPROCESS_LIST_ERROR,
    payload: error
});

// Función Principal para obtener los Departamentos en forma de DropDown
export function DocumentByProcessDDListAction(){
    return (dispatch =>{
        dispatch(DocumentByProcessDDList());
        AxiosClient.get(`${process.env.REACT_APP_DOCUMENTBYPROCESS}/ddlist`)
            .then((response)=> {
                dispatch( DocumentByProcessDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( DocumentByProcessDDListError(error) );
            });
    });
}
export const DocumentByProcessDDList = () => ({
    type: DOCUMENTBYPROCESS_DDLIST
});

export const DocumentByProcessDDListSuccess = (records) => ({
    type: DOCUMENTBYPROCESS_DDLIST_SUCCESS,
    payload: records        
});

export const DocumentByProcessDDListError = (error) => ({
    type: DOCUMENTBYPROCESS_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener un Departamento
export function DocumentByProcessGetAction(id){
    return (dispatch =>{
        dispatch(DocumentByProcessGet());
        AxiosClient.get(`${process.env.REACT_APP_DOCUMENTBYPROCESS}/${id}`)
            .then((response)=> {
                dispatch( DocumentByProcessGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( DocumentByProcessEditError(error) );
            });
    });
}

export const DocumentByProcessGet = () => ({
    type: DOCUMENTBYPROCESS_GET
});

export const DocumentByProcessGetSuccess = (record) => ({
    type: DOCUMENTBYPROCESS_GET_SUCCESS,
    payload: record
});

export const DocumentByProcessMdGetError = (error) => ({
    type: DOCUMENTBYPROCESS_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Departamento cuando se va crear un registro nuevo
export function DocumentByProcessNewAction(){
    return (dispatch =>{
            dispatch(DocumentByProcessNewRecord());
    });
}

export const DocumentByProcessNewRecord = () => ({
    type: DOCUMENTBYPROCESS_NEW_RECORD
});

// Función Principal para crear y editar registros de Departamentos
export function DocumentByProcessSaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(DocumentByProcessEdit());
        } else{
            dispatch(DocumentByProcessNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_DOCUMENTBYPROCESS}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( DocumentByProcessNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( DocumentByProcessNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_DOCUMENTBYPROCESS}/${record.id}`, record)
                .then((response)=> {
                    dispatch( DocumentByProcessEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( DocumentByProcessEditError(true) );
                });
        }
    });
}

export const DocumentByProcessNew = () => ({
    type: DOCUMENTBYPROCESS_NEW
});

export const DocumentByProcessNewSuccess = (record) => ({
    type: DOCUMENTBYPROCESS_NEW_SUCCESS,
    payload: record
});

export const DocumentByProcessNewError = (error) => ({
    type: DOCUMENTBYPROCESS_NEW_ERROR,
    payload: error
});

export const DocumentByProcessEdit = () => ({
    type: DOCUMENTBYPROCESS_EDIT
});

export const DocumentByProcessEditSuccess = (record) => ({
    type: DOCUMENTBYPROCESS_EDIT_SUCCESS,
    payload: record
});

export const DocumentByProcessEditError = (error) => ({
    type: DOCUMENTBYPROCESS_EDIT_ERROR,
    payload: error
});