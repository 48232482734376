import {
    CORRELATIVE_DOCUMENT_LIST,
    CORRELATIVE_DOCUMENT_LIST_SUCCESS,
    CORRELATIVE_DOCUMENT_LIST_ERROR,
    CORRELATIVE_DOCUMENT_DDLIST,
    CORRELATIVE_DOCUMENT_DDLIST_SUCCESS,
    CORRELATIVE_DOCUMENT_DDLIST_ERROR,
    CORRELATIVE_DOCUMENT_GET,
    CORRELATIVE_DOCUMENT_GET_SUCCESS,
    CORRELATIVE_DOCUMENT_GET_ERROR,
    CORRELATIVE_DOCUMENT_NEW,
    CORRELATIVE_DOCUMENT_NEW_SUCCESS,
    CORRELATIVE_DOCUMENT_NEW_ERROR,
    CORRELATIVE_DOCUMENT_NEW_RECORD,
    CORRELATIVE_DOCUMENT_EDIT,
    CORRELATIVE_DOCUMENT_EDIT_SUCCESS,
    CORRELATIVE_DOCUMENT_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Departamentos
export function CorrelativeDocumentListAction(){
    
    return (dispatch =>{
        dispatch(CorrelativeDocumentList());
        AxiosClient.get(`${process.env.REACT_APP_CORRELATIVE_DOCUMENT}/all`)
            .then((response)=> {
                dispatch( CorrelativeDocumentListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( CorrelativeDocumentListError(error) );
            });
    });
}

export const CorrelativeDocumentList = () => ({
    type: CORRELATIVE_DOCUMENT_LIST
});

export const CorrelativeDocumentListSuccess = (records) => ({
    type: CORRELATIVE_DOCUMENT_LIST_SUCCESS,
    payload: records
});

export const CorrelativeDocumentListError = (error) => ({
    type: CORRELATIVE_DOCUMENT_LIST_ERROR,
    payload: error
});

// Función Principal para obtener los Departamentos en forma de DropDown
export function CorrelativeDocumentDDListAction(){
    return (dispatch =>{
        dispatch(CorrelativeDocumentDDList());
        AxiosClient.get(`${process.env.REACT_APP_CORRELATIVE_DOCUMENT}/ddlist`)
            .then((response)=> {
                dispatch( CorrelativeDocumentDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( CorrelativeDocumentDDListError(error) );
            });
    });
}
export const CorrelativeDocumentDDList = () => ({
    type: CORRELATIVE_DOCUMENT_DDLIST
});

export const CorrelativeDocumentDDListSuccess = (records) => ({
    type: CORRELATIVE_DOCUMENT_DDLIST_SUCCESS,
    payload: records    
});

export const CorrelativeDocumentDDListError = (error) => ({
    type: CORRELATIVE_DOCUMENT_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener un Departamento
export const CorrelativeDocumentGetAction = (id, year) => async (dispatch) =>{
        dispatch(CorrelativeDocumentGet());
        await AxiosClient.get(`${process.env.REACT_APP_CORRELATIVE_DOCUMENT}/${id}/${year}`)
            .then((response)=> {
                dispatch( CorrelativeDocumentGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( CorrelativeDocumentEditError(error) );
            });
}

// Función Principal para obtener un Departamento
export const CorrelativeDocumentCodeGetAction = (code, year) => async (dispatch) =>{
    dispatch(CorrelativeDocumentGet());
    let response = await AxiosClient.get(`${process.env.REACT_APP_CORRELATIVE_DOCUMENT}/code/${code}/${year}`)
        .catch(error=> {
            dispatch( CorrelativeDocumentEditError(error) );
            return {
                ok: false,
                error: error
            };
        });

        if( (response ? response.data : false) ) {
            if(response.data.ok) {
                dispatch( CorrelativeDocumentGetSuccess(response.data.result));
                return {
                    ok: true,
                    result: response.data.result
                };
            }else {
                dispatch( CorrelativeDocumentEditError('error') );
                return {
                    ok: false,
                    error: 'error'
                };
            }
        }else {
            dispatch( CorrelativeDocumentEditError('error') );
            return {
                ok: false,
                error: 'error'
            };
        }
};

export const CorrelativeDocumentGet = () => ({
    type: CORRELATIVE_DOCUMENT_GET
});

export const CorrelativeDocumentGetSuccess = (record) => ({
    type: CORRELATIVE_DOCUMENT_GET_SUCCESS,
    payload: record
});

export const CorrelativeDocumentMdGetError = (error) => ({
    type: CORRELATIVE_DOCUMENT_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Departamento cuando se va crear un registro nuevo
export function CorrelativeDocumentNewAction(){
    return (dispatch =>{
            dispatch(CorrelativeDocumentNewRecord());
    });
}

export const CorrelativeDocumentNewRecord = () => ({
    type: CORRELATIVE_DOCUMENT_NEW_RECORD
});

// Función Principal para crear y editar registros de Departamentos
export function CorrelativeDocumentSaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(CorrelativeDocumentEdit());
        } else{
            dispatch(CorrelativeDocumentNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_CORRELATIVE_DOCUMENT}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( CorrelativeDocumentNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( CorrelativeDocumentNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_CORRELATIVE_DOCUMENT}/${record.id}`, record)
                .then((response)=> {
                    dispatch( CorrelativeDocumentEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( CorrelativeDocumentEditError(true) );
                });
        }
    });
}

export const CorrelativeDocumentNew = () => ({
    type: CORRELATIVE_DOCUMENT_NEW
});

export const CorrelativeDocumentNewSuccess = (record) => ({
    type: CORRELATIVE_DOCUMENT_NEW_SUCCESS,
    payload: record
});

export const CorrelativeDocumentNewError = (error) => ({
    type: CORRELATIVE_DOCUMENT_NEW_ERROR,
    payload: error
});

export const CorrelativeDocumentEdit = () => ({
    type: CORRELATIVE_DOCUMENT_EDIT
});

export const CorrelativeDocumentEditSuccess = (record) => ({
    type: CORRELATIVE_DOCUMENT_EDIT_SUCCESS,
    payload: record
});

export const CorrelativeDocumentEditError = (error) => ({
    type: CORRELATIVE_DOCUMENT_EDIT_ERROR,
    payload: error
});