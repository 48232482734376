import {
    AIRPLANE_MODEL_MANAGEMENT_LIST,
    AIRPLANE_MODEL_MANAGEMENT_LIST_SUCCESS ,
    AIRPLANE_MODEL_MANAGEMENT_LIST_ERROR ,
    AIRPLANE_MODEL_MANAGEMENT_GET ,
    AIRPLANE_MODEL_MANAGEMENT_GET_SUCCESS ,
    AIRPLANE_MODEL_MANAGEMENT_GET_ERROR ,
    AIRPLANE_MODEL_MANAGEMENT_NEW ,
    AIRPLANE_MODEL_MANAGEMENT_NEW_SUCCESS ,
    AIRPLANE_MODEL_MANAGEMENT_NEW_ERROR,
    AIRPLANE_MODEL_MANAGEMENT_EDIT,
    AIRPLANE_MODEL_MANAGEMENT_EDIT_SUCCESS ,
    AIRPLANE_MODEL_MANAGEMENT_EDIT_ERROR ,
    AIRPLANE_MODEL_MANAGEMENT_DELETE ,
    AIRPLANE_MODEL_MANAGEMENT_DELETE_SUCCESS ,
    AIRPLANE_MODEL_MANAGEMENT_DELETE_ERROR 
} from "../types";

const initialState = {
    airplanemodelmanagementlist: [], 
    airplanemodelmanagement: {},
    loading: false,
    error: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case AIRPLANE_MODEL_MANAGEMENT_LIST:
            return {
                ...state,
                loading: true,
                airplanemodelmanagementlist: [],
            };

        case AIRPLANE_MODEL_MANAGEMENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                airplanemodelmanagementlist: action.payload,
                airplanemodelmanagement: {}
            };

        case AIRPLANE_MODEL_MANAGEMENT_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                airplanemodelmanagementlist: [],
                airplanemodelmanagement: {}
            };

        case AIRPLANE_MODEL_MANAGEMENT_GET:
            return {
                ...state,
                loading: true,
                error: false,
                airplanemodelmanagement: {}
            }

        case AIRPLANE_MODEL_MANAGEMENT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                airplanemodelmanagement: action.payload
            };

        case AIRPLANE_MODEL_MANAGEMENT_GET_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                airplanemodelmanagementlist: [],
                airplanemodelmanagement: {}
            };

        case AIRPLANE_MODEL_MANAGEMENT_NEW:
            return {
                ...state,
                loading: true,
                error: false
            };

        case AIRPLANE_MODEL_MANAGEMENT_NEW_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                airplanemodelmanagementlist: [...state.airplanemodelmanagementlist, action.payload],
                airplanemodelmanagement: action.payload
            };

        case AIRPLANE_MODEL_MANAGEMENT_NEW_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                airplanemodelmanagementlist: [],
                airplanemodelmanagement: {}
            }

        case AIRPLANE_MODEL_MANAGEMENT_EDIT:
            return {
                ...state,
                loading: true,
                error: false
            };

        case AIRPLANE_MODEL_MANAGEMENT_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                airplanemodelmanagement: action.payload,
                airplanemodelmanagementlist: state.airplanemodelmanagementlist.map(airplanemodelmanagement => (airplanemodelmanagement.id === action.payload.id ? action.payload : airplanemodelmanagement))
            };

        case AIRPLANE_MODEL_MANAGEMENT_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                airplanemodelmanagementlist: [],
                airplanemodelmanagement: {}
            };

        case AIRPLANE_MODEL_MANAGEMENT_DELETE:
            return {
                ...state,
                loading: true,
                error: false
            }

        case AIRPLANE_MODEL_MANAGEMENT_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                airplanemodelmanagementlist: state.airplanemodelmanagementlist.filter(airplanemodelmanagement => airplanemodelmanagement.id !== action.payload)
            }

        case AIRPLANE_MODEL_MANAGEMENT_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                error: true
            }

        default:
            return state;
    }
};