import { lazy } from 'react'

const MaterialInstallation = lazy(() => import('./MaterialInstallation/MaterialInstallation'))
const EquipmentRequest = lazy(() => import('./EquipmentRequest/EquipmentRequest'))
const ItemInventoryAdjustment = lazy(() => import('./ItemInventoryAdjustment/ItemInventoryAdjustment'))
const ItemLocation = lazy(() => import('./ItemLocation/ItemLocation'))
const MaterialBetweenWarehouse = lazy(() => import('./MaterialBetweenWarehouse/MaterialBetweenWarehouse'))
const MaterialLoan = lazy(() => import('./MaterialLoan/MaterialLoan'))
const MaterialManagement = lazy(() => import('./MaterialManagement/MaterialManagement'))
const MaterialReception = lazy(() => import('./MaterialReception/MaterialReception'))
const MaterialRequest = lazy(() => import('./MaterialRequest/MaterialRequest'))
const MaterialReturn = lazy(() => import('./MaterialReturn/MaterialReturn'))
const Warehouse = lazy(() => import('./Warehouse/Warehouse'))
const ClaimsManagement = lazy(() => import('./Warranty/WarrantyManagement'))
const ItemInventory = lazy(() => import('./Inventory/ItemInventory'))
const ItemInventoryForToLocate = lazy(() => import('./Inventory/ItemInventoryForToLocate'))
const ChangeInventory = lazy(() => import('./Inventory/ChangeInventory'))
const TransferInventory = lazy(() => import('./Inventory/TransferInventory'))
const AdjustInventory = lazy(() => import('./Inventory/AdjustInventory'))
const PreIncoming = lazy(() => import('./Incoming/PreIncoming'))


const routes = [
    { component: MaterialInstallation, route: '/materialinstallation' },
    { component: Warehouse, route: '/warehouse' },
    { component: PreIncoming, route: '/preincoming' },
    { component: ItemInventoryForToLocate, route: '/incoming' },
    { component: MaterialRequest, route: '/materialrequest' },
    { component: MaterialReturn, route: '/materialreturn' },
    { component: ItemInventory, route: '/iteminventory' },
    { component: ChangeInventory, route: '/changeinventory' },
    { component: TransferInventory, route: '/transferinventory' },
    { component: AdjustInventory, route: '/adjustinventory' },
    { component: ItemLocation, route: '/itemlocation' },
    { component: MaterialManagement, route: '/materialregister' },
    { component: MaterialReception, route: '/materialreception' },
    { component: ClaimsManagement, route: '/claimsmanagement' },
    { component: MaterialLoan, route: '/materialloan' },
    { component: ItemInventoryAdjustment, route: '/inventoryadjustment' },
    { component: MaterialBetweenWarehouse, route: '/materialbetweenwarehouse' },
    { component: EquipmentRequest, route: '/equipmentrequest' }
]

export default routes