import { BaseService } from './BaseService';

const service = new BaseService('componentassembleditem');

export default {
    findAll() {
        return service.get();
    },
    findById(id) {
        return service.get(id);
    },
    findByParentId(parentId) {
        return service.get(`parent/${parentId}`);
    },
    saveComponents(data = {}) {
        return service.post('save/rubbersAndWheels', data);
    },
};
