import {
    INTERVAL_LIST,
    INTERVAL_LIST_SUCCESS,
    INTERVAL_LIST_ERROR,
    INTERVAL_DDLIST,
    INTERVAL_DDLIST_SUCCESS,
    INTERVAL_DDLIST_ERROR,
    INTERVAL_GET,
    INTERVAL_GET_SUCCESS,
    INTERVAL_GET_ERROR,
    INTERVAL_NEW,
    INTERVAL_NEW_SUCCESS,
    INTERVAL_NEW_ERROR,
    INTERVAL_NEW_RECORD,
    INTERVAL_EDIT,
    INTERVAL_EDIT_SUCCESS,
    INTERVAL_EDIT_ERROR
} from '../types';

const initialState = {
    intervals: [],
    intervalddList: [],
    interval: {},   
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case INTERVAL_LIST:
            return {
                ...state,
                loading: true,
                interval: {}
            }
        case INTERVAL_LIST_SUCCESS:
            return {
                ...state,
                intervals: action.payload,
                intervalddList:[],
                loading: false,
                error: false,
                interval: {}
            }
        case INTERVAL_LIST_ERROR:
            return {
                ...state,
                intervals: [],
                intervalddList:[],
                loading: false,
                error: true,
                interval: {}
            }
        case INTERVAL_DDLIST:
            return {
                ...state,
                loading: true,
                //companies:[],
                interval: {}
               
            }
        case INTERVAL_DDLIST_SUCCESS:
            return {
                ...state,                
                intervalddList:action.payload,
                //companies:action.payload,
                //companies:[],
                loading: false,
                error: false,
                //company: {}
            }
        case INTERVAL_DDLIST_ERROR:
            return {
                ...state,
                intervals: [],
                intervalddList: [],
                loading: false,
                error: true,
                interval: {}
            }
        case INTERVAL_GET:
            return {
                ...state,
                loading: true
            }
        case INTERVAL_GET_SUCCESS:
            return {
                ...state,
                interval: action.payload,
                loading: false,
                error: false
            }
        case INTERVAL_GET_ERROR:
            return {
                ...state,
                error: true,
                interval: {}
            }
        case INTERVAL_NEW:
            return {
                ...state,
                loading: true,
            }
        case INTERVAL_NEW_SUCCESS:
            sessionStorage.setItem('error', false);
            return {
                ...state,
                intervals: [...state.intervals, action.payload],
                interval: {},
                loading: false,
                error: false
            }
        case INTERVAL_NEW_ERROR:
            sessionStorage.setItem('error', true);
            return {
                ...state,
                intervaly: action.payload,
                loading: false,
                error: true
            }
        case INTERVAL_NEW_RECORD:
            return {
                ...state,
                interval: {},
                error: false
            }
        case INTERVAL_EDIT:
            return {
                ...state
            }
        case INTERVAL_EDIT_SUCCESS:
            sessionStorage.setItem('error', false);
            return {
                ...state,
                error: false,
                interval: action.payload,
                intervals: state.intervals.map(interval => interval.id === action.payload.id ? interval = action.payload : interval),
            }
        case INTERVAL_EDIT_ERROR:
            sessionStorage.setItem('error', true);
            return {
                ...state,
                interval: action.payload,
                error: false
            }
        default:
            return state;
    }
}
