import {
    USER_ALL_LIST,
    USER_ALL_LIST_SUCCESS,
    USER_ALL_LIST_ERROR,
    USER_LIST,
    USER_LIST_SUCCESS,
    USER_LIST_ERROR,
    USER_DDLIST,
    USER_DDLIST_SUCCESS,
    USER_DDLIST_ERROR,
    USER_DDLIST_FILTER,
    USER_DDLIST_FILTER_SUCCESS,
    USER_DDLIST_FILTER_ERROR,
    USER_GET,
    USER_GET_SUCCESS,
    USER_GET_ERROR,
    USER_NEW,
    USER_NEW_SUCCESS,
    USER_NEW_ERROR,
    USER_NEW_RECORD,
    USER_EDIT,
    USER_EDIT_SUCCESS,
    USER_EDIT_ERROR,
    USER_PASSWORD_EDIT,
    USER_PASSWORD_EDIT_SUCCESS,
    USER_PASSWORD_EDIT_ERROR,
    USER_VALID_PASSWORD,
    USER_VALID_PASSWORD_SUCCESS,
    USER_VALID_PASSWORD_ERROR,
    USER_DDLIST_MATERIALDISPATCH,
    USER_DDLIST_MATERIALDISPATCH_SUCCESS,
    USER_DDLIST_MATERIALDISPATCH_ERROR,
    USER_DDLIST_TMA,
    USER_DDLIST_TMA_SUCCESS,
    USER_DDLIST_TMA_ERROR
} from '../types';

import AuthToken from '../../config/AuthorizationToken';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();


// Función Principal para obtener lista de User
export function UserListAllAction(){    
    return (dispatch =>{
        dispatch(UserListAll());
        AxiosClient.get(`${process.env.REACT_APP_USER}/listall`)
            .then((response)=> {
                dispatch( UserListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( UserListError(error) );
            });
    });
}

export const UserListAll = () => ({
    type: USER_ALL_LIST
});

export const UserListAllSuccess = (records) => ({
    type: USER_ALL_LIST_SUCCESS,
    payload: records
});

export const UserListAllError = (error) => ({
    type: USER_ALL_LIST_ERROR,
    payload: error
});

// Función Principal para obtener lista de User
export function UserListAction(){    
    return (dispatch =>{
        dispatch(UserList());
        AxiosClient.get(`${process.env.REACT_APP_USER}`)
            .then((response)=> {
                dispatch( UserListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( UserListError(error) );
            });
    });
}

export const UserList = () => ({
    type: USER_LIST
});

export const UserListSuccess = (records) => ({
    type: USER_LIST_SUCCESS,
    payload: records
});

export const UserListError = (error) => ({
    type: USER_LIST_ERROR,
    payload: error
});

// Función Principal para obtener User en forma de DropDown
export function UserDDListAction(){
    return (dispatch =>{
        dispatch(UserDDList());
        AxiosClient.get(`${process.env.REACT_APP_USER}/ddlist`)
            .then((response)=> {
                dispatch( UserDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( UserDDListError(error) );
            });
    });
}

export const UserDDList = () => ({
    type: USER_DDLIST
});

export const UserDDListSuccess = (records) => ({
    type: USER_DDLIST_SUCCESS,
    payload: records});

export const UserDDListError = (error) => ({
    type: USER_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener User en forma de DropDown CON FILTRO
export function UserDDListFilterAction(charge_id){
    return (dispatch =>{
        dispatch(UserDDListFilter());
        AxiosClient.get(`${process.env.REACT_APP_USER}/ddfilterlist/${charge_id}`)
            .then((response)=> {
                dispatch( UserDDListFilterSuccess(charge_id, response.data.result));
            })
            .catch(error=> {
                dispatch( UserDDListFilterError(error) );
            });
    });
}

export const UserDDListFilter = () => ({
    type: USER_DDLIST_FILTER
});

export const UserDDListFilterSuccess = (charge_id, records) => ({
    type: USER_DDLIST_FILTER_SUCCESS,
    charge_id: charge_id,
    payload: records});

export const UserDDListFilterError = (error) => ({
    type: USER_DDLIST_FILTER_ERROR,
    payload: error
});

// Función Principal para obtener un User
export function UserGetAction(id){
    return (dispatch =>{
        dispatch(UserGet());
        AxiosClient.get(`${process.env.REACT_APP_USER}/${id}`)
            .then((response)=> {
                dispatch( UserGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( UserGetError(error) );
            });
    });
}

export const UserGet = () => ({
    type: USER_GET
});

export const UserGetSuccess = (record) => ({
    type: USER_GET_SUCCESS,
    payload: record
});

export const UserGetError = (error) => ({
    type: USER_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de User cuando se va crear un registro nuevo
export function UserNewAction(){
    return (dispatch =>{
            dispatch(UserNewRecord());
    });
}

export const UserNewRecord = () => ({
    type: USER_NEW_RECORD
});

// Función Principal para crear y editar registros de User
export function UserSaveAction(record, isNew){
    return (dispatch =>{       
        if(!isNew) {
            dispatch(UserEdit());
        } else{
            dispatch(UserNew());
        }
        if(isNew){           
            AxiosClient.post(`${process.env.REACT_APP_USER}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( UserNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( UserNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_USER}/${record.id}`, record)
                .then((response)=> {
                    dispatch( UserEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( UserEditError(true) );
                });
        }
    });
}

export const UserNew = () => ({
    type: USER_NEW
});

export const UserNewSuccess = (record) => ({
    type: USER_NEW_SUCCESS,
    payload: record
});

export const UserNewError = (error) => ({
    type: USER_NEW_ERROR,
    payload: error
});

export const UserEdit = () => ({
    type: USER_EDIT
});

export const UserEditSuccess = (record) => ({
    type: USER_EDIT_SUCCESS,
    payload: record
});

export const UserEditError = (error) => ({
    type: USER_EDIT_ERROR,
    payload: error
});



// Función Principal para actualizar el password del Usuario
export function UserUpdatePasswordAction(record){   
    return (dispatch =>{       
        AxiosClient.put(`${process.env.REACT_APP_USER}/updatepassword/${record.id}`, record)
            .then((response)=> {
                dispatch( UserPasswordEditSuccess(record));
            })
            .catch(error=> {
                dispatch( UserPasswordEditError(true) );
            });
    });
}

export const UserPasswordEdit = () => ({
    type:  USER_PASSWORD_EDIT
});

export const UserPasswordEditSuccess = (record) => ({
    type: USER_PASSWORD_EDIT_SUCCESS,
    payload: record
});

export const UserPasswordEditError = (error) => ({
    type: USER_PASSWORD_EDIT_ERROR,
    payload: error
});





// Función Principal para actualizar el password del Usuario
export function UserUpdatePasswordByUserNameAction(record){ 
    return (dispatch =>{       
        AxiosClient.put(`${process.env.REACT_APP_USER}/updatepasswordbyusername/${record.user_name}`, record)
            .then((response)=> {
                dispatch( UserPasswordEditSuccess(record));
            })
            .catch(error=> {
                dispatch( UserPasswordEditError(true) );
            });
    });
}

export const UserPasswordByUserNameEdit = () => ({
    type:  USER_PASSWORD_EDIT
});

export const UserPasswordByUserNameEditSuccess = (record) => ({
    type: USER_PASSWORD_EDIT_SUCCESS,
    payload: record
});

export const UserPasswordByUserNameEditError = (error) => ({
    type: USER_PASSWORD_EDIT_ERROR,
    payload: error
});



// Función Principal para validar el usuario y password
export const UserValidPasswordAction = (user, history)=>{
    return (dispatch =>{
        dispatch(UserValidPassword());
        AxiosClient.post(`${process.env.REACT_APP_LOGIN}/validateuser`, user)
            .then((response)=> {
                const userDecode = jwtDecode(response.data.result.token);
                if(userDecode.uservalid==="YES"){
                    //console.log('userDecoded: ', userDecode);
                    sessionStorage.setItem(process.env.REACT_APP_IS_AUTHENTICATED, true);
                    //sessionStorage.setItem(process.env.REACT_APP_VAR_USER, userDecode);
                    sessionStorage.setItem(process.env.REACT_APP_VAR_TOKEN, response.data.result.token);
                    sessionStorage.setItem(process.env.REACT_APP_VAR_USER_VALID, userDecode.uservalid);

                    const token = sessionStorage.getItem(process.env.REACT_APP_VAR_TOKEN);

                    // Autentica el token
                    if(token) AuthToken(token);

                    console.log('token');

                    //history.push('/home');

                    // if(Cookies.get('sam')) Cookies.remove('sam');

                    // var in30Minutes = 365;//1/48;
                    // Cookies.set('session', 'sam', {
                    //      expires: in30Minutes
                    // });
                    dispatch( UserValidPasswordSuccess(userDecode));
                }else{
                    dispatch( UserValidPasswordError(response.data.result));
                }
            })
            .catch(error=> {
                dispatch( UserValidPasswordError(error) );
            });
    });
}

export const UserValidPassword = () => ({
    type: USER_VALID_PASSWORD
});

export const UserValidPasswordSuccess = (record) => ({
    type: USER_VALID_PASSWORD_SUCCESS,
    payload: record
});

export const UserValidPasswordError = (error) => ({
    type: USER_VALID_PASSWORD_ERROR,
    payload: error
});

export function UserDDListMaterialDispatchAction( chargeCodes ){    
    return (dispatch =>{
        dispatch(UserDDListMaterialRequest());
        AxiosClient.post(`${process.env.REACT_APP_USER}/ddfilterlist/charge_codes`, {chargeCodes})
            .then((response)=> {
                dispatch( UserDDListMaterialRequestSuccess(response.data.result));
            })
            .catch(error=> {
                console.log(error)
                dispatch( UserDDListMaterialRequestError(error) );
            });
    });
}

export const UserDDListMaterialRequest = () => ({
    type: USER_DDLIST_MATERIALDISPATCH
});

export const UserDDListMaterialRequestSuccess = (records) => ({
    type: USER_DDLIST_MATERIALDISPATCH_SUCCESS,
    payload: records
});

export const UserDDListMaterialRequestError = (error) => ({
    type: USER_DDLIST_MATERIALDISPATCH_ERROR,
    payload: error
});

export function UserDDListTmaAction( chargeCodes ){    
    return (dispatch =>{
        dispatch(UserDDListTma());
        AxiosClient.post(`${process.env.REACT_APP_USER}/ddfilterlist/charge_codes`, {chargeCodes})
            .then((response)=> {
                dispatch( UserDDListTmaSuccess(response.data.result));
            })
            .catch(error=> {
                console.log(error)
                dispatch( UserDDListTmaError(error) );
            });
    });
}

export const UserDDListTma = () => ({
    type: USER_DDLIST_TMA
});

export const UserDDListTmaSuccess = (records) => ({
    type: USER_DDLIST_TMA_SUCCESS,
    payload: records
});

export const UserDDListTmaError = (error) => ({
    type: USER_DDLIST_TMA_ERROR,
    payload: error
});

