import {
    SUPPLIEREVALUATIONPARAM_LIST,
    SUPPLIEREVALUATIONPARAM_LIST_SUCCESS,
    SUPPLIEREVALUATIONPARAM_LIST_ERROR,
    SUPPLIEREVALUATIONPARAM_DDLIST,
    SUPPLIEREVALUATIONPARAM_DDLIST_SUCCESS,
    SUPPLIEREVALUATIONPARAM_DDLIST_ERROR,
    SUPPLIEREVALUATIONPARAM_GET,
    SUPPLIEREVALUATIONPARAM_GET_SUCCESS,
    SUPPLIEREVALUATIONPARAM_GET_ERROR,
    SUPPLIEREVALUATIONPARAM_NEW,
    SUPPLIEREVALUATIONPARAM_NEW_SUCCESS,
    SUPPLIEREVALUATIONPARAM_NEW_ERROR,
    SUPPLIEREVALUATIONPARAM_NEW_RECORD,
    SUPPLIEREVALUATIONPARAM_EDIT,
    SUPPLIEREVALUATIONPARAM_EDIT_SUCCESS,
    SUPPLIEREVALUATIONPARAM_EDIT_ERROR
} from '../types';

const initialState = {
    supplierevaluationparams: [],
    supplierevaluationparam: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case SUPPLIEREVALUATIONPARAM_LIST:
            return {
                ...state,
                loading: true,
                supplierevaluationparam: {}
            }
        case SUPPLIEREVALUATIONPARAM_LIST_SUCCESS:
            return {
                ...state,
                supplierevaluationparams: action.payload,
                loading: false,
                error: false,
                supplierevaluationparam: {}
            }
        case SUPPLIEREVALUATIONPARAM_LIST_ERROR:
            return {
                ...state,
                supplierevaluationparams: [],
                loading: false,
                error: true,
                supplierevaluationparam: {}
            }
        case SUPPLIEREVALUATIONPARAM_DDLIST:
            return {
                ...state,
                loading: true,
                supplierevaluationparam: {}
            }
        case SUPPLIEREVALUATIONPARAM_DDLIST_SUCCESS:
            return {
                ...state,
                supplierevaluationparams: action.payload,
                loading: false,
                error: false,
                supplierevaluationparam: {}
            }
        case SUPPLIEREVALUATIONPARAM_DDLIST_ERROR:
            return {
                ...state,
                supplierevaluationparams: [],
                loading: false,
                error: true,
                supplierevaluationparam: {}
            }
        case SUPPLIEREVALUATIONPARAM_GET:
            return {
                ...state,
                loading: true
            }
        case SUPPLIEREVALUATIONPARAM_GET_SUCCESS:
            return {
                ...state,
                supplierevaluationparam: action.payload,
                loading: false,
                error: false
            }
        case SUPPLIEREVALUATIONPARAM_GET_ERROR:
            return {
                ...state,
                error: true,
                supplierevaluationparam: {}
            }
        case  SUPPLIEREVALUATIONPARAM_NEW:
            return {
                ...state
            }
        case SUPPLIEREVALUATIONPARAM_NEW_SUCCESS:
            return {
                ...state,
                supplierevaluationparams: [...state.supplierevaluationparams, action.payload],
                supplierevaluationparam: {},
                error: false
            }
        case SUPPLIEREVALUATIONPARAM_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case SUPPLIEREVALUATIONPARAM_NEW_RECORD:
            return {
                ...state,
                supplierevaluationparam: {},
                error: false
            }
        case SUPPLIEREVALUATIONPARAM_EDIT:
            return {
                ...state
            }
        case SUPPLIEREVALUATIONPARAM_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                supplierevaluationparam: action.payload,
                supplierevaluationparams: state.supplierevaluationparams.map(supplierevaluationparam => supplierevaluationparam.id === action.payload.id ? supplierevaluationparam = action.payload : supplierevaluationparam),
            }
        case SUPPLIEREVALUATIONPARAM_EDIT_ERROR:
            return {
                ...state,
                error: false
            }
        default:
            return state;
    }
}
