import {
    MANUFACTURER_LIST,
    MANUFACTURER_LIST_SUCCESS,
    MANUFACTURER_LIST_ERROR,
    MANUFACTURER_DDLIST,
    MANUFACTURER_DDLIST_SUCCESS,
    MANUFACTURER_DDLIST_ERROR,
    MANUFACTURER_NEW,
    // MANUFACTURER_NEW_SUCCESS,
    // MANUFACTURER_NEW_ERROR,
    MANUFACTURER_EDIT,
    // MANUFACTURER_EDIT_SUCCESS,
    // MANUFACTURER_EDIT_ERROR
} from '../types';

const initialState = {
    manufacturers: [],
    manufacturer: {},
    manufacturerList: [],
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case MANUFACTURER_LIST:
            return {
                ...state,
                loading: true,
                manufacturer: {}
            }
        case MANUFACTURER_LIST_SUCCESS:
            return {
                ...state,
                manufacturers: action.payload,
                loading: false,
                error: false,
                manufacturer: {}
            }
        case MANUFACTURER_LIST_ERROR:
            return {
                ...state,
                manufacturers: [],
                loading: false,
                error: true,
                manufacturer: {}
            }
        case MANUFACTURER_DDLIST:
            return {
                ...state,
                loading: true,
                manufacturer: {}
            }
        case MANUFACTURER_DDLIST_SUCCESS:
            return {
                ...state,
                manufacturerList: action.payload,
                loading: false,
                error: false,
                manufacturer: {}
            }
        case MANUFACTURER_DDLIST_ERROR:
            return {
                ...state,
                manufacturerList: [],
                loading: false,
                error: true,
                manufacturer: {}
            }
        case MANUFACTURER_NEW:
            return {
                ...state
            }
        case MANUFACTURER_EDIT:
            return {
                ...state
            }
        default:
            return state;
    }
}
