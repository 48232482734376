import {
    CORRELATIVE_DOCUMENT_LIST,
    CORRELATIVE_DOCUMENT_LIST_SUCCESS,
    CORRELATIVE_DOCUMENT_LIST_ERROR,
    CORRELATIVE_DOCUMENT_DDLIST,
    CORRELATIVE_DOCUMENT_DDLIST_SUCCESS,
    CORRELATIVE_DOCUMENT_DDLIST_ERROR,
    CORRELATIVE_DOCUMENT_GET,
    CORRELATIVE_DOCUMENT_GET_SUCCESS,
    CORRELATIVE_DOCUMENT_GET_ERROR,
    CORRELATIVE_DOCUMENT_NEW,
    CORRELATIVE_DOCUMENT_NEW_SUCCESS,
    CORRELATIVE_DOCUMENT_NEW_ERROR,
    CORRELATIVE_DOCUMENT_NEW_RECORD,
    CORRELATIVE_DOCUMENT_EDIT,
    CORRELATIVE_DOCUMENT_EDIT_SUCCESS,
    CORRELATIVE_DOCUMENT_EDIT_ERROR
} from '../types';

const initialState = {
    correlativedocuments: [], 
    correlativedocument:{},   
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case CORRELATIVE_DOCUMENT_LIST:
            return {
                ...state,
                loading: true,
                correlativedocument: {}
            }
        case CORRELATIVE_DOCUMENT_LIST_SUCCESS:
            return {
                ...state,
                correlativedocuments: action.payload,
                loading: false,
                error: false,
                correlativedocument: {}
            }
        case CORRELATIVE_DOCUMENT_LIST_ERROR:
            return {
                ...state,
                correlativedocuments: [],
                loading: false,
                error: true,
                correlativedocument: {}
            }
        case CORRELATIVE_DOCUMENT_DDLIST:
            return {
                ...state,
                loading: true,
                correlativedocument: {}
            }
        case CORRELATIVE_DOCUMENT_DDLIST_SUCCESS:
            return {
                ...state,
                correlativedocuments: action.payload,
                loading: false,
                error: false,
                correlativedocument: {}
            }
        case CORRELATIVE_DOCUMENT_DDLIST_ERROR:
            return {
                ...state,
                correlativedocuments: [],
                loading: false,
                error: true,
                correlativedocument: {}
            }
        case CORRELATIVE_DOCUMENT_GET:
            return {
                ...state,
                loading: true
            }
        case CORRELATIVE_DOCUMENT_GET_SUCCESS:
            return {
                ...state,
                correlativedocument: action.payload,
                loading: false,
                error: false
            }
        case CORRELATIVE_DOCUMENT_GET_ERROR:
            return {
                ...state,
                error: true,
                correlativedocument: {}
            }
        case CORRELATIVE_DOCUMENT_NEW:
            return {
                ...state
            }
        case CORRELATIVE_DOCUMENT_NEW_SUCCESS:
            return {
                ...state,
                correlativedocuments: [...state.correlativedocuments, action.payload],
                correlativedocument: {},
                error: false
            }
        case CORRELATIVE_DOCUMENT_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case CORRELATIVE_DOCUMENT_NEW_RECORD:
            return {
                ...state,
                correlativedocument: {},
                error: false
            }
        case CORRELATIVE_DOCUMENT_EDIT:
            return {
                ...state
            }
        case CORRELATIVE_DOCUMENT_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                correlativedocument: action.payload,
                correlativedocuments: state.correlativedocuments.map(correlativedocument => correlativedocument.id === action.payload.id ? correlativedocument = action.payload : correlativedocument),
            }
        case CORRELATIVE_DOCUMENT_EDIT_ERROR:
            return {
                ...state,
                error: false
            }
        default:
            return state;
    }
}
