import {
    USERROLE_LIST,
    USERROLE_LIST_SUCCESS,
    USERROLE_LIST_ERROR,
    USERROLE_DDLIST,
    USERROLE_DDLIST_SUCCESS,
    USERROLE_DDLIST_ERROR,
    USERROLE_GET,
    USERROLE_GET_SUCCESS,
    USERROLE_GET_ERROR,
    USERROLE_NEW,
    USERROLE_NEW_SUCCESS,
    USERROLE_NEW_ERROR,
    USERROLE_NEW_RECORD,
    USERROLE_EDIT,
    USERROLE_EDIT_SUCCESS,
    USERROLE_EDIT_ERROR,
    USERROLES_LIST,
    USERROLES_LIST_SUCCESS,
    USERROLES_LIST_ERROR,
} from '../types';

const initialState = {
    userroles: [],
    userRoleList: [],
    userrole: {},
    userRoles: [],
    sessionRoles: [],
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case USERROLE_LIST:
            return {
                ...state,
                loading: true,
                userrol: {}
            }
        case USERROLE_LIST_SUCCESS:
            return {
                ...state,
                userroles: action.payload,
                loading: false,
                error: false,
                userrole: {}
            }
        case USERROLE_LIST_ERROR:
            return {
                ...state,
                userroles: [],
                loading: false,
                error: true,
                userrole: {}
            }
        case USERROLE_DDLIST:
            return {
                ...state,
                loading: true,
                userrole: {}
            }
        case USERROLE_DDLIST_SUCCESS:
            return {
                ...state,
                userRoleList: action.payload,
                loading: false,
                error: false,
                userrole: {}
            }
        case USERROLE_DDLIST_ERROR:
            return {
                ...state,
                userRoleList: [],
                loading: false,
                error: true,
                userrole: {}
            }
        case USERROLE_GET:
            return {
                ...state,
                loading: true
            }
        case USERROLE_GET_SUCCESS:
            return {
                ...state,
                userrole: action.payload,
                loading: false,
                error: false
            }
        case USERROLE_GET_ERROR:
            return {
                ...state,
                error: true,
                userrole: {}
            }
        case USERROLE_NEW:
            return {
                ...state
            }
        case USERROLE_NEW_SUCCESS:
            return {
                ...state,
                userroles: [...state.userroles, action.payload],
                userrole: {},
                error: false
            }
        case USERROLE_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case USERROLE_NEW_RECORD:
            return {
                ...state,
                userrole: {},
                error: false
            }
        case USERROLE_EDIT:
            return {
                ...state
            }
        case USERROLE_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                userrole: action.payload,
                userroles: state.userroles.map(userrole => userrole.id === action.payload.id ? userrole = action.payload : userrole),
            }
        case USERROLE_EDIT_ERROR:
            return {
                ...state,
                error: false
            }
        case USERROLES_LIST:
            return {
                ...state,
                sessionRoles: [],
            };
        case USERROLES_LIST_SUCCESS:
            return {
                ...state,
                sessionRoles: action.payload,
            };
        case USERROLES_LIST_ERROR:
            return {
                ...state,
                sessionRoles: [],
                error: true
            };
        default:
            return state;
    }
}
