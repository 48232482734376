import {
    ACCESSLOG_LIST,
    ACCESSLOG_LIST_SUCCESS,
    ACCESSLOG_LIST_ERROR,
    ACCESSLOG_DDLIST,
    ACCESSLOG_DDLIST_SUCCESS,
    ACCESSLOG_DDLIST_ERROR,
    ACCESSLOG_GET,
    ACCESSLOG_GET_SUCCESS,
    ACCESSLOG_GET_ERROR,
    ACCESSLOG_NEW,
    ACCESSLOG_NEW_SUCCESS,
    ACCESSLOG_NEW_ERROR,
    ACCESSLOG_NEW_RECORD,
    ACCESSLOG_EDIT,
    ACCESSLOG_EDIT_SUCCESS,
    ACCESSLOG_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de log de Acceso
export function AccessLogListAction(){
    
    return (dispatch =>{
        dispatch(AccessLogList());
        AxiosClient.get(`${process.env.REACT_APP_ACCESSLOG}`)        
            .then((response)=> {
                dispatch( AccessLogListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( AccessLogListError(error) );
            });
    });
}

export const AccessLogList = () => ({
    type: ACCESSLOG_LIST
});

export const AccessLogListSuccess = (records) => ({
    type: ACCESSLOG_LIST_SUCCESS,
    payload: records
});

export const AccessLogListError = (error) => ({
    type: ACCESSLOG_LIST_ERROR,
    payload: error
});

// // Función Principal para obtener los Departamentos en forma de DropDown
// export function AccessLogDDListAction(){
//     return (dispatch =>{
//         dispatch(AccessLogDDList());
//         AxiosClient.get(`${process.env.REACT_APP_ACCESSLOG}/ddlist`)
//             .then((response)=> {
//                 dispatch( AccessLogDDListSuccess(response.data.result));
//             })
//             .catch(error=> {
//                 dispatch( AccessLogDDListError(error) );
//             });
//     });
// }
// export const AccessLogDDList = () => ({
//     type: ACCESSLOG_DDLIST
// });

// export const AccessLogDDListSuccess = (records) => ({
//     type: ACCESSLOG_DDLIST_SUCCESS,
//     payload: records        
// });

// export const AccessLogDDListError = (error) => ({
//     type: ACCESSLOG_DDLIST_ERROR,
//     payload: error
// });

// // Función Principal para obtener un Departamento
// export function AccessLogGetAction(id){
//     return (dispatch =>{
//         dispatch(AccessLogGet());
//         AxiosClient.get(`${process.env.REACT_APP_ACCESSLOG}/${id}`)
//             .then((response)=> {
//                 dispatch( AccessLogGetSuccess(response.data.result));
//             })
//             .catch(error=> {
//                 dispatch( AccessLogEditError(error) );
//             });
//     });
// }

// export const AccessLogGet = () => ({
//     type: ACCESSLOG_GET
// });

// export const AccessLogGetSuccess = (record) => ({
//     type: ACCESSLOG_GET_SUCCESS,
//     payload: record
// });

// export const AccessLogGetError = (error) => ({
//     type: ACCESSLOG_GET_ERROR,
//     payload: error
// });

// //Función Principal para setear objeto de Departamento cuando se va crear un registro nuevo
// export function AccessLogNewAction(){
//     return (dispatch =>{
//             dispatch(AccessLogNewRecord());
//     });
// }

// export const AccessLogNewRecord = () => ({
//     type: ACCESSLOG_NEW_RECORD
// });

// // Función Principal para crear y editar registros de Departamentos
// export function AccessLogSaveAction(record, isNew){
//     return (dispatch =>{
//         if(!isNew) {
//             dispatch(AccessLogEdit());
//         } else{
//             dispatch(AccessLogNew());
//         }
//         if(isNew){
//             AxiosClient.post(`${process.env.REACT_APP_ACCESSLOG}`, record)
//                 .then((response)=> {
//                     record.id = response.data.result.id;
//                     dispatch( AccessLogNewSuccess(record));
//                 })
//                 .catch(error=> {
//                     dispatch( AccessLogNewError(error) );
//                 });
//         }else{
//             AxiosClient.put(`${process.env.REACT_APP_ACCESSLOG}/${record.id}`, record)
//                 .then((response)=> {
//                     dispatch( AccessLogEditSuccess(record));
//                 })
//                 .catch(error=> {
//                     dispatch( AccessLogEditError(true) );
//                 });
//         }
//     });
// }

// export const AccessLogNew = () => ({
//     type: ACCESSLOG_NEW
// });

// export const AccessLogNewSuccess = (record) => ({
//     type: ACCESSLOG_NEW_SUCCESS,
//     payload: record
// });

// export const AccessLogNewError = (error) => ({
//     type: ACCESSLOG_NEW_ERROR,
//     payload: error
// });

// export const AccessLogEdit = () => ({
//     type: ACCESSLOG_EDIT
// });

// export const AccessLogEditSuccess = (record) => ({
//     type: ACCESSLOG_EDIT_SUCCESS,
//     payload: record
// });

// export const AccessLogEditError = (error) => ({
//     type: ACCESSLOG_EDIT_ERROR,
//     payload: error
// });