import {
    AIRPLANEFLIGHTHISTORY_LIST,
    AIRPLANEFLIGHTHISTORY_LIST_SUCCESS,
    AIRPLANEFLIGHTHISTORY_LIST_ERROR,
    AIRPLANEFLIGHTHISTORY_DDLIST,
    AIRPLANEFLIGHTHISTORY_DDLIST_SUCCESS,
    AIRPLANEFLIGHTHISTORY_DDLIST_ERROR,
    AIRPLANEFLIGHTHISTORY_GET,
    AIRPLANEFLIGHTHISTORY_GET_SUCCESS,
    AIRPLANEFLIGHTHISTORY_GET_ERROR,   
    AIRPLANEFLIGHTHISTORY_NEW,
    AIRPLANEFLIGHTHISTORY_NEW_SUCCESS,
    AIRPLANEFLIGHTHISTORY_NEW_ERROR,
    AIRPLANEFLIGHTHISTORY_EDIT,
    AIRPLANEFLIGHTHISTORY_EDIT_SUCCESS,
    AIRPLANEFLIGHTHISTORY_EDIT_ERROR
} from '../types';

const initialState = {
    airplaneflights: [],
    airplaneflight: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case AIRPLANEFLIGHTHISTORY_LIST:
            return {
                ...state,
                loading: true,
                airplaneflight: {}                
            }
        case AIRPLANEFLIGHTHISTORY_LIST_SUCCESS:            
            return {
                ...state,
                airplaneflights: action.payload,
                loading: false,
                error: false,
                airplaneflight: {}
            }
        case AIRPLANEFLIGHTHISTORY_LIST_ERROR:
            return {
                ...state,
                airplaneflights: [],
                loading: false,
                error: true,
                airplane: {}
            }
        case AIRPLANEFLIGHTHISTORY_DDLIST:
            return {
                ...state,
                loading: true,
                airplaneflight: {}
            }
        case AIRPLANEFLIGHTHISTORY_DDLIST_SUCCESS:
            return {
                ...state,
                airplaneflights: action.payload,
                loading: false,
                error: false
            }
        case AIRPLANEFLIGHTHISTORY_DDLIST_ERROR:
            return {
                ...state,
                airplaneflights: [],
                loading: false,
                error: true,
                airplaneflight: {}
            }
        case AIRPLANEFLIGHTHISTORY_GET:
            return {
                ...state,
                loading: true
            }
        case AIRPLANEFLIGHTHISTORY_GET_SUCCESS:
            return {
                ...state,
                airplaneflight: action.payload,
                loading: false,
                error: false
            }
        case AIRPLANEFLIGHTHISTORY_GET_ERROR:
            return {
                ...state,
                error: true,
                airplaneflight: {}
            }
        case AIRPLANEFLIGHTHISTORY_NEW:
            return {
                ...state
            }
        case AIRPLANEFLIGHTHISTORY_NEW_SUCCESS:
            return {
                ...state,
                error: false,
                airplaneflights: [...state.airplaneflights, action.payload]
            }
        case AIRPLANEFLIGHTHISTORY_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case AIRPLANEFLIGHTHISTORY_EDIT:
            return {
                ...state,
            }
        case AIRPLANEFLIGHTHISTORY_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                airplaneflight: action.payload,
                airplaneflights: state.airplaneflights.map(airplaneflight => airplaneflight.id === action.payload.id ? airplaneflight = action.payload : airplaneflight),
            }
        case AIRPLANEFLIGHTHISTORY_EDIT_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state;
    }
}