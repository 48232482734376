import {
    
    WAREHOUSEZONE_LIST,
    WAREHOUSEZONE_LIST_SUCCESS,
    WAREHOUSEZONE_LIST_ERROR,
    WAREHOUSEZONE_DDLIST,
    WAREHOUSEZONE_DDLIST_SUCCESS,
    WAREHOUSEZONE_DDLIST_ERROR,
    WAREHOUSEZONE_GET,
    WAREHOUSEZONE_GET_SUCCESS,
    WAREHOUSEZONE_GET_ERROR,
    WAREHOUSEZONE_NEW,
    WAREHOUSEZONE_NEW_SUCCESS,
    WAREHOUSEZONE_NEW_ERROR,
    WAREHOUSEZONE_NEW_RECORD,
    WAREHOUSEZONE_EDIT,
    WAREHOUSEZONE_EDIT_SUCCESS,
    WAREHOUSEZONE_EDIT_ERROR,
    //WAREHOUSELOCATION_LIST,
    WAREHOUSELOCATION_LIST_SUCCESS,
    WAREHOUSELOCATION_LIST_ERROR,
    WAREHOUSELOCATION_DDLIST,
    WAREHOUSELOCATION_DDLIST_SUCCESS,
    WAREHOUSELOCATION_DDLIST_ERROR,
    WAREHOUSELOCATION_GET,
    WAREHOUSELOCATION_GET_SUCCESS,
    WAREHOUSELOCATION_GET_ERROR,
    WAREHOUSELOCATION_NEW,
    WAREHOUSELOCATION_NEW_SUCCESS,
    WAREHOUSELOCATION_NEW_ERROR,
    WAREHOUSELOCATION_NEW_RECORD,
    WAREHOUSELOCATION_EDIT,
    WAREHOUSELOCATION_EDIT_SUCCESS,
    WAREHOUSELOCATION_EDIT_ERROR,
    WAREHOUSE_ALL_ZONE_DDLIST,
    WAREHOUSE_ALL_ZONE_DDLIST_SUCCESS,
    WAREHOUSE_ALL_ZONE_DDLIST_ERROR,
    WAREHOUSE_ALL_LOCATION_DDLIST,
    WAREHOUSE_ALL_LOCATION_DDLIST_SUCCESS,
    WAREHOUSE_ALL_LOCATION_DDLIST_ERROR 
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();


// Función Principal para obtener Zonas por Almacén en forma de DropDown
export function WarehouseZoneLocationDDListAction(warehouse_id,zone_type){
    return (dispatch =>{
        dispatch(WarehouseZoneLocationDDList());
        AxiosClient.get(`${process.env.REACT_APP_WAREHOUSEZONE}/ddlocationall`)
            .then((response)=> {
                dispatch( WarehouseZoneLocationDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( WarehouseZoneLocationDDListError(error) );
            });
    });
}

export const WarehouseZoneLocationDDList = () => ({
    type: WAREHOUSELOCATION_DDLIST
});

export const WarehouseZoneLocationDDListSuccess = (records, name) => ({
    type: WAREHOUSELOCATION_DDLIST_SUCCESS,
    payload: records,
    id: name
});

export const WarehouseZoneLocationDDListError = (error) => ({
    type: WAREHOUSELOCATION_DDLIST_ERROR,
    payload: error
});

// Función Principal para Listar las Zonas
export function WarehouseZoneListAction(warehouse_id,zone_type){
    return (dispatch =>{
        dispatch(WarehouseZoneList());
        AxiosClient.get(`${process.env.REACT_APP_WAREHOUSEZONE}/warehousezonebywarehouse/${warehouse_id}/${zone_type}`)
            .then((response)=> {
                if(zone_type==='Z')
                    dispatch(WarehouseZoneListSuccess(response.data.result));
                else
                    dispatch(WarehouseLocationListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( WarehouseZoneListError(error) );
            });
    });
}

export const WarehouseZoneList = ()=>({
    type: WAREHOUSEZONE_LIST
});

export const WarehouseZoneListSuccess = warehousezoneList =>({
    type: WAREHOUSEZONE_LIST_SUCCESS,
    payload: warehousezoneList
});

export const WarehouseZoneListError = error =>({
    type: WAREHOUSEZONE_LIST_ERROR,
    payload: error
});

export const WarehouseLocationListSuccess = warehouseLocationList =>({
    type: WAREHOUSELOCATION_LIST_SUCCESS,
    payload: warehouseLocationList
});

export const WarehouseLocationListError = error =>({
    type: WAREHOUSELOCATION_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Ubicaciones por Zona en forma de DropDown
export function WarehouseZoneDDListAction(id, zone){
    return (dispatch =>{
        dispatch(WarehouseZoneDDList());
        AxiosClient.get(`${process.env.REACT_APP_WAREHOUSEZONE}/ddlistlocation/${id}/${zone}`)
            .then((response)=> {
                dispatch( WarehouseZoneDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( WarehouseZoneDDListError(error) );
            });
    });
}
export const WarehouseZoneDDList = () => ({
    type: WAREHOUSEZONE_DDLIST
});

export const WarehouseZoneDDListSuccess = (records, name) => ({
    type: WAREHOUSEZONE_DDLIST_SUCCESS,
    payload: records,
    id: name
});

export const WarehouseZoneDDListError = (error) => ({
    type: WAREHOUSEZONE_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener Ubicaciones por Zona en forma de DropDown
export function WarehouseZoneDDListFilterAction(id, zone_code){
    return (dispatch =>{
        dispatch(WarehouseZoneDDListFilter());
        AxiosClient.get(`${process.env.REACT_APP_WAREHOUSEZONE}/ddlist/${id}/${zone_code}`)
            .then((response)=> {
                dispatch( WarehouseZoneDDListFilterSuccess(response.data.result, zone_code));
            })
            .catch(error=> {
                dispatch( WarehouseZoneDDListFilterError(error) );
            });
    });
}
export const WarehouseZoneDDListFilter = () => ({
    type: WAREHOUSEZONE_DDLIST
});

export const WarehouseZoneDDListFilterSuccess = (records, zone_code) => ({
    type: WAREHOUSEZONE_DDLIST_SUCCESS,
    payload: records,
    zone_code: zone_code
});

export const WarehouseZoneDDListFilterError = (error) => ({
    type: WAREHOUSEZONE_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener una Zona
export function WarehouseZoneGetAction(id){
    return (dispatch =>{
        dispatch(WarehouseZoneGet());
        AxiosClient.get(`${process.env.REACT_APP_WAREHOUSEZONE}/${id}`)
            .then((response)=> {
                dispatch( WarehouseZoneGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( WarehouseZoneGetError(error) );
            });
    });
}

export const WarehouseZoneGet = () => ({
    type: WAREHOUSEZONE_GET
});

export const WarehouseZoneGetSuccess = (record) => ({
    type: WAREHOUSEZONE_GET_SUCCESS,
    payload: record
});

export const WarehouseZoneGetError = (error) => ({
    type: WAREHOUSEZONE_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Zona cuando se va crear un registro nuevo
export function WarehouseZoneNewAction(){
    return (dispatch =>{
            dispatch(WarehouseZoneNewRecord());
    });
}

export const WarehouseZoneNewRecord = () => ({
    type: WAREHOUSEZONE_NEW_RECORD
});

// Función Principal para crear y editar registros de Zonas y Ubicaciones de Almacén
export const WarehouseZoneSaveAction = (record,isNew) => async (dispatch) => {
    try {
        if (!isNew) {
            dispatch(WarehouseZoneEdit());
        } else {
            dispatch(WarehouseZoneNew());
        }
        if (isNew) {
           await AxiosClient.post(`${process.env.REACT_APP_WAREHOUSEZONE}`, record)
                .then((response) => {
                    dispatch(WarehouseZoneNewSuccess(response.data.result));
                })
                .catch(error => {
                    console.error(error);
                    dispatch(WarehouseZoneNewError(error));
                });
        } else {
            await AxiosClient.put(`${process.env.REACT_APP_WAREHOUSEZONE}/${record.id}`, record)
                .then((response) => {
                    dispatch(WarehouseZoneEditSuccess(record));
                })
                .catch(error => {
                    console.error(error);
                    dispatch(WarehouseZoneEditError(true));
                });
        }
    } catch (e) {
        console.error(e);
    }

};

export const WarehouseZoneNew = () => ({
    type: WAREHOUSEZONE_NEW
});

export const WarehouseZoneNewSuccess = (record) => ({    
    type: WAREHOUSEZONE_NEW_SUCCESS,
    payload: record    
});

export const WarehouseZoneNewError = (error) => ({
    type: WAREHOUSEZONE_NEW_ERROR,
    payload: error
});

export const WarehouseZoneEdit = () => ({
    type: WAREHOUSEZONE_EDIT
});

export const WarehouseZoneEditSuccess = (record) => ({
    type: WAREHOUSEZONE_EDIT_SUCCESS,
    payload: record
});

export const WarehouseZoneEditError = (error) => ({
    type: WAREHOUSEZONE_EDIT_ERROR,
    payload: error
});

// Función Principal para obtener una Ubicacion
export function WarehouseLocationGetAction(id){
    return (dispatch =>{
        dispatch(WarehouseLocationGet());
        AxiosClient.get(`${process.env.REACT_APP_WAREHOUSEZONE}/${id}`)
            .then((response)=> {
                dispatch( WarehouseLocationGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( WarehouseLocationGetError(error) );
            });
    });
}

export const WarehouseLocationGet = () => ({
    type: WAREHOUSELOCATION_GET
});

export const WarehouseLocationGetSuccess = (record) => ({
    type: WAREHOUSELOCATION_GET_SUCCESS,
    payload: record
});

export const WarehouseLocationGetError = (error) => ({
    type: WAREHOUSELOCATION_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Ubicación cuando se va crear un registro nuevo
export function WarehouseLocationNewAction(){
    return (dispatch =>{
            dispatch(WarehouseLocationNewRecord());
    });
}

export const WarehouseLocationNewRecord = () => ({
    type: WAREHOUSELOCATION_NEW_RECORD
});

// Función Principal para crear y editar registros de Ubicaciones de Almacén
export function WarehouseLocationSaveAction(record,isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(WarehouseLocationEdit());
        } else{             
            dispatch(WarehouseLocationNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_WAREHOUSEZONE}`, record)
                .then((response)=> { 
                    dispatch( WarehouseLocationNewSuccess(response.data.result));
                })
                .catch(error=> {
                    dispatch( WarehouseLocationNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_WAREHOUSEZONE}/${record.id}`, record)
                .then((response)=> {
                    dispatch( WarehouseLocationEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( WarehouseLocationEditError(true) );
                });
        }
    });
}

export const WarehouseLocationNew = () => ({
    type: WAREHOUSELOCATION_NEW
});

export const WarehouseLocationNewSuccess = (record) => ({    
    type: WAREHOUSELOCATION_NEW_SUCCESS,
    payload: record    
});

export const WarehouseLocationNewError = (error) => ({
    type: WAREHOUSELOCATION_NEW_ERROR,
    payload: error
});

export const WarehouseLocationEdit = () => ({
    type: WAREHOUSELOCATION_EDIT
});

export const WarehouseLocationEditSuccess = (record) => ({
    type: WAREHOUSELOCATION_EDIT_SUCCESS,
    payload: record
});

export const WarehouseLocationEditError = (error) => ({
    type: WAREHOUSELOCATION_EDIT_ERROR,
    payload: error
});

export function WarehouseZoneAllDDListAction(){
    return (dispatch =>{
        dispatch(WarehouseZoneAllDDList());
        AxiosClient.get(`${process.env.REACT_APP_WAREHOUSEZONE}/zone/ddlist`)
            .then((response)=> {
                dispatch( WarehouseZoneAllDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( WarehouseZoneAllDDListSuccess(error) );
            });
    });
}

export const WarehouseZoneAllDDList = () => ({
    type: WAREHOUSE_ALL_ZONE_DDLIST
});

export const WarehouseZoneAllDDListSuccess = (records, name) => ({
    type: WAREHOUSE_ALL_ZONE_DDLIST_SUCCESS,
    payload: records,
    id: name
});

export const WarehouseZoneAllDDListError = (error) => ({
    type: WAREHOUSE_ALL_ZONE_DDLIST_ERROR,
    payload: error
});

export function WarehouseLocationAllDDListAction(){
    return (dispatch =>{
        dispatch(WarehouseLocationAllDDList());
        AxiosClient.get(`${process.env.REACT_APP_WAREHOUSEZONE}/location/ddlist`)
            .then((response)=> {
                dispatch( WarehouseLocationAllDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( WarehouseLocationAllDDListError(error) );
            });
    });
}

export const WarehouseLocationAllDDList = () => ({
    type: WAREHOUSE_ALL_LOCATION_DDLIST
});

export const WarehouseLocationAllDDListSuccess = (records) =>{ 
  return  {
    type: WAREHOUSE_ALL_LOCATION_DDLIST_SUCCESS,
    payload: records
}
};

export const WarehouseLocationAllDDListError = (error) => ({
    type: WAREHOUSE_ALL_LOCATION_DDLIST_ERROR,
    payload: error
});