import {
    
    WAREHOUSEZONE_LIST,
    WAREHOUSEZONE_LIST_SUCCESS,
    WAREHOUSEZONE_LIST_ERROR,    
    WAREHOUSEZONE_DDLIST,
    WAREHOUSEZONE_DDLIST_SUCCESS,
    WAREHOUSEZONE_DDLIST_ERROR,
    WAREHOUSEZONE_GET,
    WAREHOUSEZONE_GET_SUCCESS,
    WAREHOUSEZONE_GET_ERROR,
    WAREHOUSEZONE_NEW,
    WAREHOUSEZONE_NEW_SUCCESS,
    WAREHOUSEZONE_NEW_ERROR,  
    WAREHOUSEZONE_EDIT,
    WAREHOUSEZONE_EDIT_SUCCESS,
    WAREHOUSEZONE_EDIT_ERROR,
    WAREHOUSELOCATION_DDLIST,
    WAREHOUSELOCATION_DDLIST_SUCCESS,
    WAREHOUSELOCATION_DDLIST_ERROR,
    WAREHOUSELOCATION_LIST,
    WAREHOUSELOCATION_LIST_SUCCESS,
    WAREHOUSELOCATION_LIST_ERROR,
    WAREHOUSELOCATION_GET,
    WAREHOUSELOCATION_GET_SUCCESS,
    WAREHOUSELOCATION_GET_ERROR,
    WAREHOUSELOCATION_NEW,
    WAREHOUSELOCATION_NEW_SUCCESS,
    WAREHOUSELOCATION_NEW_ERROR,  
    WAREHOUSELOCATION_EDIT,
    WAREHOUSELOCATION_EDIT_SUCCESS,
    WAREHOUSELOCATION_EDIT_ERROR,
    WAREHOUSE_ALL_ZONE_DDLIST,
    WAREHOUSE_ALL_ZONE_DDLIST_SUCCESS,
    WAREHOUSE_ALL_ZONE_DDLIST_ERROR,
    WAREHOUSE_ALL_LOCATION_DDLIST,
    WAREHOUSE_ALL_LOCATION_DDLIST_SUCCESS,
    WAREHOUSE_ALL_LOCATION_DDLIST_ERROR 
} from '../types';

const initialState = {
    warehouseZoneList: [],
    warehouseZoneDDList: [],
    warehouseZone: {},
    warehouseLocationList: [],
    warehouseLocationDDList: [],
    warehouseLocation: {},
    environment_conditions: [], 
    security_conditions: [],
    warehouseZoneAllList: [],
    warehouseLocationAllList: [],
    loading: false,
    error: false
}

export default function (state=initialState, action){
    switch(action.type){
        case WAREHOUSEZONE_LIST:
            return {
                ...state
            }
        case WAREHOUSEZONE_LIST_SUCCESS:                        
            return {
                ...state,
                warehouseZoneList : (action.payload===null? []:action.payload)
            }
        case WAREHOUSEZONE_LIST_ERROR:
            return {
                ...state,
                warehouseZoneList : []
            }     
        case WAREHOUSEZONE_DDLIST:
            return {...state,
                loading: true,
                warehouseZone: {},
                warehouseZoneDDList:  []
            }
        case WAREHOUSEZONE_DDLIST_SUCCESS:
            return {
                ...state,
                warehouseZoneDDList: action.payload,
                loading: false,
                error: false
            }
        case WAREHOUSEZONE_DDLIST_ERROR:
            return {
                ...state,
                warehouseZoneDDList: [],
                loading: false,
                error: true,
                warehouseZone: {}
            }            
        case WAREHOUSEZONE_GET:
            return {
                ...state,
                loading: true
            }
        case WAREHOUSEZONE_GET_SUCCESS:
            return {
                ...state,
                warehouseZone: action.payload,
                loading: false,
                error: false
            }
        case WAREHOUSEZONE_GET_ERROR:
            return {
                ...state,
                error: true,
                warehouseZone: {}
            }
        case WAREHOUSEZONE_NEW:
            return {
                ...state,
            }
        case WAREHOUSEZONE_NEW_SUCCESS:            
            return {                
                ...state,                
                warehouseZoneList: [...state.warehouseZoneList, action.payload],                
                warehouseZone: {},
                error: false,              
            }
        case WAREHOUSEZONE_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case WAREHOUSEZONE_EDIT:
            return {
                ...state
            }
        case WAREHOUSEZONE_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                warehouseZoneList: state.warehouseZoneList.map(warehousezone => warehousezone.id === action.payload.id ? warehousezone = action.payload : warehousezone),
                warehouseZone: {}
            }
        case WAREHOUSEZONE_EDIT_ERROR:
            return {
                ...state,
                error: true
            }
        // Location
        case WAREHOUSELOCATION_LIST:
            return {
                ...state
            }
        case WAREHOUSELOCATION_LIST_SUCCESS:                        
            return {
                ...state,
                warehouseLocationList : (action.payload===null? []:action.payload)
            }
        case WAREHOUSELOCATION_LIST_ERROR:
            return {
                ...state,
                warehouseLocationList : []
            }
        case WAREHOUSELOCATION_DDLIST:             
            return {
                ...state,
                loading: true,
                warehouseLocation: {}
            }
        case WAREHOUSELOCATION_DDLIST_SUCCESS:
            return {
                ...state,
                warehouseLocationDDList: action.payload,
                loading: false,
                error: false,
                warehouseZone: {}
            }        
        case WAREHOUSELOCATION_DDLIST_ERROR:
            return {
                ...state,
                warehouseLocationDDList: [],
                loading: false,
                error: true,
                warehouseZone: {}
            }
        case WAREHOUSELOCATION_GET:
            return {
                ...state,
                loading: true
            }
        case WAREHOUSELOCATION_GET_SUCCESS:
            return {
                ...state,
                warehouseLocation: action.payload,
                loading: false,
                error: false
            }
        case WAREHOUSELOCATION_GET_ERROR:
            return {
                ...state,
                error: true,
                warehouseLocation: {}
            }
        case WAREHOUSELOCATION_NEW:
            return {
                ...state,
            }
        case WAREHOUSELOCATION_NEW_SUCCESS:            
            return {                
                ...state,                
                warehouseLocationList: [...state.warehouseLocationList, action.payload],                
                warehouseLocation: {},
                error: false,              
            }
        case WAREHOUSELOCATION_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case WAREHOUSELOCATION_EDIT:
            return {
                ...state
            }
        case WAREHOUSELOCATION_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                warehouseLocationList: state.warehouseLocationList.map(warehouselocation => warehouselocation.id === action.payload.id ? warehouselocation = action.payload : warehouselocation),
                warehouseLocation: {}
            }
        case WAREHOUSELOCATION_EDIT_ERROR:
            return {
                ...state,
                error: true
            }
        case WAREHOUSE_ALL_ZONE_DDLIST:
                return {...state,
                    loading: true,
                    warehouseZone: {},
                    warehouseZoneAllList:  []
                }
        case WAREHOUSE_ALL_ZONE_DDLIST_SUCCESS:
                return {
                    ...state,
                    warehouseZoneAllList: action.payload,
                    loading: false,
                    error: false
                }
        case WAREHOUSE_ALL_ZONE_DDLIST_ERROR:
                return {
                    ...state,
                    warehouseZoneAllList: [],
                    loading: false,
                    error: true,
                    warehouseZone: {}
                } 
        case WAREHOUSE_ALL_LOCATION_DDLIST:{
            console.log(action.payload);
                return {...state,
                    loading: true,
                    warehouseZone: {},
                    warehouseLocationAllList:  []
                }}
        case WAREHOUSE_ALL_LOCATION_DDLIST_SUCCESS:
                return {
                    ...state,
                    warehouseLocationAllList: action.payload,
                    loading: false,
                    error: false
                }
        case WAREHOUSE_ALL_LOCATION_DDLIST_ERROR:
                return {
                    ...state,
                    warehouseLocationAllList: [],
                    loading: false,
                    error: true,
                    warehouseZone: {}
                } 
        default:
            return state;
    }
}