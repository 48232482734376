import AxiosClient from '../config/AxiosClient';
require('dotenv').config();
export class BaseService {
    controller = '';
    constructor(controller) {
        this.controller = controller;
    }

    get(url = '', data = {}) {
        return AxiosClient.get(`${this.controller}/${url}`, data);
    }

    post(url = '', data = {}) {
        return AxiosClient.post(`${this.controller}/${url}`, data);
    }

    put(url = '', data = {}) {
        return AxiosClient.put(`${this.controller}/${url}`, data);
    }

    delete(url = '', data = {}) {
        return AxiosClient.delete(`${this.controller}/${url}`, data);
    }
}
