export const NEW_ARTICLE_LABEL = 'New article';
export const EDIT_ARTICLE_LABEL = 'Edit article';
export const ARTICLES_LABEL = 'Articles';
export const ACTIONS_LABEL = 'Actions';
export const PART_NUMBER_LABEL = 'Part Number';
export const DESCRIPTION_LABEL = 'Description';
export const CATEGORY_LABEL = 'Category';
export const SUB_CATEGORY_LABEL = 'Sub Category';
export const SERIAL = 'Serial/Number';
export const APPLY_STOCK_LABEL = 'Apply stock';
export const PART_NUMB_IS_ACTIVE = 'Is Active'
export const EDIT_ITEM_MASTER_LABEL = 'Edit Item master';
export const ARTICLE_DATA_LABEL = 'Article Data';
export const REFERENCE_IMAGES_LABEL = 'Reference images';
export const ALTERNATE_PN_LABEL = 'Alternate P/N';
export const PN_LABEL = 'P/N';
export const CHAPTER_LABEL = 'Chapter';
export const FIGURE_LABEL = 'Figure';
export const MAINTENANCE_CONTROL_LABEL = 'Maintenance control';
export const UNIT_OF_MEASUREMENT_LABEL = 'Unit of measurement';
export const ARTICLE_CATEGORY_LABEL = 'Article category';
export const SUB_CATEGORY_ARTICLE_LABEL = 'Sub Category of the article';
export const EFFECTIVENESS_LABEL = 'Effectiveness';
export const CLASS_1_COMPONENT_LABEL = 'Class 1 Component';
export const ACTIVATE_MASTER_ARTICLE_LABEL = 'activate master article';
export const HAS_STC_LABEL = 'Has stc?';
export const STC_DESCRIPTION_LABEL = 'STC description';
export const HAS_AIRWORTHINESS_DIRECTIVE_LABEL = 'Has an airworthiness directive?';
export const AIRWORTHINESS_DIRECTIVE_DESCRPTION_LABEL = 'Airworthiness directive description';
export const HAS_SERVICE_BULLETIN_LABEL = 'Has service bulletin?';
export const SERVICE_BULLETIN_DESCRIPTION_LABEL = 'Service bulletin description';
export const SAVE_LABEL = 'Save';
export const LOCATION = 'Location';
export const CONDITION = 'Condition';
export const STATE = 'Status';
export const APPLICABILITY = 'Applicability';
export const ADQUISITION_DATE = 'Adquis./Date';
export const LOT = 'Lot';
export const CANCEL_LABEL = 'Cancel';
export const PART_NUMBER_IPC_LABEL = 'P/N IPC';
export const ATA_LABEL = 'Ata/Number';
export const SUB_ATA_LABEL = 'Sub Ata';
export const ACTIVE_LABEL = 'Active';
export const YES_LABEL = 'Yes';
export const NO_LABEL = 'No';
export const ITEM_LABEL = 'Item';
export const INDICATE_ALTER_PART_NUMBER_LABEL = 'Indicate alternate part number';
export const ASSOCIATE_LABEL = 'Associate';
export const CATEGORY_REQUIRED_MESSAGE = 'Article category is required!';
export const MAINTENANCE_CONTROL_REQUIRED_MESSAGE = 'Maintenance Control is required!';
export const UNIT_OF_MEASURE_REQUIRED_MESSAGE = 'Unit of Measure is required!';
export const EFFECTIVENESS_REQUIRED_MESSAGE = 'Effectiveness is required!';
export const ATA_REQUIRED_MESSAGE = 'ATA is required!';
export const PART_NUMBER_REQUIRED_MESSAGE = 'Part Number is required!';
export const DESCRIPTION_REQUIRED_MESSAGE = 'Description is required!';
export const APPLY_STOCK_REQUIRED_MESSAGE = 'Apply Stock is required!';
export const CHAPTER_REQUIRED_MESSAGE = 'Chapter is required!';
export const FIGURE_REQUIRED_MESSAGE = 'Figure is required!';
export const ITEM_REQUIRED_MESSAGE = 'Item is required!';
export const PART_NUMBER_MAX_LENGTH_MESSAGE = 'Maximum length is 50 characters';
export const DESCRIPTION_MAX_LENGTH_MESSAGE = 'Maximum length is 255 characters';
export const CHAPTER_MAX_LENGTH_MESSAGE = 'Maximum length is 2 characters';
export const FIGURE_MAX_LENGTH_MESSAGE = 'Maximum length is 3 characters';
export const ITEM_MAX_LENGTH_MESSAGE = 'Maximum length is 3 characters';
export const TSO_LABEL = 'Tech Standard Order';