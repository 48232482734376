import { MATERIALLOANDETAIL_ADD_NEW, MATERIALLOANDETAIL_EDIT_ONE, MATERIALLOANDETAIL_SET_LIST, MATERIALLOANDETAIL_SET_ONE, MATERIALLOAN_ADD_NEW, MATERIALLOAN_EDIT_ONE, MATERIALLOAN_SET_LIST, MATERIALLOAN_SET_ONE, MATERIALLOAN_TYPE_LIST, MATERIALLOAN_TYPE_LIST_SUCCESS, MATERIALLOAN_TYPE_LIST_ERROR } from "../types";


const initialState = {
    materialloans: [],
    materialloan: {},
    materialloandetails: [],
    materialloandetail: {},
    materialloantype: [],
}

export default function( state = initialState, action ) {
    switch (action.type) {
        case MATERIALLOAN_SET_LIST:
            return {
                ...state,
                materialloans: action.payload
            };
        
        case MATERIALLOAN_SET_ONE:
            return {
                ...state,
                materialloan: action.payload
            }

        case MATERIALLOAN_ADD_NEW:
            return {
                ...state,
                materialloans: [ ...state.materialloans, action.payload ],
                materialloan: action.payload
            }
        
        case MATERIALLOAN_EDIT_ONE:
            return {
                ...state,
                materialloans: state.materialloans
                    .map( materialloan => ( materialloan.id === action.payload.id ? action.payload : materialloan ) ),
                materialloan: action.payload
            }

        case MATERIALLOANDETAIL_SET_LIST:
            return {
                ...state,
                materialloandetails: action.payload
            }
        
        case MATERIALLOANDETAIL_SET_ONE:
            return {
                ...state,
                materialloandetail: action.payload
            }

        case MATERIALLOANDETAIL_ADD_NEW:
            return {
                ...state,
                materialloandetails: [...state.materialloandetails, action.payload],
                materialloandetail: action.payload
            }
        
        case MATERIALLOANDETAIL_EDIT_ONE:
            return {
                ...state,
                materialloandetails: state.materialloandetails
                    .map( materialloandetail => ( materialloandetail.id === action.payload.id ? action.payload : materialloandetail ) ),
                materialloandetail: action.payload
            }

            case MATERIALLOAN_TYPE_LIST:
                return {
                    ...state,
                    loading: true,
                    materialloantype: {}
                }
            case MATERIALLOAN_TYPE_LIST_SUCCESS:
                return {
                    ...state,
                    materialloantypes: action.payload,
                    loading: false,
                    error: false,
                    materialloantype: {}
                }
            case MATERIALLOAN_TYPE_LIST_ERROR:
                return {
                    ...state,
                    materialloantypes: [],
                    loading: false,
                    error: true,
                    materialloantype: {}
                }
    
        default:
            return state;
    }
}