import { combineReducers } from 'redux';

import settingsReducer from './settings.reducer';
import themesReducer from './themes.reducers';
import AirplaneManualReducer from './AirplaneManual.reducer';
import AccessLogReducer from './AccessLog.reducer';
import AirplaneReducer from './Airplane.reducer';
import AirplaneComponentReducer from './AirplaneComponent.reducer';
import AirplaneControlMaintenanceReducer from './AirplaneControlMaintenance.reducer';
import AirplaneTaskCardReducer from './AirplaneTaskCard.reducer';
import AirportIataReducer from './AirportIata.reducer';
import AirworthinessDirectiveReducer from './AirworthinessDirective.reducer';
import AirworthinessDirectiveTaskReducer from './AirworthinessDirectiveTask.reducer';
import AtaReducer from './Ata.reducer';
import AttachTypeReducer from './AttachType.reducer';
import BPartnerReducer from './BPartner.reducer';
import BankReducer from './Bank.reducer';
import BPartnerProductReducer from './BPartnerProduct.reducer';
import BPartnerBankReducer from './BPartnerBank.reducer';
import CategoryReducer from './Category.reducer';
import SubCategoryReducer from './Subcategory.reducer';
import ChargeReducer from './Charge.reducer';
import CompanyReducer from './Company.reducer';
import CountryReducer from './Country.reducer';
import CorrelativeDocumentReducer from './CorrelativeDocument.reducer';
import CurrencyReducer from './Currency.reducer';
import DepartmentReducer from './Department.reducer';
import DocumentByProcessReducer from './DocumentByProcess.reducer';
import EmployeeReducer from './Employee.reducer';
import EntityReducer from './Entity.reducer';
import EntityMdReducer from './EntityMd.reducer';
import FleetReducer from './Fleet.reducer';
import IncomingReducer from './Incoming.reducer';
import IntervalReducer from './Interval.reducer';
import ItemMasterReducer from './ItemMaster.reducer';
import ItemInventoryReducer from './ItemInventory.reducer';
import ImageByProcessReducer from './ImageByProcess.reducer';
import ManufacturerReducer from './Manufacturer.reducer';
import MaterialRequestReducer from './MaterialRequest.reducer';
import MaterialRequestDetailReducer from './MaterialRequestDetail.reducer';
import MenuReducer from './Menu.reducer';
import OmaReducer from './Oma.reducer';
import PaymentApprovalReducer from './PaymentApproval.reducer';
import PaymentHeaderReducer from './PaymentHeader.reducer';
import PaymentScheduleReducer from './PaymentSchedule.reducer';
import PriorityByProcessReducer from './PriorityByProcess.reducer';
import PurchaseRequisitionReducer from './PurchaseRequisition.reducer';
import PurchaseOrderReducer from './PurchaseOrder.reducer';
import PurchaseOrderDetailReducer from './PurchaseOrderDetail.reducer';
import QuotationBpartnerReducer from './QuotationBpartner.reducer';
import SubAtaReducer from './SubAta.reducer';
import SupplierEvaluationParamsReducer from './SupplierEvaluationParams.reducer';
import TaxReducer from './Tax.reducer';

import WarehouseReducer from './Warehouse.reducer';
import WarehouseZoneReducer from './WarehouseZone.reducer';

import UomReducer from './Uom.reducer';
import UserReducer from './User.reducer';
import UserRoleReducer from './UserRole.reducer';
import UserSecurityQuestionReducer from './UserSecurityQuestion.reducer';
import AirplaneFlightHistoryReducer from './AirplaneFlightHistory.reducer';
import PurchaseRequirementReducer from './PurchaseRequirement.reducer';

import ComponentAssembledHeaderReducer from './ComponentAssebledHeader.reducer'
import ComponentAssembledDetailReducer from './ComponentAssebledDetail.reducer'

import EquipmentReducer from './Equipment.reducer'
import EquipmentRequestReducer from './EquipmentRequest.reducer'
import EquipmentLogReducer from './EquipmentLog.reducer'
import RoleReducer from './Roles.reducer'

import ItemWarrantyReducer from './ItemWarranty.reducer'
import QuoteManagementReducer from './QuoteManagement.reducer';
import QuoteManagementDetailReducer from './QuoteManagementDetail.reducer';
import AirplaneModelManagementReducer from './AirplaneModelManagement.reducer';
import AirplaneSeriesManagementReducer from './AirplaneSeriesManagement.reducer';
import CorrelativeManagementReducer from './CorrelativeManagement.reducer';

import MaterialRemoveRecord from './MaterialRemoveRecord.reducer';
import InstallationAndRemovalParts from './InstallationMaterialAndRemovalParts.reducer'
import ServiceRoleReducer from './ServiceRole.reducer';
import MaterialLoanReducer from './MaterialLoan.reducer';
import CannibalizationRecordReducer from './CannibalizationRecord.reducer';
import iteminventoryadjustmentReducer from './ItemInventoryAdjustment.reducer';
import MaterialBetweenWarehouseReducer from './MaterialBetweenWarehouse.reducer';

export default combineReducers({
    settings: settingsReducer,
    theme: themesReducer,
    AccessLog: AccessLogReducer,
    Airplane: AirplaneReducer,
    AirportIata: AirportIataReducer,
    AirplaneManual: AirplaneManualReducer,
    AirplaneFlightHistory: AirplaneFlightHistoryReducer,
    AirplaneComponent: AirplaneComponentReducer,
    AirplaneControlMaintenance: AirplaneControlMaintenanceReducer,
    AirplaneTaskCard: AirplaneTaskCardReducer,
    AirworthinessDirective: AirworthinessDirectiveReducer,
    AirworthinessDirectiveTask: AirworthinessDirectiveTaskReducer,
    Ata: AtaReducer,
    AttachType: AttachTypeReducer,
    Bank: BankReducer,
    BPartner: BPartnerReducer,
    BPartnerProduct: BPartnerProductReducer,
    BPartnerBank: BPartnerBankReducer,
    Charge: ChargeReducer,
    Category: CategoryReducer,
    Company: CompanyReducer,
    Country: CountryReducer,
    Currency: CurrencyReducer,
    CorrelativeDocument: CorrelativeDocumentReducer,
    Department: DepartmentReducer,
    DocumentByProcess: DocumentByProcessReducer,
    Employee: EmployeeReducer,
    Entity: EntityReducer,
    EntityMd: EntityMdReducer,
    Fleet: FleetReducer,
    Incoming: IncomingReducer,
    Interval: IntervalReducer,
    ItemMaster: ItemMasterReducer,
    ItemInventory: ItemInventoryReducer,
    ImageByProcess: ImageByProcessReducer,
    Manufacturer: ManufacturerReducer,
    MaterialRequest: MaterialRequestReducer,
    MaterialRequestDetail: MaterialRequestDetailReducer,
    Menu: MenuReducer,
    Oma:OmaReducer,
    PaymentApproval: PaymentApprovalReducer,
    PaymentHeader: PaymentHeaderReducer,
    PaymentSchedule: PaymentScheduleReducer,
    PriorityByProcess: PriorityByProcessReducer,    
    PurchaseOrder: PurchaseOrderReducer,    
    PurchaseOrderDetail: PurchaseOrderDetailReducer,    
    PurchaseRequisition: PurchaseRequisitionReducer,  
    QuotationBpartner: QuotationBpartnerReducer,
    SubAta: SubAtaReducer,
    SubCategory: SubCategoryReducer,
    SupplierEvaluationParams: SupplierEvaluationParamsReducer,
    Tax: TaxReducer,
    Warehouse: WarehouseReducer,
    WarehouseZone: WarehouseZoneReducer,
    Uom: UomReducer,
    User: UserReducer,
    UserRole: UserRoleReducer,
    UserSecurityQuestion: UserSecurityQuestionReducer,   
    PurchaseRequirement: PurchaseRequirementReducer,
    ComponentAssembledHeader: ComponentAssembledHeaderReducer,
    ComponentAssembledDetail: ComponentAssembledDetailReducer,
    Equipment: EquipmentReducer,
    EquipmentRequest: EquipmentRequestReducer,
    EquipmentLog: EquipmentLogReducer,
    Role: RoleReducer,
    ItemWarranty : ItemWarrantyReducer,
    QuoteManagement: QuoteManagementReducer,
    QuoteManagementDetail: QuoteManagementDetailReducer,
    AirplaneModelManagement : AirplaneModelManagementReducer,
    AirplaneSeriesManagement: AirplaneSeriesManagementReducer,
    CorrelativeManagement: CorrelativeManagementReducer,
    MaterialRemoveRecord: MaterialRemoveRecord,
    InstallationAndRemovalParts: InstallationAndRemovalParts,
    ServiceRole: ServiceRoleReducer,
    MaterialLoan: MaterialLoanReducer,
    CannibalizationRecord: CannibalizationRecordReducer,
    iteminventoryadjustment: iteminventoryadjustmentReducer,
    MaterialBetweenWarehouse: MaterialBetweenWarehouseReducer
});
