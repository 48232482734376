import {
    AIRPLANETASKCARD_LIST,
    AIRPLANETASKCARD_LIST_SUCCESS,
    AIRPLANETASKCARD_LIST_ERROR,
    AIRPLANETASKCARD_DDLIST,
    AIRPLANETASKCARD_DDLIST_SUCCESS,
    AIRPLANETASKCARD_DDLIST_ERROR,
    AIRPLANETASKCARD_GET,
    AIRPLANETASKCARD_GET_SUCCESS,
    AIRPLANETASKCARD_GET_ERROR,  
    AIRPLANETASKCARD_NEW,
    AIRPLANETASKCARD_NEW_SUCCESS,
    AIRPLANETASKCARD_NEW_ERROR,
    AIRPLANETASKCARD_EDIT,
    AIRPLANETASKCARD_EDIT_SUCCESS,
    AIRPLANETASKCARD_EDIT_ERROR
} from '../types';

const initialState = {
    airplanetaskcards: [],    
    airplanetaskcard: {},
    airplanetaskcardList:{},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case AIRPLANETASKCARD_LIST:
            return {
                ...state,
                loading: true,
                airplanetaskcard: {},               
            }
        case AIRPLANETASKCARD_LIST_SUCCESS:            
            return {
                ...state,                
                airplanetaskcards: action.payload,  
                loading: false,
                error: false,
                airplanetaskcard: {}
            }
        case AIRPLANETASKCARD_LIST_ERROR:
            return {
                ...state,
                airplanetaskcards: [],
                loading: false,
                error: true,
                airplanetaskcard: {}
            }
        case AIRPLANETASKCARD_DDLIST:
            return {
                ...state,
                loading: true,
                airplanetaskcard: {},
                airplanetaskcardList: []
            }
        case AIRPLANETASKCARD_DDLIST_SUCCESS:
            return {
                ...state,
                airplanetaskcardList: action.payload,
                loading: false,
                error: false
            }
        case AIRPLANETASKCARD_DDLIST_ERROR:
            return {
                ...state,
                airplanetaskcardList: [],
                loading: false,
                error: true,
                airplanetaskcard: {}
            }
        case AIRPLANETASKCARD_GET:
            return {
                ...state,
                loading: true
            }
        case AIRPLANETASKCARD_GET_SUCCESS:
            return {
                ...state,
                airplanetaskcard: action.payload,
                loading: false,
                error: false
            }
        case AIRPLANETASKCARD_GET_ERROR:
            return {
                ...state,
                error: true,
                airplanetaskcard: {}
            }       
        case AIRPLANETASKCARD_NEW:
            return {
                ...state
            }
        case AIRPLANETASKCARD_NEW_SUCCESS:
            return {
                ...state,
                error: false,
                airplanetaskcards: [...state.airplanetaskcards, action.payload]
            }
        case AIRPLANETASKCARD_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case AIRPLANETASKCARD_EDIT:
            return {
                ...state,
            }
        case AIRPLANETASKCARD_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                airplanetaskcard: action.payload,
                airplanetaskcards: state.airplanetaskcards.map(airplanetaskcard => airplanetaskcard.id === action.payload.id ? airplanetaskcard = action.payload : airplanetaskcard),
            }
        case AIRPLANETASKCARD_EDIT_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state;
    }
}