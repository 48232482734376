import {
    CATEGORY_LIST,
    CATEGORY_LIST_SUCCESS,
    CATEGORY_LIST_ERROR,
    CATEGORY_DDLIST,
    CATEGORY_DDLIST_SUCCESS,
    CATEGORY_DDLIST_ERROR,
    CATEGORY_GET,
    CATEGORY_GET_SUCCESS,
    CATEGORY_GET_ERROR,
    CATEGORY_NEW,
    CATEGORY_NEW_SUCCESS,
    CATEGORY_NEW_ERROR,
    CATEGORY_EDIT,
    CATEGORY_EDIT_SUCCESS,
    CATEGORY_EDIT_ERROR
} from '../types';

const initialState = {
    categories: [],
    category: {},
    categoryList:{},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case CATEGORY_LIST:
            return {
                ...state,
                loading: true,
                category: {},
            }
        case CATEGORY_LIST_SUCCESS:

            return {
                ...state,
                categories: action.payload,
                loading: false,
                error: false,
                category: {}
            }
        case CATEGORY_LIST_ERROR:
            return {
                ...state,
                categories: [],
                loading: false,
                error: true,
                category: {}
            }
        case CATEGORY_DDLIST:
            return {
                ...state,
                loading: true,
                category: {},
                categoryList: []
            }
        case CATEGORY_DDLIST_SUCCESS:
            return {
                ...state,
                categoryList: action.payload,
                loading: false,
                error: false
            }
        case CATEGORY_DDLIST_ERROR:
            return {
                ...state,
                categoryList: [],
                loading: false,
                error: true,
                category: {}
            }
        case CATEGORY_GET:
            return {
                ...state,
                loading: true
            }
        case CATEGORY_GET_SUCCESS:
            return {
                ...state,
                category: action.payload,
                loading: false,
                error: false
            }
        case CATEGORY_GET_ERROR:
            return {
                ...state,
                error: true,
                category: {}
            }
        case CATEGORY_NEW:
            return {
                ...state
            }
        case CATEGORY_NEW_SUCCESS:
            return {
                ...state,
                error: false,
                categories: [...state.categories, action.payload]
            }
        case CATEGORY_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case CATEGORY_EDIT:
            return {
                ...state,
            }
        case CATEGORY_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                category: action.payload,
                categories: state.categories.map(category => category.id === action.payload.id ? category = action.payload : category),
            }
        case CATEGORY_EDIT_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state;
    }
}