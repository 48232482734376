import {
    COMPONENT_ASSEMBLED_HEADER_DDLIST,
    COMPONENT_ASSEMBLED_HEADER_DDLIST_ERROR,
    COMPONENT_ASSEMBLED_HEADER_DDLIST_SUCCESS,
    COMPONENT_ASSEMBLED_HEADER_EDIT,
    COMPONENT_ASSEMBLED_HEADER_EDIT_ERROR,
    COMPONENT_ASSEMBLED_HEADER_EDIT_SUCCESS,
    COMPONENT_ASSEMBLED_HEADER_GET,
    COMPONENT_ASSEMBLED_HEADER_GET_ERROR,
    COMPONENT_ASSEMBLED_HEADER_GET_SUCCESS,
    COMPONENT_ASSEMBLED_HEADER_LIST,
    COMPONENT_ASSEMBLED_HEADER_LIST_ERROR,
    COMPONENT_ASSEMBLED_HEADER_LIST_SUCCESS,
    COMPONENT_ASSEMBLED_HEADER_NEW,
    COMPONENT_ASSEMBLED_HEADER_NEW_ERROR,
    COMPONENT_ASSEMBLED_HEADER_NEW_SUCCESS,
    COMPONENT_ASSEMBLED_HEADER_SAVE,
} from '../types';

const initialState = {
    headerList: [],
    headerDDList: [],
    headerData: {},
    headerId: 0,
    loading: false,
    error: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case COMPONENT_ASSEMBLED_HEADER_LIST:
            return {
                ...state,
                loading: true,
                headerList: [],
            };
        case COMPONENT_ASSEMBLED_HEADER_LIST_SUCCESS:
            return {
                ...state,
                headerList: action.payload,
                loading: false,
                error: false,
                headerData: {},
            };
        case COMPONENT_ASSEMBLED_HEADER_LIST_ERROR:
            return {
                ...state,
                headerList: [],
                loading: false,
                error: true,
                headerData: {},
            };
        case COMPONENT_ASSEMBLED_HEADER_DDLIST:
            return {
                ...state,
                loading: true,
                headerData: {},
                headerDDList: [],
            };
        case COMPONENT_ASSEMBLED_HEADER_DDLIST_SUCCESS:
            return {
                ...state,
                headerDDList: action.payload,
                loading: false,
                error: false,
            };
        case COMPONENT_ASSEMBLED_HEADER_DDLIST_ERROR:
            return {
                ...state,
                headerDDList: [],
                loading: false,
                error: true,
                headerData: {},
            };
        case COMPONENT_ASSEMBLED_HEADER_GET:
            return {
                ...state,
                loading: true,
            };
        case COMPONENT_ASSEMBLED_HEADER_GET_SUCCESS:
            return {
                ...state,
                headerData: action.payload,
                loading: false,
                error: false,
            };
        case COMPONENT_ASSEMBLED_HEADER_GET_ERROR:
            return {
                ...state,
                error: true,
                headerData: {},
            };
        case COMPONENT_ASSEMBLED_HEADER_NEW:
            return {
                ...state,
            };
        case COMPONENT_ASSEMBLED_HEADER_NEW_SUCCESS:
            return {
                ...state,
                error: false,
                headerList: [...state.headerList, action.payload],
            };
        case COMPONENT_ASSEMBLED_HEADER_NEW_ERROR:
            return {
                ...state,
                error: true,
            };
        case COMPONENT_ASSEMBLED_HEADER_EDIT:
            return {
                ...state,
            };
        case COMPONENT_ASSEMBLED_HEADER_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                headerData: action.payload,
                headerList: [
                    ...state.headerList.filter((item) => item.id !== action.payload.id),
                    action.payload,
                ],
            };
        case COMPONENT_ASSEMBLED_HEADER_EDIT_ERROR:
            return {
                ...state,
                error: true,
            };
        case COMPONENT_ASSEMBLED_HEADER_SAVE:
            return {
                ...state,
                headerData: action.payload,
                headerList: [
                    ...state.headerList.filter((item) => item.id !== action.payload.id),
                    action.payload,
                ],
            };
        default:
            return state;
    }
}
