/* * /
import {
    QUOTATIONBPARTNER_LIST,
    QUOTATIONBPARTNER_LIST_SUCCESS,
    QUOTATIONBPARTNER_LIST_ERROR,
    QUOTATIONBYREQUISITION_LIST,
    QUOTATIONBYREQUISITION_LIST_SUCCESS,
    QUOTATIONBYREQUISITION_LIST_ERROR,
    QUOTATIONBPARTNER_DDLIST,
    QUOTATIONBPARTNER_DDLIST_SUCCESS,
    QUOTATIONBPARTNER_DDLIST_ERROR,
    QUOTATIONBPARTNER_GET,
    QUOTATIONBPARTNER_GET_SUCCESS,
    QUOTATIONBPARTNER_GET_ERROR,
    QUOTATIONBYBPARTNER_GET,
    QUOTATIONBYBPARTNER_GET_SUCCESS,
    QUOTATIONBYBPARTNER_GET_ERROR,
    QUOTATIONBPARTNER_NEW,
    QUOTATIONBPARTNER_NEW_SUCCESS,
    QUOTATIONBPARTNER_NEW_ERROR,
    QUOTATIONBPARTNER_NEW_RECORD,
    QUOTATIONBPARTNER_EDIT,
    QUOTATIONBPARTNER_EDIT_SUCCESS,
    QUOTATIONBPARTNER_EDIT_ERROR
} from '../types';
/* */
import {
    QUOTATIONBPARTNER_LIST,
    QUOTATIONBPARTNER_LIST_SUCCESS,
    QUOTATIONBPARTNER_LIST_ERROR,
    QUOTATIONBYREQUISITION_LIST,
    QUOTATIONBYREQUISITION_LIST_SUCCESS,
    QUOTATIONBYREQUISITION_LIST_ERROR,
    QUOTATIONBPARTNER_DDLIST,
    QUOTATIONBPARTNER_DDLIST_SUCCESS,
    QUOTATIONBPARTNER_DDLIST_ERROR,
    QUOTATIONBPARTNER_GET,
    QUOTATIONBPARTNER_GET_SUCCESS,
    QUOTATIONBPARTNER_GET_ERROR,
    QUOTATIONBPARTNER_NEW,
    QUOTATIONBPARTNER_NEW_SUCCESS,
    QUOTATIONBPARTNER_NEW_ERROR,
    QUOTATIONBPARTNER_NEW_RECORD,
    QUOTATIONBPARTNER_EDIT,
    QUOTATIONBPARTNER_EDIT_SUCCESS,
    QUOTATIONBPARTNER_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Unidades de Medida
export function QuotationBPartnerListAction(){    
    return (dispatch =>{
        dispatch(QuotationBPartnerList());
        AxiosClient.get(`${process.env.REACT_APP_QUOTATIONBPARTNER}`)
            .then((response)=> {
                dispatch(QuotationBPartnerListSuccess(response.data.result));
            })
            .catch(error=> {
                console.log(error);
                dispatch( QuotationBPartnerListError(error) );
            });
    });
}

export const QuotationBPartnerList = () => ({
    type: QUOTATIONBPARTNER_LIST
});

export const QuotationBPartnerListSuccess = (records) => ({
    type: QUOTATIONBPARTNER_LIST_SUCCESS,
    payload: records
});

export const QuotationBPartnerListError = (error) => ({
    type: QUOTATIONBPARTNER_LIST_ERROR,
    payload: error
});

// Función Principal para obtener el listado de Posibles Proveedores por Requisicion y Material
export function QuotationByRequisitionListAction(id){    
    return (dispatch =>{
        dispatch(QuotationByRequisitionList());
        //AxiosClient.get(`${process.env.REACT_APP_QUOTATIONBPARTNER}`)
        AxiosClient.get(`${process.env.REACT_APP_QUOTATIONBYBPARTNER}/${id}`)
            .then((response)=> {
                dispatch(QuotationByRequisitionListSuccess(response.data.result));
            })
            .catch(error=> {
                console.log(error);
                dispatch( QuotationByRequisitionListError(error) );
            });
    });
}

export const QuotationByRequisitionList = () => ({
    type: QUOTATIONBYREQUISITION_LIST,
});

export const QuotationByRequisitionListSuccess = (records) => ({
    type: QUOTATIONBYREQUISITION_LIST_SUCCESS,
    payload: records
});

export const QuotationByRequisitionListError = (error) => ({
    type: QUOTATIONBYREQUISITION_LIST_ERROR,
    payload: error
});


// Función Principal para obtener Unidad de Medida
export function QuotationBPartnerDDListAction(id){    
    return (dispatch =>{
        dispatch(QuotationBPartnerDDList());
        AxiosClient.get(`${process.env.REACT_APP_QUOTATIONBPARTNER}/ddlist/${id}`)
            .then((response)=> {
                dispatch( QuotationBPartnerDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( QuotationBPartnerDDListError(error) );
            });
    });
}

export const QuotationBPartnerDDList = () => ({
    type: QUOTATIONBPARTNER_DDLIST
});

export const QuotationBPartnerDDListSuccess = (records) => ({
    type: QUOTATIONBPARTNER_DDLIST_SUCCESS,
    payload: records
});

export const QuotationBPartnerDDListError = (error) => ({
    type: QUOTATIONBPARTNER_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener una Unidad de Medida por ID
export function QuotationBPartnerGetAction(id){
    return (dispatch =>{
        dispatch(QuotationBPartnerGet());
        AxiosClient.get(`${process.env.REACT_APP_QUOTATIONBPARTNER}/${id}`)
            .then((response)=> {
                dispatch( QuotationBPartnerGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( QuotationBPartnerGetError(error) );
            });
    });
}

export const QuotationBPartnerGet = () => ({
    type: QUOTATIONBPARTNER_GET
});

export const QuotationBPartnerGetSuccess = (record) => ({
    type: QUOTATIONBPARTNER_GET_SUCCESS,
    payload: record
});

export const QuotationBPartnerGetError = (error) => ({
    type: QUOTATIONBPARTNER_GET_ERROR,
    payload: error
});

// // Función Principal para obtener la lista de Proveedores que pueden cotizar segun un tipo de Articulo
// export function QuotationByBpartner(id){
//     return (dispatch =>{
//         dispatch(QuotationByBpartnerGet());
//         AxiosClient.get(`${process.env.REACT_APP_QUOTATIONBYBPARTNER}/${id}`)
//             .then((response)=> {
//                 console.log(response);
//                 dispatch( QuotationByBpartnerGetSuccess(response.data.result));
//             })
//             .catch(error=> {
//                 console.log(error);
//                 dispatch( QuotationByBpartnerGetError(error) );
//             });
//     });
// }

// export const QuotationByBpartnerGet= () => ({
//     type: QUOTATIONBYBPARTNER_GET,   
// });

// export const QuotationByBpartnerGetSuccess = (records) => ({
//     type:  QUOTATIONBYBPARTNER_GET_SUCCESS,
//     //payload: record
//     payload: records
// });

// export const QuotationByBpartnerGetError = (error) => ({
//     type:  QUOTATIONBYBPARTNER_GET_ERROR,
//     payload: error
// });





//Función Principal para setear objeto de Unidad de Medida cuando se va crear un registro nuevo
export function QuotationBPartnerNewAction(){
    return (dispatch =>{
            dispatch(QuotationBPartnerNewRecord());
    });
}

export const QuotationBPartnerNewRecord = () => ({
    type: QUOTATIONBPARTNER_NEW_RECORD
});

// Función Principal para crear y editar registros de Unidades de Medida
export function QuotationBPartnerSaveAction(record, isNew){
    console.log("data desde Actions",record);
    return (dispatch =>{
        if(!isNew) {
            dispatch(QuotationBPartnerEdit());
        } else{
            dispatch(QuotationBPartnerNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_QUOTATION_BPARTNER}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( QuotationBPartnerNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( QuotationBPartnerNewError(record) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_QUOTATION_BPARTNER}/${record.id}`, record)
                .then((response)=> {
                    dispatch( QuotationBPartnerEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( QuotationBPartnerEditError(record) );
                });
        }
    });
}

export const QuotationBPartnerNew = () => ({
    type: QUOTATIONBPARTNER_NEW
});

export const QuotationBPartnerNewSuccess = (record) => ({
    type: QUOTATIONBPARTNER_NEW_SUCCESS,
    payload: record
});

export const QuotationBPartnerNewError = (error) => ({
    type: QUOTATIONBPARTNER_NEW_ERROR,
    payload: error
});

export const QuotationBPartnerEdit = () => ({
    type: QUOTATIONBPARTNER_EDIT
});

export const QuotationBPartnerEditSuccess = (record) => ({
    type: QUOTATIONBPARTNER_EDIT_SUCCESS,
    payload: record
});

export const QuotationBPartnerEditError = (error) => ({
    type: QUOTATIONBPARTNER_EDIT_ERROR,
    payload: error
});
