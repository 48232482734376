import {
    MATERIALREQUEST_LIST,
    MATERIALREQUEST_LIST_SUCCESS,
    MATERIALREQUEST_LIST_ERROR,
    MATERIALREQUEST_PENDING_LIST,
    MATERIALREQUEST_PENDING_LIST_SUCCESS,
    MATERIALREQUEST_PENDING_LIST_ERROR,
    MATERIALREQUEST_RETURN_LIST,
    MATERIALREQUEST_RETURN_LIST_SUCCESS,
    MATERIALREQUEST_RETURN_LIST_ERROR,
    MATERIALREQUEST_REPORT,
    MATERIALREQUEST_REPORT_SUCCESS,
    MATERIALREQUEST_REPORT_ERROR,
    MATERIALREQUEST_GET,
    MATERIALREQUEST_GET_SUCCESS,
    MATERIALREQUEST_GET_ERROR,
    MATERIALREQUEST_NEW,
    MATERIALREQUEST_NEW_SUCCESS,
    MATERIALREQUEST_NEW_ERROR,
    MATERIALREQUEST_NEW_RECORD,
    MATERIALREQUEST_RETURN_NEW,
    MATERIALREQUEST_RETURN_NEW_SUCCESS,
    MATERIALREQUEST_RETURN_NEW_ERROR,
    MATERIALREQUEST_EDIT,
    MATERIALREQUEST_EDIT_SUCCESS,
    MATERIALREQUEST_EDIT_ERROR,
    MATERIALREQUEST_RETURN_EDIT,
    MATERIALREQUEST_RETURN_EDIT_SUCCESS,
    MATERIALREQUEST_RETURN_EDIT_ERROR,
    MATERIALREQUEST_CANCELLATION,
    MATERIALREQUEST_CANCELLATION_SUCCESS,
    MATERIALREQUEST_CANCELLATION_ERROR, MATERIALREQUEST_CLEAN,
    MATERIALREQUEST_DD_LIST,
    MATERIALREQUEST_DD_LIST_SUCCESS,
    MATERIALREQUEST_DD_LIST_ERROR
} from '../types'

import AxiosClient from '../../config/AxiosClient';
import * as appConstants from '../../AppConstants';

require('dotenv').config();

// Función Principal para obtener lista de MaterialRequest
export function MaterialRequestListAction() {

    return (dispatch => {
        dispatch(MaterialRequestList());
        const user = JSON.parse(sessionStorage.getItem('user'));
        AxiosClient.get(`${process.env.REACT_APP_MATERIALREQUEST}/listfilter/${user.userid}/${user.userrole}`)
            .then((response) => {
                dispatch(MaterialRequestListSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(MaterialRequestListError(error));
            });
    });
}

export const DispatchedMaterialRequestListAction = () => {
    return (dispatch => {
        dispatch(MaterialRequestList());
        const user = JSON.parse(sessionStorage.getItem('user'));
        AxiosClient.get(`${process.env.REACT_APP_MATERIALREQUEST}/listfilter/${user.userid}/${user.userrole}`)
            .then(async (response) => response.data.result.filter(element =>
            (element.mr_status === appConstants.REACT_APP_MR_STATUS_DP ||
                element.mr_status === appConstants.REACT_APP_MR_STATUS_CI ||
                element.mr_status === appConstants.REACT_APP_MR_STATUS_SD ||
                element.mr_status === appConstants.REACT_APP_MR_STATUS_WI)))
            .then((list) => {
                dispatch(MaterialRequestListSuccess(list));
            })
            .catch(error => {
                dispatch(MaterialRequestListError(error));
            });
    });
}

export const MaterialRequestList = () => ({
    type: MATERIALREQUEST_LIST
});

export const MaterialRequestListSuccess = (records) => ({
    type: MATERIALREQUEST_LIST_SUCCESS,
    payload: records
});

export const MaterialRequestListError = (error) => ({
    type: MATERIALREQUEST_LIST_ERROR,
    payload: error
});

// Función Principal para obtener lista de MaterialRequest Pendientes
export function MaterialRequestPendingListAction() {
    return (dispatch => {
        dispatch(MaterialRequestPendingList());
        AxiosClient.get(`${process.env.REACT_APP_MATERIALREQUESTPENDING}`)
            .then((response) => {
                dispatch(MaterialRequestPendingListSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(MaterialRequestPendingListError(error));
            });
    });
}

export const MaterialRequestPendingList = () => ({
    type: MATERIALREQUEST_PENDING_LIST
});

export const MaterialRequestPendingListSuccess = (records) => ({
    type: MATERIALREQUEST_PENDING_LIST_SUCCESS,
    payload: records
});

export const MaterialRequestPendingListError = (error) => ({
    type: MATERIALREQUEST_PENDING_LIST_ERROR,
    payload: error
});



// Función Principal para obtener lista de MaterialRequest candidatos para Devolucion o ya Devueltos
export function MaterialRequestReturnListAction() {
    return (dispatch => {
        dispatch(MaterialRequestReturnList());
        //const user = JSON.parse(sessionStorage.getItem('user'));
        AxiosClient.get(`${process.env.REACT_APP_MATERIALREQUESTRETURN}`)

            .then((response) => {
                dispatch(MaterialRequestReturnListSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(MaterialRequestReturnListError(error));
            });
    });
}

export const MaterialRequestReturnList = () => ({
    type: MATERIALREQUEST_RETURN_LIST
});

export const MaterialRequestReturnListSuccess = (records) => ({
    type: MATERIALREQUEST_RETURN_LIST_SUCCESS,
    payload: records
});

export const MaterialRequestReturnListError = (error) => ({
    type: MATERIALREQUEST_RETURN_LIST_ERROR,
    payload: error
});


// Función Principal para obtener Reporte de Solicitud de Material
export const MaterialRequestReportAction = (id) => async dispatch => {

    dispatch(MaterialRequestReport());
    const response = await AxiosClient.get(`${process.env.REACT_APP_MATERIALREQUEST}/report/${id}`)
        .catch(error => {
            dispatch(MaterialRequestReportError(error));
        });
    dispatch(MaterialRequestReportSuccess(response.data.result));
    console.log(response.data.result);
    return response.data;
}

export const MaterialRequestReport = () => ({
    type: MATERIALREQUEST_REPORT
});

export const MaterialRequestReportSuccess = (record) => ({
    type: MATERIALREQUEST_REPORT_SUCCESS,
    payload: record
});

export const MaterialRequestReportError = (error) => ({
    type: MATERIALREQUEST_REPORT_ERROR,
    payload: error
});

// Función Principal para obtener un MaterialRequest
export const MaterialRequestGetAction = (id) => async (dispatch) => {
    dispatch(MaterialRequestGet());
    await AxiosClient.get(`${process.env.REACT_APP_MATERIALREQUEST}/${id}`)
        .then((response) => {
            dispatch(MaterialRequestGetSuccess(response.data.result));
        })
        .catch(error => {
            dispatch(MaterialRequestGetError(error));
        });
}

export const MaterialRequestCleanAction = () => (dispatch) => {
    dispatch(MaterialRequestClean())
}

export const MaterialRequestClean = () => ({
    type: MATERIALREQUEST_CLEAN
});

export const MaterialRequestGet = () => ({
    type: MATERIALREQUEST_GET
});

export const MaterialRequestGetSuccess = (record) => ({
    type: MATERIALREQUEST_GET_SUCCESS,
    payload: record
});

export const MaterialRequestGetError = (error) => ({
    type: MATERIALREQUEST_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de MaterialRequest cuando se va crear un registro nuevo
export function MaterialRequestNewAction() {
    return (dispatch => {
        dispatch(MaterialRequestNewRecord());
    });
}

export const MaterialRequestNewRecord = () => ({
    type: MATERIALREQUEST_NEW_RECORD
});

// Función Principal para crear y editar registros de MaterialRequest
export const MaterialRequestSaveAction = (record, isNew) => async (dispatch) => {
    if (!isNew) {
        dispatch(MaterialRequestEdit());
    } else {
        dispatch(MaterialRequestNew());
    }
    if (isNew) {
        const response = await AxiosClient.post(
            `${process.env.REACT_APP_MATERIALREQUEST}`,
            record,
        ).catch((error) => {
            //console.log("error en el actions",error);
            dispatch(MaterialRequestNewError(error));
        });
        record.id = response.data.result.id;

        //console.log("save response.data.result en actions",response.data.result);
        dispatch(MaterialRequestNewSuccess(response.data.result));
        dispatch(MaterialRequestGetSuccess(response.data.result));

        return response.data;

    } else {
        const response = await AxiosClient.put(
            `${process.env.REACT_APP_MATERIALREQUEST}/${record.id}`,
            record,
        ).catch((error) => {
            dispatch(MaterialRequestEditError(true));
        });

        dispatch(MaterialRequestEditSuccess(response?.data.result));
        dispatch(MaterialRequestGetSuccess(response?.data.result));

        return response.data;
    }
};

export const MaterialRequestNew = () => ({
    type: MATERIALREQUEST_NEW
});

export const MaterialRequestNewSuccess = (record) => ({
    type: MATERIALREQUEST_NEW_SUCCESS,
    payload: record
});

export const MaterialRequestNewError = (error) => ({
    type: MATERIALREQUEST_NEW_ERROR,
    payload: error
});

export const MaterialRequestEdit = () => ({
    type: MATERIALREQUEST_EDIT
});

export const MaterialRequestEditSuccess = (record) => ({
    type: MATERIALREQUEST_EDIT_SUCCESS,
    payload: record
});

export const MaterialRequestEditError = (error) => ({
    type: MATERIALREQUEST_EDIT_ERROR,
    payload: error
});


// Función Principal Actualizar la informacion de Retiro de Materiales (Quien retira y quien entraga)
export function MaterialRequestDispatchSaveAction(record, isNew) {
    return (dispatch => {
        // if(!isNew) {
        //     dispatch(MaterialRequestEdit());
        // } else{
        //     dispatch(MaterialRequestNew());
        // }
        // if(isNew){
        //     AxiosClient.post(`${process.env.REACT_APP_MATERIALREQUEST}`, record)
        //         .then((response)=> {
        //             record.id = response.data.result.id;
        //             dispatch( MaterialRequestNewSuccess(response.data.result));
        //         })
        //         .catch(error=> {
        //             dispatch( MaterialRequestNewError(error) );
        //         });
        // }else{
        AxiosClient.put(`${process.env.REACT_APP_MATERIALREQUESTDISPATCH}/${record.id}`, record)
            .then((response) => {
                dispatch(MaterialRequestEditSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(MaterialRequestEditError(true));
            });
        //     }
    });
}

export const MaterialRequestDispatchNew = () => ({
    type: MATERIALREQUEST_NEW
});

export const MaterialRequestDispatchNewSuccess = (record) => ({
    type: MATERIALREQUEST_NEW_SUCCESS,
    payload: record
});

export const MaterialRequestDispatchNewError = (error) => ({
    type: MATERIALREQUEST_NEW_ERROR,
    payload: error
});

export const MaterialRequestDispatchEdit = () => ({
    type: MATERIALREQUEST_EDIT
});

export const MaterialRequestDispatchEditSuccess = (record) => ({
    type: MATERIALREQUEST_EDIT_SUCCESS,
    payload: record
});

export const MaterialRequestDispatchEditError = (error) => ({
    type: MATERIALREQUEST_EDIT_ERROR,
    payload: error
});


// Función Principal para editar registros de Devolucion de MaterialRequest
export function MaterialRequestReturnSaveAction(record, isNew) {
    return (dispatch => {
        if (!isNew) {
            dispatch(MaterialRequestReturnEdit());
        } else {
            dispatch(MaterialRequestReturnNew());
        }
        if (isNew) {
            AxiosClient.post(`${process.env.REACT_APP_MATERIALREQUESTRETURN}`, record)
                .then((response) => {
                    record.id = response.data.result.id;
                    dispatch(MaterialRequestReturnNewSuccess(response.data.result));
                })
                .catch(error => {
                    dispatch(MaterialRequestReturnNewError(error));
                });
        } else {
            AxiosClient.put(`${process.env.REACT_APP_MATERIALREQUESTRETURN}/${record.id}`, record)
                .then((response) => {
                    dispatch(MaterialRequestReturnEditSuccess(response.data.result));
                })
                .catch(error => {
                    dispatch(MaterialRequestReturnEditError(true));
                });
        }
    });
}

export const MaterialRequestReturnNew = () => ({
    type: MATERIALREQUEST_RETURN_NEW
});

export const MaterialRequestReturnNewSuccess = (record) => ({
    type: MATERIALREQUEST_RETURN_NEW_SUCCESS,
    payload: record
});

export const MaterialRequestReturnNewError = (error) => ({
    type: MATERIALREQUEST_RETURN_NEW_ERROR,
    payload: error
});

export const MaterialRequestReturnEdit = () => ({
    type: MATERIALREQUEST_RETURN_EDIT
});

export const MaterialRequestReturnEditSuccess = (record) => ({
    type: MATERIALREQUEST_RETURN_EDIT_SUCCESS,
    payload: record
});

export const MaterialRequestReturnEditError = (error) => ({
    type: MATERIALREQUEST_RETURN_EDIT_ERROR,
    payload: error
});









// Función Principal para anular Solicitudes de Material al Almacén
export function MaterialRequestCancellationAction(record) {
    return (dispatch => {
        dispatch(MaterialRequestCancellation());
        AxiosClient.put(`${process.env.REACT_APP_MATERIALREQUEST}/cancellation/${record.id}`, record)
            .then((response) => {
                dispatch(MaterialRequestCancellationSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(MaterialRequestCancellationError(error));
            });
    }
    );
}

export const MaterialRequestCancellation = () => ({
    type: MATERIALREQUEST_CANCELLATION
});

export const MaterialRequestCancellationSuccess = (record) => ({
    type: MATERIALREQUEST_CANCELLATION_SUCCESS,
    payload: record
});

export const MaterialRequestCancellationError = (error) => ({
    type: MATERIALREQUEST_CANCELLATION_ERROR,
    payload: error
});

// Material Request DD List

export function MaterialRequestDDListAction() {
    return (dispatch => {
        dispatch(MaterialRequestDDList());
        AxiosClient.get(`${process.env.REACT_APP_MATERIALREQUEST}/installed/ddlist`)
            .then((response) => {
                dispatch(MaterialRequestDDListSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(MaterialRequestDDListError());
            });
    });
};

export const MaterialRequestDDList = () => {
    return {
        type: MATERIALREQUEST_DD_LIST
    };
};

export const MaterialRequestDDListSuccess = (records) => {
    return {
        type: MATERIALREQUEST_DD_LIST_SUCCESS,
        payload: records
    };
};

export const MaterialRequestDDListError = (error) => {
    return {
        type: MATERIALREQUEST_DD_LIST_ERROR,
        payload: error
    };
};