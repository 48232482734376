import {
    PAYMENTAPPROVAL_LIST,
    PAYMENTAPPROVAL_LIST_SUCCESS,
    PAYMENTAPPROVAL_LIST_ERROR,
    PAYMENTAPPROVAL_DDLIST,
    PAYMENTAPPROVAL_DDLIST_SUCCESS,
    PAYMENTAPPROVAL_DDLIST_ERROR,
    PAYMENTAPPROVAL_GET,
    PAYMENTAPPROVAL_GET_SUCCESS,
    PAYMENTAPPROVAL_GET_ERROR,
    PAYMENTAPPROVAL_NEW,
    PAYMENTAPPROVAL_NEW_SUCCESS,
    PAYMENTAPPROVAL_NEW_ERROR,
    PAYMENTAPPROVAL_NEW_RECORD,
    PAYMENTAPPROVAL_EDIT,
    PAYMENTAPPROVAL_EDIT_SUCCESS,
    PAYMENTAPPROVAL_EDIT_ERROR
} from '../types';

const initialState = {
    paymentapprovals: [],
    paymentapproval: {},
    loading: false,
    error: false}

export default function(state = initialState, action){
    switch(action.type){
        case PAYMENTAPPROVAL_LIST:
            return {
                ...state,
                loading: true,
                paymentapproval: {}
            }
        case PAYMENTAPPROVAL_LIST_SUCCESS:
            return {
                ...state,
                paymentapprovals: action.payload,
                loading: false,
                error: false,
                paymentapproval: {}
            }
        case PAYMENTAPPROVAL_LIST_ERROR:
            return {
                ...state,
                paymentapprovals: [],
                loading: false,
                error: true,
                paymentapproval: {}
            }
        case PAYMENTAPPROVAL_DDLIST:
            return {
                ...state,
                loading: true,
                paymentapproval: {}
            }
        case PAYMENTAPPROVAL_DDLIST_SUCCESS:
            return {
                ...state,
                paymentapprovals: action.payload,
                loading: false,
                error: false                
            }
        case PAYMENTAPPROVAL_DDLIST_ERROR:
            return {
                ...state,
                paymentapprovals: [],
                loading: false,
                error: true,
                paymentapproval: {}
            }
        case PAYMENTAPPROVAL_GET:
            return {
                ...state,
                loading: true
            }
        case PAYMENTAPPROVAL_GET_SUCCESS:
            return {
                ...state,
                paymentapproval: action.payload,
                loading: false,
                error: false
            }
        case PAYMENTAPPROVAL_GET_ERROR:
            return {
                ...state,
                error: true,
                paymentapproval: {}
            }
        case PAYMENTAPPROVAL_NEW:
            return {
                ...state
            }
        case PAYMENTAPPROVAL_NEW_SUCCESS:
            return {
                ...state,
                paymentapprovals: [...state.paymentapprovals, action.payload],
                paymentapproval: {},
                error: false
            }
        case PAYMENTAPPROVAL_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case PAYMENTAPPROVAL_NEW_RECORD:
            return {
                ...state,
                paymentapproval: {},
                error: false
            }
        case PAYMENTAPPROVAL_EDIT:
            return {
                ...state
            }
        case PAYMENTAPPROVAL_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                paymentapproval: action.payload,               
                paymentapprovals: state.paymentapprovals.map(paymentapproval => paymentapproval.id === action.payload.id ? paymentapproval = action.payload : paymentapproval),
            }
        case PAYMENTAPPROVAL_EDIT_ERROR:
            return {
                ...state,
                error: false
            }
        default:
            return state;
    }
}