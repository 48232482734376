import {
    PURCHASEREQUIREMENT_CLEAN,
    PURCHASEREQUIREMENT_EDIT,
    PURCHASEREQUIREMENT_EDIT_ERROR,
    PURCHASEREQUIREMENT_EDIT_SUCCESS,
    PURCHASEREQUIREMENT_GET,
    PURCHASEREQUIREMENT_GET_ERROR,
    PURCHASEREQUIREMENT_GET_SUCCESS,
    PURCHASEREQUIREMENT_LIST,
    PURCHASEREQUIREMENT_LIST_ERROR,
    PURCHASEREQUIREMENT_LIST_SUCCESS,
    PURCHASEREQUIREMENT_NEW,
    PURCHASEREQUIREMENT_NEW_ERROR,
    PURCHASEREQUIREMENT_NEW_SUCCESS,
    PURCHASEREQUIREMENT_REPORT_GET,
    PURCHASEREQUIREMENT_REPORT_GET_ERROR,
    PURCHASEREQUIREMENT_REPORT_GET_SUCCESS,
    PURCHASEREQUIREMENTDETAIL_DD_LIST,
    PURCHASEREQUIREMENTDETAIL_DD_LIST_ERROR,
    PURCHASEREQUIREMENTDETAIL_DD_LIST_SUCCESS
} from "../types";

const initialState = {
    purchaserequirements: [],
    purchaserequirement: {},
    purchaserequirementdetailDDList: [],
    loading: false,
    error: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PURCHASEREQUIREMENT_LIST:
            return {
                ...state,
                loading: true,
                purchaserequirements: [],
                purchaserequirement: {}
            };

        case PURCHASEREQUIREMENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                purchaserequirements: action.payload,
                purchaserequirement: {}
            };

        case PURCHASEREQUIREMENT_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                purchaserequirements: [],
                purchaserequirement: {}
            };

        case PURCHASEREQUIREMENT_GET:
            return {
                ...state,
                loading: true,
                error: false,
                purchaserequirement: {}
            }

        case PURCHASEREQUIREMENT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                purchaserequirement: action.payload
            };

        case PURCHASEREQUIREMENT_GET_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                purchaserequirements: [],
                purchaserequirement: {}
            };

        case PURCHASEREQUIREMENT_REPORT_GET:
            return {
                ...state,
                loading: true,
                purchaserequirementreport: {}
            }
        case PURCHASEREQUIREMENT_REPORT_GET_SUCCESS:
            return {
                ...state,
                purchaserequirementreport: action.payload,
                loading: false,
                error: false
            }
        case PURCHASEREQUIREMENT_REPORT_GET_ERROR:
            return {
                ...state,
                purchaserequirementreport: {},
                loading: false,
                error: true
            }
        case PURCHASEREQUIREMENT_CLEAN:
            return {
                ...state,
                purchaserequirement: {}
            };

        case PURCHASEREQUIREMENT_NEW: 
            return {
                ...state,
                loading: true,
                error: false
            };

        case PURCHASEREQUIREMENT_NEW_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                purchaserequirements: [...state.purchaserequirements, action.payload],
                purchaserequirement: action.payload
            };

        case PURCHASEREQUIREMENT_NEW_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                purchaserequirements: [],
                purchaserequirement: {}
            }
        
        case PURCHASEREQUIREMENT_EDIT:
            return {
                ...state,
                loading: true,
                error: false
            };

        case PURCHASEREQUIREMENT_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                purchaserequirement: action.payload,
                purchaserequirements: state.purchaserequirements.map(purchaserequirement => (purchaserequirement.id === action.payload.id ? action.payload : purchaserequirement))
            };

        case PURCHASEREQUIREMENT_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                purchaserequirements: [],
                purchaserequirement: {}
            };

        case PURCHASEREQUIREMENTDETAIL_DD_LIST:
            return {
                ...state,
                loading: true,
                purchaserequirementdetailDDList: []
            };

        case PURCHASEREQUIREMENTDETAIL_DD_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                purchaserequirementdetailDDList: action.payload
            };

        case PURCHASEREQUIREMENTDETAIL_DD_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                purchaserequirementdetailDDList: []
            };

        default:
            return state;
    }
};