export const SERIAL_LABEL = 'Serial/Number';
export const WAREHOUSE_LABEL = 'Warehouse';
export const LOT_LABEL = 'Lot';
export const IS_ALTERNATE_LABEL = 'Is alternate?';
export const QUANTITY_AVAILABLE = 'Quantity Available';
export const EXPIRATION_DATE_LABEL = 'Expiration date';
export const ZONE_LABEL = 'Zone';
export const LOCATION_LABEL = 'Location';
export const REP_LABEL = 'Rep';
export const INCOMING_NUMBER_LABEL = 'Incoming/Number';
export const APPLICABILITY_LABEL = 'Applicability';
export const STATUS_LABEL = 'Status';
export const INVENTORY_QUANTITY_LABEL = 'Inventory quantity';
export const COMMITTED_AMOUNT_LABEL = 'Committed amount';
export const ACQUISITION_DATE_LABEL = 'Acquisition date';
export const DETAILED_DESCRIPTION_LABEL = 'Incoming number';
export const AMOUNT_LABEL = 'Amount';
export const MANUFACTURING_DATE = 'Manufacturing date';
export const LOCATION_DATE = 'Location date';
export const ALTERNATE_PROVIDER = 'Provider';
export const AERONAUTICAL_INSPECTOR_LABEL = 'Aeronautical Inspector';
export const SUB_CATEGORY_LABEL = 'Sub Category';
export const CONSUMABLE_CATEGORY_LABEL = 'Consumables';