import {
    ACCESSLOG_LIST ,       
    ACCESSLOG_LIST_SUCCESS,
    ACCESSLOG_LIST_ERROR,  
    ACCESSLOG_DDLIST,      
    ACCESSLOG_DDLIST_SUCCESS,
    ACCESSLOG_DDLIST_ERROR,
    ACCESSLOG_GET,         
    ACCESSLOG_GET_SUCCESS, 
    ACCESSLOG_GET_ERROR,   
    ACCESSLOG_NEW,         
    ACCESSLOG_NEW_SUCCESS, 
    ACCESSLOG_NEW_ERROR,   
    ACCESSLOG_NEW_RECORD,  
    ACCESSLOG_EDIT,        
    ACCESSLOG_EDIT_SUCCESS,
    ACCESSLOG_EDIT_ERROR  
} from '../types';

const initialState = {
    accesslogs: [], 
    accesslog:{},   
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case ACCESSLOG_LIST:
            return {
                ...state,
                loading: true,
                accesslog: {}
            }
        case ACCESSLOG_LIST_SUCCESS:
            return {
                ...state,
                accesslogs: action.payload,
                loading: false,
                error: false,
                accesslog: {}
            }
        case ACCESSLOG_LIST_ERROR:
            return {
                ...state,
                accesslogs: [],
                loading: false,
                error: true,
                accesslog: {}
            }
        case ACCESSLOG_DDLIST:
            return {
                ...state,
                loading: true,
                accesslog: {}
            }
        case ACCESSLOG_DDLIST_SUCCESS:
            return {
                ...state,
                accesslogs: action.payload,
                loading: false,
                error: false,
                accesslog: {}
            }
        case ACCESSLOG_DDLIST_ERROR:
            return {
                ...state,
                accesslogs: [],
                loading: false,
                error: true,
                accesslog: {}
            }
        case ACCESSLOG_GET:
            return {
                ...state,
                loading: true
            }
        case ACCESSLOG_GET_SUCCESS:
            return {
                ...state,
                accesslog: action.payload,
                loading: false,
                error: false
            }
        case ACCESSLOG_GET_ERROR:
            return {
                ...state,
                error: true,
                accesslog: {}
            }
        case ACCESSLOG_NEW:
            return {
                ...state
            }
        case ACCESSLOG_NEW_SUCCESS:
            return {
                ...state,
                accesslogs: [...state.accesslogs, action.payload],
                accesslog: {},
                error: false
            }
        case ACCESSLOG_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case ACCESSLOG_NEW_RECORD:
            return {
                ...state,
                accesslog: {},
                error: false
            }
        case ACCESSLOG_EDIT:
            return {
                ...state
            }
        case ACCESSLOG_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                accesslog: action.payload,
                accesslogs: state.accesslogs.map(accesslog => accesslog.id === action.payload.id ? accesslog = action.payload : accesslog),
            }
        case ACCESSLOG_EDIT_ERROR:
            return {
                ...state,
                error: false
            }
        default:
            return state;
    }
}
