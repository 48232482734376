import {
    AIRPLANETASKCARD_LIST,
    AIRPLANETASKCARD_LIST_SUCCESS,
    AIRPLANETASKCARD_LIST_ERROR,
    AIRPLANETASKCARD_DDLIST,
    AIRPLANETASKCARD_DDLIST_SUCCESS,
    AIRPLANETASKCARD_DDLIST_ERROR,
    AIRPLANETASKCARD_GET,
    AIRPLANETASKCARD_GET_SUCCESS,
    AIRPLANETASKCARD_GET_ERROR,
    AIRPLANETASKCARD_NEW,
    AIRPLANETASKCARD_NEW_SUCCESS,
    AIRPLANETASKCARD_NEW_ERROR,
    AIRPLANETASKCARD_NEW_RECORD,
    AIRPLANETASKCARD_EDIT,
    AIRPLANETASKCARD_EDIT_SUCCESS,
    AIRPLANETASKCARD_EDIT_ERROR,
} from '../types';

import AxiosClient from '../../config/AxiosClient';
require('dotenv').config();

// // Función Principal para obtener la Lista de Task Card
export function AirplaneTaskCardListAction(id){
    return (dispatch =>{
        dispatch(AirplaneTaskCardList());
        AxiosClient.get(`${process.env.REACT_APP_AIRPLANETASKCARD}/list/${id}`)      
        .then((response)=> {
            dispatch(AirplaneTaskCardListSuccess(response.data.result));
        })
        .catch(error=> {
            dispatch( AirplaneTaskCardListError(error) );
        });
    });
}


export const AirplaneTaskCardList = () => ({
    type: AIRPLANETASKCARD_LIST,
});

export const AirplaneTaskCardListSuccess = (records) => ({
    type: AIRPLANETASKCARD_LIST_SUCCESS,
    payload: records,
});

export const  AirplaneTaskCardListError = (error) => ({
    type: AIRPLANETASKCARD_LIST_ERROR,
    payload: error,
});

// Función Principal para obtener los Task Card en forma de DDList
export const AirplaneTaskCardDDListAction = (id) => async (dispatch) => {
    dispatch(AirplaneTaskCardDDList());
    await AxiosClient.get(`${process.env.REACT_APP_AIRPLANETASKCARD}/ddlist/${id}`)
        .then((response) => {
            dispatch(AirplaneTaskCardDDListSuccess(response.data.result));
        })
        .catch((error) => {
            dispatch(AirplaneTaskCardDDListError(error));
        });
};

export const AirplaneTaskCardDDList = () => ({
    type: AIRPLANETASKCARD_DDLIST,
});

export const AirplaneTaskCardDDListSuccess = (records) => ({
    type: AIRPLANETASKCARD_DDLIST_SUCCESS,
    payload: records,
});

export const AirplaneTaskCardDDListError = (error) => ({
    type: AIRPLANETASKCARD_DDLIST_ERROR,
    payload: error,
});


// Función Principal para obtener un Task Card
export const AirplaneTaskCardGetAction = (id) => async (dispatch) => {
    try {
        dispatch(AirplaneTaskCardGet());
        const response = await AxiosClient.get(`${process.env.REACT_APP_AIRPLANETASKCARD}/${id}`).catch(
            (error) => {
                dispatch(AirplaneTaskCardGetError(error));
            },
        );
        dispatch(AirplaneTaskCardGetSuccess(response.data.result));
    } catch (e) {
        console.error(e);
    }
};

export const AirplaneTaskCardGet = () => ({
    type: AIRPLANETASKCARD_GET,
});

export const AirplaneTaskCardGetSuccess = (record) => ({
    type: AIRPLANETASKCARD_GET_SUCCESS,
    payload: record,
});

export const AirplaneTaskCardGetError = (error) => ({
    type: AIRPLANETASKCARD_GET_ERROR,
    payload: error,
});



// Main function for to create Task Card info
export const AirplaneTaskCardNewAction = (airplanetaskcard) => async (dispatch) => {
    dispatch(AirplaneTaskCardNew());

    AxiosClient.post(process.env.REACT_APP_AIRPLANETASKCARD, airplanetaskcard)
        .then((resultado) => {
            if (resultado.status === 201) {
                dispatch(AirplaneTaskCardNewSuccess(airplanetaskcard));
            }
        })
        .catch((error) => {
            dispatch(AirplaneTaskCardNewError());
        });
};

export const AirplaneTaskCardNewRecord = () => ({
    type: AIRPLANETASKCARD_NEW_RECORD,
});

// Función Principal para crear y editar registros de Task Card
export const AirplaneTaskCardSaveAction = (record, isNew) => async (dispatch) => {

    //console.log("record.data AirplaneTaskCardSaveAction",record.data);

    if (!isNew) {
        dispatch(AirplaneTaskCardEdit());
    } else {
        dispatch(AirplaneTaskCardNew());
    }
    if (isNew) {
        await AxiosClient.post(`${process.env.REACT_APP_AIRPLANETASKCARD}`, record)
            .then((response) => {
                record.id = response.data.result.id;
                //dispatch( AirplaneNewSuccess(record));
                console.debug(response);
                //dispatch(AirplaneTaskCardNewSuccess(response.data.result));
                dispatch(AirplaneTaskCardNewSuccess(record));
            })
            .catch((error) => {
                console.error(error);
                dispatch(AirplaneTaskCardNewError(error));
            });
    } else {
        await AxiosClient.put(`${process.env.REACT_APP_AIRPLANETASKCARD}/${record.id}`, record)
            .then((response) => {               
                //dispatch(AirplaneTaskCardEditSuccess(record.data.result));
                dispatch(AirplaneTaskCardEditSuccess(record));
            })
            .catch((error) => {
                console.error(error);
                dispatch(AirplaneTaskCardEditError(error));
            });
    }
};

export const AirplaneTaskCardNew = () => ({
    type: AIRPLANETASKCARD_NEW,
});

export const AirplaneTaskCardNewSuccess = (record) => ({
    type: AIRPLANETASKCARD_NEW_SUCCESS,
    payload: record,
});

export const AirplaneTaskCardNewError = (error) => ({
    type: AIRPLANETASKCARD_NEW_ERROR,
    payload: error,
});

export const AirplaneTaskCardEdit = () => ({
    type: AIRPLANETASKCARD_EDIT,
});

export const AirplaneTaskCardEditSuccess = (record) => ({
    type: AIRPLANETASKCARD_EDIT_SUCCESS,
    payload: record,
});

export const AirplaneTaskCardEditError = (error) => ({
    type: AIRPLANETASKCARD_EDIT_ERROR,
    payload: error,
});
