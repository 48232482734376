import {
    PURCHASEREQUISITION_LIST,
    PURCHASEREQUISITION_LIST_SUCCESS,
    PURCHASEREQUISITION_LIST_ERROR,
    PURCHASEREQUISITION_DDLIST,
    PURCHASEREQUISITION_DDLIST_SUCCESS,
    PURCHASEREQUISITION_DDLIST_ERROR,
    PURCHASEREQUISITION_GET,
    PURCHASEREQUISITION_GET_SUCCESS,
    PURCHASEREQUISITION_GET_ERROR,
    PURCHASEREQUISITION_NEW,
    PURCHASEREQUISITION_NEW_SUCCESS,
    PURCHASEREQUISITION_NEW_ERROR,
    PURCHASEREQUISITION_NEW_RECORD,
    PURCHASEREQUISITION_EDIT,
    PURCHASEREQUISITION_EDIT_SUCCESS,
    PURCHASEREQUISITION_EDIT_ERROR,
    PURCHASEREQUISITION_SAVE_IN_REDUCER
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Unidades de Medida
export function PurchaseRequisitionListAction(){
    
    return (dispatch =>{
        dispatch(PurchaseRequisitionList());
        AxiosClient.get(`${process.env.REACT_APP_PURCHASE_REQUISITION}`)
            .then((response)=> {
                dispatch(PurchaseRequisitionListSuccess(response.data.result));
            })
            .catch(error=> {
                console.log(error);
                dispatch( PurchaseRequisitionListError(error) );
            });
    });
}

export const PurchaseRequisitionList = () => ({
    type: PURCHASEREQUISITION_LIST
});

export const PurchaseRequisitionListSuccess = (records) => ({
    type: PURCHASEREQUISITION_LIST_SUCCESS,
    payload: records
});

export const PurchaseRequisitionListError = (error) => ({
    type: PURCHASEREQUISITION_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Unidad de Medida
export function PurchaseRequisitionDDListAction(id){
    
    return (dispatch =>{
        dispatch(PurchaseRequisitionDDList());
        AxiosClient.get(`${process.env.REACT_APP_PURCHASE_REQUISITION}/ddlist/${id}`)
            .then((response)=> {
                dispatch( PurchaseRequisitionDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( PurchaseRequisitionDDListError(error) );
            });
    });
}

export const PurchaseRequisitionDDList = () => ({
    type: PURCHASEREQUISITION_DDLIST
});

export const PurchaseRequisitionDDListSuccess = (records) => ({
    type: PURCHASEREQUISITION_DDLIST_SUCCESS,
    payload: records
});

export const PurchaseRequisitionDDListError = (error) => ({
    type: PURCHASEREQUISITION_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener una Unidad de Medida por ID
export function PurchaseRequisitionGetAction(id){
    return (dispatch =>{
        dispatch(PurchaseRequisitionGet());
        AxiosClient.get(`${process.env.REACT_APP_PURCHASE_REQUISITION}/${id}`)
            .then((response)=> {
                dispatch( PurchaseRequisitionGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( PurchaseRequisitionGetError(error) );
            });
    });
}

export const PurchaseRequisitionGet = () => ({
    type: PURCHASEREQUISITION_GET
});

export const PurchaseRequisitionGetSuccess = (record) => ({
    type: PURCHASEREQUISITION_GET_SUCCESS,
    payload: record
});

export const PurchaseRequisitionGetError = (error) => ({
    type: PURCHASEREQUISITION_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Unidad de Medida cuando se va crear un registro nuevo
export function PurchaseRequisitionNewAction(){
    return (dispatch =>{
            dispatch(PurchaseRequisitionNewRecord());
    });
}

export const PurchaseRequisitionNewRecord = () => ({
    type: PURCHASEREQUISITION_NEW_RECORD
});

// Función Principal para crear y editar registros de Unidades de Medida
export function PurchaseRequisitionSaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(PurchaseRequisitionEdit());
        } else{
            dispatch(PurchaseRequisitionNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_PURCHASE_REQUISITION}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( PurchaseRequisitionNewSuccess(response.data.result));
                })
                .catch(error=> {
                    dispatch( PurchaseRequisitionNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_PURCHASE_REQUISITION}/${record.id}`, record)
                .then((response)=> {
                    dispatch( PurchaseRequisitionEditSuccess(response.data.result));
                })
                .catch(error=> {
                    dispatch( PurchaseRequisitionEditError(error) );
                });
        }
    });
}

export const PurchaseRequisitionNew = () => ({
    type: PURCHASEREQUISITION_NEW
});

export const PurchaseRequisitionNewSuccess = (record) => ({
    type: PURCHASEREQUISITION_NEW_SUCCESS,
    payload: record
});

export const PurchaseRequisitionNewError = (error) => ({
    type: PURCHASEREQUISITION_NEW_ERROR,
    payload: error
});

export const PurchaseRequisitionEdit = () => ({
    type: PURCHASEREQUISITION_EDIT
});

export const PurchaseRequisitionEditSuccess = (record) => ({
    type: PURCHASEREQUISITION_EDIT_SUCCESS,
    payload: record
});

export const PurchaseRequisitionEditError = (error) => ({
    type: PURCHASEREQUISITION_EDIT_ERROR,
    payload: error
});

// Función Principal para guardar registro de Requisición Temporalmente en el Reducer
export function PurchaseRequisitionSaveInReducerAction(record){
    return (dispatch =>{
        dispatch(PurchaseRequisitionSaveInReducer(record));
    });
}

export const PurchaseRequisitionSaveInReducer = (record) => ({
    type: PURCHASEREQUISITION_SAVE_IN_REDUCER,
    payload: record
});

