import {
    SUBATA_LIST,
    SUBATA_LIST_SUCCESS,
    SUBATA_LIST_ERROR,
    SUBATA_DDLIST,
    SUBATA_DDLIST_SUCCESS,
    SUBATA_DDLIST_ERROR,
    SUBATA_GET,
    SUBATA_GET_SUCCESS,
    SUBATA_GET_ERROR,
    SUBATA_NEW,
    SUBATA_NEW_SUCCESS,
    SUBATA_NEW_ERROR,
    SUBATA_NEW_RECORD,
    SUBATA_EDIT,
    SUBATA_EDIT_SUCCESS,
    SUBATA_EDIT_ERROR
} from '../types';

const initialState = {
    subatas: [],
    subata: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case SUBATA_LIST:
            return {
                ...state,
                loading: true,
                subata: {}
            }
        case SUBATA_LIST_SUCCESS:
            return {
                ...state,
                subatas: action.payload,
                loading: false,
                error: false,
                subata: {}
            }
        case SUBATA_LIST_ERROR:
            return {
                ...state,
                subatas: [],
                loading: false,
                error: true,
                subata: {}
            }
        case SUBATA_DDLIST:
            return {
                ...state,
                loading: true,
                subata: {}
            }
        case SUBATA_DDLIST_SUCCESS:
            return {
                ...state,
                subatas: action.payload,
                loading: false,
                error: false,
                subata: {}
            }
        case SUBATA_DDLIST_ERROR:
            return {
                ...state,
                subatas: [],
                loading: false,
                error: true,
                subata: {}
            }
        case SUBATA_GET:
            return {
                ...state,
                loading: true
            }
        case SUBATA_GET_SUCCESS:
            return {
                ...state,
                subata: action.payload,
                loading: false,
                error: false
            }
        case SUBATA_GET_ERROR:
            return {
                ...state,
                error: true,
                subata: {}
            }
        case SUBATA_NEW:
            return {
                ...state,
                loading: true,
            }
        case SUBATA_NEW_SUCCESS:
            sessionStorage.setItem('error', false);
            return {
                ...state,
                subatas: [...state.subatas, action.payload],
                subata: {},
                loading: false,
                error: false
            }
        case SUBATA_NEW_ERROR:
            sessionStorage.setItem('error', true);
            return {
                ...state,
                subata: action.payload,
                loading: false,
                error: true
            }
        case SUBATA_NEW_RECORD:
            return {
                ...state,
                subata: {},
                error: false
            }
        case SUBATA_EDIT:
            return {
                ...state
            }
        case SUBATA_EDIT_SUCCESS:
            sessionStorage.setItem('error', false);
            return {
                ...state,
                error: false,
                subata: action.payload,
                subatas: state.subatas.map(subata => subata.id === action.payload.id ? subata = action.payload : subata),
            }
        case SUBATA_EDIT_ERROR:
            sessionStorage.setItem('error', true);
            return {
                ...state,
                subata: action.payload,
                error: false
            }
        default:
            return state;
    }
}
