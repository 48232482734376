import {
    USERROLE_LIST,
    USERROLE_LIST_SUCCESS,
    USERROLE_LIST_ERROR,
    USERROLE_DDLIST,
    USERROLE_DDLIST_SUCCESS,
    USERROLE_DDLIST_ERROR,
    USERROLE_GET,
    USERROLE_GET_SUCCESS,
    USERROLE_GET_ERROR,
    USERROLE_NEW,
    USERROLE_NEW_SUCCESS,
    USERROLE_NEW_ERROR,
    USERROLE_NEW_RECORD,
    USERROLE_EDIT,
    USERROLE_EDIT_SUCCESS,
    USERROLE_EDIT_ERROR, USERROLES_LIST_SUCCESS, USERROLES_LIST_ERROR, USERROLES_LIST
} from "../types";

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de UserRole
export function UserRoleListAction(){
    
    return (dispatch =>{
        dispatch(UserRoleList());
        AxiosClient.get(`${process.env.REACT_APP_USERROLE}`)
            .then((response)=> {
                dispatch( UserRoleListSuccess(response.data.result || []));
            })
            .catch(error=> {
                dispatch( UserRoleListError(error) );
            });
    });
}

export const UserRoleList = () => ({
    type: USERROLE_LIST
});

export const UserRoleListSuccess = ( records = [] ) => ({
    type: USERROLE_LIST_SUCCESS,
    payload: records
});

export const UserRoleListError = (error) => ({
    type: USERROLE_LIST_ERROR,
    payload: error
});

// Función Principal para obtener UserRole en forma de DropDown
export function UserRoleDDListAction(){
    return (dispatch =>{
        dispatch(UserRoleDDList());
        AxiosClient.get(`${process.env.REACT_APP_USERROLE}/ddlist`)
            .then((response)=> {
                dispatch( UserRoleDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( UserRoleDDListError(error) );
            });
    });
}

export const UserRoleDDList = () => ({
    type: USERROLE_DDLIST
});

export const UserRoleDDListSuccess = (records) => ({
    type: USERROLE_DDLIST_SUCCESS,
    payload: records});

export const UserRoleDDListError = (error) => ({
    type: USERROLE_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener un UserRole
export function UserRoleGetAction(id){
    return (dispatch =>{
        dispatch(UserRoleGet());
        AxiosClient.get(`${process.env.REACT_APP_USERROLE}/${id}`)
            .then((response)=> {
                dispatch( UserRoleGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( UserRoleGetError(error) );
            });
    });
}

export const UserRoleGet = () => ({
    type: USERROLE_GET
});

export const UserRoleGetSuccess = (record) => ({
    type: USERROLE_GET_SUCCESS,
    payload: record
});

export const UserRoleGetError = (error) => ({
    type: USERROLE_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de UserRole cuando se va crear un registro nuevo
export function UserRoleNewAction(){
    return (dispatch =>{
            dispatch(UserRoleNewRecord());
    });
}

export const UserRoleNewRecord = () => ({
    type: USERROLE_NEW_RECORD
});

// Función Principal para crear y editar registros de UserRole
export function UserRoleSaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(UserRoleEdit());
        } else{
            dispatch(UserRoleNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_USERROLE}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( UserRoleNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( UserRoleNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_USERROLE}/${record.id}`, record)
                .then((response)=> {
                    dispatch( UserRoleEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( UserRoleEditError(true) );
                });
        }
    });
}

export const UserRoleNew = () => ({
    type: USERROLE_NEW
});

export const UserRoleNewSuccess = (record) => ({
    type: USERROLE_NEW_SUCCESS,
    payload: record
});

export const UserRoleNewError = (error) => ({
    type: USERROLE_NEW_ERROR,
    payload: error
});

export const UserRoleEdit = () => ({
    type: USERROLE_EDIT
});

export const UserRoleEditSuccess = (record) => ({
    type: USERROLE_EDIT_SUCCESS,
    payload: record
});

export const UserRoleEditError = (error) => ({
    type: USERROLE_EDIT_ERROR,
    payload: error
});


// Función Principal para obtener lista de UserRole
export function UserRolesListAction(user_id = 0){

    return (dispatch =>{
        dispatch(UserRolesList());
        AxiosClient.get(`user/roles/${user_id}`)
          .then((response)=> {
              dispatch( UserRolesListSuccess(response.data.result));
          })
          .catch(error=> {
              dispatch( UserRolesListError(error) );
          });
    });
}

export const UserRolesList = () => ({
    type: USERROLES_LIST
});

export const UserRolesListSuccess = (records) => ({
    type: USERROLES_LIST_SUCCESS,
    payload: records
});

export const UserRolesListError = (error) => ({
    type: USERROLES_LIST_ERROR,
    payload: error
});