import {
    BANK_LIST,
    BANK_LIST_SUCCESS,
    BANK_LIST_ERROR,
    BANK_DDLIST,
    BANK_DDLIST_SUCCESS,
    BANK_DDLIST_ERROR,
    BANK_GET,
    BANK_GET_SUCCESS,
    BANK_GET_ERROR,
    BANK_NEW,
    BANK_NEW_SUCCESS,
    BANK_NEW_ERROR,
    BANK_NEW_RECORD,
    BANK_EDIT,
    BANK_EDIT_SUCCESS,
    BANK_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Bancos
export function BankListAction(){
    
    return (dispatch =>{
        dispatch(BankList());
        AxiosClient.get(`${process.env.REACT_APP_BANK}`)
            .then((response)=> {
                dispatch(BankListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( BankListError(error) );
            });
    });
}

export const BankList = () => ({
    type: BANK_LIST
});

export const BankListSuccess = (records) => ({
    type: BANK_LIST_SUCCESS,
    payload: records
});

export const BankListError = (error) => ({
    type: BANK_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Bancos en Forma de Drop Lista
export function BankDDListAction(){
    return (dispatch =>{
        dispatch(BankDDList());
        AxiosClient.get(`${process.env.REACT_APP_BANK}/ddlist`)
            .then((response)=> {
                dispatch( BankDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( BankDDListError(error) );
            });
    });
}
// export function BankDDListAction(id){
    
//     return (dispatch =>{
//         dispatch(BankDDList());
//         AxiosClient.get(`${process.env.REACT_APP_BANK}/ddlist/${id}`)
//             .then((response)=> {
//                 dispatch( BankDDListSuccess(response.data.result));
//             })
//             .catch(error=> {
//                 dispatch( BankDDListError(error) );
//             });
//     });
// }

export const BankDDList = () => ({
    type: BANK_DDLIST
});

export const BankDDListSuccess = (records) => ({
    type: BANK_DDLIST_SUCCESS,
    payload: records
});

export const BankDDListError = (error) => ({
    type: BANK_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener una Unidad de Medida por ID
export function BankGetAction(id){
    return (dispatch =>{
        dispatch(BankGet());
        AxiosClient.get(`${process.env.REACT_APP_BANK}/${id}`)
            .then((response)=> {
                dispatch( BankGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( BankGetError(error) );
            });
    });
}

export const BankGet = () => ({
    type: BANK_GET
});

export const BankGetSuccess = (record) => ({
    type: BANK_GET_SUCCESS,
    payload: record
});

export const BankGetError = (error) => ({
    type: BANK_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Unidad de Medida cuando se va crear un registro nuevo
export function BankNewAction(){
    return (dispatch =>{
            dispatch(BankNewRecord());
    });
}

export const BankNewRecord = () => ({
    type: BANK_NEW_RECORD
});

// Función Principal para crear y editar registros de Unidades de Medida
export function BankSaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(BankEdit());
        } else{
            dispatch(BankNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_BANK}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( BankNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( BankNewError(record) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_BANK}/${record.id}`, record)
                .then((response)=> {
                    dispatch( BankEditSuccess(record));
                })
                .catch(error=> {                   
                    dispatch( BankEditError(error) );
                });
        }
    });
}

export const BankNew = () => ({
    type: BANK_NEW
});

export const BankNewSuccess = (record) => ({
    type: BANK_NEW_SUCCESS,
    payload: record
});

export const BankNewError = (error) => ({
    type: BANK_NEW_ERROR,
    payload: error
});

export const BankEdit = () => ({
    type: BANK_EDIT
});

export const BankEditSuccess = (record) => ({
    type: BANK_EDIT_SUCCESS,
    payload: record
});

export const BankEditError = (error) => ({
    type: BANK_EDIT_ERROR,
    payload: error
});
