import {
    PAYMENTSCHEDULE_LIST,
    PAYMENTSCHEDULE_LIST_SUCCESS,
    PAYMENTSCHEDULE_LIST_ERROR,
    PAYMENTSCHEDULE_DDLIST,
    PAYMENTSCHEDULE_DDLIST_SUCCESS,
    PAYMENTSCHEDULE_DDLIST_ERROR,
    PAYMENTSCHEDULE_GET,
    PAYMENTSCHEDULE_GET_SUCCESS,
    PAYMENTSCHEDULE_GET_ERROR,
    PAYMENTSCHEDULE_NEW,
    PAYMENTSCHEDULE_NEW_SUCCESS,
    PAYMENTSCHEDULE_NEW_ERROR,
    PAYMENTSCHEDULE_NEW_RECORD,
    PAYMENTSCHEDULE_EDIT,
    PAYMENTSCHEDULE_EDIT_SUCCESS,
    PAYMENTSCHEDULE_EDIT_ERROR
} from '../types';

const initialState = {
    paymentschedules: [],
    paymentschedulesddlist: [],
    paymentschedule: {},
    loading: false,
    error: false}

export default function(state = initialState, action){
    switch(action.type){
        case PAYMENTSCHEDULE_LIST:
            return {
                ...state,
                loading: true,
                paymentschedule: {}
            }
        case PAYMENTSCHEDULE_LIST_SUCCESS:
            return {
                ...state,
                paymentschedules: action.payload,
                loading: false,
                error: false,
                paymentschedule: {}
            }
        case PAYMENTSCHEDULE_LIST_ERROR:
            return {
                ...state,
                paymentschedules: [],
                loading: false,
                error: true,
                paymentschedule: {}
            }
        case PAYMENTSCHEDULE_DDLIST:
            return {
                ...state,
                loading: true,
                //paymentschedule: {}
                paymentschedulesddlist: {}
            }
        case PAYMENTSCHEDULE_DDLIST_SUCCESS:
            return {
                ...state,
                paymentschedulesddlist: action.payload,
                loading: false,
                error: false                
            }
        case PAYMENTSCHEDULE_DDLIST_ERROR:
            return {
                ...state,
                paymentschedulesddlist: [],
                loading: false,
                error: true,
                paymentschedule: {}
            }
        case PAYMENTSCHEDULE_GET:
            return {
                ...state,
                loading: true
            }
        case PAYMENTSCHEDULE_GET_SUCCESS:
            return {
                ...state,
                paymentschedule: action.payload,
                loading: false,
                error: false
            }
        case PAYMENTSCHEDULE_GET_ERROR:
            return {
                ...state,
                error: true,
                paymentschedule: {}
            }
        case PAYMENTSCHEDULE_NEW:
            return {
                ...state
            }
        case PAYMENTSCHEDULE_NEW_SUCCESS:
            return {
                ...state,
                paymentschedules: [...state.paymentschedules, action.payload],
                paymentschedule: {},
                error: false
            }
        case PAYMENTSCHEDULE_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case PAYMENTSCHEDULE_NEW_RECORD:
            return {
                ...state,
                paymentschedule: {},
                error: false
            }
        case PAYMENTSCHEDULE_EDIT:
            return {
                ...state
            }
        case PAYMENTSCHEDULE_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                paymentschedule: action.payload,               
                paymentschedules: state.paymentschedules.map(paymentschedule => paymentschedule.id === action.payload.id ? paymentschedule = action.payload : paymentschedule),
            }
        case PAYMENTSCHEDULE_EDIT_ERROR:
            return {
                ...state,
                error: false
            }
        default:
            return state;
    }
}