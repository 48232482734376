import {
    INCOMING_LIST,
    INCOMING_LIST_SUCCESS,
    INCOMING_LIST_ERROR,
    INCOMING_BYBPARTNER_LIST,
    INCOMING_BYBPARTNER_LIST_SUCCESS,
    INCOMING_BYBPARTNER_LIST_ERROR,
    INCOMING_DDLIST,
    INCOMING_DDLIST_SUCCESS,
    INCOMING_DDLIST_ERROR,
    INCOMING_GET,
    INCOMING_GET_SUCCESS,
    INCOMING_GET_ERROR,
    INCOMING_NEW,
    INCOMING_NEW_SUCCESS,
    INCOMING_NEW_ERROR,
    INCOMING_NEW_RECORD,
    INCOMING_EDIT,
    INCOMING_EDIT_SUCCESS,
    INCOMING_EDIT_ERROR
} from '../types';

const initialState = {
    incomingList: [],
    incomingbypartnerList: [],
    incoming: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case INCOMING_LIST:
            return {
                ...state,
                loading: true,
                incoming: {}
            }
        case INCOMING_LIST_SUCCESS:
            return {
                ...state,
                incomingList: action.payload,
                loading: false,
                error: false,
                incoming: {}
            }
        case INCOMING_LIST_ERROR:
            return {
                ...state,
                incomingList: [],
                loading: false,
                error: true,
                incoming: {}
        }
        case INCOMING_BYBPARTNER_LIST:
            return {
                ...state,
                loading: true,
                incoming: {}
            }
        case INCOMING_BYBPARTNER_LIST_SUCCESS:
            return {
                ...state,
                incomingbypartnerList: action.payload,
                loading: false,
                error: false,                
            }
        case INCOMING_BYBPARTNER_LIST_ERROR:
            return {
                ...state,
                incomingbypartnerList: [],
                loading: false,
                error: true,               
            }
        case INCOMING_DDLIST:
            return {
                ...state,
                loading: true,
                incoming: {}
            }
        case INCOMING_DDLIST_SUCCESS:
            return {
                ...state,
                incomingList: action.payload,
                loading: false,
                error: false,
                incoming: {}
            }
        case INCOMING_DDLIST_ERROR:
            return {
                ...state,
                incomingList: [],
                loading: false,
                error: true,
                incoming: {}
            }
        case INCOMING_GET:
            return {
                ...state,
                loading: true
            }
        case INCOMING_GET_SUCCESS:
            return {
                ...state,
                incoming: action.payload,
                loading: false,
                error: false
            }
        case INCOMING_GET_ERROR:
            return {
                ...state,
                error: true,
                incoming: {}
            }
        case INCOMING_NEW:
            return {
                ...state
            }
        case INCOMING_NEW_SUCCESS:
            return {
                ...state,
                incomingList: [...state.incomingList, action.payload],
                incoming: {},
                error: false
            }
        case INCOMING_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case INCOMING_NEW_RECORD:
            return {
                ...state,
                incoming: {},
                error: false
            }
        case INCOMING_EDIT:
            return {
                ...state
            }
        case INCOMING_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                incoming: action.payload,
                incomingList: state.incomingList.map(incoming => incoming.id === action.payload.id ? incoming = action.payload : incoming),
            }
        case INCOMING_EDIT_ERROR:
            return {
                ...state,
                error: false
            }
        default:
            return state;
    }
}
