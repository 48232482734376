import {
    MATERIALREQUESTDETAIL_LIST,
    MATERIALREQUESTDETAIL_LIST_SUCCESS,
    MATERIALREQUESTDETAIL_LIST_ERROR,
    MATERIALREQUESTDETAIL_GET,
    MATERIALREQUESTDETAIL_GET_SUCCESS,
    MATERIALREQUESTDETAIL_GET_ERROR,
    MATERIALREQUESTDETAIL_NEW,
    MATERIALREQUESTDETAIL_NEW_SUCCESS,
    MATERIALREQUESTDETAIL_NEW_ERROR,
    MATERIALREQUESTDETAIL_NEW_RECORD,
    MATERIALREQUESTDETAIL_EDIT,
    MATERIALREQUESTDETAIL_EDIT_SUCCESS,
    MATERIALREQUESTDETAIL_EDIT_ERROR,
    MATERIALREQUEST_RUBBER_AND_COMPONENTS_DD_LIST,
    MATERIALREQUEST_RUBBER_AND_COMPONENTS_DD_LIST_SUCCESS,
    MATERIALREQUEST_RUBBER_AND_COMPONENTS_DD_LIST_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de MaterialRequestDetail
export function MaterialRequestDetailListAction(id){    
    return (dispatch =>{
        dispatch(MaterialRequestDetailList());
        AxiosClient.get(`${process.env.REACT_APP_MATERIALREQUESTDETAIL}/list/${id}`)
            .then((response)=> {               
                dispatch( MaterialRequestDetailListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( MaterialRequestDetailListError(error) );
            });
    });
}

export const MaterialRequestDetailList = () => ({
    type: MATERIALREQUESTDETAIL_LIST
});

export const MaterialRequestDetailListSuccess = (records) => ({
    type: MATERIALREQUESTDETAIL_LIST_SUCCESS,
    payload: (records===null?[]:records)
});

export const MaterialRequestDetailListError = (error) => ({
    type: MATERIALREQUESTDETAIL_LIST_ERROR,
    payload: error
});

// Función Principal para obtener un MaterialRequestDetail
export function MaterialRequestDetailGetAction(id){
    return (dispatch =>{
        dispatch(MaterialRequestDetailGet());
        AxiosClient.get(`${process.env.REACT_APP_MATERIALREQUESTDETAIL}/${id}`)
            .then((response)=> {
                dispatch( MaterialRequestDetailGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( MaterialRequestDetailGetError(error) );
            });
    });
}

export const MaterialRequestDetailGet = () => ({
    type: MATERIALREQUESTDETAIL_GET
});

export const MaterialRequestDetailGetSuccess = (record) => ({
    type: MATERIALREQUESTDETAIL_GET_SUCCESS,
    payload: record
});

export const MaterialRequestDetailGetError = (error) => ({
    type: MATERIALREQUESTDETAIL_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de MaterialRequestDetail cuando se va crear un registro nuevo
export function MaterialRequestDetailNewAction(){
    return (dispatch =>{
            dispatch(MaterialRequestDetailNewRecord());
    });
}

export const MaterialRequestDetailNewRecord = () => ({
    type: MATERIALREQUESTDETAIL_NEW_RECORD
});

// Función Principal para crear y editar registros de MaterialRequestDetail
export function MaterialRequestDetailSaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(MaterialRequestDetailEdit());
        } else{
            dispatch(MaterialRequestDetailNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_MATERIALREQUESTDETAIL}`, record)
                .then((response)=> {
                    dispatch( MaterialRequestDetailNewSuccess(response.data.result));
                })
                .catch(error=> {
                    dispatch( MaterialRequestDetailNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_MATERIALREQUESTDETAIL}/${record.id}`, record)
                .then((response)=> {
                    dispatch( MaterialRequestDetailEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( MaterialRequestDetailEditError(true) );
                });
        }
    });
}

export const MaterialRequestDetailNew = () => ({
    type: MATERIALREQUESTDETAIL_NEW
});

export const MaterialRequestDetailNewSuccess = (record) => ({
    type: MATERIALREQUESTDETAIL_NEW_SUCCESS,
    payload: record
});

export const MaterialRequestDetailNewError = (error) => ({
    type: MATERIALREQUESTDETAIL_NEW_ERROR,
    payload: error
});

export const MaterialRequestDetailEdit = () => ({
    type: MATERIALREQUESTDETAIL_EDIT
});

export const MaterialRequestDetailEditSuccess = (record) => ({
    type: MATERIALREQUESTDETAIL_EDIT_SUCCESS,
    payload: record
});

export const MaterialRequestDetailEditError = (error) => ({
    type: MATERIALREQUESTDETAIL_EDIT_ERROR,
    payload: error
});

// Material Request Rubber and Components DD List

export function MaterialRequestRubberAndComponentsDDListAction(mr_id) {
    return (dispatch => {
        dispatch(MaterialRequestRubberAndComponentsDDList());
        AxiosClient.get(`${process.env.REACT_APP_MATERIALREQUESTDETAIL}/rubberandcomponents/bymaterialrequest/ddlist/${mr_id}`)
            .then((response) => {
                // console.log('Response Data', response);
                dispatch( MaterialRequestRubberAndComponentsDDListSuccess(response.data.result));
            })
            .catch(error => {
                dispatch( MaterialRequestRubberAndComponentsDDListError());
            });
    });
};

export const MaterialRequestRubberAndComponentsDDList = () => {
    return {
        type: MATERIALREQUEST_RUBBER_AND_COMPONENTS_DD_LIST
    };
};

export const MaterialRequestRubberAndComponentsDDListSuccess = (records) => {
    return {
        type: MATERIALREQUEST_RUBBER_AND_COMPONENTS_DD_LIST_SUCCESS,
        payload: records
    };
};

export const MaterialRequestRubberAndComponentsDDListError = (error) => {
    return {
        type: MATERIALREQUEST_RUBBER_AND_COMPONENTS_DD_LIST_ERROR,
        payload: error
    };
};
