import {
    ATTACHTYPE_LIST,
    ATTACHTYPE_LIST_SUCCESS,
    ATTACHTYPE_LIST_ERROR,
    ATTACHTYPE_DDLIST,
    ATTACHTYPE_DDLIST_SUCCESS,
    ATTACHTYPE_DDLIST_ERROR,
    ATTACHTYPE_GET,
    ATTACHTYPE_GET_SUCCESS,
    ATTACHTYPE_GET_ERROR,
    ATTACHTYPE_NEW,
    ATTACHTYPE_NEW_SUCCESS,
    ATTACHTYPE_NEW_ERROR,
    ATTACHTYPE_NEW_RECORD,
    ATTACHTYPE_EDIT,
    ATTACHTYPE_EDIT_SUCCESS,
    ATTACHTYPE_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Unidades de Medida
export function AttachTypeListAction(){
    
    return (dispatch =>{
        dispatch(AttachTypeList());
        AxiosClient.get(`${process.env.REACT_APP_ATTACHTYPE}`)
            .then((response)=> {
                dispatch(AttachTypeListSuccess(response.data.result));
            })
            .catch(error=> {
                console.log(error);
                dispatch( AttachTypeListError(error) );
            });
    });
}

export const AttachTypeList = () => ({
    type: ATTACHTYPE_LIST
});

export const AttachTypeListSuccess = (records) => ({
    type: ATTACHTYPE_LIST_SUCCESS,
    payload: records
});

export const AttachTypeListError = (error) => ({
    type: ATTACHTYPE_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Unidad de Medida
export function AttachTypeDDListAction(id){
    
    return (dispatch =>{
        dispatch(AttachTypeDDList());
        AxiosClient.get(`${process.env.REACT_APP_ATTACHTYPE}/ddlist/${id}`)
            .then((response)=> {
                dispatch( AttachTypeDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( AttachTypeDDListError(error) );
            });
    });
}

export const AttachTypeDDList = () => ({
    type: ATTACHTYPE_DDLIST
});

export const AttachTypeDDListSuccess = (records) => ({
    type: ATTACHTYPE_DDLIST_SUCCESS,
    payload: records
});

export const AttachTypeDDListError = (error) => ({
    type: ATTACHTYPE_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener una Unidad de Medida por ID
export function AttachTypeGetAction(id){
    return (dispatch =>{
        dispatch(AttachTypeGet());
        AxiosClient.get(`${process.env.REACT_APP_ATTACHTYPE}/${id}`)
            .then((response)=> {
                dispatch( AttachTypeGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( AttachTypeGetError(error) );
            });
    });
}

export const AttachTypeGet = () => ({
    type: ATTACHTYPE_GET
});

export const AttachTypeGetSuccess = (record) => ({
    type: ATTACHTYPE_GET_SUCCESS,
    payload: record
});

export const AttachTypeGetError = (error) => ({
    type: ATTACHTYPE_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Unidad de Medida cuando se va crear un registro nuevo
export function AttachTypeNewAction(){
    return (dispatch =>{
            dispatch(AttachTypeNewRecord());
    });
}

export const AttachTypeNewRecord = () => ({
    type: ATTACHTYPE_NEW_RECORD
});

// Función Principal para crear y editar registros de Unidades de Medida
export function AttachTypeSaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(AttachTypeEdit());
        } else{
            dispatch(AttachTypeNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_ATTACHTYPE}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( AttachTypeNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( AttachTypeNewError(record) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_ATTACHTYPE}/${record.id}`, record)
                .then((response)=> {
                    dispatch( AttachTypeEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( AttachTypeEditError(record) );
                });
        }
    });
}

export const AttachTypeNew = () => ({
    type: ATTACHTYPE_NEW
});

export const AttachTypeNewSuccess = (record) => ({
    type: ATTACHTYPE_NEW_SUCCESS,
    payload: record
});

export const AttachTypeNewError = (error) => ({
    type: ATTACHTYPE_NEW_ERROR,
    payload: error
});

export const AttachTypeEdit = () => ({
    type: ATTACHTYPE_EDIT
});

export const AttachTypeEditSuccess = (record) => ({
    type: ATTACHTYPE_EDIT_SUCCESS,
    payload: record
});

export const AttachTypeEditError = (error) => ({
    type: ATTACHTYPE_EDIT_ERROR,
    payload: error
});
