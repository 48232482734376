import {
    ATTACHTYPE_LIST,
    ATTACHTYPE_LIST_SUCCESS,
    ATTACHTYPE_LIST_ERROR,
    ATTACHTYPE_DDLIST,
    ATTACHTYPE_DDLIST_SUCCESS,
    ATTACHTYPE_DDLIST_ERROR,
    ATTACHTYPE_GET,
    ATTACHTYPE_GET_SUCCESS,
    ATTACHTYPE_GET_ERROR,
    ATTACHTYPE_NEW,
    ATTACHTYPE_NEW_SUCCESS,
    ATTACHTYPE_NEW_ERROR,
    ATTACHTYPE_NEW_RECORD,
    ATTACHTYPE_EDIT,
    ATTACHTYPE_EDIT_SUCCESS,
    ATTACHTYPE_EDIT_ERROR
} from '../types';

const initialState = {
    attachtypes: [],
    attachtype: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case ATTACHTYPE_LIST:
            return {
                ...state,
                loading: true,
                attachtype: {}
            }
        case ATTACHTYPE_LIST_SUCCESS:
            return {
                ...state,
                attachtypes: action.payload,
                loading: false,
                error: false,
                attachtype: {}
            }
        case ATTACHTYPE_LIST_ERROR:
            return {
                ...state,
                attachtypes: [],
                loading: false,
                error: true,
                attachtype: {}
            }
        case ATTACHTYPE_DDLIST:
            return {
                ...state,
                loading: true,
                attachtype: {}
            }
        case ATTACHTYPE_DDLIST_SUCCESS:
            return {
                ...state,
                attachtypes: action.payload,
                loading: false,
                error: false,
                attachtype: {}
            }
        case ATTACHTYPE_DDLIST_ERROR:
            return {
                ...state,
                attachtypes: [],
                loading: false,
                error: true,
                attachtype: {}
            }
        case ATTACHTYPE_GET:
            return {
                ...state,
                loading: true
            }
        case ATTACHTYPE_GET_SUCCESS:
            return {
                ...state,
                attachtype: action.payload,
                loading: false,
                error: false
            }
        case ATTACHTYPE_GET_ERROR:
            return {
                ...state,
                error: true,
                attachtype: {}
            }
        case ATTACHTYPE_NEW:
            return {
                ...state,
                loading: true,
            }
        case ATTACHTYPE_NEW_SUCCESS:
            sessionStorage.setItem('error', false);
            return {
                ...state,
                attachtypes: [...state.attachtypes, action.payload],
                attachtype: {},
                loading: false,
                error: false
            }
        case ATTACHTYPE_NEW_ERROR:
            sessionStorage.setItem('error', true);
            return {
                ...state,
                attachtype: action.payload,
                loading: false,
                error: true
            }
        case ATTACHTYPE_NEW_RECORD:
            return {
                ...state,
                attachtype: {},
                error: false
            }
        case ATTACHTYPE_EDIT:
            return {
                ...state
            }
        case ATTACHTYPE_EDIT_SUCCESS:
            sessionStorage.setItem('error', false);
            return {
                ...state,
                error: false,
                attachtype: action.payload,
                attachtypes: state.attachtypes.map(attachtype => attachtype.id === action.payload.id ? attachtype = action.payload : attachtype),
            }
        case ATTACHTYPE_EDIT_ERROR:
            sessionStorage.setItem('error', true);
            return {
                ...state,
                attachtype: action.payload,
                error: false
            }
        default:
            return state;
    }
}
