import {
    PRIORITY_BY_PROCESS_LIST,
    PRIORITY_BY_PROCESS_LIST_SUCCESS,
    PRIORITY_BY_PROCESS_LIST_ERROR,
    PRIORITY_BY_PROCESS_DDLIST,
    PRIORITY_BY_PROCESS_DDLIST_SUCCESS,
    PRIORITY_BY_PROCESS_DDLIST_ERROR,
    PRIORITY_BY_PROCESS_GET,
    PRIORITY_BY_PROCESS_GET_SUCCESS,
    PRIORITY_BY_PROCESS_GET_ERROR,
    PRIORITY_BY_PROCESS_NEW,
    PRIORITY_BY_PROCESS_NEW_SUCCESS,
    PRIORITY_BY_PROCESS_NEW_ERROR,
    PRIORITY_BY_PROCESS_NEW_RECORD,
    PRIORITY_BY_PROCESS_EDIT,
    PRIORITY_BY_PROCESS_EDIT_SUCCESS,
    PRIORITY_BY_PROCESS_EDIT_ERROR
} from '../types';

const initialState = {
    prioritybyprocesss: [],
    prioritybyprocess: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case PRIORITY_BY_PROCESS_LIST:
            return {
                ...state,
                loading: true,
                prioritybyprocess: {}
            }
        case PRIORITY_BY_PROCESS_LIST_SUCCESS:
            return {
                ...state,
                prioritybyprocesss: action.payload,
                loading: false,
                error: false,
                prioritybyprocess: {}
            }
        case PRIORITY_BY_PROCESS_LIST_ERROR:
            return {
                ...state,
                prioritybyprocesss: [],
                loading: false,
                error: true,
                prioritybyprocess: {}
            }
        case PRIORITY_BY_PROCESS_DDLIST:
            return {
                ...state,
                loading: true,
                prioritybyprocess: {}
            }
        case PRIORITY_BY_PROCESS_DDLIST_SUCCESS:
            return {
                ...state,
                prioritybyprocessList: action.payload,
                loading: false,
                error: false,
                prioritybyprocess: {}
            }
        case PRIORITY_BY_PROCESS_DDLIST_ERROR:
            return {
                ...state,
                prioritybyprocessList: [],
                loading: false,
                error: true,
                prioritybyprocess: {}
            }
        case PRIORITY_BY_PROCESS_GET:
            return {
                ...state,
                loading: true
            }
        case PRIORITY_BY_PROCESS_GET_SUCCESS:
            return {
                ...state,
                prioritybyprocess: action.payload,
                loading: false,
                error: false
            }
        case PRIORITY_BY_PROCESS_GET_ERROR:
            return {
                ...state,
                error: true,
                prioritybyprocess: {}
            }
        case PRIORITY_BY_PROCESS_NEW:
            return {
                ...state
            }
        case PRIORITY_BY_PROCESS_NEW_SUCCESS:
            return {
                ...state,
                prioritybyprocesss: [...state.prioritybyprocesss, action.payload],
                prioritybyprocess: {},
                error: false
            }
        case PRIORITY_BY_PROCESS_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case PRIORITY_BY_PROCESS_NEW_RECORD:
            return {
                ...state,
                prioritybyprocess: {},
                error: false
            }
        case PRIORITY_BY_PROCESS_EDIT:
            return {
                ...state
            }
        case PRIORITY_BY_PROCESS_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                prioritybyprocess: action.payload,
                prioritybyprocesss: state.prioritybyprocesss.map(prioritybyprocess => prioritybyprocess.id === action.payload.id ? prioritybyprocess = action.payload : prioritybyprocess),
            }
        case PRIORITY_BY_PROCESS_EDIT_ERROR:
            return {
                ...state,
                error: false
            }
        default:
            return state;
    }
}
