import {
    ENTITYMDBYENTITY_LIST,
    ENTITYMDBYENTITY_LIST_SUCCESS,
    ENTITYMDBYENTITY_LIST_ERROR,
    ENTITYMD_LIST,
    ENTITYMD_LIST_SUCCESS,
    ENTITYMD_LIST_ERROR,
    ENTITYMD_DDLIST,
    ENTITYMD_DDLIST_SUCCESS,
    ENTITYMD_DDLIST_ERROR,
    ENTITYMD_GET,
    ENTITYMD_GET_SUCCESS,
    ENTITYMD_GET_ERROR,
    ENTITYMD_NEW,
    ENTITYMD_NEW_SUCCESS,
    ENTITYMD_NEW_ERROR,
    ENTITYMD_NEW_RECORD,
    ENTITYMD_EDIT,
    ENTITYMD_EDIT_SUCCESS,
    ENTITYMD_EDIT_ERROR, 
    ENTITYMD_BYCODE_SUCCESS, 
    ENTITYMD_BYCODE_ERROR,
    ENTITYMDBYENTITYCODE_DDLIST_SUCCESS
} from '../types';

//const axios = require('axios');
import AxiosClient from '../../config/AxiosClient';
require('dotenv').config();



//Función Principal para obtener lista de EntityMd por Entidad
export function EntityMdByEntityListAction(entity_id){
    return (dispatch =>{
        dispatch(EntityMdByEntityList());
        AxiosClient.get(`${process.env.REACT_APP_ENTITYMD}/list/${entity_id}`)
            .then((response)=> {
                dispatch( EntityMdListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( EntityMdListError(error) );
            });
    });
}

export const EntityMdByEntityList = () => ({
    type: ENTITYMDBYENTITY_LIST
});

export const EntityMdByEntityListSuccess = (records) => ({
    type: ENTITYMDBYENTITY_LIST_SUCCESS,
    payload: records
});

export const EntityMdByEntityListError = (error) => ({
    type: ENTITYMDBYENTITY_LIST_ERROR,
    payload: error
});

// Función Principal para obtener lista de EntityMd
export function EntityMdListAction(){
    return (dispatch =>{
        dispatch(EntityMdList());
        AxiosClient.get(`${process.env.REACT_APP_ENTITYMD}/all`)
            .then((response)=> {
                dispatch( EntityMdListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( EntityMdListError(error) );
            });
    });
}

export const EntityMdList = () => ({
    type: ENTITYMD_LIST
});

export const EntityMdListSuccess = (records) => ({
    type: ENTITYMD_LIST_SUCCESS,
    payload: records
});

export const EntityMdListError = (error) => ({
    type: ENTITYMD_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Master Data por Entidad en forma de DropDown
export const EntityMdDDListAction = (entity_id) =>  async (dispatch) => {
        dispatch(EntityMdDDList());
        await AxiosClient.get(`${process.env.REACT_APP_ENTITYMD}/ddlist/${entity_id}`)
            .then((response)=> {
                dispatch( EntityMdDDListSuccess(response.data.result, entity_id));
            })
            .catch(error=> {
                dispatch( EntityMdDDListError(error) );
            });
}

export const EntityMdByCodeAction =  (code) => async (dispatch) => {
        dispatch(EntityMdDDList());
        await AxiosClient.get(`${process.env.REACT_APP_ENTITYMD}/getbycode/${code}`)
            .then((response)=> {
                dispatch( EntityMdByCodeSuccess(response.data.result, code));
            })
            .catch(error=> {
                dispatch( EntityMdByCodeError(error) );
            });
};

export const EntityMdByEntityCodeDDListAction = (entity_code) => async dispatch => {
    dispatch(EntityMdDDList());
    await AxiosClient.get(`${process.env.REACT_APP_ENTITYMD}/ddlist/code/${entity_code}`)
        .then((response)=> {
            dispatch( EntityMdByEntityCodeDDListSuccess(response.data.result, entity_code));
        })
        .catch(error=> {
            dispatch( EntityMdDDListError(error) );
        });
};

export const EntityMdDDList = () => ({
    type: ENTITYMD_DDLIST
});

export const EntityMdDDListSuccess = (records, entity_id) => ({
    type: ENTITYMD_DDLIST_SUCCESS,
    payload: records,
    id: entity_id
});

export const EntityMdDDListError = (error) => ({
    type: ENTITYMD_DDLIST_ERROR,
    payload: error
});

export const EntityMdByCodeSuccess = (records, entity_id) => ({
    type: ENTITYMD_BYCODE_SUCCESS,
    payload: records,
    id: entity_id
});

export const EntityMdByCodeError = (error) => ({
    type: ENTITYMD_BYCODE_ERROR,
    payload: error
});

export const EntityMdByEntityCodeDDListSuccess = (records, entity_code) => ({
    type: ENTITYMDBYENTITYCODE_DDLIST_SUCCESS,
    payload: records,
    entitycode: entity_code
});

// Función Principal para obtener un EntityMd
export function EntityMdGetAction(id = null){
    return (dispatch =>{
        dispatch(EntityMdGet());
        if(!id) return;
        AxiosClient.get(`${process.env.REACT_APP_ENTITYMD}/${id}`)
            .then((response)=> {
                dispatch( EntityMdGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( EntityMdGetError(error) );
            });
    });
}

export const EntityMdGet = () => ({
    type: ENTITYMD_GET
});

export const EntityMdGetSuccess = (record) => ({
    type: ENTITYMD_GET_SUCCESS,
    payload: record
});

export const EntityMdGetError = (error) => ({
    type: ENTITYMD_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de EntityMd cuando se va crear un registro nuevo
export function EntityMdNewAction(){
    return (dispatch =>{
            dispatch(EntityMdNewRecord());
    });
}

export const EntityMdNewRecord = () => ({
    type: ENTITYMD_NEW_RECORD
});

// Función Principal para crear y editar registros de EntityMd
export function EntityMdSaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(EntityMdEdit());
        } else{
            dispatch(EntityMdNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_ENTITYMD}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( EntityMdNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( EntityMdNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_ENTITYMD}/${record.id}`, record)
                .then((response)=> {
                    dispatch( EntityMdEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( EntityMdEditError(true) );
                });
        }
    });
}


export const EntityMdSaveV2Action = (record, isNew) => async (dispatch) => {
        if(!isNew) {
            dispatch(EntityMdEdit());
        } else{
            dispatch(EntityMdNew());
        }
        if(isNew){
            const response = await AxiosClient.post(`${process.env.REACT_APP_ENTITYMD}`, record).catch((error) => {
                dispatch(EntityMdNewError(error));
            });
            if (response) {
                dispatch(EntityMdNewSuccess( (response.data.result ? response.data.result : {}) ));
                dispatch(EntityMdGetSuccess( (response.data.result ? response.data.result : {}) ));

                return response.data;
            } else {
                return {};
            }
        }else{
            const response = await AxiosClient.put(`${process.env.REACT_APP_ENTITYMD}/${record.id}`, record).catch((error) => {
                dispatch(EntityMdNewError(error));
            });
            if (response) {
                dispatch(EntityMdEditSuccess( (response.data.result ? response.data.result : {}) ));
                dispatch(EntityMdGetSuccess( (response.data.result ? response.data.result : {}) ));

                return response.data;
            } else {
                return {};
            }
        }
}





export const EntityMdNew = () => ({
    type: ENTITYMD_NEW
});

export const EntityMdNewSuccess = (record) => ({
    type: ENTITYMD_NEW_SUCCESS,
    payload: record
});

export const EntityMdNewError = (error) => ({
    type: ENTITYMD_NEW_ERROR,
    payload: error
});

export const EntityMdEdit = () => ({
    type: ENTITYMD_EDIT
});

export const EntityMdEditSuccess = (record) => ({
    type: ENTITYMD_EDIT_SUCCESS,
    payload: record
});

export const EntityMdEditError = (error) => ({
    type: ENTITYMD_EDIT_ERROR,
    payload: error
});
