import {
    ITEMINVENTORY_DDLIST,
    ITEMINVENTORY_DDLIST_ERROR,
    ITEMINVENTORY_DDLIST_SUCCESS,
    ITEMINVENTORY_EDIT,
    ITEMINVENTORY_EDIT_ERROR,
    ITEMINVENTORY_EDIT_SUCCESS,
    ITEMINVENTORY_GET,
    ITEMINVENTORY_GET_ERROR,
    ITEMINVENTORY_GET_SUCCESS,
    ITEMINVENTORY_LIST,
    ITEMINVENTORY_LIST_ERROR,
    ITEMINVENTORY_LIST_SUCCESS,
    ITEMINVENTORY_LIST_TOLOCATE,
    ITEMINVENTORY_LIST_TOLOCATE_ERROR,
    ITEMINVENTORY_LIST_TOLOCATE_SUCCESS,
    ITEMINVENTORY_NEW,
    ITEMINVENTORY_NEW_ERROR,
    ITEMINVENTORY_NEW_RECORD,
    ITEMINVENTORY_NEW_SUCCESS,
    ITEMINVENTORY_WAITING_FOR_INSPECTION_LIST,
    ITEMINVENTORY_WAITING_FOR_INSPECTION_LIST_ERROR,
    ITEMINVENTORY_WAITING_FOR_INSPECTION_LIST_SUCCESS,
    ITEMINVENTORY_WARRANTY_LIST,
    ITEMINVENTORY_WARRANTY_LIST_ERROR,
    ITEMINVENTORY_WARRANTY_LIST_SUCCESS,
    ITEMINVENTORYTOLOCATE_EDIT,
    ITEMINVENTORYTOLOCATE_EDIT_ERROR,
    ITEMINVENTORYTOLOCATE_EDIT_SUCCESS,
    ITEMINVENTORYTOLOCATE_NEW,
    ITEMINVENTORYTOLOCATE_NEW_ERROR,
    ITEMINVENTORYTOLOCATE_NEW_RECORD,
    ITEMINVENTORYTOLOCATE_NEW_SUCCESS,
    ITEMINVENTORY_DDLISTVERYIMPORTANT,
    ITEMINVENTORY_DDLISTVERYIMPORTANT_ERROR,
    ITEMINVENTORY_DDLISTVERYIMPORTANT_SUCCESS,
    ITEMINVENTORY_MATERIAL_REMOVED_WI_LIST,
    ITEMINVENTORY_MATERIAL_REMOVED_WI_LIST_ERROR,
    ITEMINVENTORY_MATERIAL_REMOVED_WI_LIST_SUCCESS,
    ITEMINVENTORY_INSTALLATION_AND_REMOVAL_DDLIST,
    ITEMINVENTORY_INSTALLATION_AND_REMOVAL_DDLIST_ERROR,
    ITEMINVENTORY_INSTALLATION_AND_REMOVAL_DDLIST_SUCCESS,
    ITEMINVENTORY_ACCEPT_GET,
    ITEMINVENTORY_ACCEPT_GET_ERROR,
    ITEMINVENTORY_ACCEPT_GET_SUCCESS,
    } from "../types";


import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de ItemInventory
export function ItemInventoryListAction() {

    return (dispatch => {
        dispatch(ItemInventoryList());
        AxiosClient.get(`${process.env.REACT_APP_ITEMINVENTORY}/all`)
            .then((response) => {
                dispatch(ItemInventoryListSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(ItemInventoryListError(error));
            });
    });
}

export function ItemInventoryLiteListAction() {

    return (dispatch => {
        dispatch(ItemInventoryList());
        AxiosClient.get(`${process.env.REACT_APP_ITEMINVENTORY}/lite/all`)
            .then((response) => {
                dispatch(ItemInventoryListSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(ItemInventoryListError(error));
            });
    });
}

export const ItemInventoryList = () => ({
    type: ITEMINVENTORY_LIST
});

export const ItemInventoryListSuccess = (records) => ({
    type: ITEMINVENTORY_LIST_SUCCESS,
    payload: records
});

export const ItemInventoryListError = (error) => ({
    type: ITEMINVENTORY_LIST_ERROR,
    payload: error
});

// Función Principal para obtener lista de ItemInventory Disponibles para Solicitudes de Materiales
export function ItemInventoryAvailableListAction() {

    return (dispatch => {
        dispatch(ItemInventoryList());
        AxiosClient.get(`${process.env.REACT_APP_ITEMINVENTORY}/available/all`)
            .then((response) => {
                dispatch(ItemInventoryListSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(ItemInventoryListError(error));
            });
    });
}

export function ItemInventoryAvailableToMaterialRequestListAction( airplane_id ) {

    return (dispatch => {
        dispatch(ItemInventoryList());
        AxiosClient.get(`${process.env.REACT_APP_ITEMINVENTORY}/availabletomaterialrequest/${airplane_id}`)
            .then((response) => {
                dispatch(ItemInventoryListSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(ItemInventoryListError(error));
            });
    });
}

export const ItemInventoryAvailableList = () => ({
    type: ITEMINVENTORY_LIST
});

export const ItemInventoryAvailableListSuccess = (records) => ({
    type: ITEMINVENTORY_LIST_SUCCESS,
    payload: records
});

export const ItemInventoryAvailableListError = (error) => ({
    type: ITEMINVENTORY_LIST_ERROR,
    payload: error
});

// Función Principal para obtener lista de ItemInventory pendientes por Ubicacion
export function ItemInventoryListActionToLocate() {

    return (dispatch => {
        dispatch(ItemInventoryListToLocate());
        AxiosClient.get(`${process.env.REACT_APP_ITEMINVENTORY}/alltolocate`)
            .then((response) => {
                dispatch(ItemInventoryListToLocateSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(ItemInventoryListToLocateError(error));
            });
    });
}

export const ItemInventoryListToLocate = () => ({
    type: ITEMINVENTORY_LIST_TOLOCATE
});

export const ItemInventoryListToLocateSuccess = (records) => ({
    type: ITEMINVENTORY_LIST_TOLOCATE_SUCCESS,
    payload: records
});

export const ItemInventoryListToLocateError = (error) => ({
    type: ITEMINVENTORY_LIST_TOLOCATE_ERROR,
    payload: error
});

// Función Principal para obtener Master Data por Entidad en forma de DropDown
export function ItemInventoryDDListAction(entity_id) {

    return (dispatch => {
        dispatch(ItemInventoryDDList());
        AxiosClient.get(`${process.env.REACT_APP_ITEMINVENTORY}/ddlist`)
            .then((response) => {
                dispatch(ItemInventoryDDListSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(ItemInventoryDDListError(error));
            });
    });
}

// Función Principal para obtener Master Data por Entidad en forma de DropDown
export const ItemInventoryWaitingForInspectionListAction = (validation = false) => (async (dispatch) => {
    dispatch(ItemInventoryWaitingForInspectionList());
    await AxiosClient.get(`${process.env.REACT_APP_ITEMINVENTORY}/allwaitforinspection/${validation}`)
        .then((response) => {
            dispatch(ItemInventoryWaitingForInspectionListSuccess(response.data.result));
        })
        .catch(error => {
            dispatch(ItemInventoryWaitingForInspectionListError(error));
        });
});

// Función Principal para obtener Master Data por Entidad en forma de DropDown
export const ItemInventoryWarrantyListAction = (list) => async (dispatch) => {
    dispatch(ItemInventoryWarrantyList());
    if(list && list.length) dispatch(ItemInventoryWarrantyListSuccess(list));
    else dispatch(ItemInventoryWarrantyListError('list error'))

}

export const ItemInventoryDDList = () => ({
    type: ITEMINVENTORY_DDLIST
});

export const ItemInventoryDDListSuccess = (records) => ({
    type: ITEMINVENTORY_DDLIST_SUCCESS,
    payload: records
});

export const ItemInventoryDDListError = (error) => ({
    type: ITEMINVENTORY_DDLIST_ERROR,
    payload: error
});

export const ItemInventoryWaitingForInspectionList = () => ({
    type: ITEMINVENTORY_WAITING_FOR_INSPECTION_LIST
});

export const ItemInventoryWaitingForInspectionListSuccess = (records) => ({
    type: ITEMINVENTORY_WAITING_FOR_INSPECTION_LIST_SUCCESS,
    payload: records
});

export const ItemInventoryWaitingForInspectionListError = (error) => ({
    type: ITEMINVENTORY_WAITING_FOR_INSPECTION_LIST_ERROR,
    payload: error
});

export const ItemInventoryWarrantyList = () => ({
    type: ITEMINVENTORY_WARRANTY_LIST
});

export const ItemInventoryWarrantyListSuccess = (records) => ({
    type: ITEMINVENTORY_WARRANTY_LIST_SUCCESS,
    payload: records
});

export const ItemInventoryWarrantyListError = (error) => ({
    type: ITEMINVENTORY_WARRANTY_LIST_ERROR,
    payload: error
});
// Función Principal para obtener un ItemInventory
export const ItemInventoryGetAction = (id) => async (dispatch) => {
    dispatch(ItemInventoryGet());
    await AxiosClient.get(`${process.env.REACT_APP_ITEMINVENTORY}/${id}`)
        .then((response) => {
            dispatch(ItemInventoryGetSuccess(response.data.result));
        })
        .catch(error => {
            dispatch(ItemInventoryGetError(error));
        });
}

export const ItemInventoryGet = () => ({
    type: ITEMINVENTORY_GET
});

export const ItemInventoryGetSuccess = (record) => ({
    type: ITEMINVENTORY_GET_SUCCESS,
    payload: record
});

export const ItemInventoryGetError = (error) => ({
    type: ITEMINVENTORY_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de ItemInventory cuando se va crear un registro nuevo
export function ItemInventoryNewAction() {
    return (dispatch => {
        dispatch(ItemInventoryNewRecord());
    });
}

export const ItemInventoryNewRecord = () => ({
    type: ITEMINVENTORY_NEW_RECORD
});

// Función Principal para crear y editar registros de ItemInventory
export const ItemInventorySaveAction = (record, isNew) => async (dispatch) => {

    if (!isNew) {
        dispatch(ItemInventoryEdit());
    } else {
        dispatch(ItemInventoryNew());
    }
    if (isNew) {
        delete record.id
        let response = await AxiosClient.post(`${process.env.REACT_APP_ITEMINVENTORY}`, record).catch(error => {
            dispatch(ItemInventoryNewError(error));
            return {data: {
                    ok: false,
                    error: 'Error al crear iteminventory'
                }};
        });
        if (response) {
            if (response.data.ok) {
                // record.id = response.data.result.id;
                // dispatch(ItemInventoryNewSuccess(record));
                return {
                    ok: true,
                    result: response.data.result,
                    message: response.data.message,
                    warn: response.data.warn,
                };
            } else {
                dispatch(ItemInventoryNewError(response.data.error));
                return {
                    ok: false,
                    error: response.data.error
                };
            }
        } else {
            dispatch(ItemInventoryNewError('Error en creacion de iteminventory'));
            return {
                ok: false,
                error: 'Error en creacion de iteminventory'
            };
        }

    } else {
        let response = await AxiosClient.put(`${process.env.REACT_APP_ITEMINVENTORY}/${record.id}`, record)
            .catch(error => {
                dispatch(ItemInventoryEditError(error));
                return {data: {
                    ok: false,
                    error: 'Error al editar iteminventory'
                }};
            });

        console.log('response', response)

        if (response) {
            if (response.data.ok) {
                dispatch(ItemInventoryEditSuccess(record));
                return {
                    ok: true,
                    result: response.data.result,
                    message: response.data.message,
                    warn: response.data.warn,
                };
            } else {
                dispatch(ItemInventoryEditError(response.data.error));
                return {
                    ok: false,
                    error: response.data.error
                };
            }
        } else {
            dispatch(ItemInventoryEditError('Error en edición de iteminventory'));
            return {
                ok: false,
                error: 'Error en edición de iteminventory'
            };
        }
    }
}

export const ItemInventoryApproveIncomingAction = (record) => async (dispatch) => {
    console.log("estoy en el Actions de ItemInventory ItemInventorySaveAction", record);
    await AxiosClient.put(`${'iteminventoryapprove'}/${record.id}`, record)
        .then((response) => {
            dispatch(ItemInventoryEditSuccess(record));
        })
        .catch(error => {
            dispatch(ItemInventoryEditError(true));
        });
}

export const ItemInventoryChangeStatusAction = (record, code) => async (dispatch) => {
    console.log("estoy en el Actions de ItemInventory ItemInventoryChangeStatusAction", record);
    await AxiosClient.put(`${'iteeminventorychangestatus'}/${record.id}/${code}`, record)
        .then((response) => {
            console.debug(response);
            dispatch(ItemInventoryEditSuccess(record));
        })
        .catch(error => {
            console.error(error);
            dispatch(ItemInventoryEditError(true));
        });
}

export const ItemInventoryNew = () => ({
    type: ITEMINVENTORY_NEW
});

export const ItemInventoryNewSuccess = (record) => ({
    type: ITEMINVENTORY_NEW_SUCCESS,
    payload: record
});

export const ItemInventoryNewError = (error) => ({
    type: ITEMINVENTORY_NEW_ERROR,
    payload: error
});

export const ItemInventoryEdit = () => ({
    type: ITEMINVENTORY_EDIT
});

export const ItemInventoryEditSuccess = (record) => ({
    type: ITEMINVENTORY_EDIT_SUCCESS,
    payload: record
});

export const ItemInventoryEditError = (error) => ({
    type: ITEMINVENTORY_EDIT_ERROR,
    payload: error
});



//Función Principal para setear objeto de ItemInventory cuando se va crear un registro nuevo
export function ItemInventoryToLocateNewAction() {
    return (dispatch => {
        dispatch(ItemInventoryToLocateNewRecord());
    });
}

export const ItemInventoryToLocateNewRecord = () => ({
    type: ITEMINVENTORYTOLOCATE_NEW_RECORD
});

// Función Principal para crear y editar registros de ItemInventory
export const ItemInventoryToLocateSaveAction = (record, isNew) => async (dispatch) => {
        if (!isNew) {
            dispatch(ItemInventoryToLocateEdit());
        } else {
            dispatch(ItemInventoryToLocateNew());
        }
        if (isNew) {
            AxiosClient.post(`${process.env.REACT_APP_ITEMINVENTORY_TOLOCATE}`, record)
                .then((response) => {
                    record.id = response.data.result.id;
                    dispatch(ItemInventoryToLocateNewSuccess(record));
                })
                .catch(error => {
                    dispatch(ItemInventoryToLocateNewError(error));
                });
        } else {
            AxiosClient.put(`${process.env.REACT_APP_ITEMINVENTORY_TOLOCATE}/${record.id}`, record)
                .then((response) => {
                    dispatch(ItemInventoryToLocateEditSuccess(record));
                    return response.data;
                })
                .catch(error => {
                    dispatch(ItemInventoryToLocateEditError(true));
                });
        }
    }

export const ItemInventoryToLocateNew = () => ({
    type: ITEMINVENTORYTOLOCATE_NEW
});

export const ItemInventoryToLocateNewSuccess = (record) => ({
    type: ITEMINVENTORYTOLOCATE_NEW_SUCCESS,
    payload: record
});

export const ItemInventoryToLocateNewError = (error) => ({
    type: ITEMINVENTORYTOLOCATE_NEW_ERROR,
    payload: error,
});

export const ItemInventoryToLocateEdit = () => ({
    type: ITEMINVENTORYTOLOCATE_EDIT,
});

export const ItemInventoryToLocateEditSuccess = (record) => ({
    type: ITEMINVENTORYTOLOCATE_EDIT_SUCCESS,
    payload: record,
});

export const ItemInventoryToLocateEditError = (error) => ({
    type: ITEMINVENTORYTOLOCATE_EDIT_ERROR,
    payload: error,
});


export function DropDownListByItemInventoryVeryImportant(vi) {

    return (dispatch => {
        dispatch(ItemInventoryListDDListVI());
        AxiosClient.get(`${process.env.REACT_APP_ITEMINVENTORY}/vi/dropdownlist/${vi}`)
            .then((response) => {
                dispatch(ItemInventoryListDDListVISuccess(response.data.result));
            })
            .catch(error => {
                dispatch(ItemInventoryListDDListVIError(error));
            });
    });
}

export const ItemInventoryListDDListVI = () => ({
    type: ITEMINVENTORY_DDLISTVERYIMPORTANT
});

export const ItemInventoryListDDListVISuccess = (records) => ({
    type: ITEMINVENTORY_DDLISTVERYIMPORTANT_SUCCESS,
    payload: records
});

export const ItemInventoryListDDListVIError = (error) => ({
    type: ITEMINVENTORY_DDLISTVERYIMPORTANT_ERROR,
    payload: error
});

export const DropDownListOfMaterialRemovedAndWaitingForInpectionAction = () => {
    return (dispatch => {
        dispatch(ItemInventoryListOfMaterialRemovedAndWaitingForInpectionAction());
        AxiosClient.get(`${process.env.REACT_APP_ITEMINVENTORY}/materialremovalandwaitingforinspection/ddlist`)
            .then((response) => {
                dispatch(ItemInventoryListOfMaterialRemovedAndWaitingForInpectionActionSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(ItemInventoryLListOfMaterialRemovedAndWaitingForInpectionActionError(error));
            });
    });
}

export const ItemInventoryListOfMaterialRemovedAndWaitingForInpectionAction = () => ({
    type: ITEMINVENTORY_MATERIAL_REMOVED_WI_LIST
});

export const ItemInventoryListOfMaterialRemovedAndWaitingForInpectionActionSuccess = (records) => ({
    type: ITEMINVENTORY_MATERIAL_REMOVED_WI_LIST_SUCCESS,
    payload: records
});

export const ItemInventoryLListOfMaterialRemovedAndWaitingForInpectionActionError = (error) => ({
    type: ITEMINVENTORY_MATERIAL_REMOVED_WI_LIST_ERROR,
    payload: error
});

// Material Removed With Status Removed and Without Installation Removal

// Material Request DD List

export function MaterialRemovedWithStatusRemovedIRDDListAction() {
    return (dispatch => {
        dispatch(MaterialRemovedWithStatusRemovedIIR());
        AxiosClient.get(`${process.env.REACT_APP_ITEMINVENTORY}/materialremovedwithstatusremovedandwithoutinstallationremoval/ddlist`)
            .then((response) => {
                dispatch(MaterialRemovedWithStatusRemovedIIRSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(MaterialRemovedWithStatusRemovedIIRError());
            });
    });
};

export const MaterialRemovedWithStatusRemovedIIR = () => {
    return {
        type: ITEMINVENTORY_INSTALLATION_AND_REMOVAL_DDLIST
    };
};

export const MaterialRemovedWithStatusRemovedIIRSuccess = (records) => {
    return {
        type: ITEMINVENTORY_INSTALLATION_AND_REMOVAL_DDLIST_SUCCESS,
        payload: records
    };
};

export const MaterialRemovedWithStatusRemovedIIRError = (error) => {
    return {
        type: ITEMINVENTORY_INSTALLATION_AND_REMOVAL_DDLIST_ERROR,
        payload: error
    };
};

export const ItemInventoryGetApproveIncomingAction = (id) => async (dispatch) => {
    dispatch(ItemInventoryGetApproveIncoming());
    await AxiosClient.get(`${'iteminventory/report/iteminventoryapprove'}/${id}`)
        .then((response) => {
            dispatch(ItemInventoryGetApproveIncomingActionSuccess(response.data.result));
        })
        .catch(error => {
            dispatch(ItemInventoryGetApproveIncomingActionError(error));
        });
}

    export const ItemInventoryGetApproveIncoming = () => ({
        type: ITEMINVENTORY_ACCEPT_GET
    });

    export const ItemInventoryGetApproveIncomingActionSuccess = (records) => ({
        type: ITEMINVENTORY_ACCEPT_GET_SUCCESS,
        payload: records
    });

    export const ItemInventoryGetApproveIncomingActionError = (error) => ({
        type: ITEMINVENTORY_ACCEPT_GET_ERROR,
        payload: error
    });