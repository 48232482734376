export const SERVICE_SOURCE = 'OMA';
export const SERVICE_REQUEST_NUMBER = 'Service Order';
export const ASSEMBLED_DATE = 'Assembled Date';
export const MAIN_TAB_TITLE = 'Components Assembled';
export const WINDOW_TITLE = 'Wheel and Rubber Assembly';
export const WINDOW_SUBTITLE = 'Assembly Manager';
export const SECOND_TAB_TITLE = 'Components Detail';
export const TIRE_DESCRIPTION = 'Tire description';
export const WHEELS_RUBBERS_ITEMS = 'Wheels & rubbers';
export const CHECK_21_004_LABEL = '21 004';
export const NON_ROUTINE_REPORT = 'Non-routine report';
export const NDT_LABEL = 'NDT (Non destructive test)';
export const TIRE_DESCRIPTION_TYPES = [
    {
        value: 'Main Tire',
        label: 'Main Tire',
    },
    {
        value: 'Nose Tire',
        label: 'Nose Tire',
    },
];
