import {
    ITEMMASTER_LIST,
    ITEMMASTER_LIST_SUCCESS,
    ITEMMASTER_LIST_ERROR,
    ITEMMASTER_DDLIST,
    ITEMMASTER_DDLIST_SUCCESS,
    ITEMMASTER_DDLIST_ERROR,
    ITEMMASTER_ACTIVE_DDLIST,
    ITEMMASTER_ACTIVE_DDLIST_SUCCESS,
    ITEMMASTER_ACTIVE_DDLIST_ERROR,
    ITEMMASTER_ALTERNATE_DDLIST,
    ITEMMASTER_ALTERNATE_DDLIST_SUCCESS,
    ITEMMASTER_ALTERNATE_DDLIST_ERROR,
    ITEMMASTER_GET,
    ITEMMASTER_GET_SUCCESS,
    ITEMMASTER_GET_ERROR,
    ITEMMASTER_NEW,
    ITEMMASTER_NEW_SUCCESS,
    ITEMMASTER_NEW_ERROR,
    ITEMMASTER_NEW_RECORD,
    ITEMMASTER_DUPLICATE,
    ITEMMASTER_DUPLICATE_SUCCESS,
    ITEMMASTER_DUPLICATE_ERROR,
    ITEMMASTER_EDIT,
    ITEMMASTER_EDIT_SUCCESS,
    ITEMMASTER_EDIT_ERROR,
    ITEMMASTER_ALTER_LIST_SUCCESS,
    ITEMMASTER_ALTER_LIST_ERROR,
    ITEMMASTER_ALTER_LIST
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de ItemMaster
export function ItemMasterListAction(){
    
    return (dispatch =>{
        dispatch(ItemMasterList());
        AxiosClient.get(`${process.env.REACT_APP_ITEMMASTER}/all`)
            .then((response)=> {
                dispatch( ItemMasterListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( ItemMasterListError(error) );
            });
    });
}

export const ItemMasterList = () => ({
    type: ITEMMASTER_LIST
});

export const ItemMasterListSuccess = (records) => ({
    type: ITEMMASTER_LIST_SUCCESS,
    payload: records
});

export const ItemMasterListError = (error) => ({
    type: ITEMMASTER_LIST_ERROR,
    payload: error
});



export function ItemMasterAlterListAction(parentId = 0){
    
    return (dispatch =>{
        dispatch(ItemMasterAlterList());
        AxiosClient.get(`${process.env.REACT_APP_ITEMMASTER}/alters/${parentId}`)
            .then((response)=> {
                const data = response.data.result
                dispatch( ItemMasterAlterListSuccess(data));
            })
            .catch(error=> {
                dispatch( ItemMasterAlterListError(error) );
            });
    });
}

export const ItemMasterAlterList = () => ({
    type: ITEMMASTER_ALTER_LIST
});

export const ItemMasterAlterListSuccess = (records = []) => ({
    type: ITEMMASTER_ALTER_LIST_SUCCESS,
    payload: records
});

export const ItemMasterAlterListError = (error) => ({
    type: ITEMMASTER_ALTER_LIST_ERROR,
    payload: error
});




// Función Principal para obtener Master Data por Entidad en forma de DropDown
export function ItemMasterDDListAction(entity_id){
    
    return (dispatch =>{
        dispatch(ItemMasterDDList());
        AxiosClient.get(`${process.env.REACT_APP_ITEMMASTER}/ddlist`)
            .then((response)=> {
                dispatch( ItemMasterDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( ItemMasterDDListError(error) );
            });
    });
}

export function ItemMasterDDListByCategoryAction(code){
    return (dispatch =>{
        dispatch(ItemMasterDDList());
        AxiosClient.get(`${process.env.REACT_APP_ITEMMASTER}/category/${code}`)
          .then((response)=> {
              dispatch( ItemMasterDDListSuccess(response.data.result));
          })
          .catch(error=> {
              dispatch( ItemMasterDDListError(error) );
          });
    });
}

export const ItemMasterDDList = () => ({
    type: ITEMMASTER_DDLIST
});

export const ItemMasterDDListSuccess = (records) => ({
    type: ITEMMASTER_DDLIST_SUCCESS,
    payload: records
});

export const ItemMasterDDListError = (error) => ({
    type: ITEMMASTER_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener ItemMaster que son principales de forma de DropDown
export function ItemMasterAlternateDDListAction(is_alternate){
    
    return (dispatch =>{
        dispatch(ItemMasterAlternateDDList());
        AxiosClient.get(`${process.env.REACT_APP_ITEMMASTER}/alternate/ddlist/${is_alternate}`)
            .then((response)=> {
                dispatch( ItemMasterAlternateDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( ItemMasterAlternateDDListError(error) );
            });
    });
}

export const ItemMasterAlternateDDList = () => ({
    type: ITEMMASTER_ALTERNATE_DDLIST
});

export const ItemMasterAlternateDDListSuccess = (records) => ({
    type: ITEMMASTER_ALTERNATE_DDLIST_SUCCESS,
    payload: records
});

export const ItemMasterAlternateDDListError = (error) => ({
    type: ITEMMASTER_ALTERNATE_DDLIST_ERROR,
    payload: error
});


// -------------------------------------------------------------------------------------------------------------
// Función Principal para obtener ItemMaster activos en forma de DropDown
// Creado por JAREVALO 25032020
// -------------------------------------------------------------------------------------------------------------
export function ItemMasterActiveDDListAction(){    
    return (dispatch =>{
        dispatch(ItemMasterActiveDDList());
        AxiosClient.get(`${process.env.REACT_APP_ITEMMASTER}/ddlist`)
            .then((response)=> {
                dispatch( ItemMasterActiveDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( ItemMasterActiveDDListError(error) );
            });
    });
}

export const ItemMasterActiveDDList = () => ({
    type: ITEMMASTER_ACTIVE_DDLIST
});

export const ItemMasterActiveDDListSuccess = (records) => ({
    type: ITEMMASTER_ACTIVE_DDLIST_SUCCESS,
    payload: records
});

export const ItemMasterActiveDDListError = (error) => ({
    type: ITEMMASTER_ACTIVE_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener un ItemMaster
export const ItemMasterGetAction = (id, entity_type_id) => async (dispatch) => {
        dispatch(ItemMasterGet());
        await AxiosClient.get(`${process.env.REACT_APP_ITEMMASTER}/getOne/${id}/${entity_type_id}`)
            .then((response)=> {
                dispatch( ItemMasterGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( ItemMasterGetError(error) );
            });
}

export const ItemMasterGet = () => ({
    type: ITEMMASTER_GET
});

export const ItemMasterGetSuccess = (record) => ({
    type: ITEMMASTER_GET_SUCCESS,
    payload: record
});

export const ItemMasterGetError = (error) => ({
    type: ITEMMASTER_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de ItemMaster cuando se va crear un registro nuevo
export function ItemMasterNewAction() {
    return (dispatch) => {
        dispatch(ItemMasterNewRecord());
    };
}

export const ItemMasterNewRecord = () => ({
    type: ITEMMASTER_NEW_RECORD,
});

// Función Principal para crear y editar registros de ItemMaster
export const ItemMasterSaveAction = (record, isNew, entity_type_id) => async (dispatch) => {
    if (!isNew) {
        dispatch(ItemMasterEdit());
    } else {
        dispatch(ItemMasterNew());
    }
    if (isNew) {
        const response = await AxiosClient.post(
            `${process.env.REACT_APP_ITEMMASTER}`,
            record,
        ).catch((error) => {
            console.error(error);
            dispatch(ItemMasterNewError(error));
        });
        if (response && !record.item_master_parent_id) {
            dispatch(
                ItemMasterNewSuccess({ ...record, id: response.data.result.id }, entity_type_id),
            );
            return response.data;
        }
       
    } else {
        const response = await AxiosClient.put(
            `${process.env.REACT_APP_ITEMMASTER}/${record.id}`,
            record,
        ).catch((error) => {
            console.error(error);
            dispatch(ItemMasterEditError(true));
        });
        if (response) {
            dispatch(ItemMasterEditSuccess(response.data.result));
            return response.data;
        } else {
            dispatch(ItemMasterEditError(true));
            return false;
        }
    }
};

export const ItemMasterNew = () => ({
    type: ITEMMASTER_NEW
});

export const ItemMasterNewSuccess = (record) => ({
    type: ITEMMASTER_NEW_SUCCESS,
    payload: record
});

export const ItemMasterNewError = (error) => ({
    type: ITEMMASTER_NEW_ERROR,
    payload: error
});

export const ItemMasterEdit = () => ({
    type: ITEMMASTER_EDIT
});

export const ItemMasterEditSuccess = (record) => ({
    type: ITEMMASTER_EDIT_SUCCESS,
    payload: record
});

export const ItemMasterEditError = (error) => ({
    type: ITEMMASTER_EDIT_ERROR,
    payload: error
});

// Función Principal para duplicar un registro de ItemMaster
export function ItemMasterDuplicateAction(record){
    return (dispatch =>{
            dispatch(ItemMasterDuplicate());
            AxiosClient.post(`${process.env.REACT_APP_ITEMMASTER}/duplicate`, record)
                .then((response)=> {
                    const duplicated = response.data.result;
                    dispatch( ItemMasterDuplicateSuccess(duplicated));
                })
                .catch(error=> {
                    dispatch( ItemMasterDuplicateError(error) );
                });
        }
    );
}

export const ItemMasterDuplicate = () => ({
    type: ITEMMASTER_DUPLICATE
});

export const ItemMasterDuplicateSuccess = (duplicated) => ({
    type: ITEMMASTER_DUPLICATE_SUCCESS,
    payload: duplicated
});

export const ItemMasterDuplicateError = (error) => ({
    type: ITEMMASTER_DUPLICATE_ERROR,
    payload: error
});

