import {
    BANK_LIST,
    BANK_LIST_SUCCESS,
    BANK_LIST_ERROR,
    BANK_DDLIST,
    BANK_DDLIST_SUCCESS,
    BANK_DDLIST_ERROR,
    BANK_GET,
    BANK_GET_SUCCESS,
    BANK_GET_ERROR,
    BANK_NEW,
    BANK_NEW_SUCCESS,
    BANK_NEW_ERROR,
    BANK_NEW_RECORD,
    BANK_EDIT,
    BANK_EDIT_SUCCESS,
    BANK_EDIT_ERROR
} from '../types';

const initialState = {
    banks: [],
    bank: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case BANK_LIST:
            return {
                ...state,
                loading: true,
                bank: {}
            }
        case BANK_LIST_SUCCESS:
            return {
                ...state,
                banks: action.payload,
                loading: false,
                error: false,
                bank: {}
            }
        case BANK_LIST_ERROR:
            return {
                ...state,
                banks: [],
                loading: false,
                error: true,
                bank: {}
            }
        case BANK_DDLIST:
            return {
                ...state,
                loading: true,
                bank: {}
            }
        case BANK_DDLIST_SUCCESS:
            return {
                ...state,
                banks: action.payload,
                loading: false,
                error: false,
                bank: {}
            }
        case BANK_DDLIST_ERROR:
            return {
                ...state,
                banks: [],
                loading: false,
                error: true,
                bank: {}
            }
        case BANK_GET:
            return {
                ...state,
                loading: true
            }
        case BANK_GET_SUCCESS:
            return {
                ...state,
                bank: action.payload,
                loading: false,
                error: false
            }
        case BANK_GET_ERROR:
            return {
                ...state,
                error: true,
                bank: {}
            }
        case BANK_NEW:
            return {
                ...state,
                loading: true,
            }
        case BANK_NEW_SUCCESS:
            sessionStorage.setItem('error', false);
            return {
                ...state,
                banks: [...state.banks, action.payload],
                bank: {},
                loading: false,
                error: false
            }
        case BANK_NEW_ERROR:
            sessionStorage.setItem('error', true);
            return {
                ...state,
                bank: action.payload,
                loading: false,
                error: true
            }
        case BANK_NEW_RECORD:
            return {
                ...state,
                bank: {},
                error: false
            }
        case BANK_EDIT:
            return {
                ...state
            }
        case BANK_EDIT_SUCCESS:
            sessionStorage.setItem('error', false);
            return {
                ...state,
                error: false,
                bank: action.payload,
                banks: state.banks.map(bank => bank.id === action.payload.id ? bank = action.payload : bank),
            }
        case BANK_EDIT_ERROR:
            sessionStorage.setItem('error', true);
            return {
                ...state,
                bank: action.payload,
                error: false
            }
        default:
            return state;
    }
}
