import {
    INSTALLATION_AND_REMOVAL_PARTS_LIST,
    INSTALLATION_AND_REMOVAL_PARTS_LIST_SUCCESS,
    INSTALLATION_AND_REMOVAL_PARTS_LIST_ERROR,
    INSTALLATION_AND_REMOVAL_PARTS_GET,
    INSTALLATION_AND_REMOVAL_PARTS_GET_SUCCESS,
    INSTALLATION_AND_REMOVAL_PARTS_GET_ERROR,
    INSTALLATION_AND_REMOVAL_PARTS_NEW,
    INSTALLATION_AND_REMOVAL_PARTS_NEW_SUCCESS,
    INSTALLATION_AND_REMOVAL_PARTS_NEW_ERROR,
    INSTALLATION_AND_REMOVAL_PARTS_EDIT,
    INSTALLATION_AND_REMOVAL_PARTS_EDIT_SUCCESS,
    INSTALLATION_AND_REMOVAL_PARTS_EDIT_ERROR,
    INSTALLATION_AND_REMOVAL_PARTS_DELETE,
    INSTALLATION_AND_REMOVAL_PARTS_DELETE_SUCCESS,
    INSTALLATION_AND_REMOVAL_PARTS_DELETE_ERROR
} from '../types';


const initialState = {
    installationandremovalparts: [],
    installationandremovalpart: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case INSTALLATION_AND_REMOVAL_PARTS_LIST:
            return {
                ...state,
                loading: true,
                installationandremovalpart: {}
            }
        case INSTALLATION_AND_REMOVAL_PARTS_LIST_SUCCESS:
            return {
                ...state,
                installationandremovalparts: action.payload,
                loading: false,
                error: false,
                installationandremovalpart: {}
            }
        case INSTALLATION_AND_REMOVAL_PARTS_LIST_ERROR:
            return {
                ...state,
                installationandremovalparts: [],
                loading: false,
                error: true,
                installationandremovalpart: {}
            }
        case INSTALLATION_AND_REMOVAL_PARTS_GET:
            return {
                ...state,
                loading: true
            }
        case INSTALLATION_AND_REMOVAL_PARTS_GET_SUCCESS:
            return {
                ...state,
                installationandremovalpart: action.payload,
                loading: false,
                error: false
            }
        case INSTALLATION_AND_REMOVAL_PARTS_GET_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                installationandremovalpart: {}
            }
        case INSTALLATION_AND_REMOVAL_PARTS_NEW:
            return {
                ...state,
                loading: true,
            }
        case INSTALLATION_AND_REMOVAL_PARTS_NEW_SUCCESS:
            return {
                ...state,
                installationandremovalparts: [...state.installationandremovalparts, action.payload],
                installationandremovalpart: {},
                loading: false,
                error: false
            }
        case INSTALLATION_AND_REMOVAL_PARTS_NEW_ERROR:
            return {
                ...state,
                installationandremovalpart: {},
                loading: false,
                error: true
            }
        case INSTALLATION_AND_REMOVAL_PARTS_EDIT:
            return {
                ...state,
                loading: true
            }
        case INSTALLATION_AND_REMOVAL_PARTS_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                installationandremovalpart: action.payload,
                installationandremovalparts: state.installationandremovalparts.map(installationandremovalpart => ( installationandremovalpart.id === action.payload.id ? action.payload : installationandremovalpart ) ),
            }
        case INSTALLATION_AND_REMOVAL_PARTS_EDIT_ERROR:
            return {
                ...state,
                installationandremovalpart: action.payload,
                loading: false,
                error: true
            }
        case INSTALLATION_AND_REMOVAL_PARTS_DELETE:
            return {
                ...state,
                loading: true
            }
        case INSTALLATION_AND_REMOVAL_PARTS_DELETE_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                installationandremovalpart: {},
                installationandremovalparts: state.installationandremovalparts.map(installationandremovalpart => ( installationandremovalpart.id === action.payload.id ? action.payload : installationandremovalpart ) ),
            }
        case INSTALLATION_AND_REMOVAL_PARTS_DELETE_ERROR:
            return {
                ...state,
                installationandremovalpart: {},
                loading: false,
                error: true
            }
        default:
            return state;
    }
}