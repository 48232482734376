import {
    COUNTRY_LIST,
    COUNTRY_LIST_SUCCESS,
    COUNTRY_LIST_ERROR,
    COUNTRY_DDLIST,
    COUNTRY_DDLIST_SUCCESS,
    COUNTRY_DDLIST_ERROR,
    COUNTRY_GET,
    COUNTRY_GET_SUCCESS,
    COUNTRY_GET_ERROR,
    COUNTRY_NEW,
    COUNTRY_NEW_SUCCESS,
    COUNTRY_NEW_ERROR,
    COUNTRY_NEW_RECORD,
    COUNTRY_EDIT,
    COUNTRY_EDIT_SUCCESS,
    COUNTRY_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Unidades de Medida
export function CountryListAction(){
    
    return (dispatch =>{
        dispatch(CountryList());
        AxiosClient.get(`${process.env.REACT_APP_COUNTRY}`)
            .then((response)=> {
                dispatch(CountryListSuccess(response.data.result));
            })
            .catch(error=> {
                console.log(error);
                dispatch( CountryListError(error) );
            });
    });
}

export const CountryList = () => ({
    type: COUNTRY_LIST
});

export const CountryListSuccess = (records) => ({
    type: COUNTRY_LIST_SUCCESS,
    payload: records
});

export const CountryListError = (error) => ({
    type: COUNTRY_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Unidad de Medida
export function CountryDDListAction(){
    return (dispatch =>{
        dispatch(CountryDDList());
        AxiosClient.get(`${process.env.REACT_APP_COUNTRY}/ddlist`)       
            .then((response)=> {               
                dispatch( CountryDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch(CountryDDListError(error));
            });
    });
}

export const CountryDDList = () => ({
    type: COUNTRY_DDLIST
});

export const CountryDDListSuccess = (records) => ({
    type: COUNTRY_DDLIST_SUCCESS,
    payload: records
});

export const CountryDDListError = (error) => ({
    type: COUNTRY_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener una Unidad de Medida por ID
export function CountryGetAction(id){
    return (dispatch =>{
        dispatch(CountryGet());
        AxiosClient.get(`${process.env.REACT_APP_COUNTRY}/${id}`)
            .then((response)=> {
                dispatch( CountryGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( CountryGetError(error) );
            });
    });
}

export const CountryGet = () => ({
    type: COUNTRY_GET
});

export const CountryGetSuccess = (record) => ({
    type: COUNTRY_GET_SUCCESS,
    payload: record
});

export const CountryGetError = (error) => ({
    type: COUNTRY_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Unidad de Medida cuando se va crear un registro nuevo
export function CountryNewAction(){
    return (dispatch =>{
            dispatch(CountryNewRecord());
    });
}

export const CountryNewRecord = () => ({
    type: COUNTRY_NEW_RECORD
});

// Función Principal para crear y editar registros de Unidades de Medida
export function CountrySaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(CountryEdit());
        } else{
            dispatch(CountryNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_COUNTRY}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( CountryNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( CountryNewError(record) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_COUNTRY}/${record.id}`, record)
                .then((response)=> {
                    dispatch( CountryEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( CountryEditError(record) );
                });
        }
    });
}

export const CountryNew = () => ({
    type: COUNTRY_NEW
});

export const CountryNewSuccess = (record) => ({
    type: COUNTRY_NEW_SUCCESS,
    payload: record
});

export const CountryNewError = (error) => ({
    type: COUNTRY_NEW_ERROR,
    payload: error
});

export const CountryEdit = () => ({
    type: COUNTRY_EDIT
});

export const CountryEditSuccess = (record) => ({
    type: COUNTRY_EDIT_SUCCESS,
    payload: record
});

export const CountryEditError = (error) => ({
    type: COUNTRY_EDIT_ERROR,
    payload: error
});
