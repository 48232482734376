import React, {useEffect} from 'react';
import { FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Input } from 'reactstrap';
import { messageSuccess, messageError, fixFormatDate} from '../../../../Utils/SAMUtils';
import SelectSAM from '../../../Common/SelectSAM';
import ThemeDatePickerSAM from '../../../Hooks/ThemeDatePickerSAM';

// Redux
import { useDispatch, useSelector } from 'react-redux';

import { AirplaneControlMaintenanceGetAction, 
         AirplaneControlMaintenanceSaveAction} from '../../../../store/actions/AirplaneControlMaintenance.actions';

// Validaciones
import { withFormik, ErrorMessage, Field } from 'formik';
import * as Yup from "yup";

export default function ControlMaintenanceLastComplianceModal(props) {

    // Variable de Formulario
    let dataForm = {};
    const dispatch = useDispatch();

    const saveData = (AirplaneControlMaintenance)=>{         
        dispatch (AirplaneControlMaintenanceSaveAction(AirplaneControlMaintenance, props.isNew));
        // Muestra mensaje al usuario.
        if(!error){
            messageSuccess(process.env.REACT_APP_MESSAGE_CONTROL_MAINTENANCE, (props.isNew?'C':'U'));
            props.toggleModal();
        }else{
            messageError(process.env.REACT_APP_MESSAGE_CONTROL_MAINTENANCE, (props.isNew?'C':'U'));
        }
    };
    
    useEffect( () => {
        // Llama el Action para Listar Tests cuando el componente este listo
        if (!props.isNew && props.id!==0) dispatch( AirplaneControlMaintenanceGetAction(props.id));        
        return () => {  };
    }, [props.id, props.isNew, dispatch]);

    dataForm    = useSelector(state=>state.AirplaneControlMaintenance.airplanecontrolmaintenance); 
    const error = useSelector(state=>state.AirplaneControlMaintenance.error);
     
    // Condición para nuevos registros
    if(props.id===0) dataForm = {};

    // Metodo para validar formulario
    const formikValidation = withFormik({
        validationSchema: Yup.object().shape({
            airplane: Yup.string()
                .required('Airplane Name is Required!'),
            airplane_taskcard: Yup.string()
                .required('TaskCard is Required'),
            last_compliance_date: Yup.date()
                .required('Last Compliance Date is Required'),
            last_compliance_hours : Yup.number()
                .required('Last Compliance Hours is Required'),  
            last_compliance_cycles : Yup.number()
                .required('Last Compliance Cycles is Required'), 
            oma: Yup.string()
                .required('Oma is Required'),
            pt_it: Yup.string()
                .required('PT-IT is Required'), 
            maintenance_type: Yup.string()
                .required('Maintenance Type is Required'),
            applicability: Yup.boolean()
                .required('Applicability is Required'),            
        }),

        mapPropsToValues: propiedades => ({  
            id:                            dataForm.id,
            airplane:                      dataForm.airplane,
            airplane_id:                   dataForm.airplane_id,
            airplane_taskcard:             dataForm.airplane_taskcard,
            airplane_taskcard_id:          dataForm.airplane_taskcard_id,
            maintenance_type:              dataForm.maintenance_type,
            maintenance_type_id:           dataForm.maintenance_type_id,
            applicability:                 dataForm.applicability,            
            cycles_remaining:              dataForm.cycles_remaining,
            expiration_date:               dataForm.expiration_date,           
            interval_remaining_cycles:     dataForm.interval_remaining_cycles,
            interval_remaining_cycles_id:  dataForm.interval_remaining_cycles_id,            
            interval_remaining_date:       dataForm.intervalremaining_date,
            interval_remaining_date_id:    dataForm.interval_remaining_date_id,
            interval_remaining_hours:      dataForm.interval_remaining_hours,
            interval_remaining_hours_id:   dataForm.interval_remaining_hours_id,
            last_compliance_cycles:        dataForm.last_compliance_cycles,
            last_compliance_date:          dataForm.last_compliance_date,
            last_compliance_hours:         dataForm.last_compliance_hours,
            next_compliance_cycles:        dataForm.next_compliance_cycles,
            next_compliance_date:          dataForm.next_compliance_date,
            next_compliance_hours:         dataForm.next_compliance_hours,
            oma:                           dataForm.oma,
            oma_id:                        dataForm.oma_id,
            pt_it:                         dataForm.pt_it,
            remaining_date:                dataForm.remaining_date,
            remaining_hours:               dataForm.remaining_hours,
            created_user_id:               dataForm.created_user_id,
            created_date:                  dataForm.created_date,
            updated_user_id:               dataForm.updated_user_id,
            updated_date:                  dataForm.updated_date 
        }),

        handleSubmit: (values, { setSubmitting }) => {           
          const payload = {
            ...values
          };
            
        setTimeout(() => {    
        
            let airplane_id = (payload.airplane.length > 0 ? payload.airplane[0].value : payload.airplane.value);
            let airplane_taskcard_id = (payload.airplane_taskcard_id.length > 0 ? payload.airplane_taskcard_id[0].value : payload.airplane_taskcard_id.value);
            let interval_remaining_cycles_id = (payload.interval_remaining_cycles_id.length > 0 ? payload.interval_remaining_cycles_id[0].value : payload.interval_remaining_cycles_id.value);
            let interval_remaining_date_id = (payload.interval_remaining_date_id.length > 0 ? payload.interval_remaining_date_id[0].value : payload.interval_remaining_date_id.value);
            let interval_remaining_hours_id = (payload.interval_remaining_hours_id.length > 0 ? payload.interval_remaining_hours_id[0].value : payload.interval_remaining_hours_id.value);
            let oma_id = (payload.oma_id.length > 0 ? payload.oma_id[0].value : payload.oma_id.value);
            let maintenance_type_id = (payload.maintenance_type_id.length > 0 ? payload.maintenance_type_id[0].value : payload.maintenance_type_id.value);

            
            

            //let ata_id      = (payload.ata.length > 0 ? payload.ata[0].value : payload.ata.value);
            //let interval_id = (payload.interval.length > 0 ? payload.interval[0].value : payload.interval.value);
          
            saveData({  
                id:                            payload.id,
                airplane:                      payload.airplane,
                airplane_id:                   airplane_id,
                airplanetaskcard:              payload.airplanetaskcard,
                airplane_taskcard_id:          airplane_taskcard_id,
                maintenance_type:              payload.maintenance_type,
                maintenance_type_id:           maintenance_type_id,
                applicability:                 payload.applicability,            
                cycles_remaining:              payload.cycles_remaining,
                expiration_date:               payload.expiration_date,
                expiration_date:               payload.expiration_date,
                interval_remaining_cycles:     payload.interval_remaining_cycles,
                interval_remaining_cycles_id:  interval_remaining_cycles_id,
                interval_remaining_date:       payload.interval_remaining_date,
                interval_remaining_date_id:    interval_remaining_date_id,
                interval_remaining_hours:      payload.interval_remaining_hours,
                interval_remaining_hours_id:   interval_remaining_hours_id,
                last_compliance_cycles:        payload.last_compliance_cycles,
                last_compliance_date:          payload.last_compliance_date,
                last_compliance_hours:         payload.last_compliance_hours,
                next_compliance_cycles:        payload.next_compliance_cycles,
                next_compliance_date:          payload.next_compliance_date,
                next_compliance_hours:         payload.next_compliance_hours,
                pt_it:                         payload.pt_it,
                remaining_date:                payload.remaining_date,
                remaining_hours:               payload.remaining_hours,
                created_user_id:               payload.created_user_id,
                created_date:                  payload.created_date,
                updated_user_id:               payload.updated_user_id,
                updated_date:                  payload.updated_date 
            });
            setSubmitting(true);
          }, 1000);
        },
        displayName: "ControlMaintenances"
    });

    const formData = propiedades => {
        const {
          values,
          touched,
          //dirty,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          //handleReset,
          setFieldValue,
          setFieldTouched,
        //   isSubmitting
        } = propiedades;
        
        return (
            <form className="form-horizontal" encType="multipart/form-data" onSubmit={handleSubmit}>
                <ModalBody>
                    <Row>
                        <Col sm={4}>
                            <FormGroup>
                                <SelectSAM
                                    value={values.airplane}
                                    fieldName={"airplane_id"}
                                    field={"airplane"}
                                    textField={"Airplane"}
                                    isRequired={true}
                                    options={props.airplaneList}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.airplane}
                                    touched={touched.airplane}                                     
                                    isDisabled= {true}
                                />
                            </FormGroup> 
                        </Col>  
                        <Col sm={8}>
                            <FormGroup>
                                <label className="text-bold">TaskCard(*)</label>
                                <br></br>
                                { dataForm.airplanetaskcard ?
                                    <label className="text-bold">{dataForm.airplanetaskcard.label}</label>
                                :null
                                }
                            </FormGroup>
                        </Col> 
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <FormGroup>
                                <SelectSAM
                                    value={values.oma}
                                    fieldName={"oma_id"}
                                    field={"oma"}
                                    textField={"Oma"}
                                    isRequired={true}
                                    options={props.omaList}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.oma}
                                    touched={touched.oma}
                                    //isDisabled= {true}
                                />
                            </FormGroup> 
                        </Col>  
                        <Col sm={4}>
                            <FormGroup>
                                <label className="text-bold">PT-IT (*)</label>
                                <Field 
                                    type="text" 
                                    className="form-control" 
                                    name="pt_it" 
                                    placeholder="PT-IT"
                                    value={values.pt_it}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    //disabled={true}
                                />
                                {!!errors.pt_it && touched.pt_it && (
                                    <div style={{ color: "red", marginTop: ".5rem" }}>
                                        <ErrorMessage name="pt_it"/>
                                    </div>
                                )}
                            </FormGroup>
                        </Col> 
                        <Col sm={4}>
                            <FormGroup>
                                <SelectSAM
                                    value={values.maintenance_type}
                                    fieldName={"maintenance_type_id"}
                                    field={"maintenance_type"}
                                    textField={"Maintenance Type"}
                                    isRequired={true}
                                    options={props.maintenanceTypeList}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.maintenance_type}
                                    touched={touched.maintenance_type}
                                    //isDisabled= {true}
                                />
                            </FormGroup> 
                        </Col>                         
                    </Row>
                    <Row>
                        <Col sm="4">
                            <FormGroup>
                                <label className="text-bold">Applicability</label>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        name="is_active"
                                        checked={values.applicability}
                                        value={values.applicability}
                                        onChange={handleChange}
                                        disabled={true}
                                    />
                                    <span></span>
                                </label>
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <label className="text-bold">Cycles Remaining</label>
                                <Field 
                                    type="number" 
                                    className="form-control" 
                                    name="cycles_remaining" 
                                    placeholder="Cycles Remaining"
                                    value={values.cycles_remaining}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={true}
                                />
                                {!!errors.cycles_remaining && touched.cycles_remaining && (
                                    <div style={{ color: "red", marginTop: ".5rem" }}>
                                        <ErrorMessage name="cycles_remaining"/>
                                    </div>
                                )}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            {/* // Etiqueta para Fecha de Expiracion*/}
                            <FormGroup>
                                <label className="text-bold">Expiration Date</label>
                                <ThemeDatePickerSAM 
                                    disableFuture={false}
                                    name={"expiration_date"}
                                    openTo={"year"}
                                    label={''}
                                    views={["year", "month","date"]}                                   
                                    value={fixFormatDate(values.expiration_date)} 
                                    onChange={value => {
                                        setFieldValue("expiration_date", value);
                                    }}  
                                    disabled={true} 
                                />
                            </FormGroup>
                        </Col> 
                    </Row>
                    <Row> 
                        <Col sm={4}>
                            <FormGroup>
                                <SelectSAM
                                    value={values.interval_remaining_cycles}
                                    fieldName={"interval_remaining_cycles_id"}
                                    field={"interval_remaining_cycles"}
                                    textField={"Interval Remaining Cycles"}
                                    //isRequired={true}
                                    options={props.intervalList}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.interval_remaining_cycles}
                                    touched={touched.interval_remaining_cycles}
                                    isDisabled= {true}
                                />
                            </FormGroup> 
                        </Col> 

                        <Col sm={4}>
                            <FormGroup>
                                <SelectSAM
                                    value={values.interval_remaining_date}
                                    fieldName={"interval_remaining_date_id"}
                                    field={"interval_remaining_date"}
                                    textField={"Interval Remaining Date"}
                                    //isRequired={true}
                                    options={props.intervalList}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.interval_remaining_date}
                                    touched={touched.interval_remaining_date}
                                    isDisabled= {true}
                                />
                            </FormGroup> 
                        </Col> 
                        <Col sm={4}>
                            <FormGroup>
                                <SelectSAM
                                    value={values.interval_remaining_hours}
                                    field={"interval_remaining_hours_id"}
                                    textField={"Interval Remaining Hours"}
                                    //isRequired={true}
                                    options={props.intervalList}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.interval_remaining_hours}
                                    touched={touched.interval_remaining_hours}
                                    isDisabled= {true}
                                />
                            </FormGroup> 
                        </Col> 
                    </Row> 
                    <Row>

                        <Col sm={4}>
                            <FormGroup>
                                <label className="text-bold">Last Compliance Cycles (*)</label>
                                <Field 
                                    type="number" 
                                    className="form-control" 
                                    name="last_compliance_cycles" 
                                    placeholder="Last Compliance Cycles"
                                    value={values.last_compliance_cycles}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {!!errors.last_compliance_cycles && touched.last_compliance_cycles && (
                                    <div style={{ color: "red", marginTop: ".5rem" }}>
                                        <ErrorMessage name="last_compliance_cycles"/>
                                    </div>
                                )}
                            </FormGroup>
                        </Col>


                        <Col sm={4}>
                            {/* // Etiqueta para Fecha de Expiracion*/}
                            <FormGroup>
                                <label className="text-bold">Last Compliance Date(*)</label>
                                <ThemeDatePickerSAM 
                                    //disableFuture={false}
                                    name={"last_compliance_date"}
                                    openTo={"year"}
                                    label={''}
                                    views={["year", "month","date"]}                                   
                                    value={fixFormatDate(values.last_compliance_date)} 
                                    onChange={value => {
                                        setFieldValue("last_compliance_date", value);
                                    }}   
                                />
                            </FormGroup>
                        </Col> 

                        <Col sm={4}>
                            <FormGroup>
                                <label className="text-bold">Last Compliance Hours (*)</label>
                                <Field 
                                    type="number" 
                                    className="form-control" 
                                    name="last_compliance_hours" 
                                    placeholder="Last Compliance Hours"
                                    value={values.last_compliance_hours}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {!!errors.last_compliance_hours && touched.last_compliance_hours && (
                                    <div style={{ color: "red", marginTop: ".5rem" }}>
                                        <ErrorMessage name="last_compliance_hours"/>
                                    </div>
                                )}
                            </FormGroup>
                        </Col>
                    </Row> 
                    <Row>
                        <Col sm={4}>
                            <FormGroup>
                                <label className="text-bold">Next Compliance Cycles</label>
                                <Field 
                                    type="number" 
                                    className="form-control" 
                                    name="next_compliance_cycles" 
                                    placeholder="Last Compliance Cycles"
                                    value={values.next_compliance_cycles}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={true}
                                   
                                />
                                {!!errors.next_compliance_cycles && touched.next_compliance_cycles && (
                                    <div style={{ color: "red", marginTop: ".5rem" }}>
                                        <ErrorMessage name="next_compliance_cycles"/>
                                    </div>
                                )}
                            </FormGroup>
                        </Col>

                        <Col sm={4}>
                            {/* // Etiqueta para Fecha de Expiracion*/}
                            <FormGroup>
                                <label className="text-bold">Next Compliance Date</label>
                                <ThemeDatePickerSAM 
                                    //disableFuture={false}
                                    name={"next_compliance_date"}
                                    openTo={"year"}
                                    label={''}
                                    views={["year", "month","date"]}                                   
                                    value={fixFormatDate(values.next_compliance_date)} 
                                    onChange={value => {
                                        setFieldValue("next_compliance_date", value);
                                    }}  
                                    disabled={true}
                                />
                            </FormGroup>
                        </Col> 

                        <Col sm={4}>
                            <FormGroup>
                                <label className="text-bold">Next Compliance Hours</label>
                                <Field 
                                    type="number" 
                                    className="form-control" 
                                    name="next_compliance_hours" 
                                    placeholder="Next Compliance Hours"
                                    value={values.next_compliance_hours}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={true}
                                />
                                {!!errors.next_compliance_hours && touched.next_compliance_hours && (
                                    <div style={{ color: "red", marginTop: ".5rem" }}>
                                        <ErrorMessage name="next_compliance_hours"/>
                                    </div>
                                )}
                            </FormGroup>
                        </Col>

                    </Row> 
                    <Row>

                        <Col sm={6}>    
                            <FormGroup>
                                <label className="text-bold">Remaining Date</label>
                                <Field 
                                    type="number" 
                                    className="form-control" 
                                    name="remaining_date" 
                                    placeholder="Remaining Date"
                                    value={values.remaining_date}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={true}
                                />
                                {!!errors.remaining_date && touched.remaining_date && (
                                    <div style={{ color: "red", marginTop: ".5rem" }}>
                                        <ErrorMessage name="remaining_date"/>
                                    </div>
                                )}
                            </FormGroup>
                        </Col>
                        <Col sm={6}>    
                            <FormGroup>
                                <label className="text-bold">Remaining Hours</label>
                                <Field 
                                    type="number" 
                                    className="form-control" 
                                    name="remaining_hours" 
                                    placeholder="Remaining Hours"
                                    value={values.remaining_hours}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={true}
                                />
                                {!!errors.remaining_hours && touched.remaining_hours && (
                                    <div style={{ color: "red", marginTop: ".5rem" }}>
                                        <ErrorMessage name="remaining_hours"/>
                                    </div>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col sm={12}>
                            <p className="text-bold text-danger">(*) Required Fields</p>
                        </Col>
                    </Row>   
                </ModalBody>                
                <ModalFooter>
                    <Button color="inverse" type="submit">Calculate Next Compliance</Button>{' '}
                    <Button color="secondary" onClick={props.toggleModalLastCompliance}>Cancel</Button>
                </ModalFooter>
            </form>
        );
    };
    const SAMForm = formikValidation(formData);
    return (
        <Modal isOpen={props.modalLastCompliance} toggle={props.toggleModalLastCompliance} size= 'lg'>
            <ModalHeader toggle={props.toggleModalLastCompliance} className="bg-gray-darker"><div className="text-bold">{props.modalTitle}</div></ModalHeader>
            <SAMForm />            
        </Modal>
    )
}