import {
    AIRPLANECOMPONENT_LIST,
    AIRPLANECOMPONENT_LIST_SUCCESS,
    AIRPLANECOMPONENT_LIST_ERROR,
    AIRPLANECOMPONENT_GET,
    AIRPLANECOMPONENT_GET_SUCCESS,
    AIRPLANECOMPONENT_GET_ERROR,
    AIRPLANECOMPONENT_NEW,
    AIRPLANECOMPONENT_NEW_SUCCESS,
    AIRPLANECOMPONENT_NEW_ERROR,
    AIRPLANECOMPONENT_NEW_RECORD,
    AIRPLANECOMPONENT_EDIT,
    AIRPLANECOMPONENT_EDIT_SUCCESS,
    AIRPLANECOMPONENT_EDIT_ERROR,
    AIRPLANECOMPONENT_DELETE ,
    AIRPLANECOMPONENT_DELETE_SUCCESS ,
    AIRPLANECOMPONENT_DELETE_ERROR 
} from '../types';
import service from '../../services/AirplaneComponent';

// Conexión con la API usando Axios.
//const axios = require('axios');
import AxiosClient from '../../config/AxiosClient';
require('dotenv').config();

// Función Principal para Listar los Componentes por Airplane
export const AirplaneComponentListAction = (airplane_id) => async (dispatch) => {
    dispatch(AirplaneComponentList());
    service.getListById(airplane_id)
    .then((response) => {
        dispatch(AirplaneComponentListSuccess(response.data.result));
    })
    .catch(error => {
        dispatch(AirplaneComponentListError(error));
    });
};

export const AirplaneComponentList = ()=>({
    type: AIRPLANECOMPONENT_LIST
});

export const AirplaneComponentListSuccess = airplaneList =>({
    type: AIRPLANECOMPONENT_LIST_SUCCESS,
    payload: airplaneList
});

export const AirplaneComponentListError = error =>({
    type: AIRPLANECOMPONENT_LIST_ERROR,
    payload: error
});

// Función Principal para obtener un Componente de Airplane
export function AirplaneComponentGetAction(id){
    return (dispatch =>{
        dispatch(AirplaneComponentGet());
        service.getOneById(id)
        .then((response) => {
            dispatch(AirplaneComponentGetSuccess(response.data.result));
        })
        .catch(error => {
            dispatch(AirplaneComponentGetError(error));
        });
    });
}

export const AirplaneComponentGet = () => ({
    type: AIRPLANECOMPONENT_GET
});

export const AirplaneComponentGetSuccess = (record) => ({
    type: AIRPLANECOMPONENT_GET_SUCCESS,
    payload: record
});

export const AirplaneComponentGetError = (error) => ({
    type: AIRPLANECOMPONENT_GET_ERROR,
    payload: error
});

// Función Principal para Crear un Componente de Aeronave.
export function AirplaneComponentNewAction(airplane){
    return (dispatch) => {
        dispatch( AirplaneComponentNew() ); 
        AxiosClient.post(process.env.REACT_APP_AIRPLANECOMPONENT, airplane)
            .then(resultado=>{
                if(resultado.status===201) {
                    dispatch( AirplaneComponentNewSuccess(airplane) );
                }
            }).catch(error=>{
                dispatch( AirplaneComponentNewError() );
            });
    }
}

export const AirplaneComponentNewRecord = () => ({ 
    type: AIRPLANECOMPONENT_NEW_RECORD
});

// Función Principal para crear y editar registros de Airplane Component
export const AirplaneComponentSaveAction = (record) => async (dispatch) => {

    dispatch(AirplaneComponentNew());

    const response = await service.create(record).catch( error => dispatch(AirplaneComponentNewError(error)));

    if (!response) return null;

        dispatch(AirplaneComponentNewSuccess( (response.data.result || {}) ));

    return response.data;
}

export const AirplaneComponentNew = () => ({
    type: AIRPLANECOMPONENT_NEW
});

export const AirplaneComponentNewSuccess = (record) => ({
    type: AIRPLANECOMPONENT_NEW_SUCCESS,
    payload: record
});

export const AirplaneComponentNewError = (error) => ({
    type: AIRPLANECOMPONENT_NEW_ERROR,
    payload: error
});


export const AirplaneComponentUpdateAction = (record, isNew) => async (dispatch) => {

    dispatch(AirplaneComponentEdit());

    const response = await service.update(record).catch( error => dispatch(AirplaneComponentEditError(error)));

    if (!response) return null;

        dispatch(AirplaneComponentEditSuccess( (response.data.result || {}) ));

    return response.data;
}

export const AirplaneComponentEdit = () => ({
    type: AIRPLANECOMPONENT_EDIT
});

export const AirplaneComponentEditSuccess = (record) => ({
    type: AIRPLANECOMPONENT_EDIT_SUCCESS,
    payload: record
});

export const AirplaneComponentEditError = (error) => ({
    type: AIRPLANECOMPONENT_EDIT_ERROR,
    payload: error
});

// Función Principal para borrar un Componente de Airplane
export const AirplaneComponentDeleteAction = (record) => async (dispatch) => {

    dispatch(AirplaneComponentDelete());

    const response = await service.delete(record).catch( error => dispatch(AirplaneComponentDeleteError(error)));

    if (!response) return null;

        dispatch(AirplaneComponentDeleteSuccess( (response.data.result || {}) ));

    return response.data;
}

const AirplaneComponentDelete = () => ({
    type: AIRPLANECOMPONENT_DELETE
});

const AirplaneComponentDeleteSuccess = (record) => ({
    type: AIRPLANECOMPONENT_DELETE_SUCCESS,
    payload: record
});

const AirplaneComponentDeleteError = (error) => ({
    type: AIRPLANECOMPONENT_DELETE_ERROR,
    payload: error
});