import {
    INTERVAL_LIST,
    INTERVAL_LIST_SUCCESS,
    INTERVAL_LIST_ERROR,
    INTERVAL_DDLIST,
    INTERVAL_DDLIST_SUCCESS,
    INTERVAL_DDLIST_ERROR,
    INTERVAL_GET,
    INTERVAL_GET_SUCCESS,
    INTERVAL_GET_ERROR,
    INTERVAL_NEW,
    INTERVAL_NEW_SUCCESS,
    INTERVAL_NEW_ERROR,
    INTERVAL_NEW_RECORD,
    INTERVAL_EDIT,
    INTERVAL_EDIT_SUCCESS,
    INTERVAL_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Intervalos
export function IntervalListAction(){
    
    return (dispatch =>{
        dispatch(IntervalList());
        AxiosClient.get(`${process.env.REACT_APP_INTERVAL}`)
            .then((response)=> {
                dispatch(IntervalListSuccess(response.data.result));
            })
            .catch(error=> {
                console.log(error);
                dispatch( IntervalListError(error) );
            });
    });
}

export const IntervalList = () => ({
    type: INTERVAL_LIST
});

export const IntervalListSuccess = (records) => ({
    type: INTERVAL_LIST_SUCCESS,
    payload: records
});

export const IntervalListError = (error) => ({
    type: INTERVAL_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Intervalos en Forma de DDownp
export function IntervalDDListAction(){
    
    return (dispatch =>{
        dispatch(IntervalDDList());
        AxiosClient.get(`${process.env.REACT_APP_INTERVAL}/ddlist`)
            .then((response)=> {
                dispatch( IntervalDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( IntervalDDListError(error) );
            });
    });
}

export const IntervalDDList = () => ({
    type: INTERVAL_DDLIST
});

export const IntervalDDListSuccess = (records) => ({
    type: INTERVAL_DDLIST_SUCCESS,
    payload: records
});

export const IntervalDDListError = (error) => ({
    type: INTERVAL_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener un Intervalor por ID
export function IntervalGetAction(id){
    return (dispatch =>{
        dispatch(IntervalGet());
        AxiosClient.get(`${process.env.REACT_APP_INTERVAL}/${id}`)
            .then((response)=> {
                dispatch( IntervalGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( IntervalGetError(error) );
            });
    });
}

export const IntervalGet = () => ({
    type: INTERVAL_GET
});

export const IntervalGetSuccess = (record) => ({
    type: INTERVAL_GET_SUCCESS,
    payload: record
});

export const IntervalGetError = (error) => ({
    type: INTERVAL_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Intervlao cuando se va crear un registro nuevo
export function IntervalNewAction(){
    return (dispatch =>{
            dispatch(IntervalNewRecord());
    });
}

export const IntervalNewRecord = () => ({
    type: INTERVAL_NEW_RECORD
});

// Función Principal para crear y editar registros de Intervalos
export function IntervalSaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(IntervalEdit());
        } else{
            dispatch(IntervalNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_INTERVAL}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( IntervalNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( IntervalNewError(record) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_INTERVAL}/${record.id}`, record)
                .then((response)=> {
                    dispatch( IntervalEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( IntervalEditError(record) );
                });
        }
    });
}

export const IntervalNew = () => ({
    type: INTERVAL_NEW
});

export const IntervalNewSuccess = (record) => ({
    type: INTERVAL_NEW_SUCCESS,
    payload: record
});

export const IntervalNewError = (error) => ({
    type: INTERVAL_NEW_ERROR,
    payload: error
});

export const IntervalEdit = () => ({
    type: INTERVAL_EDIT
});

export const IntervalEditSuccess = (record) => ({
    type: INTERVAL_EDIT_SUCCESS,
    payload: record
});

export const IntervalEditError = (error) => ({
    type: INTERVAL_EDIT_ERROR,
    payload: error
});
