import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';
import AirplaneControlMaintenance from './components/Engineering/Planning/ControlMaintenance/AirplaneControlMaintenance';
import RubberAssembly from './components/Engineering/RubberAssembly/RubberAssembly';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';
// import BaseHorizontal from './components/Layout/BaseHorizontal';

import supplyRoutes from './components/Supply/supplyRoutes'
import planningRoutes from './components/Planning/planningRoutes'

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;

const routeComponents = [
    ...supplyRoutes,
    ...planningRoutes
]

const RouteComponents = () => (<>
    {routeComponents.map(({ route, component: _component }) => <Route key={`path-${route}`} path={route} component={waitFor(_component)} />)}
</>)


const Home = lazy(() => import('./components/Home/Home'));
const Kpi = lazy(() => import('./components/Kpi/Kpi'));
const KpiDirMantenimiento = lazy(() => import('./components/Kpi/KpiDirMantenimiento'));
const KpiDirOperaciones = lazy(() => import('./components/Kpi/KpiDirOperaciones'));
const KpiDirAseguramientoCalidad = lazy(() => import('./components/Kpi/KpiDirAseguramientoCalidad'));
const KpiDirComercializacion = lazy(() => import('./components/Kpi/KpiDirComercializacion'));
const KpiDirAdministracion = lazy(() => import('./components/Kpi/KpiDirAdministracion'));
const KpiIncomingRejectByBpartner = lazy(() => import('./components/Kpi/KpiIncomingRejectByBpartner'));
const KpiIncomingRejectByInspectors = lazy(() => import('./components/Kpi/KpiIncomingRejectByInspectors'));
const KpiMaterialReceptionByCategories = lazy(() => import('./components/Kpi/KpiMaterialReceptionByCategory'));
const KpiMaterialRequestItemsByAirplane = lazy(() => import('./components/Kpi/KpiMaterialRequestItemsByAirplane'));
const KpiMaterialRemovedByAirplane = lazy(() => import('./components/Kpi/KpiMaterialRemovedByAirplane'));

const EconomicIndicator = lazy(() => import('./components/Kpi/EconomicIndicator'));
//const Ata    = lazy(() => import('./components/Ata/Ata'));
const Ata = lazy(() => import('./components/Ata/Ata'));
const Airplane = lazy(() => import('./components/Airplane/Airplane'));
const AirworthinessDirective = lazy(() => import('./components/Maintenance/AirworthinessDirective/AirworthinessDirective'));

// Contabilidad / Accounting / Administración / Finanzas
const Payment = lazy(() => import('./components/Accounting/Payable/Payment'));
const PaymentApproval = lazy(() => import('./components/Accounting/Payable/PaymentApproval'));
const PaymentAllocate = lazy(() => import('./components/Accounting/Payable/PaymentAllocate'));
const PaymentSchedule = lazy(() => import('./components/Accounting/Payable/PaymentSchedule'));

// Ingeniería
const ItemMaster = lazy(() => import('./components/Engineering/ItemMaster/ItemMaster'));
const ItemDetails = lazy(() => import('./components/Engineering/ItemDetail/ItemDetails'));
const Planning = lazy(() => import('./components/Engineering/Planning/Planning'));
const PackageWork = lazy(() => import('./components/Engineering/Planning/PackageWork/PackageWork'));
const AirplaneTaskCard = lazy(() => import('./components/Engineering/Planning/PackageWork/AirplaneTaskCard'));
const Bulletin = lazy(() => import('./components/Engineering/Bulletin/Bulletin'));
const EquipmentTools = lazy(() => import('./components/Engineering/EquipmentTools/EquipmentTools'));
const EquipmentRequest = lazy(() => import('./components/Supply/EquipmentRequest/EquipmentRequest'));
const AirplaneModelManagement = lazy(() => import('./components/Engineering/AirplaineManagement/AirplaneModelManagement/AirplaneModelManagement'));
const AirplaneSeriesManagement = lazy(() => import('./components/Engineering/AirplaineManagement/AirplaneSeriesManagement/AirplaneSeriesManagement'));
const InstallationAndRemovalParts = lazy(() => import('./components/Maintenance/InstallationAndRemovalParts/InstallationAndRemovalParts'));
const CannibalizationRecord = lazy(() => import('./components/Maintenance/CannibalizationRecord/CannibalizationRecord') );

// Configuración / Configuration
const MasterData = lazy(() => import('./components/Configuration/MasterData/MasterData'));
const Company = lazy(() => import('./components/Configuration/Company/Company'));
const Bank = lazy(() => import('./components/Configuration/Bank/Bank'));
const BPartner = lazy(() => import('./components/Configuration/BPartner/BPartner'));
const Tax = lazy(() => import('./components/Configuration/Tax/Tax'));
const Currency = lazy(() => import('./components/Configuration/Currency/Currency'));
const Uom = lazy(() => import('./components/Configuration/Uom/Uom'));
const WorkShop = lazy(() => import('./components/Configuration/WorkShop/WorkShop'));
const Category = lazy(() => import('./components/Configuration/Category/Category'));
const Interval = lazy(() => import('./components/Configuration/Interval/Interval'));
const MaterialCondition = lazy(() => import('./components/Configuration/MaterialCondition/MaterialCondition'));
const MaterialState = lazy(() => import('./components/Configuration/MaterialState/MaterialState'));
const AirportMaintenance = lazy(() => import('./components/Configuration/AirportMaintenance/AirportMaintenance'));
const ServiceRole = lazy( () => import('./components/Configuration/ServiceRole/ServiceRole') );

const AttachType = lazy(() => import('./components/Configuration/AttachType/AttachType'));
const SupplierEvaluationParams = lazy(() => import('./components/Configuration/SupplierEvaluationParam/SupplierEvaluationParams'));

// Ordenes de Compras / Purchase Order
const PurchaseGuaranteeAndReturn = lazy(() => import('./components/Purchasing/PurchaseGuaranteeAndReturn/PurchaseGuaranteeAndReturn'));
const PurchaseLoansAndExchanges = lazy(() => import('./components/Purchasing/PurchaseLoansAndExchanges/PurchaseLoansAndExchanges'));
const PurchaseOrder = lazy(() => import('./components/Purchasing/PurchaseOrder/PurchaseOrder'));
const PurchaseOrderApproval = lazy(() => import('./components/Purchasing/PurchaseOrderApproval/PurchaseOrderApproval'));
const PurchaseQuotation = lazy(() => import('./components/Purchasing/PurchaseQuotation/PurchaseQuotation'));
const PurchaseRequisition = lazy(() => import('./components/Purchasing/PurchaseRequisition/PurchaseRequisition'));
const PurchaseRequirement = lazy(() => import('./components/Purchasing/PurchaseRequirement/PurchaseRequirement'));
const QuoteManagement = lazy(() => import('./components/Purchasing/QuoteManagement/QuoteManagement'));
const CorrelativeManagement = lazy(() => import('./components/Purchasing/CorrelativeManagement/CorrelativeManagement'));


// Production
const Production = lazy(() => import('./components/Maintenance/Production'));

// Operations
const Inspection = lazy(() => import('./components/Operation/Inspection'));


// Human Resource
const Charge = lazy(() => import('./components/HumanResource/Charge/Charge'));
const Department = lazy(() => import('./components/HumanResource/Department/Department'));
const Employee = lazy(() => import('./components/HumanResource/Employee/Employee'));
const EmployeeLicense = lazy(() => import('./components/HumanResource/Employee/EmployeeLicense'));

const Flight = lazy(() => import('./components/Operation/Flight'));


// Seguridad / Security
const Login = lazy(() => import('./components/Security/Login/Login'));
const Recover = lazy(() => import('./components/Security/Login/Recover'));
const ChangePassword = lazy(() => import('./components/Security/Login/ChangePassword'));
const Menu = lazy(() => import('./components/Security/Menu/Menu'));
const Rol = lazy(() => import('./components/Security/Rol/Rol'));
//const Rol              = lazy(()  => import('./components/Security/Rol/Rol'));



const MenuRol = lazy(() => import('./components/Security/Menu/MenuRol'));
const SystemConfig = lazy(() => import('./components/Configuration/General/SystemConfig'));
const User = lazy(() => import('./components/Security/User/User'));
const AccessLog = lazy(() => import('./components/Security/Audit/AccessLog'));
const PruebaHookTest = lazy(() => import('./components/Prueba/PruebaHookTest'));
// Reportes / Reports

const InventoryWarehouseReport = lazy(() => import('./components/Reports/InventoryWarehouseReport'));
const InventoryExpiredReport = lazy(() => import('./components/Reports/InventoryExpiredReport'));
const InventoryValuedReport = lazy(() => import('./components/Reports/InventoryValuedReport'));
const InventoryDiscardedReport = lazy(() => import('./components/Reports/InventoryDiscardedReport'));
const InventoryRepairableReport = lazy(() => import('./components/Reports/InventoryRepairableReport'));
const IncomeWarehouseReport = lazy(() => import('./components/Reports/IncomeWarehouseReport'));
const InventoryToExpireReport = lazy(() => import('./components/Reports/InventoryToExpireReport'));
const MaterialReceptionReport = lazy(() => import('./components/Reports/MaterialReceptionReport'));
const PurchaseRequirementDetailReport = lazy(() => import('./components/Reports/ReportRequestPurchaseRequimentDetail'));
const PurchaseOrderByDateReport = lazy(() => import('./components/Reports/ReportPurchaseOrderByDate'));
const MaterialAvaibleStockMinAndUmbral = lazy(() => import('./components/Reports/ReportMaterialAvaibleStockMinAndUmbral'));
const ExpiredAvailableAndRepairedMaterialReport = lazy(() => import('./components/Reports/ReportExpiredAvailableAndRepairedMaterial'));
const Recordmaterialremoved = lazy(() => import('./components/Maintenance/RecordRemovedMaterial/RecordRemovedMaterial'));
const MaterialRemovedByConditionReports = lazy(() => import('./components/Reports/MaterialRemovedByConditionReport'));
const ItemMasterIsActiveOrderByCategories = lazy(() => import('./components/Reports/ItemMasterIsActiveOrderByCategories'));
const MaterialToExpire = lazy(() => import('./components/Reports/MaterialToExpire'));
const MaterialLoanReport = lazy(() => import('./components/Reports/MaterialLoanReport'));
const HardTimeMaterialReport = lazy(() => import('./components/Reports/HardTimeReport'));
const MaterialRemovedByAircraftAndDate = lazy(() => import('./components/Reports/MaterialRemovedByAircraftAndDate'));
const ItemInventoryOilConsumeReport = lazy(() => import('./components/Reports/ItemInventoryOilConsumeReport'));
const SpecialToolsReportByDateRange =  lazy(() => import('./components/Reports/SpecialToolsByRangeOfDateReport'));
const MaterialRequestByAircraftAndDate = lazy(() => import('./components/Reports/MaterialRequestByAircraftAndDate'));
const WheelAndTyresActive = lazy(() => import('./components/Reports/WheelAndTyresActive'));
const WarehouseItemsActivesByLocation = lazy(() => import('./components/Reports/WarehouseItemsActiveByLocation'));
const MaterialNextToArrive = lazy(() => import('./components/Reports/MaterialNextToArrive'));
const Roles = lazy(() => import('./components/Configuration/Role/Role'));
// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
    /* See full project for reference */
    '/login',
    '/recover',
];

// eslint-disable-next-line react/prop-types
const Routes = ({ location }) => {
    // eslint-disable-next-line react/prop-types
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'

    const animationName = 'rag-fadeIn';
    // eslint-disable-next-line react/prop-types
    if (listofPages.indexOf(location.pathname) > -1) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        {/* See full project for reference */}
                        <Route path="/login" component={waitFor(Login)} />
                        <Route path="/recover" component={waitFor(Recover)} />
                    </Switch>
                </Suspense>
            </BasePage>
        )
    }
    else {
        return (
            // Layout component wrapper
            // Use <BaseHorizontal> to change layout
            <Base>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader />}>
                                <Switch location={location}>
                                    <Route path="/home" component={waitFor(Home)} />
                                    <Route path="/kpi" component={waitFor(Kpi)} />
                                    <Route path="/kpidirmtto" component={waitFor(KpiDirMantenimiento)} />
                                    <Route path="/kpidiroperaciones" component={waitFor(KpiDirOperaciones)} />
                                    <Route path="/kpidiraseguramientocalidad" component={waitFor(KpiDirAseguramientoCalidad)} />
                                    <Route path="/kpidircomercializacion" component={waitFor(KpiDirComercializacion)} />
                                    <Route path="/kpidiradministracion" component={waitFor(KpiDirAdministracion)} />
                                    <Route path="/kpibpartnerincoming" component={waitFor(KpiIncomingRejectByBpartner)} />
                                    <Route path="/kpiinspectorsincoming" component={waitFor(KpiIncomingRejectByInspectors)} />
                                    <Route path="/kpicategorymaterialreception" component={waitFor(KpiMaterialReceptionByCategories)} />
                                    <Route path="/kpimaterialrequestitems" component={waitFor(KpiMaterialRequestItemsByAirplane)} />
                                    <Route path="/kpimaterialremovedairplane" component={waitFor(KpiMaterialRemovedByAirplane)} />

                                    <Route path="/economicindicators" component={waitFor(EconomicIndicator)} />

                                    {/* Atas /  Atas */}
                                    {/* <Route path="/ata" component={waitFor(Ata)}/> */}
                                    <Route path="/ata" component={waitFor(Ata)} />

                                    {/* Aeronave /  Airplane */}
                                    <Route path="/airplane" component={waitFor(Airplane)} />

                                    {/* Configuración /  Configuration */}
                                    <Route path="/masterdata" component={waitFor(MasterData)} />
                                    <Route path="/company" component={waitFor(Company)} />
                                    <Route path="/bank" component={waitFor(Bank)} />
                                    <Route path="/partner" component={waitFor(BPartner)} />
                                    <Route path="/tax" component={waitFor(Tax)} />
                                    <Route path="/workshop" component={waitFor(WorkShop)} />
                                    <Route path="/currency" component={waitFor(Currency)} />
                                    <Route path="/uom" component={waitFor(Uom)} />
                                    <Route path="/supplierevaluationparam" component={waitFor(SupplierEvaluationParams)} />
                                    <Route path="/attachtype" component={waitFor(AttachType)} />
                                    <Route path="/category" component={waitFor(Category)} />
                                    <Route path="/interval" component={waitFor(Interval)} />
                                    <Route path="/materialcondition" component={waitFor(MaterialCondition)} />
                                    <Route path="/materialstate" component={waitFor(MaterialState)} />
                                    <Route path="/servicerole" component={waitFor(ServiceRole)} />

                                    {/* Administración / Accounting */}
                                    <Route path="/payment" component={waitFor(Payment)} />
                                    <Route path="/paymentapproval" component={waitFor(PaymentApproval)} />
                                    <Route path="/paymentallocate" component={waitFor(PaymentAllocate)} />
                                    <Route path="/paymentschedule" component={waitFor(PaymentSchedule)} />

                                    {/* Compras / Purchasing Order*/}
                                    <Route path="/purchaseguaranteeandreturn" component={waitFor(PurchaseGuaranteeAndReturn)} />
                                    <Route path="/purchaseloansandexchanges" component={waitFor(PurchaseLoansAndExchanges)} />
                                    <Route path="/purchaseorder" component={waitFor(PurchaseOrder)} />
                                    <Route path="/purchaseorderapproval" component={waitFor(PurchaseOrderApproval)} />
                                    <Route path="/purchasequotation" component={waitFor(PurchaseQuotation)} />
                                    <Route path="/purchaserequisition" component={waitFor(PurchaseRequisition)} />
                                    <Route path="/purchaserequirement" component={waitFor(PurchaseRequirement)} />
                                    <Route path="/quotemanagement" component={waitFor(QuoteManagement)} />
                                    <Route path="/correlativemanagement" component={waitFor(CorrelativeManagement)} />

                                    {/* Ingenieria / Engineering */}
                                    <Route path="/itemmaster" component={waitFor(ItemMaster)} />
                                    <Route path="/itemdetails" component={waitFor(ItemDetails)} />
                                    <Route path="/planning" component={waitFor(Planning)} />

                                    <Route path="/packagework " component={waitFor(PackageWork)} />
                                    <Route path="/airplanetaskcard" component={waitFor(AirplaneTaskCard)} />
                                    <Route path="/airplanecontrolmaintenance" component={waitFor(AirplaneControlMaintenance)} />
                                    <Route path="/rubberassembly" component={waitFor(RubberAssembly)} />
                                    <Route path="/airplanemodelmanagement" component={waitFor(AirplaneModelManagement)} />
                                    <Route path="/airplaneseriesmanagement" component={waitFor(AirplaneSeriesManagement)} />
                                    <Route path="/bulletin" component={waitFor(Bulletin)} />
                                    <Route path="/equipment" component={waitFor(EquipmentTools)} />
                                    <Route path="/equipmentrequest" component={waitFor(EquipmentRequest)} />

                                    {/* Mantenimiento / Maintenance */}
                                    <Route path="/production" component={waitFor(Production)} />
                                    <Route path="/airworthinessdirective" component={waitFor(AirworthinessDirective)} />
                                    <Route path="/installationandremovalparts" component={waitFor(InstallationAndRemovalParts)} />
                                    <Route path="/airportMaintenance" component={waitFor(AirportMaintenance)} />
                                    <Route path="/cannibalizationrecord" component={waitFor(CannibalizationRecord)} />

                                    {/* Operaciones / Operations */}
                                    <Route path="/inspection" component={waitFor(Inspection)} />
                                    <Route path="/flight" component={waitFor(Flight)} />

                                    {/* Capital Humano / Human Resource */}
                                    <Route path="/charge" component={waitFor(Charge)} />
                                    <Route path="/department" component={waitFor(Department)} />
                                    <Route path="/employee" component={waitFor(Employee)} />
                                    <Route path="/employeelicense" component={waitFor(EmployeeLicense)} />

                                    {/* Seguridad  / Security */}
                                    <Route path="/user" component={waitFor(User)} />
                                    <Route path="/changepassword" component={waitFor(ChangePassword)} />
                                    <Route path="/menu" component={waitFor(Menu)} />
                                    <Route path="/rol" component={waitFor(Rol)} />
                                    <Route path="/menurol" component={waitFor(MenuRol)} />
                                    <Route path="/systemconfig" component={waitFor(SystemConfig)} />
                                    <Route path="/accesslog" component={waitFor(AccessLog)} />

                                    {/* Reportes  / Report */}
                                    <Route path="/inventorywarehousereport" component={waitFor(InventoryWarehouseReport)} />
                                    <Route path="/inventoryexpiredreport" component={waitFor(InventoryExpiredReport)} />
                                    <Route path="/inventoryvaluedreport" component={waitFor(InventoryValuedReport)} />
                                    <Route path="/inventorydiscardedreport" component={waitFor(InventoryDiscardedReport)} />
                                    <Route path="/inventoryrepairablereport" component={waitFor(InventoryRepairableReport)} />
                                    <Route path="/incomewarehousereport" component={waitFor(IncomeWarehouseReport)} />
                                    <Route path="/inventorytoexpirereport" component={waitFor(InventoryToExpireReport)} />
                                    <Route path="/pruebahook" component={waitFor(PruebaHookTest)} />
                                    <Route path="/roles" component={waitFor(Roles)} />
                                    <Route path="/materialreceptionreport" component={waitFor(MaterialReceptionReport)} />
                                    <Route path="/purchaserequirementdetailreport" component={waitFor(PurchaseRequirementDetailReport)} />
                                    <Route path="/purchaseorderbydatereport" component={waitFor(PurchaseOrderByDateReport)} />
                                    <Route path="/materialavaiblestockminandumbral" component={waitFor(MaterialAvaibleStockMinAndUmbral)} />
                                    <Route path="/expiredavailableandrepairedmaterial" component={waitFor(ExpiredAvailableAndRepairedMaterialReport)} />
                                    <Route path="/roles" component={waitFor(Rol)} />
                                    <Route path="/recordmaterialremoved" component={waitFor(Recordmaterialremoved)} />
                                    <Route path="/materialremovedbyconditionreports" component={waitFor(MaterialRemovedByConditionReports)} />
                                    <Route path="/itemmasterisactiveorderbycategories" component={waitFor(ItemMasterIsActiveOrderByCategories)} />
                                    <Route path="/materialexpirebyrange" component={waitFor(MaterialToExpire)} />
                                    <Route path="/materialloanreport" component={waitFor(MaterialLoanReport)} />
                                    <Route path="/hardtimereport" component={waitFor(HardTimeMaterialReport)} />
                                    <Route path="/materialremovedbyaircraftanddate" component={waitFor(MaterialRemovedByAircraftAndDate)} />
                                    <Route path="/iteminventoryconsumeoilreportbyrangeofdate" component={waitFor(ItemInventoryOilConsumeReport)} />
                                    <Route path="/specialtoolsreportbyrangeofdate" component={waitFor(SpecialToolsReportByDateRange)} />
                                    <Route path="/materialnexttoarrive" component={waitFor(MaterialNextToArrive)} />
                                    <Route path="/materialrequestbyaircraftanddate" component={waitFor(MaterialRequestByAircraftAndDate)} />
                                    <Route path="/wheelsandtyresactivesreport" component={waitFor(WheelAndTyresActive)} />
                                    <Route path="/warehouseitemsactivebylocation" component={waitFor(WarehouseItemsActivesByLocation)} />
                                    <RouteComponents />
                                    <Redirect to="/login" />
                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Base>
        )
    }
}

export default withRouter(Routes);
