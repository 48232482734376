import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import ContentWrapper from '../../Layout/ContentWrapper';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// import { AtaListAction } from '../../store/actions/Ata.actions';
import RubberAssembledForm from './RubberAssemblyForm';
// Otras Utilidades para ocultar Tabs y manejo de las fechas.
import RubberAssemblyComponentsDetail from './RubberAssemblyComponentsDetail';
import componentAssembledHeader from '../../../services/componentAssembledHeader';
import { ComponentAssembledHeaderGetAction } from '../../../store/actions/ComponentAssembled.actions';

const RubberAssemblyDetail = ({ tabTitle, isNew, detailId }) => {
    // State para Airplane Component
    const dispatch = useDispatch();

    const asyncData = async () => {
        if (detailId) {
            const { data } = await componentAssembledHeader.findById(detailId);
            if (data && data.ok) dispatch(ComponentAssembledHeaderGetAction(data.result));
        } else {
            dispatch(ComponentAssembledHeaderGetAction({}));
        }
        return 'Run';
    };

    // Redux
    useEffect(() => {
        asyncData().then((r) => console.log(r));
    }, [dispatch]);

    const dataForm = useSelector((state) => state.ComponentAssembledHeader.headerData);

    return (
        <ContentWrapper>
            <div>
                <div className="content-heading">
                    <div>
                        <h3>{tabTitle}</h3>
                    </div>
                </div>
                <div className="tab-contents">
                    <div>
                        <RubberAssembledForm
                            detailId={dataForm.id}
                            isNew={isNew}
                            dataForm={dataForm}
                        />
                    </div>
                    <div>
                        {dataForm.id ? (
                            <RubberAssemblyComponentsDetail headerId={dataForm.id} />
                        ) : null}
                    </div>
                </div>
            </div>
        </ContentWrapper>
    );
};

export default withNamespaces('translations')(RubberAssemblyDetail);
