import {
    WAREHOUSE_LIST,
    WAREHOUSE_LIST_SUCCESS,
    WAREHOUSE_LIST_ERROR,
    WAREHOUSE_DDLIST,
    WAREHOUSE_DDLIST_SUCCESS,
    WAREHOUSE_DDLIST_ERROR,
    WAREHOUSE_GET,
    WAREHOUSE_GET_SUCCESS,
    WAREHOUSE_GET_ERROR,
    WAREHOUSE_NEW,
    WAREHOUSE_NEW_SUCCESS,
    WAREHOUSE_NEW_ERROR,
    WAREHOUSE_NEW_RECORD,
    WAREHOUSE_EDIT,
    WAREHOUSE_EDIT_SUCCESS,
    WAREHOUSE_EDIT_ERROR
} from '../types';

const initialState = {
    warehouses: [],
    warehouse: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case WAREHOUSE_LIST:
            return {
                ...state,
                loading: true,
                warehouse: {}
            }
        case WAREHOUSE_LIST_SUCCESS:
            return {
                ...state,
                warehouses: action.payload,
                loading: false,
                error: false,
                warehouse: {}
            }
        case WAREHOUSE_LIST_ERROR:
            return {
                ...state,
                warehouses: [],
                loading: false,
                error: true,
                warehouse: {}
            }
        case WAREHOUSE_DDLIST:             
            return {
                ...state,
                loading: true,
                warehouse: {}
            }
        case WAREHOUSE_DDLIST_SUCCESS:
            return {
                ...state,
                warehouses: action.payload,
                loading: false,
                error: false,
                warehouse: {}
            }        
        case WAREHOUSE_DDLIST_ERROR:
            return {
                ...state,
                warehouses: [],
                loading: false,
                error: true,
                warehouse: {}
            }

        case WAREHOUSE_GET:             
            return {
                ...state,
                loading: true
            }

        case WAREHOUSE_GET_SUCCESS:
            return {
                ...state,
                warehouse: action.payload,
                loading: false,
                error: false
            }    

        case WAREHOUSE_GET_ERROR:
            return {
                ...state,
                error: true,
                warehouse: {}
            }

        case WAREHOUSE_NEW:
            return {
                ...state,
                loading: true,
            }
        case WAREHOUSE_NEW_SUCCESS:
            return {
                ...state,
                warehouses: [...state.warehouses, action.payload],
                warehouse: {},
                loading: false,
                error: false
            }
        case WAREHOUSE_NEW_ERROR:
            return {
                ...state,
                loading: false,
                error: true
            }
        case WAREHOUSE_NEW_RECORD:
            return {
                ...state,
                warehouse: {},
                error: false
            }
        case WAREHOUSE_EDIT:
            return {
                ...state
            }
        case WAREHOUSE_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                warehouse: action.payload,
                warehouses: state.warehouses.map(warehouse => warehouse.id === action.payload.id ? warehouse = action.payload : warehouse),
            }
        case WAREHOUSE_EDIT_ERROR:
            return {
                ...state,
                error: false
            }        
        default:
            return state;
    }
}