import React, { Fragment, useState, useEffect } from 'react';
//import { withNamespaces } from 'react-i18next';
import TableSAM from '../../../Common/TableSAM'

// import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Input,
//          FormGroup, TabContent, TabPane, Nav, NavItem, NavLink, Spinner
// } from 'reactstrap';

import {formatDate, formatValue} from '../../../../Utils/SAMUtils';
import ControlMaintenanceModal from './ControlMaintenanceModal';
import ControlMaintenanceLastComplianceModal from './ControlMaintenanceLastComplianceModal';
import Swal from 'sweetalert2';

// Redux
import {useDispatch, useSelector} from 'react-redux';
import {AirplaneControlMaintenanceListAction} from '../../../../store/actions/AirplaneControlMaintenance.actions';
import {AirplaneTaskCardListAction} from '../../../../store/actions/AirplaneTaskCard.actions';
import {OmaDDListAction} from '../../../../store/actions/Oma.actions';
import {IntervalDDListAction} from '../../../../store/actions/Interval.actions';
import { EntityMdDDListAction } from '../../../../store/actions/EntityMd.actions';

//import {AtaDDListAction} from '../../../../store/actions/Ata.actions';
//import {AirplaneDDListAction} from '../../../../store/actions/Airplane.actions';

//import PackageWorkModal from './PackageWorkModal';

export default function ControlMaintenance(props) {
   // Valores para SAMTable
   const tableTitle = `Maintenance Control Airplane (${props.acronym}-${props.airplane_name})`;
   
   const columns = [{ title: 'Task Description', field: 'airplanetaskcard.label'},
                    { title: 'Last Accomplish Date', 
                        field: 'last_compliance_date',
                        render: rowData => <div>{formatDate(rowData.last_compliance_date)}</div>              
                    },
                    { title: 'Last Accomplish Horas/FH', field: 'last_compliance_hours'},
                    { title: 'Last Accomplish Ciclos/FCY', field: 'last_compliance_cycles'},
                    { title: 'Next Accomplish Date', 
                        field: 'next_compliance_date',
                        render: rowData => <div>{formatDate(rowData.last_compliance_date)}</div>              
                    },
                    { title: 'Next Accomplish Next Due', field: 'next_compliance_date'},
                    
                    { title: 'Next Accomplish Horas/FH', field: 'next_compliance_hours'},

                    //{ title: 'Next Accomplish Remanente/Next Due', field: 'mpd'},
                    { title: 'Next Accomplish Ciclos/FCY', field: 'next_compliance_cycles'},
                    //{ title: 'Next Accomplish Remanente /Next Due', field: 'mpd'},

                    { title: 'Expiration Date', 
                        field: 'expiration_date',
                        render: rowData => <div>{formatDate(rowData.expiration_date)}</div>              
                    },
                    { title: '# PT or # IT ', field: 'pt_it'},
                    { title: 'OMA', field: 'oma.label'},
                    { title: 'Applicability', field: 'applicability',     
                        render : rowData => (rowData.status?
                            <div className="badge badge-danger">No</div>:
                            <div className="badge badge-success">Si</div>)
                    }
                   ];

   // Variables usadas en TaskCard
   const [id, setId] = useState(0);
   const [modal, setModal] = useState(false);
   const [modalLastCompliance, setModalLastCompliance] = useState(false);
   const [isNew, setIsNew] = useState(false);
   const [modalTitle, setModalTitle] = useState('');

   // Dispatch Redux para traer acciones
   const dispatch = useDispatch();

   // UseEffect para traer lista de TaskCard por Aeronave
   useEffect(() => {
       dispatch(AirplaneControlMaintenanceListAction(props.id))
       dispatch( AirplaneTaskCardListAction(props.airplane_id));
       dispatch(OmaDDListAction());
       dispatch( IntervalDDListAction());
       dispatch( EntityMdDDListAction(process.env.REACT_APP_MAINTENANCE_AIRPLANE));
       //dispatch( AirplaneDDListAction());
       //dispatch( AtaDDListAction());
       //dispatch( IntervalDDListAction());
   }, [props.id, dispatch]);

   // Acceder al state 
  const data  = useSelector( state => state.AirplaneControlMaintenance.airplanecontrolmaintenances); 

  const error = useSelector( state => state.AirplaneControlMaintenance.error);

  // Trae la lista de valores de TaskCard Por Aeronave
  const taskCardList = useSelector(state=>state.AirplaneTaskCard.airplanetaskcardList);

  const omaList = useSelector((state) => state.Oma.omas);

  // Trae la lista de valores de Aeronaves 
  const airplaneList = useSelector(state=>state.Airplane.airplaneList);
  
  // Trae la lista de valores de Intervalos
  const intervalList = useSelector(state=>state.Interval.intervalddList);
 
  // Trae el listado de Tipos de Mantenimientos
  const maintenanceTypeList = useSelector(state=>state.EntityMd.maintenanceTypeList);

;
   const newData = ()=>{
       setId(0);
       setModal(true);
       setIsNew(true); 
       setModalTitle('New Maintenance Control');
   }

   const editData = (data) =>{
       setId(data.id);
       setModal(true); 
       setIsNew(false);
       setModalTitle('Edit Maintenance Control');
   }

   const viewLastCompliance = (data) =>{
    setId(data.id);
    setModalLastCompliance(true); 
    setIsNew(false);
    setModalTitle('Edit Last Compliance Maintenance Control');

   }
 
   const saveData = (record)=>{ 

       //dispatch (AirplaneManualSaveAction(record, isNew));

       if(!error){
           if(!isNew){
               Swal.fire({
                   icon:'success',
                   title: 'Edición de Control de Mantenimiento',
                   text: 'Control de Mantenimiento se ha editado correctamente'
                   });
           }else{
               Swal.fire({
                   icon:'success',
                   title: 'Crear Control de Mantenimiento',
                   text: 'Control de Mantenimiento se ha creado correctamente'
                   });
           }
           toggleModal();
       }else{
           if(!isNew){
               Swal.fire({
                   icon:'error',
                   title: 'Edición de Control de Mantenimiento de Aeronave',
                   text: 'Error al editar Control de Mantenimiento de Aeronave!'
                   });
           }else{
               Swal.fire({
                   icon:'error',
                   title: 'Crear Control de Mantenimiento de Aeronave',
                   text: 'Error al crear Control de Mantenimiento de Aeronave!'
                   });
           }
       }

       setModalTitle('');
   };

   // Método para abrir Modal de Editar o Incluir Registros.
   const toggleModal = () => {
       if(modal) setId(0);
       setModal(!modal);
   }

    // Método para abrir Modal de Editar o Incluir Registros.
    const toggleModalLastCompliance = () => {
        if(modalLastCompliance) setId(0);
        setModalLastCompliance(!modalLastCompliance);
    }

   // Setea las Acciones de la SAM Table
   const actions=[
       {
           icon: 'add_circle',
           iconProps:{
               color: "primary"
           },
           tooltip: 'New Maintenance Control',
           isFreeAction: true,
           onClick: (event) => newData()
       },
       {
           icon: 'edit',
           tooltip: 'Edit Maintenance Control',
           onClick: (event, rowData) => editData(rowData)
       },  
       
       {
            icon: 'view_compact',
            tooltip: 'Last Compliance',
            onClick: (event, rowData) => viewLastCompliance(rowData)
        },
   ];

   const applyOptions = false;

   return (
       <Fragment>
           <TableSAM
               titleTable={tableTitle}
               columns={columns}
               data={data}
               applyOptions={applyOptions}
               actions={actions}
           />
           <ControlMaintenanceModal 
               modalTitle={modalTitle}
               modal={modal}
               isNew={isNew}               
               id={id}
               toggleModal={toggleModal}               
               airplaneList={airplaneList}
               taskCardList={taskCardList}
               intervalList={intervalList}
               omaList={omaList}
               maintenanceTypeList={maintenanceTypeList}

           />
           <ControlMaintenanceLastComplianceModal 
               modalTitle={modalTitle}
               modalLastCompliance={modalLastCompliance}
               isNew={isNew}               
               id={id}
               toggleModalLastCompliance={toggleModalLastCompliance}               
               airplaneList={airplaneList}
               taskCardList={taskCardList}
               intervalList={intervalList}
               omaList={omaList}
               maintenanceTypeList={maintenanceTypeList}

           />
       </Fragment>
   )
   
}