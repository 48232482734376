import {
    MANUFACTURER_LIST,
    MANUFACTURER_LIST_SUCCESS,
    MANUFACTURER_LIST_ERROR,
    MANUFACTURER_DDLIST,
    MANUFACTURER_DDLIST_SUCCESS,
    MANUFACTURER_DDLIST_ERROR,
    // MANUFACTURER_NEW,
    // MANUFACTURER_NEW_SUCCESS,
    // MANUFACTURER_NEW_ERROR,
    // MANUFACTURER_EDIT,
    // MANUFACTURER_EDIT_SUCCESS,
    // MANUFACTURER_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener Fabricantes
export function ManufacturerListAction(){
    
    return (dispatch =>{
        dispatch(ManufacturerList());
        AxiosClient.get(`${process.env.REACT_APP_MANUFACTURER}`)
            .then((response)=> {
                dispatch( ManufacturerListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( ManufacturerListError(error) );
            });
    });
}

export const ManufacturerList = () => ({
    type: MANUFACTURER_LIST
});

export const ManufacturerListSuccess = (records) => ({
    type: MANUFACTURER_LIST_SUCCESS,
    payload: records
});

export const ManufacturerListError = (error) => ({
    type: MANUFACTURER_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Fabricantes
export function ManufacturerDDListAction(){
    return (dispatch =>{
        dispatch(ManufacturerDDList());
        AxiosClient.get(`${process.env.REACT_APP_MANUFACTURER}/ddlist`)
            .then((response)=> {
                dispatch( ManufacturerDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( ManufacturerDDListError(error) );
            });
    });
}

export const ManufacturerDDList = () => ({
    type: MANUFACTURER_DDLIST
});

export const ManufacturerDDListSuccess = (records) => ({
    type: MANUFACTURER_DDLIST_SUCCESS,
    payload: records
});

export const ManufacturerDDListError = (error) => ({
    type: MANUFACTURER_DDLIST_ERROR,
    payload: error
});