import {
    COMPONENT_ASSEMBLED_ITEM_GET,
    COMPONENT_ASSEMBLED_ITEM_GET_ERROR,
    COMPONENT_ASSEMBLED_ITEM_GET_SUCCESS,
    COMPONENT_ASSEMBLED_ITEM_LIST,
    COMPONENT_ASSEMBLED_ITEM_LIST_ERROR,
    COMPONENT_ASSEMBLED_ITEM_LIST_SUCCESS
} from "../types";

// Función Principal para obtener lista de Unidades de Medida
export const ComponentAssembledDetailListAction = (payload) => (dispatch) => {
    dispatch(ComponentAssembledDetailList());
    if (payload && payload.length) dispatch(ComponentAssembledDetailListSuccess(payload));
    else dispatch(ComponentAssembledDetailListError('List not found'));
};

export const ComponentAssembledDetailList = () => ({
    type: COMPONENT_ASSEMBLED_ITEM_LIST,
});

export const ComponentAssembledDetailListSuccess = (records) => ({
    type: COMPONENT_ASSEMBLED_ITEM_LIST_SUCCESS,
    payload: records,
});

export const ComponentAssembledDetailListError = (error) => ({
    type: COMPONENT_ASSEMBLED_ITEM_LIST_ERROR,
    payload: error,
});

export const ComponentAssembledDetailGetAction = (payload = null) => (dispatch) => {
    dispatch(ComponentAssembledDetailFind());
    if (payload) dispatch(ComponentAssembledDetailGetSuccess(payload));
    else dispatch(ComponentAssembledDetailGetError('Detail not found'));
};

export const ComponentAssembledDetailFind = () => ({
    type: COMPONENT_ASSEMBLED_ITEM_GET,
});

export const ComponentAssembledDetailGetSuccess = (records) => ({
    type: COMPONENT_ASSEMBLED_ITEM_GET_SUCCESS,
    payload: records,
});

export const ComponentAssembledDetailGetError = (error) => ({
    type: COMPONENT_ASSEMBLED_ITEM_GET_ERROR,
    payload: error,
});
