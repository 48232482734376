import {
    AIRPORTIATA_LIST,
    AIRPORTIATA_LIST_SUCCESS,
    AIRPORTIATA_LIST_ERROR,
    AIRPORTIATA_DDLIST,
    AIRPORTIATA_DDLIST_SUCCESS,
    AIRPORTIATA_DDLIST_ERROR,
    AIRPORTIATA_GET,
    AIRPORTIATA_GET_SUCCESS,
    AIRPORTIATA_GET_ERROR,
    AIRPORTIATA_NEW,
    AIRPORTIATA_NEW_SUCCESS,
    AIRPORTIATA_NEW_ERROR,
    AIRPORTIATA_NEW_RECORD,
    AIRPORTIATA_EDIT,
    AIRPORTIATA_EDIT_SUCCESS,
    AIRPORTIATA_EDIT_ERROR,
    AIRPORTIATA_DDLIST_CODE,
    AIRPORTIATA_DDLIST_CODE_SUCCESS,
    AIRPORTIATA_DDLIST_CODE_ERROR,
    AIRPORTIATA_DELETE,
    AIRPORTIATA_DELETE_SUCCESS,
    AIRPORTIATA_DELETE_ERROR,
    AIRPORTIATA_CITY_DD_LIST,
    AIRPORTIATA_CITY_DD_LIST_SUCCESS,
    AIRPORTIATA_CITY_DD_LIST_ERROR

} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Aeropuertos
export function AirporIataListAction() {

    return (dispatch => {
        dispatch(AirportIataList());
        AxiosClient.get(`${process.env.REACT_APP_AIRPORTIATA}/findAll`)
            .then((response) => {
                dispatch(AirportIataListSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(AirportIataListError(error));
            });
    });
}

export const AirportIataList = () => ({
    type: AIRPORTIATA_LIST
});

export const AirportIataListSuccess = (records) => ({
    type: AIRPORTIATA_LIST_SUCCESS,
    payload: records
});

export const AirportIataListError = (error) => ({
    type: AIRPORTIATA_LIST_ERROR,
    payload: error
});

// Función Principal para obtener un Aeropuerto
export function AirportIataGetAction(id) {
    return (dispatch => {
        dispatch(AirportIataGet());
        AxiosClient.get(`${process.env.REACT_APP_AIRPORTIATA}/findOne/${id}`)
            .then((response) => {
                dispatch(AirportIataGetSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(AirportIataEditError(error));
            });
    });
}

export const AirportIataGet = () => ({
    type: AIRPORTIATA_GET
});

export const AirportIataGetSuccess = (record) => ({
    type: AIRPORTIATA_GET_SUCCESS,
    payload: record
});

export const AirportIataMdGetError = (error) => ({
    type: AIRPORTIATA_GET_ERROR,
    payload: error
});


// Función Principal para obtener la Lista de Aeropuertos en forma de DropDown
export function AirportIataDDListAction() {
    return (dispatch => {
        dispatch(AirportIataDDList());
        AxiosClient.get(`${process.env.REACT_APP_AIRPORTIATA}/ddlist`)
            .then((response) => {
                dispatch(AirportIataDDListSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(AirportIataDDListError(error));
            });
    });
}
export const AirportIataDDList = () => ({
    type: AIRPORTIATA_DDLIST
});

export const AirportIataDDListSuccess = (records) => ({
    type: AIRPORTIATA_DDLIST_SUCCESS,
    payload: records
});

export const AirportIataDDListError = (error) => ({
    type: AIRPORTIATA_DDLIST_ERROR,
    payload: error
});


// Funcion principal para obtener los codigos de Aeropuerto
export function AirportIataDDListCodeAction(code = 0) {
    return (dispatch => {
        dispatch(AirportIataDDListCode());
        AxiosClient.get(`${process.env.REACT_APP_AIRPORTIATA}/ddlistByCode/${code}`)
            .then((response) => {
                dispatch(AirportIataDDListCodeSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(AirportIataDDListCodeError(error));
            });
    });
}
export const AirportIataDDListCode = () => ({
    type: AIRPORTIATA_DDLIST_CODE
});

export const AirportIataDDListCodeSuccess = (records) => ({
    type: AIRPORTIATA_DDLIST_CODE_SUCCESS,
    payload: records
});

export const AirportIataDDListCodeError = (error) => ({
    type: AIRPORTIATA_DDLIST_CODE_ERROR,
    payload: error
});

//Función Principal para setear objeto de Aeropuerto cuando se va crear un registro nuevo
export function AirportIataNewAction() {
    return (dispatch => {
        dispatch(AirportIataNewRecord());
    });
}
export const AirportIataNewRecord = () => ({
    type: AIRPORTIATA_NEW_RECORD
});

// Función Principal para crear registros de Aeropuertos

export function AirportIataCreateAction(record) {
    return (dispatch => {
        dispatch(AirportIataNew());
        AxiosClient.post(`${process.env.REACT_APP_AIRPORTIATA}`, record)
            .then((response) => {
                record.id = response.data.result.id;
                dispatch(AirportIataNewSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(AirportIataNewError(error));
            });
    })

}

export const AirportIataNew = () => ({
    type: AIRPORTIATA_NEW
});

export const AirportIataNewSuccess = (record) => ({
    type: AIRPORTIATA_NEW_SUCCESS,
    payload: record
});

export const AirportIataNewError = (error) => ({
    type: AIRPORTIATA_NEW_ERROR,
    payload: error
});

// Función Principal para editar registros de Aeropuertos

export function AirportIataEditAction(record) {
    return (dispatch => {
        dispatch(AirportIataEdit());
        AxiosClient.put(`${process.env.REACT_APP_AIRPORTIATA}`, record)
            .then((response) => {
                record.id = response.data.result.id;
                dispatch(AirportIataEditSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(AirportIataEditError(error));
            });
    })

}


export const AirportIataEdit = () => ({
    type: AIRPORTIATA_EDIT
});

export const AirportIataEditSuccess = (record) => ({
    type: AIRPORTIATA_EDIT_SUCCESS,
    payload: record
});

export const AirportIataEditError = (error) => ({
    type: AIRPORTIATA_EDIT_ERROR,
    payload: error
});

//  Delete record airport 

export function AirportIataDeleteAction(record) {
    return (dispatch => {
        dispatch(AirportIataDelete());
        AxiosClient.post(`${process.env.REACT_APP_AIRPORTIATA}/destroy`, record)
            .then((response) => {
                record.id = response.data.result.id;
                dispatch(AirportIataDeleteSuccess(record));
            })
            .catch(error => {
                dispatch(AirportIataDeleteError(error));
            });
    })

}

export const AirportIataDelete = () => ({
    type: AIRPORTIATA_DELETE
});

export const AirportIataDeleteSuccess = (record) => ({
    type: AIRPORTIATA_DELETE_SUCCESS,
    payload: record
});

export const AirportIataDeleteError = (error) => ({
    type: AIRPORTIATA_DELETE_ERROR,
    payload: error
});


// Función Principal para obtener lista de ciudades
export function AirporIataCityDDListAction() {

    return (dispatch => {
        dispatch(AirporIataCityDDList());
        AxiosClient.get(`${process.env.REACT_APP_CITY}/findAll/ddlist`)
            .then((response) => {
                dispatch(AirporIataCityDDListSuccess(response.data.result));
            })
            .catch(error => {
                dispatch(AirporIataCityDDListError(error));
            });
    });
}

export const AirporIataCityDDList = () => ({
    type: AIRPORTIATA_CITY_DD_LIST
});

export const AirporIataCityDDListSuccess = (records) => ({
    type: AIRPORTIATA_CITY_DD_LIST_SUCCESS,
    payload: records
});

export const AirporIataCityDDListError = (error) => ({
    type: AIRPORTIATA_CITY_DD_LIST_ERROR,
    payload: error
});