import {
    AIRWORTHINESSDIRECTIVETASK_CLEAN,
    AIRWORTHINESSDIRECTIVETASK_EDIT,
    AIRWORTHINESSDIRECTIVETASK_EDIT_ERROR,
    AIRWORTHINESSDIRECTIVETASK_EDIT_SUCCESS,
    AIRWORTHINESSDIRECTIVETASK_GET,
    AIRWORTHINESSDIRECTIVETASK_GET_ERROR,
    AIRWORTHINESSDIRECTIVETASK_GET_SUCCESS,
    AIRWORTHINESSDIRECTIVETASK_LIST,
    AIRWORTHINESSDIRECTIVETASK_LIST_ERROR,
    AIRWORTHINESSDIRECTIVETASK_LIST_SUCCESS,
    AIRWORTHINESSDIRECTIVETASK_NEW,
    AIRWORTHINESSDIRECTIVETASK_NEW_ERROR,
    AIRWORTHINESSDIRECTIVETASK_NEW_SUCCESS
} from "../types";

const initialState = {
    airworthinessdirectivetasks: [],
    airworthinessdirectivetask: {},
    loading: false,
    error: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case AIRWORTHINESSDIRECTIVETASK_LIST:
            return {
                ...state,
                loading: true,
                airworthinessdirectivetask: {}
            };

        case AIRWORTHINESSDIRECTIVETASK_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                airworthinessdirectivetasks: action.payload,
                airworthinessdirectivetask: {}
            };

        case AIRWORTHINESSDIRECTIVETASK_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                airworthinessdirectivetasks: [],
                airworthinessdirectivetask: {}
            };

        case AIRWORTHINESSDIRECTIVETASK_GET:
            return {
                ...state,
                loading: true,
                error: false,
                airworthinessdirectivetask: {}
            }

        case AIRWORTHINESSDIRECTIVETASK_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                airworthinessdirectivetask: action.payload
            };

        case AIRWORTHINESSDIRECTIVETASK_GET_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                airworthinessdirectivetasks: [],
                airworthinessdirectivetask: {}
            };
        case AIRWORTHINESSDIRECTIVETASK_CLEAN:
            return {
                ...state,
                airworthinessdirectivetask: {}
            };

        case AIRWORTHINESSDIRECTIVETASK_NEW: 
            return {
                ...state,
                loading: true,
                error: false
            };

        case AIRWORTHINESSDIRECTIVETASK_NEW_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                airworthinessdirectivetasks: [...state.airworthinessdirectivetasks, action.payload],
                airworthinessdirectivetask: action.payload
            };

        case AIRWORTHINESSDIRECTIVETASK_NEW_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                airworthinessdirectivetasks: [],
                airworthinessdirectivetask: {}
            }
        
        case AIRWORTHINESSDIRECTIVETASK_EDIT:
            return {
                ...state,
                loading: true,
                error: false
            };

        case AIRWORTHINESSDIRECTIVETASK_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                airworthinessdirectivetask: action.payload,
                airworthinessdirectivetasks: state.airworthinessdirectivetasks.map(airworthinessdirectivetask => (airworthinessdirectivetask.id === action.payload.id ? action.payload : airworthinessdirectivetask))
            };

        case AIRWORTHINESSDIRECTIVETASK_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                airworthinessdirectivetasks: [],
                airworthinessdirectivetask: {}
            };

        default:
            return state;
    }
};