import React from 'react';
import MaterialTable from 'material-table';
import Icons from '@material-ui/core/Icon';

function TableSAM({
    applyOptions,
    options,
    titleTable,
    columns,
    data,
    editable,
    localization,
    actions,
    onSelectionChange,
    cellEditable,
    ...props
}) {

    let filtering = false
    if (applyOptions) {
        filtering = options.filtering ? true : false
    }

    let _options = {
        //actionsColumnIndex: -1,
        headerStyle: {
            backgroundColor: '#fad732',
            color: '#000',
            position: 'relative',
            zIndex: '0',
            fontSize: '90%',
            textAlign: 'center',
            padding: '10px 10px 10px 10px'
        },
        cellStyle: {
            textAlign: 'center'
        },
        pageSize: 5,
        filtering,
        grouping: false,
        exportButton: false
    };
    
    const _localization = {
        pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
            labelRowsPerPage: 'Rows per pages:',
            labelRowsSelect: 'Rows',
            firstAriaLabel: 'First',
            firstTooltip: 'First',
            previousAriaLabel: 'Previous',
            previousTooltip: 'Previous',
            nextAriaLabel: 'Next',
            nextTooltip: 'Next',
            lastAriaLabel: 'Lastest',
            lastTooltip: 'Lastest',
        },
        toolbar: {
            nRowsSelected: '{0} row(s) selected',
            searchTooltip: 'Search',
            searchPlaceholder: 'Search'
        },
        header: {
            actions: 'Actions'
        },
        body: {
            filterRow:{
                filterTooltip: 'Filter'
            },
            addTooltip: 'New',
            deleteTooltip: 'Delete',
            editTooltip: 'Edit',
            editRow: {
                cancelTooltip: 'Cancel',
                saveToolTip: 'Save',
                deleteText: 'Are you sure you want to delete the record?'           
            },
            emptyDataSourceMessage: 'No records found'
        },
        grouping: {
            placeholder: "Drag headings here to group by "
        }
    };

    if(applyOptions){
        _options.exportButton = (options.exportButton!==undefined&&options.exportButton!==null?options.exportButton:true);
        _options.grouping = (options.grouping!==undefined&&options.grouping!==null?options.grouping:false);        
        _options.selection = (options.selection!==undefined&&options.selection!==null?options.selection:false);
        _options.search = (options.search!==undefined&&options.search!==null?options.search:true);
        if(options.fixedColumns!==undefined&&options.fixedColumns!==null)
            _options.fixedColumns = {
                left: (options.fixedColumns.left!==undefined&&options.fixedColumns.left!==null?options.fixedColumns.left:0),
                right: (options.fixedColumns.right!==undefined&&options.fixedColumns.right!==null?options.fixedColumns.right:0)
            }
        _options.paging =options.paging !== null ? options.paging : true;
        _options.pageSize = options.pageSize ? options.pageSize : options.pageSize;
    } 

    return (
        <MaterialTable
            Icons={Icons}
            title={titleTable}
            columns={columns}
            data={data}
            editable={editable}
            localization={{..._localization, ...localization}}
            options={_options}
            actions={actions}
            onSelectionChange={onSelectionChange}
            cellEditable={cellEditable}
            {...props}
        />
    )
}

export default TableSAM;