import { withNamespaces } from 'react-i18next';
import TableSAM from '../../Common/TableSAM';
import { WHEELS_RUBBERS_ITEMS } from './RubberAssemblyLabel';
import ComponentSelector from './ComponentSelector';
import React, { useEffect, useState } from 'react';
import { PART_NUMBER_LABEL, SUB_CATEGORY_LABEL } from '../ItemMaster/itemMasterConstants';
import {
    SERIAL_NUMBER_LABEL,
    SERIAL_TWO_LABEL,
} from '../../Supply/MaterialReception/materialReceptionConstants';
import componentAssembledDetail from '../../../services/componentAssembledDetail';
import { ComponentAssembledDetailListAction } from '../../../store/actions/ComponentAssembledDetail.actions';
import { useDispatch, useSelector } from 'react-redux';

const RubberAssemblyComponentsDetail = ({ headerId }) => {

    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [searchCode, setSearchCode] = useState('');

    const columns = [
        {
            title: PART_NUMBER_LABEL,
            field: 'part_number',
            render: (rowData) => rowData.fullitemmaster?.part_number,
        },
        {
            title: SUB_CATEGORY_LABEL,
            field: 'subcategory',
            render: (rowData) => rowData.subcategory?.name,
        },
        {
            title: SERIAL_NUMBER_LABEL,
            field: 'serial',
            render: (rowData) =>
                rowData.serial ? rowData.serial : rowData.iteminventory?.serial_number,
        },
        {
            title: SERIAL_TWO_LABEL,
            field: 'serial_two',
            render: (rowData) =>
                rowData.serial_two ? rowData.serial_two : rowData.iteminventory?.serial_two,
        },
    ];

    const newData = (_searchCode) => {
        setModal(true);
        setSearchCode(_searchCode);
        // setIsNew(true);
        setModalTitle('Assemble components');
    };
    // Método para abrir Modal de Editar o Incluir Registros.
    const toggleModal = () => {
        setModal(!modal);
        if (modal) {
            asyncData().then((r) => console.log(r));
        }
    };

    // Setea
    const actions = [
        {
            text: 'Create or replace Components',
            icon: 'add_circle',
            iconProps: {
                color: 'primary',
            },
            tooltip: 'Create or replace Components',
            isFreeAction: true,
            onClick: () => newData('WHE'),
        },
    ];

    const applyOptions = true;

    const tableOptions = {
        paging: false,
        exportButton: false,
    };

    const asyncData = async () => {
        if (headerId) {
            const { data } = await componentAssembledDetail.findByParentId(headerId);
            dispatch(ComponentAssembledDetailListAction(data?.result));
        }
        return 'Run';
    };

    const dispatch = useDispatch();
    // Redux
    useEffect(() => {
        asyncData().then((r) => console.log(r));
    }, [dispatch]);

    const data = useSelector((state) => state.ComponentAssembledDetail.detailList);

    return (
        <div>
            <div>
                {headerId ? (
                    <TableSAM
                        titleTable={WHEELS_RUBBERS_ITEMS}
                        columns={columns}
                        data={data}
                        applyOptions={applyOptions}
                        actions={actions}
                        options={tableOptions}
                    />
                ) : null}
            </div>
            {modal ? (
                <ComponentSelector
                    modal={modal}
                    toggleModal={toggleModal}
                    title={modalTitle}
                    header_id={headerId}
                    search_code={searchCode}
                />
            ) : null}
        </div>
    );
};

export default withNamespaces('translations')(RubberAssemblyComponentsDetail);
