import {
    ITEMWARRANTY_LIST,
    ITEMWARRANTY_LIST_SUCCESS,
    ITEMWARRANTY_LIST_ERROR 
} from "../types";

const initialState = {
    itemWarrantyList: [],
    itemWarranty: {},
    loading: false,
    error: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ITEMWARRANTY_LIST:
            return {
                ...state,
                loading: true,
                itemWarrantyList: [],
                itemWarranty: {},
            };
        case ITEMWARRANTY_LIST_SUCCESS:
            return {
                ...state,
                itemWarrantyList: action.payload,
                loading: false,
                error: false,
                itemWarranty: {},
            };
        case ITEMWARRANTY_LIST_ERROR:
            return {
                ...state,
                itemWarrantyList: [],
                loading: false,
                error: true,
                itemWarranty: {},
            };
        default:
            return state;
    }
}
