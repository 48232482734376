import {
    PAYMENTSCHEDULE_LIST,
    PAYMENTSCHEDULE_LIST_SUCCESS,
    PAYMENTSCHEDULE_LIST_ERROR,
    PAYMENTSCHEDULE_DDLIST,
    PAYMENTSCHEDULE_DDLIST_SUCCESS,
    PAYMENTSCHEDULE_DDLIST_ERROR,
    PAYMENTSCHEDULE_GET,
    PAYMENTSCHEDULE_GET_SUCCESS,
    PAYMENTSCHEDULE_GET_ERROR,
    PAYMENTSCHEDULE_NEW,
    PAYMENTSCHEDULE_NEW_SUCCESS,
    PAYMENTSCHEDULE_NEW_ERROR,
    PAYMENTSCHEDULE_NEW_RECORD,
    PAYMENTSCHEDULE_EDIT,
    PAYMENTSCHEDULE_EDIT_SUCCESS,
    PAYMENTSCHEDULE_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Programación de Pagos
export function PaymentScheduleListAction(){    
    return (dispatch =>{
        dispatch(PaymentScheduleList());
        AxiosClient.get(`${process.env.REACT_APP_PAYMENTSCHEDULE}`)
            .then((response)=> {              
                dispatch( PaymentScheduleListSuccess(response.data.result));
            })
            .catch(error=> {                
                dispatch( PaymentScheduleListError(error) );
            });
    });
}

export const PaymentScheduleList = () => ({
    type: PAYMENTSCHEDULE_LIST
});

export const PaymentScheduleListSuccess = (records) => ({
    type: PAYMENTSCHEDULE_LIST_SUCCESS,
    payload: records
});

export const PaymentScheduleListError = (error) => ({
    type: PAYMENTSCHEDULE_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Pagos Programados en forma de DropDown
export function PaymentScheduleDDListAction(){
    return (dispatch =>{
        dispatch(PaymentScheduleDDList());
        AxiosClient.get(`${process.env.REACT_APP_PAYMENTSCHEDULE}/ddlist/`)        
            .then((response)=> {
                //console.log("paymenscheduleddlis actions", response.data.result)    ;        
                dispatch( PaymentScheduleDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( PaymentScheduleDDListError(error) );
            });
    });
}

export const PaymentScheduleDDList = () => ({
    type: PAYMENTSCHEDULE_DDLIST
});

export const PaymentScheduleDDListSuccess = (records) => ({
    type: PAYMENTSCHEDULE_DDLIST_SUCCESS,
    payload: records
});

export const PaymentScheduleDDListError = (error) => ({
    type: PAYMENTSCHEDULE_DDLIST_ERROR,
    payload: error
});


// Función Principal para obtener un ID de PaymentSchedule
export function PaymentScheduleGetAction(id){
    return (dispatch =>{
        dispatch(PaymentScheduleGet());
        AxiosClient.get(`${process.env.REACT_APP_PAYMENTSCHEDULE}/${id}`)
            .then((response)=> {                    
                dispatch( PaymentScheduleGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( PaymentScheduleGetError(error) );
            });
    });
}

export const PaymentScheduleGet = () => ({
    type: PAYMENTSCHEDULE_GET
});

export const PaymentScheduleGetSuccess = (record) => ({
    type: PAYMENTSCHEDULE_GET_SUCCESS,
    payload: record
});

export const PaymentScheduleGetError = (error) => ({
    type: PAYMENTSCHEDULE_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de PaymentSchedule cuando se va crear un registro nuevo
export function PaymentScheduleNewAction(){
    return (dispatch =>{
            dispatch(PaymentScheduleNewRecord());
    });
}

export const PaymentScheduleNewRecord = () => ({
    type: PAYMENTSCHEDULE_NEW_RECORD
});

// Función Principal para crear y editar registros de PaymentSchedule
export function PaymentScheduleSaveAction(record, isNew){    
    return (dispatch =>{
        if(!isNew) {
            dispatch(PaymentScheduleEdit());           
        } else{
            dispatch(PaymentScheduleNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_PAYMENTSCHEDULE}`,record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( PaymentScheduleNewSuccess(response.data.result));
                })
                .catch(error=> {
                    dispatch( PaymentScheduleNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_PAYMENTSCHEDULE}/${record.id}`,record)
                .then((response)=> {                   
                    dispatch( PaymentScheduleEditSuccess(response.data.result));
                })
                .catch(error=> {                    
                    dispatch( PaymentScheduleEditError(true) );
                });
        }
    });
}

export const PaymentScheduleNew = () => ({
    type: PAYMENTSCHEDULE_NEW
});

export const PaymentScheduleNewSuccess = (record) => ({
    type: PAYMENTSCHEDULE_NEW_SUCCESS,
    payload: record
});

export const PaymentScheduleNewError = (error) => ({
    type: PAYMENTSCHEDULE_NEW_ERROR,
    payload: error
});

export const PaymentScheduleEdit = () => ({
    type: PAYMENTSCHEDULE_EDIT
});

export const PaymentScheduleEditSuccess = (record) => ({
    type: PAYMENTSCHEDULE_EDIT_SUCCESS,
    payload: record
});

export const PaymentScheduleEditError = (error) => ({
    type: PAYMENTSCHEDULE_EDIT_ERROR,
    payload: error
});