import { BaseService } from './BaseService';

const service = new BaseService('purchaseorder');

export default {
    findAll() {
        return service.get(`findAll`);
    },
    getOneById( id ) {
        return service.get(`${id}`);
    },
    create( purchaseorder ) {
        return service.post(``, purchaseorder);
    },
    update( purchaseorder ) {
        return service.put(``, purchaseorder);
    },
    cancelOrderById( id ) {
        return service.post(`cancel`, { id });
    },
    reportByIdAndCurrency (po_id, currency_type_id) {
        return service.get(`report/bypoandcurrency/${po_id}/${currency_type_id}`);
    }
};