import {
    COUNTRY_LIST,
    COUNTRY_LIST_SUCCESS,
    COUNTRY_LIST_ERROR,
    COUNTRY_DDLIST,
    COUNTRY_DDLIST_SUCCESS,
    COUNTRY_DDLIST_ERROR,
    COUNTRY_GET,
    COUNTRY_GET_SUCCESS,
    COUNTRY_GET_ERROR,
    COUNTRY_NEW,
    COUNTRY_NEW_SUCCESS,
    COUNTRY_NEW_ERROR,
    COUNTRY_NEW_RECORD,
    COUNTRY_EDIT,
    COUNTRY_EDIT_SUCCESS,
    COUNTRY_EDIT_ERROR
} from '../types';

const initialState = {
    countries: [],
    countryDDList: [],
    country: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case COUNTRY_LIST:
            return {
                ...state,
                loading: true,
                country: {}
            }
        case COUNTRY_LIST_SUCCESS:
            return {
                ...state,
                countries: action.payload,
                loading: false,
                error: false,
                country: {}
            }
        case COUNTRY_LIST_ERROR:
            return {
                ...state,
                countries: [],
                loading: false,
                error: true,
                country: {}
            }
        case COUNTRY_DDLIST:
            return {
                ...state,
                loading: true,
                country: {}
            }
        case COUNTRY_DDLIST_SUCCESS:
            return {
                ...state,
                countryDDList: action.payload,
                loading: false,
                error: false
            }
        case COUNTRY_DDLIST_ERROR:
            return {
                ...state,
                countryDDList: [],
                loading: false,
                error: true
            }
        case COUNTRY_GET:
            return {
                ...state,
                loading: true
            }
        case COUNTRY_GET_SUCCESS:
            return {
                ...state,
                country: action.payload,
                loading: false,
                error: false
            }
        case COUNTRY_GET_ERROR:
            return {
                ...state,
                error: true,
                country: {}
            }
        case COUNTRY_NEW:
            return {
                ...state,
                loading: true,
            }
        case COUNTRY_NEW_SUCCESS:
            sessionStorage.setItem('error', false);
            return {
                ...state,
                countries: [...state.countries, action.payload],
                country: {},
                loading: false,
                error: false
            }
        case COUNTRY_NEW_ERROR:
            sessionStorage.setItem('error', true);
            return {
                ...state,
                country: action.payload,
                loading: false,
                error: true
            }
        case COUNTRY_NEW_RECORD:
            return {
                ...state,
                country: {},
                error: false
            }
        case COUNTRY_EDIT:
            return {
                ...state
            }
        case COUNTRY_EDIT_SUCCESS:
            sessionStorage.setItem('error', false);
            return {
                ...state,
                error: false,
                country: action.payload,
                countries: state.countries.map(country => country.id === action.payload.id ? country = action.payload : country),
            }
        case COUNTRY_EDIT_ERROR:
            sessionStorage.setItem('error', true);
            return {
                ...state,
                country: action.payload,
                error: false
            }
        default:
            return state;
    }
}
