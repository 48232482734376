import React, { Fragment, useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import ContentWrapper from '../../Layout/ContentWrapper';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import TableSAM from '../../Common/TableSAM';
import RubberAssemblyDetail from './RubberAssemblyDetail';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Otras Utilidades para ocultar Tabs y manejo de las fechas.
import classnames from 'classnames';
import {
    ASSEMBLED_DATE,
    MAIN_TAB_TITLE,
    SECOND_TAB_TITLE,
    SERVICE_REQUEST_NUMBER,
    SERVICE_SOURCE,
    TIRE_DESCRIPTION,
    WINDOW_SUBTITLE,
    WINDOW_TITLE,
} from './RubberAssemblyLabel';
import componentAssembledHeader from '../../../services/componentAssembledHeader';
import {
    ComponentAssembledHeaderIDAction,
    ComponentAssembledHeaderListAction,
} from '../../../store/actions/ComponentAssembled.actions';
import { INVOICE_LABEL } from '../../Supply/MaterialReception/materialReceptionConstants';

const RubberAssembly = () => {
    const columns = [
        { title: SERVICE_REQUEST_NUMBER, field: 'service_request_number' },
        { title: TIRE_DESCRIPTION, field: 'tire_description' },
        { title: ASSEMBLED_DATE, field: 'assembled_date' },
        { title: SERVICE_SOURCE, field: 'oma.label' },
    ];
    const tableTitle = '';

    // State para SAMTable
    const [activeTab, setActiveTab] = useState('1');

    // State para Airplane Component
    const [id, setId] = useState(0);
    const [row, setRow] = useState(null);
    const [isNew, setIsNew] = useState(false);
    const [title, setTitle] = useState('');

    // Redux
    const dispatch = useDispatch();
    useEffect(() => {
        const asyncData = async () => {
            const { data } = await componentAssembledHeader.findAll();
            dispatch(ComponentAssembledHeaderListAction(data?.result));
            return 'Run';
        };

        asyncData().then((r) => console.log(r));
    }, [dispatch]);

    // Main list
    const data = useSelector((state) => state.ComponentAssembledHeader.headerList);

    const viewData = (rowData = {}, tab) => {
        setId(rowData?.id);
        dispatch(ComponentAssembledHeaderIDAction(rowData?.id));
        setRow(rowData);
        setTitle(rowData?.id ? 'Edit component' : 'Create component');
        setIsNew(Boolean(rowData?.id));
        toggleTab(tab);
    };

    // Metodo de las Tabs
    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    // Setea
    const actions = [
        {
            icon: 'add_circle',
            iconProps: {
                color: 'primary',
            },
            tooltip: 'New component',
            isFreeAction: true,
            onClick: () => viewData({}, '2'),
        },
        {
            icon: 'view_compact',
            tooltip: 'Detail',
            onClick: (event, rowData) => viewData(rowData, '2'),
        },
    ];

    const applyOptions = false;

    return (
        <ContentWrapper>
            <div className="content-heading">
                <div>
                    {WINDOW_TITLE}
                    <small>{WINDOW_SUBTITLE}</small>
                </div>
            </div>
            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => {
                                toggleTab('1');
                            }}
                        >
                            {MAIN_TAB_TITLE}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        {activeTab === '2' ? (
                            <NavLink
                                className={`hide-tab-2-${activeTab} ${classnames({
                                    active: activeTab === '2',
                                })}`}
                                onClick={() => {
                                    if (activeTab !== '2') toggleTab('2');
                                }}
                            >
                                {SECOND_TAB_TITLE}
                            </NavLink>
                        ) : null}
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className="tab-contents">
                    <TabPane tabId="1">
                        {activeTab === '1' ? (
                            <Fragment>
                                <TableSAM
                                    titleTable={tableTitle}
                                    columns={columns}
                                    data={data}
                                    applyOptions={applyOptions}
                                    actions={actions}
                                />
                            </Fragment>
                        ) : null}
                    </TabPane>
                    <TabPane tabId="2">
                        {activeTab === '2' ? (
                            <Fragment>
                                <RubberAssemblyDetail
                                    tabTitle={title}
                                    isNew={isNew}
                                    row={row}
                                    detailId={id}
                                />
                            </Fragment>
                        ) : null}
                    </TabPane>
                </TabContent>
            </div>
        </ContentWrapper>
    );
};

export default withNamespaces('translations')(RubberAssembly);
