import { BaseService } from './BaseService';

const service = new BaseService('iteminventory');

export default {
    findAll() {
        return service.get('all');
    },
    findByItemMasterId(itemMasterId) {
        return service.get(`parent/${itemMasterId}`);
    },
    findByCategoryCode(code) {
        return service.get(`category/${code}`);
    },
    saveEquipment(equipment) {
        return service.post(`equipment/save`, equipment);
    },
    findByWarrantyStatus() {
        return service.get(`management/warranty`);
    },
    findItemInventoryRemovedByConditionCode( condition_code ) {
        return service.get(`materialremovedbyconditioncode/${condition_code}`);
    },
    findPageableListByStatus( status_code, page, rowsperpage, search ) {
        return service.get(`pageable/bystatuscode`, { params: { status_code, page, rowsperpage, search } });
    },
    findDDListForCannibalization() {
        return service.get(`materialforcannibalization/ddlist`);
    },
    findAllPageable(page = 1, rowsperpage = 10, search = '', material_state) {
        return service.get(`pageable/all`, { params: { page, rowsperpage, search, material_state } });
    }
};
