import {
    EQUIPMENT_DD_LIST,
    EQUIPMENT_DD_LIST_ERROR,
    EQUIPMENT_DD_LIST_SUCCESS,
    EQUIPMENT_GET,
    EQUIPMENT_GET_ERROR,
    EQUIPMENT_GET_SUCCESS,
    EQUIPMENT_LIST,
    EQUIPMENT_LIST_ERROR,
    EQUIPMENT_LIST_SUCCESS,
    EQUIPMENT_SAVE,
    EQUIPMENT_SAVE_ERROR,
    EQUIPMENT_SAVE_SUCCESS,
    EQUIPMENT_SUB_CATEGORY_DD_LIST,
    EQUIPMENT_SUB_CATEGORY_DD_LIST_ERROR,
    EQUIPMENT_SUB_CATEGORY_DD_LIST_SUCCESS,
} from '../types';

const initialState = {
    equipmentList: [],
    equipmentDDList: [],
    equipmentSubCategoryDDList: [],
    equipment: {},
    loading: false,
    error: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case EQUIPMENT_LIST:
            return {
                ...state,
                loading: true,
                equipmentList: [],
            };
        case EQUIPMENT_LIST_SUCCESS:
            return {
                ...state,
                equipmentList: action.payload,
                loading: false,
                error: false,
            };
        case EQUIPMENT_LIST_ERROR:
            return {
                ...state,
                equipmentList: [],
                loading: false,
                error: true,
            };
        case EQUIPMENT_SAVE:
            return {
                ...state,
                loading: true,
                equipment: {},
            };
        case EQUIPMENT_SAVE_SUCCESS:
            return {
                ...state,
                equipment: action.payload,
                loading: false,
                error: false,
            };
        case EQUIPMENT_SAVE_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                equipment: {},
            };
        case EQUIPMENT_GET:
            return {
                ...state,
                equipment: {},
                loading: true,
                error: false,
            };
        case EQUIPMENT_GET_SUCCESS:
            return {
                ...state,
                equipment: action.payload,
                loading: false,
                error: false,
            };
        case EQUIPMENT_GET_ERROR:
            return {
                ...state,
                equipment: {},
                loading: false,
                error: true,
            };
        case EQUIPMENT_DD_LIST:
            return {
                ...state,
                equipmentDDList: [],
                loading: true,
            };
        case EQUIPMENT_DD_LIST_SUCCESS:
            return {
                ...state,
                equipmentDDList: action.payload,
                loading: false,
            };
        case EQUIPMENT_DD_LIST_ERROR:
            return {
                ...state,
                equipmentDDList: [],
                loading: false,
                error: true,
            };
            case EQUIPMENT_SUB_CATEGORY_DD_LIST:
                return {
                    ...state,
                    equipmentSubCategoryDDList: [],
                    loading: true,
                };
            case EQUIPMENT_SUB_CATEGORY_DD_LIST_SUCCESS:
                return {
                    ...state,
                    equipmentSubCategoryDDList: action.payload,
                    loading: false,
                };
            case EQUIPMENT_SUB_CATEGORY_DD_LIST_ERROR:
                return {
                    ...state,
                    equipmentSubCategoryDDList: [],
                    loading: false,
                    error: true,
                };
        default:
            return state;
    }
}
