export const ENROLLMENT_LABEL               = 'Enrollment';
export const SERIAL_LABEL                   = 'Serial';
export const NAME_LABEL                     = 'Name';
export const MODEL_LABEL                    = 'Model';
export const EFFECTTIVENESS_LABEL           = 'Effectiveness';
export const LINE_LABEL                     = 'Line';
export const VARIABLE_LABEL                 = 'Variable';
export const CURRENT_HOURS_LABEL            = 'Current Hours';
export const CURRENT_CYCLES_LABEL           = 'Current Cycles';

export const ATA_LABEL                      = 'Ata';
export const INTERVAL_LABEL                 = 'Interval';
export const MPD_LABEL                      = 'MPD';
export const TASK_CARD_DESCRIPTION_LABEL    =  'Task Card Description';
export const TASK_CARD_NUMBER_LABEL         =  'Task Card Number';


