import {
    ITEMINVENTORY_LIST,
    ITEMINVENTORY_LIST_SUCCESS,
    ITEMINVENTORY_LIST_ERROR,
    ITEMINVENTORY_LIST_TOLOCATE,
    ITEMINVENTORY_LIST_TOLOCATE_SUCCESS,
    ITEMINVENTORY_LIST_TOLOCATE_ERROR,
    ITEMINVENTORY_DDLIST,
    ITEMINVENTORY_DDLIST_SUCCESS,
    ITEMINVENTORY_DDLIST_ERROR,
    ITEMINVENTORY_GET,
    ITEMINVENTORY_GET_SUCCESS,
    ITEMINVENTORY_GET_ERROR,
    ITEMINVENTORY_NEW,
    ITEMINVENTORY_NEW_SUCCESS,
    ITEMINVENTORY_NEW_ERROR,
    ITEMINVENTORY_NEW_RECORD,
    ITEMINVENTORYTOLOCATE_NEW,
    ITEMINVENTORYTOLOCATE_NEW_SUCCESS,
    ITEMINVENTORYTOLOCATE_NEW_ERROR,
    ITEMINVENTORYTOLOCATE_NEW_RECORD,
    ITEMINVENTORY_EDIT,
    ITEMINVENTORY_EDIT_SUCCESS,
    ITEMINVENTORY_EDIT_ERROR,
    ITEMINVENTORYTOLOCATE_EDIT,
    ITEMINVENTORYTOLOCATE_EDIT_SUCCESS,
    ITEMINVENTORYTOLOCATE_EDIT_ERROR,
    ITEMINVENTORY_WAITING_FOR_INSPECTION_LIST,
    ITEMINVENTORY_WAITING_FOR_INSPECTION_LIST_SUCCESS,
    ITEMINVENTORY_WAITING_FOR_INSPECTION_LIST_ERROR,
    ITEMINVENTORY_WARRANTY_LIST,
    ITEMINVENTORY_WARRANTY_LIST_ERROR, 
    ITEMINVENTORY_WARRANTY_LIST_SUCCESS,
    ITEMINVENTORY_MATERIAL_REMOVED_WI_LIST,
    ITEMINVENTORY_MATERIAL_REMOVED_WI_LIST_ERROR,
    ITEMINVENTORY_MATERIAL_REMOVED_WI_LIST_SUCCESS,
    ITEMINVENTORY_INSTALLATION_AND_REMOVAL_DDLIST,
    ITEMINVENTORY_INSTALLATION_AND_REMOVAL_DDLIST_ERROR,
    ITEMINVENTORY_INSTALLATION_AND_REMOVAL_DDLIST_SUCCESS,
    ITEMINVENTORY_ACCEPT_GET,
    ITEMINVENTORY_ACCEPT_GET_ERROR,
    ITEMINVENTORY_ACCEPT_GET_SUCCESS
} from "../types";

const initialState = {
    iteminventoryList: [],
    iteminventorytolocateList: [],
    iteminventoryWaitingForInspectionList: [],
    iteminventorywarrantylist: [],
    iteminventoryDDList: [],
    iteminventory: {},
    equipmentList: [],
    iteminventoryMaterialRemovedList: [],
    itemInventotyMaterialStatusRemovedInstalled: [],
    itemInventoryAccept: [],
    loading: false,
    error: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ITEMINVENTORY_LIST:
            return {
                ...state,
                loading: true,
                iteminventoryList: [],
                iteminventory: {},
            };
        case ITEMINVENTORY_LIST_SUCCESS:
            return {
                ...state,
                iteminventoryList: action.payload,
                loading: false,
                error: false,
                iteminventory: {},
            };
        case ITEMINVENTORY_LIST_ERROR:
            return {
                ...state,
                iteminventoryList: [],
                loading: false,
                error: true,
                iteminventory: {},
            };

        case ITEMINVENTORY_LIST_TOLOCATE:
            return {
                ...state,
                loading: true,
                iteminventorytolocateList: [],
                iteminventory: {},
            };
        case ITEMINVENTORY_LIST_TOLOCATE_SUCCESS:
            return {
                ...state,
                iteminventorytolocateList: action.payload,
                loading: false,
                error: false,
                iteminventory: {},
            };
        case ITEMINVENTORY_LIST_TOLOCATE_ERROR:
            return {
                ...state,
                iteminventorytolocateList: [],
                loading: false,
                error: true,
                iteminventory: {},
            };

        case ITEMINVENTORY_DDLIST:
            return {
                ...state,
                loading: true,
                iteminventory: {},
            };
        case ITEMINVENTORY_DDLIST_SUCCESS:
            return {
                ...state,
                iteminventoryDDList: action.payload,
                loading: false,
                error: false,
                iteminventory: {},
            };
        case ITEMINVENTORY_DDLIST_ERROR:
            return {
                ...state,
                iteminventoryDDList: [],
                loading: false,
                error: true,
                iteminventory: {},
            };
        case ITEMINVENTORY_GET:
            return {
                ...state,
                loading: true,
            };
        case ITEMINVENTORY_GET_SUCCESS:
            return {
                ...state,
                iteminventory: action.payload,
                loading: false,
                error: false,
            };
        case ITEMINVENTORY_GET_ERROR:
            return {
                ...state,
                error: true,
                iteminventory: {},
            };
        case ITEMINVENTORY_NEW:
            return {
                ...state,
            };
        case ITEMINVENTORY_NEW_SUCCESS:
            return {
                ...state,
                iteminventoryList: [...state.iteminventoryList, action.payload],
                iteminventory: {},
                error: false,
            };
        case ITEMINVENTORY_NEW_ERROR:
            return {
                ...state,
                error: true,
            };
        case ITEMINVENTORY_NEW_RECORD:
            return {
                ...state,
                iteminventory: {},
                error: false,
            };
        case ITEMINVENTORYTOLOCATE_NEW:
            return {
                ...state,
            };
        case ITEMINVENTORYTOLOCATE_NEW_SUCCESS:
            return {
                ...state,
                iteminventoryList: [...state.iteminventoryList, action.payload],
                iteminventory: {},
                error: false,
            };
        case ITEMINVENTORYTOLOCATE_NEW_ERROR:
            return {
                ...state,
                error: true,
            };
        case ITEMINVENTORYTOLOCATE_NEW_RECORD:
            return {
                ...state,
                iteminventory: {},
                error: false,
            };
        case ITEMINVENTORY_EDIT:
            return {
                ...state,
            };
        case ITEMINVENTORY_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                iteminventory: action.payload,
                iteminventoryList: state.iteminventoryList.map((iteminventory) =>
                    iteminventory.id === action.payload.id
                        ? (iteminventory = action.payload)
                        : iteminventory,
                ),
            };
        case ITEMINVENTORY_EDIT_ERROR:
            return {
                ...state,
                error: false,
            };

        case ITEMINVENTORYTOLOCATE_EDIT:
            return {
                ...state,
            };
        case ITEMINVENTORYTOLOCATE_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                iteminventory: action.payload,
                iteminventoryList: state.iteminventoryList.map((iteminventory) =>
                    iteminventory.id === action.payload.id
                        ? (iteminventory = action.payload)
                        : iteminventory,
                ),
            };
        case ITEMINVENTORYTOLOCATE_EDIT_ERROR:
            return {
                ...state,
                error: false,
            };
        case ITEMINVENTORY_WAITING_FOR_INSPECTION_LIST:
            return {
                ...state,
                iteminventoryWaitingForInspectionList: [],
            };
        case ITEMINVENTORY_WAITING_FOR_INSPECTION_LIST_SUCCESS:
            return {
                ...state,
                error: false,
                iteminventoryWaitingForInspectionList: action.payload,
                iteminventory: {},
            };
        case ITEMINVENTORY_WAITING_FOR_INSPECTION_LIST_ERROR:
            return {
                ...state,
                error: true,
                iteminventoryWaitingForInspectionList: [],
                iteminventory: {},
            };
        case ITEMINVENTORY_WARRANTY_LIST:
            return {
                ...state,
                iteminventorywarrantylist: [],
            };
        case ITEMINVENTORY_WARRANTY_LIST_SUCCESS:
            return {
                ...state,
                error: false,
                iteminventorywarrantylist: action.payload,
                iteminventory: {},
            };
        case ITEMINVENTORY_WARRANTY_LIST_ERROR:
            return {
                ...state,
                error: true,
                iteminventorywarrantylist: [],
                iteminventory: {},
            };
            case ITEMINVENTORY_MATERIAL_REMOVED_WI_LIST:
                return {
                    ...state,
                    loading: true,
                    iteminventoryMaterialRemovedList: [],
                    iteminventory: {},
                };
            case ITEMINVENTORY_MATERIAL_REMOVED_WI_LIST_SUCCESS:
                return {
                    ...state,
                    iteminventoryMaterialRemovedList: action.payload,
                    loading: false,
                    error: false,
                    iteminventory: {},
                };
            case ITEMINVENTORY_MATERIAL_REMOVED_WI_LIST_ERROR:
                return {
                    ...state,
                    iteminventoryMaterialRemovedList: [],
                    loading: false,
                    error: true,
                    iteminventory: {}, 
                };
                case ITEMINVENTORY_INSTALLATION_AND_REMOVAL_DDLIST:
                    return {
                        ...state,
                        loading: true,
                        itemInventotyMaterialStatusRemovedInstalled: []
                    };
                case ITEMINVENTORY_INSTALLATION_AND_REMOVAL_DDLIST_SUCCESS:
                    return {
                        ...state,
                        loading: false,
                        error: false,
                        itemInventotyMaterialStatusRemovedInstalled: action.payload
                    };
                case ITEMINVENTORY_INSTALLATION_AND_REMOVAL_DDLIST_ERROR:
                    return {
                        ...state,
                        loading: false,
                        error: true,
                        itemInventotyMaterialStatusRemovedInstalled: []
                    };
                    case ITEMINVENTORY_ACCEPT_GET:
                        return {
                            ...state,
                            loading: true,
                        };
                    case ITEMINVENTORY_ACCEPT_GET_SUCCESS:
                        return {
                            ...state,
                            itemInventoryAccept: action.payload,
                            loading: false,
                            error: false,
                        };
                    case ITEMINVENTORY_ACCEPT_GET_ERROR:
                        return {
                            ...state,
                            error: true,
                            itemInventoryAccept: {},
                        };
                    default:
            return state;
    }
}