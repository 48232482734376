import {
    BPARTNERPRODUCT_LIST,
    BPARTNERPRODUCT_LIST_SUCCESS,
    BPARTNERPRODUCT_LIST_ERROR,
    BPARTNERPRODUCT_DDLIST,
    BPARTNERPRODUCT_DDLIST_SUCCESS,
    BPARTNERPRODUCT_DDLIST_ERROR,
    BPARTNERPRODUCT_GET,
    BPARTNERPRODUCT_GET_SUCCESS,
    BPARTNERPRODUCT_GET_ERROR,
    BPARTNERPRODUCT_NEW,
    BPARTNERPRODUCT_NEW_SUCCESS,
    BPARTNERPRODUCT_NEW_ERROR,
    BPARTNERPRODUCT_NEW_RECORD,
    BPARTNERPRODUCT_EDIT,
    BPARTNERPRODUCT_EDIT_SUCCESS,
    BPARTNERPRODUCT_EDIT_ERROR,
    BPARTNERPRODUCT_DELETE,
    BPARTNERPRODUCT_DELETE_SUCCESS,
    BPARTNERPRODUCT_DELETE_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de BPartnerProduct
export function BPartnerProductListAction(id){    
    return (dispatch =>{
        dispatch(BPartnerProductList());
        AxiosClient.get(`${process.env.REACT_APP_BPARTNERPRODUCT}/list/${id}`)
            .then((response)=> {               
                dispatch( BPartnerProductListSuccess(response.data.result));
            })
            .catch(error=> {                
                dispatch( BPartnerProductListError(error) );
            });
    });
}

export const BPartnerProductList = () => ({
    type: BPARTNERPRODUCT_LIST
});

export const BPartnerProductListSuccess = (records) => ({
    type: BPARTNERPRODUCT_LIST_SUCCESS,
    payload: records
});

export const BPartnerProductListError = (error) => ({
    type: BPARTNERPRODUCT_LIST_ERROR,
    payload: error
});

// Función Principal para obtener BPartneProduct en forma de Dropdown
export function PartnerProductDDListAction(entity_id){    
    return (dispatch =>{
        dispatch(BPartnerProductDDList());
        AxiosClient.get(`${process.env.REACT_APP_BPARTNERPRODUCT}/ddlist/${entity_id}`)
            .then((response)=> {
                dispatch( BPartnerProductDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( BPartnerProductDDListError(error) );
            });
    });
}

export const BPartnerProductDDList = () => ({
    type: BPARTNERPRODUCT_DDLIST
});

export const BPartnerProductDDListSuccess = (records) => ({
    type: BPARTNERPRODUCT_DDLIST_SUCCESS,
    payload: records
});

export const BPartnerProductDDListError = (error) => ({
    type: BPARTNERPRODUCT_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener un BPartnerProduct ID
export function BPartnerProductGetAction(id){
    return (dispatch =>{
        dispatch(BPartnerProductGet());
        AxiosClient.get(`${process.env.REACT_APP_BPARTNERPRODUCT}/${id}`)
            .then((response)=> {
                dispatch( BPartnerProductGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( BPartnerProductGetError(error) );
            });
    });
}

export const BPartnerProductGet = () => ({
    type: BPARTNERPRODUCT_GET
});

export const BPartnerProductGetSuccess = (record) => ({
    type: BPARTNERPRODUCT_GET_SUCCESS,
    payload: record
});

export const BPartnerProductGetError = (error) => ({
    type: BPARTNERPRODUCT_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de BPartnerProduct cuando se va crear un registro nuevo
//export function BPartnerProductNewAction(){
export function BPartnerProductNewAction(idItemMaster, id){       
    return (dispatch =>{
            //dispatch(BPartnerProductNewRecord());
            dispatch(BPartnerProductNewRecord(idItemMaster,id));
    });
}

export const BPartnerProductNewRecord = () => ({
    type: BPARTNERPRODUCT_NEW_RECORD
});

// Función Principal para crear y editar registros de BPartnerProduct
export function BPartnerProductSaveAction(record, isNew){   
    return (dispatch =>{
        if(!isNew) {
            dispatch(BPartnerProductEdit());
        } else{
            dispatch(BPartnerProductNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_BPARTNERPRODUCT}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( BPartnerProductNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( BPartnerProductNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_BPARTNERPRODUCT}/${record.id}`, record)
                .then((response)=> {
                    dispatch( BPartnerProductEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( BPartnerProductEditError(true) );
                });
        }
    });
}

export const BPartnerProductNew = () => ({
    type: BPARTNERPRODUCT_NEW
});

export const BPartnerProductNewSuccess = (record) => ({
    type: BPARTNERPRODUCT_NEW_SUCCESS,
    payload: record
});

export const BPartnerProductNewError = (error) => ({
    type: BPARTNERPRODUCT_NEW_ERROR,
    payload: error
});

export const BPartnerProductEdit = () => ({
    type: BPARTNERPRODUCT_EDIT
});

export const BPartnerProductEditSuccess = (record) => ({
    type: BPARTNERPRODUCT_EDIT_SUCCESS,
    payload: record
});

export const BPartnerProductEditError = (error) => ({
    type: BPARTNERPRODUCT_EDIT_ERROR,
    payload: error
});

// Función Principal para Eliminar un BPartnerProduct ID
export function BPartnerProductDeleteAction(id){
    return (dispatch =>{
        dispatch(BPartnerProductDelete());
        AxiosClient.delete(`${process.env.REACT_APP_BPARTNERPRODUCT}/${id}`)
            .then((response)=> {
                dispatch( BPartnerProductDeleteSuccess(id));
            })
            .catch(error=> {
                dispatch( BPartnerProductDeleteError(error) );
            });
    });
}

export const BPartnerProductDelete = () => ({
    type: BPARTNERPRODUCT_DELETE
});

export const BPartnerProductDeleteSuccess = (id) => ({
    type: BPARTNERPRODUCT_DELETE_SUCCESS,
    payload: id
});

export const BPartnerProductDeleteError = (error) => ({
    type: BPARTNERPRODUCT_DELETE_ERROR,
    payload: error
});
