import {
    EMPLOYEE_LIST,
    EMPLOYEE_LIST_SUCCESS,
    EMPLOYEE_LIST_ERROR,
    EMPLOYEE_DDLIST,
    EMPLOYEE_DDLIST_SUCCESS,
    EMPLOYEE_DDLIST_ERROR,
    EMPLOYEE_GET,
    EMPLOYEE_GET_SUCCESS,
    EMPLOYEE_GET_ERROR,
    EMPLOYEE_GET_BY_USER_ID,
    EMPLOYEE_GET_BY_USER_ID_SUCCESS,
    EMPLOYEE_GET_BY_USER_ID_ERROR,
    EMPLOYEE_NEW,
    EMPLOYEE_NEW_SUCCESS,
    EMPLOYEE_NEW_ERROR,
    EMPLOYEE_NEW_RECORD,
    EMPLOYEE_EDIT,
    EMPLOYEE_EDIT_SUCCESS,
    EMPLOYEE_EDIT_ERROR,
    EMPLOYEE_CHARGE_CODE_DDLIST,
    EMPLOYEE_CHARGE_CODE_DDLIST_SUCCESS,
    EMPLOYEE_CHARGE_CODE_DDLIST_ERROR,
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Employee
export function EmployeeListAction(){
    
    return (dispatch =>{
        dispatch(EmployeeList());
        AxiosClient.get(`${process.env.REACT_APP_EMPLOYEE}`)
            .then((response)=> {
                console.log("empleados", response.data.result);
                dispatch( EmployeeListSuccess(response.data.result));
            })
            .catch(error=> {
                console.log("empleados", error);
                dispatch( EmployeeListError(error) );
            });
    });
}

export const EmployeeList = () => ({
    type: EMPLOYEE_LIST
});

export const EmployeeListSuccess = (records) => ({
    type: EMPLOYEE_LIST_SUCCESS,
    payload: records
});

export const EmployeeListError = (error) => ({
    type: EMPLOYEE_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Employee en forma de DropDown
export function EmployeeDDListAction(){
    
    return (dispatch =>{
        dispatch(EmployeeDDList());
        AxiosClient.get(`${process.env.REACT_APP_EMPLOYEE}/ddlist`)
            .then((response)=> {
                dispatch( EmployeeDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( EmployeeDDListError(error) );
            });
    });
}

export const EmployeeDDList = () => ({
    type: EMPLOYEE_DDLIST
});

export const EmployeeDDListSuccess = (records) => ({
    type: EMPLOYEE_DDLIST_SUCCESS,
    payload: records
});

export const EmployeeDDListError = (error) => ({
    type: EMPLOYEE_DDLIST_ERROR,
    payload: error
});


// Función Principal para obtener un BPartner ID
export function EmployeeGetAction(id){
    return (dispatch =>{
        dispatch(EmployeeGet());
        AxiosClient.get(`${process.env.REACT_APP_EMPLOYEE}/${id}`)
            .then((response)=> {
                dispatch( EmployeeGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( EmployeeGetError(error) );
            });
    });
}

export const EmployeeGet = () => ({
    type: EMPLOYEE_GET
});

export const EmployeeGetSuccess = (record) => ({
    type: EMPLOYEE_GET_SUCCESS,
    payload: record
});

export const EmployeeGetError = (error) => ({
    type: EMPLOYEE_GET_ERROR,
    payload: error
});



// Función Principal para obtener el Empleado segun el Id de Usuario...
export function EmployeeGetByIdAction(id){
    return (dispatch =>{
        dispatch(EmployeeByIdGet());        
        AxiosClient.get(`${process.env.REACT_APP_EMPLOYEENAME_BY_USER_ID}/${id}`)
            .then((response)=> {                    
                dispatch( EmployeeByIdGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( EmployeeByIdGetError(error) );
            });
    });
}

export const EmployeeByIdGet = () => ({
    type: EMPLOYEE_GET_BY_USER_ID
});

export const EmployeeByIdGetSuccess = (record) => ({
    type: EMPLOYEE_GET_BY_USER_ID_SUCCESS,
    payload: record
});

export const EmployeeByIdGetError = (error) => ({
    type: EMPLOYEE_GET_BY_USER_ID_ERROR,
    payload: error
});




//Función Principal para setear objeto de Employee cuando se va crear un registro nuevo
export function EmployeeNewAction(){
    return (dispatch =>{
            dispatch(EmployeeNewRecord());
    });
}

export const EmployeeNewRecord = () => ({
    type: EMPLOYEE_NEW_RECORD
});

// Función Principal para crear y editar registros de Employees
export function EmployeeSaveAction(record, isNew){
    console.log(record);
    return (dispatch =>{
        if(!isNew) {
            dispatch(EmployeeEdit());
        } else{
            dispatch(EmployeeNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_EMPLOYEE}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( EmployeeNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( EmployeeNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_EMPLOYEE}/${record.id}`, record)
                .then((response)=> {
                    dispatch( EmployeeEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( EmployeeEditError(true) );
                });
        }
    });
}

export const EmployeeNew = () => ({
    type: EMPLOYEE_NEW
});

export const EmployeeNewSuccess = (record) => ({
    type: EMPLOYEE_NEW_SUCCESS,
    payload: record
});

export const EmployeeNewError = (error) => ({
    type: EMPLOYEE_NEW_ERROR,
    payload: error
});

export const EmployeeEdit = () => ({
    type: EMPLOYEE_EDIT
});

export const EmployeeEditSuccess = (record) => ({
    type: EMPLOYEE_EDIT_SUCCESS,
    payload: record
});

export const EmployeeEditError = (error) => ({
    type: EMPLOYEE_EDIT_ERROR,
    payload: error
});

// Función Principal para obtener Employee en forma de DropDown por cargo
export function ListByEmployeeChargeCodeArrayDDList(record){
    
    return (dispatch =>{
        dispatch(EmployeeChargeCodeArrayDDList());
        AxiosClient.post(`${process.env.REACT_APP_EMPLOYEE}/listbychargecodearray/ddlist`, record)
            .then((response)=> {
                dispatch( EmployeeChargeCodeArrayDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( EmployeeChargeCodeArrayDDListError(error) );
            });
    });
}

export const EmployeeChargeCodeArrayDDList = () => ({
    type: EMPLOYEE_CHARGE_CODE_DDLIST
});

export const EmployeeChargeCodeArrayDDListSuccess = (records) => ({
    type: EMPLOYEE_CHARGE_CODE_DDLIST_SUCCESS,
    payload: records
});

export const EmployeeChargeCodeArrayDDListError = (error) => ({
    type: EMPLOYEE_CHARGE_CODE_DDLIST_ERROR,
    payload: error
});


