import {
    AIRWORTHINESSDIRECTIVE_CLEAN,
    AIRWORTHINESSDIRECTIVE_EDIT,
    AIRWORTHINESSDIRECTIVE_EDIT_ERROR,
    AIRWORTHINESSDIRECTIVE_EDIT_SUCCESS,
    AIRWORTHINESSDIRECTIVE_GET,
    AIRWORTHINESSDIRECTIVE_GET_ERROR,
    AIRWORTHINESSDIRECTIVE_GET_SUCCESS,
    AIRWORTHINESSDIRECTIVE_LIST,
    AIRWORTHINESSDIRECTIVE_LIST_ERROR,
    AIRWORTHINESSDIRECTIVE_LIST_SUCCESS,
    AIRWORTHINESSDIRECTIVE_NEW,
    AIRWORTHINESSDIRECTIVE_NEW_ERROR,
    AIRWORTHINESSDIRECTIVE_NEW_SUCCESS
} from "../types";

const initialState = {
    airworthinessdirectives: [],
    airworthinessdirective: {},
    loading: false,
    error: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case AIRWORTHINESSDIRECTIVE_LIST:
            return {
                ...state,
                loading: true,
                airworthinessdirective: {}
            };

        case AIRWORTHINESSDIRECTIVE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                airworthinessdirectives: action.payload,
                airworthinessdirective: {}
            };

        case AIRWORTHINESSDIRECTIVE_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                airworthinessdirectives: [],
                airworthinessdirective: {}
            };

        case AIRWORTHINESSDIRECTIVE_GET:
            return {
                ...state,
                loading: true,
                error: false,
                airworthinessdirective: {}
            }

        case AIRWORTHINESSDIRECTIVE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                airworthinessdirective: action.payload
            };

        case AIRWORTHINESSDIRECTIVE_GET_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                airworthinessdirectives: [],
                airworthinessdirective: {}
            };
        case AIRWORTHINESSDIRECTIVE_CLEAN:
            return {
                ...state,
                airworthinessdirective: {}
            };

        case AIRWORTHINESSDIRECTIVE_NEW: 
            return {
                ...state,
                loading: true,
                error: false
            };

        case AIRWORTHINESSDIRECTIVE_NEW_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                airworthinessdirectives: [...state.airworthinessdirectives, action.payload],
                airworthinessdirective: action.payload
            };

        case AIRWORTHINESSDIRECTIVE_NEW_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                airworthinessdirectives: [],
                airworthinessdirective: {}
            }
        
        case AIRWORTHINESSDIRECTIVE_EDIT:
            return {
                ...state,
                loading: true,
                error: false
            };

        case AIRWORTHINESSDIRECTIVE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                airworthinessdirective: action.payload,
                airworthinessdirectives: state.airworthinessdirectives.map(airworthinessdirective => (airworthinessdirective.id === action.payload.id ? action.payload : airworthinessdirective))
            };

        case AIRWORTHINESSDIRECTIVE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                airworthinessdirectives: [],
                airworthinessdirective: {}
            };

        default:
            return state;
    }
};