import {
    AIRPLANE_LIST,
    AIRPLANE_LIST_SUCCESS,
    AIRPLANE_LIST_ERROR,
    AIRPLANE_DDLIST,
    AIRPLANE_DDLIST_SUCCESS,
    AIRPLANE_DDLIST_ERROR,
    AIRPLANE_GET,
    AIRPLANE_GET_SUCCESS,
    AIRPLANE_GET_ERROR,
    AIRPLANE_GET_SEARCH,
    AIRPLANE_GET_SEARCH_SUCCESS,
    AIRPLANE_GET_SEARCH_ERROR,
    AIRPLANE_NEW,
    AIRPLANE_NEW_SUCCESS,
    AIRPLANE_NEW_ERROR,
    AIRPLANE_NEW_RECORD,
    AIRPLANE_EDIT,
    AIRPLANE_EDIT_SUCCESS,
    AIRPLANE_EDIT_ERROR,
    AIRPLANE_MODEL_DDLIST,
    AIRPLANE_MODEL_DDLIST_SUCCESS,
    AIRPLANE_MODEL_DDLIST_ERROR
} from '../types';

//const axios = require('axios');
import AxiosClient from '../../config/AxiosClient';
require('dotenv').config();

// Función Principal para obtener
export const AirplaneListAction = () => async (dispatch) => {
    try {
        dispatch(AirplaneList());
        const response = await AxiosClient.get(`${process.env.REACT_APP_AIRPLANE}`).catch(error => {
            dispatch(AirplaneListError(error));
        });
        dispatch(AirplaneListSuccess(response.data.result));
    }
    catch (e) {
        console.error(e);
    }

};

export const AirplaneList = () => ({
    type: AIRPLANE_LIST
});

export const AirplaneListSuccess = (airplanes) => ({
    type: AIRPLANE_LIST_SUCCESS,
    payload: airplanes
});

export const AirplaneListError = (error) => ({
    type: AIRPLANE_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Master Data por Entidad en forma de DropDown
export function AirplaneDDListAction(){
    return (async dispatch =>{
        dispatch(AirplaneDDList());
        await AxiosClient.get(`${process.env.REACT_APP_AIRPLANE}/ddlist`)
            .then((response)=> {
                dispatch( AirplaneDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( AirplaneDDListError(error) );
            });
    });
}

export const AirplaneDDList = () => ({
    type: AIRPLANE_DDLIST
});

export const AirplaneDDListSuccess = (records) => ({
    type: AIRPLANE_DDLIST_SUCCESS,
    payload: records
});

export const AirplaneDDListError = (error) => ({
    type: AIRPLANE_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener la Efectividad de las Aeronaves en forma de DropDown
export function AirplaneEffectivenessDDListAction(){
    return (dispatch =>{
        dispatch(AirplaneEffectivenessDDList());
        AxiosClient.get(`${process.env.REACT_APP_AIRPLANE}/effectiveness/ddlist`)
            .then((response)=> {
                dispatch( AirplaneEffectivenessDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( AirplaneEffectivenessDDListError(error) );
            });
    });
}

export const AirplaneEffectivenessDDList = () => ({
    type: AIRPLANE_DDLIST
});

export const AirplaneEffectivenessDDListSuccess = (records) => ({
    type: AIRPLANE_DDLIST_SUCCESS,
    payload: records
});

export const AirplaneEffectivenessDDListError = (error) => ({
    type: AIRPLANE_DDLIST_ERROR,
    payload: error
});


// Función Principal para obtener un Airplane
export const AirplaneGetAction = (id, entity_type_id) => async (dispatch) =>{
    try {
            dispatch(AirplaneGet());
            const response = await AxiosClient.get(`${process.env.REACT_APP_AIRPLANE}/${id}/${entity_type_id}`)
                .catch(error => {
                    dispatch(AirplaneGetError(error));
                });
            dispatch(AirplaneGetSuccess(response.data.result));
    } catch (e) {
        console.error(e);
    }
};

export const AirplaneGet = () => ({
    type: AIRPLANE_GET
});

export const AirplaneGetSuccess = (record) => ({
    type: AIRPLANE_GET_SUCCESS,
    payload: record
});

export const AirplaneGetError = (error) => ({
    type: AIRPLANE_GET_ERROR,
    payload: error
});

// Función Principal para obtener el Serial, Matricula, Nombre de Airplane
export function AirplaneGetSearchAction(id, isNew, param, paramvalue){
    return (dispatch =>{
        dispatch(AirplaneGetSearch());

        const urlPart = `${process.env.REACT_APP_AIRPLANE}/${param}/${paramvalue}`;

        const url = isNew? urlPart : `${urlPart}/id/${id}`;

        AxiosClient.get(url)
            .then((response)=> {
                dispatch( AirplaneGetSearchSuccess(response.data));
            })
            .catch(error=> {
                dispatch( AirplaneGetSearchError(error) );
            });
    });
}

export const AirplaneGetSearch = () => ({
    type: AIRPLANE_GET_SEARCH
});

export const AirplaneGetSearchSuccess = (record) => ({
    type: AIRPLANE_GET_SEARCH_SUCCESS,
    payload: record
});

export const AirplaneGetSearchError = (error) => ({
    type: AIRPLANE_GET_SEARCH_ERROR,
    payload: error
});

// Función Principal para Crear una Aeronave.
export function AirplaneNewAction(airplane){
    return (dispatch) => {
        dispatch( AirplaneNew() );

        AxiosClient.post(process.env.REACT_APP_AIRPLANE, airplane)
            .then(resultado=>{
                if(resultado.status===201) {
                    dispatch( AirplaneNewSuccess(airplane) );
                }
            }).catch(error=>{
                dispatch( AirplaneNewError() );
            });
    }
}

export const AirplaneNewRecord = () => ({
    type: AIRPLANE_NEW_RECORD
});

// Función Principal para crear y editar registros de Airplane
export function AirplaneSaveAction(record, isNew, entity_type_id){
    return (dispatch =>{
        if(!isNew) {
            dispatch(AirplaneEdit());
        } else{
            dispatch(AirplaneNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_AIRPLANE}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    //dispatch( AirplaneNewSuccess(record));
                    dispatch( AirplaneNewSuccess(response.data.result));
                })
                .catch(error=> {
                    dispatch( AirplaneNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_AIRPLANE}/${record.id}/${entity_type_id}`, record)
                .then((response)=> {
                   // dispatch( AirplaneEditSuccess(record));
                    dispatch( AirplaneEditSuccess(record.data.result));
                })
                .catch(error=> {
                    dispatch( AirplaneEditError(error) );
                });
        }
    });
}

export const AirplaneNew = () => ({
    type: AIRPLANE_NEW
});

export const AirplaneNewSuccess = (record) => ({
    type: AIRPLANE_NEW_SUCCESS,
    payload: record
});

export const AirplaneNewError = (error) => ({
    type: AIRPLANE_NEW_ERROR,
    payload: error
});

export const AirplaneEdit = () => ({
    type: AIRPLANE_EDIT
});

export const AirplaneEditSuccess = (record) => ({
    type: AIRPLANE_EDIT_SUCCESS,
    payload: record
});

export const AirplaneEditError = (error) => ({
    type: AIRPLANE_EDIT_ERROR,
    payload: error
});

export function AirplaneModelDDListAction(){
    return (async dispatch =>{
        dispatch(AirplaneModelDDList());
        await AxiosClient.get(`${process.env.REACT_APP_AIRPLANE}/model/ddlist/all`)
            .then((response)=> {
                dispatch( AirplaneModelDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( AirplaneModelDDListError(error) );
            });
    });
}

export const AirplaneModelDDList = () => ({
    type: AIRPLANE_MODEL_DDLIST
});

export const AirplaneModelDDListSuccess = (records) => ({
    type: AIRPLANE_MODEL_DDLIST_SUCCESS,
    payload: records
});

export const AirplaneModelDDListError = (error) => ({
    type: AIRPLANE_MODEL_DDLIST_ERROR,
    payload: error
});