import React, { useEffect } from 'react';
import {
    Button,
    Col,
    FormGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import SelectSAM from '../../Common/SelectSAM';
import { messageText } from '../../../Utils/SAMUtils';

import '../../Common/fileinput.css';
// Redux Dispatch, Selector, y acciones.
import { useDispatch, useSelector } from 'react-redux';
// Validaciones con Formik y Yup
import { Field, withFormik } from 'formik';
import itemMaster from '../../../services/itemMaster';
import {
    CANCEL_LABEL,
    DESCRIPTION_LABEL,
    PART_NUMBER_LABEL,
    SAVE_LABEL,
} from '../ItemMaster/itemMasterConstants';
import { REQUIERED_FIELDS_LABEL } from '../../../constants/common';
import itemInventory from '../../../services/itemInventory';
import TableSAM from '../../Common/TableSAM';
import {
    CONDITION_LABEL,
    SERIAL_NUMBER_LABEL,
    SERIAL_TWO_LABEL,
} from '../../Supply/MaterialReception/materialReceptionConstants';
import { REP_LABEL, STATUS_LABEL } from '../../Supply/Inventory/ItenInventoryConstants';
import componentAssembledDetail from '../../../services/componentAssembledDetail';

require('dotenv').config();

export default function ComponentSelector({ title, header_id, toggleModal, modal }) {
    // Variable de Formulario
    const dispatch = useDispatch();

    const saveData = async (record) => {
        const { data } = await componentAssembledDetail
            .saveComponents(record)
            .catch((error) => console.error(error));
        if (!data?.ok) {
            messageText('An error has occurred', 'Components could not be saved', 'error');
        } else {
            messageText('Components saved', 'the components were stored correctly', 'success');
            toggleModal();
        }
    };

    let rubbersList = [];
    let rubbersDDList = [];

    let wheelsList = [];
    let wheelsDDList = [];

    const setColumn = (searchCode) => {
        return searchCode === 'WHE'
            ? [
                  {
                      title: PART_NUMBER_LABEL,
                      field: 'part_number',
                      render: (rowData) => findPartNumber(rowData, wheelsList),
                      editable: 'never',
                  },
                  {
                      title: DESCRIPTION_LABEL,
                      field: 'description',
                      render: (rowData) => rowData.description,
                      editable: 'never',
                  },
                  {
                      title: SERIAL_NUMBER_LABEL,
                      field: 'serial_number',
                      type: 'string',
                      editable: 'always',
                      render: (rowData) => rowData.serial_number,
                  },
                  {
                      title: SERIAL_TWO_LABEL,
                      field: 'serial_two',
                      type: 'string',
                      editable: 'always',
                      render: (rowData) => rowData.serial_two,
                  },
                  {
                      title: CONDITION_LABEL,
                      field: 'material_condition',
                      render: (rowData) => rowData.material_condition?.label,
                      editable: 'never',
                  },
                  {
                      title: STATUS_LABEL,
                      field: 'material_state',
                      render: (rowData) => rowData.material_state?.label,
                      editable: 'never',
                  },
              ]
            : [
                  {
                      title: PART_NUMBER_LABEL,
                      field: 'part_number',
                      render: (rowData) => findPartNumber(rowData, rubbersList),
                  },
                  {
                      title: DESCRIPTION_LABEL,
                      field: 'description',
                      render: (rowData) => rowData.description,
                  },
                  {
                      title: SERIAL_NUMBER_LABEL,
                      field: 'serial_number',
                      render: (rowData) => rowData.serial_number,
                  },
                  {
                      title: REP_LABEL,
                      field: 'rep',
                      render: (rowData) => rowData.rep,
                  },
              ];
    };

    const applyOptions = true;

    const tableOptions = {
        grouping: false,
        fixedColumns: {
            left: 0,
            right: 0,
        },
        filtering: false,
        selection: true,
        paging: false,
        exportButton: false,
    };

    const findPartNumber = (rowData, list = []) => {
        const found = list.find((i) => parseInt(i.id) === parseInt(rowData.itemmaster_id));
        if (found) return found.part_number;
    };

    useEffect(() => {
        const asyncData = async () => {
            const { data } = await itemMaster.findAll();
            if (data?.result) {
                rubbersList = data?.result.filter((it) => it.subcategory_code?.code === 'CAU');
                rubbersDDList = rubbersList.map((it) => ({ value: it.id, label: it.part_number }));

                wheelsList = data?.result.filter((it) => it.subcategory_code?.code === 'WHE');
                wheelsDDList = wheelsList.map((it) => ({ value: it.id, label: it.part_number }));
            }
            return 'Run';
        };

        asyncData().then((r) => console.debug(r));
    }, [dispatch]);

    // Trae el objeto de SubAta

    // Validación con Yup y Formik
    const formikValidation = withFormik({
        mapPropsToValues: (propiedades) => ({
            id: 0,
            componentassembledheader_id: header_id,
            rubberDescription: '',
            wheelDescription: '',
            wheel: {},
            rubber: {},
            wheelInventoryList: [],
            rubberInventoryList: [],
            wheelSelected: null,
            rubberSelected: null,
        }),
        handleSubmit: (values, { setSubmitting }) => {
            const payload = {
                ...values,
            };
            setTimeout(() => {
                saveData({ ...payload });
                setSubmitting(false);
            }, 1000);
        },
        displayName: 'SubAtaForm',
    });

    const formData = (propiedades) => {
        const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
        } = propiedades;
        return (
            <form
                className="form-horizontal"
                name="form"
                encType="multipart/form-data"
                onSubmit={handleSubmit}
            >
                <ModalBody>
                    <Row>
                        <Col sm="6">
                            <FormGroup>
                                <SelectSAM
                                    value={values.wheel}
                                    fieldName={'wheel_id'}
                                    field={'wheel'}
                                    textField={'Select Wheel Part number'}
                                    isRequired={true}
                                    options={wheelsDDList}
                                    onChange={async (evt, data) => {
                                        setFieldValue(evt, data);
                                        const itMaster = wheelsList.find(
                                            (wh) => data.value === wh.id,
                                        );
                                        if (itMaster) {
                                            setFieldValue('wheelDescription', itMaster.description);
                                            const { data } = await itemInventory.findByItemMasterId(
                                                itMaster.id,
                                            );

                                            if (data) {
                                                setFieldValue(
                                                    'wheelInventoryList',
                                                    data.result || [],
                                                );
                                            }
                                        }
                                    }}
                                    onBlur={setFieldTouched}
                                    error={errors.wheel}
                                    touched={touched.wheel}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <label className="text-bold" htmlFor="description">
                                    {DESCRIPTION_LABEL}
                                </label>
                                <Field
                                    type="text"
                                    className="form-control"
                                    name="description"
                                    placeholder={DESCRIPTION_LABEL}
                                    value={values.wheelDescription}
                                    disabled={true}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            {values.wheelInventoryList.length ? (
                                <div>
                                    <TableSAM
                                        titleTable={'Select Wheel'}
                                        columns={setColumn('WHE')}
                                        data={values.wheelInventoryList}
                                        applyOptions={applyOptions}
                                        options={tableOptions}
                                        selection={true}
                                        onSelectionChange={(event) => {
                                            setFieldValue('wheelSelected', event[0]);

                                            if (event.length > 1)
                                                alert('You must select only one item per table.');
                                        }}
                                        cellEditable={{
                                            onCellEditApproved: (
                                                newValue,
                                                oldValue,
                                                rowData,
                                                columnDef,
                                            ) => {
                                                return new Promise((resolve, reject) => {
                                                    const { wheelInventoryList } = values;
                                                    const { field } = columnDef;
                                                    const wheelFound = wheelInventoryList.find(
                                                        (i) => i.id === rowData.id,
                                                    );
                                                    let wheelIndex = -1;
                                                    if (wheelFound) {
                                                        wheelIndex = wheelInventoryList.indexOf(
                                                            wheelFound,
                                                        );

                                                        wheelFound[field] = newValue;
                                                        if (~wheelIndex) {
                                                            wheelInventoryList[wheelIndex] = {
                                                                ...rowData,
                                                                wheelFound,
                                                            };
                                                        }
                                                    }
                                                    resolve(newValue);
                                                });
                                            },
                                        }}
                                    />
                                </div>
                            ) : null}
                        </Col>
                    </Row>

                    <Row className={'mt-5'}>
                        <Col sm="6">
                            <FormGroup>
                                <SelectSAM
                                    value={values.rubber}
                                    fieldName={'rubber_id'}
                                    field={'rubber'}
                                    textField={'Select Rubber Part number'}
                                    isRequired={true}
                                    options={rubbersDDList}
                                    onChange={async (evt, data) => {
                                        setFieldValue(evt, data);
                                        const itMaster = rubbersList.find(
                                            (wh) => data.value === wh.id,
                                        );
                                        if (itMaster) {
                                            setFieldValue(
                                                'rubberDescription',
                                                itMaster.description,
                                            );
                                            const { data } = await itemInventory.findByItemMasterId(
                                                itMaster.id,
                                            );

                                            if (data) {
                                                setFieldValue(
                                                    'rubberInventoryList',
                                                    data.result || [],
                                                );
                                            }
                                        }
                                    }}
                                    onBlur={setFieldTouched}
                                    error={errors.wheel}
                                    touched={touched.wheel}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <label className="text-bold" htmlFor="description">
                                    {DESCRIPTION_LABEL}
                                </label>
                                <Field
                                    type="text"
                                    className="form-control"
                                    name="description"
                                    placeholder={DESCRIPTION_LABEL}
                                    value={values.rubberDescription}
                                    disabled={true}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            {values.rubberInventoryList.length ? (
                                <div>
                                    <TableSAM
                                        titleTable={'Select Wheel'}
                                        columns={setColumn()}
                                        data={values.rubberInventoryList}
                                        applyOptions={applyOptions}
                                        options={tableOptions}
                                        selection={false}
                                        onSelectionChange={(event) => {
                                            setFieldValue('rubberSelected', event[0]);

                                            if (event.length > 1)
                                                alert('You must select only one item per table.');
                                        }}
                                    />
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <p className="text-bold text-danger">{REQUIERED_FIELDS_LABEL}</p>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="inverse" type="submit">
                        {SAVE_LABEL}
                    </Button>{' '}
                    <Button color="secondary" onClick={toggleModal}>
                        {CANCEL_LABEL}
                    </Button>
                </ModalFooter>
            </form>
        );
    };

    const SAMForm = formikValidation(formData);

    return (
        <Modal isOpen={modal} toggle={toggleModal} size="lg">
            <ModalHeader toggle={toggleModal} className="bg-gray-darker">
                <div className="text-bold">{title}</div>
            </ModalHeader>
            <SAMForm />
        </Modal>
    );
}
