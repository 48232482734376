import {
    PAYMENTHEADER_LIST,
    PAYMENTHEADER_LIST_SUCCESS,
    PAYMENTHEADER_LIST_ERROR,
    PAYMENTHEADER_DDLIST,
    PAYMENTHEADER_DDLIST_SUCCESS,
    PAYMENTHEADER_DDLIST_ERROR,
    PAYMENTHEADER_GET,
    PAYMENTHEADER_GET_SUCCESS,
    PAYMENTHEADER_GET_ERROR,
    PAYMENTHEADER_NEW,
    PAYMENTHEADER_NEW_SUCCESS,
    PAYMENTHEADER_NEW_ERROR,
    PAYMENTHEADER_NEW_RECORD,
    PAYMENTHEADER_EDIT,
    PAYMENTHEADER_EDIT_SUCCESS,
    PAYMENTHEADER_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Cabecera dePagos
export function PaymentHeaderListAction(){    
    return (dispatch =>{
        dispatch(PaymentHeaderList());
        AxiosClient.get(`${process.env.REACT_APP_PAYMENTHEADER}`)
            .then((response)=> {              
                dispatch( PaymentHeaderListSuccess(response.data.result));
            })
            .catch(error=> {            
                dispatch( PaymentHeaderListError(error) );
            });
    });
}

export const PaymentHeaderList = () => ({
    type: PAYMENTHEADER_LIST
});

export const PaymentHeaderListSuccess = (records) => ({
    type: PAYMENTHEADER_LIST_SUCCESS,
    payload: records
});

export const PaymentHeaderListError = (error) => ({
    type: PAYMENTHEADER_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Cabecera de PAgos en forma de DropDown
export function PaymentHeaderDDListAction(){
    
    return (dispatch =>{
        dispatch(PaymentHeaderDDList());
        AxiosClient.get(`${process.env.REACT_APP_PAYMENTHEADER}/ddlist`)
            .then((response)=> {
                dispatch( PaymentHeaderDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( PaymentHeaderDDListError(error));
            });
    });
}

export const PaymentHeaderDDList = () => ({
    type: PAYMENTHEADER_DDLIST
});

export const PaymentHeaderDDListSuccess = (records) => ({
    type: PAYMENTHEADER_DDLIST_SUCCESS,
    payload: records
});

export const PaymentHeaderDDListError = (error) => ({
    type: PAYMENTHEADER_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener un ID de Cabecera de Pagos
export function PaymentHeaderGetAction(id){
    return (dispatch =>{
        dispatch(PaymentHeaderGet());
        AxiosClient.get(`${process.env.REACT_APP_PAYMENTHEADER}/${id}`)
            .then((response)=> {                    
                dispatch( PaymentHeaderGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( PaymentHeaderGetError(error));
            });
    });
}

export const PaymentHeaderGet = () => ({
    type: PAYMENTHEADER_GET
});

export const PaymentHeaderGetSuccess = (record) => ({
    type: PAYMENTHEADER_GET_SUCCESS,
    payload: record
});

export const PaymentHeaderGetError = (error) => ({
    type: PAYMENTHEADER_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Cabecera de Pagos cuando se va crear un registro nuevo
export function PaymentHeaderNewAction(){
    return (dispatch =>{
            dispatch(PaymentHeaderNewRecord());
    });
}

export const PaymentHeaderNewRecord = () => ({
    type: PAYMENTHEADER_NEW_RECORD
});

// Función Principal para crear y editar registros de Cabecera de Pagos
export function PaymentHeaderSaveAction(record, isNew){    
    return (dispatch =>{
        if(!isNew) {
            dispatch(PaymentHeaderEdit());
        } else{
            dispatch(PaymentHeaderNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_PAYMENTHEADER}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( PaymentHeaderNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( PaymentHeaderNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_PAYMENTHEADER}/${record.id}`, record)
                .then((response)=> {
                    dispatch( PaymentHeaderEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( PaymentHeaderEditError(true) );
                });
        }
    });
}

export const PaymentHeaderNew = () => ({
    type: PAYMENTHEADER_NEW
});

export const PaymentHeaderNewSuccess = (record) => ({
    type: PAYMENTHEADER_NEW_SUCCESS,
    payload: record
});

export const PaymentHeaderNewError = (error) => ({
    type: PAYMENTHEADER_NEW_ERROR,
    payload: error
});

export const PaymentHeaderEdit = () => ({
    type: PAYMENTHEADER_EDIT
});

export const PaymentHeaderEditSuccess = (record) => ({
    type: PAYMENTHEADER_EDIT_SUCCESS,
    payload: record
});

export const PaymentHeaderEditError = (error) => ({
    type: PAYMENTHEADER_EDIT_ERROR,
    payload: error
});