import {
    QUOTATIONBPARTNER_LIST,
    QUOTATIONBPARTNER_LIST_SUCCESS,
    QUOTATIONBPARTNER_LIST_ERROR,
    QUOTATIONBPARTNER_DDLIST,
    QUOTATIONBPARTNER_DDLIST_SUCCESS,
    QUOTATIONBPARTNER_DDLIST_ERROR,
    QUOTATIONBPARTNER_GET,
    QUOTATIONBPARTNER_GET_SUCCESS,
    QUOTATIONBPARTNER_GET_ERROR,
    QUOTATIONBYBPARTNER_GET,
    QUOTATIONBYBPARTNER_GET_SUCCESS,
    QUOTATIONBYBPARTNER_GET_ERROR,


    QUOTATIONBYREQUISITION_LIST,
    QUOTATIONBYREQUISITION_LIST_SUCCESS,
    QUOTATIONBYREQUISITION_LIST_ERROR,

    QUOTATIONBPARTNER_NEW,
    QUOTATIONBPARTNER_NEW_SUCCESS,
    QUOTATIONBPARTNER_NEW_ERROR,
    QUOTATIONBPARTNER_NEW_RECORD,
    QUOTATIONBPARTNER_EDIT,
    QUOTATIONBPARTNER_EDIT_SUCCESS,
    QUOTATIONBPARTNER_EDIT_ERROR
} from '../types';

const initialState = {
    quotationbpartners: [],
    quotationbpartner: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case QUOTATIONBPARTNER_LIST:
            return {
                ...state,
                loading: true,
                quotationbpartner: {}
            }
        case QUOTATIONBPARTNER_LIST_SUCCESS:
            return {
                ...state,
                quotationbpartners: action.payload,
                loading: false,
                error: false,
                quotationbpartner: {}
            }
        case QUOTATIONBPARTNER_LIST_ERROR:
            return {
                ...state,
                quotationbpartners: [],
                loading: false,
                error: true,
                quotationbpartner: {}
            }
        case QUOTATIONBYREQUISITION_LIST:
            return {
                ...state,
                loading: true,
                quotationbpartner: {}
            }
        case QUOTATIONBYREQUISITION_LIST_SUCCESS:
            return {
                ...state,
                quotationbpartners: action.payload,
                loading: false,
                error: false,
                quotationbpartner: {}
            }
        case QUOTATIONBYREQUISITION_LIST_ERROR:
            return {
                ...state,
                quotationbpartners: [],
                loading: false,
                error: true,
                quotationbpartner: {}
            }


        // case QUOTATIONBPARTNER_DDLIST:
        //     return {
        //         ...state,
        //         loading: true,
        //         quotationbpartner: {}
        //     }
        // case QUOTATIONBPARTNER_DDLIST_SUCCESS:
        //     return {
        //         ...state,
        //         quotationbpartners: action.payload,
        //         loading: false,
        //         error: false,
        //         quotationbpartner: {}
        //     }
        // case QUOTATIONBPARTNER_DDLIST_ERROR:
        //     return {
        //         ...state,
        //         quotationbpartners: [],
        //         loading: false,
        //         error: true,
        //         quotationbpartner: {}
        //     }
        // case QUOTATIONBPARTNER_GET:
        //     return {
        //         ...state,
        //         loading: true
        //     }
        // case QUOTATIONBPARTNER_GET_SUCCESS:
        //     return {
        //         ...state,
        //         quotationbpartner: action.payload,
        //         loading: false,
        //         error: false
        //     }
        // case QUOTATIONBPARTNER_GET_ERROR:
        //     return {
        //         ...state,
        //         error: true,
        //         quotationbpartner: {}
        //     }
        // case QUOTATIONBYBPARTNER_GET:
        //     return {
        //         ...state,
        //         loading: true
        //     }
        // case QUOTATIONBYBPARTNER_GET_SUCCESS:
        //     return {
        //         ...state,
        //         quotationbpartner: action.payload,                
        //         loading: false,
        //         error: false
        //     }
        // case QUOTATIONBYBPARTNER_GET_ERROR:
        //     return {
        //         ...state,
        //         error: true,
        //         quotationbpartner: {}
        //     }

        case QUOTATIONBPARTNER_NEW:
            return {
                ...state,
                loading: true,
            }
        case QUOTATIONBPARTNER_NEW_SUCCESS:
            sessionStorage.setItem('error', false);
            return {
                ...state,
                quotationbpartners: [...state.quotationbpartners, action.payload],
                quotationbpartner: {},
                loading: false,
                error: false
            }
        case QUOTATIONBPARTNER_NEW_ERROR:
            sessionStorage.setItem('error', true);
            return {
                ...state,
                quotationbpartner: action.payload,
                loading: false,
                error: true
            }
        case QUOTATIONBPARTNER_NEW_RECORD:
            return {
                ...state,
                quotationbpartner: {},
                error: false
            }
        
        
        // case QUOTATIONBPARTNER_EDIT:
        //     return {
        //         ...state
        //     }
        // case QUOTATIONBPARTNER_EDIT_SUCCESS:
        //     sessionStorage.setItem('error', false);
        //     return {
        //         ...state,
        //         error: false,
        //         quotationbpartner: action.payload,
        //         quotationbpartners: state.quotationbpartners.map(quotationbpartner => quotationbpartner.id === action.payload.id ? quotationbpartner = action.payload : quotationbpartner),
        //     }
        // case QUOTATIONBPARTNER_EDIT_ERROR:
        //     sessionStorage.setItem('error', true);
        //     return {
        //         ...state,
        //         quotationbpartner: action.payload,
        //         error: false
        //     }
        default:
            return state;
    }
}
