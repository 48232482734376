import {
    TAX_LIST,
    TAX_LIST_SUCCESS,
    TAX_LIST_ERROR,
    TAX_DDLIST,
    TAX_DDLIST_SUCCESS,
    TAX_DDLIST_ERROR,
    TAX_GET,
    TAX_GET_SUCCESS,
    TAX_GET_ERROR,
    TAX_NEW,
    TAX_NEW_SUCCESS,
    TAX_NEW_ERROR,
    TAX_NEW_RECORD,
    TAX_EDIT,
    TAX_EDIT_SUCCESS,
    TAX_EDIT_ERROR
} from '../types';

const initialState = {
    taxes: [],
    tax: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case TAX_LIST:
            return {
                ...state,
                loading: true,
                tax: {}
            }
        case TAX_LIST_SUCCESS:
            return {
                ...state,
                taxes: action.payload,
                loading: false,
                error: false,
                tax: {}
            }
        case TAX_LIST_ERROR:
            return {
                ...state,
                taxes: [],
                loading: false,
                error: true,
                tax: {}
            }
        case TAX_DDLIST:
            return {
                ...state,
                loading: true,
                tax: {}
            }
        case TAX_DDLIST_SUCCESS:
            return {
                ...state,
                taxes: action.payload,
                loading: false,
                error: false,
                tax: {}
            }
        case TAX_DDLIST_ERROR:
            return {
                ...state,
                taxes: [],
                loading: false,
                error: true,
                tax: {}
            }
        case TAX_GET:
            return {
                ...state,
                loading: true
            }
        case TAX_GET_SUCCESS:
            return {
                ...state,
                tax: action.payload,
                loading: false,
                error: false
            }
        case TAX_GET_ERROR:
            return {
                ...state,
                error: true,
                tax: {}
            }
        case TAX_NEW:
            return {
                ...state,
                loading: true,
            }
        case TAX_NEW_SUCCESS:
            sessionStorage.setItem('error', false);
            return {
                ...state,
                taxes: [...state.taxes, action.payload],
                tax: {},
                loading: false,
                error: false
            }
        case TAX_NEW_ERROR:
            sessionStorage.setItem('error', true);
            return {
                ...state,
                tax: action.payload,
                loading: false,
                error: true
            }
        case TAX_NEW_RECORD:
            return {
                ...state,
                tax: {},
                error: false
            }
        case TAX_EDIT:
            return {
                ...state
            }
        case TAX_EDIT_SUCCESS:
            sessionStorage.setItem('error', false);
            return {
                ...state,
                error: false,
                tax: action.payload,
                taxes: state.taxes.map(tax => tax.id === action.payload.id ? tax = action.payload : tax),
            }
        case TAX_EDIT_ERROR:
            sessionStorage.setItem('error', true);
            return {
                ...state,
                tax: action.payload,
                error: false
            }
        default:
            return state;
    }
}
