import {
    AIRPLANE_SERIES_MANAGEMENT_LIST,
    AIRPLANE_SERIES_MANAGEMENT_LIST_SUCCESS ,
    AIRPLANE_SERIES_MANAGEMENT_LIST_ERROR ,
    AIRPLANE_SERIES_MANAGEMENT_GET ,
    AIRPLANE_SERIES_MANAGEMENT_GET_SUCCESS ,
    AIRPLANE_SERIES_MANAGEMENT_GET_ERROR ,
    AIRPLANE_SERIES_MANAGEMENT_NEW ,
    AIRPLANE_SERIES_MANAGEMENT_NEW_SUCCESS ,
    AIRPLANE_SERIES_MANAGEMENT_NEW_ERROR,
    AIRPLANE_SERIES_MANAGEMENT_EDIT,
    AIRPLANE_SERIES_MANAGEMENT_EDIT_SUCCESS ,
    AIRPLANE_SERIES_MANAGEMENT_EDIT_ERROR ,
    AIRPLANE_SERIES_MANAGEMENT_DELETE ,
    AIRPLANE_SERIES_MANAGEMENT_DELETE_SUCCESS ,
    AIRPLANE_SERIES_MANAGEMENT_DELETE_ERROR,
    AIRPLANE_SERIES_DDLIST,
    AIRPLANE_SERIES_DDLIST_SUCCESS,
    AIRPLANE_SERIES_DDLIST_ERROR
} from "../types";

const initialState = {
    airplaneseriesmanagementlist: [],
    airplaneseriesmanagement: {},
    airplane_series_ddlist: [],
    loading: false,
    error: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case AIRPLANE_SERIES_MANAGEMENT_LIST:
            return {
                ...state,
                loading: true,
                airplaneseriesmanagementlist: [],
            };

        case AIRPLANE_SERIES_MANAGEMENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                airplaneseriesmanagementlist: action.payload,
                airplaneseriesmanagement: {}
            };

        case AIRPLANE_SERIES_MANAGEMENT_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                airplaneseriesmanagementlist: [],
                airplaneseriesmanagement: {}
            };

        case AIRPLANE_SERIES_MANAGEMENT_GET:
            return {
                ...state,
                loading: true,
                error: false,
                airplaneseriesmanagement: {}
            }

        case AIRPLANE_SERIES_MANAGEMENT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                airplaneseriesmanagement: action.payload
            };

        case AIRPLANE_SERIES_MANAGEMENT_GET_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                airplaneseriesmanagementlist: [],
                airplaneseriesmanagement: {}
            };

        case AIRPLANE_SERIES_MANAGEMENT_NEW:
            return {
                ...state,
                loading: true,
                error: false
            };

        case AIRPLANE_SERIES_MANAGEMENT_NEW_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                airplaneseriesmanagementlist: [...state.airplaneseriesmanagementlist, action.payload],
                airplaneseriesmanagement: action.payload
            };

        case AIRPLANE_SERIES_MANAGEMENT_NEW_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                airplaneseriesmanagementlist: [],
                airplaneseriesmanagement: {}
            }

        case AIRPLANE_SERIES_MANAGEMENT_EDIT:
            return {
                ...state,
                loading: true,
                error: false
            };

        case AIRPLANE_SERIES_MANAGEMENT_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                airplaneseriesmanagement: action.payload,
                airplaneseriesmanagementlist: state.airplaneseriesmanagementlist.map(airplaneseriesmanagement => (airplaneseriesmanagement.id === action.payload.id ? action.payload : airplaneseriesmanagement))
            };

        case AIRPLANE_SERIES_MANAGEMENT_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                airplaneseriesmanagementlist: [],
                airplaneseriesmanagement: {}
            };

        case AIRPLANE_SERIES_MANAGEMENT_DELETE:
            return {
                ...state,
                loading: true,
                error: false
            }

        case AIRPLANE_SERIES_MANAGEMENT_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                airplaneseriesmanagementlist: state.airplaneseriesmanagementlist.filter(airplaneseriesmanagement => airplaneseriesmanagement.id !== action.payload)
            }

        case AIRPLANE_SERIES_MANAGEMENT_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                error: true
            }

            case AIRPLANE_SERIES_DDLIST:
            return {
                ...state,
                loading: true,
                airplane_series_ddlist: [],
            };

            case AIRPLANE_SERIES_DDLIST_SUCCESS:
                return {
                    ...state,
                    airplane_series_ddlist: action.payload,
                    loading: false,
                    error: false
                }

                case AIRPLANE_SERIES_DDLIST_ERROR:
            return {
                ...state,
                airplane_series_ddlist: [],
                loading: false,
                error: true
            }
        default:
            return state;
    }
};