import {
    OMA_LIST,
    OMA_LIST_SUCCESS,
    OMA_LIST_ERROR,
    OMA_DDLIST,
    OMA_DDLIST_SUCCESS,
    OMA_DDLIST_ERROR,
    OMA_GET,
    OMA_GET_SUCCESS,
    OMA_GET_ERROR,
    OMA_NEW,
    OMA_NEW_SUCCESS,
    OMA_NEW_ERROR,
    OMA_NEW_RECORD,
    OMA_EDIT,
    OMA_EDIT_SUCCESS,
    OMA_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Omas
export function OmaListAction(){
    
    return (dispatch =>{
        dispatch(OmaList());
        AxiosClient.get(`${process.env.REACT_APP_OMA}`)
            .then((response)=> {
                dispatch(OmaListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( OmaListError(error) );
            });
    });
}

export const OmaList = () => ({
    type: OMA_LIST
});

export const OmaListSuccess = (records) => ({
    type: OMA_LIST_SUCCESS,
    payload: records
});

export const OmaListError = (error) => ({
    type: OMA_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Omas en Forma de Drop Lista
export function OmaDDListAction(){
    return (dispatch =>{
        dispatch(OmaDDList());
        AxiosClient.get(`${process.env.REACT_APP_OMA}/ddlist`)
            .then((response)=> {
                dispatch( OmaDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( OmaDDListError(error) );
            });
    });
}

export const OmaDDList = () => ({
    type: OMA_DDLIST
});

export const OmaDDListSuccess = (records) => ({
    type: OMA_DDLIST_SUCCESS,
    payload: records
});

export const OmaDDListError = (error) => ({
    type: OMA_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener una Oma por id
export function OmaGetAction(id){
    return (dispatch =>{
        dispatch(OmaGet());
        AxiosClient.get(`${process.env.REACT_APP_OMA}/${id}`)
            .then((response)=> {
                dispatch( OmaGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( OmaGetError(error) );
            });
    });
}

export const OmaGet = () => ({
    type: OMA_GET
});

export const OmaGetSuccess = (record) => ({
    type: OMA_GET_SUCCESS,
    payload: record
});

export const OmaGetError = (error) => ({
    type: OMA_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Oma cuando se va crear un registro nuevo
export function OmaNewAction(){
    return (dispatch =>{
            dispatch(OmaNewRecord());
    });
}

export const OmaNewRecord = () => ({
    type: OMA_NEW_RECORD
});

// Función Principal para crear y editar registros de Omas
export function OmaSaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(OmaEdit());
        } else{
            dispatch(OmaNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_OMA}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( OmaNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( OmaNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_OMA}/${record.id}`, record)
                .then((response)=> {
                    dispatch( OmaEditSuccess(record));
                })
                .catch(error=> {                   
                    dispatch( OmaEditError(true) );
                });
        }
    });
}

export const OmaNew = () => ({
    type: OMA_NEW
});

export const OmaNewSuccess = (record) => ({
    type: OMA_NEW_SUCCESS,
    payload: record
});

export const OmaNewError = (error) => ({
    type: OMA_NEW_ERROR,
    payload: error
});

export const OmaEdit = () => ({
    type: OMA_EDIT
});

export const OmaEditSuccess = (record) => ({
    type: OMA_EDIT_SUCCESS,
    payload: record
});

export const OmaEditError = (error) => ({
    type: OMA_EDIT_ERROR,
    payload: error
});
