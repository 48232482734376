import {
    PAYMENTDETAIL_LIST,
    PAYMENTDETAIL_LIST_SUCCESS,
    PAYMENTDETAIL_LIST_ERROR,
    PAYMENTDETAIL_DDLIST,
    PAYMENTDETAIL_DDLIST_SUCCESS,
    PAYMENTDETAIL_DDLIST_ERROR,
    PAYMENTDETAIL_GET,
    PAYMENTDETAIL_GET_SUCCESS,
    PAYMENTDETAIL_GET_ERROR,
    PAYMENTDETAIL_NEW,
    PAYMENTDETAIL_NEW_SUCCESS,
    PAYMENTDETAIL_NEW_ERROR,
    PAYMENTDETAIL_NEW_RECORD,
    PAYMENTDETAIL_EDIT,
    PAYMENTDETAIL_EDIT_SUCCESS,
    PAYMENTDETAIL_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Detalle de Pagos
export function PaymentDetailListAction(){    
    return (dispatch =>{
        dispatch(PaymentDetailList());
        AxiosClient.get(`${process.env.REACT_APP_PAYMENTDETAIL}`)
            .then((response)=> {              
                dispatch( PaymentDetailListSuccess(response.data.result));
            })
            .catch(error=> {            
                dispatch( PaymentDetailListError(error) );
            });
    });
}

export const PaymentDetailList = () => ({
    type: PAYMENTDETAIL_LIST
});

export const PaymentDetailListSuccess = (records) => ({
    type: PAYMENTDETAIL_LIST_SUCCESS,
    payload: records
});

export const PaymentDetailListError = (error) => ({
    type: PAYMENTDETAIL_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Detalle de Pagos en forma de DropDown
export function PaymentDetailDDListAction(){
    
    return (dispatch =>{
        dispatch(PaymentDetailDDList());
        AxiosClient.get(`${process.env.REACT_APP_PAYMENTDETAIL}/ddlist`)
            .then((response)=> {
                dispatch( PaymentDetailDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( PaymentDetailDDListError(error));
            });
    });
}

export const PaymentDetailDDList = () => ({
    type: PAYMENTDETAIL_DDLIST
});

export const PaymentDetailDDListSuccess = (records) => ({
    type: PAYMENTDETAIL_DDLIST_SUCCESS,
    payload: records
});

export const PaymentDetailDDListError = (error) => ({
    type: PAYMENTDETAIL_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener un ID de Detalle de Pagos
export function PaymentDetailGetAction(id){
    return (dispatch =>{
        dispatch(PaymentDetailGet());
        AxiosClient.get(`${process.env.REACT_APP_PAYMENTDETAIL}/${id}`)
            .then((response)=> {                    
                dispatch( PaymentDetailGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( PaymentDetailGetError(error));
            });
    });
}

export const PaymentDetailGet = () => ({
    type: PAYMENTDETAIL_GET
});

export const PaymentDetailGetSuccess = (record) => ({
    type: PAYMENTDETAIL_GET_SUCCESS,
    payload: record
});

export const PaymentDetailGetError = (error) => ({
    type: PAYMENTDETAIL_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Detalle de Pagos cuando se va crear un registro nuevo
export function PaymentDetailNewAction(){
    return (dispatch =>{
            dispatch(PaymentDetailNewRecord());
    });
}

export const PaymentDetailNewRecord = () => ({
    type: PAYMENTDETAIL_NEW_RECORD
});

// Función Principal para crear y editar registros de Detall de Pagos
export function PaymentDetailSaveAction(record, isNew){    
    return (dispatch =>{
        if(!isNew) {
            dispatch(PaymentDetailEdit());
        } else{
            dispatch(PaymentDetailNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_PAYMENTDETAIL}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( PaymentDetailNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( PaymentDetailNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_PAYMENTDETAIL}/${record.id}`, record)
                .then((response)=> {
                    dispatch( PaymentDetailEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( PaymentDetailEditError(true) );
                });
        }
    });
}

export const PaymentDetailNew = () => ({
    type: PAYMENTDETAIL_NEW
});

export const PaymentDetailNewSuccess = (record) => ({
    type: PAYMENTDETAIL_NEW_SUCCESS,
    payload: record
});

export const PaymentDetailNewError = (error) => ({
    type: PAYMENTDETAIL_NEW_ERROR,
    payload: error
});

export const PaymentDetailEdit = () => ({
    type: PAYMENTDETAIL_EDIT
});

export const PaymentDetailEditSuccess = (record) => ({
    type: PAYMENTDETAIL_EDIT_SUCCESS,
    payload: record
});

export const PaymentDetailEditError = (error) => ({
    type: PAYMENTDETAIL_EDIT_ERROR,
    payload: error
});