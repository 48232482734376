import AuthToken from '../config/AuthorizationToken';
import AxiosClient from '../config/AxiosClient';
import jwtDecode from 'jwt-decode';

require('dotenv').config();

export const AuthLogin = {
    login,
    logout,
};

export var login = async (payload) => {
    let error = null;
    const { data } = await AxiosClient.post(
        `${process.env.REACT_APP_LOGIN}/validateuser`,
        payload,
    ).catch((err) => (error = err));
    let userDecode = {};
    if (data && data.ok) {
        const jwtToken = data.result.token;

        userDecode = JSON.stringify(jwtDecode(jwtToken));

        sessionStorage.setItem(process.env.REACT_APP_IS_AUTHENTICATED, true);
        sessionStorage.setItem(process.env.REACT_APP_VAR_USER, userDecode);
        sessionStorage.setItem(process.env.REACT_APP_VAR_TOKEN, jwtToken);

        // Se extra el userid para luego poder obtener el nombre de la persona...
        const userId = sessionStorage
            .getItem('user')
            .slice(
                sessionStorage.getItem('user').indexOf(':') + 1,
                sessionStorage.getItem('user').indexOf(','),
            );

        const {
            data: {
                result: { employeename },
            },
        } = await AxiosClient.get(`${process.env.REACT_APP_EMPLOYEENAME_BY_USER_ID}/${userId}`);

        sessionStorage.setItem(process.env.REACT_APP_VAR_EMPLOYEE_NAME, employeename);

        // Autentica el token
        if (jwtToken) AuthToken(jwtToken);

        return { ok: data?.ok, userId, employeename, userDecode, jwtToken, error };
    }

    return { message: data?.message, ok: data?.ok, error };
};

export var logout = async () => {
    const user = JSON.parse(sessionStorage.getItem('user'))
    console.log(user)
    await AxiosClient.post(`${process.env.REACT_APP_LOGIN}/logout`, { userId: user.userid })
    sessionStorage.clear();
    window.location.href = '/login';
};
