import {
    AIRPLANECOMPONENT_LIST,
    AIRPLANECOMPONENT_LIST_SUCCESS,
    AIRPLANECOMPONENT_LIST_ERROR,
    AIRPLANECOMPONENT_DDLIST,
    AIRPLANECOMPONENT_DDLIST_SUCCESS,
    AIRPLANECOMPONENT_DDLIST_ERROR,
    AIRPLANECOMPONENT_GET,
    AIRPLANECOMPONENT_GET_SUCCESS,
    AIRPLANECOMPONENT_GET_ERROR,   
    AIRPLANECOMPONENT_NEW,
    AIRPLANECOMPONENT_NEW_SUCCESS,
    AIRPLANECOMPONENT_NEW_ERROR,
    AIRPLANECOMPONENT_NEW_RECORD,
    AIRPLANECOMPONENT_EDIT,
    AIRPLANECOMPONENT_EDIT_SUCCESS,
    AIRPLANECOMPONENT_EDIT_ERROR
} from '../types';

const initialState = {
    airplanecomponents: [],
    airplanecomponent: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case AIRPLANECOMPONENT_LIST:
            return {
                ...state,
                loading: true,
                airplanecomponent: {}                
            }
        case AIRPLANECOMPONENT_LIST_SUCCESS:            
            return {
                ...state,
                airplanecomponents: action.payload,
                loading: false,
                error: false,
                airplanecomponent: {}
            }
        case AIRPLANECOMPONENT_LIST_ERROR:
            return {
                ...state,
                airplanecomponents: [],
                loading: false,
                error: true,
                airplane: {}
            }
        case AIRPLANECOMPONENT_DDLIST:
            return {
                ...state,
                loading: true,
                airplanecomponent: {}
            }
        case AIRPLANECOMPONENT_DDLIST_SUCCESS:
            return {
                ...state,
                airplanecomponents: action.payload,
                loading: false,
                error: false
            }
        case AIRPLANECOMPONENT_DDLIST_ERROR:
            return {
                ...state,
                airplanecomponents: [],
                loading: false,
                error: true,
                airplanecomponent: {}
            }
        case AIRPLANECOMPONENT_GET:
            return {
                ...state,
                loading: true
            }
        case AIRPLANECOMPONENT_GET_SUCCESS:
            return {
                ...state,
                airplanecomponent: action.payload,
                loading: false,
                error: false
            }
        case AIRPLANECOMPONENT_GET_ERROR:
            return {
                ...state,
                error: true,
                airplanecomponent: {}
            }
        case AIRPLANECOMPONENT_NEW:
            return {
                ...state
            }
        case AIRPLANECOMPONENT_NEW_SUCCESS:
            return {
                ...state,
                airplanecomponents: [...state.airplanecomponents],
                airplanecomponent: {},
                error: false,
            }
        case AIRPLANECOMPONENT_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case AIRPLANECOMPONENT_NEW_RECORD:
                return {
                    ...state,
                    airplanecomponent: {},                   
                    error: false
                }
        case AIRPLANECOMPONENT_EDIT:
            return {
                ...state,
            }
        case AIRPLANECOMPONENT_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                airplanecomponent: action.payload,
                airplanecomponents: state.airplanecomponents.map(airplanecomponent => airplanecomponent.id === action.payload.id ? airplanecomponent = action.payload : airplanecomponent),
            }
        case AIRPLANECOMPONENT_EDIT_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state;
    }
}