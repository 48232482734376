import React, {Fragment, useState, useEffect} from 'react';
import { withNamespaces } from 'react-i18next';
import ContentWrapper from '../../../Layout/ContentWrapper';
import { TabContent, TabPane, 
         Nav, NavItem, 
         NavLink} from 'reactstrap';
import TableSAM from '../../../Common/TableSAM'
import useFileHandlers from '../../../Hooks/useFileHandlers';

// Redux
import { useDispatch, useSelector } from 'react-redux';
//import { AirplaneListAction } from '../../../store/actions/Airplane.actions';
import {AirplaneListAction} from '../../../../store/actions/Airplane.actions';
//import { FleetDDListAction } from '../../store/actions/Fleet.actions';
//import { ManufacturerDDListAction } from '../../store/actions/Manufacturer.actions';
//import { EntityMdDDListAction } from '../../store/actions/EntityMd.actions';

// Otras Utilidades para ocultar Tabs y manejo de las fechas.
import classnames from 'classnames';
// import produce from 'immer';

// Componentes de Task Card
import ControlMaintenance from './ControlMaintenance';

import {ENROLLMENT_LABEL, SERIAL_LABEL,
        NAME_LABEL,MODEL_LABEL,
        EFFECTTIVENESS_LABEL,
        LINE_LABEL,VARIABLE_LABEL,
        CURRENT_HOURS_LABEL,CURRENT_CYCLES_LABEL } from '../PackageWork/TaskCardConstants';
       
export default function  AirplaneControlMaintenance() {

    // const columns = [
    //     { title: 'Matricula', field: 'acronym'},
    //     { title: 'Serial', field: 'airplane_serial'},       
    //     { title: 'Nombre', field: 'airplane_name'},
    //     { title: 'Módelo', field: 'airplane_model.label'},
    //     { title: 'Efectividad', field: 'effectiveness'},
    //     { title: 'Linea', field: 'line'},
    //     { title: 'Variable', field: 'variable'},
    //     { title: 'Horas Actuales', field: 'current_flight_hours'},
    //     { title: 'Ciclos Actuales', field: 'current_flight_cycles'},        
    // ];

    const columns = [
        { title: ENROLLMENT_LABEL, field: 'acronym'},
        { title: SERIAL_LABEL, field: 'airplane_serial'},       
        { title: NAME_LABEL, field: 'airplane_name'},
        { title: MODEL_LABEL, field: 'airplane_model.label'},
        { title: EFFECTTIVENESS_LABEL, field: 'effectiveness'},
        { title: LINE_LABEL, field: 'line'},
        { title: VARIABLE_LABEL, field: 'variable'},
        { title: CURRENT_HOURS_LABEL, field: 'current_flight_hours'},
        { title: CURRENT_CYCLES_LABEL, field: 'current_flight_cycles'},        
    ];
    const tableTitle = '';

    // State para SAMTable
    const [activeTab, setActiveTab] = useState('1');
    const [tab_2, setTab_2] = useState(true);
    const [tab_3, setTab_3] = useState(true);
    const [tab_4, setTab_4] = useState(true);
    // const [tab_5, setTab_5] = useState(true);

    // State para Airplane Component
    const [id, setId] = useState(0);
    const [airplane_id, setAirplaneId] = useState('');
    const [airplane_name, setAirplaneName] = useState('');
    const [acronym, setAcronym] = useState('');
    const [modal, setModal] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [modalTitle, setModalTitle] = useState(''); 

    // Redux
    const dispatch = useDispatch();
    useEffect( () => {
        // Llama el Action de Aeronaves cuando el componente este listo
        dispatch( AirplaneListAction() ) ;
        //dispatch( FleetDDListAction() );
        //dispatch( ManufacturerDDListAction());
        //dispatch( EntityMdDDListAction(process.env.REACT_APP_AIRPLANE_MODEL));
        //dispatch( EntityMdDDListAction(process.env.REACT_APP_AIRPLANE_MODEL));
    }, [dispatch]);

    // Trae los datos de Aeronaves del State del Reducer.  
     const data = useSelector( state => state.Airplane.airplanes );

    // Trae la lista de valores dde Flota, Fabricante y Modelo de Aeronave
    // const fleetList         = useSelector(state=>state.Fleet.fleetList);
    // const manufacturerList  = useSelector(state=>state.Manufacturer.manufacturerList);
    // const airplaneModelList = useSelector(state=>state.EntityMd.airplaneModelList);
    // const airplaneModelVarianList = useSelector(state=>state.EntityMd.airplaneModelList);

    const {reInit} = useFileHandlers()

    // const newData = ()=>{
    //     setId(0);
    //     setModal(true);
    //     setIsNew(true); 
    //     setModalTitle('Nueva Aeronave');
    // };

    // const editData = (data) =>{
    //     setId(data.id);
    //     setModal(true);
    //     setIsNew(false);
    //     setModalTitle('Editar Aeronave');
    // };

    // Método para abrir Modal de Editar o Incluir Registros.
    const toggleModal = () => {
        reInit();
        setModal(!modal);
        if (modal) {
            //dispatch(AirplaneListAction());
        }
    };

    const viewData = (data, tab)=>{
        setId(data.id);
        setAcronym(data.acronym);
        setAirplaneId(data.airplane_id);
        setAirplaneName(data.airplane_name);
        toggleTab(tab);
    };

    // Metodo de las Tabs
    const toggleTab = (tab)=> {
        if(tab==='1'){
            setTab_2(true);
            setTab_3(true);
            // setTab_4(true);
            // setTab_5(true);
        }else if(tab==='2'){
            setTab_3(true);
            // setTab_4(true);
            // setTab_5(true);
        }else if(tab==='3'){
            setTab_4(true);
            // setTab_5(true);
        }else if(tab==='4'){
            //setTab_5(true);
        }
            
        if (activeTab !== tab) setActiveTab(tab);
        
    };

    // Setea 
    const actions=[
        // {
        //     icon: 'add_circle',
        //     iconProps:{
        //         color: "primary"
        //     },
            // tooltip: 'Nueva Aeronave',
            // isFreeAction: true,
            // onClick: (event) => newData()
        //},
        // {
        //     icon: 'edit',
        //     tooltip: 'Editar Aeronave',
        //     onClick: (event, rowData) => editData(rowData)
        // } ,
        {
            //icon: 'local_library',
            icon: 'build',
            
            tooltip: 'Maintenance Control',
            onClick: (event, rowData) => viewData(rowData, '2')
        },
        // {
        //     icon: 'view_compact',
        //     tooltip: 'Packet Work',
        //     onClick: (event, rowData) => viewData(rowData, '3')
        // },
        // {
        //     icon: 'flight_takeoff',
        //     tooltip: 'Historial de Vuelos',
        //     onClick: (event, rowData) => viewData(rowData,'4')
        // }, 
        // {
        //     icon: 'build',
        //     tooltip: 'Mantenimiento',
        //     onClick: (event, rowData) => this.viewData(rowData)
        // },
                                    
    ];

    const applyOptions = false;

    return (
        <ContentWrapper>
            <div className="content-heading">
                <div>Airplane Maintenance Control
                    <small>Maintenance Control / Airplane</small>
                </div>
            </div>
            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggleTab('1'); }}
                            >
                            Airplanes
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        {(activeTab==='2'?
                            <NavLink
                                className={'hide-tab-2-' + tab_2 + ' ' + classnames({ active: activeTab === '2' })}
                                onClick={() => { toggleTab('2'); }}
                                >
                                Maintenance Control
                            </NavLink>
                        :null)}
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className="tab-contents">
                    <TabPane tabId="1">
                        <Fragment>
                            <TableSAM
                                titleTable={tableTitle}
                                columns={columns}
                                data={data}
                                applyOptions={applyOptions}
                                actions={actions}
                            />
                            {/* {(modal ? <AirplaneModal
                            modal={modal}
                            modalTitle={modalTitle}
                            isNew={isNew}
                            id={id}
                            toggleModal={toggleModal}
                            fleetList={fleetList}
                            manufacturerList={manufacturerList}
                            airplaneModelList={airplaneModelList}
                            airplaneModelVarianList={airplaneModelVarianList}
                        /> : null)} */}
                        </Fragment>
                    </TabPane>
                    <TabPane tabId="2">
                        <Fragment>{(activeTab === '2' ?
                            <ControlMaintenance
                                id={id}
                                airplane_id={airplane_id}
                                airplane_name={airplane_name}
                                acronym={acronym}
                            /> : null)}</Fragment>
                    </TabPane>
                    <TabPane tabId="3">
                        {/* <Fragment>{(activeTab === '3' ?
                            <AirplaneComponentsInstalled
                                id={id}
                                airplane_name={airplane_name}
                                acronym={acronym}
                            />
                            : null)}</Fragment> */}
                    </TabPane>
                    <TabPane tabId="4">
                            {/* {(activeTab === '4' ? <AirplaneFlightHistory
                                    id={id}
                                    airplane_name={airplane_name}
                                    acronym={acronym}
                                />

                                : null)} */}
                    </TabPane>
                            {/* <TabPane tabId="5">
                        <div>Manteniento</div>
                    </TabPane> */}
                </TabContent>
            </div>
        </ContentWrapper>
    );
}

//export default withNamespaces('translations')(AirplaneTaskCard);