import {
    PAYMENTAPPROVAL_LIST,
    PAYMENTAPPROVAL_LIST_SUCCESS,
    PAYMENTAPPROVAL_LIST_ERROR,
    PAYMENTAPPROVAL_DDLIST,
    PAYMENTAPPROVAL_DDLIST_SUCCESS,
    PAYMENTAPPROVAL_DDLIST_ERROR,
    PAYMENTAPPROVAL_GET,
    PAYMENTAPPROVAL_GET_SUCCESS,
    PAYMENTAPPROVAL_GET_ERROR,
    PAYMENTAPPROVAL_NEW,
    PAYMENTAPPROVAL_NEW_SUCCESS,
    PAYMENTAPPROVAL_NEW_ERROR,
    PAYMENTAPPROVAL_NEW_RECORD,
    PAYMENTAPPROVAL_EDIT,
    PAYMENTAPPROVAL_EDIT_SUCCESS,
    PAYMENTAPPROVAL_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Cabecera de Aprobación de Pagos
export function PaymentApprovalListAction(){    
    return (dispatch =>{
        dispatch(PaymentApprovalList());
        AxiosClient.get(`${process.env.REACT_APP_PAYMENTAPPROVAL}`)
            .then((response)=> {              
                dispatch( PaymentApprovalListSuccess(response.data.result));
            })
            .catch(error=> {            
                dispatch( PaymentApprovalListError(error) );
            });
    });
}

export const PaymentApprovalList = () => ({
    type: PAYMENTAPPROVAL_LIST
});

export const PaymentApprovalListSuccess = (records) => ({
    type: PAYMENTAPPROVAL_LIST_SUCCESS,
    payload: records
});

export const PaymentApprovalListError = (error) => ({
    type: PAYMENTAPPROVAL_LIST_ERROR,
    payload: error
});

// // Función Principal para obtener Cabecera de Aprobación de Pagos en forma de DropDown
// export function PaymentApprovalDDListAction(){
    
//     return (dispatch =>{
//         dispatch(PaymentApprovalDDList());
//         AxiosClient.get(`${process.env.REACT_APP_PAYMENTAPPROVAL}/ddlist`)
//             .then((response)=> {
//                 dispatch( PaymentAppovalDDListSuccess(response.data.result));
//             })
//             .catch(error=> {
//                 dispatch( PaymentApprovalDDListError(error));
//             });
//     });
// }

// export const PaymentApprovalDDList = () => ({
//     type: PAYMENTAPPROVAL_DDLIST
// });

// export const PaymentApprovalDDListSuccess = (records) => ({
//     type: PAYMENTAPPROVAL_DDLIST_SUCCESS,
//     payload: records
// });

// export const PaymentAppovalDDListError = (error) => ({
//     type: PAYMENTAPPROVAL_DDLIST_ERROR,
//     payload: error
// });

// Función Principal para obtener un ID de Cabecera de Pagos
export function PaymentApprovalGetAction(id){
    return (dispatch =>{
        dispatch(PaymentApprovalGet());
        AxiosClient.get(`${process.env.REACT_APP_PAYMENTAPPROVAL}/${id}`)
            .then((response)=> {                    
                dispatch( PaymentApprovalGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( PaymentApprovalGetError(error));
            });
    });
}

export const PaymentApprovalGet = () => ({
    type: PAYMENTAPPROVAL_GET
});

export const PaymentApprovalGetSuccess = (record) => ({
    type: PAYMENTAPPROVAL_GET_SUCCESS,
    payload: record
});

export const PaymentApprovalGetError = (error) => ({
    type: PAYMENTAPPROVAL_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Cabecera de Pagos cuando se va crear un registro nuevo
export function PaymentApprovalNewAction(){
    return (dispatch =>{
            dispatch(PaymentApprovalNewRecord());
    });
}

export const PaymentApprovalNewRecord = () => ({
    type: PAYMENTAPPROVAL_NEW_RECORD
});

// Función Principal para crear y editar registros de Cabecera de Pagos
export function PaymentApprovalSaveAction(record, isNew){    
    return (dispatch =>{
        if(!isNew) {           
            dispatch(PaymentApprovalEdit());
        } else{
            dispatch(PaymentApprovalNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_PAYMENTAPPROVAL}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( PaymentApprovalNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( PaymentApprovalNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_PAYMENTAPPROVAL}/${record.id}`, record)
                .then((response)=> {
                    dispatch( PaymentApprovalEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( PaymentApprovalEditError(true) );
                });
        }
    });
}

export const PaymentApprovalNew = () => ({
    type: PAYMENTAPPROVAL_NEW
});

export const PaymentApprovalNewSuccess = (record) => ({
    type: PAYMENTAPPROVAL_NEW_SUCCESS,
    payload: record
});

export const PaymentApprovalNewError = (error) => ({
    type: PAYMENTAPPROVAL_NEW_ERROR,
    payload: error
});

export const PaymentApprovalEdit = () => ({
    type: PAYMENTAPPROVAL_EDIT
});

export const PaymentApprovalEditSuccess = (record) => ({
    type: PAYMENTAPPROVAL_EDIT_SUCCESS,
    payload: record
});

export const PaymentApprovalEditError = (error) => ({
    type: PAYMENTAPPROVAL_EDIT_ERROR,
    payload: error
});