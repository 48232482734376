import {
    MATERIALREQUEST_LIST,
    MATERIALREQUEST_LIST_SUCCESS,
    MATERIALREQUEST_LIST_ERROR,
    MATERIALREQUEST_PENDING_LIST,
    MATERIALREQUEST_PENDING_LIST_SUCCESS,
    MATERIALREQUEST_PENDING_LIST_ERROR,
    MATERIALREQUEST_RETURN_LIST,
    MATERIALREQUEST_RETURN_LIST_SUCCESS,
    MATERIALREQUEST_RETURN_LIST_ERROR,
    MATERIALREQUEST_REPORT,
    MATERIALREQUEST_REPORT_SUCCESS,
    MATERIALREQUEST_REPORT_ERROR,
    MATERIALREQUEST_GET,
    MATERIALREQUEST_GET_SUCCESS,
    MATERIALREQUEST_GET_ERROR,
    MATERIALREQUEST_CLEAN,
    MATERIALREQUEST_NEW,
    MATERIALREQUEST_NEW_SUCCESS,
    MATERIALREQUEST_NEW_ERROR,
    MATERIALREQUEST_RETURN_NEW,
    MATERIALREQUEST_RETURN_NEW_SUCCESS,
    MATERIALREQUEST_RETURN_NEW_ERROR,
    MATERIALREQUEST_NEW_RECORD,
    MATERIALREQUEST_EDIT,
    MATERIALREQUEST_EDIT_SUCCESS,
    MATERIALREQUEST_EDIT_ERROR,
    MATERIALREQUEST_RETURN_EDIT,
    MATERIALREQUEST_RETURN_EDIT_SUCCESS,
    MATERIALREQUEST_RETURN_EDIT_ERROR,
    MATERIALREQUEST_CANCELLATION,
    MATERIALREQUEST_CANCELLATION_SUCCESS,
    MATERIALREQUEST_CANCELLATION_ERROR,
    MATERIALREQUEST_DD_LIST,
    MATERIALREQUEST_DD_LIST_SUCCESS,
    MATERIALREQUEST_DD_LIST_ERROR,
} from '../types';

const initialState = {
    materialrequests: [],
    materialrequest: {},
    materialrequestreport: {},
    materialrequestDDList: [],
    loading: false,
    error: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case MATERIALREQUEST_LIST:
            return {
                ...state,
                loading: true,
                materialrequest: {}
            }
        case MATERIALREQUEST_LIST_SUCCESS:
            return {
                ...state,
                materialrequests: action.payload,
                loading: false,
                error: false,
                materialrequest: {}
            }
        case MATERIALREQUEST_LIST_ERROR:
            return {
                ...state,
                materialrequests: [],
                loading: false,
                error: true,
                materialrequest: {}
            }

        case MATERIALREQUEST_PENDING_LIST:
            return {
                ...state,
                loading: true,
                materialrequest: {}
            }
        case MATERIALREQUEST_PENDING_LIST_SUCCESS:
            return {
                ...state,
                materialrequests: action.payload,
                loading: false,
                error: false,
                materialrequest: {}
            }
        case MATERIALREQUEST_PENDING_LIST_ERROR:
            return {
                ...state,
                materialrequests: [],
                loading: false,
                error: true,
                materialrequest: {}
            }
        case MATERIALREQUEST_RETURN_LIST:
            return {
                ...state,
                loading: true,
                materialrequest: {}
            }
        case MATERIALREQUEST_RETURN_LIST_SUCCESS:
            return {
                ...state,
                materialrequests: action.payload,
                loading: false,
                error: false,
                materialrequest: {}
            }
        case MATERIALREQUEST_RETURN_LIST_ERROR:
            return {
                ...state,
                materialrequests: [],
                loading: false,
                error: true,
                materialrequest: {}
            }
        case MATERIALREQUEST_REPORT:
            return {
                ...state,
                loading: true,
                materialrequestreport: {}
            }
        case MATERIALREQUEST_REPORT_SUCCESS:
            return {
                ...state,
                materialrequestreport: action.payload,
                loading: false,
                error: false
            }
        case MATERIALREQUEST_REPORT_ERROR:
            return {
                ...state,
                materialrequestreport: {},
                loading: false,
                error: true
            }
        case MATERIALREQUEST_GET:
            return {
                ...state,
                materialrequest: {},
                loading: true
            }
        case MATERIALREQUEST_GET_SUCCESS:
            return {
                ...state,
                materialrequest: action.payload,
                loading: false,
                error: false
            }
        case MATERIALREQUEST_GET_ERROR:
            return {
                ...state,
                error: true,
                materialrequest: {}
            }
        case MATERIALREQUEST_NEW:
            return {
                ...state,
                loading: true,
                error: false
            }
        case MATERIALREQUEST_NEW_SUCCESS:
            return {
                ...state,
                loading: false,
                materialrequests: [...state.materialrequests, action.payload],
                materialrequest: action.payload, // es diferente porque no se cierra la modal
                error: false
            }
        case MATERIALREQUEST_NEW_ERROR:
            return {
                ...state,
                loading: false,
                error: true
            }
        case MATERIALREQUEST_NEW_RECORD:
            return {
                ...state,
                materialrequest: {},
                error: false
            }
        case MATERIALREQUEST_RETURN_NEW:
            return {
                ...state,
                loading: true,
                error: false
            }
        case MATERIALREQUEST_RETURN_NEW_SUCCESS:
            return {
                ...state,
                loading: false,
                materialrequests: [...state.materialrequests, action.payload],
                materialrequest: action.payload, // es diferente porque no se cierra la modal
                error: false
            }
        case MATERIALREQUEST_RETURN_NEW_ERROR:
            return {
                ...state,
                loading: false,
                error: true
            }
        case MATERIALREQUEST_EDIT:
            return {
                ...state,
                loading: true
            }
        case MATERIALREQUEST_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                materialrequest: action.payload,
                materialrequests: state.materialrequests.map(materialrequest => materialrequest.id === action.payload.id ? materialrequest = action.payload : materialrequest),
            }
        case MATERIALREQUEST_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                error: false
            }
        case MATERIALREQUEST_RETURN_EDIT:
            return {
                ...state,
                loading: true
            }
        case MATERIALREQUEST_RETURN_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                materialrequest: action.payload,
                materialrequests: state.materialrequests.map(materialrequest => materialrequest.id === action.payload.id ? materialrequest = action.payload : materialrequest),
            }
        case MATERIALREQUEST_RETURN_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                error: false
            }
        case MATERIALREQUEST_CANCELLATION:
            return {
                ...state,
                loading: true
            }
        case MATERIALREQUEST_CANCELLATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                materialrequest: action.payload,
                materialrequests: state.materialrequests.map(materialrequest => materialrequest.id === action.payload.id ? materialrequest = action.payload : materialrequest),
            }
        case MATERIALREQUEST_CANCELLATION_ERROR:
            return {
                ...state,
                loading: false,
                error: false
            }
        case MATERIALREQUEST_CLEAN:
            return {
                ...state,
                materialrequest: {}
            }
        case MATERIALREQUEST_DD_LIST:
            return {
                ...state,
                loading: true,
                materialrequestDDList: []
            };
        case MATERIALREQUEST_DD_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                materialrequestDDList: action.payload
            };
        case MATERIALREQUEST_DD_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                materialrequestDDList: []
            };
        default:
            return state;
    }
}
