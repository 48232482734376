import {
    CURRENCY_LIST,
    CURRENCY_LIST_SUCCESS,
    CURRENCY_LIST_ERROR,
    CURRENCY_DDLIST,
    CURRENCY_DDLIST_SUCCESS,
    CURRENCY_DDLIST_ERROR,
    CURRENCY_GET,
    CURRENCY_GET_SUCCESS,
    CURRENCY_GET_ERROR,
    CURRENCY_NEW,
    CURRENCY_NEW_SUCCESS,
    CURRENCY_NEW_ERROR,
    CURRENCY_NEW_RECORD,
    CURRENCY_EDIT,
    CURRENCY_EDIT_SUCCESS,
    CURRENCY_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Monedas
export function CurrencyListAction(){
    
    return (dispatch =>{
        dispatch(CurrencyList());
        AxiosClient.get(`${process.env.REACT_APP_CURRENCY}`)
            .then((response)=> {
                dispatch(CurrencyListSuccess(response.data.result));
            })
            .catch(error=> {
                console.log(error);
                dispatch( CurrencyListError(error) );
            });
    });
}

export const CurrencyList = () => ({
    type: CURRENCY_LIST
});

export const CurrencyListSuccess = (records) => ({
    type: CURRENCY_LIST_SUCCESS,
    payload: records
});

export const CurrencyListError = (error) => ({
    type: CURRENCY_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Monedas en forma de DropDown
export function CurrencyDDListAction(){
    
    return (dispatch =>{
        dispatch(CurrencyDDList());
        AxiosClient.get(`${process.env.REACT_APP_CURRENCY}/ddlist/`)
            .then((response)=> {
                dispatch( CurrencyDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( CurrencyDDListError(error) );
            });
    });
}

export const CurrencyDDList = () => ({
    type: CURRENCY_DDLIST
});

export const CurrencyDDListSuccess = (records) => ({
    type: CURRENCY_DDLIST_SUCCESS,
    payload: records
});

export const CurrencyDDListError = (error) => ({
    type: CURRENCY_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener una Unidad de Medida por ID
export function CurrencyGetAction(id){
    return (dispatch =>{
        dispatch(CurrencyGet());
        AxiosClient.get(`${process.env.REACT_APP_CURRENCY}/${id}`)
            .then((response)=> {
                dispatch( CurrencyGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( CurrencyGetError(error) );
            });
    });
}

export const CurrencyGet = () => ({
    type: CURRENCY_GET
});

export const CurrencyGetSuccess = (record) => ({
    type: CURRENCY_GET_SUCCESS,
    payload: record
});

export const CurrencyGetError = (error) => ({
    type: CURRENCY_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Unidad de Medida cuando se va crear un registro nuevo
export function CurrencyNewAction(){
    return (dispatch =>{
            dispatch(CurrencyNewRecord());
    });
}

export const CurrencyNewRecord = () => ({
    type: CURRENCY_NEW_RECORD
});

// Función Principal para crear y editar registros de Unidades de Medida
export function CurrencySaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(CurrencyEdit());
        } else{
            dispatch(CurrencyNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_CURRENCY}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( CurrencyNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( CurrencyNewError(record) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_CURRENCY}/${record.id}`, record)
                .then((response)=> {
                    dispatch( CurrencyEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( CurrencyEditError(record) );
                });
        }
    });
}

export const CurrencyNew = () => ({
    type: CURRENCY_NEW
});

export const CurrencyNewSuccess = (record) => ({
    type: CURRENCY_NEW_SUCCESS,
    payload: record
});

export const CurrencyNewError = (error) => ({
    type: CURRENCY_NEW_ERROR,
    payload: error
});

export const CurrencyEdit = () => ({
    type: CURRENCY_EDIT
});

export const CurrencyEditSuccess = (record) => ({
    type: CURRENCY_EDIT_SUCCESS,
    payload: record
});

export const CurrencyEditError = (error) => ({
    type: CURRENCY_EDIT_ERROR,
    payload: error
});
