import {
    IMAGE_BY_PROCESS_LIST,
    IMAGE_BY_PROCESS_LIST_SUCCESS,
    IMAGE_BY_PROCESS_LIST_ERROR,
    IMAGE_BY_PROCESS_GET,
    IMAGE_BY_PROCESS_GET_SUCCESS,
    IMAGE_BY_PROCESS_GET_ERROR,
    IMAGE_BY_PROCESS_NEW,
    IMAGE_BY_PROCESS_NEW_SUCCESS,
    IMAGE_BY_PROCESS_NEW_ERROR,
    IMAGE_BY_PROCESS_NEW_RECORD,
    IMAGE_BY_PROCESS_EDIT,
    IMAGE_BY_PROCESS_EDIT_SUCCESS,
    IMAGE_BY_PROCESS_EDIT_ERROR,
    IMAGE_BY_PROCESS_DELETE,
    IMAGE_BY_PROCESS_DELETE_SUCCESS,
    IMAGE_BY_PROCESS_DELETE_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para eliminar un a ImageByProcess
export function ImageByProcessDeleteAction(id){
    return (dispatch =>{
        dispatch(ImageByProcessDelete());
        AxiosClient.delete(`${process.env.REACT_APP_IMAGEBYPROCESS}/${id}`)
            .then((response)=> {
                dispatch( ImageByProcessDeleteSuccess(id, response));
            })
            .catch(error=> {
                dispatch( ImageByProcessDeleteError(error) );
            });
    });
}

export const ImageByProcessDelete = () => ({
    type: IMAGE_BY_PROCESS_DELETE
});

export const ImageByProcessDeleteSuccess = (id) => ({
    type: IMAGE_BY_PROCESS_DELETE_SUCCESS,
    payload: id
});

export const ImageByProcessDeleteError = (error) => ({
    type: IMAGE_BY_PROCESS_DELETE_ERROR,
    payload: error
});

// Función Principal para obtener lista de ImageByProcess
export function ImageByProcessListAction(){
    
    return (dispatch =>{
        dispatch(ImageByProcessList());
        AxiosClient.get(`${process.env.REACT_APP_IMAGE_BY_PROCESS}`)
            .then((response)=> {
                console.log(response.data.result);
                dispatch( ImageByProcessListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( ImageByProcessListError(error) );
            });
    });
}

export const ImageByProcessList = () => ({
    type: IMAGE_BY_PROCESS_LIST
});

export const ImageByProcessListSuccess = (records) => ({
    type: IMAGE_BY_PROCESS_LIST_SUCCESS,
    payload: records
});

export const ImageByProcessListError = (error) => ({
    type: IMAGE_BY_PROCESS_LIST_ERROR,
    payload: error
});

// Función Principal para obtener un ImageByProcess
export function ImageByProcessGetAction(id){
    return (dispatch =>{
        dispatch(ImageByProcessGet());
        AxiosClient.get(`${process.env.REACT_APP_IMAGE_BY_PROCESS}/${id}`)
            .then((response)=> {
                dispatch( ImageByProcessGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( ImageByProcessGetError(error) );
            });
    });
}

export const ImageByProcessGet = () => ({
    type: IMAGE_BY_PROCESS_GET
});

export const ImageByProcessGetSuccess = (record) => ({
    type: IMAGE_BY_PROCESS_GET_SUCCESS,
    payload: record
});

export const ImageByProcessGetError = (error) => ({
    type: IMAGE_BY_PROCESS_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de ImageByProcess cuando se va crear un registro nuevo
export function ImageByProcessNewAction(){
    return (dispatch =>{
            dispatch(ImageByProcessNewRecord());
    });
}

export const ImageByProcessNewRecord = () => ({
    type: IMAGE_BY_PROCESS_NEW_RECORD
});

// Función Principal para crear y editar registros de ImageByProcess
export function ImageByProcessSaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(ImageByProcessEdit());
        } else{
            dispatch(ImageByProcessNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_IMAGE_BY_PROCESS}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( ImageByProcessNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( ImageByProcessNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_IMAGE_BY_PROCESS}/${record.id}`, record)
                .then((response)=> {
                    dispatch( ImageByProcessEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( ImageByProcessEditError(true) );
                });
        }
    });
}

export const ImageByProcessNew = () => ({
    type: IMAGE_BY_PROCESS_NEW
});

export const ImageByProcessNewSuccess = (record) => ({
    type: IMAGE_BY_PROCESS_NEW_SUCCESS,
    payload: record
});

export const ImageByProcessNewError = (error) => ({
    type: IMAGE_BY_PROCESS_NEW_ERROR,
    payload: error
});

export const ImageByProcessEdit = () => ({
    type: IMAGE_BY_PROCESS_EDIT
});

export const ImageByProcessEditSuccess = (record) => ({
    type: IMAGE_BY_PROCESS_EDIT_SUCCESS,
    payload: record
});

export const ImageByProcessEditError = (error) => ({
    type: IMAGE_BY_PROCESS_EDIT_ERROR,
    payload: error
});

