import {
    COMPONENT_ASSEMBLED_ITEM_DDLIST,
    COMPONENT_ASSEMBLED_ITEM_DDLIST_ERROR,
    COMPONENT_ASSEMBLED_ITEM_DDLIST_SUCCESS,
    COMPONENT_ASSEMBLED_ITEM_EDIT,
    COMPONENT_ASSEMBLED_ITEM_EDIT_ERROR,
    COMPONENT_ASSEMBLED_ITEM_EDIT_SUCCESS,
    COMPONENT_ASSEMBLED_ITEM_GET,
    COMPONENT_ASSEMBLED_ITEM_GET_ERROR,
    COMPONENT_ASSEMBLED_ITEM_GET_SUCCESS,
    COMPONENT_ASSEMBLED_ITEM_LIST,
    COMPONENT_ASSEMBLED_ITEM_LIST_ERROR,
    COMPONENT_ASSEMBLED_ITEM_LIST_SUCCESS,
    COMPONENT_ASSEMBLED_ITEM_NEW,
    COMPONENT_ASSEMBLED_ITEM_NEW_ERROR,
    COMPONENT_ASSEMBLED_ITEM_NEW_SUCCESS
} from "../types";

const initialState = {
    detailList: [],
    detailDDList: [],
    detailData: {},
    loading: false,
    error: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case COMPONENT_ASSEMBLED_ITEM_LIST:
            return {
                ...state,
                loading: true,
                detailList: [],
            };
        case COMPONENT_ASSEMBLED_ITEM_LIST_SUCCESS:
            return {
                ...state,
                detailList: action.payload,
                loading: false,
                error: false,
                detailData: {},
            };
        case COMPONENT_ASSEMBLED_ITEM_LIST_ERROR:
            return {
                ...state,
                detailList: [],
                loading: false,
                error: true,
                detailData: {},
            };
        case COMPONENT_ASSEMBLED_ITEM_DDLIST:
            return {
                ...state,
                loading: true,
                detailData: {},
                detailDDList: [],
            };
        case COMPONENT_ASSEMBLED_ITEM_DDLIST_SUCCESS:
            return {
                ...state,
                detailDDList: action.payload,
                loading: false,
                error: false,
            };
        case COMPONENT_ASSEMBLED_ITEM_DDLIST_ERROR:
            return {
                ...state,
                detailDDList: [],
                loading: false,
                error: true,
                detailData: {},
            };
        case COMPONENT_ASSEMBLED_ITEM_GET:
            return {
                ...state,
                loading: true,
            };
        case COMPONENT_ASSEMBLED_ITEM_GET_SUCCESS:
            return {
                ...state,
                detailData: action.payload,
                loading: false,
                error: false,
            };
        case COMPONENT_ASSEMBLED_ITEM_GET_ERROR:
            return {
                ...state,
                error: true,
                detailData: {},
            };
        case COMPONENT_ASSEMBLED_ITEM_NEW:
            return {
                ...state,
            };
        case COMPONENT_ASSEMBLED_ITEM_NEW_SUCCESS:
            return {
                ...state,
                error: false,
                detailList: [...state.detailList, action.payload],
            };
        case COMPONENT_ASSEMBLED_ITEM_NEW_ERROR:
            return {
                ...state,
                error: true,
            };
        case COMPONENT_ASSEMBLED_ITEM_EDIT:
            return {
                ...state,
            };
        case COMPONENT_ASSEMBLED_ITEM_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                detailData: action.payload,
                detailList: state.detailList.map((item) =>
                    item.id === action.payload.id ? { ...item, ...action.payload } : item,
                ),
            };
        case COMPONENT_ASSEMBLED_ITEM_EDIT_ERROR:
            return {
                ...state,
                error: true,
            };
        default:
            return state;
    }
}
