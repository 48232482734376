import {
    COMPONENT_ASSEMBLED_HEADER_GET,
    COMPONENT_ASSEMBLED_HEADER_GET_ERROR,
    COMPONENT_ASSEMBLED_HEADER_GET_SUCCESS,
    COMPONENT_ASSEMBLED_HEADER_ID_CLEAN,
    COMPONENT_ASSEMBLED_HEADER_ID_SET,
    COMPONENT_ASSEMBLED_HEADER_LIST,
    COMPONENT_ASSEMBLED_HEADER_LIST_ERROR,
    COMPONENT_ASSEMBLED_HEADER_LIST_SUCCESS,
    COMPONENT_ASSEMBLED_HEADER_SAVE
} from "../types";

// Función Principal para obtener lista de Unidades de Medida
export const ComponentAssembledHeaderListAction = (payload) => (dispatch) => {
    dispatch(ComponentAssembledHeaderList());
    if (payload && payload.length) dispatch(ComponentAssembledHeaderListSuccess(payload));
    else dispatch(ComponentAssembledHeaderListError('List not found'));
};

export const ComponentAssembledHeaderList = () => ({
    type: COMPONENT_ASSEMBLED_HEADER_LIST,
});

export const ComponentAssembledHeaderListSuccess = (records) => ({
    type: COMPONENT_ASSEMBLED_HEADER_LIST_SUCCESS,
    payload: records,
});

export const ComponentAssembledHeaderListError = (error) => ({
    type: COMPONENT_ASSEMBLED_HEADER_LIST_ERROR,
    payload: error,
});

export const ComponentAssembledHeaderGetAction = (payload = null) => (dispatch) => {
    dispatch(ComponentAssembledHeaderFind());
    if (payload) dispatch(ComponentAssembledHeaderGetSuccess(payload));
    else dispatch(ComponentAssembledHeaderGetError('List not found'));
};

export const ComponentAssembledHeaderFind = () => ({
    type: COMPONENT_ASSEMBLED_HEADER_GET,
});

export const ComponentAssembledHeaderGetSuccess = (records) => ({
    type: COMPONENT_ASSEMBLED_HEADER_GET_SUCCESS,
    payload: records,
});

export const ComponentAssembledHeaderGetError = (error) => ({
    type: COMPONENT_ASSEMBLED_HEADER_GET_ERROR,
    payload: error,
});

export const ComponentAssembledHeaderIDAction = (payload = null) => (dispatch) => {
    dispatch(ComponentAssembledHeaderCleanId());
    if (payload) dispatch(ComponentAssembledHeaderSetID(payload || 0));
};

export const ComponentAssembledHeaderCleanId = () => ({
    type: COMPONENT_ASSEMBLED_HEADER_ID_CLEAN,
});

export const ComponentAssembledHeaderSetID = (id) => ({
    type: COMPONENT_ASSEMBLED_HEADER_ID_SET,
    payload: id,
});

export const ComponentAssembledheaderSaveRecordAction = (record) => (dispatch) => {
    if (record) dispatch(ComponentAssembledHeaderSaveSuccess(record || 0));
};

export const ComponentAssembledHeaderSaveSuccess = (record) => ({
    type: COMPONENT_ASSEMBLED_HEADER_SAVE,
    payload: record,
});