import Swal from 'sweetalert2';
import AxiosClient from '../config/AxiosClient';
import { CorrelativeDocumentEditError } from '../store/actions/CorrelativeDocument.actions';

import React, { useState, useEffect } from 'react';
import { get } from 'lodash';

const moment = require('moment');

require('dotenv').config();

// Mensaje de Exito por pantalla estandar para operaciones del Crear, Editar y Eliminar registros
export const messageSuccess = (entityName, typeMessage) => {
    let titleMessage = '';
    let bodyMessage = '';
    switch (typeMessage) {
        case 'C': // Crear registro
            titleMessage = `Crear ${entityName}`;
            bodyMessage = `${entityName} se ha creado correctamente`;
            break;
        case 'U': // Editar registro
            titleMessage = `Editar ${entityName}`;
            bodyMessage = `${entityName} se ha editado correctamente`;
            break;
        case 'D': // Eliminar registro
            titleMessage = `Eliminar ${entityName}`;
            bodyMessage = `${entityName} se ha eliminado correctamente`;
            break;
        case 'DD': // Duplicar registro
            titleMessage = `Duplicación de ${entityName}`;
            bodyMessage = `${entityName} se ha duplicado correctamente`;
            break;
        case 'MP': // Materiales Pendientes por Despachar
            titleMessage = `Atención`;
            bodyMessage =
                'Usted posee solicitudes de Materiales de Almacen Pendientes por Despachar';
            break;
        case 'RP': // Regeneracion de Contrasena Satisfactoria
            titleMessage = `Atención`;
            bodyMessage = 'La Contraseña ha sido Restituida Satisfactoriamente';
            break;
        case '': // Duplicar registro
            titleMessage = `Duplicación de ${entityName}`;
            bodyMessage = `${entityName} se ha duplicado correctamente`;
            break;
        default:
            break;
    }
    Swal.fire({
        icon: 'success',
        title: titleMessage,
        text: bodyMessage,
    });
};

// Mensaje de Error por pantalla estandar para operaciones del Crear, Editar y Eliminar registros
export const messageError = (entityName, typeMessage, msg = '') => {
    let titleMessage = '';
    let bodyMessage = '';
    switch (typeMessage) {
        case 'C': // Crear registro
            titleMessage = `Crear ${entityName}`;
            bodyMessage = `Error al tratar de crear ${entityName}`;
            break;
        case 'U': // Editar registro
            titleMessage = `Editar ${entityName}`;
            bodyMessage = `Error al tratar de actualizar ${entityName}`;
            break;
        case 'D': // Eliminar registro
            titleMessage = `Eliminar ${entityName}`;
            bodyMessage = `Error al tratar de eliminar${entityName}`;
            break;
        case 'DD': // Duplicar registro
            titleMessage = `Duplicación de ${entityName}`;
            bodyMessage = `Error al duplicar el ${entityName}`;
            break;
        case 'CD': // No se Permite Crear registro Duplicado
            titleMessage = `Duplicación de ${entityName}`;
            bodyMessage = `El Item Seleccionado ya está asociado ${entityName}`;
            break;
        case 'NC': // No hay Registros Seleccionados
            titleMessage = `Crear ${entityName}`;
            bodyMessage = `No se han seleccionado registros para crear ${entityName}`;
            break;
        case 'CUSTOM':
            titleMessage = `Error:`;
            bodyMessage = `${msg}`;
            break;
        case 'PR':
            titleMessage = `Error:`;
            bodyMessage = `La Persona que Retira es Obligatoria`;
            break;
        case 'RD':
            titleMessage = `Error:`;
            bodyMessage = `Fecha de Retiro es Obligatoria`;
            break;
        case 'RT':
            titleMessage = `Error:`;
            bodyMessage = `La Hora de Retiro es Obligatoria`;
            break;
        // case 'FE':
        //     titleMessage = `Error:`;
        //     bodyMessage = `La Fecha de Entrega no puede ser menor que la fecha de Solicitud`;
        case 'FD':
            titleMessage = `Error:`;
            bodyMessage = `La Fecha de Devolución no puede ser menor que la fecha de Entrega`;
            break;

        case 'IT':
            titleMessage = `Error:`;
            bodyMessage = `No existen Ubicaciones Configuradas para el almacén seleccionado y el tipo de Almacenamiento del Item`;
            break;
        case 'ITZ':
            titleMessage = `Error:`;
            bodyMessage = `No existen Zonas Configuradas para el Almacén Seleccionado`;
            break;
        case 'PW':
            titleMessage = `Error:`;
            bodyMessage = `La Contraseña y la Confirmación no coinciden, revise la información`;
            break;
        case 'RPW':
            titleMessage = `Error:`;
            bodyMessage = `Usted Introdujo una Respuesta Inválida, revise la información`;
            break;
        case 'BPC':
            titleMessage = `Error:`;
            bodyMessage = `La contraseña no cumple con los requerimientos de composición mínimos establecidos`;
            break;
        default:
            titleMessage = `Error de Actualización`;
            bodyMessage = `No se pudo actualizar la información`;
            break;
    }
    Swal.fire({
        icon: 'error',
        title: titleMessage,
        text: bodyMessage,
    });
};

// Mensaje con titulo, texto, icono personalizado
export const messageText = (title, message, icon) => {
    Swal.fire({
        icon: icon,
        title: title,
        text: message,
    });
};

// Metodo para crear correlativo según el Proceso (solicitud de Materiales, Incoming, Preimcoming, Compras)
export const createCorrelative = (correlativeDocument) => {
    if (correlativeDocument) {
        const increment = parseInt(correlativeDocument.increment_number) + 1;
        return (
            correlativeDocument.document_prefix +
            '-' +
            correlativeDocument.year +
            '-' +
            new Array(5 - increment.toString().length + 1).join('0') +
            increment.toString()
        );
    }
};

export const createCorrelativeJustNumber = (correlativeDocument) => {
    if (correlativeDocument) {
        const increment = parseInt(correlativeDocument.increment_number) + 1;
        return (
            new Array(5 - increment.toString().length + 1).join('0') +
            increment.toString()
        );
    }
}

// Función Principal para obtener un Departamento
export const getCorrelativeDocumentByCode = async (code) => {
    let correlativeDocument;
    const year = new Date().getFullYear();
    const url = `${process.env.REACT_APP_CORRELATIVE_DOCUMENT}/code/${code}/${year}`;
    const response = await AxiosClient.get(url);

    console.log(response);
    if (response) {
        correlativeDocument = createCorrelative(response.data.result);
    }
    console.log(correlativeDocument);
    return correlativeDocument;
};

// Metodo para Incrementar correlativo según el Proceso (solicitud de Materiales, Incoming, Preimcoming, Compras)
export const incrementCorrelative = (correlativeDocument) => {
    const { increment_number } = correlativeDocument;
    if (increment_number) {
        return parseInt(increment_number) + 1;
    }
};

// Metodo para darle Formato con Separador de Miles y decimales a una variable numerica
export const formatValue = (amount) => {
    if (amount) {
        return amount.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }
    return 0;
};

// Metodo para Eliminar registros de Material-table
export const itemRemove = (arr, id) => {
    const index = arr.indexOf(id);
    arr.splice(index, 1);
};

// Metodo para refrescar en Material-Table modificando el titulo de la tabla (truco)
export const setTrickyText = (textOrig, textMod) => {
    let text_returned = textOrig;
    if (textMod.substring(textMod.length - 1) !== ' ') text_returned = textMod + ' ';
    return text_returned;
};

// Metodo para consultar ItemMaster
export const getItemMaster = async (id) => {
    const response = await AxiosClient.get(
        `${process.env.REACT_APP_ITEMMASTER}/getOne/${id}/${process.env.REACT_APP_ENT_ITEMMASTER_IMAGE}`,
    );

    let itemMaster = {};

    if (response) itemMaster = response.data.result;

    return itemMaster;
};

export const getItemInventoryById = async (id) => {
    const response = await AxiosClient.get(
        `${process.env.REACT_APP_ITEMINVENTORY}/${id}`,
    );

    let itemInventory = {};

    if (response) itemInventory = response.data.result;

    return itemInventory;
};

// Metodo para consultar ItemMaster
export const getItemMasterIfAlternate = async (isAlternate) => {
    const response = await AxiosClient.get(
        `${process.env.REACT_APP_ITEMMASTER}/alternate/ddlist/${isAlternate}`,
    );
    if (response) return response.data.result;
    return [];
};

// Metodo para consultar ItemMaster
export const getItemMasterByItemMasterParent = async (itemMasterId) => {
    const response = await AxiosClient.get(
        `${process.env.REACT_APP_ITEMMASTER}/parent/dropdownlist/${itemMasterId}`,
    );
    if (response) return response.data.result;
    return [];
};

// Metodo para consultar ItemMaster
export const getItemMasterByParent = async (itemmaster_parent_id) => {
    const response = await AxiosClient.get(
        `${process.env.REACT_APP_ITEMMASTER}/parent/${itemmaster_parent_id}`,
    );
    console.table(response);
    return response ? response.data.result : [];
};

// Metodo para obtener Cuentas Bancarias por Proveedor
export const getBpartnerAccountBank = async (id) => {
    const url = `${process.env.REACT_APP_BPARTNERBANK}/ddlist/${id}`;
    const response = await AxiosClient.get(url);
    let bpartnerBank = {};
    if (response) bpartnerBank = response.data.result;
    return bpartnerBank;
};

// Metodo para obtener SubAta de Acuerdo al Id del ATA Indicado
export const getSubAtaByAta = async (id) => {
    const url = `/subata/ddlist/${id}`;
    const response = await AxiosClient.get(url);
    let subAta = {};
    if (response) subAta = response.data.result;
    return subAta;
};

export const getSubCategoryByCategoryId = async (id) => {
    const url = `/subcategory/category/ddlist/${id}`;
    const response = await AxiosClient.get(url);
    let subCategory = {};
    if (response) subCategory = response.data.result;
    return subCategory;
};

// Metodo para obtener Listado de Fabricantes
export const getManufacturer = async () => {
    //const url = `${process.env.REACT_APP_MANUFACTURER}/ddlist`;
    const url = `/manufacturer/ddlist`;
    const response = await AxiosClient.get(url);
    let manufacturerList = {};
    if (response) manufacturerList = response.data.result;
    return manufacturerList;
};

// Metodo para verificar existencia de Matricula de Avión
export const verifyAirplaneDataExists = async (id, isNew, param, paramValue) => {
    const urlPart = `${process.env.REACT_APP_AIRPLANE}/${param}/${paramValue}`;
    const url = isNew ? urlPart : `${urlPart}/id/${id}`;
    const response = await AxiosClient.get(url);
    let verified = false;
    if (response) verified = response.data.ok;
    return verified;
};

// Metodo para obtener Listado de Zonas por Almacen
export const getWarehouseZoneParent = async (idWareHouse, zoneType) => {
    const url = `/warehousezone/warehousezonebywarehouseforlocation/${idWareHouse}/${zoneType}`;
    const response = await AxiosClient.get(url);
    // let wareHouseZoneList = {};
    // if (response) wareHouseZoneList = response.data.result;
    return response.data.result || [];
};

// Metodo para obtener Listado de Ubicaciones por Almacen y Tipo de Almacenaje
//export const getWarehouseZone = async (idWareHouse,wareHouseZoneParent,zoneType) => {
export const getWarehouseZone = async (idWareHouse, wareHouseZoneParent) => {
    //const url = `${process.env.REACT_APP_MANUFACTURER}/ddlist`;
    //const url = `/warehousezone/warehousezonebywarehouseandtype/${idWareHouse}/${wareHouseZoneParent}/${zoneType}`;
    const url = `/warehousezone/warehousezonebywarehouseandtype/${idWareHouse}/${wareHouseZoneParent}`;
    const response = await AxiosClient.get(url);
    let wareHouseZoneList = {};
    if (response) wareHouseZoneList = response.data.result;
    return wareHouseZoneList;
};

export const getWarehouseZonDDListById = async (warehouse_id) => {
    const url = `/warehousezone/ddlistlocation/${warehouse_id}/Z`;
    const response = await AxiosClient.get(url);
    let warehouseZoneDDList = [];
    if (response) warehouseZoneDDList = response.data.result;
    return warehouseZoneDDList;
};

// Metodo para crear Correlativos de Procesos fuera de Redux -- Para casos especiales (Ejemplo en Creacion de Cotizaciones)
export const getCorrelativeDocument = async (id) => {
    let correlativeDocument;
    const year = new Date().getFullYear();
    const url = `${process.env.REACT_APP_CORRELATIVE_DOCUMENT}/${id}/${year}`;
    const response = await AxiosClient.get(url);

    if (response) {
        correlativeDocument = response.data.result;
    }
    return correlativeDocument;
};

//Metodo para obtener la descripcion de una opcion de menu de acuerdo a su id
export const getMenuNameOption = async (id) => {
    let menuName;

    const url = `${process.env.REACT_APP_MENU}/name/${id}`;
    const response = await AxiosClient.get(url);

    //console.log("response",response);

    if (response) {
        //if (response.data.result) {
        // console.log("response.data.result.name",response.data.result.name);
        //menuName = response.data.result.name;
        menuName = response.data.result;
        //console.log("menuName",menuName);
        return menuName;
    }
};

//Metodo para obtener la descripcion de una opcion de menu de acuerdo a su id
export const getMenuName = async () => {
    const url = `${process.env.REACT_APP_MENU}/listall`;
    const response = await AxiosClient.get(url);

    let menuName = {};
    if (response) menuName = response.data.result;
    //console.log("menuName",menuName);
    return menuName;
};

//Metodo para obtener el estatus de la opcion de menu en la tabla Menurol
export const getMenuStatus = async (menu_id, role_id) => {
    const url = `${process.env.REACT_APP_MENU_ROL}/${menu_id}/${role_id}/`;
    const response = await AxiosClient.get(url);

    let menuStatus;
    if (response) menuStatus = response.data.result;
    //console.log("menuName",menuName);
    return menuStatus;
};

// Metodo Actualizar el Password del Usuario
export const updatePassword = async (userName) => {
    const url = `/login/generatepassword`;

    const response = await AxiosClient.post(url);

    //console.log("response",response)
    // let wareHouseZoneList = {};
    // if (response) wareHouseZoneList = response.data.result;
    // return wareHouseZoneList;
};

// Metodo para formatear la fecha en MAterial Datatable
export const formatDate = (date) => {
    if (date) {
        return moment(date).format('DD-MM-YYYY');
    } else {
        return '';
    }
};

// Metodo para formatear la fecha en MAterial Datatable en formato Anio, Mes, Dia
export const formatDateYyMmDd = (date) => {
    let dateReturn;
    if (date !== undefined && date !== null) {
        dateReturn = moment(date).format('YYYY-MM-DD');
    } else {
        dateReturn = '';
    }
    return dateReturn;
};

const utcOffset = moment().utcOffset() / 60;

const fixDateTime = ( datetime ) => moment(datetime || new Date()).add( utcOffset, 'hour');

export const fixFormatDateTime = (date) => (date ? fixDateTime(date).format('DD-MM-YYYY HH:mm:ss') : '')

// Metodo para formatear la fecha/Hora en MAterial Datatable
export const formatDateTime = (date) => (date ? moment(date).format('DD-MM-YYYY HH:mm:ss') : '')

export const formatTime = (date) => (date ? moment(date).format('HH:mm:ss') : '')

export const formatDateByFormat = (date, format) => (date ? moment(date).format(format) : '')

// Metodo Interno para corregir Fecha en DatePicker de Material UI cuando se edita un registro existente
export const fixFormatDate = (date) => {
    return moment(date).add(4, 'hour');
};

// Metodo para corregir Fecha en DatePicker de Material UI
export const fixFormatDateChange = (date, boolean) => {
    return boolean ? date : fixFormatDate(date);
};

// Metodo para llenar un objeto SELECT con multiples selecciones
export const multipleSelections = (arrayMultiple, arraySource) => {
    let applymultiple_v = [];
    if (arrayMultiple !== undefined && arrayMultiple !== null && arrayMultiple.length > 0)
        arraySource.forEach((source) => {
            arrayMultiple.forEach((apply) => {
                if (parseInt(source.value) === parseInt(apply.value)) {
                    applymultiple_v.push(source);
                }
            });
        });
    return applymultiple_v;
};

// Metodo para extraer un objeto value/label de un Dropdown para campos que necesitan ser llenados por default. Ej: Usuario
export const getObjectOneDropDown = (dropdownList = [], value) => {
    let objectOne = { value: 0, label: '' };
    dropdownList.forEach((element) => {
        if (parseInt(element.value) === parseInt(value)) {
            objectOne = element;
        }
    });
    return objectOne;
};

export const getObjectFromDropDownByValue = ( dropdownList = [], value ) => 
    ( dropdownList?.find( item => parseInt(item.value) === parseInt(value) ) || { value: 0, label: '' } );

// Metodo para eliminar registros duplicados de un Array
export const cleanArray = (arrayOriginal) => {
    const newArr = [];
    const myObj = {};
    arrayOriginal.forEach((el) => !(el in myObj) && (myObj[el] = true) && newArr.push(el));
    return newArr;
};

// Valida si posee al menos un Caracter en mayusculas
export const validateUpperCharter = (password) => {
    let result;
    let i;
    var upperCharter = 'ABCDEFGHYJKLMNÑOPQRSTUVWXYZ';

    // Validamos que el Password tenga al menos un Caracter en Mayuscula
    for (i = 0; i < password.length; i++) {
        if (upperCharter.indexOf(password.charAt(i), 0) != -1) {
            result = true;
            return result;
        } else {
            result = false;
        }
    }

    return result;
};

// Valida si posee al menos un Caracter en minuscula
export const validateLowerCharter = (password) => {
    let result;
    let i;
    var lowerCharter = 'abcdefghyjklmnñopqrstuvwxyz';

    // Validamos que el Password tenga al menos un Caracter en Minuscula
    for (i = 0; i < password.length; i++) {
        if (lowerCharter.indexOf(password.charAt(i), 0) != -1) {
            result = true;
            return result;
        } else {
            result = false;
        }
    }

    return result;
};

// Valida si posee al menos un numero
export const validateNumberCharter = (password) => {
    let result;
    let i;
    var numberCharter = '0123456789';

    // Validamos que el Password tenga al menos un numero
    for (i = 0; i < password.length; i++) {
        if (numberCharter.indexOf(password.charAt(i), 0) != -1) {
            result = true;
            return result;
        } else {
            result = false;
        }
    }

    return result;
};

// Valida si posee al menos un caracter especial
export const validateEspecialCharter = (password) => {
    let result;
    let i;
    var especialCharter = '.,/{}[]?<>-_+=&@#$%!*()';

    // Validamos que el Password tenga al menos un caracter especial de los configurados en el Sistema
    for (i = 0; i < password.length; i++) {
        if (especialCharter.indexOf(password.charAt(i), 0) != -1) {
            result = true;
            return result;
            //return;
        } else {
            result = false;
        }
    }

    return result;
};

// Metodo para Validar Composicion del Password del Usuario
export const validatePasswordComposition = (password) => {
    let result;
    if (
        validateUpperCharter(password) &
        validateLowerCharter(password) &
        validateNumberCharter(password) &
        validateEspecialCharter(password)
    ) {
        result = true;
    } else {
        result = false;
    }

    return result;
};

export const getUserId = () =>
    sessionStorage.getItem('user')
        ? sessionStorage.getItem('user').slice(
            sessionStorage.getItem('user').indexOf(':') + 1,
            sessionStorage.getItem('user').indexOf(',') ) 
        : null;


export const getRoleArrayStringFromArrayObject = 
    roleArrayObject => ( roleArrayObject ? roleArrayObject.map( roleObject => roleObject.code ) : [] );


export const getHoursFromMiliseconds = ( miliseconds = 0 ) => roundNumberFormat( parseFloat( miliseconds / 1000 / 60 / 60 ) );

export const roundNumberFormat = ( number ) => Math.round((number + Number.EPSILON) * 100) / 100;

export const getAtaCodeByParameters = ( ataCode, subAtaCode, chapter = '', figure = '', item = '' ) => {
    let ataCodeFull = ataCode + '-' + subAtaCode;
    if (chapter) {
        ataCodeFull = ataCodeFull + '-' + chapter;
    }
    if (figure) {
        ataCodeFull = ataCodeFull + '-' + figure;
    }
    if (item) {
        ataCodeFull = ataCodeFull + '-' + item;
    }
    return ataCodeFull;
}

export const setObjectAttributes = ( callback, attributes = [], object = {} ) => {
    let returnObject = {...object};
    for (const attribute of attributes) {
        returnObject[attribute] = callback(object[attribute]);
    }
    return returnObject;
}

export const dateTimeComparator = ( dateTime1, dateTime2 ) => moment(dateTime1).isAfter(dateTime2);

export const deleteDialog = async ( titleDialog, messageDialog ) => {
    return await Swal.fire({
        title: titleDialog,
        text: messageDialog,
        showCancelButton: true,
        confirmButtonText: `Do it`,
        confirmButtonColor: '#d33',
        icon: 'question'
    });
}

export const dropDownTransform = 
    (list = [], labelKey = 'label', valueKey = 'value') => list.map(el => ({ label: get(el, labelKey, ''), value: get(el, valueKey, 0) }))