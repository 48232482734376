import {
    ENTITY_LIST,
    ENTITY_LIST_SUCCESS,
    ENTITY_LIST_ERROR,
    ENTITY_DDLIST,
    ENTITY_DDLIST_SUCCESS,
    ENTITY_DDLIST_ERROR,
} from '../types';

const initialState = {
    entities: [],
    entity: {},
    loading: false,
    error: false
}

export default function(state = initialState, action){
    switch(action.type){
        case ENTITY_LIST:
            return {
                ...state,
                loading: true,
                entity: {}
            }
        case ENTITY_LIST_SUCCESS:
            return {
                ...state,
                entities: action.payload,
                loading: false,
                error: false,
                entity: {}
            }
        case ENTITY_LIST_ERROR:
            return {
                ...state,
                entities: [],
                loading: false,
                error: true,
                entity: {}
            }
        case ENTITY_DDLIST:
            return {
                ...state,
                loading: true,
                entity: {}
            }
        case ENTITY_DDLIST_SUCCESS:
            return {
                ...state,
                entities: action.payload,
                loading: false,
                error: false,
                entity: {}
            }
        case ENTITY_DDLIST_ERROR:
            return {
                ...state,
                entities: [],
                loading: false,
                error: true,
                entity: {}
            }
        default:
            return state;
    }
}
