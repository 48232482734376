import { 
    EQUIPMENT_REQUEST_SET_ONE,
    EQUIPMENT_REQUEST_DETAIL_SET_LIST,
    EQUIPMENT_REQUEST_DETAIL_SET_ONE,
    EQUIPMENT_REQUEST_DETAIL_ADD_NEW,
    EQUIPMENT_REQUEST_DETAIL_EDIT_ONE,
    EQUIPMENT_REQUEST_DETAIL_DELETE_ONE
} from "../types";

const initialState = {
    equipmentrequest: {},
    equipmentrequestdetails: [],
    equipmentrequestdetail: {}
}

export default function( state= initialState, action ) {
    switch (action.type) {
        case EQUIPMENT_REQUEST_SET_ONE:
            return {
                ...state,
                equipmentrequest: action.payload
            };
        case EQUIPMENT_REQUEST_DETAIL_SET_LIST:
            return {
                ...state,
                equipmentrequestdetails: action.payload
            };
        
        case EQUIPMENT_REQUEST_DETAIL_SET_ONE:
            return {
                ...state,
                equipmentrequestdetail: action.payload
            };

        case EQUIPMENT_REQUEST_DETAIL_ADD_NEW:
            return {
                ...state,
                equipmentrequestdetails: [...state.equipmentrequestdetails, action.payload]
            };

        case EQUIPMENT_REQUEST_DETAIL_EDIT_ONE:
            return {
                ...state,
                equipmentrequestdetails: state.equipmentrequestdetails.map( equipmentrequestdetail => ( equipmentrequestdetail.id === action.payload.id ? action.payload : equipmentrequestdetail ) )
            };

        case EQUIPMENT_REQUEST_DETAIL_DELETE_ONE:
            return {
                ...state,
                equipmentrequestdetail: {},
                equipmentrequestdetails: state.equipmentrequestdetails.filter( equipmentrequestdetail => ( equipmentrequestdetail.id !== action.payload ) )
            }
        default:
            return state;
    }
}