import {
    ITEMMASTER_LIST,
    ITEMMASTER_LIST_SUCCESS,
    ITEMMASTER_LIST_ERROR,
    ITEMMASTER_DDLIST,
    ITEMMASTER_DDLIST_SUCCESS,
    ITEMMASTER_DDLIST_ERROR,
    ITEMMASTER_ACTIVE_DDLIST,
    ITEMMASTER_ACTIVE_DDLIST_SUCCESS,
    ITEMMASTER_ACTIVE_DDLIST_ERROR,
    ITEMMASTER_ALTERNATE_DDLIST,
    ITEMMASTER_ALTERNATE_DDLIST_SUCCESS,
    ITEMMASTER_ALTERNATE_DDLIST_ERROR,
    ITEMMASTER_GET,
    ITEMMASTER_GET_SUCCESS,
    ITEMMASTER_GET_ERROR,
    ITEMMASTER_NEW,
    ITEMMASTER_NEW_SUCCESS,
    ITEMMASTER_NEW_ERROR,
    ITEMMASTER_NEW_RECORD,
    ITEMMASTER_DUPLICATE,
    ITEMMASTER_DUPLICATE_SUCCESS,
    ITEMMASTER_DUPLICATE_ERROR,
    ITEMMASTER_EDIT,
    ITEMMASTER_EDIT_SUCCESS,
    ITEMMASTER_EDIT_ERROR,
    ITEMMASTER_ALTER_LIST_SUCCESS,
    ITEMMASTER_ALTER_LIST_ERROR,
    ITEMMASTER_ALTER_LIST
} from '../types';

const initialState = {
    itemmasters: [],
    itemmasterDDList: [],
    itemmaster: {},
    loading: false,
    error: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ITEMMASTER_LIST:
            return {
                ...state,
                loading: true,
                itemmaster: {}
            }
        case ITEMMASTER_LIST_SUCCESS:
            return {
                ...state,
                itemmasters: action.payload,
                loading: false,
                error: false,
                itemmaster: {}
            }
        case ITEMMASTER_LIST_ERROR:
            return {
                ...state,
                itemmasters: [],
                loading: false,
                error: true,
                itemmaster: {}
            }
        case ITEMMASTER_ALTER_LIST:
            return {
                ...state,
                itemmasterAlters: [],
                loading: true
            }
        case ITEMMASTER_ALTER_LIST_SUCCESS:
            return {
                ...state,
                itemmasterAlters: action.payload,
                loading: false,
                error: false
            }
        case ITEMMASTER_ALTER_LIST_ERROR:
            return {
                ...state,
                itemmasters: [],
                itemmasterAlters: [],
                loading: false,
                error: true,
                itemmaster: {}
            }
        case ITEMMASTER_DDLIST:
            return {
                ...state,
                loading: true,
                itemmaster: {}
            }
        case ITEMMASTER_DDLIST_SUCCESS:
            return {
                ...state,
                itemmasterDDList: action.payload,
                loading: false,
                error: false,
                itemmaster: {}
            }
        case ITEMMASTER_DDLIST_ERROR:
            return {
                ...state,
                itemmasterDDList: [],
                itemmasters: [],
                loading: false,
                error: true,
                itemmaster: {}
            }
        case ITEMMASTER_ACTIVE_DDLIST:
            return {
                ...state,
                loading: true,
                itemmaster: {}
            }
        case ITEMMASTER_ACTIVE_DDLIST_SUCCESS:
            return {
                ...state,
                itemmasters: action.payload,
                loading: false,
                error: false,
                itemmaster: {}
            }
        case ITEMMASTER_ACTIVE_DDLIST_ERROR:
            return {
                ...state,
                itemmasters: [],
                loading: false,
                error: true,
                itemmaster: {}
            }

        case ITEMMASTER_ALTERNATE_DDLIST:
            return {
                ...state,
                loading: true
            }
        case ITEMMASTER_ALTERNATE_DDLIST_SUCCESS:

            return {
                ...state,
                loading: false,
                error: false,
                itemmasterAlternateList: action.payload
            }
        case ITEMMASTER_ALTERNATE_DDLIST_ERROR:
            return {
                ...state,
                itemmasterAlternateList: [],
                loading: false,
            }
        case ITEMMASTER_GET:
            return {
                ...state,
                loading: true
            }
        case ITEMMASTER_GET_SUCCESS:
            return {
                ...state,
                itemmaster: action.payload,
                loading: false,
                error: false
            }
        case ITEMMASTER_GET_ERROR:
            return {
                ...state,
                error: true,
                itemmaster: {}
            }
        case ITEMMASTER_NEW:
            return {
                ...state
            }
        case ITEMMASTER_NEW_SUCCESS:
            return {
                ...state,
                itemmasters: [...state.itemmasters, action.payload],
                itemmaster: {},
                error: false
            }
        case ITEMMASTER_NEW_ERROR:
            return {
                ...state,
                error: true
            }
        case ITEMMASTER_NEW_RECORD:
            return {
                ...state,
                itemmaster: {},
                error: false
            }
        case ITEMMASTER_DUPLICATE:
            return {
                ...state
            }
        case ITEMMASTER_DUPLICATE_SUCCESS:
            console.log('ActionPayload', action.payload);
            return {
                ...state,
                itemmasters: [...state.itemmasters, action.payload],
                itemmaster: {},
                error: false
            }
        case ITEMMASTER_DUPLICATE_ERROR:
            return {
                ...state,
                error: true
            }
        case ITEMMASTER_EDIT:
            return {
                ...state
            }
        case ITEMMASTER_EDIT_SUCCESS:
            return {
                ...state,
                error: false,
                itemmaster: action.payload,
                itemmasters: state.itemmasters.map(itemmaster => itemmaster.id === action.payload.id ? action.payload : itemmaster),
            }
        case ITEMMASTER_EDIT_ERROR:
            return {
                ...state,
                error: false
            }
        default:
            return state;
    }
}
