import {
    TAX_LIST,
    TAX_LIST_SUCCESS,
    TAX_LIST_ERROR,
    TAX_DDLIST,
    TAX_DDLIST_SUCCESS,
    TAX_DDLIST_ERROR,
    TAX_GET,
    TAX_GET_SUCCESS,
    TAX_GET_ERROR,
    TAX_NEW,
    TAX_NEW_SUCCESS,
    TAX_NEW_ERROR,
    TAX_NEW_RECORD,
    TAX_EDIT,
    TAX_EDIT_SUCCESS,
    TAX_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de Unidades de Medida
export function TaxListAction(){
    
    return (dispatch =>{
        dispatch(TaxList());
        AxiosClient.get(`${process.env.REACT_APP_TAX}`)
            .then((response)=> {
                dispatch(TaxListSuccess(response.data.result));
            })
            .catch(error=> {
                console.log(error);
                dispatch( TaxListError(error) );
            });
    });
}

export const TaxList = () => ({
    type: TAX_LIST
});

export const TaxListSuccess = (records) => ({
    type: TAX_LIST_SUCCESS,
    payload: records
});

export const TaxListError = (error) => ({
    type: TAX_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Unidad de Medida
export function TaxDDListAction(id){
    
    return (dispatch =>{
        dispatch(TaxDDList());
        AxiosClient.get(`${process.env.REACT_APP_TAX}/ddlist/${id}`)
            .then((response)=> {
                dispatch( TaxDDListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( TaxDDListError(error) );
            });
    });
}

export const TaxDDList = () => ({
    type: TAX_DDLIST
});

export const TaxDDListSuccess = (records) => ({
    type: TAX_DDLIST_SUCCESS,
    payload: records
});

export const TaxDDListError = (error) => ({
    type: TAX_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener una Unidad de Medida por ID
export function TaxGetAction(id){
    return (dispatch =>{
        dispatch(TaxGet());
        AxiosClient.get(`${process.env.REACT_APP_TAX}/${id}`)
            .then((response)=> {
                dispatch( TaxGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( TaxGetError(error) );
            });
    });
}

export const TaxGet = () => ({
    type: TAX_GET
});

export const TaxGetSuccess = (record) => ({
    type: TAX_GET_SUCCESS,
    payload: record
});

export const TaxGetError = (error) => ({
    type: TAX_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de Unidad de Medida cuando se va crear un registro nuevo
export function TaxNewAction(){
    return (dispatch =>{
            dispatch(TaxNewRecord());
    });
}

export const TaxNewRecord = () => ({
    type: TAX_NEW_RECORD
});

// Función Principal para crear y editar registros de Unidades de Medida
export function TaxSaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(TaxEdit());
        } else{
            dispatch(TaxNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_TAX}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( TaxNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( TaxNewError(record) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_TAX}/${record.id}`, record)
                .then((response)=> {
                    dispatch( TaxEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( TaxEditError(record) );
                });
        }
    });
}

export const TaxNew = () => ({
    type: TAX_NEW
});

export const TaxNewSuccess = (record) => ({
    type: TAX_NEW_SUCCESS,
    payload: record
});

export const TaxNewError = (error) => ({
    type: TAX_NEW_ERROR,
    payload: error
});

export const TaxEdit = () => ({
    type: TAX_EDIT
});

export const TaxEditSuccess = (record) => ({
    type: TAX_EDIT_SUCCESS,
    payload: record
});

export const TaxEditError = (error) => ({
    type: TAX_EDIT_ERROR,
    payload: error
});
