import {
    PRIORITY_BY_PROCESS_LIST,
    PRIORITY_BY_PROCESS_LIST_SUCCESS,
    PRIORITY_BY_PROCESS_LIST_ERROR,
    PRIORITY_BY_PROCESS_DDLIST,
    PRIORITY_BY_PROCESS_DDLIST_SUCCESS,
    PRIORITY_BY_PROCESS_DDLIST_ERROR,
    PRIORITY_BY_PROCESS_GET,
    PRIORITY_BY_PROCESS_GET_SUCCESS,
    PRIORITY_BY_PROCESS_GET_ERROR,
    PRIORITY_BY_PROCESS_NEW,
    PRIORITY_BY_PROCESS_NEW_SUCCESS,
    PRIORITY_BY_PROCESS_NEW_ERROR,
    PRIORITY_BY_PROCESS_NEW_RECORD,
    PRIORITY_BY_PROCESS_EDIT,
    PRIORITY_BY_PROCESS_EDIT_SUCCESS,
    PRIORITY_BY_PROCESS_EDIT_ERROR
} from '../types';

import AxiosClient from '../../config/AxiosClient';

require('dotenv').config();

// Función Principal para obtener lista de PriorityByProcess
export const PriorityByProcessListAction = (entity_id) => async (dispatch) => {
    
        dispatch(PriorityByProcessList());
        AxiosClient.get(`${process.env.REACT_APP_PRIORITYBYPROCESS}/list/${entity_id}`)
            .then((response)=> {
                dispatch( PriorityByProcessListSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( PriorityByProcessListError(error) );
            });
}

export const PriorityByProcessList = () => ({
    type: PRIORITY_BY_PROCESS_LIST
});

export const PriorityByProcessListSuccess = (records) => ({
    type: PRIORITY_BY_PROCESS_LIST_SUCCESS,
    payload: records
});

export const PriorityByProcessListError = (error) => ({
    type: PRIORITY_BY_PROCESS_LIST_ERROR,
    payload: error
});

// Función Principal para obtener Master Data por Entidad en forma de DropDown
export function PriorityByProcessDDListAction(entity_id){
    return (dispatch =>{
        dispatch(PriorityByProcessDDList());
        AxiosClient.get(`${process.env.REACT_APP_PRIORITYBYPROCESS}/ddlist/${entity_id}`)
            .then((response)=> {
                dispatch( PriorityByProcessDDListSuccess(response.data.result, entity_id));
            })
            .catch(error=> {
                dispatch( PriorityByProcessDDListError(error) );
            });
    });
}

export const PriorityByProcessDDList = () => ({
    type: PRIORITY_BY_PROCESS_DDLIST
});

export const PriorityByProcessDDListSuccess = (records, entity_id) => ({
    type: PRIORITY_BY_PROCESS_DDLIST_SUCCESS,
    payload: records,
    id: entity_id
});

export const PriorityByProcessDDListError = (error) => ({
    type: PRIORITY_BY_PROCESS_DDLIST_ERROR,
    payload: error
});

// Función Principal para obtener un PriorityByProcess
export function PriorityByProcessGetAction(id){
    return (dispatch =>{
        dispatch(PriorityByProcessGet());
        AxiosClient.get(`${process.env.REACT_APP_PRIORITYBYPROCESS}/${id}`)
            .then((response)=> {
                dispatch( PriorityByProcessGetSuccess(response.data.result));
            })
            .catch(error=> {
                dispatch( PriorityByProcessGetError(error) );
            });
    });
}

export const PriorityByProcessGet = () => ({
    type: PRIORITY_BY_PROCESS_GET
});

export const PriorityByProcessGetSuccess = (record) => ({
    type: PRIORITY_BY_PROCESS_GET_SUCCESS,
    payload: record
});

export const PriorityByProcessGetError = (error) => ({
    type: PRIORITY_BY_PROCESS_GET_ERROR,
    payload: error
});

//Función Principal para setear objeto de PriorityByProcess cuando se va crear un registro nuevo
export function PriorityByProcessNewAction(){
    return (dispatch =>{
            dispatch(PriorityByProcessNewRecord());
    });
}

export const PriorityByProcessNewRecord = () => ({
    type: PRIORITY_BY_PROCESS_NEW_RECORD
});

// Función Principal para crear y editar registros de PriorityByProcess
export function PriorityByProcessSaveAction(record, isNew){
    return (dispatch =>{
        if(!isNew) {
            dispatch(PriorityByProcessEdit());
        } else{
            dispatch(PriorityByProcessNew());
        }
        if(isNew){
            AxiosClient.post(`${process.env.REACT_APP_PRIORITYBYPROCESS}`, record)
                .then((response)=> {
                    record.id = response.data.result.id;
                    dispatch( PriorityByProcessNewSuccess(record));
                })
                .catch(error=> {
                    dispatch( PriorityByProcessNewError(error) );
                });
        }else{
            AxiosClient.put(`${process.env.REACT_APP_PRIORITYBYPROCESS}/${record.id}`, record)
                .then((response)=> {
                    dispatch( PriorityByProcessEditSuccess(record));
                })
                .catch(error=> {
                    dispatch( PriorityByProcessEditError(true) );
                });
        }
    });
}

export const PriorityByProcessNew = () => ({
    type: PRIORITY_BY_PROCESS_NEW
});

export const PriorityByProcessNewSuccess = (record) => ({
    type: PRIORITY_BY_PROCESS_NEW_SUCCESS,
    payload: record
});

export const PriorityByProcessNewError = (error) => ({
    type: PRIORITY_BY_PROCESS_NEW_ERROR,
    payload: error
});

export const PriorityByProcessEdit = () => ({
    type: PRIORITY_BY_PROCESS_EDIT
});

export const PriorityByProcessEditSuccess = (record) => ({
    type: PRIORITY_BY_PROCESS_EDIT_SUCCESS,
    payload: record
});

export const PriorityByProcessEditError = (error) => ({
    type: PRIORITY_BY_PROCESS_EDIT_ERROR,
    payload: error
});